import React, { useState } from 'react';
import { AppState } from '../redux/AppStore';
import { connect, ConnectedProps } from 'react-redux';
import { Table, TableHeader, TableRow, TextTableCell } from '../common/Table';
import { Button } from '../common/Button';
import Scrollable from '../common/Scrollable';
import { NotificationMessageBroadcastState } from '../api/model/backoffice';
import { notificationActions, notificationSelectors } from './store';
import SendNotificationButton from './SendNotificationButton';
import NotificationBroadcastMessageStatsDialog from './NotificationBroadcastMessageStatsDialog';

const mapStateToProps = (state: AppState) => ({
    notificationBroadcastMessages:
        notificationSelectors.getRecentBroadcastMessages(state),
});

const connector = connect(mapStateToProps, {
    fetchRecentBroadcasts: notificationActions.fetchRecentBroadcasts,
    enterViewStatsMode: notificationActions.enterViewStatsMode,
});

type Props = ConnectedProps<typeof connector>;

export const ChallengeListInner: React.FunctionComponent<Props> = (props) => {
    const { fetchRecentBroadcasts, notificationBroadcastMessages } = props;

    const [didFetch, setDidFetch] = useState(false);

    React.useEffect(() => {
        if (notificationBroadcastMessages.length === 0 && !didFetch) {
            fetchRecentBroadcasts();
            setDidFetch(true);
        }
    }, [
        didFetch,
        setDidFetch,
        notificationBroadcastMessages,
        fetchRecentBroadcasts,
    ]);

    return (
        <Scrollable>
            <div className="flex mb-4">
                <h1 className="font-bold text-3xl">
                    Broadcast Notification Messages
                </h1>
                <div className="ml-auto">
                    <Button
                        to="/notification/broadcast/create"
                        primary
                        label="Create new Notification"
                    />
                </div>
            </div>
            <NotificationBroadcastMessageStatsDialog />
            <Table
                header={<TableHeader labels={['Title', 'Status', 'Actions']} />}
            >
                {notificationBroadcastMessages.map((bm) => (
                    <TableRow
                        key={bm.id}
                        contents={[
                            <TextTableCell
                                primary={bm.title}
                                secondary={bm.body}
                            />,
                            <TextTableCell
                                primary={<StatusChip state={bm.state} />}
                                secondary={(bm.signedOffBy || []).join(',')}
                            />,
                            <div className="inline-flex">
                                <Button
                                    label={
                                        bm.state ===
                                        NotificationMessageBroadcastState.DRAFT
                                            ? 'Edit'
                                            : 'View'
                                    }
                                    secondary
                                    to={`/notification/broadcast/${bm.id}/edit`}
                                />
                                {bm.state ===
                                    NotificationMessageBroadcastState.SENT && (
                                    <Button
                                        label="View Stats"
                                        secondary
                                        onClick={() =>
                                            props.enterViewStatsMode(bm.id)
                                        }
                                    />
                                )}
                                <SendNotificationButton id={bm.id} />
                            </div>,
                        ]}
                    />
                ))}
            </Table>
        </Scrollable>
    );
};

export default connector(ChallengeListInner);

const StatusChip: React.FunctionComponent<{
    state: NotificationMessageBroadcastState;
}> = ({ state }) => {
    if (state === NotificationMessageBroadcastState.DRAFT) {
        return (
            <span className="text-sm flex items-center space-x-2 -ml-4">
                <span className="w-2 h-2 bg-yellow-500 rounded-full" />
                <span>Draft</span>
            </span>
        );
    } else if (state === NotificationMessageBroadcastState.SENT) {
        return (
            <span className="text-sm flex items-center space-x-2 -ml-4">
                <span className="w-2 h-2 bg-green-500 rounded-full" />
                <span>Sent</span>
            </span>
        );
    } else if (state === NotificationMessageBroadcastState.ROLLOUT) {
        return (
            <span className="text-sm flex items-center space-x-2 -ml-4">
                <span className="w-2 h-2 bg-blue-500 rounded-full" />
                <span>Rollout</span>
            </span>
        );
    } else {
        return (
            <span className="text-sm flex items-center space-x-2 -ml-4">
                <span className="w-2 h-2 bg-blue-500 rounded-full" />
                <span>Unknown</span>
            </span>
        );
    }
};
