import {
    Plan3tRichText,
    RTElement,
    RTElementType,
    RTNode,
} from '../api/model/richtext';
import { Any } from '../api/google/protobuf/any';
import { DoubleValue, StringValue } from '../api/google/protobuf/wrappers';
import { CSSProperties } from 'react';
import cx from 'classnames';
import { int64ToHex } from '../common/proto_conv';
import { usePartnerProfile } from '../partner/store';

function Column({ element }: { element: RTElement }) {
    const gap =
        'gap' in element.attributes
            ? Any.unpack(element.attributes['gap'], DoubleValue).value
            : 0;
    return (
        <div className="flex flex-col">
            {element.children.map((node, i) => (
                <div key={i} style={{ marginTop: `${i === 0 ? 0 : gap}px` }}>
                    <RichTextNode node={node} />
                </div>
            ))}
        </div>
    );
}

const resolveStyle = (style: string | undefined): CSSProperties => {
    const neutral500 = '#64748B';
    const neutral700 = '#344155';

    switch (style) {
        case 'headline1':
            return {
                fontSize: '24px',
                fontWeight: 'bold',
                color: neutral700,
            };
        case 'headline2':
            return {
                fontSize: '20px',
                fontWeight: 'bold',
                color: neutral700,
            };
        case 'headline3':
            return {
                fontSize: '18px',
                fontWeight: 'bold',
                color: neutral700,
            };
        case 'headline4':
            return {
                fontSize: '16px',
                fontWeight: 'bold',
                color: neutral700,
            };
        case 'headline5':
            return {
                fontSize: '14px',
                fontWeight: 'bold',
                color: neutral700,
            };
        case 'headline6':
            return {
                fontSize: '12px',
                fontWeight: 'bold',
                color: neutral500,
            };
        case 'body1':
            return {
                fontSize: '14px',
                lineHeight: '24px',
                color: neutral700,
            };
        case 'body2':
            return {
                fontSize: '12px',
                lineHeight: '16px',
                color: neutral700,
            };
        case 'caption':
            return {
                fontSize: '12px',
                color: neutral500,
            };
        default:
            return {};
    }
};

function Paragraph({ element }: { element: RTElement }) {
    const style = Any.unpack(element.attributes['style'], StringValue).value;

    return (
        <p style={resolveStyle(style)}>
            {element.children.map((node, i) => (
                <RichTextNode key={i} node={node} />
            ))}
        </p>
    );
}

function List({ element }: { element: RTElement }) {
    const style = Any.unpack(element.attributes['style'], StringValue).value;
    const marker = Any.unpack(element.attributes['marker'], StringValue).value;

    return (
        <ul
            className={cx({
                'list-disc': marker === 'disc',
                'list-decimal': marker === 'decimal',
                'list-check': marker === 'check',
            })}
            style={{
                paddingInlineStart: '16px',
                ...resolveStyle(style),
            }}
        >
            {element.children.map((node, i) => (
                <li key={i}>
                    <RichTextNode key={i} node={node} />
                </li>
            ))}
        </ul>
    );
}

function Image({ element }: { element: RTElement }) {
    if (element.type !== RTElementType.image) {
        return <span>ERROR</span>;
    }
    const url = Any.unpack(element.attributes['url'], StringValue).value;
    if (!url) {
        return <span>Please select an image!</span>;
    }
    return <img src={url} alt="" />;
}

function PartnerRef({ element }: { element: RTElement }) {
    const id = Any.unpack(element.attributes['id'], StringValue).value;
    const partner = usePartnerProfile(id);
    if (element.type !== RTElementType.partner_ref) {
        return <span>ERROR</span>;
    }
    if (!id) {
        return <span>Please select a partner!</span>;
    }

    return (
        <div className="flex flex-row items-center space-x-4 py-2">
            <div className="w-8 h-8 rounded-full bg-gray-200" />
            <div className="flex-1 flex flex-col">
                <span style={resolveStyle('headline5')}>
                    {partner?.name || '--'}
                </span>
                <span style={resolveStyle('body2')} className="italic">
                    Tagline (only visible in the app)
                </span>
            </div>
            <div>-&gt;</div>
        </div>
    );
}

function RichTextNode({ node }: { node: RTNode }) {
    if (node.value.oneofKind === 'text') {
        const { bold, italic, color } = node.value.text;
        return (
            <span
                className={cx('whitespace-pre-wrap', {
                    'font-bold': bold,
                    italic: italic,
                })}
                style={{
                    ...(color !== 0 ? { color: int64ToHex(color) } : {}),
                }}
            >
                {node.value.text.text}
            </span>
        );
    }
    if (node.value.oneofKind !== 'element') {
        return <span>Cannot render {JSON.stringify(node)}</span>;
    }
    switch (node.value.element.type) {
        case RTElementType.column:
            return <Column element={node.value.element} />;
        case RTElementType.paragraph:
            return <Paragraph element={node.value.element} />;
        case RTElementType.image:
            return <Image element={node.value.element} />;
        case RTElementType.list:
            return <List element={node.value.element} />;
        case RTElementType.partner_ref:
            return <PartnerRef element={node.value.element} />;
    }
    return <span>Cannot render {JSON.stringify(node)}</span>;
}

export default function RichTextRenderer(props: { richText: Plan3tRichText }) {
    return (
        <RichTextNode
            node={{
                value: {
                    oneofKind: 'element',
                    element: {
                        type: RTElementType.column,
                        attributes: {},
                        children: props.richText.children,
                    },
                },
            }}
        />
    );
}
