import React, { Suspense, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { TextTableCell } from '../common/Table';
import ItemList from '../crud-module/ItemList';
import {
    codeStatsQuery,
    referralCustomCodeActions,
    referralCustomCodeModule,
} from './store';
import ReferralCustomCodeForm from './ReferralCustomCodeForm';
import { Button } from '../common/Button';
import { useAppDispatch } from '../redux/react';
import Modal from '../common/Modal';
import LoadingSpinner from '../common/LoadingSpinner';
import ErrorBoundary from '../common/ErrorBoundary';
import { useRecoilValue } from 'recoil';
import { Timestamp } from '../api/google/protobuf/timestamp';

const ReferralCustomCodeModule: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const copyLink = (code: string) =>
        dispatch(referralCustomCodeActions.copyInviteLink({ code }));

    return (
        <Switch>
            <Route path="/referral/customCode" exact>
                <ItemList
                    module={referralCustomCodeModule}
                    tableHeaders={['Code', 'Actions']}
                >
                    {(item) => {
                        return [
                            <TextTableCell primary={item.code} />,
                            <div className="inline-flex">
                                <Button
                                    label="Copy Invite Link"
                                    secondary
                                    onClick={() => copyLink(item.code)}
                                />
                                <ViewStatsButton code={item.code} />
                            </div>,
                        ];
                    }}
                </ItemList>
            </Route>
            <Route path="/referral/customCode/create" exact>
                <ReferralCustomCodeForm id={undefined} />
            </Route>
            <Route
                path="/referral/customCode/:code/edit"
                exact
                render={({ match }) => (
                    <ReferralCustomCodeForm id={match.params['code']} />
                )}
            />
        </Switch>
    );
};

export default ReferralCustomCodeModule;

function ViewStatsButton(props: { code: string }) {
    const [visible, setVisible] = useState(false);

    return (
        <>
            <Modal
                visible={visible}
                headline={`${props.code} stats`}
                onRequestClose={() => setVisible(false)}
            >
                <ErrorBoundary>
                    <Suspense fallback={<LoadingSpinner />}>
                        <StatsModalContent code={props.code} />
                    </Suspense>
                </ErrorBoundary>
            </Modal>
            <Button
                label="View Stats"
                secondary
                onClick={() => setVisible(true)}
            />
        </>
    );
}

function StatsModalContent(props: { code: string }) {
    const stats = useRecoilValue(codeStatsQuery(props.code));

    return (
        <div className="flex flex-col divide-y">
            <div className="pb-2">
                Pending Invites:{' '}
                <span className="font-bold">{stats.pending}</span>
                <br />
                Completed Invites:{' '}
                <span className="font-bold">{stats.completed}</span>
            </div>
            <div className="text-xs pt-2">
                Created by {stats.createdBy} on{' '}
                {Timestamp.toDate(stats.createdAt!).toDateString()}
            </div>
        </div>
    );
}
