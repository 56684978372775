import {
    FlexBox,
    FlexBoxDirection,
    NumericSideIndicator,
} from '@ui5/webcomponents-react';
import { useRecoilValue } from 'recoil';
import { periodTotalsQuery, postingTypeIndex } from './store';
import { useFormatMoney } from '../common/formatting';

export default function PeriodTotalsSection(props: {
    periodCode: string;
    slot?: string;
}) {
    const totals = useRecoilValue(periodTotalsQuery(props.periodCode));

    const byTypeSorted = Object.entries(totals.byType);
    byTypeSorted.sort(
        (a, b) => postingTypeIndex(a[0]) - postingTypeIndex(b[0]),
    );

    const fmt = useFormatMoney();

    return (
        <FlexBox
            slot={props.slot}
            direction={FlexBoxDirection.Row}
            className="space-x-2"
        >
            <NumericSideIndicator
                number={totals.numPostings}
                titleText="# Postings"
            />
            <NumericSideIndicator
                number={totals.totals?.totals?.map(fmt).join(' | ') ?? '-'}
                titleText="Total"
            />
            {byTypeSorted.map(([typ, totals]) => (
                <NumericSideIndicator
                    number={totals.totals.map(fmt).join(' | ') ?? '-'}
                    titleText={typ}
                />
            ))}
        </FlexBox>
    );
}
