import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import CreateVoucherForm from './CreateVoucherForm';

const VoucherModule: React.FunctionComponent = () => {
    return (
        <Switch>
            <Route path="/voucher" exact>
                <Redirect to="/voucher/create" />
            </Route>
            <Route path="/voucher/create">
                <CreateVoucherForm />
            </Route>
        </Switch>
    );
};

export default VoucherModule;
