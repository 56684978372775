import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { ulid } from 'ulid';
import {
    GetTipQuizDraftResponse,
    GetTipSlideDraftsResponse,
    SaveTipQuizDraftRequest,
    SaveTipSlideDraftsRequest,
} from '../api/model/backoffice';
import { ImageResource } from '../api/model/core';
import { Duration } from '../api/google/protobuf/duration';
import { Timestamp } from '../api/google/protobuf/timestamp';
import { DoubleValue, StringValue } from '../api/google/protobuf/wrappers';
import { TipQuizDraft, TipSlideDraft } from '../api/model/tip';
import { AppState } from '../redux/AppStore';
import {
    emptyStandardLayoutOne,
    standardLayoutToProtoRichText,
    StandardLayoutType,
} from './layouts';
import { snackbarActions } from '../snackbar/store';

type TipSlideEditorState = {
    tipID: string | undefined;
    slides: Array<TipSlideDraft> | undefined;
    slideState: Array<TipSlideDraft> | undefined;

    isFetching: boolean;
    hasUnsavedChanges: boolean;
};

const initialTipSlideState: TipSlideEditorState = {
    tipID: undefined,
    slides: undefined,
    slideState: undefined,
    isFetching: false,
    hasUnsavedChanges: false,
};

const typeRegistry = [DoubleValue, StringValue];

const fetchSlides = createAsyncThunk(
    'tipSlideEditor/fetch',
    async (args: { id: string }): Promise<TipSlideDraft[]> => {
        const raw = await axios.get<Object>(`/tip/${args.id}/slides`);
        const res = GetTipSlideDraftsResponse.fromJson(raw.data as any, {
            typeRegistry,
        });
        return res.slides;
    },
);

const saveSlides = createAsyncThunk(
    'tipSlideEditor/save',
    async (_, { getState }): Promise<void> => {
        const { tipID, slideState } = (getState() as AppState).tipSlideEditor;
        const req = SaveTipSlideDraftsRequest.toJson(
            {
                tipId: tipID!,
                slides: slideState || [],
            },
            { typeRegistry },
        );
        await axios.post(`/tip/${tipID || '-'}/slides`, req);
    },
);

const tipSlideEditorSlice = createSlice({
    name: 'tipSlideEditor',
    initialState: initialTipSlideState,
    reducers: {
        addSlide(state, action: PayloadAction<{ type: StandardLayoutType }>) {
            if (!state.slideState) {
                state.slideState = [];
            }
            state.slideState.push({
                id: ulid(),
                position: state.slideState?.length ?? 0,
                duration: Duration.create({ seconds: 5 }),
                content: standardLayoutToProtoRichText(emptyStandardLayoutOne),
                createdAt: Timestamp.now(),
                createdBy: '',
                modifiedAt: Timestamp.now(),
                modifiedBy: '',
            });
            state.hasUnsavedChanges = true;
        },
        removeSlide(state, action: PayloadAction<{ id: string }>) {
            state.slideState = state.slideState?.filter(
                ({ id }) => id !== action.payload.id,
            );
            state.hasUnsavedChanges = true;
        },
        reorderSlides(
            state,
            action: PayloadAction<{ id: string; amount: number }>,
        ) {
            if (state.slideState === undefined) {
                return;
            }
            const idx = state.slideState.findIndex(
                (s) => s.id === action.payload.id,
            );

            let newIndex = idx + action.payload.amount;
            if (newIndex === idx) {
                return;
            }
            if (newIndex < 0) {
                newIndex = 0;
            }
            if (newIndex >= state.slideState.length) {
                newIndex = state.slideState.length - 1;
            }

            const slide = state.slideState[idx];

            state.slideState.splice(idx, 1);
            state.slideState.splice(newIndex, 0, slide);

            for (let i = 0; i < state.slideState.length; i++) {
                state.slideState[i].position = i;
            }
        },
        updateSlide(
            state,
            action: PayloadAction<
                Partial<TipSlideDraft> & Pick<TipSlideDraft, 'id'>
            >,
        ) {
            if (state.slideState === undefined) {
                return;
            }
            const index = state.slideState.findIndex(
                ({ id }) => id === action.payload.id,
            );
            if (index < 0) {
                console.error('slide not found');
                return;
            }
            state.slideState[index] = {
                ...state.slideState[index],
                ...action.payload,
            };
            state.hasUnsavedChanges = true;
        },
        resetState(state) {
            state.slideState = state.slides || [];
            state.hasUnsavedChanges = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchSlides.pending, (state, action) => {
            state.isFetching = true;
            if (state.tipID !== action.meta.arg.id) {
                state.tipID = action.meta.arg.id;
                state.slideState = undefined;
                state.slides = undefined;
                state.hasUnsavedChanges = false;
            }
        });
        builder.addCase(fetchSlides.rejected, (state, action) => {
            state.isFetching = false;
            state.slides = undefined;
            state.slideState = undefined;
            state.hasUnsavedChanges = false;
        });
        builder.addCase(fetchSlides.fulfilled, (state, action) => {
            state.slides = action.payload;
            state.slideState = [...action.payload].sort(
                (a, b) => a.position - b.position,
            );
            state.hasUnsavedChanges = false;
        });
        builder.addCase(saveSlides.fulfilled, (state, action) => {
            state.slides = state.slideState;
            state.hasUnsavedChanges = false;
        });
    },
});

export const tipSlideEditorReducer = tipSlideEditorSlice.reducer;
export const tipSlideEditorActions = {
    ...tipSlideEditorSlice.actions,
    fetchSlides,
    saveSlides,
};
export const tipSlideEditorSelectors = {
    isFetching: (s: AppState) => s.tipSlideEditor.isFetching,
    getSlides: (s: AppState) => s.tipSlideEditor.slideState,
    hasUnsavedChanges: (s: AppState) => s.tipSlideEditor.hasUnsavedChanges,
};

type TipQuizEditorState = {
    tipID: string | undefined;
    quiz: TipQuizDraft | undefined;
    quizState: TipQuizDraft | undefined;

    isFetching: boolean;
    hasUnsavedChanges: boolean;
};

const initialTipQuizState: TipQuizEditorState = {
    tipID: undefined,
    quiz: undefined,
    quizState: undefined,
    isFetching: false,
    hasUnsavedChanges: false,
};

const fetchQuiz = createAsyncThunk(
    'tipQuizEditor/fetch',
    async (args: { id: string }): Promise<TipQuizDraft | undefined> => {
        const raw = await axios.get<Object>(`/tip/${args.id}/quiz`);
        const res = GetTipQuizDraftResponse.fromJson(raw.data as any, {
            typeRegistry,
        });
        return res.quiz ?? { ...initialQuiz };
    },
);

const saveQuiz = createAsyncThunk(
    'tipQuizEditor/save',
    async (_, { getState, dispatch }): Promise<void> => {
        const { tipID, quizState } = (getState() as AppState).tipQuizEditor;
        if (quizState?.answers.some((a) => !a)) {
            dispatch(
                snackbarActions.sendMessage({
                    title: 'Invalid answers',
                    subtitle: 'At least one answer is still empty',
                    type: 'warning',
                }),
            );
            throw new Error('Invalid answers');
        }
        const req = SaveTipQuizDraftRequest.toJson(
            {
                tipId: tipID!,
                quiz: quizState,
            },
            { typeRegistry },
        );
        await axios.post(`/tip/${tipID || '-'}/quiz`, req);
    },
);

const initialQuiz: TipQuizDraft = {
    id: '',
    correctAnswerIndex: 0,
    question: 'How much is the fish?',
    answers: ['A', 'B', 'C', 'D'],
    createdAt: Timestamp.now(),
    createdBy: '',
    modifiedAt: Timestamp.now(),
    modifiedBy: '',
};

const tipQuizEditorSlice = createSlice({
    name: 'tipQuizEditor',
    initialState: initialTipQuizState,
    reducers: {
        initQuiz(state) {
            state.quizState = state.quiz ?? { ...initialQuiz, id: ulid() };
            state.hasUnsavedChanges = true;
        },
        setQuestion(state, action: PayloadAction<string>) {
            if (!state.quizState) {
                return;
            }

            state.quizState.question = action.payload;
            state.hasUnsavedChanges = true;
        },
        setAnswer(state, action: PayloadAction<{ idx: number; text: string }>) {
            if (
                !state.quizState ||
                state.quizState.answers.length < action.payload.idx + 1
            ) {
                return;
            }
            state.quizState.answers[action.payload.idx] = action.payload.text;
            state.hasUnsavedChanges = true;
        },
        addAnswer(state) {
            if (!state.quizState) {
                return;
            }

            state.quizState?.answers.push('');
            state.hasUnsavedChanges = true;
        },
        removeAnswer(state, action: PayloadAction<number>) {
            if (!state.quizState) {
                return;
            }

            state.quizState?.answers.splice(action.payload, 1);
            state.hasUnsavedChanges = true;
        },
        setCorrectAnswerIndex(state, action: PayloadAction<number>) {
            if (!state.quizState) {
                return;
            }

            state.quizState.correctAnswerIndex = action.payload;
            state.hasUnsavedChanges = true;
        },
        setBackgroundImage(
            state,
            action: PayloadAction<ImageResource | undefined>,
        ) {
            if (!state.quizState) {
                return;
            }
            state.quizState.backgroundImage = action.payload;
            state.hasUnsavedChanges = true;
        },
        resetState(state) {
            state.quizState = state.quiz ?? { ...initialQuiz, id: ulid() };
            state.hasUnsavedChanges = false;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchQuiz.pending, (state, action) => {
            state.isFetching = true;
            if (state.tipID !== action.meta.arg.id) {
                state.tipID = action.meta.arg.id;
                state.quizState = undefined;
                state.quiz = undefined;
                state.hasUnsavedChanges = false;
            }
        });
        builder.addCase(fetchQuiz.rejected, (state, action) => {
            state.isFetching = false;
            state.quiz = undefined;
            state.quizState = undefined;
            state.hasUnsavedChanges = false;
        });
        builder.addCase(fetchQuiz.fulfilled, (state, action) => {
            state.quiz = action.payload;
            state.quizState = action.payload;
            state.hasUnsavedChanges = false;
        });
        builder.addCase(saveQuiz.fulfilled, (state, action) => {
            state.quiz = state.quizState;
            state.hasUnsavedChanges = false;
        });
    },
});
export const tipQuizEditorReducer = tipQuizEditorSlice.reducer;
export const tipQuizEditorActions = {
    ...tipQuizEditorSlice.actions,
    fetchQuiz,
    saveQuiz,
};
export const tipQuizEditorSelectors = {
    isFetching: (s: AppState) => s.tipQuizEditor.isFetching,
    getQuiz: (s: AppState) => s.tipQuizEditor.quizState,
    hasUnsavedChanges: (s: AppState) => s.tipQuizEditor.hasUnsavedChanges,
};
