import axios from 'axios';
import { selector, selectorFamily } from 'recoil';
import * as Auditor from '../api/auditor/rpc/auditor';

export const auditorResultsOverviewQuery = selector({
    key: 'auditor/auditorResultsOverviewQuery',
    get: async () => {
        const res = await axios.post(
            '/one.plan3t.core.auditor.rpc.Auditor/GetResultOverview',
            {},
        );
        return Auditor.GetResultOverviewResponse.fromJson(res.data);
    },
});

export const auditorAuditNamesQuery = selector({
    key: 'auditor/auditorAuditNamesQuery',
    get: ({ get }) => {
        const all = get(auditorResultsOverviewQuery).recentRunResults;
        const names = new Set<string>();
        for (let rr of all) {
            names.add(rr.auditName);
        }
        return Array.from(names);
    },
});

export const auditorRecentRunsQuery = selectorFamily({
    key: 'auditor/auditorRecentRunsQuery',
    get:
        (name: string) =>
        ({ get }) => {
            const runs = get(
                auditorResultsOverviewQuery,
            ).recentRunResults.filter((rr) => rr.auditName === name);
            runs.sort((a, b) => b.id - a.id);
            return runs;
        },
});

export const auditorLastRunQuery = selectorFamily({
    key: 'auditor/auditorLastRunQuery',
    get:
        (name: string) =>
        ({ get }) =>
            get(auditorRecentRunsQuery(name))[0],
});
