import { useRecoilValue } from 'recoil';
import { singleDocumentQuery } from './store';
import { Suspense } from 'react';
import LoadingSpinner from '../common/LoadingSpinner';
import ErrorBoundary from '../common/ErrorBoundary';
import { Bar, Button, Dialog } from '@ui5/webcomponents-react';

type Props = {
    id: string | null;
    show: boolean;
    onClose: () => void;
};

export default function DocumentPreviewDialog(props: Props) {
    return (
        <Dialog
            headerText={`Document ${props.id ?? '-'}`}
            onAfterClose={props.onClose}
            open={props.show}
            footer={
                <Bar
                    startContent={
                        props.id && (
                            <>
                                <DownloadButton id={props.id} />
                                <CopyButton id={props.id} />
                            </>
                        )
                    }
                    endContent={<Button onClick={props.onClose}>Close</Button>}
                />
            }
            draggable
            resizable
        >
            <ErrorBoundary>
                <Suspense fallback={<LoadingSpinner />}>
                    {props.id && <ModalContent id={props.id} />}
                </Suspense>
            </ErrorBoundary>
        </Dialog>
    );
}

function ModalContent(props: { id: string }) {
    const doc = useRecoilValue(singleDocumentQuery(props.id));

    if (doc.mimeType.startsWith('text/')) {
        const text = new TextDecoder().decode(doc.content);
        return (
            <div className="flex">
                <pre className="bg-gray-200 w-full px-2 py-1">{text}</pre>
            </div>
        );
    }

    if (doc.mimeType.startsWith('image/')) {
        const b64 = Buffer.from(doc.content).toString('base64');
        const src = `data:${doc.mimeType};base64, ${b64}`;
        return (
            <div className="flex">
                <img src={src} alt="preview" />
            </div>
        );
    }

    return <span>Cannot display document of type {doc.mimeType}.</span>;
}

function DownloadButton(props: { slot?: string; id: string }) {
    const doc = useRecoilValue(singleDocumentQuery(props.id));

    const download = () => {
        const blob = new Blob([doc.content], { type: doc.mimeType });
        const url = URL.createObjectURL(blob);
        window.open(url);
    };

    return (
        <Button slot={props.slot} onClick={download} icon="download">
            Download
        </Button>
    );
}

function CopyButton(props: { slot?: string; id: string }) {
    const doc = useRecoilValue(singleDocumentQuery(props.id));

    const copy = async () => {
        // https://stackoverflow.com/a/61650940/3941291
        const blob = new Blob([doc.content], { type: 'text/plain' });
        await navigator.clipboard.write([
            new ClipboardItem({ 'text/plain': blob }),
        ]);
    };

    if (!doc.mimeType.startsWith('text')) {
        return null;
    }

    return (
        <Button slot={props.slot} onClick={copy} icon="copy">
            Copy
        </Button>
    );
}
