import { FormSelectField } from '../common/FormFields';
import { selector, useRecoilValue } from 'recoil';
import axios from 'axios';
import { GetTipAuthorDraftsResponse } from '../api/model/backoffice';
import { Suspense } from 'react';
import LoadingSpinner from '../common/LoadingSpinner';

const allTipAuthorsQuery = selector({
    key: 'tip/allAuthors',
    get: async () => {
        const res = await axios.get(`/tipAuthor?page_size=100`);
        return GetTipAuthorDraftsResponse.fromJson(res.data).items;
    },
});

export default function TipAuthorSelector() {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <TipAuthorSelectorInner />
        </Suspense>
    );
}

function TipAuthorSelectorInner() {
    const all = useRecoilValue(allTipAuthorsQuery);
    return (
        <FormSelectField
            name="authorId"
            label="Author (optional)"
            options={[
                {
                    label: 'None (default)',
                    value: '',
                },
                ...all.map((a) => ({
                    label: a.displayName,
                    value: a.id,
                })),
            ]}
        />
    );
}
