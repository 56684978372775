import { tipModule } from './store';
import {
    FormCO2SavingPotentialField,
    FormCoreIndustryField,
    FormNumberField,
    FormSingleImageField,
    FormTextField,
} from '../common/FormFields';
import ItemForm from '../crud-module/ItemForm';
import React from 'react';
import FormPartnerAssociationField from '../common/FormPartnerAssociationField';
import TipAuthorSelector from './TipAuthorSelector';

export default function TipPreviewForm(props: { id: string | undefined }) {
    return (
        <ItemForm module={tipModule} id={props.id}>
            <FormTextField name="title" label="Title" />
            <FormTextField name="tagline" label="TagLine" />
            <FormSingleImageField
                name="image"
                label="Image"
                message="A preview image which will be displayed before a user start viewings a tip"
            />
            <FormTextField name="category" label="Category" />
            <FormCoreIndustryField name="coreIndustry" label="Impact Area" />
            <FormNumberField
                name="award.amount"
                label="Plan3t Points"
                min={0}
            />
            <FormPartnerAssociationField
                single
                name="partnerId"
                label="Author/Sponsor"
            />
            <FormPartnerAssociationField
                name="associatedPartnerIds"
                label="Related Partners (not sponsored)"
            />
            <TipAuthorSelector />
            <FormCO2SavingPotentialField
                name="recommendedPotentials"
                label="Recommended Potentials"
            />
        </ItemForm>
    );
}
