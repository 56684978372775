import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { deploymentEnvironment } from './env';
import { auth } from './auth/firebase';

export const tmpGetReviewLink = (
    payloadID: string,
    payloadType: string,
    magicLinkID: string,
) => {
    let base;
    switch (deploymentEnvironment) {
        case 'development':
            base = 'http://localhost:8080';
            break;
        case 'staging':
            base = 'https://api.staging.plan3t.one';
            break;
        case 'production':
            base = 'https://api.plan3t.one';
            break;
    }

    return `${base}/review/type/${payloadType}/id/${payloadID}/magiclinkid/${magicLinkID}`;
};

export const getPartnerManagerUrl = () => {
    switch (deploymentEnvironment) {
        case 'development':
            return 'http://localhost:3000';
        case 'staging':
            return 'https://partnermanager.staging.plan3t.one';
        case 'production':
            return 'https://partnermanager.plan3t.one';
    }
};

export const init = () => {
    switch (deploymentEnvironment) {
        case 'development':
            axios.defaults.baseURL = 'http://localhost:8080/backoffice/';
            break;
        case 'staging':
            axios.defaults.baseURL =
                'https://api.staging.plan3t.one/backoffice/';
            break;
        case 'production':
            axios.defaults.baseURL = 'https://api.plan3t.one/backoffice/';
            break;
    }

    axios.interceptors.request.use(async (config: AxiosRequestConfig) => {
        const token = await auth.currentUser?.getIdToken();
        if (!config.headers) {
            config.headers = {};
        }
        config.headers['Authorization'] = `Bearer ${token}`;
        config.headers['X-App-Version'] = process.env.REACT_APP_GIT_SHA || '';
        return config;
    });
    axios.interceptors.response.use(undefined, (error: any) => {
        if (error.response) {
            const res = error.response as AxiosResponse;
            if ('errors' in res.data) {
                return Promise.reject(new Error(res.data.errors.join(', ')));
            }
        }
        return Promise.reject(error);
    });
};
