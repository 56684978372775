import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import ReviewView from './review/ReviewView';
import Sidebar from './Sidebar';
import PartnerModule from './partner/PartnerModule';
import AnalyticsDashboard from './analytics/AnalyticsDashboard';
import ChallengeModule from './challenge/ChallengeModule';
import SendTestEmail from './debug/SendTestEmail';
import PendingReviews from './review/PendingReviews';
import SendPartnerManagerInvite from './debug/SendPartnerManagerInvite';
import RewardModule from './reward/RewardModule';
import PaymentModule from './payment/PaymentModule';
import NotificationModule from './notification/NotificationModule';
import VoucherModule from './voucher/VoucherModule';
import LotteryModule from './lottery/LotteryModule';
import GenerateInviteToken from './debug/GenerateInviteToken';
import TipModule from './tip/TipModule';
import FeedModule from './feed/FeedModule';
import CashbackTicketModule from './cashback/CashbackTicketModule';
import ReferralCustomCodeModule from './referral/ReferralCustomCodeModule';
import CashbackLogModule from './cashback/CashbackLogModule';
import OpenBankingWaitlistModule from './openbankingwaitlist/OpenBankingWaitlistModule';
import AssessmentModule from './assessment/AssessmentModule';
import BillingModule from './billing/BillingModule';
import LoadingSpinner from './common/LoadingSpinner';
import ErrorBoundary from './common/ErrorBoundary';
import AccountingModule from './accounting/AccountingModule';
import CashbackRateModule from './cashback/CashbackRateModule';
import Overview from './Overview';
import DeepLinkBuilderModule from './deeplink_builder/DeepLinkBuilderModule';
import AuditorModule from './auditor/AuditorModule';
import GeneratePasswordResetLink from './user/GeneratePasswordResetLink';
import ReportModule from './report/ReportModule';
import CategorizerModule from './categorizer/CategorizerModule';
import LedgerModule from './ledger/LedgerModule';

const Dashboard: React.FunctionComponent = () => {
    return (
        <div className="flex items-stretch h-screen">
            <Sidebar />

            <ErrorBoundary>
                <Suspense fallback={<LoadingSpinner />}>
                    <main className="flex-1">
                        <Switch>
                            <Route path="/" exact>
                                <Redirect to="/overview" />
                            </Route>
                            <Route path="/overview">
                                <Overview />
                            </Route>
                            <Route path="/accounting">
                                <AccountingModule />
                            </Route>
                            <Route path="/assessment">
                                <AssessmentModule />
                            </Route>
                            <Route path="/notification">
                                <NotificationModule />
                            </Route>
                            <Route path="/openbanking/waitlist">
                                <OpenBankingWaitlistModule />
                            </Route>
                            <Route path="/partner">
                                <PartnerModule />
                            </Route>
                            <Route path="/feed">
                                <FeedModule />
                            </Route>
                            <Route path="/challenge">
                                <ChallengeModule />
                            </Route>
                            <Route path="/categorizer">
                                <CategorizerModule />
                            </Route>
                            <Route path="/analytics">
                                <AnalyticsDashboard />
                            </Route>
                            <Route path="/audit">
                                <AuditorModule />
                            </Route>
                            <Route path="/billing">
                                <BillingModule />
                            </Route>
                            <Route path="/lottery">
                                <LotteryModule />
                            </Route>
                            <Route path="/referral/customCode">
                                <ReferralCustomCodeModule />
                            </Route>
                            <Route path="/reward">
                                <RewardModule />
                            </Route>
                            <Route path="/review">
                                <PendingReviews />
                            </Route>
                            <Route path="/review/:type/:id/:magiclinkid">
                                <ReviewView />
                            </Route>
                            <Route path="/payment">
                                <PaymentModule />
                            </Route>
                            <Route path="/tip">
                                <TipModule />
                            </Route>
                            <Route path="/voucher">
                                <VoucherModule />
                            </Route>
                            <Route path="/cashback/ticket">
                                <CashbackTicketModule />
                            </Route>
                            <Route path="/cashback/log">
                                <CashbackLogModule />
                            </Route>
                            <Route path="/cashback/rates">
                                <CashbackRateModule />
                            </Route>
                            <Route path="/deeplinkBuilder">
                                <DeepLinkBuilderModule />
                            </Route>
                            <Route path="/user/resetPassword">
                                <GeneratePasswordResetLink />
                            </Route>
                            <Route path="/debug/email">
                                <SendTestEmail />
                            </Route>
                            <Route path="/debug/sendInvite">
                                <SendPartnerManagerInvite />
                            </Route>
                            <Route path="/debug/generateInviteToken">
                                <GenerateInviteToken />
                            </Route>
                            <Route path="/report">
                                <ReportModule />
                            </Route>
                            <Route path="/ledger">
                                <LedgerModule />
                            </Route>
                            <Route path="*">404 - Not Found</Route>
                        </Switch>
                    </main>
                </Suspense>
            </ErrorBoundary>
        </div>
    );
};

export default Dashboard;
