import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ItemCategoryAuthoring } from '../api/model/backoffice';
import {
    DragDropContext,
    Draggable,
    Droppable,
    DropResult,
} from 'react-beautiful-dnd';
import { CoreIndustry } from '../api/model/core';
import { ulid } from 'ulid';
import { Timestamp } from '../api/google/protobuf/timestamp';
import { Button } from './Button';
import LoadingSpinner from './LoadingSpinner';
import cx from 'classnames';
import * as React from 'react';
import Scrollable from './Scrollable';
import usePromise from '../hooks/usePromise';
import { int64ToHex } from './proto_conv';
import { snackbarActions } from '../snackbar/store';
import { useAppDispatch } from '../redux/react';

type Props = {
    title: ReactNode;
    fetchCategories: () => Promise<ItemCategoryAuthoring[]>;
    fetchAllItemIds: () => Promise<string[]>;
    write: (category: ItemCategoryAuthoring) => Promise<void>;

    renderItem: (
        itemId: string,
        index: number,
        categoryId: string,
    ) => ReactNode;

    deepLinkBuilder?: (id: string) => string;
};

const colorOptions = {
    default: 0,
    energy: 0xfff59e0b,
    mobility: 0xfff43f5e,
    nutrition: 0xffa855f7,
    consumption: 0xff6366f1,
};

const iconOptions = {
    none: '',
    energy: '::housing',
    mobility: '::mobility',
    nutrition: '::nutrition',
    consumption: '::consumption',
};

const _coreIndustryIcons: Record<string, string> = {
    '::housing': 'home',
    '::mobility': 'location_on',
    '::nutrition': 'restaurant_menu',
    '::consumption': 'shopping_bag',
};

const iconUIResolver = (key: string) => _coreIndustryIcons[key] ?? key;

function ItemCategoryEditor(props: Props) {
    const emptyArray = useMemo(() => [], []);
    const {
        value: categories,
        reload: reloadCategories,
        error,
    } = usePromise(
        props.fetchCategories,
        emptyArray as ItemCategoryAuthoring[],
    );
    const { value: allItemIds } = usePromise(
        props.fetchAllItemIds,
        emptyArray as string[],
    );

    const [copyMode, setCopyMode] = useState(false);

    const [draft, setDraft] = useState<ItemCategoryAuthoring[]>([]);
    const [dirtyFlags, markAsDirty, resetDirtyFlags] = useDirtyFlags();

    useEffect(() => {
        setDraft(categories);
        resetDirtyFlags();
    }, [setDraft, categories, resetDirtyFlags]);

    const alreadyUsed = useMemo(() => {
        return new Set(draft.flatMap((cat) => cat.itemIds));
    }, [draft]);

    const unusedItems = allItemIds.filter((id) => !alreadyUsed.has(id));

    const canSave = useMemo(() => {
        return (
            dirtyFlags.size > 0 && draft.every((cat) => cat.itemIds.length > 0)
        );
    }, [dirtyFlags, draft]);

    const _updateSingle = (
        categoryId: string,
        updater: (category: ItemCategoryAuthoring) => ItemCategoryAuthoring,
    ) => {
        const dirty: string[] = [];
        setDraft(
            draft.map((category) => {
                if (category.id !== categoryId) {
                    return category;
                }

                dirty.push(category.id);
                return updater(category);
            }),
        );
        markAsDirty(...dirty);
    };

    const toggleActive = (categoryId: string) => {
        _updateSingle(categoryId, (c) => ({
            ...c,
            active: !c.active,
        }));
    };

    const onChangeIcon = (categoryId: string, iconName: string) => {
        _updateSingle(categoryId, (c) => ({
            ...c,
            iconName,
        }));
    };

    const onChangeColor = (categoryId: string, color: number) => {
        _updateSingle(categoryId, (c) => ({
            ...c,
            color,
        }));
    };

    const onChangeTitle = (categoryId: string, title: string) => {
        _updateSingle(categoryId, (c) => ({
            ...c,
            title,
        }));
    };

    const onChangeNumAboveTheFold = (
        categoryId: string,
        numAboveTheFold: number,
    ) => {
        if (numAboveTheFold < 1) {
            numAboveTheFold = 1;
        }
        _updateSingle(categoryId, (c) => ({
            ...c,
            numAboveTheFold,
        }));
    };

    const onChangeCoreIndustry = (
        categoryId: string,
        coreIndustry: CoreIndustry,
    ) => {
        _updateSingle(categoryId, (c) => ({
            ...c,
            coreIndustry,
        }));
    };

    const onAddCategory = () => {
        const id = ulid();
        setDraft([
            ...draft,
            {
                id,
                coreIndustry: CoreIndustry.UnknownIndustry,
                title: '',
                iconName: '',
                color: 0,
                active: false,
                itemIds: [],
                numAboveTheFold: 4,
                createdAt: Timestamp.now(),
                createdBy: '',
                modifiedAt: Timestamp.now(),
                modifiedBy: '',
                position: draft.length,
            },
        ]);
        markAsDirty(id);
    };

    const onMove = (categoryId: string, dir: -1 | 1) => {
        const current = draft.find(({ id }) => id === categoryId)!.position;

        if (dir === -1 && current === 0) {
            return;
        }
        if (dir === 1 && current === draft.length - 1) {
            return;
        }

        const dirty: string[] = [];
        setDraft(
            reorder(draft, current, current + dir).map((cat, index) => {
                if (cat.position !== index) {
                    dirty.push(cat.id);
                }
                return {
                    ...cat,
                    position: index,
                };
            }),
        );
        markAsDirty(...dirty);
    };

    const [isSaving, setIsSaving] = useState(false);
    const onSave = async () => {
        try {
            setIsSaving(true);
            const toWrite = Array.from(dirtyFlags)
                .map((id) => draft.find((cat) => cat.id === id))
                .filter(
                    (category): category is ItemCategoryAuthoring =>
                        category !== undefined,
                );
            await Promise.all(toWrite.map(props.write));
            await reloadCategories();
        } finally {
            setIsSaving(false);
        }
    };

    const onReset = () => {
        setDraft(categories);
        resetDirtyFlags();
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const isCrossLane =
            result.destination.droppableId !== result.source.droppableId;

        if (!isCrossLane && result.destination.index === result.source.index) {
            return;
        }

        const dirty: string[] = [];
        if (isCrossLane) {
            const itemId =
                result.source.droppableId === 'SOURCE'
                    ? unusedItems[result.source.index]
                    : draft.find(
                          (category) =>
                              category.id === result.source.droppableId,
                      )?.itemIds[result.source.index];
            if (!itemId) {
                return;
            }
            setDraft(
                draft.map((category) => {
                    if (category.id === result.source!.droppableId) {
                        dirty.push(category.id);
                        return {
                            ...category,
                            itemIds:
                                copyMode &&
                                result.destination?.droppableId !== 'SOURCE'
                                    ? category.itemIds
                                    : category.itemIds.filter(
                                          (id) => id !== itemId,
                                      ),
                        };
                    }

                    if (category.id === result.destination!.droppableId) {
                        const itemIds = Array.from(category.itemIds);

                        itemIds.splice(result.destination!.index, 0, itemId);

                        dirty.push(category.id);
                        return {
                            ...category,
                            itemIds: Array.from(new Set(itemIds)),
                        };
                    }

                    return category;
                }),
            );
        } else {
            setDraft(
                draft.map((category) => {
                    if (category.id !== result.destination!.droppableId) {
                        return category;
                    }

                    dirty.push(category.id);
                    return {
                        ...category,
                        itemIds: reorder(
                            category.itemIds,
                            result.source.index,
                            result.destination!.index,
                        ),
                    };
                }),
            );
        }

        markAsDirty(...dirty);
    };

    const renderItem = (id: string, index: number, categoryId: string) => {
        return (
            <Draggable
                key={categoryId + '#' + id}
                draggableId={categoryId + '#' + id}
                index={index}
            >
                {(provided) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                    >
                        {props.renderItem(id, index, categoryId)}
                    </div>
                )}
            </Draggable>
        );
    };

    const dispatch = useAppDispatch();
    const onCopyDeeplink =
        props.deepLinkBuilder !== undefined
            ? (id: string) => {
                  const deepLink = props.deepLinkBuilder!(id);
                  navigator.clipboard.writeText(deepLink);
                  dispatch(
                      snackbarActions.sendMessage({
                          title: 'DeepLink copied to clipboard',
                          subtitle: deepLink,
                      }),
                  );
              }
            : undefined;

    if (error) {
        return (
            <div>
                <div className="bg-red-100 rounded-md border border-red-300 text-red-900 px-4 py-2 mt-8">
                    Error loading: {(error as any).toString()}
                </div>
                <Button
                    primary
                    label="Try again"
                    onClick={() => reloadCategories()}
                />
            </div>
        );
    }

    return (
        <Scrollable>
            <div className="w-full flex items-center space-x-2 mb-4">
                <h1 className="font-bold text-3xl">{props.title}</h1>
                <div className="flex flex-row space-x-2">
                    <Button
                        primary
                        label="Publish"
                        disabled={!canSave}
                        onClick={onSave}
                        className="my-6"
                    />
                    <Button
                        primary
                        label="Reset"
                        onClick={onReset}
                        className="my-6"
                    />
                </div>
            </div>
            <DragDropContext onDragEnd={onDragEnd}>
                <div className="w-full flex flex-row relative">
                    {isSaving && (
                        <div className="absolute inset-0 bg-black opacity-25 pt-4 flex justify-center">
                            <LoadingSpinner className="h-12 w-12 text-white" />
                        </div>
                    )}
                    <div className="flex flex-col space-y-4 flex-1">
                        <label className="flex flex-row space-x-2 items-center">
                            <input
                                type="checkbox"
                                className="form-checkbox"
                                checked={copyMode}
                                onChange={(e) => setCopyMode(e.target.checked)}
                            />
                            <span>Copy items instead of moving</span>
                        </label>
                        <span className="font-bold">UNUSED</span>
                        <Droppable droppableId="SOURCE" direction="horizontal">
                            {(provided, snapshot) => (
                                <div
                                    ref={provided.innerRef}
                                    className={cx(
                                        'flex items-center space-x-2 h-56 bg-red-50',
                                        {
                                            'bg-red-100':
                                                snapshot.isDraggingOver,
                                        },
                                    )}
                                    {...provided.droppableProps}
                                >
                                    {unusedItems.map((id, index) =>
                                        renderItem(id, index, 'SOURCE'),
                                    )}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        {draft.map((category) => (
                            <div
                                key={category.id}
                                className={cx({
                                    'opacity-50': !category.active,
                                })}
                            >
                                <div className="flex flex-row space-x-2 mb-2">
                                    {category.iconName !== '' && (
                                        <span
                                            className="material-icons text-gray-700"
                                            style={{
                                                ...(category.color !== 0
                                                    ? {
                                                          color: int64ToHex(
                                                              category.color,
                                                          ),
                                                      }
                                                    : {}),
                                            }}
                                        >
                                            {iconUIResolver(category.iconName)}
                                        </span>
                                    )}
                                    <h1
                                        className="font-bold text-xl text-gray-700"
                                        style={{
                                            ...(category.color !== 0
                                                ? {
                                                      color: int64ToHex(
                                                          category.color,
                                                      ),
                                                  }
                                                : {}),
                                        }}
                                    >
                                        {category.title}
                                    </h1>

                                    <div
                                        className={cx(
                                            'rounded border border-gray-100 px-2 py-1 flex flex-wrap space-x-2 items-center space-y-2',
                                            {
                                                'border-yellow-500':
                                                    dirtyFlags.has(category.id),
                                            },
                                        )}
                                    >
                                        <input
                                            type="text"
                                            className="form-input mt-2"
                                            value={category.title}
                                            onChange={(e) =>
                                                onChangeTitle(
                                                    category.id,
                                                    e.target.value,
                                                )
                                            }
                                        />
                                        <select
                                            className="form-select"
                                            value={category.color >>> 0}
                                            onChange={(e) =>
                                                onChangeColor(
                                                    category.id,
                                                    parseInt(
                                                        e.target.value,
                                                        10,
                                                    ),
                                                )
                                            }
                                        >
                                            {Object.entries(colorOptions).map(
                                                ([label, value]) => (
                                                    <option
                                                        key={label}
                                                        value={value}
                                                    >
                                                        Color: {label} (
                                                        {int64ToHex(value)})
                                                    </option>
                                                ),
                                            )}
                                        </select>
                                        <select
                                            className="form-select"
                                            value={category.iconName}
                                            onChange={(e) =>
                                                onChangeIcon(
                                                    category.id,
                                                    e.target.value,
                                                )
                                            }
                                        >
                                            {Object.entries(iconOptions).map(
                                                ([label, value]) => (
                                                    <option
                                                        key={label}
                                                        value={value}
                                                    >
                                                        Icon: {label}
                                                    </option>
                                                ),
                                            )}
                                        </select>
                                        <label className="space-x-2">
                                            <input
                                                type="checkbox"
                                                className="form-checkbox"
                                                onChange={() =>
                                                    toggleActive(category.id)
                                                }
                                                checked={category.active}
                                            />
                                            <span className="text-sm text-gray-700">
                                                Visible in App
                                            </span>
                                        </label>
                                        <input
                                            type="number"
                                            className="form-input"
                                            value={category.numAboveTheFold}
                                            onChange={(e) =>
                                                onChangeNumAboveTheFold(
                                                    category.id,
                                                    parseInt(
                                                        e.target.value,
                                                        10,
                                                    ),
                                                )
                                            }
                                            min={0}
                                        />
                                        <select
                                            className="form-select"
                                            value={category.coreIndustry}
                                            onChange={(e) =>
                                                onChangeCoreIndustry(
                                                    category.id,
                                                    parseInt(
                                                        e.target.value,
                                                        10,
                                                    ),
                                                )
                                            }
                                        >
                                            <option
                                                value={
                                                    CoreIndustry.UnknownIndustry
                                                }
                                            >
                                                Unknown
                                            </option>
                                            <option
                                                value={CoreIndustry.Housing}
                                            >
                                                Energy
                                            </option>
                                            <option
                                                value={CoreIndustry.Mobility}
                                            >
                                                Mobility
                                            </option>
                                            <option
                                                value={CoreIndustry.Nutrition}
                                            >
                                                Nutrition
                                            </option>
                                            <option
                                                value={CoreIndustry.Consumption}
                                            >
                                                Consumption
                                            </option>
                                        </select>
                                        <button
                                            onClick={() =>
                                                onMove(category.id, -1)
                                            }
                                            className="p-2 border border-gray-200 rounded hover:bg-gray-100 material-icons"
                                        >
                                            expand_less
                                        </button>
                                        <button
                                            onClick={() =>
                                                onMove(category.id, 1)
                                            }
                                            className="p-2 border border-gray-200 rounded hover:bg-gray-100 material-icons"
                                        >
                                            expand_more
                                        </button>
                                        ({category.position})
                                        {onCopyDeeplink !== undefined && (
                                            <button
                                                onClick={() =>
                                                    onCopyDeeplink(category.id)
                                                }
                                                className="p-2 border border-gray-200 rounded hover:bg-gray-100"
                                            >
                                                Copy DeepLink
                                            </button>
                                        )}
                                    </div>
                                </div>

                                <Droppable
                                    droppableId={category.id}
                                    direction="horizontal"
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            className={cx(
                                                'flex items-center space-x-2 h-56',
                                                {
                                                    'bg-green-100':
                                                        snapshot.isDraggingOver,
                                                },
                                            )}
                                            {...provided.droppableProps}
                                        >
                                            {category.itemIds.map(
                                                (itemId, index) => (
                                                    <React.Fragment
                                                        key={itemId}
                                                    >
                                                        {renderItem(
                                                            itemId,
                                                            index,
                                                            category.id,
                                                        )}
                                                        {index + 1 ===
                                                            category.numAboveTheFold && (
                                                            <div className="w-2 h-8 bg-red-500" />
                                                        )}
                                                    </React.Fragment>
                                                ),
                                            )}
                                            {category.itemIds.length === 0 && (
                                                <div>
                                                    EMPTY! This is not good!
                                                </div>
                                            )}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </div>
                        ))}
                        <div className="max-w-lg">
                            <Button
                                primary
                                label="New Category"
                                onClick={onAddCategory}
                            />
                        </div>
                    </div>
                </div>
            </DragDropContext>
        </Scrollable>
    );
}

export default ItemCategoryEditor;

// a little function to help us with reordering the result
function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

function useDirtyFlags(): [Set<String>, (...id: string[]) => void, () => void] {
    const [s, set] = useState(new Set<String>());

    const onAdd = useCallback(
        (...id: string[]) => {
            set(new Set<String>([...id, ...Array.from(s)]));
        },
        [s, set],
    );
    const onReset = useCallback(() => {
        set(new Set<String>());
    }, [set]);

    return [s, onAdd, onReset];
}
