import Scrollable from '../common/Scrollable';
import { FunctionComponent } from 'react';

const AnalyticsDashboard: FunctionComponent = () => {
    return (
        <Scrollable className="h-full flex flex-col">
            <div className="flex mb-4 items-center space-x-2">
                <h1 className="font-bold text-3xl">Analytics</h1>
                <div>
                    <a
                        href="https://datastudio.google.com/reporting/ff7996ad-7206-4c70-a76d-0fdf8fb59c71"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Open in new tab
                    </a>
                </div>
            </div>
            <div className="flex-grow">
                <iframe
                    title="datastudio report"
                    width="100%"
                    height="100%"
                    src="https://datastudio.google.com/embed/reporting/ff7996ad-7206-4c70-a76d-0fdf8fb59c71/page/p_bts0nom2mc"
                />
            </div>
        </Scrollable>
    );
};

export default AnalyticsDashboard;
