import { useCallback, useEffect, useState } from 'react';

export default function usePromise<T, D>(fn: () => Promise<T>, orElse: D) {
    const [v, setV] = useState<T | undefined>(undefined);
    const [e, setE] = useState<unknown | undefined>(undefined);

    const reload = useCallback(async () => {
        try {
            setE(undefined);
            const r = await fn();
            setV(r);
            return r;
        } catch (e) {
            setE(e);
        }
    }, [fn, setV]);

    useEffect(() => {
        reload().then();
    }, [reload]);

    return {
        value: v || orElse,
        reload,
        error: e,
    } as const;
}
