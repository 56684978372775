// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "model/backoffice.proto" (package "one.plan3t.core.model", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { AccountingCommand } from "./internal";
import { TransactionDetails } from "./openbanking";
import { FeedItem } from "./feed";
import { TipAuthorDraft } from "./tip";
import { TipQuizDraft } from "./tip";
import { TipSlideDraft } from "./tip";
import { TipPreviewDraft } from "./tip";
import { Plan3TPoint } from "./core";
import { ImageResource } from "./core";
import { LotteryTicket } from "./core";
import { RewardBillingType } from "./core";
import { CoreIndustry } from "./core";
import { NotificationAction } from "./core";
import { DraftState } from "./core";
import { Timestamp } from "../google/protobuf/timestamp";
import { ChallengePreview } from "./core";
import { AnalyticsReport } from "./core";
import { PartnerBase } from "./core";
/**
 * @generated from protobuf message one.plan3t.core.model.PostCreatePartnerBaseRequest
 */
export interface PostCreatePartnerBaseRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string legalName = 2;
     */
    legalName: string;
    /**
     * @generated from protobuf field: string managerEmail = 3;
     */
    managerEmail: string;
    /**
     * @generated from protobuf field: string brandName = 4;
     */
    brandName: string;
    /**
     * @generated from protobuf field: string pipedrive_id = 5;
     */
    pipedriveId: string;
    /**
     * @generated from protobuf field: string datev_id = 6;
     */
    datevId: string;
    /**
     * @generated from protobuf field: bool affiliate_only = 7;
     */
    affiliateOnly: boolean;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostCreatePartnerBaseResponse
 */
export interface PostCreatePartnerBaseResponse {
    /**
     * @generated from protobuf field: string loginLink = 1;
     */
    loginLink: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.UpdatePartnerBaseRequest
 */
export interface UpdatePartnerBaseRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string legal_name = 2;
     */
    legalName: string;
    /**
     * @generated from protobuf field: string brand_name = 3;
     */
    brandName: string;
    /**
     * @generated from protobuf field: string pipedrive_id = 4;
     */
    pipedriveId: string;
    /**
     * @generated from protobuf field: string datev_id = 5;
     */
    datevId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.UpdatePartnerBaseResponse
 */
export interface UpdatePartnerBaseResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetPartnersRequest
 */
export interface GetPartnersRequest {
    /**
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetPartnersResponse
 */
export interface GetPartnersResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.PartnerBase partners = 1;
     */
    partners: PartnerBase[];
    /**
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetPlatformReportRequest
 */
export interface GetPlatformReportRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.PlatformReport
 */
export interface PlatformReport {
    /**
     * @generated from protobuf field: int32 uniqueUserCount = 1;
     */
    uniqueUserCount: number;
    /**
     * @generated from protobuf field: int32 impressionCount = 2;
     */
    impressionCount: number;
    /**
     * @generated from protobuf field: map<string, int32> operatingSystemUniqueUserCount = 3;
     */
    operatingSystemUniqueUserCount: {
        [key: string]: number;
    };
    /**
     * @generated from protobuf field: one.plan3t.core.model.AnalyticsReport dailyActiveUsers = 4;
     */
    dailyActiveUsers?: AnalyticsReport;
    /**
     * @generated from protobuf field: one.plan3t.core.model.AnalyticsReport totalDownloadsCum = 5;
     */
    totalDownloadsCum?: AnalyticsReport;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetPlatformReportResponse
 */
export interface GetPlatformReportResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.PlatformReport report = 1;
     */
    report?: PlatformReport;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostChallengePreviewRequest
 */
export interface PostChallengePreviewRequest {
    /**
     * @generated from protobuf field: one.plan3t.core.model.ChallengePreview challenge = 1;
     */
    challenge?: ChallengePreview;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostChallengePreviewResponse
 */
export interface PostChallengePreviewResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.ReviewRequest
 */
export interface ReviewRequest {
    /**
     * @generated from protobuf field: string payloadType = 1;
     */
    payloadType: string;
    /**
     * @generated from protobuf field: string payloadId = 2;
     */
    payloadId: string;
    /**
     * @generated from protobuf field: string magicLinkId = 3;
     */
    magicLinkId: string;
    /**
     * @generated from protobuf field: string createdBy = 4;
     */
    createdBy: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp createdAt = 5;
     */
    createdAt?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetReviewRequestsResponse
 */
export interface GetReviewRequestsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ReviewRequest reviewRequests = 1;
     */
    reviewRequests: ReviewRequest[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostDeleteRewardCodesRequest
 */
export interface PostDeleteRewardCodesRequest {
    /**
     * @generated from protobuf field: string transactionId = 1;
     */
    transactionId: string;
    /**
     * @generated from protobuf field: string partnerId = 2;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string rewardId = 3;
     */
    rewardId: string;
    /**
     * @generated from protobuf field: repeated string codes = 4;
     */
    codes: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostDeleteRewardCodesResponse
 */
export interface PostDeleteRewardCodesResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetImpersonationTokenRequest
 */
export interface GetImpersonationTokenRequest {
    /**
     * @generated from protobuf field: string partnerId = 1;
     */
    partnerId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetImpersonationTokenResponse
 */
export interface GetImpersonationTokenResponse {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostAdjustRewardPriceRequest
 */
export interface PostAdjustRewardPriceRequest {
    /**
     * @generated from protobuf field: string transactionId = 1;
     */
    transactionId: string;
    /**
     * @generated from protobuf field: string partnerId = 2;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string rewardId = 3;
     */
    rewardId: string;
    /**
     * @generated from protobuf field: int32 points = 4;
     */
    points: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostAdjustRewardPriceResponse
 */
export interface PostAdjustRewardPriceResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.SetRewardStatusRequest
 */
export interface SetRewardStatusRequest {
    /**
     * @generated from protobuf field: string partnerId = 1;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string rewardId = 2;
     */
    rewardId: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.DraftState state = 3;
     */
    state: DraftState;
}
/**
 * @generated from protobuf message one.plan3t.core.model.SetRewardStatusResponse
 */
export interface SetRewardStatusResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.SetValidUntilForRewardRequest
 */
export interface SetValidUntilForRewardRequest {
    /**
     * @generated from protobuf field: string partnerId = 1;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string rewardId = 2;
     */
    rewardId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp valid_until = 3;
     */
    validUntil?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.SetValidUntilForRewardResponse
 */
export interface SetValidUntilForRewardResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.NotificationBroadcastMessage
 */
export interface NotificationBroadcastMessage {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string channelId = 2;
     */
    channelId: string;
    /**
     * @generated from protobuf field: string title = 3;
     */
    title: string;
    /**
     * @generated from protobuf field: string body = 4;
     */
    body: string;
    /**
     * @generated from protobuf field: string category = 5;
     */
    category: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.NotificationAction action = 6;
     */
    action?: NotificationAction;
    /**
     * @generated from protobuf field: map<string, string> payload = 7;
     */
    payload: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timestamp = 8;
     */
    timestamp?: Timestamp;
    /**
     * @generated from protobuf field: repeated string signedOffBy = 9;
     */
    signedOffBy: string[];
    /**
     * @generated from protobuf field: one.plan3t.core.model.NotificationMessageBroadcastState state = 10;
     */
    state: NotificationMessageBroadcastState;
}
/**
 * @generated from protobuf message one.plan3t.core.model.CreateNotificationBroadcastMessageRequest
 */
export interface CreateNotificationBroadcastMessageRequest {
    /**
     * @generated from protobuf field: one.plan3t.core.model.NotificationBroadcastMessage message = 1;
     */
    message?: NotificationBroadcastMessage;
}
/**
 * @generated from protobuf message one.plan3t.core.model.CreateNotificationBroadcastMessageResponse
 */
export interface CreateNotificationBroadcastMessageResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.NotificationBroadcastMessage message = 1;
     */
    message?: NotificationBroadcastMessage;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetNotificationBroadcastMessagesRequest
 */
export interface GetNotificationBroadcastMessagesRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetNotificationBroadcastMessagesResponse
 */
export interface GetNotificationBroadcastMessagesResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.NotificationBroadcastMessage messages = 1;
     */
    messages: NotificationBroadcastMessage[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.SendNotificationBroadcastMessageRequest
 */
export interface SendNotificationBroadcastMessageRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.SendNotificationBroadcastMessageResponse
 */
export interface SendNotificationBroadcastMessageResponse {
    /**
     * @generated from protobuf field: int64 success_count = 1;
     */
    successCount: number;
    /**
     * @generated from protobuf field: int64 failure_count = 2;
     */
    failureCount: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetNotificationBroadcastStatsRequest
 */
export interface GetNotificationBroadcastStatsRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetNotificationBroadcastStatsResponse
 */
export interface GetNotificationBroadcastStatsResponse {
    /**
     * @generated from protobuf field: int64 num_sent = 1;
     */
    numSent: number;
    /**
     * @generated from protobuf field: int64 num_opened = 2;
     */
    numOpened: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.CreateVoucherRequest
 */
export interface CreateVoucherRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string code = 2;
     */
    code: string;
    /**
     * @generated from protobuf field: int32 value = 3;
     */
    value: number;
    /**
     * @generated from protobuf field: string comment = 4;
     */
    comment: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.CreateVoucherResponse
 */
export interface CreateVoucherResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.ItemCategoryAuthoring
 */
export interface ItemCategoryAuthoring {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.CoreIndustry coreIndustry = 2;
     */
    coreIndustry: CoreIndustry;
    /**
     * @generated from protobuf field: string title = 3;
     */
    title: string;
    /**
     * @generated from protobuf field: string icon_name = 4;
     */
    iconName: string;
    /**
     * @generated from protobuf field: int64 color = 5;
     */
    color: number;
    /**
     * @generated from protobuf field: bool active = 6;
     */
    active: boolean;
    /**
     * @generated from protobuf field: repeated string item_ids = 7;
     */
    itemIds: string[];
    /**
     * @generated from protobuf field: int32 numAboveTheFold = 8;
     */
    numAboveTheFold: number;
    /**
     * @generated from protobuf field: string createdBy = 100;
     */
    createdBy: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp createdAt = 101;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string modifiedBy = 102;
     */
    modifiedBy: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp modifiedAt = 103;
     */
    modifiedAt?: Timestamp;
    /**
     * @generated from protobuf field: int32 position = 104;
     */
    position: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRewardsCategoryAuthoringItemsRequest
 */
export interface GetRewardsCategoryAuthoringItemsRequest {
    /**
     * @generated from protobuf field: one.plan3t.core.model.RewardBillingType billing_type = 1;
     */
    billingType: RewardBillingType;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRewardsCategoryAuthoringItemsResponse
 */
export interface GetRewardsCategoryAuthoringItemsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ItemCategoryAuthoring categories = 1;
     */
    categories: ItemCategoryAuthoring[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.WriteRewardsCategoryAuthoringRequest
 */
export interface WriteRewardsCategoryAuthoringRequest {
    /**
     * @generated from protobuf field: one.plan3t.core.model.ItemCategoryAuthoring item = 1;
     */
    item?: ItemCategoryAuthoring;
    /**
     * @generated from protobuf field: one.plan3t.core.model.RewardBillingType billing_type = 2;
     */
    billingType: RewardBillingType;
}
/**
 * @generated from protobuf message one.plan3t.core.model.WriteRewardsCategoryAuthoringResponse
 */
export interface WriteRewardsCategoryAuthoringResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetPartnerCategoryAuthoringItemsRequest
 */
export interface GetPartnerCategoryAuthoringItemsRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetPartnerCategoryAuthoringItemsResponse
 */
export interface GetPartnerCategoryAuthoringItemsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ItemCategoryAuthoring categories = 1;
     */
    categories: ItemCategoryAuthoring[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.WritePartnerCategoryAuthoringRequest
 */
export interface WritePartnerCategoryAuthoringRequest {
    /**
     * @generated from protobuf field: one.plan3t.core.model.ItemCategoryAuthoring item = 1;
     */
    item?: ItemCategoryAuthoring;
}
/**
 * @generated from protobuf message one.plan3t.core.model.WritePartnerCategoryAuthoringResponse
 */
export interface WritePartnerCategoryAuthoringResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipsCategoryAuthoringItemsRequest
 */
export interface GetTipsCategoryAuthoringItemsRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipsCategoryAuthoringItemsResponse
 */
export interface GetTipsCategoryAuthoringItemsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ItemCategoryAuthoring categories = 1;
     */
    categories: ItemCategoryAuthoring[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.WriteTipsCategoryAuthoringRequest
 */
export interface WriteTipsCategoryAuthoringRequest {
    /**
     * @generated from protobuf field: one.plan3t.core.model.ItemCategoryAuthoring item = 1;
     */
    item?: ItemCategoryAuthoring;
}
/**
 * @generated from protobuf message one.plan3t.core.model.WriteTipsCategoryAuthoringResponse
 */
export interface WriteTipsCategoryAuthoringResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetNumberOfLotteryTicketsRequest
 */
export interface GetNumberOfLotteryTicketsRequest {
    /**
     * @generated from protobuf field: string priceDrawingId = 1;
     */
    priceDrawingId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetNumberOfLotteryTicketsResponse
 */
export interface GetNumberOfLotteryTicketsResponse {
    /**
     * @generated from protobuf field: int64 numTickets = 1;
     */
    numTickets: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.DrawRandomLotteryTicketsRequest
 */
export interface DrawRandomLotteryTicketsRequest {
    /**
     * @generated from protobuf field: string priceDrawingId = 1;
     */
    priceDrawingId: string;
    /**
     * @generated from protobuf field: int32 numTickets = 2;
     */
    numTickets: number;
    /**
     * @generated from protobuf field: repeated string excludedTicketIds = 3;
     */
    excludedTicketIds: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.DrawRandomLotteryTicketsResponse
 */
export interface DrawRandomLotteryTicketsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.LotteryTicket tickets = 1;
     */
    tickets: LotteryTicket[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.SetLotteryWinnerTicketsRequest
 */
export interface SetLotteryWinnerTicketsRequest {
    /**
     * @generated from protobuf field: string priceDrawingId = 1;
     */
    priceDrawingId: string;
    /**
     * @generated from protobuf field: repeated string ticketIds = 2;
     */
    ticketIds: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.SetLotteryWinnerTicketsResponse
 */
export interface SetLotteryWinnerTicketsResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetLotteryTicketRequest
 */
export interface GetLotteryTicketRequest {
    /**
     * @generated from protobuf field: string ticketId = 1;
     */
    ticketId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetLotteryTicketResponse
 */
export interface GetLotteryTicketResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.LotteryTicket ticket = 1;
     */
    ticket?: LotteryTicket;
}
/**
 * @generated from protobuf message one.plan3t.core.model.LotteryPriceDrawingAuthoringItem
 */
export interface LotteryPriceDrawingAuthoringItem {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp starts_at = 2;
     */
    startsAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp ends_at = 3;
     */
    endsAt?: Timestamp;
    /**
     * @generated from protobuf field: string title = 4;
     */
    title: string;
    /**
     * @generated from protobuf field: string description = 5;
     */
    description: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource image = 6;
     */
    image?: ImageResource;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint ticketPrice = 7;
     */
    ticketPrice?: Plan3TPoint;
    /**
     * @generated from protobuf field: string partnerId = 8;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: repeated string winnerTicketIds = 9;
     */
    winnerTicketIds: string[];
    /**
     * @generated from protobuf field: string extraBenefitExplanation = 10;
     */
    extraBenefitExplanation: string;
    /**
     * @generated from protobuf field: string cooperationProjectName = 11;
     */
    cooperationProjectName: string;
    /**
     * @generated from protobuf field: string cooperationProjectUrl = 12;
     */
    cooperationProjectUrl: string;
    /**
     * @generated from protobuf field: string cooperationProjectTagLine = 13;
     */
    cooperationProjectTagLine: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource cooperationProjectLogo = 14;
     */
    cooperationProjectLogo?: ImageResource;
    /**
     * @generated from protobuf field: string terms = 15;
     */
    terms: string;
    /**
     * @generated from protobuf field: string notification_title = 16;
     */
    notificationTitle: string;
    /**
     * @generated from protobuf field: string notification_body = 17;
     */
    notificationBody: string;
    /**
     * @generated from protobuf field: repeated string associated_partner_ids = 18;
     */
    associatedPartnerIds: string[];
    /**
     * @generated from protobuf field: string compensate_project_id = 19;
     */
    compensateProjectId: string;
    /**
     * @generated from protobuf field: string donation_project_sku = 20;
     */
    donationProjectSku: string;
    /**
     * @generated from protobuf field: uint32 donation_project_contribution = 21;
     */
    donationProjectContribution: number;
    /**
     * @generated from protobuf field: string createdBy = 100;
     */
    createdBy: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp createdAt = 101;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string modifiedBy = 102;
     */
    modifiedBy: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp modifiedAt = 103;
     */
    modifiedAt?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetLotteryPriceDrawingAuthoringItemsRequest
 */
export interface GetLotteryPriceDrawingAuthoringItemsRequest {
    /**
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetLotteryPriceDrawingAuthoringItemsResponse
 */
export interface GetLotteryPriceDrawingAuthoringItemsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.LotteryPriceDrawingAuthoringItem items = 1;
     */
    items: LotteryPriceDrawingAuthoringItem[];
    /**
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.WriteLotteryPriceDrawingAuthoringItemRequest
 */
export interface WriteLotteryPriceDrawingAuthoringItemRequest {
    /**
     * @generated from protobuf field: one.plan3t.core.model.LotteryPriceDrawingAuthoringItem item = 1;
     */
    item?: LotteryPriceDrawingAuthoringItem;
}
/**
 * @generated from protobuf message one.plan3t.core.model.WriteLotteryPriceDrawingAuthoringItemResponse
 */
export interface WriteLotteryPriceDrawingAuthoringItemResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetAvailableLotteryProjectsRequest
 */
export interface GetAvailableLotteryProjectsRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetAvailableLotteryProjectsResponse
 */
export interface GetAvailableLotteryProjectsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.GetAvailableLotteryProjectsResponse.AvailableProject projects = 1;
     */
    projects: GetAvailableLotteryProjectsResponse_AvailableProject[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetAvailableLotteryProjectsResponse.AvailableProject
 */
export interface GetAvailableLotteryProjectsResponse_AvailableProject {
    /**
     * @generated from protobuf field: string extraBenefitExplanation = 1;
     */
    extraBenefitExplanation: string;
    /**
     * @generated from protobuf field: string cooperationProjectName = 2;
     */
    cooperationProjectName: string;
    /**
     * @generated from protobuf field: string cooperationProjectUrl = 3;
     */
    cooperationProjectUrl: string;
    /**
     * @generated from protobuf field: string cooperationProjectTagLine = 4;
     */
    cooperationProjectTagLine: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource cooperationProjectLogo = 5;
     */
    cooperationProjectLogo?: ImageResource;
    /**
     * @generated from protobuf field: string compensate_project_id = 6;
     */
    compensateProjectId: string;
    /**
     * @generated from protobuf field: string donation_project_sku = 7;
     */
    donationProjectSku: string;
    /**
     * @generated from protobuf field: uint32 donation_project_contribution = 8;
     */
    donationProjectContribution: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipDraftsRequest
 */
export interface GetTipDraftsRequest {
    /**
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipDraftsResponse
 */
export interface GetTipDraftsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.TipPreviewDraft tips = 1;
     */
    tips: TipPreviewDraft[];
    /**
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.SaveTipDraftRequest
 */
export interface SaveTipDraftRequest {
    /**
     * @generated from protobuf field: one.plan3t.core.model.TipPreviewDraft draft = 1;
     */
    draft?: TipPreviewDraft;
}
/**
 * @generated from protobuf message one.plan3t.core.model.SaveTipDraftResponse
 */
export interface SaveTipDraftResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.TipPreviewDraft draft = 1;
     */
    draft?: TipPreviewDraft;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipDraftRequest
 */
export interface GetTipDraftRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipDraftResponse
 */
export interface GetTipDraftResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.TipPreviewDraft draft = 1;
     */
    draft?: TipPreviewDraft;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipSlideDraftsRequest
 */
export interface GetTipSlideDraftsRequest {
    /**
     * @generated from protobuf field: string tip_id = 1;
     */
    tipId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipSlideDraftsResponse
 */
export interface GetTipSlideDraftsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.TipSlideDraft slides = 1;
     */
    slides: TipSlideDraft[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.SaveTipSlideDraftsRequest
 */
export interface SaveTipSlideDraftsRequest {
    /**
     * @generated from protobuf field: string tip_id = 1;
     */
    tipId: string;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.TipSlideDraft slides = 2;
     */
    slides: TipSlideDraft[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.SaveTipSlideDraftsResponse
 */
export interface SaveTipSlideDraftsResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipQuizDraftRequest
 */
export interface GetTipQuizDraftRequest {
    /**
     * @generated from protobuf field: string tip_id = 1;
     */
    tipId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipQuizDraftResponse
 */
export interface GetTipQuizDraftResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.TipQuizDraft quiz = 1;
     */
    quiz?: TipQuizDraft;
}
/**
 * @generated from protobuf message one.plan3t.core.model.SaveTipQuizDraftRequest
 */
export interface SaveTipQuizDraftRequest {
    /**
     * @generated from protobuf field: string tip_id = 1;
     */
    tipId: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.TipQuizDraft quiz = 2;
     */
    quiz?: TipQuizDraft;
}
/**
 * @generated from protobuf message one.plan3t.core.model.SaveTipQuizDraftResponse
 */
export interface SaveTipQuizDraftResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.SaveTipAuthorDraftRequest
 */
export interface SaveTipAuthorDraftRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string display_name = 2;
     */
    displayName: string;
    /**
     * @generated from protobuf field: string tag_line = 3;
     */
    tagLine: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource image = 4;
     */
    image?: ImageResource;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.SaveTipAuthorDraftRequest.SocialLink social_links = 5;
     */
    socialLinks: SaveTipAuthorDraftRequest_SocialLink[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.SaveTipAuthorDraftRequest.SocialLink
 */
export interface SaveTipAuthorDraftRequest_SocialLink {
    /**
     * @generated from protobuf field: string url = 1;
     */
    url: string;
    /**
     * @generated from protobuf field: string label = 2;
     */
    label: string;
    /**
     * @generated from protobuf field: string kind = 3;
     */
    kind: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.SaveTipAuthorDraftResponse
 */
export interface SaveTipAuthorDraftResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipAuthorDraftsRequest
 */
export interface GetTipAuthorDraftsRequest {
    /**
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipAuthorDraftsResponse
 */
export interface GetTipAuthorDraftsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.TipAuthorDraft items = 1;
     */
    items: TipAuthorDraft[];
    /**
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetChallengesCategoryAuthoringItemsRequest
 */
export interface GetChallengesCategoryAuthoringItemsRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetChallengesCategoryAuthoringItemsResponse
 */
export interface GetChallengesCategoryAuthoringItemsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ItemCategoryAuthoring categories = 1;
     */
    categories: ItemCategoryAuthoring[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.WriteChallengesCategoryAuthoringRequest
 */
export interface WriteChallengesCategoryAuthoringRequest {
    /**
     * @generated from protobuf field: one.plan3t.core.model.ItemCategoryAuthoring item = 1;
     */
    item?: ItemCategoryAuthoring;
}
/**
 * @generated from protobuf message one.plan3t.core.model.WriteChallengesCategoryAuthoringResponse
 */
export interface WriteChallengesCategoryAuthoringResponse {
}
/**
 * internal use only
 *
 * @generated from protobuf message one.plan3t.core.model.CreateFeedItemRequest
 */
export interface CreateFeedItemRequest {
    /**
     * @generated from protobuf field: one.plan3t.core.model.FeedItem item = 1;
     */
    item?: FeedItem;
}
/**
 * @generated from protobuf message one.plan3t.core.model.CreateFeedItemResponse
 */
export interface CreateFeedItemResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetFeedItemsRequest
 */
export interface GetFeedItemsRequest {
    /**
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetFeedItemsResponse
 */
export interface GetFeedItemsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.FeedItem items = 1;
     */
    items: FeedItem[];
    /**
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.DeleteFeedItemRequest
 */
export interface DeleteFeedItemRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.DeleteFeedItemResponse
 */
export interface DeleteFeedItemResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.MissingCashbackTicket
 */
export interface MissingCashbackTicket {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp purchase_date = 2;
     */
    purchaseDate?: Timestamp;
    /**
     * @generated from protobuf field: string partner_id = 3;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string account_id = 4;
     */
    accountId: string;
    /**
     * @generated from protobuf field: int32 amount_unscaled = 5;
     */
    amountUnscaled: number;
    /**
     * @generated from protobuf field: int32 amount_scale = 6;
     */
    amountScale: number;
    /**
     * @generated from protobuf field: one.plan3t.core.model.MissingCashbackState state = 7;
     */
    state: MissingCashbackState;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 8;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string user_id = 9;
     */
    userId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetMissingCashbackTicketsRequest
 */
export interface GetMissingCashbackTicketsRequest {
    /**
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetMissingCashbackTicketsResponse
 */
export interface GetMissingCashbackTicketsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.MissingCashbackTicket items = 1;
     */
    items: MissingCashbackTicket[];
    /**
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.SetCashbackTicketStateRequest
 */
export interface SetCashbackTicketStateRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.MissingCashbackState target_state = 2;
     */
    targetState: MissingCashbackState;
}
/**
 * @generated from protobuf message one.plan3t.core.model.SetCashbackTicketStateResponse
 */
export interface SetCashbackTicketStateResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTransactionsForMissingCashbackTicketRequest
 */
export interface GetTransactionsForMissingCashbackTicketRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTransactionsForMissingCashbackTicketResponse
 */
export interface GetTransactionsForMissingCashbackTicketResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.TransactionDetails transactions = 1;
     */
    transactions: TransactionDetails[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.CashbackPartnerCondition
 */
export interface CashbackPartnerCondition {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string field = 2;
     */
    field: string;
    /**
     * @generated from protobuf field: string regex = 3;
     */
    regex: string;
    /**
     * @generated from protobuf field: string comment = 4;
     */
    comment: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.EvaluateCashbackRuleConditionRequest
 */
export interface EvaluateCashbackRuleConditionRequest {
    /**
     * @generated from protobuf field: one.plan3t.core.model.CashbackPartnerCondition condition = 1;
     */
    condition?: CashbackPartnerCondition;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp from = 2;
     */
    from?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp to = 3;
     */
    to?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.EvaluateCashbackRuleConditionResponse
 */
export interface EvaluateCashbackRuleConditionResponse {
    /**
     * @generated from protobuf field: int64 num_processed = 1;
     */
    numProcessed: number;
    /**
     * @generated from protobuf field: int64 num_matched = 2;
     */
    numMatched: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetCustomReferralCodesRequest
 */
export interface GetCustomReferralCodesRequest {
    /**
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetCustomReferralCodesResponse
 */
export interface GetCustomReferralCodesResponse {
    /**
     * @generated from protobuf field: repeated string items = 1;
     */
    items: string[];
    /**
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.CreateCustomReferralCodeRequest
 */
export interface CreateCustomReferralCodeRequest {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.CreateCustomReferralCodeResponse
 */
export interface CreateCustomReferralCodeResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetReferralCodeStatsRequest
 */
export interface GetReferralCodeStatsRequest {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetReferralCodeStatsResponse
 */
export interface GetReferralCodeStatsResponse {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string created_by = 3;
     */
    createdBy: string;
    /**
     * @generated from protobuf field: int32 pending = 4;
     */
    pending: number;
    /**
     * @generated from protobuf field: int32 completed = 5;
     */
    completed: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetOpenBankingWaitlistEntriesRequest
 */
export interface GetOpenBankingWaitlistEntriesRequest {
    /**
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.OpenBankingWaitlistEntry
 */
export interface OpenBankingWaitlistEntry {
    /**
     * @generated from protobuf field: string user_id = 1;
     */
    userId: string;
    /**
     * @generated from protobuf field: string email = 2;
     */
    email: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp confirmed_at = 7;
     */
    confirmedAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 8;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp modified_at = 9;
     */
    modifiedAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp accepted_at = 10;
     */
    acceptedAt?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetOpenBankingWaitlistEntriesResponse
 */
export interface GetOpenBankingWaitlistEntriesResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.OpenBankingWaitlistEntry items = 1;
     */
    items: OpenBankingWaitlistEntry[];
    /**
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.AcceptOpenBankingWaitlistEntryRequest
 */
export interface AcceptOpenBankingWaitlistEntryRequest {
    /**
     * @generated from protobuf field: string user_id = 1;
     */
    userId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.AcceptOpenBankingWaitlistEntryResponse
 */
export interface AcceptOpenBankingWaitlistEntryResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.FetchAssessmentScoresRequest
 */
export interface FetchAssessmentScoresRequest {
    /**
     * @generated from protobuf field: bool force_overwrite = 1;
     */
    forceOverwrite: boolean;
}
/**
 * @generated from protobuf message one.plan3t.core.model.FetchAssessmentScoresResponse
 */
export interface FetchAssessmentScoresResponse {
    /**
     * @generated from protobuf field: int32 num_fetched = 1;
     */
    numFetched: number;
    /**
     * @generated from protobuf field: int32 num_written = 2;
     */
    numWritten: number;
    /**
     * @generated from protobuf field: repeated string partners_without_ids = 3;
     */
    partnersWithoutIds: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.AssessmentScoreAuthoring
 */
export interface AssessmentScoreAuthoring {
    /**
     * @generated from protobuf field: string label = 1;
     */
    label: string;
    /**
     * @generated from protobuf field: int32 value_unscaled = 2;
     */
    valueUnscaled: number;
    /**
     * @generated from protobuf field: int32 value_scale = 3;
     */
    valueScale: number;
    /**
     * @generated from protobuf field: bool applicable = 4;
     */
    applicable: boolean;
}
/**
 * @generated from protobuf message one.plan3t.core.model.AssessmentAuthoring
 */
export interface AssessmentAuthoring {
    /**
     * @generated from protobuf field: string partner_id = 1;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string scoring_word = 2;
     */
    scoringWord: string;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.AssessmentScoreAuthoring scores = 3;
     */
    scores: AssessmentScoreAuthoring[];
    /**
     * @generated from protobuf field: string edit_link = 4;
     */
    editLink: string;
    /**
     * @generated from protobuf field: int32 score = 5;
     */
    score: number;
    /**
     * @generated from protobuf field: int32 score_max = 6;
     */
    scoreMax: number;
    /**
     * @generated from protobuf field: string createdBy = 100;
     */
    createdBy: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp createdAt = 101;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string modifiedBy = 102;
     */
    modifiedBy: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp modifiedAt = 103;
     */
    modifiedAt?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetAssessmentsAuthoringRequest
 */
export interface GetAssessmentsAuthoringRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetAssessmentsAuthoringResponse
 */
export interface GetAssessmentsAuthoringResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.AssessmentAuthoring assessments = 1;
     */
    assessments: AssessmentAuthoring[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.ApplyAccountingCommandManualRequest
 */
export interface ApplyAccountingCommandManualRequest {
    /**
     * @generated from protobuf field: one.plan3t.core.model.AccountingCommand command = 1;
     */
    command?: AccountingCommand;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ApplyAccountingCommandManualResponse
 */
export interface ApplyAccountingCommandManualResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRewardCodeRequest
 */
export interface GetRewardCodeRequest {
    /**
     * code_id is already globally unique, but the others just ensure, that we retrieve the correct one,
     * adding an additional layer of security.
     *
     * @generated from protobuf field: string code_id = 1;
     */
    codeId: string;
    /**
     * @generated from protobuf field: string reward_id = 2;
     */
    rewardId: string;
    /**
     * @generated from protobuf field: string partner_id = 3;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string nonce = 4;
     */
    nonce: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRewardCodeResponse
 */
export interface GetRewardCodeResponse {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
    /**
     * @generated from protobuf field: string owner = 2;
     */
    owner: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp valid_until = 3;
     */
    validUntil?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.CashbackRate
 */
export interface CashbackRate {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string partner_id = 2;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: int32 rate = 3;
     */
    rate: number;
    /**
     * @generated from protobuf field: int32 rate_scale = 4;
     */
    rateScale: number;
    /**
     * @generated from protobuf field: int32 fee = 5;
     */
    fee: number;
    /**
     * @generated from protobuf field: int32 fee_scale = 6;
     */
    feeScale: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp valid_from = 7;
     */
    validFrom?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 8;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp modified_at = 9;
     */
    modifiedAt?: Timestamp;
    /**
     * @generated from protobuf field: int32 sponsored_rate = 10;
     */
    sponsoredRate: number;
    /**
     * @generated from protobuf field: int32 sponsored_rate_scale = 11;
     */
    sponsoredRateScale: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetCurrentCashbackRatesRequest
 */
export interface GetCurrentCashbackRatesRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetCurrentCashbackRatesResponse
 */
export interface GetCurrentCashbackRatesResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.CashbackRate items = 1;
     */
    items: CashbackRate[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetCashbackRateScheduleRequest
 */
export interface GetCashbackRateScheduleRequest {
    /**
     * @generated from protobuf field: string partner_id = 1;
     */
    partnerId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetCashbackRateScheduleResponse
 */
export interface GetCashbackRateScheduleResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.CashbackRate items = 1;
     */
    items: CashbackRate[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.SetCashbackRateRequest
 */
export interface SetCashbackRateRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string partner_id = 2;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: int32 rate = 3;
     */
    rate: number;
    /**
     * @generated from protobuf field: int32 rate_scale = 4;
     */
    rateScale: number;
    /**
     * @generated from protobuf field: int32 fee = 5;
     */
    fee: number;
    /**
     * @generated from protobuf field: int32 fee_scale = 6;
     */
    feeScale: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp valid_from = 7;
     */
    validFrom?: Timestamp; // must be in the future
    /**
     * @generated from protobuf field: int32 sponsored_rate = 8;
     */
    sponsoredRate: number;
    /**
     * @generated from protobuf field: int32 sponsored_rate_scale = 9;
     */
    sponsoredRateScale: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.SetCashbackRateResponse
 */
export interface SetCashbackRateResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.ManuallyApplyCashbackRequest
 */
export interface ManuallyApplyCashbackRequest {
    /**
     * @generated from protobuf field: string transaction_id = 1;
     */
    transactionId: string;
    /**
     * @generated from protobuf field: string partner_id = 2;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: bytes document = 3;
     */
    document: Uint8Array;
    /**
     * @generated from protobuf field: string mime_type = 4;
     */
    mimeType: string;
    /**
     * @generated from protobuf field: string document_identifier = 5;
     */
    documentIdentifier: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ManuallyApplyCashbackResponse
 */
export interface ManuallyApplyCashbackResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.ApplyExternalCashbackRequest
 */
export interface ApplyExternalCashbackRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string partner_id = 2;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string user_id = 3;
     */
    userId: string;
    /**
     * @generated from protobuf field: int32 order_amount_unscaled = 4;
     */
    orderAmountUnscaled: number;
    /**
     * @generated from protobuf field: int32 order_amount_scale = 5;
     */
    orderAmountScale: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp order_date = 6;
     */
    orderDate?: Timestamp;
    /**
     * @generated from protobuf field: bytes document = 10;
     */
    document: Uint8Array;
    /**
     * @generated from protobuf field: string mime_type = 11;
     */
    mimeType: string;
    /**
     * @generated from protobuf field: string document_identifier = 12;
     */
    documentIdentifier: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ApplyExternalCashbackResponse
 */
export interface ApplyExternalCashbackResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.MarkRewardCodeAsUsedRequest
 */
export interface MarkRewardCodeAsUsedRequest {
    /**
     * @generated from protobuf field: string partner_id = 1;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string reward_id = 2;
     */
    rewardId: string;
    /**
     * @generated from protobuf field: repeated string codes = 3;
     */
    codes: string[];
    /**
     * @generated from protobuf field: int64 billing_period = 4;
     */
    billingPeriod: number;
    /**
     * @generated from protobuf field: int32 fee_unscaled = 5;
     */
    feeUnscaled: number;
    /**
     * @generated from protobuf field: int32 fee_scale = 6;
     */
    feeScale: number;
    /**
     * @generated from protobuf field: string document_id = 7;
     */
    documentId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.MarkRewardCodeAsUsedResponse
 */
export interface MarkRewardCodeAsUsedResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GeneratePasswordResetLinkRequest
 */
export interface GeneratePasswordResetLinkRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.GeneratePasswordResetLinkRequest.Scope scope = 2;
     */
    scope: GeneratePasswordResetLinkRequest_Scope;
}
/**
 * @generated from protobuf enum one.plan3t.core.model.GeneratePasswordResetLinkRequest.Scope
 */
export enum GeneratePasswordResetLinkRequest_Scope {
    /**
     * @generated from protobuf enum value: SCOPE_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: SCOPE_B2C = 1;
     */
    B2C = 1,
    /**
     * @generated from protobuf enum value: SCOPE_B2B = 2;
     */
    B2B = 2
}
/**
 * @generated from protobuf message one.plan3t.core.model.GeneratePasswordResetLinkResponse
 */
export interface GeneratePasswordResetLinkResponse {
    /**
     * @generated from protobuf field: string url = 1;
     */
    url: string;
}
/**
 * @generated from protobuf enum one.plan3t.core.model.NotificationMessageBroadcastState
 */
export enum NotificationMessageBroadcastState {
    /**
     * @generated from protobuf enum value: UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: DRAFT = 1;
     */
    DRAFT = 1,
    /**
     * @generated from protobuf enum value: ROLLOUT = 2;
     */
    ROLLOUT = 2,
    /**
     * @generated from protobuf enum value: SENT = 3;
     */
    SENT = 3
}
/**
 * @generated from protobuf enum one.plan3t.core.model.MissingCashbackState
 */
export enum MissingCashbackState {
    /**
     * @generated from protobuf enum value: MISSING_CASHBACK_TICKET_STATE_OPEN = 0;
     */
    MISSING_CASHBACK_TICKET_STATE_OPEN = 0,
    /**
     * @generated from protobuf enum value: MISSING_CASHBACK_TICKET_STATE_ON_HOLD = 1;
     */
    MISSING_CASHBACK_TICKET_STATE_ON_HOLD = 1,
    /**
     * @generated from protobuf enum value: MISSING_CASHBACK_TICKET_STATE_SOLVED = 2;
     */
    MISSING_CASHBACK_TICKET_STATE_SOLVED = 2,
    /**
     * @generated from protobuf enum value: MISSING_CASHBACK_TICKET_STATE_REJECTED = 3;
     */
    MISSING_CASHBACK_TICKET_STATE_REJECTED = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class PostCreatePartnerBaseRequest$Type extends MessageType<PostCreatePartnerBaseRequest> {
    constructor() {
        super("one.plan3t.core.model.PostCreatePartnerBaseRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "legalName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "managerEmail", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "brandName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "pipedrive_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "datev_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "affiliate_only", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<PostCreatePartnerBaseRequest>): PostCreatePartnerBaseRequest {
        const message = { id: "", legalName: "", managerEmail: "", brandName: "", pipedriveId: "", datevId: "", affiliateOnly: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostCreatePartnerBaseRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostCreatePartnerBaseRequest): PostCreatePartnerBaseRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string legalName */ 2:
                    message.legalName = reader.string();
                    break;
                case /* string managerEmail */ 3:
                    message.managerEmail = reader.string();
                    break;
                case /* string brandName */ 4:
                    message.brandName = reader.string();
                    break;
                case /* string pipedrive_id */ 5:
                    message.pipedriveId = reader.string();
                    break;
                case /* string datev_id */ 6:
                    message.datevId = reader.string();
                    break;
                case /* bool affiliate_only */ 7:
                    message.affiliateOnly = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostCreatePartnerBaseRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string legalName = 2; */
        if (message.legalName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.legalName);
        /* string managerEmail = 3; */
        if (message.managerEmail !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.managerEmail);
        /* string brandName = 4; */
        if (message.brandName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.brandName);
        /* string pipedrive_id = 5; */
        if (message.pipedriveId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.pipedriveId);
        /* string datev_id = 6; */
        if (message.datevId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.datevId);
        /* bool affiliate_only = 7; */
        if (message.affiliateOnly !== false)
            writer.tag(7, WireType.Varint).bool(message.affiliateOnly);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostCreatePartnerBaseRequest
 */
export const PostCreatePartnerBaseRequest = new PostCreatePartnerBaseRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostCreatePartnerBaseResponse$Type extends MessageType<PostCreatePartnerBaseResponse> {
    constructor() {
        super("one.plan3t.core.model.PostCreatePartnerBaseResponse", [
            { no: 1, name: "loginLink", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PostCreatePartnerBaseResponse>): PostCreatePartnerBaseResponse {
        const message = { loginLink: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostCreatePartnerBaseResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostCreatePartnerBaseResponse): PostCreatePartnerBaseResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string loginLink */ 1:
                    message.loginLink = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostCreatePartnerBaseResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string loginLink = 1; */
        if (message.loginLink !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.loginLink);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostCreatePartnerBaseResponse
 */
export const PostCreatePartnerBaseResponse = new PostCreatePartnerBaseResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdatePartnerBaseRequest$Type extends MessageType<UpdatePartnerBaseRequest> {
    constructor() {
        super("one.plan3t.core.model.UpdatePartnerBaseRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "legal_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "brand_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "pipedrive_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "datev_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdatePartnerBaseRequest>): UpdatePartnerBaseRequest {
        const message = { id: "", legalName: "", brandName: "", pipedriveId: "", datevId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdatePartnerBaseRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdatePartnerBaseRequest): UpdatePartnerBaseRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string legal_name */ 2:
                    message.legalName = reader.string();
                    break;
                case /* string brand_name */ 3:
                    message.brandName = reader.string();
                    break;
                case /* string pipedrive_id */ 4:
                    message.pipedriveId = reader.string();
                    break;
                case /* string datev_id */ 5:
                    message.datevId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdatePartnerBaseRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string legal_name = 2; */
        if (message.legalName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.legalName);
        /* string brand_name = 3; */
        if (message.brandName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.brandName);
        /* string pipedrive_id = 4; */
        if (message.pipedriveId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.pipedriveId);
        /* string datev_id = 5; */
        if (message.datevId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.datevId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.UpdatePartnerBaseRequest
 */
export const UpdatePartnerBaseRequest = new UpdatePartnerBaseRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdatePartnerBaseResponse$Type extends MessageType<UpdatePartnerBaseResponse> {
    constructor() {
        super("one.plan3t.core.model.UpdatePartnerBaseResponse", []);
    }
    create(value?: PartialMessage<UpdatePartnerBaseResponse>): UpdatePartnerBaseResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdatePartnerBaseResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdatePartnerBaseResponse): UpdatePartnerBaseResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdatePartnerBaseResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.UpdatePartnerBaseResponse
 */
export const UpdatePartnerBaseResponse = new UpdatePartnerBaseResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPartnersRequest$Type extends MessageType<GetPartnersRequest> {
    constructor() {
        super("one.plan3t.core.model.GetPartnersRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPartnersRequest>): GetPartnersRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPartnersRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPartnersRequest): GetPartnersRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPartnersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetPartnersRequest
 */
export const GetPartnersRequest = new GetPartnersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPartnersResponse$Type extends MessageType<GetPartnersResponse> {
    constructor() {
        super("one.plan3t.core.model.GetPartnersResponse", [
            { no: 1, name: "partners", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PartnerBase },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPartnersResponse>): GetPartnersResponse {
        const message = { partners: [], nextPageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPartnersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPartnersResponse): GetPartnersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.PartnerBase partners */ 1:
                    message.partners.push(PartnerBase.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPartnersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.PartnerBase partners = 1; */
        for (let i = 0; i < message.partners.length; i++)
            PartnerBase.internalBinaryWrite(message.partners[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetPartnersResponse
 */
export const GetPartnersResponse = new GetPartnersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPlatformReportRequest$Type extends MessageType<GetPlatformReportRequest> {
    constructor() {
        super("one.plan3t.core.model.GetPlatformReportRequest", []);
    }
    create(value?: PartialMessage<GetPlatformReportRequest>): GetPlatformReportRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPlatformReportRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPlatformReportRequest): GetPlatformReportRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetPlatformReportRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetPlatformReportRequest
 */
export const GetPlatformReportRequest = new GetPlatformReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PlatformReport$Type extends MessageType<PlatformReport> {
    constructor() {
        super("one.plan3t.core.model.PlatformReport", [
            { no: 1, name: "uniqueUserCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "impressionCount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "operatingSystemUniqueUserCount", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } },
            { no: 4, name: "dailyActiveUsers", kind: "message", T: () => AnalyticsReport },
            { no: 5, name: "totalDownloadsCum", kind: "message", T: () => AnalyticsReport }
        ]);
    }
    create(value?: PartialMessage<PlatformReport>): PlatformReport {
        const message = { uniqueUserCount: 0, impressionCount: 0, operatingSystemUniqueUserCount: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PlatformReport>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PlatformReport): PlatformReport {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 uniqueUserCount */ 1:
                    message.uniqueUserCount = reader.int32();
                    break;
                case /* int32 impressionCount */ 2:
                    message.impressionCount = reader.int32();
                    break;
                case /* map<string, int32> operatingSystemUniqueUserCount */ 3:
                    this.binaryReadMap3(message.operatingSystemUniqueUserCount, reader, options);
                    break;
                case /* one.plan3t.core.model.AnalyticsReport dailyActiveUsers */ 4:
                    message.dailyActiveUsers = AnalyticsReport.internalBinaryRead(reader, reader.uint32(), options, message.dailyActiveUsers);
                    break;
                case /* one.plan3t.core.model.AnalyticsReport totalDownloadsCum */ 5:
                    message.totalDownloadsCum = AnalyticsReport.internalBinaryRead(reader, reader.uint32(), options, message.totalDownloadsCum);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: PlatformReport["operatingSystemUniqueUserCount"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof PlatformReport["operatingSystemUniqueUserCount"] | undefined, val: PlatformReport["operatingSystemUniqueUserCount"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.model.PlatformReport.operatingSystemUniqueUserCount");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message: PlatformReport, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 uniqueUserCount = 1; */
        if (message.uniqueUserCount !== 0)
            writer.tag(1, WireType.Varint).int32(message.uniqueUserCount);
        /* int32 impressionCount = 2; */
        if (message.impressionCount !== 0)
            writer.tag(2, WireType.Varint).int32(message.impressionCount);
        /* map<string, int32> operatingSystemUniqueUserCount = 3; */
        for (let k of Object.keys(message.operatingSystemUniqueUserCount))
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).int32(message.operatingSystemUniqueUserCount[k]).join();
        /* one.plan3t.core.model.AnalyticsReport dailyActiveUsers = 4; */
        if (message.dailyActiveUsers)
            AnalyticsReport.internalBinaryWrite(message.dailyActiveUsers, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.AnalyticsReport totalDownloadsCum = 5; */
        if (message.totalDownloadsCum)
            AnalyticsReport.internalBinaryWrite(message.totalDownloadsCum, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PlatformReport
 */
export const PlatformReport = new PlatformReport$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPlatformReportResponse$Type extends MessageType<GetPlatformReportResponse> {
    constructor() {
        super("one.plan3t.core.model.GetPlatformReportResponse", [
            { no: 1, name: "report", kind: "message", T: () => PlatformReport }
        ]);
    }
    create(value?: PartialMessage<GetPlatformReportResponse>): GetPlatformReportResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPlatformReportResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPlatformReportResponse): GetPlatformReportResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.PlatformReport report */ 1:
                    message.report = PlatformReport.internalBinaryRead(reader, reader.uint32(), options, message.report);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPlatformReportResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.PlatformReport report = 1; */
        if (message.report)
            PlatformReport.internalBinaryWrite(message.report, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetPlatformReportResponse
 */
export const GetPlatformReportResponse = new GetPlatformReportResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostChallengePreviewRequest$Type extends MessageType<PostChallengePreviewRequest> {
    constructor() {
        super("one.plan3t.core.model.PostChallengePreviewRequest", [
            { no: 1, name: "challenge", kind: "message", T: () => ChallengePreview }
        ]);
    }
    create(value?: PartialMessage<PostChallengePreviewRequest>): PostChallengePreviewRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostChallengePreviewRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostChallengePreviewRequest): PostChallengePreviewRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.ChallengePreview challenge */ 1:
                    message.challenge = ChallengePreview.internalBinaryRead(reader, reader.uint32(), options, message.challenge);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostChallengePreviewRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.ChallengePreview challenge = 1; */
        if (message.challenge)
            ChallengePreview.internalBinaryWrite(message.challenge, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostChallengePreviewRequest
 */
export const PostChallengePreviewRequest = new PostChallengePreviewRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostChallengePreviewResponse$Type extends MessageType<PostChallengePreviewResponse> {
    constructor() {
        super("one.plan3t.core.model.PostChallengePreviewResponse", []);
    }
    create(value?: PartialMessage<PostChallengePreviewResponse>): PostChallengePreviewResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostChallengePreviewResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostChallengePreviewResponse): PostChallengePreviewResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: PostChallengePreviewResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostChallengePreviewResponse
 */
export const PostChallengePreviewResponse = new PostChallengePreviewResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReviewRequest$Type extends MessageType<ReviewRequest> {
    constructor() {
        super("one.plan3t.core.model.ReviewRequest", [
            { no: 1, name: "payloadType", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "payloadId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "magicLinkId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "createdBy", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "createdAt", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<ReviewRequest>): ReviewRequest {
        const message = { payloadType: "", payloadId: "", magicLinkId: "", createdBy: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReviewRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReviewRequest): ReviewRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string payloadType */ 1:
                    message.payloadType = reader.string();
                    break;
                case /* string payloadId */ 2:
                    message.payloadId = reader.string();
                    break;
                case /* string magicLinkId */ 3:
                    message.magicLinkId = reader.string();
                    break;
                case /* string createdBy */ 4:
                    message.createdBy = reader.string();
                    break;
                case /* google.protobuf.Timestamp createdAt */ 5:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReviewRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string payloadType = 1; */
        if (message.payloadType !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.payloadType);
        /* string payloadId = 2; */
        if (message.payloadId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.payloadId);
        /* string magicLinkId = 3; */
        if (message.magicLinkId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.magicLinkId);
        /* string createdBy = 4; */
        if (message.createdBy !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.createdBy);
        /* google.protobuf.Timestamp createdAt = 5; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ReviewRequest
 */
export const ReviewRequest = new ReviewRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetReviewRequestsResponse$Type extends MessageType<GetReviewRequestsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetReviewRequestsResponse", [
            { no: 1, name: "reviewRequests", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ReviewRequest }
        ]);
    }
    create(value?: PartialMessage<GetReviewRequestsResponse>): GetReviewRequestsResponse {
        const message = { reviewRequests: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetReviewRequestsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetReviewRequestsResponse): GetReviewRequestsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.ReviewRequest reviewRequests */ 1:
                    message.reviewRequests.push(ReviewRequest.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetReviewRequestsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.ReviewRequest reviewRequests = 1; */
        for (let i = 0; i < message.reviewRequests.length; i++)
            ReviewRequest.internalBinaryWrite(message.reviewRequests[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetReviewRequestsResponse
 */
export const GetReviewRequestsResponse = new GetReviewRequestsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostDeleteRewardCodesRequest$Type extends MessageType<PostDeleteRewardCodesRequest> {
    constructor() {
        super("one.plan3t.core.model.PostDeleteRewardCodesRequest", [
            { no: 1, name: "transactionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "partnerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "rewardId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "codes", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PostDeleteRewardCodesRequest>): PostDeleteRewardCodesRequest {
        const message = { transactionId: "", partnerId: "", rewardId: "", codes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostDeleteRewardCodesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostDeleteRewardCodesRequest): PostDeleteRewardCodesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string transactionId */ 1:
                    message.transactionId = reader.string();
                    break;
                case /* string partnerId */ 2:
                    message.partnerId = reader.string();
                    break;
                case /* string rewardId */ 3:
                    message.rewardId = reader.string();
                    break;
                case /* repeated string codes */ 4:
                    message.codes.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostDeleteRewardCodesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string transactionId = 1; */
        if (message.transactionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.transactionId);
        /* string partnerId = 2; */
        if (message.partnerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerId);
        /* string rewardId = 3; */
        if (message.rewardId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.rewardId);
        /* repeated string codes = 4; */
        for (let i = 0; i < message.codes.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.codes[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostDeleteRewardCodesRequest
 */
export const PostDeleteRewardCodesRequest = new PostDeleteRewardCodesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostDeleteRewardCodesResponse$Type extends MessageType<PostDeleteRewardCodesResponse> {
    constructor() {
        super("one.plan3t.core.model.PostDeleteRewardCodesResponse", []);
    }
    create(value?: PartialMessage<PostDeleteRewardCodesResponse>): PostDeleteRewardCodesResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostDeleteRewardCodesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostDeleteRewardCodesResponse): PostDeleteRewardCodesResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: PostDeleteRewardCodesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostDeleteRewardCodesResponse
 */
export const PostDeleteRewardCodesResponse = new PostDeleteRewardCodesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetImpersonationTokenRequest$Type extends MessageType<GetImpersonationTokenRequest> {
    constructor() {
        super("one.plan3t.core.model.GetImpersonationTokenRequest", [
            { no: 1, name: "partnerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetImpersonationTokenRequest>): GetImpersonationTokenRequest {
        const message = { partnerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetImpersonationTokenRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetImpersonationTokenRequest): GetImpersonationTokenRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string partnerId */ 1:
                    message.partnerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetImpersonationTokenRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string partnerId = 1; */
        if (message.partnerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.partnerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetImpersonationTokenRequest
 */
export const GetImpersonationTokenRequest = new GetImpersonationTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetImpersonationTokenResponse$Type extends MessageType<GetImpersonationTokenResponse> {
    constructor() {
        super("one.plan3t.core.model.GetImpersonationTokenResponse", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetImpersonationTokenResponse>): GetImpersonationTokenResponse {
        const message = { token: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetImpersonationTokenResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetImpersonationTokenResponse): GetImpersonationTokenResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetImpersonationTokenResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetImpersonationTokenResponse
 */
export const GetImpersonationTokenResponse = new GetImpersonationTokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostAdjustRewardPriceRequest$Type extends MessageType<PostAdjustRewardPriceRequest> {
    constructor() {
        super("one.plan3t.core.model.PostAdjustRewardPriceRequest", [
            { no: 1, name: "transactionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "partnerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "rewardId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "points", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<PostAdjustRewardPriceRequest>): PostAdjustRewardPriceRequest {
        const message = { transactionId: "", partnerId: "", rewardId: "", points: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostAdjustRewardPriceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostAdjustRewardPriceRequest): PostAdjustRewardPriceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string transactionId */ 1:
                    message.transactionId = reader.string();
                    break;
                case /* string partnerId */ 2:
                    message.partnerId = reader.string();
                    break;
                case /* string rewardId */ 3:
                    message.rewardId = reader.string();
                    break;
                case /* int32 points */ 4:
                    message.points = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostAdjustRewardPriceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string transactionId = 1; */
        if (message.transactionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.transactionId);
        /* string partnerId = 2; */
        if (message.partnerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerId);
        /* string rewardId = 3; */
        if (message.rewardId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.rewardId);
        /* int32 points = 4; */
        if (message.points !== 0)
            writer.tag(4, WireType.Varint).int32(message.points);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostAdjustRewardPriceRequest
 */
export const PostAdjustRewardPriceRequest = new PostAdjustRewardPriceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostAdjustRewardPriceResponse$Type extends MessageType<PostAdjustRewardPriceResponse> {
    constructor() {
        super("one.plan3t.core.model.PostAdjustRewardPriceResponse", []);
    }
    create(value?: PartialMessage<PostAdjustRewardPriceResponse>): PostAdjustRewardPriceResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostAdjustRewardPriceResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostAdjustRewardPriceResponse): PostAdjustRewardPriceResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: PostAdjustRewardPriceResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostAdjustRewardPriceResponse
 */
export const PostAdjustRewardPriceResponse = new PostAdjustRewardPriceResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetRewardStatusRequest$Type extends MessageType<SetRewardStatusRequest> {
    constructor() {
        super("one.plan3t.core.model.SetRewardStatusRequest", [
            { no: 1, name: "partnerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "rewardId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "state", kind: "enum", T: () => ["one.plan3t.core.model.DraftState", DraftState] }
        ]);
    }
    create(value?: PartialMessage<SetRewardStatusRequest>): SetRewardStatusRequest {
        const message = { partnerId: "", rewardId: "", state: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetRewardStatusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetRewardStatusRequest): SetRewardStatusRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string partnerId */ 1:
                    message.partnerId = reader.string();
                    break;
                case /* string rewardId */ 2:
                    message.rewardId = reader.string();
                    break;
                case /* one.plan3t.core.model.DraftState state */ 3:
                    message.state = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetRewardStatusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string partnerId = 1; */
        if (message.partnerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.partnerId);
        /* string rewardId = 2; */
        if (message.rewardId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.rewardId);
        /* one.plan3t.core.model.DraftState state = 3; */
        if (message.state !== 0)
            writer.tag(3, WireType.Varint).int32(message.state);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SetRewardStatusRequest
 */
export const SetRewardStatusRequest = new SetRewardStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetRewardStatusResponse$Type extends MessageType<SetRewardStatusResponse> {
    constructor() {
        super("one.plan3t.core.model.SetRewardStatusResponse", []);
    }
    create(value?: PartialMessage<SetRewardStatusResponse>): SetRewardStatusResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetRewardStatusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetRewardStatusResponse): SetRewardStatusResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SetRewardStatusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SetRewardStatusResponse
 */
export const SetRewardStatusResponse = new SetRewardStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetValidUntilForRewardRequest$Type extends MessageType<SetValidUntilForRewardRequest> {
    constructor() {
        super("one.plan3t.core.model.SetValidUntilForRewardRequest", [
            { no: 1, name: "partnerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "rewardId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "valid_until", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<SetValidUntilForRewardRequest>): SetValidUntilForRewardRequest {
        const message = { partnerId: "", rewardId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetValidUntilForRewardRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetValidUntilForRewardRequest): SetValidUntilForRewardRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string partnerId */ 1:
                    message.partnerId = reader.string();
                    break;
                case /* string rewardId */ 2:
                    message.rewardId = reader.string();
                    break;
                case /* google.protobuf.Timestamp valid_until */ 3:
                    message.validUntil = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.validUntil);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetValidUntilForRewardRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string partnerId = 1; */
        if (message.partnerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.partnerId);
        /* string rewardId = 2; */
        if (message.rewardId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.rewardId);
        /* google.protobuf.Timestamp valid_until = 3; */
        if (message.validUntil)
            Timestamp.internalBinaryWrite(message.validUntil, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SetValidUntilForRewardRequest
 */
export const SetValidUntilForRewardRequest = new SetValidUntilForRewardRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetValidUntilForRewardResponse$Type extends MessageType<SetValidUntilForRewardResponse> {
    constructor() {
        super("one.plan3t.core.model.SetValidUntilForRewardResponse", []);
    }
    create(value?: PartialMessage<SetValidUntilForRewardResponse>): SetValidUntilForRewardResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetValidUntilForRewardResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetValidUntilForRewardResponse): SetValidUntilForRewardResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SetValidUntilForRewardResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SetValidUntilForRewardResponse
 */
export const SetValidUntilForRewardResponse = new SetValidUntilForRewardResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationBroadcastMessage$Type extends MessageType<NotificationBroadcastMessage> {
    constructor() {
        super("one.plan3t.core.model.NotificationBroadcastMessage", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "channelId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "body", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "action", kind: "message", T: () => NotificationAction },
            { no: 7, name: "payload", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 8, name: "timestamp", kind: "message", T: () => Timestamp },
            { no: 9, name: "signedOffBy", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "state", kind: "enum", T: () => ["one.plan3t.core.model.NotificationMessageBroadcastState", NotificationMessageBroadcastState] }
        ]);
    }
    create(value?: PartialMessage<NotificationBroadcastMessage>): NotificationBroadcastMessage {
        const message = { id: "", channelId: "", title: "", body: "", category: "", payload: {}, signedOffBy: [], state: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NotificationBroadcastMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NotificationBroadcastMessage): NotificationBroadcastMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string channelId */ 2:
                    message.channelId = reader.string();
                    break;
                case /* string title */ 3:
                    message.title = reader.string();
                    break;
                case /* string body */ 4:
                    message.body = reader.string();
                    break;
                case /* string category */ 5:
                    message.category = reader.string();
                    break;
                case /* one.plan3t.core.model.NotificationAction action */ 6:
                    message.action = NotificationAction.internalBinaryRead(reader, reader.uint32(), options, message.action);
                    break;
                case /* map<string, string> payload */ 7:
                    this.binaryReadMap7(message.payload, reader, options);
                    break;
                case /* google.protobuf.Timestamp timestamp */ 8:
                    message.timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timestamp);
                    break;
                case /* repeated string signedOffBy */ 9:
                    message.signedOffBy.push(reader.string());
                    break;
                case /* one.plan3t.core.model.NotificationMessageBroadcastState state */ 10:
                    message.state = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap7(map: NotificationBroadcastMessage["payload"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof NotificationBroadcastMessage["payload"] | undefined, val: NotificationBroadcastMessage["payload"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.model.NotificationBroadcastMessage.payload");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: NotificationBroadcastMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string channelId = 2; */
        if (message.channelId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.channelId);
        /* string title = 3; */
        if (message.title !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.title);
        /* string body = 4; */
        if (message.body !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.body);
        /* string category = 5; */
        if (message.category !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.category);
        /* one.plan3t.core.model.NotificationAction action = 6; */
        if (message.action)
            NotificationAction.internalBinaryWrite(message.action, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* map<string, string> payload = 7; */
        for (let k of Object.keys(message.payload))
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.payload[k]).join();
        /* google.protobuf.Timestamp timestamp = 8; */
        if (message.timestamp)
            Timestamp.internalBinaryWrite(message.timestamp, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* repeated string signedOffBy = 9; */
        for (let i = 0; i < message.signedOffBy.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.signedOffBy[i]);
        /* one.plan3t.core.model.NotificationMessageBroadcastState state = 10; */
        if (message.state !== 0)
            writer.tag(10, WireType.Varint).int32(message.state);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.NotificationBroadcastMessage
 */
export const NotificationBroadcastMessage = new NotificationBroadcastMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateNotificationBroadcastMessageRequest$Type extends MessageType<CreateNotificationBroadcastMessageRequest> {
    constructor() {
        super("one.plan3t.core.model.CreateNotificationBroadcastMessageRequest", [
            { no: 1, name: "message", kind: "message", T: () => NotificationBroadcastMessage }
        ]);
    }
    create(value?: PartialMessage<CreateNotificationBroadcastMessageRequest>): CreateNotificationBroadcastMessageRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateNotificationBroadcastMessageRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateNotificationBroadcastMessageRequest): CreateNotificationBroadcastMessageRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.NotificationBroadcastMessage message */ 1:
                    message.message = NotificationBroadcastMessage.internalBinaryRead(reader, reader.uint32(), options, message.message);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateNotificationBroadcastMessageRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.NotificationBroadcastMessage message = 1; */
        if (message.message)
            NotificationBroadcastMessage.internalBinaryWrite(message.message, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CreateNotificationBroadcastMessageRequest
 */
export const CreateNotificationBroadcastMessageRequest = new CreateNotificationBroadcastMessageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateNotificationBroadcastMessageResponse$Type extends MessageType<CreateNotificationBroadcastMessageResponse> {
    constructor() {
        super("one.plan3t.core.model.CreateNotificationBroadcastMessageResponse", [
            { no: 1, name: "message", kind: "message", T: () => NotificationBroadcastMessage }
        ]);
    }
    create(value?: PartialMessage<CreateNotificationBroadcastMessageResponse>): CreateNotificationBroadcastMessageResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateNotificationBroadcastMessageResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateNotificationBroadcastMessageResponse): CreateNotificationBroadcastMessageResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.NotificationBroadcastMessage message */ 1:
                    message.message = NotificationBroadcastMessage.internalBinaryRead(reader, reader.uint32(), options, message.message);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateNotificationBroadcastMessageResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.NotificationBroadcastMessage message = 1; */
        if (message.message)
            NotificationBroadcastMessage.internalBinaryWrite(message.message, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CreateNotificationBroadcastMessageResponse
 */
export const CreateNotificationBroadcastMessageResponse = new CreateNotificationBroadcastMessageResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNotificationBroadcastMessagesRequest$Type extends MessageType<GetNotificationBroadcastMessagesRequest> {
    constructor() {
        super("one.plan3t.core.model.GetNotificationBroadcastMessagesRequest", []);
    }
    create(value?: PartialMessage<GetNotificationBroadcastMessagesRequest>): GetNotificationBroadcastMessagesRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNotificationBroadcastMessagesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNotificationBroadcastMessagesRequest): GetNotificationBroadcastMessagesRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetNotificationBroadcastMessagesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetNotificationBroadcastMessagesRequest
 */
export const GetNotificationBroadcastMessagesRequest = new GetNotificationBroadcastMessagesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNotificationBroadcastMessagesResponse$Type extends MessageType<GetNotificationBroadcastMessagesResponse> {
    constructor() {
        super("one.plan3t.core.model.GetNotificationBroadcastMessagesResponse", [
            { no: 1, name: "messages", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NotificationBroadcastMessage }
        ]);
    }
    create(value?: PartialMessage<GetNotificationBroadcastMessagesResponse>): GetNotificationBroadcastMessagesResponse {
        const message = { messages: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNotificationBroadcastMessagesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNotificationBroadcastMessagesResponse): GetNotificationBroadcastMessagesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.NotificationBroadcastMessage messages */ 1:
                    message.messages.push(NotificationBroadcastMessage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNotificationBroadcastMessagesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.NotificationBroadcastMessage messages = 1; */
        for (let i = 0; i < message.messages.length; i++)
            NotificationBroadcastMessage.internalBinaryWrite(message.messages[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetNotificationBroadcastMessagesResponse
 */
export const GetNotificationBroadcastMessagesResponse = new GetNotificationBroadcastMessagesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendNotificationBroadcastMessageRequest$Type extends MessageType<SendNotificationBroadcastMessageRequest> {
    constructor() {
        super("one.plan3t.core.model.SendNotificationBroadcastMessageRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SendNotificationBroadcastMessageRequest>): SendNotificationBroadcastMessageRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SendNotificationBroadcastMessageRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendNotificationBroadcastMessageRequest): SendNotificationBroadcastMessageRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendNotificationBroadcastMessageRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SendNotificationBroadcastMessageRequest
 */
export const SendNotificationBroadcastMessageRequest = new SendNotificationBroadcastMessageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendNotificationBroadcastMessageResponse$Type extends MessageType<SendNotificationBroadcastMessageResponse> {
    constructor() {
        super("one.plan3t.core.model.SendNotificationBroadcastMessageResponse", [
            { no: 1, name: "success_count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "failure_count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<SendNotificationBroadcastMessageResponse>): SendNotificationBroadcastMessageResponse {
        const message = { successCount: 0, failureCount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SendNotificationBroadcastMessageResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendNotificationBroadcastMessageResponse): SendNotificationBroadcastMessageResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 success_count */ 1:
                    message.successCount = reader.int64().toNumber();
                    break;
                case /* int64 failure_count */ 2:
                    message.failureCount = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendNotificationBroadcastMessageResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 success_count = 1; */
        if (message.successCount !== 0)
            writer.tag(1, WireType.Varint).int64(message.successCount);
        /* int64 failure_count = 2; */
        if (message.failureCount !== 0)
            writer.tag(2, WireType.Varint).int64(message.failureCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SendNotificationBroadcastMessageResponse
 */
export const SendNotificationBroadcastMessageResponse = new SendNotificationBroadcastMessageResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNotificationBroadcastStatsRequest$Type extends MessageType<GetNotificationBroadcastStatsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetNotificationBroadcastStatsRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetNotificationBroadcastStatsRequest>): GetNotificationBroadcastStatsRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNotificationBroadcastStatsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNotificationBroadcastStatsRequest): GetNotificationBroadcastStatsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNotificationBroadcastStatsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetNotificationBroadcastStatsRequest
 */
export const GetNotificationBroadcastStatsRequest = new GetNotificationBroadcastStatsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNotificationBroadcastStatsResponse$Type extends MessageType<GetNotificationBroadcastStatsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetNotificationBroadcastStatsResponse", [
            { no: 1, name: "num_sent", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "num_opened", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetNotificationBroadcastStatsResponse>): GetNotificationBroadcastStatsResponse {
        const message = { numSent: 0, numOpened: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNotificationBroadcastStatsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNotificationBroadcastStatsResponse): GetNotificationBroadcastStatsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 num_sent */ 1:
                    message.numSent = reader.int64().toNumber();
                    break;
                case /* int64 num_opened */ 2:
                    message.numOpened = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNotificationBroadcastStatsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 num_sent = 1; */
        if (message.numSent !== 0)
            writer.tag(1, WireType.Varint).int64(message.numSent);
        /* int64 num_opened = 2; */
        if (message.numOpened !== 0)
            writer.tag(2, WireType.Varint).int64(message.numOpened);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetNotificationBroadcastStatsResponse
 */
export const GetNotificationBroadcastStatsResponse = new GetNotificationBroadcastStatsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateVoucherRequest$Type extends MessageType<CreateVoucherRequest> {
    constructor() {
        super("one.plan3t.core.model.CreateVoucherRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "value", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "comment", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateVoucherRequest>): CreateVoucherRequest {
        const message = { id: "", code: "", value: 0, comment: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateVoucherRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateVoucherRequest): CreateVoucherRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string code */ 2:
                    message.code = reader.string();
                    break;
                case /* int32 value */ 3:
                    message.value = reader.int32();
                    break;
                case /* string comment */ 4:
                    message.comment = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateVoucherRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string code = 2; */
        if (message.code !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.code);
        /* int32 value = 3; */
        if (message.value !== 0)
            writer.tag(3, WireType.Varint).int32(message.value);
        /* string comment = 4; */
        if (message.comment !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.comment);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CreateVoucherRequest
 */
export const CreateVoucherRequest = new CreateVoucherRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateVoucherResponse$Type extends MessageType<CreateVoucherResponse> {
    constructor() {
        super("one.plan3t.core.model.CreateVoucherResponse", []);
    }
    create(value?: PartialMessage<CreateVoucherResponse>): CreateVoucherResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateVoucherResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateVoucherResponse): CreateVoucherResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CreateVoucherResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CreateVoucherResponse
 */
export const CreateVoucherResponse = new CreateVoucherResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemCategoryAuthoring$Type extends MessageType<ItemCategoryAuthoring> {
    constructor() {
        super("one.plan3t.core.model.ItemCategoryAuthoring", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "coreIndustry", kind: "enum", T: () => ["one.plan3t.core.model.CoreIndustry", CoreIndustry] },
            { no: 3, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "icon_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "color", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "item_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "numAboveTheFold", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 100, name: "createdBy", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 101, name: "createdAt", kind: "message", T: () => Timestamp },
            { no: 102, name: "modifiedBy", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 103, name: "modifiedAt", kind: "message", T: () => Timestamp },
            { no: 104, name: "position", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ItemCategoryAuthoring>): ItemCategoryAuthoring {
        const message = { id: "", coreIndustry: 0, title: "", iconName: "", color: 0, active: false, itemIds: [], numAboveTheFold: 0, createdBy: "", modifiedBy: "", position: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ItemCategoryAuthoring>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemCategoryAuthoring): ItemCategoryAuthoring {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* one.plan3t.core.model.CoreIndustry coreIndustry */ 2:
                    message.coreIndustry = reader.int32();
                    break;
                case /* string title */ 3:
                    message.title = reader.string();
                    break;
                case /* string icon_name */ 4:
                    message.iconName = reader.string();
                    break;
                case /* int64 color */ 5:
                    message.color = reader.int64().toNumber();
                    break;
                case /* bool active */ 6:
                    message.active = reader.bool();
                    break;
                case /* repeated string item_ids */ 7:
                    message.itemIds.push(reader.string());
                    break;
                case /* int32 numAboveTheFold */ 8:
                    message.numAboveTheFold = reader.int32();
                    break;
                case /* string createdBy */ 100:
                    message.createdBy = reader.string();
                    break;
                case /* google.protobuf.Timestamp createdAt */ 101:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string modifiedBy */ 102:
                    message.modifiedBy = reader.string();
                    break;
                case /* google.protobuf.Timestamp modifiedAt */ 103:
                    message.modifiedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.modifiedAt);
                    break;
                case /* int32 position */ 104:
                    message.position = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemCategoryAuthoring, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* one.plan3t.core.model.CoreIndustry coreIndustry = 2; */
        if (message.coreIndustry !== 0)
            writer.tag(2, WireType.Varint).int32(message.coreIndustry);
        /* string title = 3; */
        if (message.title !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.title);
        /* string icon_name = 4; */
        if (message.iconName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.iconName);
        /* int64 color = 5; */
        if (message.color !== 0)
            writer.tag(5, WireType.Varint).int64(message.color);
        /* bool active = 6; */
        if (message.active !== false)
            writer.tag(6, WireType.Varint).bool(message.active);
        /* repeated string item_ids = 7; */
        for (let i = 0; i < message.itemIds.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.itemIds[i]);
        /* int32 numAboveTheFold = 8; */
        if (message.numAboveTheFold !== 0)
            writer.tag(8, WireType.Varint).int32(message.numAboveTheFold);
        /* string createdBy = 100; */
        if (message.createdBy !== "")
            writer.tag(100, WireType.LengthDelimited).string(message.createdBy);
        /* google.protobuf.Timestamp createdAt = 101; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(101, WireType.LengthDelimited).fork(), options).join();
        /* string modifiedBy = 102; */
        if (message.modifiedBy !== "")
            writer.tag(102, WireType.LengthDelimited).string(message.modifiedBy);
        /* google.protobuf.Timestamp modifiedAt = 103; */
        if (message.modifiedAt)
            Timestamp.internalBinaryWrite(message.modifiedAt, writer.tag(103, WireType.LengthDelimited).fork(), options).join();
        /* int32 position = 104; */
        if (message.position !== 0)
            writer.tag(104, WireType.Varint).int32(message.position);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ItemCategoryAuthoring
 */
export const ItemCategoryAuthoring = new ItemCategoryAuthoring$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRewardsCategoryAuthoringItemsRequest$Type extends MessageType<GetRewardsCategoryAuthoringItemsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetRewardsCategoryAuthoringItemsRequest", [
            { no: 1, name: "billing_type", kind: "enum", T: () => ["one.plan3t.core.model.RewardBillingType", RewardBillingType, "REWARD_BILLING_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<GetRewardsCategoryAuthoringItemsRequest>): GetRewardsCategoryAuthoringItemsRequest {
        const message = { billingType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRewardsCategoryAuthoringItemsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRewardsCategoryAuthoringItemsRequest): GetRewardsCategoryAuthoringItemsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.RewardBillingType billing_type */ 1:
                    message.billingType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRewardsCategoryAuthoringItemsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.RewardBillingType billing_type = 1; */
        if (message.billingType !== 0)
            writer.tag(1, WireType.Varint).int32(message.billingType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRewardsCategoryAuthoringItemsRequest
 */
export const GetRewardsCategoryAuthoringItemsRequest = new GetRewardsCategoryAuthoringItemsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRewardsCategoryAuthoringItemsResponse$Type extends MessageType<GetRewardsCategoryAuthoringItemsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetRewardsCategoryAuthoringItemsResponse", [
            { no: 1, name: "categories", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemCategoryAuthoring }
        ]);
    }
    create(value?: PartialMessage<GetRewardsCategoryAuthoringItemsResponse>): GetRewardsCategoryAuthoringItemsResponse {
        const message = { categories: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRewardsCategoryAuthoringItemsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRewardsCategoryAuthoringItemsResponse): GetRewardsCategoryAuthoringItemsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.ItemCategoryAuthoring categories */ 1:
                    message.categories.push(ItemCategoryAuthoring.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRewardsCategoryAuthoringItemsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.ItemCategoryAuthoring categories = 1; */
        for (let i = 0; i < message.categories.length; i++)
            ItemCategoryAuthoring.internalBinaryWrite(message.categories[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRewardsCategoryAuthoringItemsResponse
 */
export const GetRewardsCategoryAuthoringItemsResponse = new GetRewardsCategoryAuthoringItemsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WriteRewardsCategoryAuthoringRequest$Type extends MessageType<WriteRewardsCategoryAuthoringRequest> {
    constructor() {
        super("one.plan3t.core.model.WriteRewardsCategoryAuthoringRequest", [
            { no: 1, name: "item", kind: "message", T: () => ItemCategoryAuthoring },
            { no: 2, name: "billing_type", kind: "enum", T: () => ["one.plan3t.core.model.RewardBillingType", RewardBillingType, "REWARD_BILLING_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<WriteRewardsCategoryAuthoringRequest>): WriteRewardsCategoryAuthoringRequest {
        const message = { billingType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WriteRewardsCategoryAuthoringRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WriteRewardsCategoryAuthoringRequest): WriteRewardsCategoryAuthoringRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.ItemCategoryAuthoring item */ 1:
                    message.item = ItemCategoryAuthoring.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                case /* one.plan3t.core.model.RewardBillingType billing_type */ 2:
                    message.billingType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WriteRewardsCategoryAuthoringRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.ItemCategoryAuthoring item = 1; */
        if (message.item)
            ItemCategoryAuthoring.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.RewardBillingType billing_type = 2; */
        if (message.billingType !== 0)
            writer.tag(2, WireType.Varint).int32(message.billingType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.WriteRewardsCategoryAuthoringRequest
 */
export const WriteRewardsCategoryAuthoringRequest = new WriteRewardsCategoryAuthoringRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WriteRewardsCategoryAuthoringResponse$Type extends MessageType<WriteRewardsCategoryAuthoringResponse> {
    constructor() {
        super("one.plan3t.core.model.WriteRewardsCategoryAuthoringResponse", []);
    }
    create(value?: PartialMessage<WriteRewardsCategoryAuthoringResponse>): WriteRewardsCategoryAuthoringResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WriteRewardsCategoryAuthoringResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WriteRewardsCategoryAuthoringResponse): WriteRewardsCategoryAuthoringResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: WriteRewardsCategoryAuthoringResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.WriteRewardsCategoryAuthoringResponse
 */
export const WriteRewardsCategoryAuthoringResponse = new WriteRewardsCategoryAuthoringResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPartnerCategoryAuthoringItemsRequest$Type extends MessageType<GetPartnerCategoryAuthoringItemsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetPartnerCategoryAuthoringItemsRequest", []);
    }
    create(value?: PartialMessage<GetPartnerCategoryAuthoringItemsRequest>): GetPartnerCategoryAuthoringItemsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPartnerCategoryAuthoringItemsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPartnerCategoryAuthoringItemsRequest): GetPartnerCategoryAuthoringItemsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetPartnerCategoryAuthoringItemsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetPartnerCategoryAuthoringItemsRequest
 */
export const GetPartnerCategoryAuthoringItemsRequest = new GetPartnerCategoryAuthoringItemsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPartnerCategoryAuthoringItemsResponse$Type extends MessageType<GetPartnerCategoryAuthoringItemsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetPartnerCategoryAuthoringItemsResponse", [
            { no: 1, name: "categories", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemCategoryAuthoring }
        ]);
    }
    create(value?: PartialMessage<GetPartnerCategoryAuthoringItemsResponse>): GetPartnerCategoryAuthoringItemsResponse {
        const message = { categories: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPartnerCategoryAuthoringItemsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPartnerCategoryAuthoringItemsResponse): GetPartnerCategoryAuthoringItemsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.ItemCategoryAuthoring categories */ 1:
                    message.categories.push(ItemCategoryAuthoring.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPartnerCategoryAuthoringItemsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.ItemCategoryAuthoring categories = 1; */
        for (let i = 0; i < message.categories.length; i++)
            ItemCategoryAuthoring.internalBinaryWrite(message.categories[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetPartnerCategoryAuthoringItemsResponse
 */
export const GetPartnerCategoryAuthoringItemsResponse = new GetPartnerCategoryAuthoringItemsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WritePartnerCategoryAuthoringRequest$Type extends MessageType<WritePartnerCategoryAuthoringRequest> {
    constructor() {
        super("one.plan3t.core.model.WritePartnerCategoryAuthoringRequest", [
            { no: 1, name: "item", kind: "message", T: () => ItemCategoryAuthoring }
        ]);
    }
    create(value?: PartialMessage<WritePartnerCategoryAuthoringRequest>): WritePartnerCategoryAuthoringRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WritePartnerCategoryAuthoringRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WritePartnerCategoryAuthoringRequest): WritePartnerCategoryAuthoringRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.ItemCategoryAuthoring item */ 1:
                    message.item = ItemCategoryAuthoring.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WritePartnerCategoryAuthoringRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.ItemCategoryAuthoring item = 1; */
        if (message.item)
            ItemCategoryAuthoring.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.WritePartnerCategoryAuthoringRequest
 */
export const WritePartnerCategoryAuthoringRequest = new WritePartnerCategoryAuthoringRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WritePartnerCategoryAuthoringResponse$Type extends MessageType<WritePartnerCategoryAuthoringResponse> {
    constructor() {
        super("one.plan3t.core.model.WritePartnerCategoryAuthoringResponse", []);
    }
    create(value?: PartialMessage<WritePartnerCategoryAuthoringResponse>): WritePartnerCategoryAuthoringResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WritePartnerCategoryAuthoringResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WritePartnerCategoryAuthoringResponse): WritePartnerCategoryAuthoringResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: WritePartnerCategoryAuthoringResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.WritePartnerCategoryAuthoringResponse
 */
export const WritePartnerCategoryAuthoringResponse = new WritePartnerCategoryAuthoringResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipsCategoryAuthoringItemsRequest$Type extends MessageType<GetTipsCategoryAuthoringItemsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetTipsCategoryAuthoringItemsRequest", []);
    }
    create(value?: PartialMessage<GetTipsCategoryAuthoringItemsRequest>): GetTipsCategoryAuthoringItemsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipsCategoryAuthoringItemsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipsCategoryAuthoringItemsRequest): GetTipsCategoryAuthoringItemsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetTipsCategoryAuthoringItemsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipsCategoryAuthoringItemsRequest
 */
export const GetTipsCategoryAuthoringItemsRequest = new GetTipsCategoryAuthoringItemsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipsCategoryAuthoringItemsResponse$Type extends MessageType<GetTipsCategoryAuthoringItemsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetTipsCategoryAuthoringItemsResponse", [
            { no: 1, name: "categories", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemCategoryAuthoring }
        ]);
    }
    create(value?: PartialMessage<GetTipsCategoryAuthoringItemsResponse>): GetTipsCategoryAuthoringItemsResponse {
        const message = { categories: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipsCategoryAuthoringItemsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipsCategoryAuthoringItemsResponse): GetTipsCategoryAuthoringItemsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.ItemCategoryAuthoring categories */ 1:
                    message.categories.push(ItemCategoryAuthoring.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipsCategoryAuthoringItemsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.ItemCategoryAuthoring categories = 1; */
        for (let i = 0; i < message.categories.length; i++)
            ItemCategoryAuthoring.internalBinaryWrite(message.categories[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipsCategoryAuthoringItemsResponse
 */
export const GetTipsCategoryAuthoringItemsResponse = new GetTipsCategoryAuthoringItemsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WriteTipsCategoryAuthoringRequest$Type extends MessageType<WriteTipsCategoryAuthoringRequest> {
    constructor() {
        super("one.plan3t.core.model.WriteTipsCategoryAuthoringRequest", [
            { no: 1, name: "item", kind: "message", T: () => ItemCategoryAuthoring }
        ]);
    }
    create(value?: PartialMessage<WriteTipsCategoryAuthoringRequest>): WriteTipsCategoryAuthoringRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WriteTipsCategoryAuthoringRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WriteTipsCategoryAuthoringRequest): WriteTipsCategoryAuthoringRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.ItemCategoryAuthoring item */ 1:
                    message.item = ItemCategoryAuthoring.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WriteTipsCategoryAuthoringRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.ItemCategoryAuthoring item = 1; */
        if (message.item)
            ItemCategoryAuthoring.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.WriteTipsCategoryAuthoringRequest
 */
export const WriteTipsCategoryAuthoringRequest = new WriteTipsCategoryAuthoringRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WriteTipsCategoryAuthoringResponse$Type extends MessageType<WriteTipsCategoryAuthoringResponse> {
    constructor() {
        super("one.plan3t.core.model.WriteTipsCategoryAuthoringResponse", []);
    }
    create(value?: PartialMessage<WriteTipsCategoryAuthoringResponse>): WriteTipsCategoryAuthoringResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WriteTipsCategoryAuthoringResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WriteTipsCategoryAuthoringResponse): WriteTipsCategoryAuthoringResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: WriteTipsCategoryAuthoringResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.WriteTipsCategoryAuthoringResponse
 */
export const WriteTipsCategoryAuthoringResponse = new WriteTipsCategoryAuthoringResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNumberOfLotteryTicketsRequest$Type extends MessageType<GetNumberOfLotteryTicketsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetNumberOfLotteryTicketsRequest", [
            { no: 1, name: "priceDrawingId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetNumberOfLotteryTicketsRequest>): GetNumberOfLotteryTicketsRequest {
        const message = { priceDrawingId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNumberOfLotteryTicketsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNumberOfLotteryTicketsRequest): GetNumberOfLotteryTicketsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string priceDrawingId */ 1:
                    message.priceDrawingId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNumberOfLotteryTicketsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string priceDrawingId = 1; */
        if (message.priceDrawingId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.priceDrawingId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetNumberOfLotteryTicketsRequest
 */
export const GetNumberOfLotteryTicketsRequest = new GetNumberOfLotteryTicketsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNumberOfLotteryTicketsResponse$Type extends MessageType<GetNumberOfLotteryTicketsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetNumberOfLotteryTicketsResponse", [
            { no: 1, name: "numTickets", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetNumberOfLotteryTicketsResponse>): GetNumberOfLotteryTicketsResponse {
        const message = { numTickets: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNumberOfLotteryTicketsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNumberOfLotteryTicketsResponse): GetNumberOfLotteryTicketsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 numTickets */ 1:
                    message.numTickets = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNumberOfLotteryTicketsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 numTickets = 1; */
        if (message.numTickets !== 0)
            writer.tag(1, WireType.Varint).int64(message.numTickets);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetNumberOfLotteryTicketsResponse
 */
export const GetNumberOfLotteryTicketsResponse = new GetNumberOfLotteryTicketsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DrawRandomLotteryTicketsRequest$Type extends MessageType<DrawRandomLotteryTicketsRequest> {
    constructor() {
        super("one.plan3t.core.model.DrawRandomLotteryTicketsRequest", [
            { no: 1, name: "priceDrawingId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "numTickets", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "excludedTicketIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DrawRandomLotteryTicketsRequest>): DrawRandomLotteryTicketsRequest {
        const message = { priceDrawingId: "", numTickets: 0, excludedTicketIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DrawRandomLotteryTicketsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DrawRandomLotteryTicketsRequest): DrawRandomLotteryTicketsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string priceDrawingId */ 1:
                    message.priceDrawingId = reader.string();
                    break;
                case /* int32 numTickets */ 2:
                    message.numTickets = reader.int32();
                    break;
                case /* repeated string excludedTicketIds */ 3:
                    message.excludedTicketIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DrawRandomLotteryTicketsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string priceDrawingId = 1; */
        if (message.priceDrawingId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.priceDrawingId);
        /* int32 numTickets = 2; */
        if (message.numTickets !== 0)
            writer.tag(2, WireType.Varint).int32(message.numTickets);
        /* repeated string excludedTicketIds = 3; */
        for (let i = 0; i < message.excludedTicketIds.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.excludedTicketIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.DrawRandomLotteryTicketsRequest
 */
export const DrawRandomLotteryTicketsRequest = new DrawRandomLotteryTicketsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DrawRandomLotteryTicketsResponse$Type extends MessageType<DrawRandomLotteryTicketsResponse> {
    constructor() {
        super("one.plan3t.core.model.DrawRandomLotteryTicketsResponse", [
            { no: 1, name: "tickets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LotteryTicket }
        ]);
    }
    create(value?: PartialMessage<DrawRandomLotteryTicketsResponse>): DrawRandomLotteryTicketsResponse {
        const message = { tickets: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DrawRandomLotteryTicketsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DrawRandomLotteryTicketsResponse): DrawRandomLotteryTicketsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.LotteryTicket tickets */ 1:
                    message.tickets.push(LotteryTicket.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DrawRandomLotteryTicketsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.LotteryTicket tickets = 1; */
        for (let i = 0; i < message.tickets.length; i++)
            LotteryTicket.internalBinaryWrite(message.tickets[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.DrawRandomLotteryTicketsResponse
 */
export const DrawRandomLotteryTicketsResponse = new DrawRandomLotteryTicketsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetLotteryWinnerTicketsRequest$Type extends MessageType<SetLotteryWinnerTicketsRequest> {
    constructor() {
        super("one.plan3t.core.model.SetLotteryWinnerTicketsRequest", [
            { no: 1, name: "priceDrawingId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "ticketIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SetLotteryWinnerTicketsRequest>): SetLotteryWinnerTicketsRequest {
        const message = { priceDrawingId: "", ticketIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetLotteryWinnerTicketsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetLotteryWinnerTicketsRequest): SetLotteryWinnerTicketsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string priceDrawingId */ 1:
                    message.priceDrawingId = reader.string();
                    break;
                case /* repeated string ticketIds */ 2:
                    message.ticketIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetLotteryWinnerTicketsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string priceDrawingId = 1; */
        if (message.priceDrawingId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.priceDrawingId);
        /* repeated string ticketIds = 2; */
        for (let i = 0; i < message.ticketIds.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.ticketIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SetLotteryWinnerTicketsRequest
 */
export const SetLotteryWinnerTicketsRequest = new SetLotteryWinnerTicketsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetLotteryWinnerTicketsResponse$Type extends MessageType<SetLotteryWinnerTicketsResponse> {
    constructor() {
        super("one.plan3t.core.model.SetLotteryWinnerTicketsResponse", []);
    }
    create(value?: PartialMessage<SetLotteryWinnerTicketsResponse>): SetLotteryWinnerTicketsResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetLotteryWinnerTicketsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetLotteryWinnerTicketsResponse): SetLotteryWinnerTicketsResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SetLotteryWinnerTicketsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SetLotteryWinnerTicketsResponse
 */
export const SetLotteryWinnerTicketsResponse = new SetLotteryWinnerTicketsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLotteryTicketRequest$Type extends MessageType<GetLotteryTicketRequest> {
    constructor() {
        super("one.plan3t.core.model.GetLotteryTicketRequest", [
            { no: 1, name: "ticketId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetLotteryTicketRequest>): GetLotteryTicketRequest {
        const message = { ticketId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetLotteryTicketRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLotteryTicketRequest): GetLotteryTicketRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ticketId */ 1:
                    message.ticketId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLotteryTicketRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ticketId = 1; */
        if (message.ticketId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ticketId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetLotteryTicketRequest
 */
export const GetLotteryTicketRequest = new GetLotteryTicketRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLotteryTicketResponse$Type extends MessageType<GetLotteryTicketResponse> {
    constructor() {
        super("one.plan3t.core.model.GetLotteryTicketResponse", [
            { no: 1, name: "ticket", kind: "message", T: () => LotteryTicket }
        ]);
    }
    create(value?: PartialMessage<GetLotteryTicketResponse>): GetLotteryTicketResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetLotteryTicketResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLotteryTicketResponse): GetLotteryTicketResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.LotteryTicket ticket */ 1:
                    message.ticket = LotteryTicket.internalBinaryRead(reader, reader.uint32(), options, message.ticket);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLotteryTicketResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.LotteryTicket ticket = 1; */
        if (message.ticket)
            LotteryTicket.internalBinaryWrite(message.ticket, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetLotteryTicketResponse
 */
export const GetLotteryTicketResponse = new GetLotteryTicketResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LotteryPriceDrawingAuthoringItem$Type extends MessageType<LotteryPriceDrawingAuthoringItem> {
    constructor() {
        super("one.plan3t.core.model.LotteryPriceDrawingAuthoringItem", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "starts_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "ends_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "image", kind: "message", T: () => ImageResource },
            { no: 7, name: "ticketPrice", kind: "message", T: () => Plan3TPoint },
            { no: 8, name: "partnerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "winnerTicketIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "extraBenefitExplanation", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "cooperationProjectName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "cooperationProjectUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "cooperationProjectTagLine", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "cooperationProjectLogo", kind: "message", T: () => ImageResource },
            { no: 15, name: "terms", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "notification_title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 17, name: "notification_body", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "associated_partner_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "compensate_project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "donation_project_sku", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "donation_project_contribution", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 100, name: "createdBy", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 101, name: "createdAt", kind: "message", T: () => Timestamp },
            { no: 102, name: "modifiedBy", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 103, name: "modifiedAt", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<LotteryPriceDrawingAuthoringItem>): LotteryPriceDrawingAuthoringItem {
        const message = { id: "", title: "", description: "", partnerId: "", winnerTicketIds: [], extraBenefitExplanation: "", cooperationProjectName: "", cooperationProjectUrl: "", cooperationProjectTagLine: "", terms: "", notificationTitle: "", notificationBody: "", associatedPartnerIds: [], compensateProjectId: "", donationProjectSku: "", donationProjectContribution: 0, createdBy: "", modifiedBy: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LotteryPriceDrawingAuthoringItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LotteryPriceDrawingAuthoringItem): LotteryPriceDrawingAuthoringItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* google.protobuf.Timestamp starts_at */ 2:
                    message.startsAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.startsAt);
                    break;
                case /* google.protobuf.Timestamp ends_at */ 3:
                    message.endsAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.endsAt);
                    break;
                case /* string title */ 4:
                    message.title = reader.string();
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource image */ 6:
                    message.image = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.image);
                    break;
                case /* one.plan3t.core.model.Plan3TPoint ticketPrice */ 7:
                    message.ticketPrice = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.ticketPrice);
                    break;
                case /* string partnerId */ 8:
                    message.partnerId = reader.string();
                    break;
                case /* repeated string winnerTicketIds */ 9:
                    message.winnerTicketIds.push(reader.string());
                    break;
                case /* string extraBenefitExplanation */ 10:
                    message.extraBenefitExplanation = reader.string();
                    break;
                case /* string cooperationProjectName */ 11:
                    message.cooperationProjectName = reader.string();
                    break;
                case /* string cooperationProjectUrl */ 12:
                    message.cooperationProjectUrl = reader.string();
                    break;
                case /* string cooperationProjectTagLine */ 13:
                    message.cooperationProjectTagLine = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource cooperationProjectLogo */ 14:
                    message.cooperationProjectLogo = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.cooperationProjectLogo);
                    break;
                case /* string terms */ 15:
                    message.terms = reader.string();
                    break;
                case /* string notification_title */ 16:
                    message.notificationTitle = reader.string();
                    break;
                case /* string notification_body */ 17:
                    message.notificationBody = reader.string();
                    break;
                case /* repeated string associated_partner_ids */ 18:
                    message.associatedPartnerIds.push(reader.string());
                    break;
                case /* string compensate_project_id */ 19:
                    message.compensateProjectId = reader.string();
                    break;
                case /* string donation_project_sku */ 20:
                    message.donationProjectSku = reader.string();
                    break;
                case /* uint32 donation_project_contribution */ 21:
                    message.donationProjectContribution = reader.uint32();
                    break;
                case /* string createdBy */ 100:
                    message.createdBy = reader.string();
                    break;
                case /* google.protobuf.Timestamp createdAt */ 101:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string modifiedBy */ 102:
                    message.modifiedBy = reader.string();
                    break;
                case /* google.protobuf.Timestamp modifiedAt */ 103:
                    message.modifiedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.modifiedAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LotteryPriceDrawingAuthoringItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* google.protobuf.Timestamp starts_at = 2; */
        if (message.startsAt)
            Timestamp.internalBinaryWrite(message.startsAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp ends_at = 3; */
        if (message.endsAt)
            Timestamp.internalBinaryWrite(message.endsAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string title = 4; */
        if (message.title !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.title);
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        /* one.plan3t.core.model.ImageResource image = 6; */
        if (message.image)
            ImageResource.internalBinaryWrite(message.image, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.Plan3TPoint ticketPrice = 7; */
        if (message.ticketPrice)
            Plan3TPoint.internalBinaryWrite(message.ticketPrice, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string partnerId = 8; */
        if (message.partnerId !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.partnerId);
        /* repeated string winnerTicketIds = 9; */
        for (let i = 0; i < message.winnerTicketIds.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.winnerTicketIds[i]);
        /* string extraBenefitExplanation = 10; */
        if (message.extraBenefitExplanation !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.extraBenefitExplanation);
        /* string cooperationProjectName = 11; */
        if (message.cooperationProjectName !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.cooperationProjectName);
        /* string cooperationProjectUrl = 12; */
        if (message.cooperationProjectUrl !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.cooperationProjectUrl);
        /* string cooperationProjectTagLine = 13; */
        if (message.cooperationProjectTagLine !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.cooperationProjectTagLine);
        /* one.plan3t.core.model.ImageResource cooperationProjectLogo = 14; */
        if (message.cooperationProjectLogo)
            ImageResource.internalBinaryWrite(message.cooperationProjectLogo, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* string terms = 15; */
        if (message.terms !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.terms);
        /* string notification_title = 16; */
        if (message.notificationTitle !== "")
            writer.tag(16, WireType.LengthDelimited).string(message.notificationTitle);
        /* string notification_body = 17; */
        if (message.notificationBody !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.notificationBody);
        /* repeated string associated_partner_ids = 18; */
        for (let i = 0; i < message.associatedPartnerIds.length; i++)
            writer.tag(18, WireType.LengthDelimited).string(message.associatedPartnerIds[i]);
        /* string compensate_project_id = 19; */
        if (message.compensateProjectId !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.compensateProjectId);
        /* string donation_project_sku = 20; */
        if (message.donationProjectSku !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.donationProjectSku);
        /* uint32 donation_project_contribution = 21; */
        if (message.donationProjectContribution !== 0)
            writer.tag(21, WireType.Varint).uint32(message.donationProjectContribution);
        /* string createdBy = 100; */
        if (message.createdBy !== "")
            writer.tag(100, WireType.LengthDelimited).string(message.createdBy);
        /* google.protobuf.Timestamp createdAt = 101; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(101, WireType.LengthDelimited).fork(), options).join();
        /* string modifiedBy = 102; */
        if (message.modifiedBy !== "")
            writer.tag(102, WireType.LengthDelimited).string(message.modifiedBy);
        /* google.protobuf.Timestamp modifiedAt = 103; */
        if (message.modifiedAt)
            Timestamp.internalBinaryWrite(message.modifiedAt, writer.tag(103, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.LotteryPriceDrawingAuthoringItem
 */
export const LotteryPriceDrawingAuthoringItem = new LotteryPriceDrawingAuthoringItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLotteryPriceDrawingAuthoringItemsRequest$Type extends MessageType<GetLotteryPriceDrawingAuthoringItemsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetLotteryPriceDrawingAuthoringItemsRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetLotteryPriceDrawingAuthoringItemsRequest>): GetLotteryPriceDrawingAuthoringItemsRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetLotteryPriceDrawingAuthoringItemsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLotteryPriceDrawingAuthoringItemsRequest): GetLotteryPriceDrawingAuthoringItemsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLotteryPriceDrawingAuthoringItemsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetLotteryPriceDrawingAuthoringItemsRequest
 */
export const GetLotteryPriceDrawingAuthoringItemsRequest = new GetLotteryPriceDrawingAuthoringItemsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLotteryPriceDrawingAuthoringItemsResponse$Type extends MessageType<GetLotteryPriceDrawingAuthoringItemsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetLotteryPriceDrawingAuthoringItemsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LotteryPriceDrawingAuthoringItem },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetLotteryPriceDrawingAuthoringItemsResponse>): GetLotteryPriceDrawingAuthoringItemsResponse {
        const message = { items: [], nextPageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetLotteryPriceDrawingAuthoringItemsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLotteryPriceDrawingAuthoringItemsResponse): GetLotteryPriceDrawingAuthoringItemsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.LotteryPriceDrawingAuthoringItem items */ 1:
                    message.items.push(LotteryPriceDrawingAuthoringItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLotteryPriceDrawingAuthoringItemsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.LotteryPriceDrawingAuthoringItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            LotteryPriceDrawingAuthoringItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetLotteryPriceDrawingAuthoringItemsResponse
 */
export const GetLotteryPriceDrawingAuthoringItemsResponse = new GetLotteryPriceDrawingAuthoringItemsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WriteLotteryPriceDrawingAuthoringItemRequest$Type extends MessageType<WriteLotteryPriceDrawingAuthoringItemRequest> {
    constructor() {
        super("one.plan3t.core.model.WriteLotteryPriceDrawingAuthoringItemRequest", [
            { no: 1, name: "item", kind: "message", T: () => LotteryPriceDrawingAuthoringItem }
        ]);
    }
    create(value?: PartialMessage<WriteLotteryPriceDrawingAuthoringItemRequest>): WriteLotteryPriceDrawingAuthoringItemRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WriteLotteryPriceDrawingAuthoringItemRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WriteLotteryPriceDrawingAuthoringItemRequest): WriteLotteryPriceDrawingAuthoringItemRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.LotteryPriceDrawingAuthoringItem item */ 1:
                    message.item = LotteryPriceDrawingAuthoringItem.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WriteLotteryPriceDrawingAuthoringItemRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.LotteryPriceDrawingAuthoringItem item = 1; */
        if (message.item)
            LotteryPriceDrawingAuthoringItem.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.WriteLotteryPriceDrawingAuthoringItemRequest
 */
export const WriteLotteryPriceDrawingAuthoringItemRequest = new WriteLotteryPriceDrawingAuthoringItemRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WriteLotteryPriceDrawingAuthoringItemResponse$Type extends MessageType<WriteLotteryPriceDrawingAuthoringItemResponse> {
    constructor() {
        super("one.plan3t.core.model.WriteLotteryPriceDrawingAuthoringItemResponse", []);
    }
    create(value?: PartialMessage<WriteLotteryPriceDrawingAuthoringItemResponse>): WriteLotteryPriceDrawingAuthoringItemResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WriteLotteryPriceDrawingAuthoringItemResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WriteLotteryPriceDrawingAuthoringItemResponse): WriteLotteryPriceDrawingAuthoringItemResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: WriteLotteryPriceDrawingAuthoringItemResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.WriteLotteryPriceDrawingAuthoringItemResponse
 */
export const WriteLotteryPriceDrawingAuthoringItemResponse = new WriteLotteryPriceDrawingAuthoringItemResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAvailableLotteryProjectsRequest$Type extends MessageType<GetAvailableLotteryProjectsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetAvailableLotteryProjectsRequest", []);
    }
    create(value?: PartialMessage<GetAvailableLotteryProjectsRequest>): GetAvailableLotteryProjectsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAvailableLotteryProjectsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAvailableLotteryProjectsRequest): GetAvailableLotteryProjectsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetAvailableLotteryProjectsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetAvailableLotteryProjectsRequest
 */
export const GetAvailableLotteryProjectsRequest = new GetAvailableLotteryProjectsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAvailableLotteryProjectsResponse$Type extends MessageType<GetAvailableLotteryProjectsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetAvailableLotteryProjectsResponse", [
            { no: 1, name: "projects", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GetAvailableLotteryProjectsResponse_AvailableProject }
        ]);
    }
    create(value?: PartialMessage<GetAvailableLotteryProjectsResponse>): GetAvailableLotteryProjectsResponse {
        const message = { projects: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAvailableLotteryProjectsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAvailableLotteryProjectsResponse): GetAvailableLotteryProjectsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.GetAvailableLotteryProjectsResponse.AvailableProject projects */ 1:
                    message.projects.push(GetAvailableLotteryProjectsResponse_AvailableProject.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAvailableLotteryProjectsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.GetAvailableLotteryProjectsResponse.AvailableProject projects = 1; */
        for (let i = 0; i < message.projects.length; i++)
            GetAvailableLotteryProjectsResponse_AvailableProject.internalBinaryWrite(message.projects[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetAvailableLotteryProjectsResponse
 */
export const GetAvailableLotteryProjectsResponse = new GetAvailableLotteryProjectsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAvailableLotteryProjectsResponse_AvailableProject$Type extends MessageType<GetAvailableLotteryProjectsResponse_AvailableProject> {
    constructor() {
        super("one.plan3t.core.model.GetAvailableLotteryProjectsResponse.AvailableProject", [
            { no: 1, name: "extraBenefitExplanation", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "cooperationProjectName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "cooperationProjectUrl", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "cooperationProjectTagLine", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "cooperationProjectLogo", kind: "message", T: () => ImageResource },
            { no: 6, name: "compensate_project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "donation_project_sku", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "donation_project_contribution", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetAvailableLotteryProjectsResponse_AvailableProject>): GetAvailableLotteryProjectsResponse_AvailableProject {
        const message = { extraBenefitExplanation: "", cooperationProjectName: "", cooperationProjectUrl: "", cooperationProjectTagLine: "", compensateProjectId: "", donationProjectSku: "", donationProjectContribution: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAvailableLotteryProjectsResponse_AvailableProject>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAvailableLotteryProjectsResponse_AvailableProject): GetAvailableLotteryProjectsResponse_AvailableProject {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string extraBenefitExplanation */ 1:
                    message.extraBenefitExplanation = reader.string();
                    break;
                case /* string cooperationProjectName */ 2:
                    message.cooperationProjectName = reader.string();
                    break;
                case /* string cooperationProjectUrl */ 3:
                    message.cooperationProjectUrl = reader.string();
                    break;
                case /* string cooperationProjectTagLine */ 4:
                    message.cooperationProjectTagLine = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource cooperationProjectLogo */ 5:
                    message.cooperationProjectLogo = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.cooperationProjectLogo);
                    break;
                case /* string compensate_project_id */ 6:
                    message.compensateProjectId = reader.string();
                    break;
                case /* string donation_project_sku */ 7:
                    message.donationProjectSku = reader.string();
                    break;
                case /* uint32 donation_project_contribution */ 8:
                    message.donationProjectContribution = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAvailableLotteryProjectsResponse_AvailableProject, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string extraBenefitExplanation = 1; */
        if (message.extraBenefitExplanation !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.extraBenefitExplanation);
        /* string cooperationProjectName = 2; */
        if (message.cooperationProjectName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.cooperationProjectName);
        /* string cooperationProjectUrl = 3; */
        if (message.cooperationProjectUrl !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.cooperationProjectUrl);
        /* string cooperationProjectTagLine = 4; */
        if (message.cooperationProjectTagLine !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.cooperationProjectTagLine);
        /* one.plan3t.core.model.ImageResource cooperationProjectLogo = 5; */
        if (message.cooperationProjectLogo)
            ImageResource.internalBinaryWrite(message.cooperationProjectLogo, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string compensate_project_id = 6; */
        if (message.compensateProjectId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.compensateProjectId);
        /* string donation_project_sku = 7; */
        if (message.donationProjectSku !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.donationProjectSku);
        /* uint32 donation_project_contribution = 8; */
        if (message.donationProjectContribution !== 0)
            writer.tag(8, WireType.Varint).uint32(message.donationProjectContribution);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetAvailableLotteryProjectsResponse.AvailableProject
 */
export const GetAvailableLotteryProjectsResponse_AvailableProject = new GetAvailableLotteryProjectsResponse_AvailableProject$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipDraftsRequest$Type extends MessageType<GetTipDraftsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetTipDraftsRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTipDraftsRequest>): GetTipDraftsRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipDraftsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipDraftsRequest): GetTipDraftsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipDraftsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipDraftsRequest
 */
export const GetTipDraftsRequest = new GetTipDraftsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipDraftsResponse$Type extends MessageType<GetTipDraftsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetTipDraftsResponse", [
            { no: 1, name: "tips", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TipPreviewDraft },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTipDraftsResponse>): GetTipDraftsResponse {
        const message = { tips: [], nextPageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipDraftsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipDraftsResponse): GetTipDraftsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.TipPreviewDraft tips */ 1:
                    message.tips.push(TipPreviewDraft.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipDraftsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.TipPreviewDraft tips = 1; */
        for (let i = 0; i < message.tips.length; i++)
            TipPreviewDraft.internalBinaryWrite(message.tips[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipDraftsResponse
 */
export const GetTipDraftsResponse = new GetTipDraftsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveTipDraftRequest$Type extends MessageType<SaveTipDraftRequest> {
    constructor() {
        super("one.plan3t.core.model.SaveTipDraftRequest", [
            { no: 1, name: "draft", kind: "message", T: () => TipPreviewDraft }
        ]);
    }
    create(value?: PartialMessage<SaveTipDraftRequest>): SaveTipDraftRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SaveTipDraftRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveTipDraftRequest): SaveTipDraftRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.TipPreviewDraft draft */ 1:
                    message.draft = TipPreviewDraft.internalBinaryRead(reader, reader.uint32(), options, message.draft);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveTipDraftRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.TipPreviewDraft draft = 1; */
        if (message.draft)
            TipPreviewDraft.internalBinaryWrite(message.draft, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SaveTipDraftRequest
 */
export const SaveTipDraftRequest = new SaveTipDraftRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveTipDraftResponse$Type extends MessageType<SaveTipDraftResponse> {
    constructor() {
        super("one.plan3t.core.model.SaveTipDraftResponse", [
            { no: 1, name: "draft", kind: "message", T: () => TipPreviewDraft }
        ]);
    }
    create(value?: PartialMessage<SaveTipDraftResponse>): SaveTipDraftResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SaveTipDraftResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveTipDraftResponse): SaveTipDraftResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.TipPreviewDraft draft */ 1:
                    message.draft = TipPreviewDraft.internalBinaryRead(reader, reader.uint32(), options, message.draft);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveTipDraftResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.TipPreviewDraft draft = 1; */
        if (message.draft)
            TipPreviewDraft.internalBinaryWrite(message.draft, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SaveTipDraftResponse
 */
export const SaveTipDraftResponse = new SaveTipDraftResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipDraftRequest$Type extends MessageType<GetTipDraftRequest> {
    constructor() {
        super("one.plan3t.core.model.GetTipDraftRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTipDraftRequest>): GetTipDraftRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipDraftRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipDraftRequest): GetTipDraftRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipDraftRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipDraftRequest
 */
export const GetTipDraftRequest = new GetTipDraftRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipDraftResponse$Type extends MessageType<GetTipDraftResponse> {
    constructor() {
        super("one.plan3t.core.model.GetTipDraftResponse", [
            { no: 1, name: "draft", kind: "message", T: () => TipPreviewDraft }
        ]);
    }
    create(value?: PartialMessage<GetTipDraftResponse>): GetTipDraftResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipDraftResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipDraftResponse): GetTipDraftResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.TipPreviewDraft draft */ 1:
                    message.draft = TipPreviewDraft.internalBinaryRead(reader, reader.uint32(), options, message.draft);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipDraftResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.TipPreviewDraft draft = 1; */
        if (message.draft)
            TipPreviewDraft.internalBinaryWrite(message.draft, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipDraftResponse
 */
export const GetTipDraftResponse = new GetTipDraftResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipSlideDraftsRequest$Type extends MessageType<GetTipSlideDraftsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetTipSlideDraftsRequest", [
            { no: 1, name: "tip_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTipSlideDraftsRequest>): GetTipSlideDraftsRequest {
        const message = { tipId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipSlideDraftsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipSlideDraftsRequest): GetTipSlideDraftsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tip_id */ 1:
                    message.tipId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipSlideDraftsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tip_id = 1; */
        if (message.tipId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tipId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipSlideDraftsRequest
 */
export const GetTipSlideDraftsRequest = new GetTipSlideDraftsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipSlideDraftsResponse$Type extends MessageType<GetTipSlideDraftsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetTipSlideDraftsResponse", [
            { no: 1, name: "slides", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TipSlideDraft }
        ]);
    }
    create(value?: PartialMessage<GetTipSlideDraftsResponse>): GetTipSlideDraftsResponse {
        const message = { slides: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipSlideDraftsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipSlideDraftsResponse): GetTipSlideDraftsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.TipSlideDraft slides */ 1:
                    message.slides.push(TipSlideDraft.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipSlideDraftsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.TipSlideDraft slides = 1; */
        for (let i = 0; i < message.slides.length; i++)
            TipSlideDraft.internalBinaryWrite(message.slides[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipSlideDraftsResponse
 */
export const GetTipSlideDraftsResponse = new GetTipSlideDraftsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveTipSlideDraftsRequest$Type extends MessageType<SaveTipSlideDraftsRequest> {
    constructor() {
        super("one.plan3t.core.model.SaveTipSlideDraftsRequest", [
            { no: 1, name: "tip_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "slides", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TipSlideDraft }
        ]);
    }
    create(value?: PartialMessage<SaveTipSlideDraftsRequest>): SaveTipSlideDraftsRequest {
        const message = { tipId: "", slides: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SaveTipSlideDraftsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveTipSlideDraftsRequest): SaveTipSlideDraftsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tip_id */ 1:
                    message.tipId = reader.string();
                    break;
                case /* repeated one.plan3t.core.model.TipSlideDraft slides */ 2:
                    message.slides.push(TipSlideDraft.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveTipSlideDraftsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tip_id = 1; */
        if (message.tipId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tipId);
        /* repeated one.plan3t.core.model.TipSlideDraft slides = 2; */
        for (let i = 0; i < message.slides.length; i++)
            TipSlideDraft.internalBinaryWrite(message.slides[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SaveTipSlideDraftsRequest
 */
export const SaveTipSlideDraftsRequest = new SaveTipSlideDraftsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveTipSlideDraftsResponse$Type extends MessageType<SaveTipSlideDraftsResponse> {
    constructor() {
        super("one.plan3t.core.model.SaveTipSlideDraftsResponse", []);
    }
    create(value?: PartialMessage<SaveTipSlideDraftsResponse>): SaveTipSlideDraftsResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SaveTipSlideDraftsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveTipSlideDraftsResponse): SaveTipSlideDraftsResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SaveTipSlideDraftsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SaveTipSlideDraftsResponse
 */
export const SaveTipSlideDraftsResponse = new SaveTipSlideDraftsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipQuizDraftRequest$Type extends MessageType<GetTipQuizDraftRequest> {
    constructor() {
        super("one.plan3t.core.model.GetTipQuizDraftRequest", [
            { no: 1, name: "tip_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTipQuizDraftRequest>): GetTipQuizDraftRequest {
        const message = { tipId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipQuizDraftRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipQuizDraftRequest): GetTipQuizDraftRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tip_id */ 1:
                    message.tipId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipQuizDraftRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tip_id = 1; */
        if (message.tipId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tipId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipQuizDraftRequest
 */
export const GetTipQuizDraftRequest = new GetTipQuizDraftRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipQuizDraftResponse$Type extends MessageType<GetTipQuizDraftResponse> {
    constructor() {
        super("one.plan3t.core.model.GetTipQuizDraftResponse", [
            { no: 1, name: "quiz", kind: "message", T: () => TipQuizDraft }
        ]);
    }
    create(value?: PartialMessage<GetTipQuizDraftResponse>): GetTipQuizDraftResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipQuizDraftResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipQuizDraftResponse): GetTipQuizDraftResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.TipQuizDraft quiz */ 1:
                    message.quiz = TipQuizDraft.internalBinaryRead(reader, reader.uint32(), options, message.quiz);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipQuizDraftResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.TipQuizDraft quiz = 1; */
        if (message.quiz)
            TipQuizDraft.internalBinaryWrite(message.quiz, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipQuizDraftResponse
 */
export const GetTipQuizDraftResponse = new GetTipQuizDraftResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveTipQuizDraftRequest$Type extends MessageType<SaveTipQuizDraftRequest> {
    constructor() {
        super("one.plan3t.core.model.SaveTipQuizDraftRequest", [
            { no: 1, name: "tip_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "quiz", kind: "message", T: () => TipQuizDraft }
        ]);
    }
    create(value?: PartialMessage<SaveTipQuizDraftRequest>): SaveTipQuizDraftRequest {
        const message = { tipId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SaveTipQuizDraftRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveTipQuizDraftRequest): SaveTipQuizDraftRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tip_id */ 1:
                    message.tipId = reader.string();
                    break;
                case /* one.plan3t.core.model.TipQuizDraft quiz */ 2:
                    message.quiz = TipQuizDraft.internalBinaryRead(reader, reader.uint32(), options, message.quiz);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveTipQuizDraftRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tip_id = 1; */
        if (message.tipId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tipId);
        /* one.plan3t.core.model.TipQuizDraft quiz = 2; */
        if (message.quiz)
            TipQuizDraft.internalBinaryWrite(message.quiz, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SaveTipQuizDraftRequest
 */
export const SaveTipQuizDraftRequest = new SaveTipQuizDraftRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveTipQuizDraftResponse$Type extends MessageType<SaveTipQuizDraftResponse> {
    constructor() {
        super("one.plan3t.core.model.SaveTipQuizDraftResponse", []);
    }
    create(value?: PartialMessage<SaveTipQuizDraftResponse>): SaveTipQuizDraftResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SaveTipQuizDraftResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveTipQuizDraftResponse): SaveTipQuizDraftResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SaveTipQuizDraftResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SaveTipQuizDraftResponse
 */
export const SaveTipQuizDraftResponse = new SaveTipQuizDraftResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveTipAuthorDraftRequest$Type extends MessageType<SaveTipAuthorDraftRequest> {
    constructor() {
        super("one.plan3t.core.model.SaveTipAuthorDraftRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tag_line", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "image", kind: "message", T: () => ImageResource },
            { no: 5, name: "social_links", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SaveTipAuthorDraftRequest_SocialLink }
        ]);
    }
    create(value?: PartialMessage<SaveTipAuthorDraftRequest>): SaveTipAuthorDraftRequest {
        const message = { id: "", displayName: "", tagLine: "", socialLinks: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SaveTipAuthorDraftRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveTipAuthorDraftRequest): SaveTipAuthorDraftRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string display_name */ 2:
                    message.displayName = reader.string();
                    break;
                case /* string tag_line */ 3:
                    message.tagLine = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource image */ 4:
                    message.image = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.image);
                    break;
                case /* repeated one.plan3t.core.model.SaveTipAuthorDraftRequest.SocialLink social_links */ 5:
                    message.socialLinks.push(SaveTipAuthorDraftRequest_SocialLink.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveTipAuthorDraftRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string display_name = 2; */
        if (message.displayName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.displayName);
        /* string tag_line = 3; */
        if (message.tagLine !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tagLine);
        /* one.plan3t.core.model.ImageResource image = 4; */
        if (message.image)
            ImageResource.internalBinaryWrite(message.image, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated one.plan3t.core.model.SaveTipAuthorDraftRequest.SocialLink social_links = 5; */
        for (let i = 0; i < message.socialLinks.length; i++)
            SaveTipAuthorDraftRequest_SocialLink.internalBinaryWrite(message.socialLinks[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SaveTipAuthorDraftRequest
 */
export const SaveTipAuthorDraftRequest = new SaveTipAuthorDraftRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveTipAuthorDraftRequest_SocialLink$Type extends MessageType<SaveTipAuthorDraftRequest_SocialLink> {
    constructor() {
        super("one.plan3t.core.model.SaveTipAuthorDraftRequest.SocialLink", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "kind", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SaveTipAuthorDraftRequest_SocialLink>): SaveTipAuthorDraftRequest_SocialLink {
        const message = { url: "", label: "", kind: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SaveTipAuthorDraftRequest_SocialLink>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveTipAuthorDraftRequest_SocialLink): SaveTipAuthorDraftRequest_SocialLink {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                case /* string label */ 2:
                    message.label = reader.string();
                    break;
                case /* string kind */ 3:
                    message.kind = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SaveTipAuthorDraftRequest_SocialLink, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        /* string label = 2; */
        if (message.label !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.label);
        /* string kind = 3; */
        if (message.kind !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.kind);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SaveTipAuthorDraftRequest.SocialLink
 */
export const SaveTipAuthorDraftRequest_SocialLink = new SaveTipAuthorDraftRequest_SocialLink$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SaveTipAuthorDraftResponse$Type extends MessageType<SaveTipAuthorDraftResponse> {
    constructor() {
        super("one.plan3t.core.model.SaveTipAuthorDraftResponse", []);
    }
    create(value?: PartialMessage<SaveTipAuthorDraftResponse>): SaveTipAuthorDraftResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SaveTipAuthorDraftResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SaveTipAuthorDraftResponse): SaveTipAuthorDraftResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SaveTipAuthorDraftResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SaveTipAuthorDraftResponse
 */
export const SaveTipAuthorDraftResponse = new SaveTipAuthorDraftResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipAuthorDraftsRequest$Type extends MessageType<GetTipAuthorDraftsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetTipAuthorDraftsRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTipAuthorDraftsRequest>): GetTipAuthorDraftsRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipAuthorDraftsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipAuthorDraftsRequest): GetTipAuthorDraftsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipAuthorDraftsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipAuthorDraftsRequest
 */
export const GetTipAuthorDraftsRequest = new GetTipAuthorDraftsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipAuthorDraftsResponse$Type extends MessageType<GetTipAuthorDraftsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetTipAuthorDraftsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TipAuthorDraft },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTipAuthorDraftsResponse>): GetTipAuthorDraftsResponse {
        const message = { items: [], nextPageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipAuthorDraftsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipAuthorDraftsResponse): GetTipAuthorDraftsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.TipAuthorDraft items */ 1:
                    message.items.push(TipAuthorDraft.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipAuthorDraftsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.TipAuthorDraft items = 1; */
        for (let i = 0; i < message.items.length; i++)
            TipAuthorDraft.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipAuthorDraftsResponse
 */
export const GetTipAuthorDraftsResponse = new GetTipAuthorDraftsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChallengesCategoryAuthoringItemsRequest$Type extends MessageType<GetChallengesCategoryAuthoringItemsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetChallengesCategoryAuthoringItemsRequest", []);
    }
    create(value?: PartialMessage<GetChallengesCategoryAuthoringItemsRequest>): GetChallengesCategoryAuthoringItemsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetChallengesCategoryAuthoringItemsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetChallengesCategoryAuthoringItemsRequest): GetChallengesCategoryAuthoringItemsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetChallengesCategoryAuthoringItemsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetChallengesCategoryAuthoringItemsRequest
 */
export const GetChallengesCategoryAuthoringItemsRequest = new GetChallengesCategoryAuthoringItemsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChallengesCategoryAuthoringItemsResponse$Type extends MessageType<GetChallengesCategoryAuthoringItemsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetChallengesCategoryAuthoringItemsResponse", [
            { no: 1, name: "categories", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemCategoryAuthoring }
        ]);
    }
    create(value?: PartialMessage<GetChallengesCategoryAuthoringItemsResponse>): GetChallengesCategoryAuthoringItemsResponse {
        const message = { categories: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetChallengesCategoryAuthoringItemsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetChallengesCategoryAuthoringItemsResponse): GetChallengesCategoryAuthoringItemsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.ItemCategoryAuthoring categories */ 1:
                    message.categories.push(ItemCategoryAuthoring.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetChallengesCategoryAuthoringItemsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.ItemCategoryAuthoring categories = 1; */
        for (let i = 0; i < message.categories.length; i++)
            ItemCategoryAuthoring.internalBinaryWrite(message.categories[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetChallengesCategoryAuthoringItemsResponse
 */
export const GetChallengesCategoryAuthoringItemsResponse = new GetChallengesCategoryAuthoringItemsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WriteChallengesCategoryAuthoringRequest$Type extends MessageType<WriteChallengesCategoryAuthoringRequest> {
    constructor() {
        super("one.plan3t.core.model.WriteChallengesCategoryAuthoringRequest", [
            { no: 1, name: "item", kind: "message", T: () => ItemCategoryAuthoring }
        ]);
    }
    create(value?: PartialMessage<WriteChallengesCategoryAuthoringRequest>): WriteChallengesCategoryAuthoringRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WriteChallengesCategoryAuthoringRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WriteChallengesCategoryAuthoringRequest): WriteChallengesCategoryAuthoringRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.ItemCategoryAuthoring item */ 1:
                    message.item = ItemCategoryAuthoring.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WriteChallengesCategoryAuthoringRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.ItemCategoryAuthoring item = 1; */
        if (message.item)
            ItemCategoryAuthoring.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.WriteChallengesCategoryAuthoringRequest
 */
export const WriteChallengesCategoryAuthoringRequest = new WriteChallengesCategoryAuthoringRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WriteChallengesCategoryAuthoringResponse$Type extends MessageType<WriteChallengesCategoryAuthoringResponse> {
    constructor() {
        super("one.plan3t.core.model.WriteChallengesCategoryAuthoringResponse", []);
    }
    create(value?: PartialMessage<WriteChallengesCategoryAuthoringResponse>): WriteChallengesCategoryAuthoringResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WriteChallengesCategoryAuthoringResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WriteChallengesCategoryAuthoringResponse): WriteChallengesCategoryAuthoringResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: WriteChallengesCategoryAuthoringResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.WriteChallengesCategoryAuthoringResponse
 */
export const WriteChallengesCategoryAuthoringResponse = new WriteChallengesCategoryAuthoringResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateFeedItemRequest$Type extends MessageType<CreateFeedItemRequest> {
    constructor() {
        super("one.plan3t.core.model.CreateFeedItemRequest", [
            { no: 1, name: "item", kind: "message", T: () => FeedItem }
        ]);
    }
    create(value?: PartialMessage<CreateFeedItemRequest>): CreateFeedItemRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateFeedItemRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateFeedItemRequest): CreateFeedItemRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.FeedItem item */ 1:
                    message.item = FeedItem.internalBinaryRead(reader, reader.uint32(), options, message.item);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateFeedItemRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.FeedItem item = 1; */
        if (message.item)
            FeedItem.internalBinaryWrite(message.item, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CreateFeedItemRequest
 */
export const CreateFeedItemRequest = new CreateFeedItemRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateFeedItemResponse$Type extends MessageType<CreateFeedItemResponse> {
    constructor() {
        super("one.plan3t.core.model.CreateFeedItemResponse", []);
    }
    create(value?: PartialMessage<CreateFeedItemResponse>): CreateFeedItemResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateFeedItemResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateFeedItemResponse): CreateFeedItemResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CreateFeedItemResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CreateFeedItemResponse
 */
export const CreateFeedItemResponse = new CreateFeedItemResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFeedItemsRequest$Type extends MessageType<GetFeedItemsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetFeedItemsRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetFeedItemsRequest>): GetFeedItemsRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetFeedItemsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFeedItemsRequest): GetFeedItemsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFeedItemsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetFeedItemsRequest
 */
export const GetFeedItemsRequest = new GetFeedItemsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFeedItemsResponse$Type extends MessageType<GetFeedItemsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetFeedItemsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FeedItem },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetFeedItemsResponse>): GetFeedItemsResponse {
        const message = { items: [], nextPageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetFeedItemsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFeedItemsResponse): GetFeedItemsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.FeedItem items */ 1:
                    message.items.push(FeedItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFeedItemsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.FeedItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            FeedItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetFeedItemsResponse
 */
export const GetFeedItemsResponse = new GetFeedItemsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteFeedItemRequest$Type extends MessageType<DeleteFeedItemRequest> {
    constructor() {
        super("one.plan3t.core.model.DeleteFeedItemRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteFeedItemRequest>): DeleteFeedItemRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteFeedItemRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteFeedItemRequest): DeleteFeedItemRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteFeedItemRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.DeleteFeedItemRequest
 */
export const DeleteFeedItemRequest = new DeleteFeedItemRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteFeedItemResponse$Type extends MessageType<DeleteFeedItemResponse> {
    constructor() {
        super("one.plan3t.core.model.DeleteFeedItemResponse", []);
    }
    create(value?: PartialMessage<DeleteFeedItemResponse>): DeleteFeedItemResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteFeedItemResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteFeedItemResponse): DeleteFeedItemResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteFeedItemResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.DeleteFeedItemResponse
 */
export const DeleteFeedItemResponse = new DeleteFeedItemResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MissingCashbackTicket$Type extends MessageType<MissingCashbackTicket> {
    constructor() {
        super("one.plan3t.core.model.MissingCashbackTicket", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "purchase_date", kind: "message", T: () => Timestamp },
            { no: 3, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "amount_unscaled", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "amount_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "state", kind: "enum", T: () => ["one.plan3t.core.model.MissingCashbackState", MissingCashbackState] },
            { no: 8, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 9, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MissingCashbackTicket>): MissingCashbackTicket {
        const message = { id: "", partnerId: "", accountId: "", amountUnscaled: 0, amountScale: 0, state: 0, userId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MissingCashbackTicket>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MissingCashbackTicket): MissingCashbackTicket {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* google.protobuf.Timestamp purchase_date */ 2:
                    message.purchaseDate = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.purchaseDate);
                    break;
                case /* string partner_id */ 3:
                    message.partnerId = reader.string();
                    break;
                case /* string account_id */ 4:
                    message.accountId = reader.string();
                    break;
                case /* int32 amount_unscaled */ 5:
                    message.amountUnscaled = reader.int32();
                    break;
                case /* int32 amount_scale */ 6:
                    message.amountScale = reader.int32();
                    break;
                case /* one.plan3t.core.model.MissingCashbackState state */ 7:
                    message.state = reader.int32();
                    break;
                case /* google.protobuf.Timestamp created_at */ 8:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string user_id */ 9:
                    message.userId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MissingCashbackTicket, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* google.protobuf.Timestamp purchase_date = 2; */
        if (message.purchaseDate)
            Timestamp.internalBinaryWrite(message.purchaseDate, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string partner_id = 3; */
        if (message.partnerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.partnerId);
        /* string account_id = 4; */
        if (message.accountId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.accountId);
        /* int32 amount_unscaled = 5; */
        if (message.amountUnscaled !== 0)
            writer.tag(5, WireType.Varint).int32(message.amountUnscaled);
        /* int32 amount_scale = 6; */
        if (message.amountScale !== 0)
            writer.tag(6, WireType.Varint).int32(message.amountScale);
        /* one.plan3t.core.model.MissingCashbackState state = 7; */
        if (message.state !== 0)
            writer.tag(7, WireType.Varint).int32(message.state);
        /* google.protobuf.Timestamp created_at = 8; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* string user_id = 9; */
        if (message.userId !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.MissingCashbackTicket
 */
export const MissingCashbackTicket = new MissingCashbackTicket$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMissingCashbackTicketsRequest$Type extends MessageType<GetMissingCashbackTicketsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetMissingCashbackTicketsRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetMissingCashbackTicketsRequest>): GetMissingCashbackTicketsRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetMissingCashbackTicketsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMissingCashbackTicketsRequest): GetMissingCashbackTicketsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMissingCashbackTicketsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetMissingCashbackTicketsRequest
 */
export const GetMissingCashbackTicketsRequest = new GetMissingCashbackTicketsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMissingCashbackTicketsResponse$Type extends MessageType<GetMissingCashbackTicketsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetMissingCashbackTicketsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MissingCashbackTicket },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetMissingCashbackTicketsResponse>): GetMissingCashbackTicketsResponse {
        const message = { items: [], nextPageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetMissingCashbackTicketsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMissingCashbackTicketsResponse): GetMissingCashbackTicketsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.MissingCashbackTicket items */ 1:
                    message.items.push(MissingCashbackTicket.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMissingCashbackTicketsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.MissingCashbackTicket items = 1; */
        for (let i = 0; i < message.items.length; i++)
            MissingCashbackTicket.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetMissingCashbackTicketsResponse
 */
export const GetMissingCashbackTicketsResponse = new GetMissingCashbackTicketsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetCashbackTicketStateRequest$Type extends MessageType<SetCashbackTicketStateRequest> {
    constructor() {
        super("one.plan3t.core.model.SetCashbackTicketStateRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "target_state", kind: "enum", T: () => ["one.plan3t.core.model.MissingCashbackState", MissingCashbackState] }
        ]);
    }
    create(value?: PartialMessage<SetCashbackTicketStateRequest>): SetCashbackTicketStateRequest {
        const message = { id: "", targetState: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetCashbackTicketStateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetCashbackTicketStateRequest): SetCashbackTicketStateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* one.plan3t.core.model.MissingCashbackState target_state */ 2:
                    message.targetState = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetCashbackTicketStateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* one.plan3t.core.model.MissingCashbackState target_state = 2; */
        if (message.targetState !== 0)
            writer.tag(2, WireType.Varint).int32(message.targetState);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SetCashbackTicketStateRequest
 */
export const SetCashbackTicketStateRequest = new SetCashbackTicketStateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetCashbackTicketStateResponse$Type extends MessageType<SetCashbackTicketStateResponse> {
    constructor() {
        super("one.plan3t.core.model.SetCashbackTicketStateResponse", []);
    }
    create(value?: PartialMessage<SetCashbackTicketStateResponse>): SetCashbackTicketStateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetCashbackTicketStateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetCashbackTicketStateResponse): SetCashbackTicketStateResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SetCashbackTicketStateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SetCashbackTicketStateResponse
 */
export const SetCashbackTicketStateResponse = new SetCashbackTicketStateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTransactionsForMissingCashbackTicketRequest$Type extends MessageType<GetTransactionsForMissingCashbackTicketRequest> {
    constructor() {
        super("one.plan3t.core.model.GetTransactionsForMissingCashbackTicketRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTransactionsForMissingCashbackTicketRequest>): GetTransactionsForMissingCashbackTicketRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTransactionsForMissingCashbackTicketRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTransactionsForMissingCashbackTicketRequest): GetTransactionsForMissingCashbackTicketRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTransactionsForMissingCashbackTicketRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTransactionsForMissingCashbackTicketRequest
 */
export const GetTransactionsForMissingCashbackTicketRequest = new GetTransactionsForMissingCashbackTicketRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTransactionsForMissingCashbackTicketResponse$Type extends MessageType<GetTransactionsForMissingCashbackTicketResponse> {
    constructor() {
        super("one.plan3t.core.model.GetTransactionsForMissingCashbackTicketResponse", [
            { no: 1, name: "transactions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransactionDetails }
        ]);
    }
    create(value?: PartialMessage<GetTransactionsForMissingCashbackTicketResponse>): GetTransactionsForMissingCashbackTicketResponse {
        const message = { transactions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTransactionsForMissingCashbackTicketResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTransactionsForMissingCashbackTicketResponse): GetTransactionsForMissingCashbackTicketResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.TransactionDetails transactions */ 1:
                    message.transactions.push(TransactionDetails.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTransactionsForMissingCashbackTicketResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.TransactionDetails transactions = 1; */
        for (let i = 0; i < message.transactions.length; i++)
            TransactionDetails.internalBinaryWrite(message.transactions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTransactionsForMissingCashbackTicketResponse
 */
export const GetTransactionsForMissingCashbackTicketResponse = new GetTransactionsForMissingCashbackTicketResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CashbackPartnerCondition$Type extends MessageType<CashbackPartnerCondition> {
    constructor() {
        super("one.plan3t.core.model.CashbackPartnerCondition", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "field", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "regex", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "comment", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CashbackPartnerCondition>): CashbackPartnerCondition {
        const message = { id: "", field: "", regex: "", comment: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CashbackPartnerCondition>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CashbackPartnerCondition): CashbackPartnerCondition {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string field */ 2:
                    message.field = reader.string();
                    break;
                case /* string regex */ 3:
                    message.regex = reader.string();
                    break;
                case /* string comment */ 4:
                    message.comment = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CashbackPartnerCondition, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string field = 2; */
        if (message.field !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.field);
        /* string regex = 3; */
        if (message.regex !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.regex);
        /* string comment = 4; */
        if (message.comment !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.comment);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CashbackPartnerCondition
 */
export const CashbackPartnerCondition = new CashbackPartnerCondition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EvaluateCashbackRuleConditionRequest$Type extends MessageType<EvaluateCashbackRuleConditionRequest> {
    constructor() {
        super("one.plan3t.core.model.EvaluateCashbackRuleConditionRequest", [
            { no: 1, name: "condition", kind: "message", T: () => CashbackPartnerCondition },
            { no: 2, name: "from", kind: "message", T: () => Timestamp },
            { no: 3, name: "to", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<EvaluateCashbackRuleConditionRequest>): EvaluateCashbackRuleConditionRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EvaluateCashbackRuleConditionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EvaluateCashbackRuleConditionRequest): EvaluateCashbackRuleConditionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.CashbackPartnerCondition condition */ 1:
                    message.condition = CashbackPartnerCondition.internalBinaryRead(reader, reader.uint32(), options, message.condition);
                    break;
                case /* google.protobuf.Timestamp from */ 2:
                    message.from = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.from);
                    break;
                case /* google.protobuf.Timestamp to */ 3:
                    message.to = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.to);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EvaluateCashbackRuleConditionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.CashbackPartnerCondition condition = 1; */
        if (message.condition)
            CashbackPartnerCondition.internalBinaryWrite(message.condition, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp from = 2; */
        if (message.from)
            Timestamp.internalBinaryWrite(message.from, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp to = 3; */
        if (message.to)
            Timestamp.internalBinaryWrite(message.to, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.EvaluateCashbackRuleConditionRequest
 */
export const EvaluateCashbackRuleConditionRequest = new EvaluateCashbackRuleConditionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EvaluateCashbackRuleConditionResponse$Type extends MessageType<EvaluateCashbackRuleConditionResponse> {
    constructor() {
        super("one.plan3t.core.model.EvaluateCashbackRuleConditionResponse", [
            { no: 1, name: "num_processed", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "num_matched", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<EvaluateCashbackRuleConditionResponse>): EvaluateCashbackRuleConditionResponse {
        const message = { numProcessed: 0, numMatched: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<EvaluateCashbackRuleConditionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EvaluateCashbackRuleConditionResponse): EvaluateCashbackRuleConditionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 num_processed */ 1:
                    message.numProcessed = reader.int64().toNumber();
                    break;
                case /* int64 num_matched */ 2:
                    message.numMatched = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EvaluateCashbackRuleConditionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 num_processed = 1; */
        if (message.numProcessed !== 0)
            writer.tag(1, WireType.Varint).int64(message.numProcessed);
        /* int64 num_matched = 2; */
        if (message.numMatched !== 0)
            writer.tag(2, WireType.Varint).int64(message.numMatched);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.EvaluateCashbackRuleConditionResponse
 */
export const EvaluateCashbackRuleConditionResponse = new EvaluateCashbackRuleConditionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCustomReferralCodesRequest$Type extends MessageType<GetCustomReferralCodesRequest> {
    constructor() {
        super("one.plan3t.core.model.GetCustomReferralCodesRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCustomReferralCodesRequest>): GetCustomReferralCodesRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCustomReferralCodesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCustomReferralCodesRequest): GetCustomReferralCodesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCustomReferralCodesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetCustomReferralCodesRequest
 */
export const GetCustomReferralCodesRequest = new GetCustomReferralCodesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCustomReferralCodesResponse$Type extends MessageType<GetCustomReferralCodesResponse> {
    constructor() {
        super("one.plan3t.core.model.GetCustomReferralCodesResponse", [
            { no: 1, name: "items", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCustomReferralCodesResponse>): GetCustomReferralCodesResponse {
        const message = { items: [], nextPageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCustomReferralCodesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCustomReferralCodesResponse): GetCustomReferralCodesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string items */ 1:
                    message.items.push(reader.string());
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCustomReferralCodesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string items = 1; */
        for (let i = 0; i < message.items.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.items[i]);
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetCustomReferralCodesResponse
 */
export const GetCustomReferralCodesResponse = new GetCustomReferralCodesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateCustomReferralCodeRequest$Type extends MessageType<CreateCustomReferralCodeRequest> {
    constructor() {
        super("one.plan3t.core.model.CreateCustomReferralCodeRequest", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateCustomReferralCodeRequest>): CreateCustomReferralCodeRequest {
        const message = { code: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateCustomReferralCodeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateCustomReferralCodeRequest): CreateCustomReferralCodeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateCustomReferralCodeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CreateCustomReferralCodeRequest
 */
export const CreateCustomReferralCodeRequest = new CreateCustomReferralCodeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateCustomReferralCodeResponse$Type extends MessageType<CreateCustomReferralCodeResponse> {
    constructor() {
        super("one.plan3t.core.model.CreateCustomReferralCodeResponse", []);
    }
    create(value?: PartialMessage<CreateCustomReferralCodeResponse>): CreateCustomReferralCodeResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateCustomReferralCodeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateCustomReferralCodeResponse): CreateCustomReferralCodeResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CreateCustomReferralCodeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CreateCustomReferralCodeResponse
 */
export const CreateCustomReferralCodeResponse = new CreateCustomReferralCodeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetReferralCodeStatsRequest$Type extends MessageType<GetReferralCodeStatsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetReferralCodeStatsRequest", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetReferralCodeStatsRequest>): GetReferralCodeStatsRequest {
        const message = { code: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetReferralCodeStatsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetReferralCodeStatsRequest): GetReferralCodeStatsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetReferralCodeStatsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetReferralCodeStatsRequest
 */
export const GetReferralCodeStatsRequest = new GetReferralCodeStatsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetReferralCodeStatsResponse$Type extends MessageType<GetReferralCodeStatsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetReferralCodeStatsResponse", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "created_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "pending", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "completed", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetReferralCodeStatsResponse>): GetReferralCodeStatsResponse {
        const message = { code: "", createdBy: "", pending: 0, completed: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetReferralCodeStatsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetReferralCodeStatsResponse): GetReferralCodeStatsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string created_by */ 3:
                    message.createdBy = reader.string();
                    break;
                case /* int32 pending */ 4:
                    message.pending = reader.int32();
                    break;
                case /* int32 completed */ 5:
                    message.completed = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetReferralCodeStatsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string created_by = 3; */
        if (message.createdBy !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.createdBy);
        /* int32 pending = 4; */
        if (message.pending !== 0)
            writer.tag(4, WireType.Varint).int32(message.pending);
        /* int32 completed = 5; */
        if (message.completed !== 0)
            writer.tag(5, WireType.Varint).int32(message.completed);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetReferralCodeStatsResponse
 */
export const GetReferralCodeStatsResponse = new GetReferralCodeStatsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOpenBankingWaitlistEntriesRequest$Type extends MessageType<GetOpenBankingWaitlistEntriesRequest> {
    constructor() {
        super("one.plan3t.core.model.GetOpenBankingWaitlistEntriesRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetOpenBankingWaitlistEntriesRequest>): GetOpenBankingWaitlistEntriesRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOpenBankingWaitlistEntriesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOpenBankingWaitlistEntriesRequest): GetOpenBankingWaitlistEntriesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOpenBankingWaitlistEntriesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetOpenBankingWaitlistEntriesRequest
 */
export const GetOpenBankingWaitlistEntriesRequest = new GetOpenBankingWaitlistEntriesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OpenBankingWaitlistEntry$Type extends MessageType<OpenBankingWaitlistEntry> {
    constructor() {
        super("one.plan3t.core.model.OpenBankingWaitlistEntry", [
            { no: 1, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "confirmed_at", kind: "message", T: () => Timestamp },
            { no: 8, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 9, name: "modified_at", kind: "message", T: () => Timestamp },
            { no: 10, name: "accepted_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<OpenBankingWaitlistEntry>): OpenBankingWaitlistEntry {
        const message = { userId: "", email: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OpenBankingWaitlistEntry>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OpenBankingWaitlistEntry): OpenBankingWaitlistEntry {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_id */ 1:
                    message.userId = reader.string();
                    break;
                case /* string email */ 2:
                    message.email = reader.string();
                    break;
                case /* google.protobuf.Timestamp confirmed_at */ 7:
                    message.confirmedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.confirmedAt);
                    break;
                case /* google.protobuf.Timestamp created_at */ 8:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp modified_at */ 9:
                    message.modifiedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.modifiedAt);
                    break;
                case /* google.protobuf.Timestamp accepted_at */ 10:
                    message.acceptedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.acceptedAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OpenBankingWaitlistEntry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user_id = 1; */
        if (message.userId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userId);
        /* string email = 2; */
        if (message.email !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.email);
        /* google.protobuf.Timestamp confirmed_at = 7; */
        if (message.confirmedAt)
            Timestamp.internalBinaryWrite(message.confirmedAt, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 8; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp modified_at = 9; */
        if (message.modifiedAt)
            Timestamp.internalBinaryWrite(message.modifiedAt, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp accepted_at = 10; */
        if (message.acceptedAt)
            Timestamp.internalBinaryWrite(message.acceptedAt, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.OpenBankingWaitlistEntry
 */
export const OpenBankingWaitlistEntry = new OpenBankingWaitlistEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOpenBankingWaitlistEntriesResponse$Type extends MessageType<GetOpenBankingWaitlistEntriesResponse> {
    constructor() {
        super("one.plan3t.core.model.GetOpenBankingWaitlistEntriesResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OpenBankingWaitlistEntry },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetOpenBankingWaitlistEntriesResponse>): GetOpenBankingWaitlistEntriesResponse {
        const message = { items: [], nextPageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOpenBankingWaitlistEntriesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOpenBankingWaitlistEntriesResponse): GetOpenBankingWaitlistEntriesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.OpenBankingWaitlistEntry items */ 1:
                    message.items.push(OpenBankingWaitlistEntry.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOpenBankingWaitlistEntriesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.OpenBankingWaitlistEntry items = 1; */
        for (let i = 0; i < message.items.length; i++)
            OpenBankingWaitlistEntry.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetOpenBankingWaitlistEntriesResponse
 */
export const GetOpenBankingWaitlistEntriesResponse = new GetOpenBankingWaitlistEntriesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AcceptOpenBankingWaitlistEntryRequest$Type extends MessageType<AcceptOpenBankingWaitlistEntryRequest> {
    constructor() {
        super("one.plan3t.core.model.AcceptOpenBankingWaitlistEntryRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AcceptOpenBankingWaitlistEntryRequest>): AcceptOpenBankingWaitlistEntryRequest {
        const message = { userId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AcceptOpenBankingWaitlistEntryRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AcceptOpenBankingWaitlistEntryRequest): AcceptOpenBankingWaitlistEntryRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_id */ 1:
                    message.userId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AcceptOpenBankingWaitlistEntryRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user_id = 1; */
        if (message.userId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.AcceptOpenBankingWaitlistEntryRequest
 */
export const AcceptOpenBankingWaitlistEntryRequest = new AcceptOpenBankingWaitlistEntryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AcceptOpenBankingWaitlistEntryResponse$Type extends MessageType<AcceptOpenBankingWaitlistEntryResponse> {
    constructor() {
        super("one.plan3t.core.model.AcceptOpenBankingWaitlistEntryResponse", []);
    }
    create(value?: PartialMessage<AcceptOpenBankingWaitlistEntryResponse>): AcceptOpenBankingWaitlistEntryResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AcceptOpenBankingWaitlistEntryResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AcceptOpenBankingWaitlistEntryResponse): AcceptOpenBankingWaitlistEntryResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AcceptOpenBankingWaitlistEntryResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.AcceptOpenBankingWaitlistEntryResponse
 */
export const AcceptOpenBankingWaitlistEntryResponse = new AcceptOpenBankingWaitlistEntryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FetchAssessmentScoresRequest$Type extends MessageType<FetchAssessmentScoresRequest> {
    constructor() {
        super("one.plan3t.core.model.FetchAssessmentScoresRequest", [
            { no: 1, name: "force_overwrite", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<FetchAssessmentScoresRequest>): FetchAssessmentScoresRequest {
        const message = { forceOverwrite: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FetchAssessmentScoresRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FetchAssessmentScoresRequest): FetchAssessmentScoresRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool force_overwrite */ 1:
                    message.forceOverwrite = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FetchAssessmentScoresRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool force_overwrite = 1; */
        if (message.forceOverwrite !== false)
            writer.tag(1, WireType.Varint).bool(message.forceOverwrite);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.FetchAssessmentScoresRequest
 */
export const FetchAssessmentScoresRequest = new FetchAssessmentScoresRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FetchAssessmentScoresResponse$Type extends MessageType<FetchAssessmentScoresResponse> {
    constructor() {
        super("one.plan3t.core.model.FetchAssessmentScoresResponse", [
            { no: 1, name: "num_fetched", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "num_written", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "partners_without_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FetchAssessmentScoresResponse>): FetchAssessmentScoresResponse {
        const message = { numFetched: 0, numWritten: 0, partnersWithoutIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FetchAssessmentScoresResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FetchAssessmentScoresResponse): FetchAssessmentScoresResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 num_fetched */ 1:
                    message.numFetched = reader.int32();
                    break;
                case /* int32 num_written */ 2:
                    message.numWritten = reader.int32();
                    break;
                case /* repeated string partners_without_ids */ 3:
                    message.partnersWithoutIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FetchAssessmentScoresResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 num_fetched = 1; */
        if (message.numFetched !== 0)
            writer.tag(1, WireType.Varint).int32(message.numFetched);
        /* int32 num_written = 2; */
        if (message.numWritten !== 0)
            writer.tag(2, WireType.Varint).int32(message.numWritten);
        /* repeated string partners_without_ids = 3; */
        for (let i = 0; i < message.partnersWithoutIds.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.partnersWithoutIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.FetchAssessmentScoresResponse
 */
export const FetchAssessmentScoresResponse = new FetchAssessmentScoresResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentScoreAuthoring$Type extends MessageType<AssessmentScoreAuthoring> {
    constructor() {
        super("one.plan3t.core.model.AssessmentScoreAuthoring", [
            { no: 1, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value_unscaled", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "value_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "applicable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<AssessmentScoreAuthoring>): AssessmentScoreAuthoring {
        const message = { label: "", valueUnscaled: 0, valueScale: 0, applicable: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AssessmentScoreAuthoring>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssessmentScoreAuthoring): AssessmentScoreAuthoring {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string label */ 1:
                    message.label = reader.string();
                    break;
                case /* int32 value_unscaled */ 2:
                    message.valueUnscaled = reader.int32();
                    break;
                case /* int32 value_scale */ 3:
                    message.valueScale = reader.int32();
                    break;
                case /* bool applicable */ 4:
                    message.applicable = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AssessmentScoreAuthoring, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string label = 1; */
        if (message.label !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.label);
        /* int32 value_unscaled = 2; */
        if (message.valueUnscaled !== 0)
            writer.tag(2, WireType.Varint).int32(message.valueUnscaled);
        /* int32 value_scale = 3; */
        if (message.valueScale !== 0)
            writer.tag(3, WireType.Varint).int32(message.valueScale);
        /* bool applicable = 4; */
        if (message.applicable !== false)
            writer.tag(4, WireType.Varint).bool(message.applicable);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.AssessmentScoreAuthoring
 */
export const AssessmentScoreAuthoring = new AssessmentScoreAuthoring$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentAuthoring$Type extends MessageType<AssessmentAuthoring> {
    constructor() {
        super("one.plan3t.core.model.AssessmentAuthoring", [
            { no: 1, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scoring_word", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "scores", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssessmentScoreAuthoring },
            { no: 4, name: "edit_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "score", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "score_max", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 100, name: "createdBy", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 101, name: "createdAt", kind: "message", T: () => Timestamp },
            { no: 102, name: "modifiedBy", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 103, name: "modifiedAt", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<AssessmentAuthoring>): AssessmentAuthoring {
        const message = { partnerId: "", scoringWord: "", scores: [], editLink: "", score: 0, scoreMax: 0, createdBy: "", modifiedBy: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AssessmentAuthoring>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssessmentAuthoring): AssessmentAuthoring {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string partner_id */ 1:
                    message.partnerId = reader.string();
                    break;
                case /* string scoring_word */ 2:
                    message.scoringWord = reader.string();
                    break;
                case /* repeated one.plan3t.core.model.AssessmentScoreAuthoring scores */ 3:
                    message.scores.push(AssessmentScoreAuthoring.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string edit_link */ 4:
                    message.editLink = reader.string();
                    break;
                case /* int32 score */ 5:
                    message.score = reader.int32();
                    break;
                case /* int32 score_max */ 6:
                    message.scoreMax = reader.int32();
                    break;
                case /* string createdBy */ 100:
                    message.createdBy = reader.string();
                    break;
                case /* google.protobuf.Timestamp createdAt */ 101:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string modifiedBy */ 102:
                    message.modifiedBy = reader.string();
                    break;
                case /* google.protobuf.Timestamp modifiedAt */ 103:
                    message.modifiedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.modifiedAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AssessmentAuthoring, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string partner_id = 1; */
        if (message.partnerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.partnerId);
        /* string scoring_word = 2; */
        if (message.scoringWord !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scoringWord);
        /* repeated one.plan3t.core.model.AssessmentScoreAuthoring scores = 3; */
        for (let i = 0; i < message.scores.length; i++)
            AssessmentScoreAuthoring.internalBinaryWrite(message.scores[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string edit_link = 4; */
        if (message.editLink !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.editLink);
        /* int32 score = 5; */
        if (message.score !== 0)
            writer.tag(5, WireType.Varint).int32(message.score);
        /* int32 score_max = 6; */
        if (message.scoreMax !== 0)
            writer.tag(6, WireType.Varint).int32(message.scoreMax);
        /* string createdBy = 100; */
        if (message.createdBy !== "")
            writer.tag(100, WireType.LengthDelimited).string(message.createdBy);
        /* google.protobuf.Timestamp createdAt = 101; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(101, WireType.LengthDelimited).fork(), options).join();
        /* string modifiedBy = 102; */
        if (message.modifiedBy !== "")
            writer.tag(102, WireType.LengthDelimited).string(message.modifiedBy);
        /* google.protobuf.Timestamp modifiedAt = 103; */
        if (message.modifiedAt)
            Timestamp.internalBinaryWrite(message.modifiedAt, writer.tag(103, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.AssessmentAuthoring
 */
export const AssessmentAuthoring = new AssessmentAuthoring$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentsAuthoringRequest$Type extends MessageType<GetAssessmentsAuthoringRequest> {
    constructor() {
        super("one.plan3t.core.model.GetAssessmentsAuthoringRequest", []);
    }
    create(value?: PartialMessage<GetAssessmentsAuthoringRequest>): GetAssessmentsAuthoringRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAssessmentsAuthoringRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAssessmentsAuthoringRequest): GetAssessmentsAuthoringRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetAssessmentsAuthoringRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetAssessmentsAuthoringRequest
 */
export const GetAssessmentsAuthoringRequest = new GetAssessmentsAuthoringRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentsAuthoringResponse$Type extends MessageType<GetAssessmentsAuthoringResponse> {
    constructor() {
        super("one.plan3t.core.model.GetAssessmentsAuthoringResponse", [
            { no: 1, name: "assessments", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssessmentAuthoring }
        ]);
    }
    create(value?: PartialMessage<GetAssessmentsAuthoringResponse>): GetAssessmentsAuthoringResponse {
        const message = { assessments: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAssessmentsAuthoringResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAssessmentsAuthoringResponse): GetAssessmentsAuthoringResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.AssessmentAuthoring assessments */ 1:
                    message.assessments.push(AssessmentAuthoring.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAssessmentsAuthoringResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.AssessmentAuthoring assessments = 1; */
        for (let i = 0; i < message.assessments.length; i++)
            AssessmentAuthoring.internalBinaryWrite(message.assessments[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetAssessmentsAuthoringResponse
 */
export const GetAssessmentsAuthoringResponse = new GetAssessmentsAuthoringResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplyAccountingCommandManualRequest$Type extends MessageType<ApplyAccountingCommandManualRequest> {
    constructor() {
        super("one.plan3t.core.model.ApplyAccountingCommandManualRequest", [
            { no: 1, name: "command", kind: "message", T: () => AccountingCommand }
        ]);
    }
    create(value?: PartialMessage<ApplyAccountingCommandManualRequest>): ApplyAccountingCommandManualRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ApplyAccountingCommandManualRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ApplyAccountingCommandManualRequest): ApplyAccountingCommandManualRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.AccountingCommand command */ 1:
                    message.command = AccountingCommand.internalBinaryRead(reader, reader.uint32(), options, message.command);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ApplyAccountingCommandManualRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.AccountingCommand command = 1; */
        if (message.command)
            AccountingCommand.internalBinaryWrite(message.command, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ApplyAccountingCommandManualRequest
 */
export const ApplyAccountingCommandManualRequest = new ApplyAccountingCommandManualRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplyAccountingCommandManualResponse$Type extends MessageType<ApplyAccountingCommandManualResponse> {
    constructor() {
        super("one.plan3t.core.model.ApplyAccountingCommandManualResponse", []);
    }
    create(value?: PartialMessage<ApplyAccountingCommandManualResponse>): ApplyAccountingCommandManualResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ApplyAccountingCommandManualResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ApplyAccountingCommandManualResponse): ApplyAccountingCommandManualResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ApplyAccountingCommandManualResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ApplyAccountingCommandManualResponse
 */
export const ApplyAccountingCommandManualResponse = new ApplyAccountingCommandManualResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRewardCodeRequest$Type extends MessageType<GetRewardCodeRequest> {
    constructor() {
        super("one.plan3t.core.model.GetRewardCodeRequest", [
            { no: 1, name: "code_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "reward_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "nonce", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetRewardCodeRequest>): GetRewardCodeRequest {
        const message = { codeId: "", rewardId: "", partnerId: "", nonce: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRewardCodeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRewardCodeRequest): GetRewardCodeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code_id */ 1:
                    message.codeId = reader.string();
                    break;
                case /* string reward_id */ 2:
                    message.rewardId = reader.string();
                    break;
                case /* string partner_id */ 3:
                    message.partnerId = reader.string();
                    break;
                case /* string nonce */ 4:
                    message.nonce = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRewardCodeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code_id = 1; */
        if (message.codeId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.codeId);
        /* string reward_id = 2; */
        if (message.rewardId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.rewardId);
        /* string partner_id = 3; */
        if (message.partnerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.partnerId);
        /* string nonce = 4; */
        if (message.nonce !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.nonce);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRewardCodeRequest
 */
export const GetRewardCodeRequest = new GetRewardCodeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRewardCodeResponse$Type extends MessageType<GetRewardCodeResponse> {
    constructor() {
        super("one.plan3t.core.model.GetRewardCodeResponse", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "owner", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "valid_until", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<GetRewardCodeResponse>): GetRewardCodeResponse {
        const message = { code: "", owner: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRewardCodeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRewardCodeResponse): GetRewardCodeResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                case /* string owner */ 2:
                    message.owner = reader.string();
                    break;
                case /* google.protobuf.Timestamp valid_until */ 3:
                    message.validUntil = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.validUntil);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRewardCodeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        /* string owner = 2; */
        if (message.owner !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.owner);
        /* google.protobuf.Timestamp valid_until = 3; */
        if (message.validUntil)
            Timestamp.internalBinaryWrite(message.validUntil, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRewardCodeResponse
 */
export const GetRewardCodeResponse = new GetRewardCodeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CashbackRate$Type extends MessageType<CashbackRate> {
    constructor() {
        super("one.plan3t.core.model.CashbackRate", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "rate", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "rate_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "fee", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "fee_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "valid_from", kind: "message", T: () => Timestamp },
            { no: 8, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 9, name: "modified_at", kind: "message", T: () => Timestamp },
            { no: 10, name: "sponsored_rate", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 11, name: "sponsored_rate_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<CashbackRate>): CashbackRate {
        const message = { id: "", partnerId: "", rate: 0, rateScale: 0, fee: 0, feeScale: 0, sponsoredRate: 0, sponsoredRateScale: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CashbackRate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CashbackRate): CashbackRate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string partner_id */ 2:
                    message.partnerId = reader.string();
                    break;
                case /* int32 rate */ 3:
                    message.rate = reader.int32();
                    break;
                case /* int32 rate_scale */ 4:
                    message.rateScale = reader.int32();
                    break;
                case /* int32 fee */ 5:
                    message.fee = reader.int32();
                    break;
                case /* int32 fee_scale */ 6:
                    message.feeScale = reader.int32();
                    break;
                case /* google.protobuf.Timestamp valid_from */ 7:
                    message.validFrom = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.validFrom);
                    break;
                case /* google.protobuf.Timestamp created_at */ 8:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp modified_at */ 9:
                    message.modifiedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.modifiedAt);
                    break;
                case /* int32 sponsored_rate */ 10:
                    message.sponsoredRate = reader.int32();
                    break;
                case /* int32 sponsored_rate_scale */ 11:
                    message.sponsoredRateScale = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CashbackRate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string partner_id = 2; */
        if (message.partnerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerId);
        /* int32 rate = 3; */
        if (message.rate !== 0)
            writer.tag(3, WireType.Varint).int32(message.rate);
        /* int32 rate_scale = 4; */
        if (message.rateScale !== 0)
            writer.tag(4, WireType.Varint).int32(message.rateScale);
        /* int32 fee = 5; */
        if (message.fee !== 0)
            writer.tag(5, WireType.Varint).int32(message.fee);
        /* int32 fee_scale = 6; */
        if (message.feeScale !== 0)
            writer.tag(6, WireType.Varint).int32(message.feeScale);
        /* google.protobuf.Timestamp valid_from = 7; */
        if (message.validFrom)
            Timestamp.internalBinaryWrite(message.validFrom, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 8; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp modified_at = 9; */
        if (message.modifiedAt)
            Timestamp.internalBinaryWrite(message.modifiedAt, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* int32 sponsored_rate = 10; */
        if (message.sponsoredRate !== 0)
            writer.tag(10, WireType.Varint).int32(message.sponsoredRate);
        /* int32 sponsored_rate_scale = 11; */
        if (message.sponsoredRateScale !== 0)
            writer.tag(11, WireType.Varint).int32(message.sponsoredRateScale);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CashbackRate
 */
export const CashbackRate = new CashbackRate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCurrentCashbackRatesRequest$Type extends MessageType<GetCurrentCashbackRatesRequest> {
    constructor() {
        super("one.plan3t.core.model.GetCurrentCashbackRatesRequest", []);
    }
    create(value?: PartialMessage<GetCurrentCashbackRatesRequest>): GetCurrentCashbackRatesRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCurrentCashbackRatesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCurrentCashbackRatesRequest): GetCurrentCashbackRatesRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetCurrentCashbackRatesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetCurrentCashbackRatesRequest
 */
export const GetCurrentCashbackRatesRequest = new GetCurrentCashbackRatesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCurrentCashbackRatesResponse$Type extends MessageType<GetCurrentCashbackRatesResponse> {
    constructor() {
        super("one.plan3t.core.model.GetCurrentCashbackRatesResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CashbackRate }
        ]);
    }
    create(value?: PartialMessage<GetCurrentCashbackRatesResponse>): GetCurrentCashbackRatesResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCurrentCashbackRatesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCurrentCashbackRatesResponse): GetCurrentCashbackRatesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.CashbackRate items */ 1:
                    message.items.push(CashbackRate.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCurrentCashbackRatesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.CashbackRate items = 1; */
        for (let i = 0; i < message.items.length; i++)
            CashbackRate.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetCurrentCashbackRatesResponse
 */
export const GetCurrentCashbackRatesResponse = new GetCurrentCashbackRatesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCashbackRateScheduleRequest$Type extends MessageType<GetCashbackRateScheduleRequest> {
    constructor() {
        super("one.plan3t.core.model.GetCashbackRateScheduleRequest", [
            { no: 1, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCashbackRateScheduleRequest>): GetCashbackRateScheduleRequest {
        const message = { partnerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCashbackRateScheduleRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCashbackRateScheduleRequest): GetCashbackRateScheduleRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string partner_id */ 1:
                    message.partnerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCashbackRateScheduleRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string partner_id = 1; */
        if (message.partnerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.partnerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetCashbackRateScheduleRequest
 */
export const GetCashbackRateScheduleRequest = new GetCashbackRateScheduleRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCashbackRateScheduleResponse$Type extends MessageType<GetCashbackRateScheduleResponse> {
    constructor() {
        super("one.plan3t.core.model.GetCashbackRateScheduleResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CashbackRate }
        ]);
    }
    create(value?: PartialMessage<GetCashbackRateScheduleResponse>): GetCashbackRateScheduleResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCashbackRateScheduleResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCashbackRateScheduleResponse): GetCashbackRateScheduleResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.CashbackRate items */ 1:
                    message.items.push(CashbackRate.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCashbackRateScheduleResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.CashbackRate items = 1; */
        for (let i = 0; i < message.items.length; i++)
            CashbackRate.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetCashbackRateScheduleResponse
 */
export const GetCashbackRateScheduleResponse = new GetCashbackRateScheduleResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetCashbackRateRequest$Type extends MessageType<SetCashbackRateRequest> {
    constructor() {
        super("one.plan3t.core.model.SetCashbackRateRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "rate", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "rate_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "fee", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "fee_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "valid_from", kind: "message", T: () => Timestamp },
            { no: 8, name: "sponsored_rate", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 9, name: "sponsored_rate_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<SetCashbackRateRequest>): SetCashbackRateRequest {
        const message = { id: "", partnerId: "", rate: 0, rateScale: 0, fee: 0, feeScale: 0, sponsoredRate: 0, sponsoredRateScale: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetCashbackRateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetCashbackRateRequest): SetCashbackRateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string partner_id */ 2:
                    message.partnerId = reader.string();
                    break;
                case /* int32 rate */ 3:
                    message.rate = reader.int32();
                    break;
                case /* int32 rate_scale */ 4:
                    message.rateScale = reader.int32();
                    break;
                case /* int32 fee */ 5:
                    message.fee = reader.int32();
                    break;
                case /* int32 fee_scale */ 6:
                    message.feeScale = reader.int32();
                    break;
                case /* google.protobuf.Timestamp valid_from */ 7:
                    message.validFrom = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.validFrom);
                    break;
                case /* int32 sponsored_rate */ 8:
                    message.sponsoredRate = reader.int32();
                    break;
                case /* int32 sponsored_rate_scale */ 9:
                    message.sponsoredRateScale = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SetCashbackRateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string partner_id = 2; */
        if (message.partnerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerId);
        /* int32 rate = 3; */
        if (message.rate !== 0)
            writer.tag(3, WireType.Varint).int32(message.rate);
        /* int32 rate_scale = 4; */
        if (message.rateScale !== 0)
            writer.tag(4, WireType.Varint).int32(message.rateScale);
        /* int32 fee = 5; */
        if (message.fee !== 0)
            writer.tag(5, WireType.Varint).int32(message.fee);
        /* int32 fee_scale = 6; */
        if (message.feeScale !== 0)
            writer.tag(6, WireType.Varint).int32(message.feeScale);
        /* google.protobuf.Timestamp valid_from = 7; */
        if (message.validFrom)
            Timestamp.internalBinaryWrite(message.validFrom, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* int32 sponsored_rate = 8; */
        if (message.sponsoredRate !== 0)
            writer.tag(8, WireType.Varint).int32(message.sponsoredRate);
        /* int32 sponsored_rate_scale = 9; */
        if (message.sponsoredRateScale !== 0)
            writer.tag(9, WireType.Varint).int32(message.sponsoredRateScale);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SetCashbackRateRequest
 */
export const SetCashbackRateRequest = new SetCashbackRateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SetCashbackRateResponse$Type extends MessageType<SetCashbackRateResponse> {
    constructor() {
        super("one.plan3t.core.model.SetCashbackRateResponse", []);
    }
    create(value?: PartialMessage<SetCashbackRateResponse>): SetCashbackRateResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SetCashbackRateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SetCashbackRateResponse): SetCashbackRateResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SetCashbackRateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SetCashbackRateResponse
 */
export const SetCashbackRateResponse = new SetCashbackRateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ManuallyApplyCashbackRequest$Type extends MessageType<ManuallyApplyCashbackRequest> {
    constructor() {
        super("one.plan3t.core.model.ManuallyApplyCashbackRequest", [
            { no: 1, name: "transaction_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "document", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 4, name: "mime_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "document_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ManuallyApplyCashbackRequest>): ManuallyApplyCashbackRequest {
        const message = { transactionId: "", partnerId: "", document: new Uint8Array(0), mimeType: "", documentIdentifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ManuallyApplyCashbackRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ManuallyApplyCashbackRequest): ManuallyApplyCashbackRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string transaction_id */ 1:
                    message.transactionId = reader.string();
                    break;
                case /* string partner_id */ 2:
                    message.partnerId = reader.string();
                    break;
                case /* bytes document */ 3:
                    message.document = reader.bytes();
                    break;
                case /* string mime_type */ 4:
                    message.mimeType = reader.string();
                    break;
                case /* string document_identifier */ 5:
                    message.documentIdentifier = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ManuallyApplyCashbackRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string transaction_id = 1; */
        if (message.transactionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.transactionId);
        /* string partner_id = 2; */
        if (message.partnerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerId);
        /* bytes document = 3; */
        if (message.document.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.document);
        /* string mime_type = 4; */
        if (message.mimeType !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.mimeType);
        /* string document_identifier = 5; */
        if (message.documentIdentifier !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.documentIdentifier);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ManuallyApplyCashbackRequest
 */
export const ManuallyApplyCashbackRequest = new ManuallyApplyCashbackRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ManuallyApplyCashbackResponse$Type extends MessageType<ManuallyApplyCashbackResponse> {
    constructor() {
        super("one.plan3t.core.model.ManuallyApplyCashbackResponse", []);
    }
    create(value?: PartialMessage<ManuallyApplyCashbackResponse>): ManuallyApplyCashbackResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ManuallyApplyCashbackResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ManuallyApplyCashbackResponse): ManuallyApplyCashbackResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ManuallyApplyCashbackResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ManuallyApplyCashbackResponse
 */
export const ManuallyApplyCashbackResponse = new ManuallyApplyCashbackResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplyExternalCashbackRequest$Type extends MessageType<ApplyExternalCashbackRequest> {
    constructor() {
        super("one.plan3t.core.model.ApplyExternalCashbackRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "order_amount_unscaled", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "order_amount_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "order_date", kind: "message", T: () => Timestamp },
            { no: 10, name: "document", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 11, name: "mime_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "document_identifier", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ApplyExternalCashbackRequest>): ApplyExternalCashbackRequest {
        const message = { id: "", partnerId: "", userId: "", orderAmountUnscaled: 0, orderAmountScale: 0, document: new Uint8Array(0), mimeType: "", documentIdentifier: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ApplyExternalCashbackRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ApplyExternalCashbackRequest): ApplyExternalCashbackRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string partner_id */ 2:
                    message.partnerId = reader.string();
                    break;
                case /* string user_id */ 3:
                    message.userId = reader.string();
                    break;
                case /* int32 order_amount_unscaled */ 4:
                    message.orderAmountUnscaled = reader.int32();
                    break;
                case /* int32 order_amount_scale */ 5:
                    message.orderAmountScale = reader.int32();
                    break;
                case /* google.protobuf.Timestamp order_date */ 6:
                    message.orderDate = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.orderDate);
                    break;
                case /* bytes document */ 10:
                    message.document = reader.bytes();
                    break;
                case /* string mime_type */ 11:
                    message.mimeType = reader.string();
                    break;
                case /* string document_identifier */ 12:
                    message.documentIdentifier = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ApplyExternalCashbackRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string partner_id = 2; */
        if (message.partnerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerId);
        /* string user_id = 3; */
        if (message.userId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.userId);
        /* int32 order_amount_unscaled = 4; */
        if (message.orderAmountUnscaled !== 0)
            writer.tag(4, WireType.Varint).int32(message.orderAmountUnscaled);
        /* int32 order_amount_scale = 5; */
        if (message.orderAmountScale !== 0)
            writer.tag(5, WireType.Varint).int32(message.orderAmountScale);
        /* google.protobuf.Timestamp order_date = 6; */
        if (message.orderDate)
            Timestamp.internalBinaryWrite(message.orderDate, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* bytes document = 10; */
        if (message.document.length)
            writer.tag(10, WireType.LengthDelimited).bytes(message.document);
        /* string mime_type = 11; */
        if (message.mimeType !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.mimeType);
        /* string document_identifier = 12; */
        if (message.documentIdentifier !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.documentIdentifier);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ApplyExternalCashbackRequest
 */
export const ApplyExternalCashbackRequest = new ApplyExternalCashbackRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplyExternalCashbackResponse$Type extends MessageType<ApplyExternalCashbackResponse> {
    constructor() {
        super("one.plan3t.core.model.ApplyExternalCashbackResponse", []);
    }
    create(value?: PartialMessage<ApplyExternalCashbackResponse>): ApplyExternalCashbackResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ApplyExternalCashbackResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ApplyExternalCashbackResponse): ApplyExternalCashbackResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ApplyExternalCashbackResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ApplyExternalCashbackResponse
 */
export const ApplyExternalCashbackResponse = new ApplyExternalCashbackResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MarkRewardCodeAsUsedRequest$Type extends MessageType<MarkRewardCodeAsUsedRequest> {
    constructor() {
        super("one.plan3t.core.model.MarkRewardCodeAsUsedRequest", [
            { no: 1, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "reward_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "codes", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "billing_period", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "fee_unscaled", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "fee_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "document_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MarkRewardCodeAsUsedRequest>): MarkRewardCodeAsUsedRequest {
        const message = { partnerId: "", rewardId: "", codes: [], billingPeriod: 0, feeUnscaled: 0, feeScale: 0, documentId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MarkRewardCodeAsUsedRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MarkRewardCodeAsUsedRequest): MarkRewardCodeAsUsedRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string partner_id */ 1:
                    message.partnerId = reader.string();
                    break;
                case /* string reward_id */ 2:
                    message.rewardId = reader.string();
                    break;
                case /* repeated string codes */ 3:
                    message.codes.push(reader.string());
                    break;
                case /* int64 billing_period */ 4:
                    message.billingPeriod = reader.int64().toNumber();
                    break;
                case /* int32 fee_unscaled */ 5:
                    message.feeUnscaled = reader.int32();
                    break;
                case /* int32 fee_scale */ 6:
                    message.feeScale = reader.int32();
                    break;
                case /* string document_id */ 7:
                    message.documentId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MarkRewardCodeAsUsedRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string partner_id = 1; */
        if (message.partnerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.partnerId);
        /* string reward_id = 2; */
        if (message.rewardId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.rewardId);
        /* repeated string codes = 3; */
        for (let i = 0; i < message.codes.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.codes[i]);
        /* int64 billing_period = 4; */
        if (message.billingPeriod !== 0)
            writer.tag(4, WireType.Varint).int64(message.billingPeriod);
        /* int32 fee_unscaled = 5; */
        if (message.feeUnscaled !== 0)
            writer.tag(5, WireType.Varint).int32(message.feeUnscaled);
        /* int32 fee_scale = 6; */
        if (message.feeScale !== 0)
            writer.tag(6, WireType.Varint).int32(message.feeScale);
        /* string document_id = 7; */
        if (message.documentId !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.documentId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.MarkRewardCodeAsUsedRequest
 */
export const MarkRewardCodeAsUsedRequest = new MarkRewardCodeAsUsedRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MarkRewardCodeAsUsedResponse$Type extends MessageType<MarkRewardCodeAsUsedResponse> {
    constructor() {
        super("one.plan3t.core.model.MarkRewardCodeAsUsedResponse", []);
    }
    create(value?: PartialMessage<MarkRewardCodeAsUsedResponse>): MarkRewardCodeAsUsedResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MarkRewardCodeAsUsedResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MarkRewardCodeAsUsedResponse): MarkRewardCodeAsUsedResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MarkRewardCodeAsUsedResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.MarkRewardCodeAsUsedResponse
 */
export const MarkRewardCodeAsUsedResponse = new MarkRewardCodeAsUsedResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GeneratePasswordResetLinkRequest$Type extends MessageType<GeneratePasswordResetLinkRequest> {
    constructor() {
        super("one.plan3t.core.model.GeneratePasswordResetLinkRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scope", kind: "enum", T: () => ["one.plan3t.core.model.GeneratePasswordResetLinkRequest.Scope", GeneratePasswordResetLinkRequest_Scope, "SCOPE_"] }
        ]);
    }
    create(value?: PartialMessage<GeneratePasswordResetLinkRequest>): GeneratePasswordResetLinkRequest {
        const message = { email: "", scope: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GeneratePasswordResetLinkRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GeneratePasswordResetLinkRequest): GeneratePasswordResetLinkRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* one.plan3t.core.model.GeneratePasswordResetLinkRequest.Scope scope */ 2:
                    message.scope = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GeneratePasswordResetLinkRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* one.plan3t.core.model.GeneratePasswordResetLinkRequest.Scope scope = 2; */
        if (message.scope !== 0)
            writer.tag(2, WireType.Varint).int32(message.scope);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GeneratePasswordResetLinkRequest
 */
export const GeneratePasswordResetLinkRequest = new GeneratePasswordResetLinkRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GeneratePasswordResetLinkResponse$Type extends MessageType<GeneratePasswordResetLinkResponse> {
    constructor() {
        super("one.plan3t.core.model.GeneratePasswordResetLinkResponse", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GeneratePasswordResetLinkResponse>): GeneratePasswordResetLinkResponse {
        const message = { url: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GeneratePasswordResetLinkResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GeneratePasswordResetLinkResponse): GeneratePasswordResetLinkResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GeneratePasswordResetLinkResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GeneratePasswordResetLinkResponse
 */
export const GeneratePasswordResetLinkResponse = new GeneratePasswordResetLinkResponse$Type();
