import React from 'react';

const IndexPlaceholder: React.FunctionComponent = () => {
    return (
        <div className="bg-gray-50 h-screen">
            <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
                <h2 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                    Willkommen bei PLAN3T
                    <br />
                    <span className="text-green-600">
                        Suchst Du nach dem Partner Manager?
                    </span>
                </h2>
                <div className="mt-8 flex lg:flex-shrink-0 lg:mt-0">
                    <div className="inline-flex rounded-md shadow">
                        <a
                            href="https://partnermanager.plan3t.one"
                            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                        >
                            Zum Partner Manager
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IndexPlaceholder;
