import React, { useCallback } from 'react';
import { challengeModule } from './store';
import {
    ChallengePreview,
    ChallengeType,
    CoreIndustry,
} from '../api/model/core';
import {
    FormCheckboxField,
    FormCoreIndustryField,
    FormInternalImageField,
    FormItemAssociationField,
    FormNumberField,
    FormPublishedAtField,
    FormSelectField,
    FormTextAreaField,
    FormTextField,
} from '../common/FormFields';
import FormPartnerAssociationField from '../common/FormPartnerAssociationField';
import SavingsPotentialsField from './SavingPotentialsField';
import FormBlogReferenceAssociationField from './FormBlogReferenceAssociationField';
import ItemForm from '../crud-module/ItemForm';
import { tipModule } from '../tip/store';

type Props = {
    id: string | undefined;
};

const ChallengeForm: React.FunctionComponent<Props> = ({ id }) => {
    const isNew = id === undefined;

    const validate = useCallback((challenge: ChallengePreview) => {
        const errors: { [K in keyof ChallengePreview]?: string } = {};

        if (!challenge.name) {
            errors.name = 'Required';
        } else if (challenge.name.length < 2) {
            errors.name = 'Too short';
        } else if (challenge.name.length > 255) {
            errors.name = 'Too Long';
        }

        if (challenge.coreIndustry === CoreIndustry.UnknownIndustry) {
            errors.coreIndustry = 'Required';
        }

        if (challenge.type === ChallengeType.ChallengeTypeUnknown) {
            errors.type = 'Required';
        }

        if (challenge.type !== ChallengeType.ChallengeTypeHabit) {
            errors.type = 'Only habit supported right now';
        }

        if (!!challenge.publishedAt && !challenge.image?.url) {
            errors.image = 'Required';
        }

        return errors;
    }, []);

    return (
        <ItemForm module={challengeModule} id={id} validate={validate}>
            <FormTextField name="name" label="Name" />
            <FormTextAreaField name="description" label="Description" />
            <FormInternalImageField name="image" label="Image URL" />
            <FormCoreIndustryField name="coreIndustry" label="Impact Area" />
            <FormSelectField
                name="type"
                label="Type"
                options={[
                    {
                        label: 'Unknown',
                        value: ChallengeType.ChallengeTypeUnknown,
                    },
                    {
                        label: 'Habit',
                        value: ChallengeType.ChallengeTypeHabit,
                    },
                    {
                        label: 'Once',
                        value: ChallengeType.ChallengeTypeOnce,
                    },
                    {
                        label: 'Repeatable',
                        value: ChallengeType.ChallengeTypeRepeatable,
                    },
                ]}
            />
            <FormTextField name="category" label="Category" />

            <FormTextField name="keyIdeas.0" label="KeyIdea #1" />
            <FormTextField name="keyIdeas.1" label="KeyIdea #2" />
            <FormTextField name="keyIdeas.2" label="KeyIdea #3" />

            <FormNumberField
                name="award.amount"
                label="Plan3t Points"
                min={0}
            />
            <SavingsPotentialsField />
            <FormBlogReferenceAssociationField
                label="Blog References"
                name="blogReferenceIds"
            />
            <FormItemAssociationField
                name="tipIds"
                label="Associated Tips"
                module={tipModule}
                nameGetter={(t) => t.title}
            />
            <div className="flex space-x-2">
                <FormCheckboxField name="active" label="Active" />
                <FormPublishedAtField
                    name="publishedAt"
                    label="Publish"
                    disabled={!isNew}
                />
            </div>
            <FormPartnerAssociationField name="partnerIds" label="Partner" />
        </ItemForm>
    );
};

export default ChallengeForm;
