import React, { FunctionComponent } from 'react';
import { useField } from 'formik';
import { ChallengeEstimatedSavings } from '../api/model/core';

type Props = {};

function toPercentage(v: string): number {
    const res = +(parseInt(v, 10) / 100).toFixed(2);
    if (Number.isNaN(res)) {
        return 0;
    }
    return res;
}

const SavingsPotentialsField: FunctionComponent<Props> = (props) => {
    const name = 'estimatedSavings';

    const [absolute] = useField<number>(`${name}.absoluteSavingsInKg`);
    const [housing] = useField<number>(`${name}.housing`);
    const [mobility] = useField<number>(`${name}.mobility`);
    const [nutrition] = useField<number>(`${name}.nutrition`);
    const [consumption] = useField<number>(`${name}.consumption`);

    const [{ value: raw }, meta, h] = useField<ChallengeEstimatedSavings>(name);

    const value = {
        absoluteSavingsInKg: raw?.absoluteSavingsInKg ?? 0,
        housing: raw?.housing ?? 0,
        mobility: raw?.mobility ?? 0,
        nutrition: raw?.nutrition ?? 0,
        consumption: raw?.consumption ?? 0,
    };

    return (
        <div>
            <div className="block">
                <span className="text-gray-700">CO2 Savings</span>
                <div className="flex flex-row space-x-2">
                    <label className="block">
                        <span className="text-gray-700">KG</span>
                        <input
                            type="number"
                            className="form-input mt-1 block w-full"
                            value={absolute.value || 0}
                            onChange={(e) => {
                                h.setValue({
                                    ...value,
                                    absoluteSavingsInKg: parseInt(
                                        e.target.value,
                                        10,
                                    ),
                                });
                            }}
                            min={0}
                        />
                    </label>
                    <label className="block">
                        <span className="text-gray-700">% Energy</span>
                        <input
                            type="number"
                            className="form-input mt-1 block w-full"
                            value={((housing.value || 0) * 100).toFixed(0)}
                            onChange={(e) => {
                                h.setValue({
                                    ...value,
                                    housing: toPercentage(e.target.value),
                                });
                            }}
                            min={0}
                            max={100}
                        />
                    </label>
                    <label className="block">
                        <span className="text-gray-700">% Mobility</span>
                        <input
                            type="number"
                            className="form-input mt-1 block w-full"
                            value={((mobility.value || 0) * 100).toFixed(0)}
                            onChange={(e) => {
                                h.setValue({
                                    ...value,
                                    mobility: toPercentage(e.target.value),
                                });
                            }}
                            min={0}
                            max={100}
                        />
                    </label>
                    <label className="block">
                        <span className="text-gray-700">% Nutrition</span>
                        <input
                            type="number"
                            className="form-input mt-1 block w-full"
                            value={((nutrition.value || 0) * 100).toFixed(0)}
                            onChange={(e) => {
                                h.setValue({
                                    ...value,
                                    nutrition: toPercentage(e.target.value),
                                });
                            }}
                            min={0}
                            max={100}
                        />
                    </label>
                    <label className="block">
                        <span className="text-gray-700">% Consumption</span>
                        <input
                            type="number"
                            className="form-input mt-1 block w-full"
                            value={((consumption.value || 0) * 100).toFixed(0)}
                            onChange={(e) => {
                                h.setValue({
                                    ...value,
                                    consumption: toPercentage(e.target.value),
                                });
                            }}
                            min={0}
                            max={100}
                        />
                    </label>
                </div>
            </div>
            {meta.touched && meta.error ? (
                <p className="font-medium text-sm text-red-400 leading-5 py-1">
                    {meta.error}
                </p>
            ) : (
                <div className="py-1 leading-5">&nbsp;</div>
            )}
        </div>
    );
};

export default SavingsPotentialsField;
