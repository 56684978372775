import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    GoogleAuthProvider,
    signInWithPopup,
    signOut,
    User,
} from 'firebase/auth';
import { AppState } from '../redux/AppStore';
import { auth } from './firebase';

const login = createAsyncThunk('auth/login', async (): Promise<activeUser> => {
    const provider = new GoogleAuthProvider();

    const res = await signInWithPopup(auth, provider);

    if (res.user === null) {
        return Promise.reject('auth.error.no_user');
    }

    return transformUser(res.user);
});

const loadUser = createAsyncThunk('auth/load', async (user: User | null) => {
    if (user === null) {
        return null;
    }
    return transformUser(user);
});

const logout = createAsyncThunk('auth/logout', async () => {
    await signOut(auth);
});

const transformUser = async (user: User): Promise<activeUser> => {
    const result = await user.getIdTokenResult();

    const email = (user.emailVerified ? user.email : null) || '';
    const allowedDomains = ['plan3t.one', 'cc.systems'];
    const allowed = allowedDomains.some((d) => email.endsWith('@' + d));
    if (!allowed) {
        return Promise.reject('auth.error.invalid_email');
    }

    return {
        uid: result.claims['sub'] as string,
        email: user.email!,
        token: result.token,
    };
};

type activeUser = {
    uid: string;
    email: string;
    token: string;
};

type AuthState = {
    active: activeUser | null;
    loginError?: any;
    loginInProgress: boolean;
    initialized: boolean;
};

const initialState: AuthState = {
    active: null,
    loginInProgress: false,
    initialized: false,
};
const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state, action) => {
            state.loginInProgress = true;
        });
        builder.addCase(login.rejected, (state, action) => {
            state.loginInProgress = false;
            state.loginError = action.error;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            state.active = action.payload;
            state.loginInProgress = false;
        });

        builder.addCase(loadUser.fulfilled, (state, action) => {
            state.active = action.payload;
            state.initialized = true;
            state.loginInProgress = false;
        });

        builder.addCase(logout.fulfilled, (state) => {
            state.active = null;
        });
    },
});

export const authReducer = authSlice.reducer;
export const authActions = {
    ...authSlice.actions,
    login,
    logout,
    loadUser,
};

const s = (state: AppState): AuthState => state.auth;
export const authSelectors = {
    isSignedIn: (state: AppState): boolean => s(state).active !== null,
    getEmail: (state: AppState) => s(state).active?.email,
    isInitialized: (state: AppState): boolean => s(state).initialized,
} as const;
