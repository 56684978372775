import PartnerName from '../partner/PartnerName';
import { useHistory } from 'react-router-dom';
import {
    partnerBaseWithPeriodStateQuery,
    periodForCode,
    PeriodStateCode,
    resubmissionDocumentsQuery,
    useChangePeriodStateCode,
} from './store';
import cx from 'classnames';
import React, { Suspense, useMemo, useState } from 'react';
import {
    ActionSheet,
    Breadcrumbs,
    BreadcrumbsItem,
    BusyIndicator,
    Button,
    ButtonDesign,
    DynamicPage,
    DynamicPageHeader,
    DynamicPageTitle,
    FilterBar,
    FilterGroupItem,
    FlexBox,
    FlexBoxDirection,
    Icon,
    Input,
    Label,
    ObjectStatus,
    Option,
    Select,
    Table,
    TableColumn,
    TableRow,
    Title,
    ToolbarSeparator,
    ValueState,
} from '@ui5/webcomponents-react';
import { TableCell } from '@ui5/webcomponents-react/wrappers';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { Timestamp } from '../api/google/protobuf/timestamp';
import PeriodTotalsSection from './PeriodTotalsSection';
import ErrorBoundary from '../common/ErrorBoundary';

type Filter = {
    state: string | undefined;
    search: string | undefined;
};

const _filterAtom = atom<Filter>({
    key: 'billing/BillingPeriodOverview/_filterAtom',
    default: {
        state: undefined,
        search: undefined,
    },
});

export default function BillingPeriodOverview(props: { periodCode: string }) {
    const partners = useRecoilValue(
        partnerBaseWithPeriodStateQuery(props.periodCode),
    );
    const period = useRecoilValue(periodForCode(props.periodCode));

    const [filter, setFilter] = useRecoilState(_filterAtom);

    const visiblePartners = useMemo(
        () =>
            partners
                .filter(({ partner }) => {
                    if (!partner.createdAt || !period.endsAt) {
                        return true;
                    }
                    const createdAt = Timestamp.toDate(partner.createdAt);
                    return (
                        createdAt <=
                        new Date(
                            `${period.endsAt.year}-${period.endsAt.month}-${period.endsAt.day}`,
                        )
                    );
                })
                .filter(({ partner, state }) => {
                    const stateMatches =
                        filter.state === undefined || state === filter.state;
                    const searchMatches =
                        filter.search === undefined ||
                        filter.search.trim().length === 0 ||
                        Object.values(partner)
                            .join('')
                            .toLowerCase()
                            .includes(filter.search.toLowerCase());

                    return stateMatches && searchMatches;
                }),
        [partners, period, filter],
    );

    const history = useHistory();

    return (
        <DynamicPage
            headerTitle={
                <DynamicPageTitle
                    breadcrumbs={
                        <Breadcrumbs
                            onItemClick={(e) => {
                                e.preventDefault();
                                const { item } = e.detail;
                                if (
                                    'href' in item &&
                                    // @ts-ignore
                                    typeof item.href === 'string'
                                ) {
                                    // @ts-ignore
                                    history.push(item.href);
                                }
                            }}
                        >
                            <BreadcrumbsItem href="/billing">
                                Billing
                            </BreadcrumbsItem>
                            <BreadcrumbsItem>
                                {props.periodCode}
                            </BreadcrumbsItem>
                        </Breadcrumbs>
                    }
                    header={<Title>{props.periodCode}</Title>}
                />
            }
            headerContent={
                <DynamicPageHeader>
                    <FlexBox
                        direction={FlexBoxDirection.Row}
                        className="space-x-4"
                    >
                        <FlexBox direction={FlexBoxDirection.Column}>
                            <Label>Dokumente zur Wiedervorlage</Label>
                            <Suspense fallback={<BusyIndicator />}>
                                <NumberOfResubmissionDocuments
                                    periodCode={props.periodCode}
                                />
                            </Suspense>
                        </FlexBox>
                        <ToolbarSeparator />
                        <ErrorBoundary>
                            <Suspense fallback={<BusyIndicator />}>
                                <PeriodTotalsSection
                                    periodCode={props.periodCode}
                                />
                            </Suspense>
                        </ErrorBoundary>
                    </FlexBox>
                </DynamicPageHeader>
            }
        >
            <FilterBar hideFilterConfiguration>
                <FilterGroupItem label="Search">
                    <Input
                        icon={<Icon name="search" className="w-full h-full" />}
                        value={filter.search ?? ''}
                        onInput={(e) =>
                            setFilter((f) => ({
                                ...f,
                                search: e.target.value || undefined,
                            }))
                        }
                    />
                </FilterGroupItem>
                <FilterGroupItem label="State">
                    <Select
                        onChange={(e) =>
                            setFilter((f) => ({
                                ...f,
                                state:
                                    e.detail.selectedOption.dataset.id ||
                                    undefined,
                            }))
                        }
                    >
                        <Option selected={filter.state === undefined}>
                            All
                        </Option>
                        {['I', 'H', 'O', 'R', 'C'].map((s) => (
                            <Option
                                key={s}
                                data-id={s}
                                selected={filter.state === s}
                            >
                                {s}
                            </Option>
                        ))}
                    </Select>
                </FilterGroupItem>
            </FilterBar>
            <Table
                columns={
                    <>
                        <TableColumn>
                            <Label>Partner</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Status</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Wiedervorlagen</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Aktionen</Label>
                        </TableColumn>
                    </>
                }
                noDataText="-/-"
            >
                {visiblePartners.map(({ partner }) => (
                    <TableRow key={partner.id}>
                        <TableCell>
                            <Label>
                                <PartnerName id={partner.id} />
                            </Label>
                        </TableCell>
                        <TableCell>
                            <Suspense fallback={<BusyIndicator />}>
                                <BillingPeriodStateChip
                                    periodCode={props.periodCode}
                                    partnerId={partner.id}
                                />
                            </Suspense>
                        </TableCell>
                        <TableCell>
                            <Suspense fallback={<BusyIndicator />}>
                                <NumberOfResubmissionDocuments
                                    periodCode={props.periodCode}
                                    partnerId={partner.id}
                                />
                            </Suspense>
                        </TableCell>
                        <TableCell>
                            <FlexBox>
                                <Button
                                    icon="arrow-right"
                                    onClick={() =>
                                        history.push(
                                            `/billing/${props.periodCode}/${partner.id}`,
                                        )
                                    }
                                />
                            </FlexBox>
                        </TableCell>
                    </TableRow>
                ))}
            </Table>
        </DynamicPage>
    );
}

export function BillingPeriodStateChip(props: {
    periodCode: string;
    partnerId: string;
}) {
    const { state, isChanging, change } = useChangePeriodStateCode(
        props.periodCode,
        props.partnerId,
    );

    const onClick = async () => {
        const res = window.prompt('Change state to:');

        if (!res) {
            return;
        }

        if (!['H', 'O', 'R', 'C'].includes(res)) {
            window.alert('Invalid state code');
            return;
        }

        await change(res);
    };

    return (
        <div className="flex justify-start items-center">
            <button
                className={cx(
                    'rounded-full flex-shrink border px-4 text-body1',
                    {
                        'border-red-400 bg-red-200 text-red-800':
                            state === PeriodStateCode.Initial,
                        'border-blue-400 bg-blue-200 text-blue-800':
                            state === PeriodStateCode.HalfOpen,
                        'border-green-400 bg-green-200 text-green-800':
                            state === PeriodStateCode.Open,
                        'border-yellow-400 bg-yellow-200 text-yellow-800':
                            state === PeriodStateCode.Review,
                        'border-gray-100 bg-gray-50 text-gray-600':
                            state === PeriodStateCode.Closed,
                        'opacity-50': isChanging,
                    },
                )}
                onClick={onClick}
            >
                <pre>
                    {state}
                    {isChanging && '?'}
                </pre>
            </button>
        </div>
    );
}

export function PeriodStateChangerButton(props: {
    periodCode: string;
    partnerId: string;
    slot?: string;
}) {
    const [open, setOpen] = useState(false);
    const { state, change } = useChangePeriodStateCode(
        props.periodCode,
        props.partnerId,
    );

    const stateCodes = ['H', 'O', 'R', 'C'];

    const id = `PeriodStateChangerButton-${props.periodCode}-${props.partnerId}`;

    return (
        <>
            <Button
                id={id}
                slot={props.slot}
                design={ButtonDesign.Emphasized}
                onClick={() => setOpen(true)}
            >
                Status anpassen
            </Button>
            <ActionSheet
                open={open}
                opener={id}
                onAfterClose={() => setOpen(false)}
            >
                {stateCodes.map((s) => (
                    <Button
                        key={s}
                        onClick={s !== state ? () => change(s) : undefined}
                        disabled={s === state}
                    >
                        {s}
                    </Button>
                ))}
            </ActionSheet>
        </>
    );
}

function NumberOfResubmissionDocuments(props: {
    periodCode: string;
    partnerId?: string;
}) {
    const items = useRecoilValue(resubmissionDocumentsQuery(props.periodCode));

    const filteredItems = props.partnerId
        ? items.filter((d) => d.partnerId === props.partnerId)
        : items;

    const state =
        filteredItems.length === 0 ? ValueState.Success : ValueState.Warning;

    return (
        <ObjectStatus showDefaultIcon state={state}>
            {filteredItems.length}
        </ObjectStatus>
    );
}
