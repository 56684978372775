import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    AssessmentAuthoring,
    FetchAssessmentScoresRequest,
    FetchAssessmentScoresResponse,
    GetAssessmentsAuthoringResponse,
} from '../api/model/backoffice';
import axios from 'axios';

const getAssessments = createAsyncThunk(
    'assessment/getAssessments',
    async () => {
        const raw = await axios.get<Object>(`/assessment`, {});
        return GetAssessmentsAuthoringResponse.fromJson(raw.data as any);
    },
);

const fetchAssessmentsFromSheet = createAsyncThunk(
    'assessment/fetchFromSheet',
    async (overwrite: boolean) => {
        const raw = await axios.post<Object>(
            `/assessment/fetch`,
            FetchAssessmentScoresRequest.create({ forceOverwrite: overwrite }),
        );
        return FetchAssessmentScoresResponse.fromJson(raw.data as any);
    },
);

type AssessmentsState = {
    isLoading: boolean;
    error: any;
    assessments: AssessmentAuthoring[] | undefined;
};

type FetchingState = {
    isFetching: boolean;
    fetchError: any;
    res: FetchAssessmentScoresResponse | undefined;
};

const initialState: AssessmentsState = {
    isLoading: false,
    error: undefined,
    assessments: undefined,
};

const initialFetchingState: FetchingState = {
    isFetching: false,
    fetchError: undefined,
    res: undefined,
};

const assessmentsSlice = createSlice({
    name: 'assessments',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAssessments.pending, (state, action) => {
            state.isLoading = true;
            state.error = undefined;
        });
        builder.addCase(getAssessments.fulfilled, (state, action) => {
            state.isLoading = false;
            state.assessments = action.payload.assessments;
        });
        builder.addCase(getAssessments.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.error;
        });
    },
});

const assessmentFetchingSlice = createSlice({
    name: 'assessmentFetching',
    initialState: initialFetchingState,
    reducers: {
        dismissResult: (state) => {
            state.res = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchAssessmentsFromSheet.pending, (state, action) => {
            state.isFetching = true;
            state.fetchError = undefined;
        });
        builder.addCase(
            fetchAssessmentsFromSheet.fulfilled,
            (state, action) => {
                state.isFetching = false;
                state.res = action.payload;
            },
        );
        builder.addCase(fetchAssessmentsFromSheet.rejected, (state, action) => {
            state.isFetching = false;
            state.fetchError = action.error;
        });
    },
});

export const assessmentsReducer = assessmentsSlice.reducer;
export const assessmentFetchingReducer = assessmentFetchingSlice.reducer;

export const assessmentsActions = {
    ...assessmentsSlice.actions,
    getAssessments,
};
export const assessmentFetchingActions = {
    ...assessmentFetchingSlice.actions,
    fetchAssessmentsFromSheet,
};
