import Scrollable from '../common/Scrollable';
import RewardName from './RewardName';
import { useRouteMatch } from 'react-router-dom';
import ErrorBoundary from '../common/ErrorBoundary';
import { Suspense, useState } from 'react';
import LoadingSpinner from '../common/LoadingSpinner';
import { useRecoilValue } from 'recoil';
import { codeIDsV2Query, codeV2Query } from './store';
import { Button } from '../common/Button';
import * as React from 'react';
import Modal from '../common/Modal';
import { Timestamp } from '../api/google/protobuf/timestamp';

type routeParams = { id: string; partnerId: string };

export default function ManageRewardCodesV2() {
    const match = useRouteMatch<routeParams>();

    return (
        <Scrollable>
            <h1 className="text-xl font-bold">
                <RewardName id={match.params['id']} />
            </h1>
            <ErrorBoundary>
                <Suspense fallback={<LoadingSpinner />}>
                    <CodeIDList {...match.params} />
                </Suspense>
            </ErrorBoundary>
        </Scrollable>
    );
}

function CodeIDList(props: routeParams) {
    const [revealCodeFor, setRevealCodeFor] = useState('');
    const codeIDs = useRecoilValue(codeIDsV2Query([props.partnerId, props.id]));
    return (
        <>
            <div className="flex flex-col space-y-2">
                <div>
                    <span className="font-bold">{codeIDs.length}</span>
                    &nbsp;Codes left
                </div>
                {codeIDs.map((id) => (
                    <div
                        key={id}
                        className="flex flex-row justify-start items-center space-x-2"
                    >
                        <code>
                            #{id.substring(0, 4)}...
                            {id.substring(id.length - 4, id.length)}
                        </code>
                        <div>
                            <Button
                                secondary
                                label="Reveal"
                                onClick={() => setRevealCodeFor(id)}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <Modal
                visible={!!revealCodeFor}
                headline="Code"
                onRequestClose={() => setRevealCodeFor('')}
            >
                <ErrorBoundary>
                    <Suspense fallback={<LoadingSpinner />}>
                        {revealCodeFor && (
                            <CodeRevealer {...props} codeID={revealCodeFor} />
                        )}
                    </Suspense>
                </ErrorBoundary>
            </Modal>
        </>
    );
}

function CodeRevealer(props: routeParams & { codeID: string }) {
    const code = useRecoilValue(
        codeV2Query([props.partnerId, props.id, props.codeID]),
    );
    return (
        <div>
            <code>{code.code}</code>
            <br />
            <span>
                Valid until{' '}
                {Timestamp.toDate(code.validUntil!).toLocaleString()}
            </span>
        </div>
    );
}
