import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { Form, Formik, useField } from 'formik';
import {
    NotificationBroadcastMessage,
    NotificationMessageBroadcastState,
} from '../api/model/backoffice';
import { Button } from '../common/Button';
import { ulid } from 'ulid';
import { FormSelectField, FormTextField } from '../common/FormFields';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { notificationActions, notificationSelectors } from './store';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

const prepareNewMessage = (): NotificationBroadcastMessage => ({
    id: ulid(),
    channelId: 'one.plan3t.app.general',
    title: '',
    body: '',
    category: 'general',
    action: {
        label: '',
        navigation: '',
    },
    payload: {},
    timestamp: undefined,
    signedOffBy: [],
    state: NotificationMessageBroadcastState.DRAFT,
});

type Props = {
    id: string | undefined;
};

const CreateBroadcastMessageForm: React.FunctionComponent<Props> = ({ id }) => {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const [defaultEmpty] = useState(prepareNewMessage());

    const current: NotificationBroadcastMessage = useAppSelector((state) =>
        notificationSelectors.getNotificationBroadcast(state, { id: id || '' }),
    );

    const isNew = id === undefined;
    const isViewOnly =
        current !== undefined &&
        current.state !== NotificationMessageBroadcastState.DRAFT;

    useEffect(() => {
        if (!isNew && current === undefined) {
            dispatch(notificationActions.fetchRecentBroadcasts());
        }
    }, [dispatch, isNew, current]);

    const onSubmit = useCallback(
        async (value: NotificationBroadcastMessage) => {
            if (isViewOnly) {
                return;
            }
            await dispatch(
                notificationActions.writeNotificationBroadcastMessage(value),
            );
            history.goBack();
        },
        [history, dispatch, isViewOnly],
    );

    const validate = useCallback((value: NotificationBroadcastMessage) => {
        const errors: any = {};

        if (!value.title) {
            errors.title = 'Required';
        } else if (value.title.length < 2) {
            errors.title = 'Too short';
        } else if (value.title.length > 60) {
            errors.title = 'Too Long';
        }

        if (value.action?.navigation.includes(' ')) {
            errors.action = {
                navigation: 'No whitespace allowed',
            };
        }

        if (!value.body) {
            errors.body = 'Required';
        } else if (value.body.length < 2) {
            errors.body = 'Too short';
        } else if (value.body.length > 120) {
            errors.body = 'Too Long';
        }
        return errors;
    }, []);

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={current || defaultEmpty}
            validate={validate}
            enableReinitialize
        >
            <Form className="px-4 pt-8 space-y-2">
                <div className="flex items-center space-x-2">
                    <div>
                        <Button
                            secondary
                            label={
                                <svg
                                    className="w-4 h-4"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                </svg>
                            }
                            to="/notification"
                        />
                    </div>
                    <h1 className="sm:font-bold lg:text-3xl">
                        {isNew ? 'New' : isViewOnly ? 'View' : 'Edit'}{' '}
                        Notification
                    </h1>
                    {!isViewOnly && (
                        <div className="flex-shrink">
                            <Button type="submit" label="Save" primary />
                        </div>
                    )}
                </div>
                <div
                    className={cx('space-y-2 max-w-lg', {
                        'pointer-events-none opacity-75 cursor-not-allowed':
                            isViewOnly,
                    })}
                >
                    <FormTextField name="title" label="Title" />
                    <FormTextField name="body" label="Body" />
                    <FormSelectField
                        name="channelId"
                        label="Notification Channel ID"
                        options={[
                            {
                                label: 'one.plan3t.app.general',
                                value: 'one.plan3t.app.general',
                            },
                        ]}
                    />
                    <FormSelectField
                        name="category"
                        label="Category"
                        options={[
                            {
                                label: 'General',
                                value: 'general',
                            },
                            {
                                label: 'Challenges',
                                value: 'challenges',
                            },
                            {
                                label: 'Compensate / Projects',
                                value: 'compensate',
                            },
                        ]}
                    />
                    <FormTextField
                        name="action.label"
                        label="Action Button Label"
                    />
                    <FormTextField
                        name="action.navigation"
                        label="Action Deep Link"
                    />
                    <FormSelectField
                        disabled
                        name="state"
                        label="State"
                        options={[
                            {
                                label: 'Unknown',
                                value: NotificationMessageBroadcastState.UNKNOWN,
                            },
                            {
                                label: 'Draft',
                                value: NotificationMessageBroadcastState.DRAFT,
                            },
                            {
                                label: 'Rollout',
                                value: NotificationMessageBroadcastState.ROLLOUT,
                            },
                            {
                                label: 'Sent',
                                value: NotificationMessageBroadcastState.SENT,
                            },
                        ]}
                    />
                    <SignedOffBy />
                </div>
            </Form>
        </Formik>
    );
};

export default CreateBroadcastMessageForm;

const SignedOffBy: FunctionComponent = () => {
    const [field] = useField('signedOffBy');
    const ids = field.value as string[];
    return (
        <div className="flex flex-col space-y-2">
            <span className="text-gray-700">Signed-Off by</span>
            {ids.length > 0 && (
                <ul>
                    {ids.map((id) => (
                        <li key={id}>{id}</li>
                    ))}
                </ul>
            )}
            {ids.length === 0 && (
                <span className="text-gray-500">not yet signed off</span>
            )}
        </div>
    );
};
