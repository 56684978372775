import { atom, GetRecoilValue, selectorFamily, useRecoilState } from 'recoil';
import { useCallback } from 'react';

const _refresherAtom = atom<Record<string, number>>({
    key: 'common/_refresherAtom',
    default: {},
});

const _refresherSingleTag = selectorFamily({
    key: 'common/_refresherSingleTag',
    get:
        (key: string) =>
        ({ get }) =>
            get(_refresherAtom)[key],
});

export function makeRecoilValueShallowRefreshable(args: {
    get: GetRecoilValue;
    tag: string;
}) {
    args.get(_refresherSingleTag(args.tag));
}

export function useShallowRecoilRefresher(tag: string) {
    const [, s] = useRecoilState(_refresherAtom);
    return useCallback(() => {
        s((c) => ({
            ...c,
            [tag]: Date.now(),
        }));
    }, [s, tag]);
}
