import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreateVoucherRequest } from '../api/model/backoffice';
import axios from 'axios';
import { AppState } from '../redux/AppStore';
import { ulid } from 'ulid';

const createVoucher = createAsyncThunk(
    'voucher/create',
    async (args: CreateVoucherRequest) => {
        try {
            await axios.post<Object>(`/voucher`, args);
        } catch (e: any) {
            if (e.isAxiosError && e.response.status === 409) {
                throw Error(
                    'This voucher code is already in use, please choose or generate another one',
                );
            }
            throw e;
        }
    },
);

const createNewVoucherRequest = (): CreateVoucherRequest => ({
    id: ulid(),
    code: '',
    value: 50,
    comment: '',
});

type State = {
    isWriting: boolean;
    writeError: string | undefined;
    draft: CreateVoucherRequest;

    createdPreview: CreateVoucherRequest | undefined;
};

const initialState: State = {
    isWriting: false,
    writeError: undefined,
    draft: createNewVoucherRequest(),
    createdPreview: undefined,
};

const voucherSlice = createSlice({
    name: 'voucher',
    initialState,
    reducers: {
        clearCreatedPreview(state) {
            state.createdPreview = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createVoucher.pending, (state) => {
            state.isWriting = true;
            state.writeError = undefined;
            state.createdPreview = undefined;
        });
        builder.addCase(createVoucher.fulfilled, (state, action) => {
            state.isWriting = false;
            state.writeError = undefined;
            state.draft = createNewVoucherRequest();
            state.createdPreview = action.meta.arg;
        });
        builder.addCase(createVoucher.rejected, (state, action) => {
            state.isWriting = false;
            state.writeError = action.error.message;
            state.createdPreview = undefined;
        });
    },
});

export const voucherReducer = voucherSlice.reducer;
export const voucherActions = {
    ...voucherSlice.actions,
    createVoucher,
};

export const voucherSelectors = {
    getWriteError: (state: AppState) => state.voucher.writeError,
    isWriting: (state: AppState) => state.voucher.isWriting,
    getDraft: (state: AppState) => state.voucher.draft,
    getCreatedPreview: (state: AppState) => state.voucher.createdPreview,
};
