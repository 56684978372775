import { useAppDispatch, useAppSelector } from '../redux/react';
import { rewardActions, rewardSelectors } from './store';
import { useEffect } from 'react';

type Props = {
    id: string;
};

export default function RewardName(props: Props) {
    const reward = useAppSelector((s) =>
        rewardSelectors.getReward(s, { id: props.id }),
    );

    const hasLoaded = useAppSelector((s) => s.reward.rewardIds.length > 0);

    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!hasLoaded) {
            dispatch(rewardActions.fetchRewards());
        }
    }, [dispatch, hasLoaded]);

    return <>{reward?.title || props.id}</>;
}
