import LoadingSpinner from '../common/LoadingSpinner';
import React, { Suspense, useMemo, useState } from 'react';
import {
    Bar,
    Breadcrumbs,
    BreadcrumbsItem,
    Button,
    ButtonDesign,
    Dialog,
    DynamicPage,
    DynamicPageTitle,
    FlexBox,
    Label,
    ListMode,
    MessageStrip,
    MessageStripDesign,
    Table,
    TableCell,
    TableColumn,
    TableGroupRow,
    TableRow,
    Title,
    Tree,
    TreeItem,
} from '@ui5/webcomponents-react';
import {
    atom,
    selector,
    useRecoilCallback,
    useRecoilValue,
    useRecoilValueLoadable,
} from 'recoil';
import axios from 'axios';
import * as Categorizer from '../api/classifier-services/categorizer/rpc/categorizer';
import PartnerName from '../partner/PartnerName';
import { usePartnerProfileList } from '../partner/store';

export default function CategorizerModule() {
    const partners = usePartnerProfileList();
    const mapping = useRecoilValueLoadable(partnerCategoryMappingQuery);

    const unassignedPartners = useMemo(() => {
        if (mapping.state !== 'hasValue') {
            return [];
        }
        const partnerIDs = new Set(partners.map((p) => p.id));

        for (let id of Object.keys(mapping.contents)) {
            partnerIDs.delete(id);
        }

        return Array.from(partnerIDs);
    }, [partners, mapping]);

    return (
        <Suspense fallback={<LoadingSpinner />}>
            <DynamicPage
                headerTitle={
                    <DynamicPageTitle
                        breadcrumbs={
                            <Breadcrumbs>
                                <BreadcrumbsItem>Categorizer</BreadcrumbsItem>
                            </Breadcrumbs>
                        }
                        header={<Title>Categorizer</Title>}
                    />
                }
            >
                {mapping.state === 'hasError' && (
                    <MessageStrip
                        hideCloseButton
                        design={MessageStripDesign.Negative}
                    >
                        {mapping.errorMaybe().toString()}
                    </MessageStrip>
                )}
                <Table
                    busy={mapping.state === 'loading'}
                    columns={
                        <>
                            <TableColumn>
                                <Label>Partner</Label>
                            </TableColumn>
                            <TableColumn>
                                <Label>Category</Label>
                            </TableColumn>
                        </>
                    }
                >
                    {unassignedPartners.length > 0 && (
                        <TableGroupRow title="Unassigned">
                            Unassigned
                        </TableGroupRow>
                    )}
                    {unassignedPartners.map((partnerId) => (
                        <Row key={partnerId} partnerId={partnerId} />
                    ))}
                    {unassignedPartners.length > 0 &&
                        mapping.state === 'hasValue' && (
                            <TableGroupRow title="Assigned">
                                Assigned
                            </TableGroupRow>
                        )}
                    {mapping.state === 'hasValue' &&
                        Object.keys(mapping.contents).map((partnerId) => (
                            <Row key={partnerId} partnerId={partnerId} />
                        ))}
                </Table>
            </DynamicPage>
        </Suspense>
    );
}

function Row(props: { slot?: string; partnerId: string }) {
    return (
        <TableRow slot={props.slot}>
            <TableCell>
                <Label>
                    <Suspense fallback={<LoadingSpinner />}>
                        <PartnerName id={props.partnerId} />
                    </Suspense>
                </Label>
            </TableCell>
            <TableCell>
                <FlexBox>
                    <Suspense fallback={<LoadingSpinner />}>
                        <CategoryChooser partnerId={props.partnerId} />
                    </Suspense>
                </FlexBox>
            </TableCell>
        </TableRow>
    );
}

function CategoryChooser(props: { slot?: string; partnerId: string }) {
    const categoryId = useRecoilValue(partnerCategoryMappingQuery)[
        props.partnerId
    ];

    const categories = useRecoilValue(categoriesQuery);
    const tree = useRecoilValue(categoryTreeQuery);

    const activeCategory = categories.find((c) => c.id === categoryId);

    const [open, setOpen] = useState(false);

    const [pendingCategoryID, setPendingCategoryID] = useState<string | null>(
        activeCategory?.id ?? null,
    );

    const [save, working] = useUpdateMappingFunction();

    function render(node: TreeNode) {
        return (
            <TreeItem
                key={node.id}
                text={node.category?.label}
                selected={node.id === pendingCategoryID}
                data-id={node.id}
            >
                {Object.values(node.children).map(render)}
            </TreeItem>
        );
    }

    return (
        <>
            <Button slot={props.slot} onClick={() => setOpen(true)}>
                {activeCategory?.label || 'Unassigned'}
            </Button>
            <Dialog
                headerText="Select category"
                open={open}
                onAfterClose={() => setOpen(false)}
                footer={
                    <Bar
                        endContent={
                            pendingCategoryID &&
                            pendingCategoryID !== activeCategory?.id ? (
                                <Button
                                    onClick={async () => {
                                        await save(
                                            props.partnerId,
                                            pendingCategoryID,
                                        );
                                        setOpen(false);
                                    }}
                                    design={ButtonDesign.Emphasized}
                                >
                                    {working ? <LoadingSpinner /> : <>Save</>}
                                </Button>
                            ) : (
                                <Button>-</Button>
                            )
                        }
                        startContent={
                            <Button
                                onClick={() => {
                                    setPendingCategoryID(null);
                                    setOpen(false);
                                }}
                            >
                                Close
                            </Button>
                        }
                    />
                }
            >
                <div className="h-full">
                    <Tree
                        mode={ListMode.SingleSelect}
                        onSelectionChange={(e) => {
                            if (e.detail.selectedItems.length !== 1) {
                                setPendingCategoryID(null);
                                return;
                            }
                            const id =
                                e.detail.selectedItems.map(
                                    (e) => (e as HTMLElement).dataset['id'],
                                )[0] ?? null;

                            const cat = categories.find((c) => c.id === id);
                            if (!cat || cat.path.length < 2) {
                                return;
                            }

                            setPendingCategoryID(id);
                        }}
                    >
                        {Object.values(tree.children).map(render)}
                    </Tree>
                </div>
            </Dialog>
        </>
    );
}

const categoriesQuery = selector({
    key: 'categorizer/categoriesQuery',
    get: async () => {
        const res = await axios.get(`/categorizer/categories`);

        return Categorizer.GetCategoriesResponse.fromJson(res.data).categories;
    },
});

type TreeNode = {
    id: string;
    children: Record<string, TreeNode>;
    category?: Categorizer.GetCategoriesResponse_Category;
};

const categoryTreeQuery = selector({
    key: 'categorizer/categoryTreeQuery',
    get: async ({ get }) => {
        const categories = get(categoriesQuery);
        const roots = categories.filter((c) => c.path.length === 1);
        const leafs = categories.filter((c) => c.path.length === 2);

        const tree: TreeNode = { id: '', children: {} };

        for (let c of roots) {
            tree.children[c.id] = {
                id: c.id,
                children: {},
                category: c,
            };
        }
        for (let c of leafs) {
            tree.children[c.path[0]].children[c.id] = {
                id: c.id,
                children: {},
                category: c,
            };
        }

        return tree;
    },
});

const _partnerCategoryMappingQuery = selector({
    key: 'categorizer/_partnerCategoryMappingQuery',
    get: async () => {
        const res = await axios.get(`/categorizer/partnerMapping`);

        return Categorizer.GetPartnerMappingResponse.fromJson(res.data)
            .partnerCategoryMap;
    },
});

const _partnerCategoryMappingOptimisticUpdatesAtom = atom<
    Record<string, string>
>({
    key: 'categorizer/_partnerCategoryMappingOptimisticUpdatesAtom',
    default: {},
});

const partnerCategoryMappingQuery = selector({
    key: 'categorizer/partnerCategoryMappingQuery',
    get: ({ get }) => {
        return {
            ...get(_partnerCategoryMappingQuery),
            ...get(_partnerCategoryMappingOptimisticUpdatesAtom),
        };
    },
});

function useUpdateMappingFunction() {
    const [working, setWorking] = useState(false);
    const fn = useRecoilCallback(
        ({ set }) =>
            async (partnerId: string, categoryId: string) => {
                setWorking(true);
                try {
                    const req = Categorizer.WritePartnerMappingRequest.toJson({
                        partnerId,
                        categoryId,
                    });
                    await axios.post(`/categorizer/partnerMapping`, req);
                    set(
                        _partnerCategoryMappingOptimisticUpdatesAtom,
                        (curr) => ({
                            ...curr,
                            [partnerId]: categoryId,
                        }),
                    );
                } finally {
                    setWorking(false);
                }
            },
        [setWorking],
    );

    return [fn, working] as const;
}
