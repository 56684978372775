import axios from 'axios';
import { selector, useRecoilCallback, useRecoilValue } from 'recoil';
import * as Accounting from '../api/accounting/rpc/accounting';
import {
    Bar,
    BarDesign,
    BusyIndicator,
    Button,
    ButtonDesign,
    CheckBox,
    Dialog,
    DynamicPage,
    DynamicPageHeader,
    DynamicPageTitle,
    FlexBox,
    FlexBoxDirection,
    Label,
    Table,
    TableCell,
    TableColumn,
    TableRow,
    Title,
} from '@ui5/webcomponents-react';
import React, { useEffect, useState } from 'react';
import { Timestamp } from '../api/google/protobuf/timestamp';
import { trialBalanceQuery } from '../ledger/store';
import LoadingSpinner from '../common/LoadingSpinner';
import { CommandType } from '../api/model/internal';

export default function SplitBalanceQueue() {
    const queueSize = useRecoilValue(splitBalanceQueueSizeQuery);
    const { working, perform } = usePerformSplitBalanceCalculations();

    return (
        <DynamicPage
            headerTitle={
                <DynamicPageTitle
                    header={<Title>Split Balance Queue</Title>}
                    actions={
                        <>
                            <Button onClick={perform}>
                                {working ? (
                                    <LoadingSpinner />
                                ) : (
                                    <>Process Split Balance Queue</>
                                )}
                            </Button>
                        </>
                    }
                />
            }
            headerContent={
                <DynamicPageHeader>
                    <FlexBox direction={FlexBoxDirection.Column}>
                        <Label>Queue Size</Label>
                        <Label>{queueSize}</Label>
                    </FlexBox>
                </DynamicPageHeader>
            }
        >
            <CommandList />
        </DynamicPage>
    );
}

function CommandList() {
    const commands = useRecoilValue(commandsWithMissingSBCCQuery);
    const [showDialogFor, setShowDialogFor] = useState<string | undefined>();
    return (
        <Table
            columns={
                <>
                    <TableColumn>
                        <Label>ID</Label>
                    </TableColumn>
                    <TableColumn>
                        <Label>Type</Label>
                    </TableColumn>
                    <TableColumn>
                        <Label>Payload</Label>
                    </TableColumn>
                    <TableColumn>
                        <Label>Created At</Label>
                    </TableColumn>
                    <TableColumn>
                        <Label>Actions</Label>
                    </TableColumn>
                </>
            }
        >
            {commands.map((cmd) => (
                <TableRow key={cmd.id}>
                    <TableCell>
                        <code>{cmd.id}</code>
                    </TableCell>
                    <TableCell>
                        {CommandType[cmd.commandType]
                            .toString()
                            .slice('CommandType'.length)}
                    </TableCell>
                    <TableCell>
                        <code>{new TextDecoder().decode(cmd.payload)}</code>
                    </TableCell>
                    <TableCell>
                        {cmd.createdAt &&
                            Timestamp.toDate(cmd.createdAt).toISOString()}
                    </TableCell>
                    <TableCell>
                        <>
                            <Button
                                icon="compare"
                                onClick={() => setShowDialogFor(cmd.id)}
                            />
                            <SBCCCreationDialog
                                id={cmd.id}
                                open={showDialogFor === cmd.id}
                                onClose={() => setShowDialogFor(undefined)}
                            />
                        </>
                    </TableCell>
                </TableRow>
            ))}
        </Table>
    );
}

function SBCCCreationDialog(props: {
    id: string;
    open: boolean;
    onClose: () => void;
}) {
    const [state, setState] = useState(
        Accounting.CreateSBCCRequest.create({
            commandId: props.id,
            locked: true,
            mintNonVirtual: false,
        }),
    );
    useEffect(() => {
        if (state.commandId !== props.id) {
            setState((v) => ({
                ...v,
                commandId: props.id,
            }));
        }
    }, [state.commandId, props.id]);

    const [working, setWorking] = useState(false);
    const onSave = useRecoilCallback(
        ({ refresh }) =>
            async () => {
                setWorking(true);
                try {
                    await axios.post(
                        '/one.plan3t.core.accounting.rpc.Accounting/CreateSBCC',
                        Accounting.CreateSBCCRequest.toJson(state),
                    );
                    props.onClose();
                    refresh(missingSBCCsResponseQuery);
                } finally {
                    setWorking(false);
                }
            },
        [state, setWorking, props.onClose],
    );

    return (
        <Dialog
            open={props.open}
            onAfterClose={props.onClose}
            headerText="SBCC"
            footer={
                <Bar
                    design={BarDesign.Footer}
                    endContent={
                        <FlexBox>
                            <Button
                                icon="save"
                                onClick={working ? undefined : onSave}
                                design={ButtonDesign.Emphasized}
                            >
                                {working ? <BusyIndicator /> : 'Save'}
                            </Button>
                            <Button
                                design={ButtonDesign.Default}
                                onClick={props.onClose}
                            >
                                Cancel
                            </Button>
                        </FlexBox>
                    }
                />
            }
        >
            <FlexBox direction={FlexBoxDirection.Column}>
                <CheckBox
                    text="Mint Non Virtual"
                    checked={state.mintNonVirtual}
                    onChange={(evt) =>
                        setState((v) => ({
                            ...v,
                            mintNonVirtual: evt.target.checked ?? false,
                        }))
                    }
                />
                <CheckBox text="Locked" checked={state.locked} disabled />
            </FlexBox>
        </Dialog>
    );
}

const missingSBCCsResponseQuery = selector({
    key: 'accounting/missingSBCCsResponseQuery',
    get: async () => {
        const res = await axios.post(
            '/one.plan3t.core.accounting.rpc.Accounting/GetMissingSBCCs',
            Accounting.GetMissingSBCCsRequest.create({}),
        );
        return Accounting.GetMissingSBCCsResponse.fromJson(res.data);
    },
});

const splitBalanceQueueSizeQuery = selector({
    key: 'accounting/splitBalanceQueueSizeQuery',
    get: ({ get }) => get(missingSBCCsResponseQuery).blockedQueueSize,
});

const commandsWithMissingSBCCQuery = selector({
    key: 'accounting/commandsWithMissingSBCCQuery',
    get: ({ get }) => get(missingSBCCsResponseQuery).commands,
});

export function usePerformSplitBalanceCalculations() {
    const [working, setWorking] = useState(false);

    const fn = useRecoilCallback(
        ({ refresh }) =>
            async () => {
                setWorking(true);
                try {
                    await axios.post(
                        '/one.plan3t.core.accounting.rpc.Accounting/PerformSplitBalanceCalculations',
                        { max_transactions: 1000 },
                    );
                    refresh(trialBalanceQuery);
                } finally {
                    setWorking(false);
                }
            },
        [setWorking],
    );

    return { working, perform: working ? undefined : fn };
}
