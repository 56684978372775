import { useAppDispatch, useAppSelector } from '../redux/react';
import { userActions, userSelectors } from '../user/store';
import { useEffect } from 'react';
import CopyToClipboardButton from '../common/CopyToClipboardButton';

export default function LotteryUserAccountDisplay(props: { uid: string }) {
    const { uid } = props;
    const account = useAppSelector((state) =>
        userSelectors.getById(state, { id: uid }),
    );

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!account) {
            dispatch(userActions.fetchUserAccount([uid]));
        }
    }, [account, dispatch, uid]);

    if (!account) {
        return <span>No Account Information found!</span>;
    }

    return (
        <div className="flex flex-row space-x-4 items-center">
            <img
                src={account.photoUrl}
                alt="profile"
                className="w-16 h-16 rounded-full"
            />
            <div className="flex flex-col -space-y-2">
                <div className="text-2xl font-bold">{account.name}</div>
                <div className="flex items-center space-x-1">
                    {account.email}
                    <CopyToClipboardButton value={account.email} />
                </div>
            </div>
        </div>
    );
}
