import { atom, selector, selectorFamily } from 'recoil';
import * as Ledger from '../api/ledger/rpc/ledger';
import axios from 'axios';

const ledgerIDAtom = atom({
    key: 'ledger/ledgerIDAtom',
    default: 'plan3t/main',
});

export const trialBalanceQuery = selector({
    key: 'ledger/trialBalanceQuery',
    get: async ({ get }) => {
        const now = new Date();
        const req = Ledger.GetTrialBalanceRequest.create({
            asOfDate: {
                year: now.getFullYear(),
                month: now.getMonth() + 1,
                day: now.getDate() + 1,
            },
            ledgerId: get(ledgerIDAtom),
        });

        const res = await axios.post(
            '/one.plan3t.core.ledger.rpc.Ledger/GetTrialBalance',
            req,
        );
        return Ledger.GetTrialBalanceResponse.fromJson(res.data);
    },
});

export const availableAccounts = selector({
    key: 'ledger/availableAccounts',
    get: ({ get }) => get(trialBalanceQuery).accounts,
});

export const accountQuery = selectorFamily({
    key: 'ledger/accountQuery',
    get:
        (accountID: string) =>
        ({ get }) => {
            return get(trialBalanceQuery).accounts.find(
                (acc) => acc.id === accountID,
            );
        },
});

export const transactionListStateAtom = atom<{
    pageToken: string;
    pageSize: number;
    filter: Ledger.GetTransactionsRequest_Filter;
}>({
    key: 'ledger/transactionListStateAtom',
    default: selector({
        key: 'ledger/transactionListStateAtom/default',
        get: ({ get }) => ({
            pageToken: '',
            pageSize: 100,
            filter: {
                ledgerId: get(ledgerIDAtom),
                states: [
                    Ledger.TransactionState.PENDING,
                    Ledger.TransactionState.POSTED,
                ],
                after: undefined,
                before: undefined,
            },
        }),
    }),
});

export const transactionListQuery = selector({
    key: 'ledger/transactionsQuery',
    get: async ({ get }) => {
        const state = get(transactionListStateAtom);

        const req = Ledger.GetTransactionsRequest.create(state);

        const res = await axios.post(
            '/one.plan3t.core.ledger.rpc.Ledger/GetTransactions',
            req,
        );
        return Ledger.GetTransactionsResponse.fromJson(res.data);
    },
});
