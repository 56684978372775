// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "model/internal.proto" (package "one.plan3t.core.model", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Date } from "./common";
import { Plan3TPoint } from "./core";
import { Any } from "../google/protobuf/any";
import { Plan3tUserTransaction } from "./openbanking";
import { Timestamp } from "../google/protobuf/timestamp";
import { NotificationAction } from "./core";
/**
 * @generated from protobuf message one.plan3t.core.model.NotificationMessageAuthoring
 */
export interface NotificationMessageAuthoring {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string user_id = 2;
     */
    userId: string;
    /**
     * @generated from protobuf field: string channelId = 3;
     */
    channelId: string;
    /**
     * @generated from protobuf field: string title = 4;
     */
    title: string;
    /**
     * @generated from protobuf field: string body = 5;
     */
    body: string;
    /**
     * @generated from protobuf field: string category = 6;
     */
    category: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.NotificationAction action = 7;
     */
    action?: NotificationAction;
    /**
     * @generated from protobuf field: map<string, string> payload = 8;
     */
    payload: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timestamp = 9;
     */
    timestamp?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp seenAt = 10;
     */
    seenAt?: Timestamp;
    /**
     * @generated from protobuf field: string broadcast_message_id = 11;
     */
    broadcastMessageId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.SendNotificationsRequest
 */
export interface SendNotificationsRequest {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.NotificationMessageAuthoring messages = 1;
     */
    messages: NotificationMessageAuthoring[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.SendNotificationsResponse
 */
export interface SendNotificationsResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.ProcessOpenBankingPubSubTransactionRequest
 */
export interface ProcessOpenBankingPubSubTransactionRequest {
    /**
     * @generated from protobuf field: string transaction_id = 1;
     */
    transactionId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ProcessOpenBankingPubSubTransactionResponse
 */
export interface ProcessOpenBankingPubSubTransactionResponse {
}
/**
 * those two messages can be used by classifier services, which need to receive pub/sub messages
 * for every new open banking transaction in the storage bucket
 *
 * @generated from protobuf message one.plan3t.core.model.ProcessOpenBankingTransactionRequest
 */
export interface ProcessOpenBankingTransactionRequest {
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3tUserTransaction transaction = 1;
     */
    transaction?: Plan3tUserTransaction;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ProcessOpenBankingTransactionResponse
 */
export interface ProcessOpenBankingTransactionResponse {
}
/**
 * used to trigger reclassification of transaction in the storage
 *
 * @generated from protobuf message one.plan3t.core.model.ProcessOpenBankingTransactionStorageRequest
 */
export interface ProcessOpenBankingTransactionStorageRequest {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp since = 1;
     */
    since?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ProcessOpenBankingTransactionStorageResponse
 */
export interface ProcessOpenBankingTransactionStorageResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.AccountingCommand
 */
export interface AccountingCommand {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.CommandType command_type = 2;
     */
    commandType: CommandType;
    /**
     * @generated from protobuf field: string user_id = 3;
     */
    userId: string;
    /**
     * @generated from protobuf field: string partner_id = 4;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 5;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: bytes payload = 6;
     */
    payload: Uint8Array;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ApplyAccountingCommandRequest
 */
export interface ApplyAccountingCommandRequest {
    /**
     * @generated from protobuf field: one.plan3t.core.model.AccountingCommand command = 1;
     */
    command?: AccountingCommand;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ApplyAccountingCommandResponse
 */
export interface ApplyAccountingCommandResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.SendCashbackNotificationsRequest
 */
export interface SendCashbackNotificationsRequest {
    /**
     * @generated from protobuf field: string cronHour = 1;
     */
    cronHour: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.SendCashbackNotificationsResponse
 */
export interface SendCashbackNotificationsResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.ReclassifyTransactionRequest
 */
export interface ReclassifyTransactionRequest {
    /**
     * @generated from protobuf field: string transaction_id = 1;
     */
    transactionId: string;
    /**
     * @generated from protobuf field: string current_category_id = 2;
     */
    currentCategoryId: string;
    /**
     * @generated from protobuf field: string new_category_id = 3;
     */
    newCategoryId: string;
    /**
     * modifier_choices maps modifier_id to the underlying data message
     *
     * @generated from protobuf field: map<string, google.protobuf.Any> modifier_choices = 4;
     */
    modifierChoices: {
        [key: string]: Any;
    };
}
/**
 * @generated from protobuf message one.plan3t.core.model.ReclassifyTransactionResponse
 */
export interface ReclassifyTransactionResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetAccountingTransfersRequest
 */
export interface GetAccountingTransfersRequest {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp from = 1;
     */
    from?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp to = 2;
     */
    to?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetAccountingTransfersResponse
 */
export interface GetAccountingTransfersResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.GetAccountingTransfersResponse.Transfer transfers = 1;
     */
    transfers: GetAccountingTransfersResponse_Transfer[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetAccountingTransfersResponse.Address
 */
export interface GetAccountingTransfersResponse_Address {
    /**
     * @generated from protobuf field: string address = 1;
     */
    address: string;
    /**
     * @generated from protobuf field: string address_type = 2;
     */
    addressType: string;
    /**
     * @generated from protobuf field: bool can_mint_or_burn = 3;
     */
    canMintOrBurn: boolean;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetAccountingTransfersResponse.Transfer
 */
export interface GetAccountingTransfersResponse_Transfer {
    /**
     * @generated from protobuf field: string transaction_id = 1;
     */
    transactionId: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.CommandType command_type = 2;
     */
    commandType: CommandType;
    /**
     * @generated from protobuf field: one.plan3t.core.model.GetAccountingTransfersResponse.Address from = 4;
     */
    from?: GetAccountingTransfersResponse_Address;
    /**
     * @generated from protobuf field: one.plan3t.core.model.GetAccountingTransfersResponse.Address to = 5;
     */
    to?: GetAccountingTransfersResponse_Address;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint amount = 6;
     */
    amount?: Plan3TPoint;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timestamp = 7;
     */
    timestamp?: Timestamp; // timestamp of the actual transfer
    /**
     * @generated from protobuf field: google.protobuf.Timestamp reference_timestamp = 8;
     */
    referenceTimestamp?: Timestamp; // the timestamp for billing purposes, used for deferred transactions, which are booking stuff, like reward billing.
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetCashbackStatsForUserRequest
 */
export interface GetCashbackStatsForUserRequest {
    /**
     * @generated from protobuf field: one.plan3t.core.model.Date reference = 1;
     */
    reference?: Date;
    /**
     * @generated from protobuf field: string user_id = 2;
     */
    userId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetCashbackStatsForUserResponse
 */
export interface GetCashbackStatsForUserResponse {
    /**
     * @generated from protobuf field: int32 number_of_transactions = 1;
     */
    numberOfTransactions: number;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint cashback_amount = 2;
     */
    cashbackAmount?: Plan3TPoint;
}
/**
 * @generated from protobuf message one.plan3t.core.model.SendOpenBankingConnectionStatusNotificationsRequest
 */
export interface SendOpenBankingConnectionStatusNotificationsRequest {
    /**
     * @generated from protobuf field: bool dry_run = 1;
     */
    dryRun: boolean;
}
/**
 * @generated from protobuf message one.plan3t.core.model.SendOpenBankingConnectionStatusNotificationsResponse
 */
export interface SendOpenBankingConnectionStatusNotificationsResponse {
}
/**
 * @generated from protobuf enum one.plan3t.core.model.CommandType
 */
export enum CommandType {
    /**
     * @generated from protobuf enum value: CommandTypeUnknown = 0;
     */
    CommandTypeUnknown = 0,
    /**
     * @generated from protobuf enum value: CommandTypeCreateRewardCode = 1;
     */
    CommandTypeCreateRewardCode = 1,
    /**
     * @generated from protobuf enum value: CommandTypeGuideCompleted = 2;
     */
    CommandTypeGuideCompleted = 2,
    /**
     * @generated from protobuf enum value: CommandTypeRewardBought = 3;
     */
    CommandTypeRewardBought = 3,
    /**
     * @generated from protobuf enum value: CommandTypeCompensatePurchased = 4;
     */
    CommandTypeCompensatePurchased = 4,
    /**
     * @generated from protobuf enum value: CommandTypeProfilingCompleted = 5;
     */
    CommandTypeProfilingCompleted = 5,
    /**
     * @generated from protobuf enum value: CommandTypeDeleteRewardCode = 6;
     */
    CommandTypeDeleteRewardCode = 6,
    /**
     * @generated from protobuf enum value: CommandTypeAdjustRewardPrice = 7;
     */
    CommandTypeAdjustRewardPrice = 7,
    /**
     * @generated from protobuf enum value: CommandTypeChallengeCompleted = 8;
     */
    CommandTypeChallengeCompleted = 8,
    /**
     * @generated from protobuf enum value: CommandTypeReferralBonusClaimed = 9;
     */
    CommandTypeReferralBonusClaimed = 9,
    /**
     * @generated from protobuf enum value: CommandTypeVoucherRedeemed = 10;
     */
    CommandTypeVoucherRedeemed = 10,
    /**
     * @generated from protobuf enum value: CommandTypeLotteryTicketBought = 11;
     */
    CommandTypeLotteryTicketBought = 11,
    /**
     * @generated from protobuf enum value: CommandTypeTipQuizCompleted = 12;
     */
    CommandTypeTipQuizCompleted = 12,
    /**
     * @generated from protobuf enum value: CommandTypeCompensateWheelSpun = 13;
     */
    CommandTypeCompensateWheelSpun = 13,
    /**
     * @generated from protobuf enum value: CommandTypePartnerPurchaseCashback = 14;
     */
    CommandTypePartnerPurchaseCashback = 14,
    /**
     * @generated from protobuf enum value: CommandTypeOpenBankingWelcomeBonus = 15;
     */
    CommandTypeOpenBankingWelcomeBonus = 15,
    /**
     * @generated from protobuf enum value: CommandTypeDonatedToTarget = 16;
     */
    CommandTypeDonatedToTarget = 16,
    /**
     * @generated from protobuf enum value: CommandTypeDeleteAccount = 17;
     */
    CommandTypeDeleteAccount = 17,
    /**
     * @generated from protobuf enum value: CommandTypeAdjustRewardCodeValidUntil = 18;
     */
    CommandTypeAdjustRewardCodeValidUntil = 18,
    /**
     * @generated from protobuf enum value: CommandTypeRevokeConversionToCoins = 19;
     */
    CommandTypeRevokeConversionToCoins = 19,
    /**
     * @generated from protobuf enum value: CommandTypeMarkRewardCodeAsUsed = 20;
     */
    CommandTypeMarkRewardCodeAsUsed = 20,
    /**
     * @generated from protobuf enum value: CommandTypeAccountTransferred = 21;
     */
    CommandTypeAccountTransferred = 21
}
// @generated message type with reflection information, may provide speed optimized methods
class NotificationMessageAuthoring$Type extends MessageType<NotificationMessageAuthoring> {
    constructor() {
        super("one.plan3t.core.model.NotificationMessageAuthoring", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "channelId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "body", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "action", kind: "message", T: () => NotificationAction },
            { no: 8, name: "payload", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 9, name: "timestamp", kind: "message", T: () => Timestamp },
            { no: 10, name: "seenAt", kind: "message", T: () => Timestamp },
            { no: 11, name: "broadcast_message_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<NotificationMessageAuthoring>): NotificationMessageAuthoring {
        const message = { id: "", userId: "", channelId: "", title: "", body: "", category: "", payload: {}, broadcastMessageId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NotificationMessageAuthoring>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NotificationMessageAuthoring): NotificationMessageAuthoring {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string user_id */ 2:
                    message.userId = reader.string();
                    break;
                case /* string channelId */ 3:
                    message.channelId = reader.string();
                    break;
                case /* string title */ 4:
                    message.title = reader.string();
                    break;
                case /* string body */ 5:
                    message.body = reader.string();
                    break;
                case /* string category */ 6:
                    message.category = reader.string();
                    break;
                case /* one.plan3t.core.model.NotificationAction action */ 7:
                    message.action = NotificationAction.internalBinaryRead(reader, reader.uint32(), options, message.action);
                    break;
                case /* map<string, string> payload */ 8:
                    this.binaryReadMap8(message.payload, reader, options);
                    break;
                case /* google.protobuf.Timestamp timestamp */ 9:
                    message.timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timestamp);
                    break;
                case /* google.protobuf.Timestamp seenAt */ 10:
                    message.seenAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.seenAt);
                    break;
                case /* string broadcast_message_id */ 11:
                    message.broadcastMessageId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap8(map: NotificationMessageAuthoring["payload"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof NotificationMessageAuthoring["payload"] | undefined, val: NotificationMessageAuthoring["payload"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.model.NotificationMessageAuthoring.payload");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: NotificationMessageAuthoring, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string user_id = 2; */
        if (message.userId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        /* string channelId = 3; */
        if (message.channelId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.channelId);
        /* string title = 4; */
        if (message.title !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.title);
        /* string body = 5; */
        if (message.body !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.body);
        /* string category = 6; */
        if (message.category !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.category);
        /* one.plan3t.core.model.NotificationAction action = 7; */
        if (message.action)
            NotificationAction.internalBinaryWrite(message.action, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* map<string, string> payload = 8; */
        for (let k of Object.keys(message.payload))
            writer.tag(8, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.payload[k]).join();
        /* google.protobuf.Timestamp timestamp = 9; */
        if (message.timestamp)
            Timestamp.internalBinaryWrite(message.timestamp, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp seenAt = 10; */
        if (message.seenAt)
            Timestamp.internalBinaryWrite(message.seenAt, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* string broadcast_message_id = 11; */
        if (message.broadcastMessageId !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.broadcastMessageId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.NotificationMessageAuthoring
 */
export const NotificationMessageAuthoring = new NotificationMessageAuthoring$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendNotificationsRequest$Type extends MessageType<SendNotificationsRequest> {
    constructor() {
        super("one.plan3t.core.model.SendNotificationsRequest", [
            { no: 1, name: "messages", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NotificationMessageAuthoring }
        ]);
    }
    create(value?: PartialMessage<SendNotificationsRequest>): SendNotificationsRequest {
        const message = { messages: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SendNotificationsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendNotificationsRequest): SendNotificationsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.NotificationMessageAuthoring messages */ 1:
                    message.messages.push(NotificationMessageAuthoring.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendNotificationsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.NotificationMessageAuthoring messages = 1; */
        for (let i = 0; i < message.messages.length; i++)
            NotificationMessageAuthoring.internalBinaryWrite(message.messages[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SendNotificationsRequest
 */
export const SendNotificationsRequest = new SendNotificationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendNotificationsResponse$Type extends MessageType<SendNotificationsResponse> {
    constructor() {
        super("one.plan3t.core.model.SendNotificationsResponse", []);
    }
    create(value?: PartialMessage<SendNotificationsResponse>): SendNotificationsResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SendNotificationsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendNotificationsResponse): SendNotificationsResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SendNotificationsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SendNotificationsResponse
 */
export const SendNotificationsResponse = new SendNotificationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessOpenBankingPubSubTransactionRequest$Type extends MessageType<ProcessOpenBankingPubSubTransactionRequest> {
    constructor() {
        super("one.plan3t.core.model.ProcessOpenBankingPubSubTransactionRequest", [
            { no: 1, name: "transaction_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ProcessOpenBankingPubSubTransactionRequest>): ProcessOpenBankingPubSubTransactionRequest {
        const message = { transactionId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProcessOpenBankingPubSubTransactionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessOpenBankingPubSubTransactionRequest): ProcessOpenBankingPubSubTransactionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string transaction_id */ 1:
                    message.transactionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessOpenBankingPubSubTransactionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string transaction_id = 1; */
        if (message.transactionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.transactionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ProcessOpenBankingPubSubTransactionRequest
 */
export const ProcessOpenBankingPubSubTransactionRequest = new ProcessOpenBankingPubSubTransactionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessOpenBankingPubSubTransactionResponse$Type extends MessageType<ProcessOpenBankingPubSubTransactionResponse> {
    constructor() {
        super("one.plan3t.core.model.ProcessOpenBankingPubSubTransactionResponse", []);
    }
    create(value?: PartialMessage<ProcessOpenBankingPubSubTransactionResponse>): ProcessOpenBankingPubSubTransactionResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProcessOpenBankingPubSubTransactionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessOpenBankingPubSubTransactionResponse): ProcessOpenBankingPubSubTransactionResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ProcessOpenBankingPubSubTransactionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ProcessOpenBankingPubSubTransactionResponse
 */
export const ProcessOpenBankingPubSubTransactionResponse = new ProcessOpenBankingPubSubTransactionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessOpenBankingTransactionRequest$Type extends MessageType<ProcessOpenBankingTransactionRequest> {
    constructor() {
        super("one.plan3t.core.model.ProcessOpenBankingTransactionRequest", [
            { no: 1, name: "transaction", kind: "message", T: () => Plan3tUserTransaction }
        ]);
    }
    create(value?: PartialMessage<ProcessOpenBankingTransactionRequest>): ProcessOpenBankingTransactionRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProcessOpenBankingTransactionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessOpenBankingTransactionRequest): ProcessOpenBankingTransactionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.Plan3tUserTransaction transaction */ 1:
                    message.transaction = Plan3tUserTransaction.internalBinaryRead(reader, reader.uint32(), options, message.transaction);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessOpenBankingTransactionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.Plan3tUserTransaction transaction = 1; */
        if (message.transaction)
            Plan3tUserTransaction.internalBinaryWrite(message.transaction, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ProcessOpenBankingTransactionRequest
 */
export const ProcessOpenBankingTransactionRequest = new ProcessOpenBankingTransactionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessOpenBankingTransactionResponse$Type extends MessageType<ProcessOpenBankingTransactionResponse> {
    constructor() {
        super("one.plan3t.core.model.ProcessOpenBankingTransactionResponse", []);
    }
    create(value?: PartialMessage<ProcessOpenBankingTransactionResponse>): ProcessOpenBankingTransactionResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProcessOpenBankingTransactionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessOpenBankingTransactionResponse): ProcessOpenBankingTransactionResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ProcessOpenBankingTransactionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ProcessOpenBankingTransactionResponse
 */
export const ProcessOpenBankingTransactionResponse = new ProcessOpenBankingTransactionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessOpenBankingTransactionStorageRequest$Type extends MessageType<ProcessOpenBankingTransactionStorageRequest> {
    constructor() {
        super("one.plan3t.core.model.ProcessOpenBankingTransactionStorageRequest", [
            { no: 1, name: "since", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<ProcessOpenBankingTransactionStorageRequest>): ProcessOpenBankingTransactionStorageRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProcessOpenBankingTransactionStorageRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessOpenBankingTransactionStorageRequest): ProcessOpenBankingTransactionStorageRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp since */ 1:
                    message.since = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.since);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProcessOpenBankingTransactionStorageRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp since = 1; */
        if (message.since)
            Timestamp.internalBinaryWrite(message.since, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ProcessOpenBankingTransactionStorageRequest
 */
export const ProcessOpenBankingTransactionStorageRequest = new ProcessOpenBankingTransactionStorageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProcessOpenBankingTransactionStorageResponse$Type extends MessageType<ProcessOpenBankingTransactionStorageResponse> {
    constructor() {
        super("one.plan3t.core.model.ProcessOpenBankingTransactionStorageResponse", []);
    }
    create(value?: PartialMessage<ProcessOpenBankingTransactionStorageResponse>): ProcessOpenBankingTransactionStorageResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProcessOpenBankingTransactionStorageResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProcessOpenBankingTransactionStorageResponse): ProcessOpenBankingTransactionStorageResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ProcessOpenBankingTransactionStorageResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ProcessOpenBankingTransactionStorageResponse
 */
export const ProcessOpenBankingTransactionStorageResponse = new ProcessOpenBankingTransactionStorageResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AccountingCommand$Type extends MessageType<AccountingCommand> {
    constructor() {
        super("one.plan3t.core.model.AccountingCommand", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "command_type", kind: "enum", T: () => ["one.plan3t.core.model.CommandType", CommandType] },
            { no: 3, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 6, name: "payload", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<AccountingCommand>): AccountingCommand {
        const message = { id: "", commandType: 0, userId: "", partnerId: "", payload: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AccountingCommand>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AccountingCommand): AccountingCommand {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* one.plan3t.core.model.CommandType command_type */ 2:
                    message.commandType = reader.int32();
                    break;
                case /* string user_id */ 3:
                    message.userId = reader.string();
                    break;
                case /* string partner_id */ 4:
                    message.partnerId = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 5:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* bytes payload */ 6:
                    message.payload = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AccountingCommand, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* one.plan3t.core.model.CommandType command_type = 2; */
        if (message.commandType !== 0)
            writer.tag(2, WireType.Varint).int32(message.commandType);
        /* string user_id = 3; */
        if (message.userId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.userId);
        /* string partner_id = 4; */
        if (message.partnerId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.partnerId);
        /* google.protobuf.Timestamp created_at = 5; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* bytes payload = 6; */
        if (message.payload.length)
            writer.tag(6, WireType.LengthDelimited).bytes(message.payload);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.AccountingCommand
 */
export const AccountingCommand = new AccountingCommand$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplyAccountingCommandRequest$Type extends MessageType<ApplyAccountingCommandRequest> {
    constructor() {
        super("one.plan3t.core.model.ApplyAccountingCommandRequest", [
            { no: 1, name: "command", kind: "message", T: () => AccountingCommand }
        ]);
    }
    create(value?: PartialMessage<ApplyAccountingCommandRequest>): ApplyAccountingCommandRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ApplyAccountingCommandRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ApplyAccountingCommandRequest): ApplyAccountingCommandRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.AccountingCommand command */ 1:
                    message.command = AccountingCommand.internalBinaryRead(reader, reader.uint32(), options, message.command);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ApplyAccountingCommandRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.AccountingCommand command = 1; */
        if (message.command)
            AccountingCommand.internalBinaryWrite(message.command, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ApplyAccountingCommandRequest
 */
export const ApplyAccountingCommandRequest = new ApplyAccountingCommandRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ApplyAccountingCommandResponse$Type extends MessageType<ApplyAccountingCommandResponse> {
    constructor() {
        super("one.plan3t.core.model.ApplyAccountingCommandResponse", []);
    }
    create(value?: PartialMessage<ApplyAccountingCommandResponse>): ApplyAccountingCommandResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ApplyAccountingCommandResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ApplyAccountingCommandResponse): ApplyAccountingCommandResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ApplyAccountingCommandResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ApplyAccountingCommandResponse
 */
export const ApplyAccountingCommandResponse = new ApplyAccountingCommandResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendCashbackNotificationsRequest$Type extends MessageType<SendCashbackNotificationsRequest> {
    constructor() {
        super("one.plan3t.core.model.SendCashbackNotificationsRequest", [
            { no: 1, name: "cronHour", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SendCashbackNotificationsRequest>): SendCashbackNotificationsRequest {
        const message = { cronHour: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SendCashbackNotificationsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendCashbackNotificationsRequest): SendCashbackNotificationsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string cronHour */ 1:
                    message.cronHour = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendCashbackNotificationsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string cronHour = 1; */
        if (message.cronHour !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.cronHour);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SendCashbackNotificationsRequest
 */
export const SendCashbackNotificationsRequest = new SendCashbackNotificationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendCashbackNotificationsResponse$Type extends MessageType<SendCashbackNotificationsResponse> {
    constructor() {
        super("one.plan3t.core.model.SendCashbackNotificationsResponse", []);
    }
    create(value?: PartialMessage<SendCashbackNotificationsResponse>): SendCashbackNotificationsResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SendCashbackNotificationsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendCashbackNotificationsResponse): SendCashbackNotificationsResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SendCashbackNotificationsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SendCashbackNotificationsResponse
 */
export const SendCashbackNotificationsResponse = new SendCashbackNotificationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReclassifyTransactionRequest$Type extends MessageType<ReclassifyTransactionRequest> {
    constructor() {
        super("one.plan3t.core.model.ReclassifyTransactionRequest", [
            { no: 1, name: "transaction_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "current_category_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "new_category_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "modifier_choices", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Any } }
        ]);
    }
    create(value?: PartialMessage<ReclassifyTransactionRequest>): ReclassifyTransactionRequest {
        const message = { transactionId: "", currentCategoryId: "", newCategoryId: "", modifierChoices: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReclassifyTransactionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReclassifyTransactionRequest): ReclassifyTransactionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string transaction_id */ 1:
                    message.transactionId = reader.string();
                    break;
                case /* string current_category_id */ 2:
                    message.currentCategoryId = reader.string();
                    break;
                case /* string new_category_id */ 3:
                    message.newCategoryId = reader.string();
                    break;
                case /* map<string, google.protobuf.Any> modifier_choices */ 4:
                    this.binaryReadMap4(message.modifierChoices, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: ReclassifyTransactionRequest["modifierChoices"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ReclassifyTransactionRequest["modifierChoices"] | undefined, val: ReclassifyTransactionRequest["modifierChoices"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Any.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.model.ReclassifyTransactionRequest.modifier_choices");
            }
        }
        map[key ?? ""] = val ?? Any.create();
    }
    internalBinaryWrite(message: ReclassifyTransactionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string transaction_id = 1; */
        if (message.transactionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.transactionId);
        /* string current_category_id = 2; */
        if (message.currentCategoryId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.currentCategoryId);
        /* string new_category_id = 3; */
        if (message.newCategoryId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.newCategoryId);
        /* map<string, google.protobuf.Any> modifier_choices = 4; */
        for (let k of Object.keys(message.modifierChoices)) {
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Any.internalBinaryWrite(message.modifierChoices[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ReclassifyTransactionRequest
 */
export const ReclassifyTransactionRequest = new ReclassifyTransactionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReclassifyTransactionResponse$Type extends MessageType<ReclassifyTransactionResponse> {
    constructor() {
        super("one.plan3t.core.model.ReclassifyTransactionResponse", []);
    }
    create(value?: PartialMessage<ReclassifyTransactionResponse>): ReclassifyTransactionResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReclassifyTransactionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReclassifyTransactionResponse): ReclassifyTransactionResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ReclassifyTransactionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ReclassifyTransactionResponse
 */
export const ReclassifyTransactionResponse = new ReclassifyTransactionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAccountingTransfersRequest$Type extends MessageType<GetAccountingTransfersRequest> {
    constructor() {
        super("one.plan3t.core.model.GetAccountingTransfersRequest", [
            { no: 1, name: "from", kind: "message", T: () => Timestamp },
            { no: 2, name: "to", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<GetAccountingTransfersRequest>): GetAccountingTransfersRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAccountingTransfersRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAccountingTransfersRequest): GetAccountingTransfersRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp from */ 1:
                    message.from = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.from);
                    break;
                case /* google.protobuf.Timestamp to */ 2:
                    message.to = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.to);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAccountingTransfersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp from = 1; */
        if (message.from)
            Timestamp.internalBinaryWrite(message.from, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp to = 2; */
        if (message.to)
            Timestamp.internalBinaryWrite(message.to, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetAccountingTransfersRequest
 */
export const GetAccountingTransfersRequest = new GetAccountingTransfersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAccountingTransfersResponse$Type extends MessageType<GetAccountingTransfersResponse> {
    constructor() {
        super("one.plan3t.core.model.GetAccountingTransfersResponse", [
            { no: 1, name: "transfers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GetAccountingTransfersResponse_Transfer }
        ]);
    }
    create(value?: PartialMessage<GetAccountingTransfersResponse>): GetAccountingTransfersResponse {
        const message = { transfers: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAccountingTransfersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAccountingTransfersResponse): GetAccountingTransfersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.GetAccountingTransfersResponse.Transfer transfers */ 1:
                    message.transfers.push(GetAccountingTransfersResponse_Transfer.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAccountingTransfersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.GetAccountingTransfersResponse.Transfer transfers = 1; */
        for (let i = 0; i < message.transfers.length; i++)
            GetAccountingTransfersResponse_Transfer.internalBinaryWrite(message.transfers[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetAccountingTransfersResponse
 */
export const GetAccountingTransfersResponse = new GetAccountingTransfersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAccountingTransfersResponse_Address$Type extends MessageType<GetAccountingTransfersResponse_Address> {
    constructor() {
        super("one.plan3t.core.model.GetAccountingTransfersResponse.Address", [
            { no: 1, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "address_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "can_mint_or_burn", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetAccountingTransfersResponse_Address>): GetAccountingTransfersResponse_Address {
        const message = { address: "", addressType: "", canMintOrBurn: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAccountingTransfersResponse_Address>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAccountingTransfersResponse_Address): GetAccountingTransfersResponse_Address {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string address */ 1:
                    message.address = reader.string();
                    break;
                case /* string address_type */ 2:
                    message.addressType = reader.string();
                    break;
                case /* bool can_mint_or_burn */ 3:
                    message.canMintOrBurn = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAccountingTransfersResponse_Address, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string address = 1; */
        if (message.address !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.address);
        /* string address_type = 2; */
        if (message.addressType !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.addressType);
        /* bool can_mint_or_burn = 3; */
        if (message.canMintOrBurn !== false)
            writer.tag(3, WireType.Varint).bool(message.canMintOrBurn);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetAccountingTransfersResponse.Address
 */
export const GetAccountingTransfersResponse_Address = new GetAccountingTransfersResponse_Address$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAccountingTransfersResponse_Transfer$Type extends MessageType<GetAccountingTransfersResponse_Transfer> {
    constructor() {
        super("one.plan3t.core.model.GetAccountingTransfersResponse.Transfer", [
            { no: 1, name: "transaction_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "command_type", kind: "enum", T: () => ["one.plan3t.core.model.CommandType", CommandType] },
            { no: 4, name: "from", kind: "message", T: () => GetAccountingTransfersResponse_Address },
            { no: 5, name: "to", kind: "message", T: () => GetAccountingTransfersResponse_Address },
            { no: 6, name: "amount", kind: "message", T: () => Plan3TPoint },
            { no: 7, name: "timestamp", kind: "message", T: () => Timestamp },
            { no: 8, name: "reference_timestamp", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<GetAccountingTransfersResponse_Transfer>): GetAccountingTransfersResponse_Transfer {
        const message = { transactionId: "", commandType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAccountingTransfersResponse_Transfer>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAccountingTransfersResponse_Transfer): GetAccountingTransfersResponse_Transfer {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string transaction_id */ 1:
                    message.transactionId = reader.string();
                    break;
                case /* one.plan3t.core.model.CommandType command_type */ 2:
                    message.commandType = reader.int32();
                    break;
                case /* one.plan3t.core.model.GetAccountingTransfersResponse.Address from */ 4:
                    message.from = GetAccountingTransfersResponse_Address.internalBinaryRead(reader, reader.uint32(), options, message.from);
                    break;
                case /* one.plan3t.core.model.GetAccountingTransfersResponse.Address to */ 5:
                    message.to = GetAccountingTransfersResponse_Address.internalBinaryRead(reader, reader.uint32(), options, message.to);
                    break;
                case /* one.plan3t.core.model.Plan3TPoint amount */ 6:
                    message.amount = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                case /* google.protobuf.Timestamp timestamp */ 7:
                    message.timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timestamp);
                    break;
                case /* google.protobuf.Timestamp reference_timestamp */ 8:
                    message.referenceTimestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.referenceTimestamp);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAccountingTransfersResponse_Transfer, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string transaction_id = 1; */
        if (message.transactionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.transactionId);
        /* one.plan3t.core.model.CommandType command_type = 2; */
        if (message.commandType !== 0)
            writer.tag(2, WireType.Varint).int32(message.commandType);
        /* one.plan3t.core.model.GetAccountingTransfersResponse.Address from = 4; */
        if (message.from)
            GetAccountingTransfersResponse_Address.internalBinaryWrite(message.from, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.GetAccountingTransfersResponse.Address to = 5; */
        if (message.to)
            GetAccountingTransfersResponse_Address.internalBinaryWrite(message.to, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.Plan3TPoint amount = 6; */
        if (message.amount)
            Plan3TPoint.internalBinaryWrite(message.amount, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp timestamp = 7; */
        if (message.timestamp)
            Timestamp.internalBinaryWrite(message.timestamp, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp reference_timestamp = 8; */
        if (message.referenceTimestamp)
            Timestamp.internalBinaryWrite(message.referenceTimestamp, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetAccountingTransfersResponse.Transfer
 */
export const GetAccountingTransfersResponse_Transfer = new GetAccountingTransfersResponse_Transfer$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCashbackStatsForUserRequest$Type extends MessageType<GetCashbackStatsForUserRequest> {
    constructor() {
        super("one.plan3t.core.model.GetCashbackStatsForUserRequest", [
            { no: 1, name: "reference", kind: "message", T: () => Date },
            { no: 2, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCashbackStatsForUserRequest>): GetCashbackStatsForUserRequest {
        const message = { userId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCashbackStatsForUserRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCashbackStatsForUserRequest): GetCashbackStatsForUserRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.Date reference */ 1:
                    message.reference = Date.internalBinaryRead(reader, reader.uint32(), options, message.reference);
                    break;
                case /* string user_id */ 2:
                    message.userId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCashbackStatsForUserRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.Date reference = 1; */
        if (message.reference)
            Date.internalBinaryWrite(message.reference, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string user_id = 2; */
        if (message.userId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetCashbackStatsForUserRequest
 */
export const GetCashbackStatsForUserRequest = new GetCashbackStatsForUserRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCashbackStatsForUserResponse$Type extends MessageType<GetCashbackStatsForUserResponse> {
    constructor() {
        super("one.plan3t.core.model.GetCashbackStatsForUserResponse", [
            { no: 1, name: "number_of_transactions", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "cashback_amount", kind: "message", T: () => Plan3TPoint }
        ]);
    }
    create(value?: PartialMessage<GetCashbackStatsForUserResponse>): GetCashbackStatsForUserResponse {
        const message = { numberOfTransactions: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCashbackStatsForUserResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCashbackStatsForUserResponse): GetCashbackStatsForUserResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 number_of_transactions */ 1:
                    message.numberOfTransactions = reader.int32();
                    break;
                case /* one.plan3t.core.model.Plan3TPoint cashback_amount */ 2:
                    message.cashbackAmount = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.cashbackAmount);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCashbackStatsForUserResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 number_of_transactions = 1; */
        if (message.numberOfTransactions !== 0)
            writer.tag(1, WireType.Varint).int32(message.numberOfTransactions);
        /* one.plan3t.core.model.Plan3TPoint cashback_amount = 2; */
        if (message.cashbackAmount)
            Plan3TPoint.internalBinaryWrite(message.cashbackAmount, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetCashbackStatsForUserResponse
 */
export const GetCashbackStatsForUserResponse = new GetCashbackStatsForUserResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendOpenBankingConnectionStatusNotificationsRequest$Type extends MessageType<SendOpenBankingConnectionStatusNotificationsRequest> {
    constructor() {
        super("one.plan3t.core.model.SendOpenBankingConnectionStatusNotificationsRequest", [
            { no: 1, name: "dry_run", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SendOpenBankingConnectionStatusNotificationsRequest>): SendOpenBankingConnectionStatusNotificationsRequest {
        const message = { dryRun: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SendOpenBankingConnectionStatusNotificationsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendOpenBankingConnectionStatusNotificationsRequest): SendOpenBankingConnectionStatusNotificationsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool dry_run */ 1:
                    message.dryRun = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendOpenBankingConnectionStatusNotificationsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool dry_run = 1; */
        if (message.dryRun !== false)
            writer.tag(1, WireType.Varint).bool(message.dryRun);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SendOpenBankingConnectionStatusNotificationsRequest
 */
export const SendOpenBankingConnectionStatusNotificationsRequest = new SendOpenBankingConnectionStatusNotificationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendOpenBankingConnectionStatusNotificationsResponse$Type extends MessageType<SendOpenBankingConnectionStatusNotificationsResponse> {
    constructor() {
        super("one.plan3t.core.model.SendOpenBankingConnectionStatusNotificationsResponse", []);
    }
    create(value?: PartialMessage<SendOpenBankingConnectionStatusNotificationsResponse>): SendOpenBankingConnectionStatusNotificationsResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SendOpenBankingConnectionStatusNotificationsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendOpenBankingConnectionStatusNotificationsResponse): SendOpenBankingConnectionStatusNotificationsResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SendOpenBankingConnectionStatusNotificationsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.SendOpenBankingConnectionStatusNotificationsResponse
 */
export const SendOpenBankingConnectionStatusNotificationsResponse = new SendOpenBankingConnectionStatusNotificationsResponse$Type();
