import { CrudSlice } from './store';
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../redux/react';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Button } from '../common/Button';

type Props<T> = {
    module: CrudSlice<T>;
    id: string | undefined;
    validate?: (item: T) => object;
    validationSchema?: any | (() => any);
    children?: ReactNode | undefined;
};

export default function ItemForm<T extends { [field: string]: any }>({
    module,
    id,
    validate,
    validationSchema,
    children,
}: Props<T>) {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const [defaultEmpty] = useState(module._args.prepareEmpty());

    const current: T = useAppSelector((state) =>
        module.selectors.getItem(state, { id: id || '' }),
    );

    const isNew = id === undefined;

    const { fetchSingleItem } = module.actions;
    useEffect(() => {
        if (!isNew && current === undefined) {
            dispatch(fetchSingleItem(id!));
        }
    }, [dispatch, isNew, id, current, fetchSingleItem]);

    const isFetching = useAppSelector(module.selectors.isFetching);

    const { writeItem } = module.actions;
    const onSubmit = useCallback(
        async (value: T) => {
            const res = await dispatch(writeItem(value));
            if (res.meta.requestStatus === 'fulfilled') {
                history.goBack();
            }
        },
        [dispatch, history, writeItem],
    );

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={current || defaultEmpty}
            enableReinitialize
            validate={validate}
            validationSchema={validationSchema}
        >
            <Form className="px-4 pt-8 space-y-2">
                <div className="flex items-center space-x-2">
                    <div>
                        <Button
                            secondary
                            label={
                                <svg
                                    className="w-4 h-4"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                </svg>
                            }
                            onClick={(e) => {
                                e.preventDefault();
                                history.goBack();
                            }}
                        />
                    </div>
                    <h1 className="font-bold text-3xl">
                        {isNew ? 'New' : 'Edit'}&nbsp;
                        <span className="capitalize">{module._args.name}</span>
                    </h1>
                    {isFetching && <span>(Loading)</span>}
                    <div className="flex-shrink">
                        <Button type="submit" label="Save" primary />
                    </div>
                </div>
                <div className="flex flex-col space-y-4 px-4 pt-8">
                    {children}
                </div>
            </Form>
        </Formik>
    );
}
