import { useAppDispatch, useAppSelector } from '../redux/react';
import ItemCategoryEditor from '../common/ItemCategoryEditor';
import { FunctionComponent, useCallback, useEffect } from 'react';
import {
    GetRewardsCategoryAuthoringItemsResponse,
    ItemCategoryAuthoring,
    WriteRewardsCategoryAuthoringRequest,
} from '../api/model/backoffice';
import axios from 'axios';
import { GetRewardLiveResponse } from '../api/model/core';
import { rewardActions, rewardSelectors } from './store';
import { Timestamp } from '../api/google/protobuf/timestamp';
import cx from 'classnames';
import { useRouteMatch } from 'react-router-dom';
import PartnerName from '../partner/PartnerName';

const RewardsCategoriesEditor: FunctionComponent = () => {
    // preload the rewards
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(rewardActions.fetchRewards());
    }, [dispatch]);

    const billingTypeRaw = (useRouteMatch().params as any)[
        'billingType'
    ] as string;
    const billingType = WriteRewardsCategoryAuthoringRequest.fromJson({
        billingType: billingTypeRaw,
    }).billingType;

    const fetchCategories = useCallback(async (): Promise<
        ItemCategoryAuthoring[]
    > => {
        // @ts-ignore
        const encoded = WriteRewardsCategoryAuthoringRequest.toJson({
            billingType,
        })['billingType'];
        const raw = await axios.get<Object>(
            `/rewardsCategory?billing_type=${encoded}`,
        );
        const res = GetRewardsCategoryAuthoringItemsResponse.fromJson(
            raw.data as any,
        );
        return res.categories;
    }, [billingType]);

    const fetchRewardIds = useCallback(async () => {
        const raw = await axios.get<Object>(`/reward?limit=500`);
        const res = GetRewardLiveResponse.fromJson(raw.data as any);
        return res.rewards
            .filter((r) => r.billingType === billingType)
            .map((r) => r.rewardId);
    }, [billingType]);

    const write = useCallback(
        async (item: ItemCategoryAuthoring) => {
            await axios.post<Object>(
                `/rewardsCategory`,
                WriteRewardsCategoryAuthoringRequest.toJson({
                    item,
                    billingType,
                }),
            );
        },
        [billingType],
    );

    return (
        <ItemCategoryEditor
            title="Reward Categories"
            deepLinkBuilder={(id) => `/rewardsCategory?itemCategory=${id}`}
            fetchCategories={fetchCategories}
            fetchAllItemIds={fetchRewardIds}
            write={write}
            renderItem={(itemId, index, categoryId) => (
                <RewardCard
                    key={itemId}
                    id={itemId}
                    index={index}
                    categoryId={categoryId}
                />
            )}
        />
    );
};

export default RewardsCategoriesEditor;

const RewardCard: FunctionComponent<{
    id: string;
    index: number;
    categoryId: string;
}> = ({ id, index, categoryId }) => {
    const reward = useAppSelector((state) =>
        rewardSelectors.getReward(state, { id }),
    );

    const dispatch = useAppDispatch();

    const fetch = useCallback(
        () =>
            dispatch(
                rewardActions.fetchSingleReward({
                    rewardId: id,
                    partnerId: '-',
                }),
            ),
        [dispatch, id],
    );

    if (!reward) {
        fetch();
        return <span>Loading...</span>;
    }

    const expired = Timestamp.toDate(reward.validUntil!) < new Date();

    return (
        <div
            className={cx(
                'bg-white rounded border-gray-100 border-2 space-y-2 w-48 h-56 hover:shadow-lg overflow-hidden',
                {
                    'opacity-50': expired,
                },
            )}
        >
            <div className="h-32 w-full rounded-t relative">
                <img
                    src={reward.images[0].url}
                    alt="cover"
                    className="object-cover"
                />
                <div className="absolute top-0 left-0 px-2 py-1">
                    <div className="w-24 inline-flex justify-start">
                        <div className="bg-gray-300 text-gray-900 rounded px-1 truncate">
                            <PartnerName id={reward.partnerId} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="px-2 pb-4">
                <span className="text-lg font-bold text-gray-700">
                    {expired && 'EXPIRED: '}
                    {reward?.title}
                </span>
            </div>
        </div>
    );
};
