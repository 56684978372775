import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ImageResource } from '../api/model/core';
import { TipQuizDraft } from '../api/model/tip';
import { Button } from '../common/Button';
import { RadioButton } from '../common/Checkbox';
import Scrollable from '../common/Scrollable';
import { TextField } from '../common/TextField';
import FileUpload from '../files/FileUpload';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { tipQuizEditorActions, tipQuizEditorSelectors } from './editorStore';

export interface TipQuizEditorProps {
    readonly tipID: string;
}

export const TipQuizEditor = (props: TipQuizEditorProps) => {
    const { tipID } = props;
    const dispatch = useAppDispatch();
    const history = useHistory();
    const quiz = useAppSelector(tipQuizEditorSelectors.getQuiz);
    const hasUnsavedChanges = useAppSelector(
        tipQuizEditorSelectors.hasUnsavedChanges,
    );

    useEffect(() => {
        dispatch(tipQuizEditorActions.fetchQuiz({ id: tipID }));
    }, [dispatch, tipID]);

    const save = () => dispatch(tipQuizEditorActions.saveQuiz());
    const reset = () => dispatch(tipQuizEditorActions.resetState());
    const initQuiz = () => dispatch(tipQuizEditorActions.initQuiz());

    return (
        <Scrollable>
            <div className="flex items-center space-x-2">
                <div>
                    <Button
                        secondary
                        label={
                            <svg
                                className="w-4 h-4"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                            </svg>
                        }
                        onClick={(e) => {
                            e.preventDefault();
                            history.goBack();
                        }}
                    />
                </div>
                <h1 className="font-bold text-3xl">Quiz</h1>
                <div className="flex-shrink">
                    <Button
                        type="submit"
                        label="Save"
                        primary
                        disabled={!hasUnsavedChanges}
                        onClick={save}
                    />
                </div>
                {hasUnsavedChanges && (
                    <div className="flex-shrink">
                        <Button
                            type="button"
                            label="Reset"
                            secondary
                            onClick={reset}
                        />
                    </div>
                )}
                {hasUnsavedChanges && (
                    <span className="px-2 py-1 rounded-full bg-yellow-100 text-yellow-800">
                        Unsaved changes
                    </span>
                )}
                {!quiz && !hasUnsavedChanges && (
                    <Button
                        type="button"
                        label="Add Quiz"
                        primary
                        onClick={initQuiz}
                    />
                )}
            </div>
            {quiz && <QuizForm quiz={quiz} />}
        </Scrollable>
    );
};

const QuizForm = ({ quiz }: { quiz: TipQuizDraft }) => {
    const dispatch = useAppDispatch();

    const removeAnswer = (idx: number) => {
        dispatch(tipQuizEditorActions.removeAnswer(idx));
    };
    const addAnswer = () => dispatch(tipQuizEditorActions.addAnswer());
    const setCorrectAnswerIndex = (idx: number) =>
        dispatch(tipQuizEditorActions.setCorrectAnswerIndex(idx));
    const setQuestion = (text: string) =>
        dispatch(tipQuizEditorActions.setQuestion(text));
    const setAnswer = (idx: number, text: string) =>
        dispatch(tipQuizEditorActions.setAnswer({ idx, text }));
    const setBackgroundImage = (image: ImageResource | undefined) =>
        dispatch(tipQuizEditorActions.setBackgroundImage(image));
    return (
        <div className="flex flex-col space-y-4 mt-4 px-4 py-6 border-4 border-gray-50 rounded">
            <FileUpload
                label="Image"
                message="Drop a single image here"
                file={quiz.backgroundImage}
                onSingleChanged={(i) => setBackgroundImage(i)}
            />
            <TextField
                className="w-full"
                placeholder={'Question text'}
                value={quiz.question}
                onChange={setQuestion}
            />
            {quiz.answers.map((answer, idx) => (
                <div
                    className="flex flex-row space-x-8 items-baseline"
                    key={idx}
                >
                    <RadioButton
                        label=""
                        checked={quiz.correctAnswerIndex === idx}
                        onChange={() => setCorrectAnswerIndex(idx)}
                    />
                    <TextField
                        className="w-full"
                        placeholder={'Add an answer...'}
                        value={answer}
                        onChange={(value) => setAnswer(idx, value)}
                    />
                    <div className="w-20">
                        <Button
                            secondary
                            onClick={() => removeAnswer(idx)}
                            label="Remove"
                        />
                    </div>
                </div>
            ))}
            <Button label="Add Answer" primary onClick={() => addAnswer()} />
        </div>
    );
};
