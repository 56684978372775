import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    DraftState,
    GetRewardAssetsResponse,
    GetRewardLiveResponse,
    GetRewardResponse,
    GetCodeIDsForRewardResponse,
    Reward,
} from '../api/model/core';
import { AppState } from '../redux/AppStore';
import {
    GetRewardCodeResponse,
    GetRewardsCategoryAuthoringItemsResponse,
    ItemCategoryAuthoring,
    PostAdjustRewardPriceRequest,
    PostDeleteRewardCodesRequest,
    SetRewardStatusRequest,
    SetValidUntilForRewardRequest,
    WriteRewardsCategoryAuthoringRequest,
} from '../api/model/backoffice';
import { snackbarActions } from '../snackbar/store';
import { selectorFamily } from 'recoil';
import { Hash } from 'fast-sha256';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { useEffect, useMemo } from 'react';

const fetchAll = async (): Promise<Reward[]> => {
    const raw = await axios.get<Object>(`/reward`);
    const res = GetRewardLiveResponse.fromJson(raw.data as any);
    return res.rewards;
};

const fetchRewards = createAsyncThunk(
    'reward/fetch',
    async (_: void, { getState }) => {
        const s = (getState() as AppState).reward;

        if (s.rewardIds.length === 0) {
            return fetchAll();
        }
        return [];
    },
);

type fetchSingleRewardArgs = {
    rewardId: string;
    partnerId: string;
};

const fetchSingleReward = createAsyncThunk(
    'reward/fetchSingle',
    async (args: fetchSingleRewardArgs) => {
        const raw = await axios.get<Object>(
            `/reward/${args.partnerId}/id/${args.rewardId}`,
        );
        const res = GetRewardResponse.fromJson(raw.data as any);
        return res.reward;
    },
);

type fetchRewardBalanceArgs = {
    rewardId: string;
    partnerId: string;
};

const fetchRewardBalance = createAsyncThunk(
    'reward/balance/fetch',
    async (args: fetchRewardBalanceArgs) => {
        const raw = await axios.get<Object>(
            `/reward/${args.partnerId}/id/${args.rewardId}/codes`,
        );

        const res = GetRewardAssetsResponse.fromJson(raw.data as any);
        return res.available;
    },
);

const deleteRewardCodes = createAsyncThunk(
    'reward/balance/delete',
    async (args: PostDeleteRewardCodesRequest) => {
        await axios.post<Object>(
            `/reward/${args.partnerId}/id/${args.rewardId}/deleteRewardCodes`,
            args,
        );
    },
);

const adjustRewardPrice = createAsyncThunk(
    'reward/price/adjust',
    async (args: PostAdjustRewardPriceRequest) => {
        await axios.post<Object>(
            `/reward/${args.partnerId}/id/${args.rewardId}/adjustRewardPrice`,
            args,
        );
    },
);

const setRewardStatus = createAsyncThunk(
    'reward/status/set',
    async (args: SetRewardStatusRequest) => {
        await axios.post<Object>(
            `/reward/${args.partnerId}/id/${args.rewardId}/setRewardStatus`,
            SetRewardStatusRequest.toJson(args),
        );
    },
);

const setValidUntil = createAsyncThunk(
    'reward/validUntil/set',
    async (args: SetValidUntilForRewardRequest) => {
        await axios.post<Object>(
            `/reward/${args.partnerId}/id/${args.rewardId}/setValidUntil`,
            SetValidUntilForRewardRequest.toJson(args),
        );
    },
);

const fetchRewardCategories = createAsyncThunk(
    'reward/category/fetch',
    async (): Promise<ItemCategoryAuthoring[]> => {
        const raw = await axios.get<Object>(`/rewardsCategory`);
        const res = GetRewardsCategoryAuthoringItemsResponse.fromJson(
            raw.data as any,
        );
        return res.categories;
    },
);

const writeRewardsCategory = createAsyncThunk(
    'reward/category/write',
    async (arg: WriteRewardsCategoryAuthoringRequest) => {
        await axios.post<Object>(`/rewardsCategory`, arg);
    },
);

const copyDeepLink = createAsyncThunk(
    'reward/copyDeepLink',
    async (args: { rewardId: string; partnerId: string }, { dispatch }) => {
        const deepLink = `/reward?reward=${args.rewardId}&partner=${args.partnerId}`;
        await navigator.clipboard.writeText(deepLink);

        dispatch(
            snackbarActions.sendMessage({
                title: 'DeepLink copied to clipboard',
                subtitle: deepLink,
            }),
        );
    },
);

type State = {
    rewardIds: string[];
    rewardById: Record<string, Reward>;
    codesByRewardId: Record<string, string[]>;
    isFetching: boolean;
    fetchError: any;

    categoryIds: string[];
    categoryById: Record<string, ItemCategoryAuthoring>;
    isFetchingCategories: boolean;
    fetchErrorCategories: any;
};

const initialState: State = {
    rewardIds: [],
    rewardById: {},
    codesByRewardId: {},
    isFetching: false,
    fetchError: undefined,
    categoryIds: [],
    categoryById: {},
    isFetchingCategories: false,
    fetchErrorCategories: undefined,
};

const rewardSlice = createSlice({
    name: 'reward',
    initialState,
    reducers: {
        navigateToNextPage(state) {},
        navigateToPreviousPage(state) {},
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRewards.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(fetchRewards.fulfilled, (state, action) => {
            state.isFetching = false;

            const asSet = new Set(state.rewardIds);

            action.payload.forEach((reward) => {
                asSet.add(reward.rewardId);
                state.rewardById[reward.rewardId] = reward;
            });
            state.rewardIds = Array.from(asSet).sort();
        });
        builder.addCase(fetchRewards.rejected, (state, action) => {
            state.isFetching = false;
            state.fetchError = action.error;
        });
        builder.addCase(fetchRewardBalance.fulfilled, (state, action) => {
            state.codesByRewardId[action.meta.arg.rewardId] =
                action.payload.map((asset) => asset.code);
        });
        builder.addCase(setRewardStatus.fulfilled, (state, action) => {
            if (action.meta.arg.state === DraftState.Suspended) {
                delete state.codesByRewardId[action.meta.arg.rewardId];
                state.rewardIds = state.rewardIds
                    .filter((id) => id !== action.meta.arg.rewardId)
                    .sort();
            }
        });
        builder.addCase(fetchSingleReward.fulfilled, (state, action) => {
            if (!action.payload) {
                return;
            }
            const asSet = new Set(state.rewardIds);
            asSet.add(action.payload.rewardId);
            state.rewardById[action.payload.rewardId] = action.payload;
            state.rewardIds = Array.from(asSet).sort();
        });
        builder.addCase(fetchRewardCategories.pending, (state) => {
            state.isFetchingCategories = true;
        });
        builder.addCase(fetchRewardCategories.rejected, (state, action) => {
            state.isFetchingCategories = false;
            state.fetchErrorCategories = action.error;
        });
        builder.addCase(fetchRewardCategories.fulfilled, (state, action) => {
            state.isFetchingCategories = false;
            state.fetchErrorCategories = undefined;

            const asSet = new Set(state.categoryIds);

            action.payload.forEach((category) => {
                asSet.add(category.id);
                state.categoryById[category.id] = category;
            });
            state.categoryIds = Array.from(asSet).sort();
        });
    },
});

export const rewardReducer = rewardSlice.reducer;
export const rewardActions = {
    ...rewardSlice.actions,
    fetchRewards,
    fetchSingleReward,
    fetchRewardBalance,
    deleteRewardCodes,
    adjustRewardPrice,
    setRewardStatus,
    setValidUntil,
    fetchRewardCategories,
    writeRewardsCategory,
    copyDeepLink,
};

export const rewardSelectors = {
    isFetching: (state: AppState) => state.reward.isFetching,
    getCurrentPageNumber: (state: AppState) => 0,
    getCurrentPage: (state: AppState): Reward[] => {
        const { rewardIds, rewardById } = state.reward;

        return rewardIds.map((id) => rewardById[id]);
    },
    getReward: (state: AppState, { id }: { id: string }) =>
        state.reward.rewardById[id],
    getPageSize: (state: AppState) => state.reward.rewardIds.length,
    hasMore: (state: AppState): boolean =>
        state.reward.fetchError === undefined &&
        state.reward.rewardIds.length === 0,
    codesForReward: (state: AppState, { id }: { id: string }): string[] =>
        state.reward.codesByRewardId[id] ?? [],
    getCategories: (state: AppState) =>
        state.reward.categoryIds
            .map((id) => state.reward.categoryById[id])
            .sort((a, b) => a.position - b.position),
} as const;

export const codeIDsV2Query = selectorFamily({
    key: 'reward/codeIDsV2Query',
    get: (args: [string, string]) => async () => {
        const res = await axios.get(
            `/reward/${args[0]}/id/${args[1]}/codes/v2`,
        );
        return GetCodeIDsForRewardResponse.fromJson(res.data).codeIds;
    },
});

const powForCodeV2Req = selectorFamily({
    key: 'reward/powForCodeV2Req',
    get: (args: [string, string, string]) => async () => {
        const diff = 2;
        const enc = new TextEncoder();

        const parts: Array<Uint8Array> = args.map(enc.encode.bind(enc));
        const base = new Uint8Array(parts.reduce((a, b) => a + b.length, 0));
        let pos = 0;
        for (let p of parts) {
            base.set(p, pos);
            pos += p.length;
        }

        const h = new Hash();
        for (let i = 0; i < 1_000_000; i++) {
            if (i % 1000 === 0) {
                await new Promise((resolve) => requestAnimationFrame(resolve));
            }
            h.reset();
            h.update(base);
            const rawNonce = `PLAN3T0x${i}`;
            const nonce = enc.encode(rawNonce);
            h.update(nonce);

            const d = h.digest();
            if (d.slice(0, diff).reduce((a, b) => a + b, 0) < diff) {
                console.log(Buffer.from(h.digest()).toString('hex'));
                return rawNonce;
            }
        }

        throw new Error('could not find proof of work solution');
    },
});

export const codeV2Query = selectorFamily({
    key: 'reward/codeV2Query',
    get:
        (args: [string, string, string]) =>
        async ({ get }) => {
            const nonce = get(powForCodeV2Req([args[2], args[1], args[0]]));

            const res = await axios.get(
                `/reward/${args[0]}/id/${args[1]}/codes/v2/id/${args[2]}?nonce=${nonce}`,
            );

            return GetRewardCodeResponse.fromJson(res.data);
        },
});

export function useRewardList(): Array<Reward> {
    const dispatch = useAppDispatch();
    const rewards = useAppSelector((state) =>
        Object.values(state.reward.rewardById),
    );
    const isFetching = useAppSelector((state) => state.reward.isFetching);
    const hasLoaded = rewards.length > 0;

    useEffect(() => {
        if (!hasLoaded && !isFetching) {
            dispatch(rewardActions.fetchRewards());
        }
    }, [dispatch, hasLoaded, isFetching]);

    return rewards;
}

export function useReward(id: string): Reward | undefined {
    const rewards = useRewardList();
    return useMemo(() => rewards.find((p) => p.rewardId === id), [id, rewards]);
}
