import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PartnerList from './PartnerList';
import CreatePartnerForm from './CreatePartnerForm';
import PartnerProfileViewer from './PartnerProfileViewer';
import PartnerCategoriesEditor from './PartnerCategoriesEditor';

const PartnerModule: React.FunctionComponent = () => {
    return (
        <Switch>
            <Route path="/partner" exact>
                <PartnerList />
            </Route>
            <Route path="/partner/create">
                <CreatePartnerForm />
            </Route>
            <Route path="/partner/categories">
                <PartnerCategoriesEditor />
            </Route>
            <Route
                path="/partner/:id/profile"
                render={({ match }) => (
                    <PartnerProfileViewer id={match.params['id']} />
                )}
            />
        </Switch>
    );
};

export default PartnerModule;
