import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../redux/AppStore';

import {
    GetPlatformReportResponse,
    PlatformReport,
} from '../api/model/backoffice';

const fetchReport = createAsyncThunk('fetch/analytics', async () => {
    const res = await axios.get<Object>(`/analytics/platformReport`);

    return GetPlatformReportResponse.fromJson(res.data as any);
});

export type AnalyticsState = {
    isLoading: boolean;
    loadingFailed: boolean;
    report: PlatformReport | undefined;
};

const initialState: AnalyticsState = {
    isLoading: false,
    loadingFailed: false,
    report: undefined,
};

const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchReport.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(fetchReport.rejected, (state, action) => {
            state.isLoading = false;
            state.loadingFailed = true;
        });
        builder.addCase(fetchReport.fulfilled, (state, action) => {
            state.isLoading = false;
            state.report = action.payload?.report;
        });
    },
});

export const analyticsReducer = analyticsSlice.reducer;

export const analyticsActions = {
    ...analyticsSlice.actions,
    fetchReport,
};

export const analyticsSelectors = {
    isLoading: (state: AppState) => state.analytics.isLoading,
    loadingFailed: (state: AppState) => state.analytics.loadingFailed,
    report: (state: AppState) => state.analytics.report,
};
