import React, { useEffect, useState } from 'react';
import { useField } from 'formik';

type Props = { name: string; label: string };

const FormBlogReferenceAssociationField: React.FunctionComponent<Props> = (
    props,
) => {
    const [raw, meta, h] = useField<string[]>(props.name);

    const serialized = raw.value?.join(',') ?? '';

    const [inflight, setInflight] = useState<string>(serialized);

    useEffect(() => {
        setInflight(serialized);
    }, [setInflight, serialized]);

    return (
        <div>
            <label className="block">
                <span className="text-gray-700">{props.label}</span>
                <input
                    className="form-input mt-1 block w-full"
                    type="text"
                    {...raw}
                    value={inflight}
                    onChange={(e) => {
                        setInflight(e.target.value);
                    }}
                    onBlur={() => {
                        const next = inflight
                            .split(',')
                            .map((s) => s.trim())
                            .filter((s) => !!s);
                        h.setValue(next);
                        setInflight(next.join(','));
                    }}
                />
            </label>
            {meta.touched && meta.error ? (
                <p className="font-medium text-sm text-red-400 leading-5 py-1">
                    {meta.error}
                </p>
            ) : (
                <div className="py-1 leading-5">&nbsp;</div>
            )}
        </div>
    );
};

export default FormBlogReferenceAssociationField;
