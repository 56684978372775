// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "model/tip.proto" (package "one.plan3t.core.model", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Duration } from "../google/protobuf/duration";
import { Plan3tRichText } from "./richtext";
import { Timestamp } from "../google/protobuf/timestamp";
import { CO2SavingPotential } from "./core";
import { Plan3TPoint } from "./core";
import { CoreIndustry } from "./core";
import { ImageResource } from "./core";
/**
 * @generated from protobuf message one.plan3t.core.model.TipPreview
 */
export interface TipPreview {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * @generated from protobuf field: string tagline = 3;
     */
    tagline: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource image = 4;
     */
    image?: ImageResource;
    /**
     * @generated from protobuf field: one.plan3t.core.model.CoreIndustry core_industry = 5;
     */
    coreIndustry: CoreIndustry;
    /**
     * @generated from protobuf field: string category = 6;
     */
    category: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint award = 7;
     */
    award?: Plan3TPoint;
    /**
     * @generated from protobuf field: string partner_id = 8;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: repeated string associated_partner_ids = 9;
     */
    associatedPartnerIds: string[];
    /**
     * @generated from protobuf field: bool is_new = 10;
     */
    isNew: boolean;
    /**
     * @generated from protobuf field: string author_id = 11;
     */
    authorId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.TipPreviewDraft
 */
export interface TipPreviewDraft {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * @generated from protobuf field: string tagline = 3;
     */
    tagline: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource image = 4;
     */
    image?: ImageResource;
    /**
     * @generated from protobuf field: one.plan3t.core.model.CoreIndustry core_industry = 5;
     */
    coreIndustry: CoreIndustry;
    /**
     * @generated from protobuf field: string category = 6;
     */
    category: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint award = 7;
     */
    award?: Plan3TPoint;
    /**
     * @generated from protobuf field: string partner_id = 8;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: repeated string associated_partner_ids = 9;
     */
    associatedPartnerIds: string[];
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.CO2SavingPotential recommended_potentials = 10;
     */
    recommendedPotentials: CO2SavingPotential[];
    /**
     * @generated from protobuf field: string author_id = 11;
     */
    authorId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 90;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string created_by = 91;
     */
    createdBy: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp modified_at = 92;
     */
    modifiedAt?: Timestamp;
    /**
     * @generated from protobuf field: string modified_by = 93;
     */
    modifiedBy: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp published_at = 94;
     */
    publishedAt?: Timestamp;
    /**
     * @generated from protobuf field: string published_by = 95;
     */
    publishedBy: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.TipSlide
 */
export interface TipSlide {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: uint32 position = 2;
     */
    position: number;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3tRichText content = 3;
     */
    content?: Plan3tRichText;
    /**
     * @generated from protobuf field: google.protobuf.Duration duration = 4;
     */
    duration?: Duration;
}
/**
 * @generated from protobuf message one.plan3t.core.model.TipSlideDraft
 */
export interface TipSlideDraft {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: uint32 position = 2;
     */
    position: number;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3tRichText content = 3;
     */
    content?: Plan3tRichText;
    /**
     * @generated from protobuf field: google.protobuf.Duration duration = 4;
     */
    duration?: Duration;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 90;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string created_by = 91;
     */
    createdBy: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp modified_at = 92;
     */
    modifiedAt?: Timestamp;
    /**
     * @generated from protobuf field: string modified_by = 93;
     */
    modifiedBy: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.TipQuiz
 */
export interface TipQuiz {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource background_image = 2;
     */
    backgroundImage?: ImageResource;
    /**
     * @generated from protobuf field: string question = 3;
     */
    question: string;
    /**
     * @generated from protobuf field: repeated string answers = 4;
     */
    answers: string[];
    /**
     * @generated from protobuf field: uint32 correct_answer_index = 5;
     */
    correctAnswerIndex: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.TipQuizDraft
 */
export interface TipQuizDraft {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource background_image = 2;
     */
    backgroundImage?: ImageResource;
    /**
     * @generated from protobuf field: string question = 3;
     */
    question: string;
    /**
     * @generated from protobuf field: repeated string answers = 4;
     */
    answers: string[];
    /**
     * @generated from protobuf field: uint32 correct_answer_index = 5;
     */
    correctAnswerIndex: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 90;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string created_by = 91;
     */
    createdBy: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp modified_at = 92;
     */
    modifiedAt?: Timestamp;
    /**
     * @generated from protobuf field: string modified_by = 93;
     */
    modifiedBy: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.TipAuthorDraft
 */
export interface TipAuthorDraft {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string display_name = 2;
     */
    displayName: string;
    /**
     * @generated from protobuf field: string tag_line = 3;
     */
    tagLine: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource image = 4;
     */
    image?: ImageResource;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.TipAuthorDraft.SocialLink social_links = 5;
     */
    socialLinks: TipAuthorDraft_SocialLink[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 90;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string created_by = 91;
     */
    createdBy: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp modified_at = 92;
     */
    modifiedAt?: Timestamp;
    /**
     * @generated from protobuf field: string modified_by = 93;
     */
    modifiedBy: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.TipAuthorDraft.SocialLink
 */
export interface TipAuthorDraft_SocialLink {
    /**
     * @generated from protobuf field: string url = 1;
     */
    url: string;
    /**
     * @generated from protobuf field: string label = 2;
     */
    label: string;
    /**
     * @generated from protobuf field: string kind = 3;
     */
    kind: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.TipAuthor
 */
export interface TipAuthor {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string display_name = 2;
     */
    displayName: string;
    /**
     * @generated from protobuf field: string tag_line = 3;
     */
    tagLine: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource image = 4;
     */
    image?: ImageResource;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.TipAuthor.SocialLink social_links = 5;
     */
    socialLinks: TipAuthor_SocialLink[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.TipAuthor.SocialLink
 */
export interface TipAuthor_SocialLink {
    /**
     * @generated from protobuf field: string url = 1;
     */
    url: string;
    /**
     * @generated from protobuf field: string label = 2;
     */
    label: string;
    /**
     * @generated from protobuf field: string kind = 3;
     */
    kind: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class TipPreview$Type extends MessageType<TipPreview> {
    constructor() {
        super("one.plan3t.core.model.TipPreview", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tagline", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "image", kind: "message", T: () => ImageResource },
            { no: 5, name: "core_industry", kind: "enum", T: () => ["one.plan3t.core.model.CoreIndustry", CoreIndustry] },
            { no: 6, name: "category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "award", kind: "message", T: () => Plan3TPoint },
            { no: 8, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "associated_partner_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "is_new", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "author_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TipPreview>): TipPreview {
        const message = { id: "", title: "", tagline: "", coreIndustry: 0, category: "", partnerId: "", associatedPartnerIds: [], isNew: false, authorId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TipPreview>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TipPreview): TipPreview {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string tagline */ 3:
                    message.tagline = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource image */ 4:
                    message.image = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.image);
                    break;
                case /* one.plan3t.core.model.CoreIndustry core_industry */ 5:
                    message.coreIndustry = reader.int32();
                    break;
                case /* string category */ 6:
                    message.category = reader.string();
                    break;
                case /* one.plan3t.core.model.Plan3TPoint award */ 7:
                    message.award = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.award);
                    break;
                case /* string partner_id */ 8:
                    message.partnerId = reader.string();
                    break;
                case /* repeated string associated_partner_ids */ 9:
                    message.associatedPartnerIds.push(reader.string());
                    break;
                case /* bool is_new */ 10:
                    message.isNew = reader.bool();
                    break;
                case /* string author_id */ 11:
                    message.authorId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TipPreview, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string tagline = 3; */
        if (message.tagline !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tagline);
        /* one.plan3t.core.model.ImageResource image = 4; */
        if (message.image)
            ImageResource.internalBinaryWrite(message.image, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.CoreIndustry core_industry = 5; */
        if (message.coreIndustry !== 0)
            writer.tag(5, WireType.Varint).int32(message.coreIndustry);
        /* string category = 6; */
        if (message.category !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.category);
        /* one.plan3t.core.model.Plan3TPoint award = 7; */
        if (message.award)
            Plan3TPoint.internalBinaryWrite(message.award, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string partner_id = 8; */
        if (message.partnerId !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.partnerId);
        /* repeated string associated_partner_ids = 9; */
        for (let i = 0; i < message.associatedPartnerIds.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.associatedPartnerIds[i]);
        /* bool is_new = 10; */
        if (message.isNew !== false)
            writer.tag(10, WireType.Varint).bool(message.isNew);
        /* string author_id = 11; */
        if (message.authorId !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.authorId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.TipPreview
 */
export const TipPreview = new TipPreview$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TipPreviewDraft$Type extends MessageType<TipPreviewDraft> {
    constructor() {
        super("one.plan3t.core.model.TipPreviewDraft", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tagline", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "image", kind: "message", T: () => ImageResource },
            { no: 5, name: "core_industry", kind: "enum", T: () => ["one.plan3t.core.model.CoreIndustry", CoreIndustry] },
            { no: 6, name: "category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "award", kind: "message", T: () => Plan3TPoint },
            { no: 8, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "associated_partner_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "recommended_potentials", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["one.plan3t.core.model.CO2SavingPotential", CO2SavingPotential] },
            { no: 11, name: "author_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 90, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 91, name: "created_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 92, name: "modified_at", kind: "message", T: () => Timestamp },
            { no: 93, name: "modified_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 94, name: "published_at", kind: "message", T: () => Timestamp },
            { no: 95, name: "published_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TipPreviewDraft>): TipPreviewDraft {
        const message = { id: "", title: "", tagline: "", coreIndustry: 0, category: "", partnerId: "", associatedPartnerIds: [], recommendedPotentials: [], authorId: "", createdBy: "", modifiedBy: "", publishedBy: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TipPreviewDraft>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TipPreviewDraft): TipPreviewDraft {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string tagline */ 3:
                    message.tagline = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource image */ 4:
                    message.image = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.image);
                    break;
                case /* one.plan3t.core.model.CoreIndustry core_industry */ 5:
                    message.coreIndustry = reader.int32();
                    break;
                case /* string category */ 6:
                    message.category = reader.string();
                    break;
                case /* one.plan3t.core.model.Plan3TPoint award */ 7:
                    message.award = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.award);
                    break;
                case /* string partner_id */ 8:
                    message.partnerId = reader.string();
                    break;
                case /* repeated string associated_partner_ids */ 9:
                    message.associatedPartnerIds.push(reader.string());
                    break;
                case /* repeated one.plan3t.core.model.CO2SavingPotential recommended_potentials */ 10:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.recommendedPotentials.push(reader.int32());
                    else
                        message.recommendedPotentials.push(reader.int32());
                    break;
                case /* string author_id */ 11:
                    message.authorId = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 90:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string created_by */ 91:
                    message.createdBy = reader.string();
                    break;
                case /* google.protobuf.Timestamp modified_at */ 92:
                    message.modifiedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.modifiedAt);
                    break;
                case /* string modified_by */ 93:
                    message.modifiedBy = reader.string();
                    break;
                case /* google.protobuf.Timestamp published_at */ 94:
                    message.publishedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.publishedAt);
                    break;
                case /* string published_by */ 95:
                    message.publishedBy = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TipPreviewDraft, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string tagline = 3; */
        if (message.tagline !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tagline);
        /* one.plan3t.core.model.ImageResource image = 4; */
        if (message.image)
            ImageResource.internalBinaryWrite(message.image, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.CoreIndustry core_industry = 5; */
        if (message.coreIndustry !== 0)
            writer.tag(5, WireType.Varint).int32(message.coreIndustry);
        /* string category = 6; */
        if (message.category !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.category);
        /* one.plan3t.core.model.Plan3TPoint award = 7; */
        if (message.award)
            Plan3TPoint.internalBinaryWrite(message.award, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* string partner_id = 8; */
        if (message.partnerId !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.partnerId);
        /* repeated string associated_partner_ids = 9; */
        for (let i = 0; i < message.associatedPartnerIds.length; i++)
            writer.tag(9, WireType.LengthDelimited).string(message.associatedPartnerIds[i]);
        /* repeated one.plan3t.core.model.CO2SavingPotential recommended_potentials = 10; */
        if (message.recommendedPotentials.length) {
            writer.tag(10, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.recommendedPotentials.length; i++)
                writer.int32(message.recommendedPotentials[i]);
            writer.join();
        }
        /* string author_id = 11; */
        if (message.authorId !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.authorId);
        /* google.protobuf.Timestamp created_at = 90; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(90, WireType.LengthDelimited).fork(), options).join();
        /* string created_by = 91; */
        if (message.createdBy !== "")
            writer.tag(91, WireType.LengthDelimited).string(message.createdBy);
        /* google.protobuf.Timestamp modified_at = 92; */
        if (message.modifiedAt)
            Timestamp.internalBinaryWrite(message.modifiedAt, writer.tag(92, WireType.LengthDelimited).fork(), options).join();
        /* string modified_by = 93; */
        if (message.modifiedBy !== "")
            writer.tag(93, WireType.LengthDelimited).string(message.modifiedBy);
        /* google.protobuf.Timestamp published_at = 94; */
        if (message.publishedAt)
            Timestamp.internalBinaryWrite(message.publishedAt, writer.tag(94, WireType.LengthDelimited).fork(), options).join();
        /* string published_by = 95; */
        if (message.publishedBy !== "")
            writer.tag(95, WireType.LengthDelimited).string(message.publishedBy);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.TipPreviewDraft
 */
export const TipPreviewDraft = new TipPreviewDraft$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TipSlide$Type extends MessageType<TipSlide> {
    constructor() {
        super("one.plan3t.core.model.TipSlide", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "position", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "content", kind: "message", T: () => Plan3tRichText },
            { no: 4, name: "duration", kind: "message", T: () => Duration }
        ]);
    }
    create(value?: PartialMessage<TipSlide>): TipSlide {
        const message = { id: "", position: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TipSlide>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TipSlide): TipSlide {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* uint32 position */ 2:
                    message.position = reader.uint32();
                    break;
                case /* one.plan3t.core.model.Plan3tRichText content */ 3:
                    message.content = Plan3tRichText.internalBinaryRead(reader, reader.uint32(), options, message.content);
                    break;
                case /* google.protobuf.Duration duration */ 4:
                    message.duration = Duration.internalBinaryRead(reader, reader.uint32(), options, message.duration);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TipSlide, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* uint32 position = 2; */
        if (message.position !== 0)
            writer.tag(2, WireType.Varint).uint32(message.position);
        /* one.plan3t.core.model.Plan3tRichText content = 3; */
        if (message.content)
            Plan3tRichText.internalBinaryWrite(message.content, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Duration duration = 4; */
        if (message.duration)
            Duration.internalBinaryWrite(message.duration, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.TipSlide
 */
export const TipSlide = new TipSlide$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TipSlideDraft$Type extends MessageType<TipSlideDraft> {
    constructor() {
        super("one.plan3t.core.model.TipSlideDraft", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "position", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 3, name: "content", kind: "message", T: () => Plan3tRichText },
            { no: 4, name: "duration", kind: "message", T: () => Duration },
            { no: 90, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 91, name: "created_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 92, name: "modified_at", kind: "message", T: () => Timestamp },
            { no: 93, name: "modified_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TipSlideDraft>): TipSlideDraft {
        const message = { id: "", position: 0, createdBy: "", modifiedBy: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TipSlideDraft>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TipSlideDraft): TipSlideDraft {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* uint32 position */ 2:
                    message.position = reader.uint32();
                    break;
                case /* one.plan3t.core.model.Plan3tRichText content */ 3:
                    message.content = Plan3tRichText.internalBinaryRead(reader, reader.uint32(), options, message.content);
                    break;
                case /* google.protobuf.Duration duration */ 4:
                    message.duration = Duration.internalBinaryRead(reader, reader.uint32(), options, message.duration);
                    break;
                case /* google.protobuf.Timestamp created_at */ 90:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string created_by */ 91:
                    message.createdBy = reader.string();
                    break;
                case /* google.protobuf.Timestamp modified_at */ 92:
                    message.modifiedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.modifiedAt);
                    break;
                case /* string modified_by */ 93:
                    message.modifiedBy = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TipSlideDraft, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* uint32 position = 2; */
        if (message.position !== 0)
            writer.tag(2, WireType.Varint).uint32(message.position);
        /* one.plan3t.core.model.Plan3tRichText content = 3; */
        if (message.content)
            Plan3tRichText.internalBinaryWrite(message.content, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Duration duration = 4; */
        if (message.duration)
            Duration.internalBinaryWrite(message.duration, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 90; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(90, WireType.LengthDelimited).fork(), options).join();
        /* string created_by = 91; */
        if (message.createdBy !== "")
            writer.tag(91, WireType.LengthDelimited).string(message.createdBy);
        /* google.protobuf.Timestamp modified_at = 92; */
        if (message.modifiedAt)
            Timestamp.internalBinaryWrite(message.modifiedAt, writer.tag(92, WireType.LengthDelimited).fork(), options).join();
        /* string modified_by = 93; */
        if (message.modifiedBy !== "")
            writer.tag(93, WireType.LengthDelimited).string(message.modifiedBy);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.TipSlideDraft
 */
export const TipSlideDraft = new TipSlideDraft$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TipQuiz$Type extends MessageType<TipQuiz> {
    constructor() {
        super("one.plan3t.core.model.TipQuiz", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "background_image", kind: "message", T: () => ImageResource },
            { no: 3, name: "question", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "answers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "correct_answer_index", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<TipQuiz>): TipQuiz {
        const message = { id: "", question: "", answers: [], correctAnswerIndex: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TipQuiz>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TipQuiz): TipQuiz {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource background_image */ 2:
                    message.backgroundImage = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.backgroundImage);
                    break;
                case /* string question */ 3:
                    message.question = reader.string();
                    break;
                case /* repeated string answers */ 4:
                    message.answers.push(reader.string());
                    break;
                case /* uint32 correct_answer_index */ 5:
                    message.correctAnswerIndex = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TipQuiz, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* one.plan3t.core.model.ImageResource background_image = 2; */
        if (message.backgroundImage)
            ImageResource.internalBinaryWrite(message.backgroundImage, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string question = 3; */
        if (message.question !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.question);
        /* repeated string answers = 4; */
        for (let i = 0; i < message.answers.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.answers[i]);
        /* uint32 correct_answer_index = 5; */
        if (message.correctAnswerIndex !== 0)
            writer.tag(5, WireType.Varint).uint32(message.correctAnswerIndex);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.TipQuiz
 */
export const TipQuiz = new TipQuiz$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TipQuizDraft$Type extends MessageType<TipQuizDraft> {
    constructor() {
        super("one.plan3t.core.model.TipQuizDraft", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "background_image", kind: "message", T: () => ImageResource },
            { no: 3, name: "question", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "answers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "correct_answer_index", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 90, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 91, name: "created_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 92, name: "modified_at", kind: "message", T: () => Timestamp },
            { no: 93, name: "modified_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TipQuizDraft>): TipQuizDraft {
        const message = { id: "", question: "", answers: [], correctAnswerIndex: 0, createdBy: "", modifiedBy: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TipQuizDraft>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TipQuizDraft): TipQuizDraft {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource background_image */ 2:
                    message.backgroundImage = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.backgroundImage);
                    break;
                case /* string question */ 3:
                    message.question = reader.string();
                    break;
                case /* repeated string answers */ 4:
                    message.answers.push(reader.string());
                    break;
                case /* uint32 correct_answer_index */ 5:
                    message.correctAnswerIndex = reader.uint32();
                    break;
                case /* google.protobuf.Timestamp created_at */ 90:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string created_by */ 91:
                    message.createdBy = reader.string();
                    break;
                case /* google.protobuf.Timestamp modified_at */ 92:
                    message.modifiedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.modifiedAt);
                    break;
                case /* string modified_by */ 93:
                    message.modifiedBy = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TipQuizDraft, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* one.plan3t.core.model.ImageResource background_image = 2; */
        if (message.backgroundImage)
            ImageResource.internalBinaryWrite(message.backgroundImage, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string question = 3; */
        if (message.question !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.question);
        /* repeated string answers = 4; */
        for (let i = 0; i < message.answers.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.answers[i]);
        /* uint32 correct_answer_index = 5; */
        if (message.correctAnswerIndex !== 0)
            writer.tag(5, WireType.Varint).uint32(message.correctAnswerIndex);
        /* google.protobuf.Timestamp created_at = 90; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(90, WireType.LengthDelimited).fork(), options).join();
        /* string created_by = 91; */
        if (message.createdBy !== "")
            writer.tag(91, WireType.LengthDelimited).string(message.createdBy);
        /* google.protobuf.Timestamp modified_at = 92; */
        if (message.modifiedAt)
            Timestamp.internalBinaryWrite(message.modifiedAt, writer.tag(92, WireType.LengthDelimited).fork(), options).join();
        /* string modified_by = 93; */
        if (message.modifiedBy !== "")
            writer.tag(93, WireType.LengthDelimited).string(message.modifiedBy);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.TipQuizDraft
 */
export const TipQuizDraft = new TipQuizDraft$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TipAuthorDraft$Type extends MessageType<TipAuthorDraft> {
    constructor() {
        super("one.plan3t.core.model.TipAuthorDraft", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tag_line", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "image", kind: "message", T: () => ImageResource },
            { no: 5, name: "social_links", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TipAuthorDraft_SocialLink },
            { no: 90, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 91, name: "created_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 92, name: "modified_at", kind: "message", T: () => Timestamp },
            { no: 93, name: "modified_by", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TipAuthorDraft>): TipAuthorDraft {
        const message = { id: "", displayName: "", tagLine: "", socialLinks: [], createdBy: "", modifiedBy: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TipAuthorDraft>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TipAuthorDraft): TipAuthorDraft {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string display_name */ 2:
                    message.displayName = reader.string();
                    break;
                case /* string tag_line */ 3:
                    message.tagLine = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource image */ 4:
                    message.image = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.image);
                    break;
                case /* repeated one.plan3t.core.model.TipAuthorDraft.SocialLink social_links */ 5:
                    message.socialLinks.push(TipAuthorDraft_SocialLink.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp created_at */ 90:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string created_by */ 91:
                    message.createdBy = reader.string();
                    break;
                case /* google.protobuf.Timestamp modified_at */ 92:
                    message.modifiedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.modifiedAt);
                    break;
                case /* string modified_by */ 93:
                    message.modifiedBy = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TipAuthorDraft, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string display_name = 2; */
        if (message.displayName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.displayName);
        /* string tag_line = 3; */
        if (message.tagLine !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tagLine);
        /* one.plan3t.core.model.ImageResource image = 4; */
        if (message.image)
            ImageResource.internalBinaryWrite(message.image, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated one.plan3t.core.model.TipAuthorDraft.SocialLink social_links = 5; */
        for (let i = 0; i < message.socialLinks.length; i++)
            TipAuthorDraft_SocialLink.internalBinaryWrite(message.socialLinks[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp created_at = 90; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(90, WireType.LengthDelimited).fork(), options).join();
        /* string created_by = 91; */
        if (message.createdBy !== "")
            writer.tag(91, WireType.LengthDelimited).string(message.createdBy);
        /* google.protobuf.Timestamp modified_at = 92; */
        if (message.modifiedAt)
            Timestamp.internalBinaryWrite(message.modifiedAt, writer.tag(92, WireType.LengthDelimited).fork(), options).join();
        /* string modified_by = 93; */
        if (message.modifiedBy !== "")
            writer.tag(93, WireType.LengthDelimited).string(message.modifiedBy);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.TipAuthorDraft
 */
export const TipAuthorDraft = new TipAuthorDraft$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TipAuthorDraft_SocialLink$Type extends MessageType<TipAuthorDraft_SocialLink> {
    constructor() {
        super("one.plan3t.core.model.TipAuthorDraft.SocialLink", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "kind", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TipAuthorDraft_SocialLink>): TipAuthorDraft_SocialLink {
        const message = { url: "", label: "", kind: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TipAuthorDraft_SocialLink>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TipAuthorDraft_SocialLink): TipAuthorDraft_SocialLink {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                case /* string label */ 2:
                    message.label = reader.string();
                    break;
                case /* string kind */ 3:
                    message.kind = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TipAuthorDraft_SocialLink, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        /* string label = 2; */
        if (message.label !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.label);
        /* string kind = 3; */
        if (message.kind !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.kind);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.TipAuthorDraft.SocialLink
 */
export const TipAuthorDraft_SocialLink = new TipAuthorDraft_SocialLink$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TipAuthor$Type extends MessageType<TipAuthor> {
    constructor() {
        super("one.plan3t.core.model.TipAuthor", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tag_line", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "image", kind: "message", T: () => ImageResource },
            { no: 5, name: "social_links", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TipAuthor_SocialLink }
        ]);
    }
    create(value?: PartialMessage<TipAuthor>): TipAuthor {
        const message = { id: "", displayName: "", tagLine: "", socialLinks: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TipAuthor>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TipAuthor): TipAuthor {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string display_name */ 2:
                    message.displayName = reader.string();
                    break;
                case /* string tag_line */ 3:
                    message.tagLine = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource image */ 4:
                    message.image = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.image);
                    break;
                case /* repeated one.plan3t.core.model.TipAuthor.SocialLink social_links */ 5:
                    message.socialLinks.push(TipAuthor_SocialLink.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TipAuthor, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string display_name = 2; */
        if (message.displayName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.displayName);
        /* string tag_line = 3; */
        if (message.tagLine !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tagLine);
        /* one.plan3t.core.model.ImageResource image = 4; */
        if (message.image)
            ImageResource.internalBinaryWrite(message.image, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated one.plan3t.core.model.TipAuthor.SocialLink social_links = 5; */
        for (let i = 0; i < message.socialLinks.length; i++)
            TipAuthor_SocialLink.internalBinaryWrite(message.socialLinks[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.TipAuthor
 */
export const TipAuthor = new TipAuthor$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TipAuthor_SocialLink$Type extends MessageType<TipAuthor_SocialLink> {
    constructor() {
        super("one.plan3t.core.model.TipAuthor.SocialLink", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "kind", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TipAuthor_SocialLink>): TipAuthor_SocialLink {
        const message = { url: "", label: "", kind: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TipAuthor_SocialLink>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TipAuthor_SocialLink): TipAuthor_SocialLink {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                case /* string label */ 2:
                    message.label = reader.string();
                    break;
                case /* string kind */ 3:
                    message.kind = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TipAuthor_SocialLink, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        /* string label = 2; */
        if (message.label !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.label);
        /* string kind = 3; */
        if (message.kind !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.kind);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.TipAuthor.SocialLink
 */
export const TipAuthor_SocialLink = new TipAuthor_SocialLink$Type();
