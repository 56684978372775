import * as React from 'react';
import { AppState } from '../redux/AppStore';
import { partnerActions, partnerSelectors } from './store';
import { connect, ConnectedProps } from 'react-redux';
import { Button } from '../common/Button';

type OwnProps = {
    partnerId: string;
};

const mapStateToProps = (state: AppState, props: OwnProps) => ({
    state: partnerSelectors.impersonationState(state, props),
});

const connector = connect(mapStateToProps, {
    impersonate: partnerActions.impersonatePartner,
});

type Props = OwnProps & ConnectedProps<typeof connector>;

const ImpersonatePartnerButtonInner: React.FunctionComponent<Props> = (
    props,
) => {
    const onClick = () => {
        props.impersonate({
            partnerId: props.partnerId,
        });
    };

    const label =
        props.state === undefined
            ? 'Impersonate'
            : {
                  pending: '...',
                  success: 'Done',
                  failed: 'ERROR',
              }[props.state];

    return (
        <Button
            label={label}
            secondary
            onClick={props.state === undefined ? onClick : undefined}
        />
    );
};

export default connector(ImpersonatePartnerButtonInner);
