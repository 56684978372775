import React from 'react';
import { usePartnerProfileList } from '../partner/store';
import { FormMultiSelectField, FormSelectField } from './FormFields';

type Props = {
    name: string;
    label: string;
    single?: boolean;
    fieldNamePrefix?: string;
};

const FormPartnerAssociationField: React.FunctionComponent<Props> = (props) => {
    const profiles = usePartnerProfileList();

    const name = props.fieldNamePrefix
        ? `${props.fieldNamePrefix}.${props.name}`
        : props.name;

    const options = profiles.map((partner) => ({
        label: partner.name,
        value: partner.id,
    }));

    if (props.single) {
        return (
            <FormSelectField
                name={name}
                label={props.label}
                options={[{ label: 'None', value: '' }, ...options]}
            />
        );
    } else {
        return (
            <FormMultiSelectField
                name={name}
                label={props.label}
                options={options}
            />
        );
    }
};

export default FormPartnerAssociationField;
