import { CrudSlice } from './store';
import { Button } from '../common/Button';
import { useState } from 'react';
import { useAppDispatch } from '../redux/react';

type Props<T> = {
    module: CrudSlice<T>;
    item: T;
};

export default function ItemDeletionButton<T>(props: Props<T>) {
    const dispatch = useAppDispatch();

    const [isWorking, setIsWorking] = useState(false);

    const onDelete = async () => {
        const v = Math.floor(Math.random() * 10).toString();
        const a = window.prompt(`Type "${v}" to confirm deletion.`);
        if (v !== a) {
            alert('Incorrect check code. Aborted.');
            return;
        }
        try {
            setIsWorking(true);
            await dispatch(props.module.actions.deleteItem(props.item));
        } catch (e) {
            setIsWorking(false);
            throw e;
        }
    };

    return (
        <Button
            secondary
            onClick={onDelete}
            label={!isWorking ? 'Delete' : '...'}
        />
    );
}
