// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "model/b2c.proto" (package "one.plan3t.core.model", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { TransactionCo2EModifier } from "./transaction_co2e_modifier";
import { Duration } from "../google/protobuf/duration";
import { Any } from "../google/protobuf/any";
import { FeedItem } from "./feed";
import { ChallengePreview } from "./core";
import { TipAuthor } from "./tip";
import { CO2SavingPotential } from "./core";
import { TipQuiz } from "./tip";
import { TipSlide } from "./tip";
import { TipPreview } from "./tip";
import { RewardBillingType } from "./core";
import { CoreIndustry } from "./core";
import { LotteryTicket } from "./core";
import { ImageResource } from "./core";
import { NotificationAction } from "./core";
import { CompensateProject } from "./core";
import { Date } from "./common";
import { Balance } from "./core";
import { Plan3TPoint } from "./core";
import { Timestamp } from "../google/protobuf/timestamp";
/**
 * @generated from protobuf message one.plan3t.core.model.PostProfilingResultRequest
 */
export interface PostProfilingResultRequest {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 1;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: string jsonBlob = 2;
     */
    jsonBlob: string;
    /**
     * @generated from protobuf field: string transactionId = 3;
     */
    transactionId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostProfilingResultResponse
 */
export interface PostProfilingResultResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint earned = 1;
     */
    earned?: Plan3TPoint;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetProfilingResultRequest
 */
export interface GetProfilingResultRequest {
    /**
     * @generated from protobuf field: string user_id = 1;
     */
    userId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetProfilingResultResponse
 */
export interface GetProfilingResultResponse {
    /**
     * @generated from protobuf field: string jsonBlob = 1;
     */
    jsonBlob: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetBalanceRequest
 */
export interface GetBalanceRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetBalanceResponse
 */
export interface GetBalanceResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.Balance balance = 1;
     */
    balance?: Balance;
}
/**
 * frontend specific 'wrapper' around 'accounting.Command'
 *
 * @generated from protobuf message one.plan3t.core.model.UserBalanceChangeEntry
 */
export interface UserBalanceChangeEntry {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint amount = 3;
     */
    amount?: Plan3TPoint;
    /**
     * one of {rewardId, compensateProjectId, challengeId, profiling, referralBonus, tipId, ...}
     *
     * @generated from protobuf field: string rewardId = 11;
     */
    rewardId: string;
    /**
     * @generated from protobuf field: string compensateProjectId = 12;
     */
    compensateProjectId: string;
    /**
     * @generated from protobuf field: string challengeId = 13;
     */
    challengeId: string;
    /**
     * @generated from protobuf field: bool profiling = 14;
     */
    profiling: boolean;
    /**
     * @generated from protobuf field: bool referralBonus = 15;
     */
    referralBonus: boolean;
    /**
     * @generated from protobuf field: bool voucher = 16;
     */
    voucher: boolean;
    /**
     * @generated from protobuf field: string lotteryDrawingItemId = 17;
     */
    lotteryDrawingItemId: string;
    /**
     * @generated from protobuf field: string tipId = 18;
     */
    tipId: string;
    /**
     * @generated from protobuf field: string compensateWheelSpinId = 19;
     */
    compensateWheelSpinId: string;
    /**
     * @generated from protobuf field: string partnerId = 20;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: bool open_banking_welcome_bonus = 21;
     */
    openBankingWelcomeBonus: boolean;
    /**
     * @generated from protobuf field: string donationReceiptId = 22;
     */
    donationReceiptId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetBalanceChangesRequest
 */
export interface GetBalanceChangesRequest {
    /**
     * @generated from protobuf field: string user_id = 1;
     */
    userId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp before = 2;
     */
    before?: Timestamp;
    /**
     * @generated from protobuf field: int32 limit = 3;
     */
    limit: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetBalanceChangesResponse
 */
export interface GetBalanceChangesResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.UserBalanceChangeEntry entries = 1;
     */
    entries: UserBalanceChangeEntry[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetBalanceChangesSummaryRequest
 */
export interface GetBalanceChangesSummaryRequest {
    /**
     * @generated from protobuf field: string user_id = 1;
     */
    userId: string;
    /**
     * start_date includes txs on that day, starting at midnight
     * if this field would be a timestamp, it would have the time 00:00:59
     *
     * @generated from protobuf field: one.plan3t.core.model.Date start_date = 2;
     */
    startDate?: Date;
    /**
     * start_date includes txs on that day as well, until midnight
     * if this field would be a timestamp, it would have the time 23:59:59.999999999
     *
     * @generated from protobuf field: one.plan3t.core.model.Date end_date = 3;
     */
    endDate?: Date;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetBalanceChangesSummaryResponse
 */
export interface GetBalanceChangesSummaryResponse {
    /**
     * @generated from protobuf field: int32 num_transactions = 1;
     */
    numTransactions: number;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint earned = 2;
     */
    earned?: Plan3TPoint;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint spent = 3;
     */
    spent?: Plan3TPoint;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostBuyRewardRequest
 */
export interface PostBuyRewardRequest {
    /**
     * @generated from protobuf field: string transactionId = 1;
     */
    transactionId: string;
    /**
     * @generated from protobuf field: string partnerId = 2;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string userId = 3;
     */
    userId: string;
    /**
     * @generated from protobuf field: string lastEventId = 4;
     */
    lastEventId: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint points = 5;
     */
    points?: Plan3TPoint;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostBuyRewardResponse
 */
export interface PostBuyRewardResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostStartPurchaseCompensationRequest
 */
export interface PostStartPurchaseCompensationRequest {
    /**
     * @generated from protobuf field: string checkoutId = 1;
     */
    checkoutId: string;
    /**
     * @generated from protobuf field: string projectId = 2;
     */
    projectId: string;
    /**
     * @generated from protobuf field: int32 amountInKg = 3;
     */
    amountInKg: number;
    /**
     * @generated from protobuf field: string targetDate = 4;
     */
    targetDate: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostStartPurchaseCompensationResponse
 */
export interface PostStartPurchaseCompensationResponse {
    /**
     * @generated from protobuf field: string stripeClientSecret = 1;
     */
    stripeClientSecret: string;
    /**
     * @generated from protobuf field: string currency = 2;
     */
    currency: string;
    /**
     * @generated from protobuf field: int32 total = 3;
     */
    total: number;
    /**
     * @generated from protobuf field: string targetDate = 4;
     */
    targetDate: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostCompletePurchaseCompensationRequest
 */
export interface PostCompletePurchaseCompensationRequest {
    /**
     * @generated from protobuf field: string checkoutId = 1;
     */
    checkoutId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostCompletePurchaseCompensationResponse
 */
export interface PostCompletePurchaseCompensationResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint earned = 1;
     */
    earned?: Plan3TPoint;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetCompensateProjectsRequest
 */
export interface GetCompensateProjectsRequest {
    /**
     * @generated from protobuf field: int32 limit = 1;
     */
    limit: number;
    /**
     * @generated from protobuf field: string after = 2;
     */
    after: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetCompensateProjectsResponse
 */
export interface GetCompensateProjectsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.CompensateProject projects = 1;
     */
    projects: CompensateProject[];
    /**
     * @generated from protobuf field: repeated string featured_order = 2;
     */
    featuredOrder: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.CompensatePurchase
 */
export interface CompensatePurchase {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string userId = 2;
     */
    userId: string;
    /**
     * @generated from protobuf field: string projectId = 3;
     */
    projectId: string;
    /**
     * @generated from protobuf field: int32 amountInKg = 4;
     */
    amountInKg: number;
    /**
     * @generated from protobuf field: bool completed = 5;
     */
    completed: boolean;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint points = 6;
     */
    points?: Plan3TPoint;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp createdAt = 7;
     */
    createdAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp modifiedAt = 8;
     */
    modifiedAt?: Timestamp;
    /**
     * @generated from protobuf field: string targetDate = 9;
     */
    targetDate: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetCompensatePurchasesRequest
 */
export interface GetCompensatePurchasesRequest {
    /**
     * @generated from protobuf field: string user_id = 1;
     */
    userId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetCompensatePurchasesResponse
 */
export interface GetCompensatePurchasesResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.CompensatePurchase purchases = 1;
     */
    purchases: CompensatePurchase[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.RemoteConfigValue
 */
export interface RemoteConfigValue {
    /**
     * @generated from protobuf field: int32 _mask = 1;
     */
    Mask: number;
    /**
     * @generated from protobuf field: bool boolVal = 10;
     */
    boolVal: boolean;
    /**
     * @generated from protobuf field: string stringVal = 11;
     */
    stringVal: string;
    /**
     * @generated from protobuf field: bytes bytesVal = 12;
     */
    bytesVal: Uint8Array;
    /**
     * @generated from protobuf field: double doubleVal = 13;
     */
    doubleVal: number;
    /**
     * @generated from protobuf field: int32 int32Val = 14;
     */
    int32Val: number;
    /**
     * @generated from protobuf field: int64 int64Val = 15;
     */
    int64Val: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRemoteConfigRequest
 */
export interface GetRemoteConfigRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRemoteConfigResponse
 */
export interface GetRemoteConfigResponse {
    /**
     * @generated from protobuf field: map<string, one.plan3t.core.model.RemoteConfigValue> remoteConfig = 1;
     */
    remoteConfig: {
        [key: string]: RemoteConfigValue;
    };
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostRegisterNotificationTokenRequest
 */
export interface PostRegisterNotificationTokenRequest {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
    /**
     * @generated from protobuf field: string userId = 2;
     */
    userId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostRegisterNotificationTokenResponse
 */
export interface PostRegisterNotificationTokenResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostStartChallengeRequest
 */
export interface PostStartChallengeRequest {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp started_at = 1;
     */
    startedAt?: Timestamp;
    /**
     * @generated from protobuf field: string challengeId = 2;
     */
    challengeId: string;
    /**
     * @generated from protobuf field: int32 goal = 3;
     */
    goal: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 4;
     */
    createdAt?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostStartChallengeResponse
 */
export interface PostStartChallengeResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.StartedChallenge
 */
export interface StartedChallenge {
    /**
     * @generated from protobuf field: string challengeId = 1;
     */
    challengeId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp started_at = 2;
     */
    startedAt?: Timestamp;
    /**
     * @generated from protobuf field: int32 goal = 3;
     */
    goal: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 4;
     */
    createdAt?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetStartedChallengesResponse
 */
export interface GetStartedChallengesResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.StartedChallenge startedChallenges = 1;
     */
    startedChallenges: StartedChallenge[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostTrackChallengeRequest
 */
export interface PostTrackChallengeRequest {
    /**
     * @generated from protobuf field: bool success = 1;
     */
    success: boolean;
    /**
     * @generated from protobuf field: string transactionId = 2;
     */
    transactionId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timestamp = 3;
     */
    timestamp?: Timestamp;
    /**
     * @generated from protobuf field: string targetDate = 4;
     */
    targetDate: string;
    /**
     * @generated from protobuf field: int32 goal = 5;
     */
    goal: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostTrackChallengeResponse
 */
export interface PostTrackChallengeResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.HabitTrackingState
 */
export interface HabitTrackingState {
    /**
     * @generated from protobuf field: string challengeId = 1;
     */
    challengeId: string;
    /**
     * @generated from protobuf field: bool success = 2;
     */
    success: boolean;
    /**
     * @generated from protobuf field: string transactionId = 3;
     */
    transactionId: string;
    /**
     * @generated from protobuf field: string targetDate = 4;
     */
    targetDate: string;
    /**
     * @generated from protobuf field: int32 goal = 5;
     */
    goal: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetHabitTrackingStatesResponse
 */
export interface GetHabitTrackingStatesResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.HabitTrackingState habitStates = 1;
     */
    habitStates: HabitTrackingState[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.BonusPayout
 */
export interface BonusPayout {
    /**
     * @generated from protobuf field: string challengeId = 1;
     */
    challengeId: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint amount = 2;
     */
    amount?: Plan3TPoint;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint streakAmount = 3;
     */
    streakAmount?: Plan3TPoint;
    /**
     * @generated from protobuf field: string targetDate = 4;
     */
    targetDate: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.LockedChallenge
 */
export interface LockedChallenge {
    /**
     * @generated from protobuf field: string challengeId = 1;
     */
    challengeId: string;
    /**
     * @generated from protobuf field: string targetDate = 2;
     */
    targetDate: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ChallengeBonusRequest
 */
export interface ChallengeBonusRequest {
    /**
     * @generated from protobuf field: string challengeId = 1;
     */
    challengeId: string;
    /**
     * @generated from protobuf field: string transactionId = 2;
     */
    transactionId: string;
    /**
     * @generated from protobuf field: string targetDate = 3;
     */
    targetDate: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostClaimChallengeBonusRequest
 */
export interface PostClaimChallengeBonusRequest {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ChallengeBonusRequest requests = 1;
     */
    requests: ChallengeBonusRequest[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostClaimChallengeBonusResponse
 */
export interface PostClaimChallengeBonusResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.BonusPayout bonuses = 1;
     */
    bonuses: BonusPayout[];
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.LockedChallenge lockedChallenges = 2;
     */
    lockedChallenges: LockedChallenge[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.NotificationChannelStatus
 */
export interface NotificationChannelStatus {
    /**
     * @generated from protobuf field: string channelId = 1;
     */
    channelId: string;
    /**
     * @generated from protobuf field: bool enabled = 2;
     */
    enabled: boolean;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp syncedAt = 3;
     */
    syncedAt?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostWriteNotificationChannelStatusRequest
 */
export interface PostWriteNotificationChannelStatusRequest {
    /**
     * @generated from protobuf field: one.plan3t.core.model.NotificationChannelStatus status = 1;
     */
    status?: NotificationChannelStatus;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PostWriteNotificationChannelStatusResponse
 */
export interface PostWriteNotificationChannelStatusResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetNotificationChannelStatusRequest
 */
export interface GetNotificationChannelStatusRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetNotificationChannelStatusResponse
 */
export interface GetNotificationChannelStatusResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.NotificationChannelStatus status = 1;
     */
    status: NotificationChannelStatus[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetChallengeStartedCountResponse
 */
export interface GetChallengeStartedCountResponse {
    /**
     * @generated from protobuf field: map<string, int32> counts = 1;
     */
    counts: {
        [key: string]: number;
    };
}
/**
 * @generated from protobuf message one.plan3t.core.model.NotificationMessage
 */
export interface NotificationMessage {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string channelId = 3;
     */
    channelId: string;
    /**
     * @generated from protobuf field: string title = 4;
     */
    title: string;
    /**
     * @generated from protobuf field: string body = 5;
     */
    body: string;
    /**
     * @generated from protobuf field: string category = 6;
     */
    category: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.NotificationAction action = 7;
     */
    action?: NotificationAction;
    /**
     * @generated from protobuf field: map<string, string> payload = 8;
     */
    payload: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timestamp = 9;
     */
    timestamp?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp seenAt = 10;
     */
    seenAt?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetNotificationsRequest
 */
export interface GetNotificationsRequest {
    /**
     * @generated from protobuf field: string userId = 1;
     */
    userId: string;
    /**
     * @generated from protobuf field: int32 limit = 2;
     */
    limit: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp after = 3;
     */
    after?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetNotificationsResponse
 */
export interface GetNotificationsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.NotificationMessage notifications = 1;
     */
    notifications: NotificationMessage[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.AddNotificationsRequest
 */
export interface AddNotificationsRequest {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.NotificationMessage messages = 1;
     */
    messages: NotificationMessage[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.AddNotificationsResponse
 */
export interface AddNotificationsResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetIndividualReferralCodeRequest
 */
export interface GetIndividualReferralCodeRequest {
    /**
     * @generated from protobuf field: string user_id = 1;
     */
    userId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetIndividualReferralCodeResponse
 */
export interface GetIndividualReferralCodeResponse {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.RegisterReferralCodeUsageRequest
 */
export interface RegisterReferralCodeUsageRequest {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.RegisterReferralCodeUsageResponse
 */
export interface RegisterReferralCodeUsageResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.ClaimReferralBonusRequest
 */
export interface ClaimReferralBonusRequest {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
    /**
     * @generated from protobuf field: string device_id = 2;
     */
    deviceId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ClaimReferralBonusResponse
 */
export interface ClaimReferralBonusResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint amount = 1;
     */
    amount?: Plan3TPoint;
}
/**
 * @generated from protobuf message one.plan3t.core.model.CheckReferralCodeRequest
 */
export interface CheckReferralCodeRequest {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.CheckReferralCodeResponse
 */
export interface CheckReferralCodeResponse {
    /**
     * @generated from protobuf field: bool valid = 1;
     */
    valid: boolean;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetIndividualReferralReportRequest
 */
export interface GetIndividualReferralReportRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.ReferralSpecialBonus
 */
export interface ReferralSpecialBonus {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: int32 requiredReferrals = 2;
     */
    requiredReferrals: number;
    /**
     * @generated from protobuf field: string label = 3;
     */
    label: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource image = 4;
     */
    image?: ImageResource;
    /**
     * @generated from protobuf field: string labelLong = 5;
     */
    labelLong: string;
    /**
     * @generated from protobuf field: string description = 6;
     */
    description: string;
    /**
     * @generated from protobuf field: string icon_name = 7;
     */
    iconName: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ReferralBonusTier
 */
export interface ReferralBonusTier {
    /**
     * @generated from protobuf field: int32 required_referrals = 1;
     */
    requiredReferrals: number;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: int32 amount = 3;
     */
    amount: number;
    /**
     * @generated from protobuf field: string icon_name = 4;
     */
    iconName: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetIndividualReferralReportResponse
 */
export interface GetIndividualReferralReportResponse {
    /**
     * @generated from protobuf field: int32 numReferred = 1;
     */
    numReferred: number;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ReferralSpecialBonus specialBonuses = 2;
     */
    specialBonuses: ReferralSpecialBonus[];
    /**
     * @generated from protobuf field: map<string, one.plan3t.core.model.ReferralSpecialBonusRequestState> specialBonusRequestState = 3;
     */
    specialBonusRequestState: {
        [key: string]: ReferralSpecialBonusRequestState;
    };
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ReferralBonusTier bonusTiers = 4;
     */
    bonusTiers: ReferralBonusTier[];
    /**
     * @generated from protobuf field: string shareText = 5;
     */
    shareText: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ClaimSpecialReferralBonusRequest
 */
export interface ClaimSpecialReferralBonusRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string deviceId = 2;
     */
    deviceId: string;
    /**
     * @generated from protobuf field: string specialBonusId = 3;
     */
    specialBonusId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ClaimSpecialReferralBonusResponse
 */
export interface ClaimSpecialReferralBonusResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.RedeemVoucherRequest
 */
export interface RedeemVoucherRequest {
    /**
     * @generated from protobuf field: string code = 1;
     */
    code: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.RedeemVoucherResponse
 */
export interface RedeemVoucherResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint amount = 1;
     */
    amount?: Plan3TPoint;
}
/**
 * @generated from protobuf message one.plan3t.core.model.BuyLotteryTicketRequest
 */
export interface BuyLotteryTicketRequest {
    /**
     * @generated from protobuf field: string transactionId = 1;
     */
    transactionId: string;
    /**
     * @generated from protobuf field: string priceDrawingId = 2;
     */
    priceDrawingId: string;
    /**
     * @generated from protobuf field: string lastEventId = 3;
     */
    lastEventId: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint points = 4;
     */
    points?: Plan3TPoint;
}
/**
 * @generated from protobuf message one.plan3t.core.model.BuyLotteryTicketResponse
 */
export interface BuyLotteryTicketResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetLotteryTicketsRequest
 */
export interface GetLotteryTicketsRequest {
    /**
     * @generated from protobuf field: string drawingItemId = 1;
     */
    drawingItemId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetLotteryTicketsResponse
 */
export interface GetLotteryTicketsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.LotteryTicket tickets = 1;
     */
    tickets: LotteryTicket[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.ItemCategory
 */
export interface ItemCategory {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.CoreIndustry coreIndustry = 2;
     */
    coreIndustry: CoreIndustry;
    /**
     * @generated from protobuf field: string title = 3;
     */
    title: string;
    /**
     * @generated from protobuf field: string icon_name = 4;
     */
    iconName: string;
    /**
     * @generated from protobuf field: int64 color = 5;
     */
    color: number;
    /**
     * @generated from protobuf field: bool active = 6;
     */
    active: boolean;
    /**
     * @generated from protobuf field: repeated string item_ids = 7;
     */
    itemIds: string[];
    /**
     * @generated from protobuf field: int32 numAboveTheFold = 8;
     */
    numAboveTheFold: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRewardsCategoriesRequest
 */
export interface GetRewardsCategoriesRequest {
    /**
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.RewardBillingType billing_type = 3;
     */
    billingType: RewardBillingType;
    /**
     * @generated from protobuf field: repeated string ids = 4;
     */
    ids: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRewardsCategoriesResponse
 */
export interface GetRewardsCategoriesResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ItemCategory categories = 1;
     */
    categories: ItemCategory[];
    /**
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetPartnerCategoriesRequest
 */
export interface GetPartnerCategoriesRequest {
    /**
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetPartnerCategoriesResponse
 */
export interface GetPartnerCategoriesResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ItemCategory categories = 1;
     */
    categories: ItemCategory[];
    /**
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipsCategoriesRequest
 */
export interface GetTipsCategoriesRequest {
    /**
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
    /**
     * @generated from protobuf field: repeated string ids = 3;
     */
    ids: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipsCategoriesResponse
 */
export interface GetTipsCategoriesResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ItemCategory categories = 1;
     */
    categories: ItemCategory[];
    /**
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipPreviewsRequest
 */
export interface GetTipPreviewsRequest {
    /**
     * @generated from protobuf field: repeated string ids = 1;
     */
    ids: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipPreviewsResponse
 */
export interface GetTipPreviewsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.TipPreview tips = 1;
     */
    tips: TipPreview[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipSlidesRequest
 */
export interface GetTipSlidesRequest {
    /**
     * @generated from protobuf field: string tip_id = 1;
     */
    tipId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipSlidesResponse
 */
export interface GetTipSlidesResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.TipSlide slides = 1;
     */
    slides: TipSlide[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipQuizRequest
 */
export interface GetTipQuizRequest {
    /**
     * @generated from protobuf field: string tip_id = 1;
     */
    tipId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipQuizResponse
 */
export interface GetTipQuizResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.TipQuiz quiz = 1;
     */
    quiz?: TipQuiz;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ClaimTipQuizBonusRequest
 */
export interface ClaimTipQuizBonusRequest {
    /**
     * @generated from protobuf field: string tip_id = 1;
     */
    tipId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ClaimTipQuizBonusResponse
 */
export interface ClaimTipQuizBonusResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint award = 1;
     */
    award?: Plan3TPoint;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipQuizCompletionsRequest
 */
export interface GetTipQuizCompletionsRequest {
    /**
     * @generated from protobuf field: string user_id = 1;
     */
    userId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipQuizCompletionsResponse
 */
export interface GetTipQuizCompletionsResponse {
    /**
     * @generated from protobuf field: map<string, google.protobuf.Timestamp> tip_completed_at = 1;
     */
    tipCompletedAt: {
        [key: string]: Timestamp;
    };
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRecommendedTipsRequest
 */
export interface GetRecommendedTipsRequest {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.CO2SavingPotential potentials = 1;
     */
    potentials: CO2SavingPotential[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRecommendedTipsResponse
 */
export interface GetRecommendedTipsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.TipPreview tips = 1;
     */
    tips: TipPreview[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipAuthorsRequest
 */
export interface GetTipAuthorsRequest {
    /**
     * @generated from protobuf field: repeated string ids = 1;
     */
    ids: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTipAuthorsResponse
 */
export interface GetTipAuthorsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.TipAuthor items = 1;
     */
    items: TipAuthor[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.InitOpenBankingConnectionRequest
 */
export interface InitOpenBankingConnectionRequest {
    /**
     * DE
     *
     * @generated from protobuf field: string market = 1;
     */
    market: string;
    /**
     * de_DE
     *
     * @generated from protobuf field: string locale = 2;
     */
    locale: string;
    /**
     * displayed when connecting to bank account (e.g. user name from app)
     *
     * @generated from protobuf field: string display_name = 4;
     */
    displayName: string;
    /**
     * if the capacity is too low, users can request a token to secure their spot.
     * this token can be obtained from the GetCapacityInformation rpc call
     * this token is optional, but if not present, the user must be accepted via the wait list.
     *
     * @generated from protobuf field: string token = 5;
     */
    token: string;
    /**
     * a device id can be used to ban certain devices which try to abuse our system
     * this is not fool proof, as on iOS this device might change after uninstalling the app. But should be stable
     * on android. This is just a first defense, IBAN checks are also present to prevent further abuse.
     *
     * @generated from protobuf field: string device_id = 6;
     */
    deviceId: string;
    /**
     * An aspsp_id can be used to skip the bank selection in the linking screen.
     *
     * @generated from protobuf field: string aspsp_id = 7;
     */
    aspspId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.InitOpenBankingConnectionResponse
 */
export interface InitOpenBankingConnectionResponse {
    /**
     * @generated from protobuf field: string user_authorization_code = 1;
     */
    userAuthorizationCode: string;
    /**
     * @generated from protobuf field: string state = 2;
     */
    state: string;
    /**
     * @generated from protobuf field: string tink_client_id = 3;
     */
    tinkClientId: string;
    /**
     * @generated from protobuf field: string link_url = 4;
     */
    linkUrl: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.OpenBankingASPSP
 */
export interface OpenBankingASPSP {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource logo = 3;
     */
    logo?: ImageResource;
    /**
     * @generated from protobuf field: string group_id = 4;
     */
    groupId: string;
    /**
     * @generated from protobuf field: int32 sort_index = 5;
     */
    sortIndex: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.OpenBankingASPSPGroup
 */
export interface OpenBankingASPSPGroup {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource logo = 4;
     */
    logo?: ImageResource;
    /**
     * @generated from protobuf field: int32 sort_index = 5;
     */
    sortIndex: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetOpenBankingASPSPsRequest
 */
export interface GetOpenBankingASPSPsRequest {
    /**
     * @generated from protobuf field: string market = 1;
     */
    market: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetOpenBankingASPSPsResponse
 */
export interface GetOpenBankingASPSPsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.OpenBankingASPSP aspsps = 1;
     */
    aspsps: OpenBankingASPSP[];
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.OpenBankingASPSPGroup groups = 2;
     */
    groups: OpenBankingASPSPGroup[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.CompleteInitOpenBankingConnectionRequest
 */
export interface CompleteInitOpenBankingConnectionRequest {
    /**
     * @generated from protobuf field: string credentials_id = 1;
     */
    credentialsId: string;
    /**
     * @generated from protobuf field: string state = 2;
     */
    state: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.CompleteInitOpenBankingConnectionResponse
 */
export interface CompleteInitOpenBankingConnectionResponse {
    /**
     * @generated from protobuf field: string fetch_id = 1;
     */
    fetchId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.RefreshOpenBankingConnectionRequest
 */
export interface RefreshOpenBankingConnectionRequest {
    /**
     * @generated from protobuf field: string credentials_id = 1;
     */
    credentialsId: string;
    /**
     * displayed when refreshing connection to bank account
     *
     * @generated from protobuf field: string display_name = 2;
     */
    displayName: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.RefreshOpenBankingConnectionResponse
 */
export interface RefreshOpenBankingConnectionResponse {
    /**
     * @generated from protobuf field: string user_authorization_code = 1;
     */
    userAuthorizationCode: string;
    /**
     * @generated from protobuf field: string credentials_id = 2;
     */
    credentialsId: string;
    /**
     * @generated from protobuf field: string state = 3;
     */
    state: string;
    /**
     * @generated from protobuf field: string tink_client_id = 4;
     */
    tinkClientId: string;
    /**
     * @generated from protobuf field: string link_url = 5;
     */
    linkUrl: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.CompleteRefreshOpenBankingConnectionRequest
 */
export interface CompleteRefreshOpenBankingConnectionRequest {
    /**
     * @generated from protobuf field: string credentials_id = 1;
     */
    credentialsId: string;
    /**
     * @generated from protobuf field: string state = 2;
     */
    state: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.CompleteRefreshOpenBankingConnectionResponse
 */
export interface CompleteRefreshOpenBankingConnectionResponse {
    /**
     * @generated from protobuf field: string fetch_id = 1;
     */
    fetchId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.DeleteOpenBankingConnectionRequest
 */
export interface DeleteOpenBankingConnectionRequest {
    /**
     * @generated from protobuf field: string credentials_id = 2;
     */
    credentialsId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.DeleteOpenBankingConnectionResponse
 */
export interface DeleteOpenBankingConnectionResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetOpenBankingUserStatusResponse
 */
export interface GetOpenBankingUserStatusResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.OpenBankingConnection connections = 1;
     */
    connections: OpenBankingConnection[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.OpenBankingConnection
 */
export interface OpenBankingConnection {
    /**
     * @generated from protobuf field: string display_name = 1;
     */
    displayName: string;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.OpenBankingAccount accounts = 2;
     */
    accounts: OpenBankingAccount[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp connected_since = 3;
     */
    connectedSince?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp valid_until = 4;
     */
    validUntil?: Timestamp;
    /**
     * @generated from protobuf field: string credentials_id = 5;
     */
    credentialsId: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.OpenBankingCredentialStatus status = 6;
     */
    status: OpenBankingCredentialStatus;
    /**
     * @generated from protobuf field: string statusPayload = 7;
     */
    statusPayload: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp statusUpdated = 8;
     */
    statusUpdated?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.OpenBankingAccount
 */
export interface OpenBankingAccount {
    /**
     * @generated from protobuf field: string display_name = 1;
     */
    displayName: string;
    /**
     * IBAN, BBAN, ...
     *
     * @generated from protobuf field: string account_number = 2;
     */
    accountNumber: string;
    /**
     * @generated from protobuf field: string id = 3;
     */
    id: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.OpenBankingWaitlistSignupRequest
 */
export interface OpenBankingWaitlistSignupRequest {
    /**
     * @generated from protobuf field: string email = 1;
     */
    email: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.OpenBankingWaitlistSignupOrigin origin = 2;
     */
    origin: OpenBankingWaitlistSignupOrigin;
}
/**
 * @generated from protobuf message one.plan3t.core.model.OpenBankingWaitlistSignupResponse
 */
export interface OpenBankingWaitlistSignupResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.OpenBankingWaitlistConfirmRequest
 */
export interface OpenBankingWaitlistConfirmRequest {
    /**
     * @generated from protobuf field: string confirmation_code = 1;
     */
    confirmationCode: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.OpenBankingWaitlistConfirmResponse
 */
export interface OpenBankingWaitlistConfirmResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetChallengesCategoriesRequest
 */
export interface GetChallengesCategoriesRequest {
    /**
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetChallengesCategoriesResponse
 */
export interface GetChallengesCategoriesResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ItemCategory categories = 1;
     */
    categories: ItemCategory[];
    /**
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRecommendedChallengesRequest
 */
export interface GetRecommendedChallengesRequest {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.CO2SavingPotential potentials = 1;
     */
    potentials: CO2SavingPotential[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRecommendedChallengesResponse
 */
export interface GetRecommendedChallengesResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.ChallengePreview challenges = 1;
     */
    challenges: ChallengePreview[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetOpenBankingWaitlistUserStatusResponse
 */
export interface GetOpenBankingWaitlistUserStatusResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.OpenBankingWaitlistUserStatus status = 1;
     */
    status: OpenBankingWaitlistUserStatus;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetFeedRequest
 */
export interface GetFeedRequest {
    /**
     * @generated from protobuf field: int32 page_size = 1;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string page_token = 2;
     */
    pageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetFeedResponse
 */
export interface GetFeedResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.FeedItem items = 1;
     */
    items: FeedItem[];
    /**
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.CompensateWheel
 */
export interface CompensateWheel {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp starts_at = 2;
     */
    startsAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp ends_at = 3;
     */
    endsAt?: Timestamp;
    /**
     * @generated from protobuf field: string targetDate = 4;
     */
    targetDate: string;
    /**
     * @generated from protobuf field: string title = 5;
     */
    title: string;
    /**
     * @generated from protobuf field: string tagLine = 6;
     */
    tagLine: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource image = 7;
     */
    image?: ImageResource;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource wheel_background_image = 8;
     */
    wheelBackgroundImage?: ImageResource;
    /**
     * @generated from protobuf field: string wheel_title = 9;
     */
    wheelTitle: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint price_to_spin = 10;
     */
    priceToSpin?: Plan3TPoint;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.CompensateWheelOutcome outcomes = 11;
     */
    outcomes: CompensateWheelOutcome[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.CompensateWheelOutcome
 */
export interface CompensateWheelOutcome {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string project_id = 2;
     */
    projectId: string;
    /**
     * @generated from protobuf field: double chance = 3;
     */
    chance: number;
    /**
     * @generated from protobuf field: int32 amountInKg = 4;
     */
    amountInKg: number;
    /**
     * @generated from protobuf field: string emoji = 5;
     */
    emoji: string;
    /**
     * @generated from protobuf field: string human_readable_equivalent = 6;
     */
    humanReadableEquivalent: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetCompensateWheelsRequest
 */
export interface GetCompensateWheelsRequest {
    /**
     * @generated from protobuf field: repeated string ids = 1;
     */
    ids: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetCompensateWheelsResponse
 */
export interface GetCompensateWheelsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.CompensateWheel items = 1;
     */
    items: CompensateWheel[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.BuyCompensateWheelSpinRequest
 */
export interface BuyCompensateWheelSpinRequest {
    /**
     * @generated from protobuf field: string spin_id = 1;
     */
    spinId: string;
    /**
     * @generated from protobuf field: string wheel_id = 2;
     */
    wheelId: string;
    /**
     * @generated from protobuf field: string lastEventId = 3;
     */
    lastEventId: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint points = 4;
     */
    points?: Plan3TPoint;
}
/**
 * @generated from protobuf message one.plan3t.core.model.BuyCompensateWheelSpinResponse
 */
export interface BuyCompensateWheelSpinResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.CompensateWheelSpin result = 1;
     */
    result?: CompensateWheelSpin;
}
/**
 * @generated from protobuf message one.plan3t.core.model.CompensateWheelSpin
 */
export interface CompensateWheelSpin {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint points = 2;
     */
    points?: Plan3TPoint;
    /**
     * @generated from protobuf field: string compensate_wheel_id = 3;
     */
    compensateWheelId: string;
    /**
     * @generated from protobuf field: string compensate_wheel_outcome_id = 4;
     */
    compensateWheelOutcomeId: string;
    /**
     * @generated from protobuf field: string target_date = 5;
     */
    targetDate: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 6;
     */
    createdAt?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetCompensateWheelSpinsRequest
 */
export interface GetCompensateWheelSpinsRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetCompensateWheelSpinsResponse
 */
export interface GetCompensateWheelSpinsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.CompensateWheelSpin spins = 1;
     */
    spins: CompensateWheelSpin[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.DeleteAccountRequest
 */
export interface DeleteAccountRequest {
    /**
     * @generated from protobuf field: one.plan3t.core.model.UserAccountType type = 1;
     */
    type: UserAccountType;
}
/**
 * @generated from protobuf message one.plan3t.core.model.DeleteAccountResponse
 */
export interface DeleteAccountResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.ReportMissingCashbackRequest
 */
export interface ReportMissingCashbackRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp purchase_date = 2;
     */
    purchaseDate?: Timestamp;
    /**
     * @generated from protobuf field: string partner_id = 3;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string account_id = 4;
     */
    accountId: string;
    /**
     * @generated from protobuf field: int32 amount_unscaled = 5;
     */
    amountUnscaled: number;
    /**
     * @generated from protobuf field: int32 amount_scale = 6;
     */
    amountScale: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ReportMissingCashbackResponse
 */
export interface ReportMissingCashbackResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.OpenBankingTransaction
 */
export interface OpenBankingTransaction {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string user_id = 2;
     */
    userId: string;
    /**
     * @generated from protobuf field: string account_id = 3;
     */
    accountId: string;
    /**
     * @generated from protobuf field: int32 transaction_amount_unscaled = 4;
     */
    transactionAmountUnscaled: number;
    /**
     * @generated from protobuf field: int32 transaction_amount_scale = 5;
     */
    transactionAmountScale: number;
    /**
     * @generated from protobuf field: string transaction_description = 6;
     */
    transactionDescription: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp transaction_date = 7;
     */
    transactionDate?: Timestamp;
    /**
     * @generated from protobuf field: one.plan3t.core.model.OpenBankingTransaction.CategoryClassification category = 10;
     */
    category?: OpenBankingTransaction_CategoryClassification;
    /**
     * @generated from protobuf field: one.plan3t.core.model.OpenBankingTransaction.CashbackClassification cashback = 11;
     */
    cashback?: OpenBankingTransaction_CashbackClassification;
    /**
     * @generated from protobuf field: one.plan3t.core.model.OpenBankingTransaction.Co2EClassification co2e = 12 [json_name = "co2e"];
     */
    co2E?: OpenBankingTransaction_Co2EClassification;
    /**
     * @generated from protobuf field: one.plan3t.core.model.OpenBankingTransaction.PartnerClassification partner = 13;
     */
    partner?: OpenBankingTransaction_PartnerClassification;
    /**
     * @generated from protobuf field: map<string, google.protobuf.Any> modifier_choices = 14;
     */
    modifierChoices: {
        [key: string]: Any;
    };
}
/**
 * @generated from protobuf message one.plan3t.core.model.OpenBankingTransaction.CategoryClassification
 */
export interface OpenBankingTransaction_CategoryClassification {
    /**
     * @generated from protobuf field: one.plan3t.core.model.OpenBankingTransaction.ClassificationState state = 1;
     */
    state: OpenBankingTransaction_ClassificationState;
    /**
     * @generated from protobuf field: string category_id = 2;
     */
    categoryId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.OpenBankingTransaction.CashbackClassification
 */
export interface OpenBankingTransaction_CashbackClassification {
    /**
     * @generated from protobuf field: one.plan3t.core.model.OpenBankingTransaction.ClassificationState state = 1;
     */
    state: OpenBankingTransaction_ClassificationState;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint amount = 2;
     */
    amount?: Plan3TPoint;
}
/**
 * @generated from protobuf message one.plan3t.core.model.OpenBankingTransaction.Co2EClassification
 */
export interface OpenBankingTransaction_Co2EClassification {
    /**
     * @generated from protobuf field: one.plan3t.core.model.OpenBankingTransaction.ClassificationState state = 1;
     */
    state: OpenBankingTransaction_ClassificationState;
    /**
     * @generated from protobuf field: int32 amount_unscaled = 2;
     */
    amountUnscaled: number;
    /**
     * @generated from protobuf field: int32 amount_scale = 3;
     */
    amountScale: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.OpenBankingTransaction.PartnerClassification
 */
export interface OpenBankingTransaction_PartnerClassification {
    /**
     * @generated from protobuf field: one.plan3t.core.model.OpenBankingTransaction.ClassificationState state = 1;
     */
    state: OpenBankingTransaction_ClassificationState;
    /**
     * @generated from protobuf field: string partner_id = 2;
     */
    partnerId: string;
}
/**
 * @generated from protobuf enum one.plan3t.core.model.OpenBankingTransaction.ClassificationState
 */
export enum OpenBankingTransaction_ClassificationState {
    /**
     * @generated from protobuf enum value: PENDING = 0;
     */
    PENDING = 0,
    /**
     * @generated from protobuf enum value: NOT_CLASSIFIED = 1;
     */
    NOT_CLASSIFIED = 1,
    /**
     * @generated from protobuf enum value: CLASSIFIED = 2;
     */
    CLASSIFIED = 2
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetOpenBankingTransactionsRequest
 */
export interface GetOpenBankingTransactionsRequest {
    /**
     * @generated from protobuf oneof: filter
     */
    filter: {
        oneofKind: "date";
        /**
         * @generated from protobuf field: one.plan3t.core.model.Date date = 1;
         */
        date: Date;
    } | {
        oneofKind: "ids";
        /**
         * @generated from protobuf field: one.plan3t.core.model.GetOpenBankingTransactionsRequest.IdsWrapper ids = 2;
         */
        ids: GetOpenBankingTransactionsRequest_IdsWrapper;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetOpenBankingTransactionsRequest.IdsWrapper
 */
export interface GetOpenBankingTransactionsRequest_IdsWrapper {
    /**
     * @generated from protobuf field: repeated string ids = 1;
     */
    ids: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetOpenBankingTransactionsResponse
 */
export interface GetOpenBankingTransactionsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.OpenBankingTransaction transactions = 1;
     */
    transactions: OpenBankingTransaction[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetOpenBankingInfoRequest
 */
export interface GetOpenBankingInfoRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetOpenBankingInfoResponse
 */
export interface GetOpenBankingInfoResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.Date earliest_date = 1;
     */
    earliestDate?: Date;
}
/**
 * @generated from protobuf message one.plan3t.core.model.OpenBankingCategory
 */
export interface OpenBankingCategory {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: repeated string path = 2;
     */
    path: string[];
    /**
     * @generated from protobuf field: string label = 3;
     */
    label: string;
    /**
     * @generated from protobuf field: string icon_name = 4;
     */
    iconName: string;
    /**
     * @generated from protobuf field: int64 color = 5;
     */
    color: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetOpenBankingCategoriesRequest
 */
export interface GetOpenBankingCategoriesRequest {
    /**
     * @generated from protobuf field: repeated string ids = 1;
     */
    ids: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetOpenBankingCategoriesResponse
 */
export interface GetOpenBankingCategoriesResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.OpenBankingCategory categories = 1;
     */
    categories: OpenBankingCategory[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.ReclassifyOpenBankingTransactionRequest
 */
export interface ReclassifyOpenBankingTransactionRequest {
    /**
     * @generated from protobuf field: string request_id = 1;
     */
    requestId: string;
    /**
     * @generated from protobuf field: string transaction_id = 2;
     */
    transactionId: string;
    /**
     * @generated from protobuf field: string current_category_id = 3;
     */
    currentCategoryId: string;
    /**
     * @generated from protobuf field: string new_category_id = 4;
     */
    newCategoryId: string;
    /**
     * modifier_choices maps modifier_id to the underlying data message
     *
     * @generated from protobuf field: map<string, google.protobuf.Any> modifier_choices = 5;
     */
    modifierChoices: {
        [key: string]: Any;
    };
}
/**
 * @generated from protobuf message one.plan3t.core.model.ReclassifyOpenBankingTransactionResponse
 */
export interface ReclassifyOpenBankingTransactionResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetOpenBankingFetchStatusRequest
 */
export interface GetOpenBankingFetchStatusRequest {
    /**
     * @generated from protobuf field: string fetch_id = 1;
     */
    fetchId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetOpenBankingFetchStatusResponse
 */
export interface GetOpenBankingFetchStatusResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.GetOpenBankingFetchStatusResponse.State state = 1;
     */
    state: GetOpenBankingFetchStatusResponse_State;
    /**
     * @generated from protobuf field: int32 num_transactions_fetched = 2;
     */
    numTransactionsFetched: number;
    /**
     * @generated from protobuf field: int32 num_transactions_processed = 3;
     */
    numTransactionsProcessed: number;
}
/**
 * @generated from protobuf enum one.plan3t.core.model.GetOpenBankingFetchStatusResponse.State
 */
export enum GetOpenBankingFetchStatusResponse_State {
    /**
     * @generated from protobuf enum value: INITIAL = 0;
     */
    INITIAL = 0,
    /**
     * @generated from protobuf enum value: FETCHING_TRANSACTIONS = 1;
     */
    FETCHING_TRANSACTIONS = 1,
    /**
     * @generated from protobuf enum value: PROCESSING_TRANSACTIONS = 2;
     */
    PROCESSING_TRANSACTIONS = 2,
    /**
     * @generated from protobuf enum value: DONE = 3;
     */
    DONE = 3
}
/**
 * @generated from protobuf message one.plan3t.core.model.CanRequestWelcomeBonusRequest
 */
export interface CanRequestWelcomeBonusRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.CanRequestWelcomeBonusResponse
 */
export interface CanRequestWelcomeBonusResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.CanRequestWelcomeBonusResponse.Verdict verdict = 1;
     */
    verdict: CanRequestWelcomeBonusResponse_Verdict;
}
/**
 * @generated from protobuf enum one.plan3t.core.model.CanRequestWelcomeBonusResponse.Verdict
 */
export enum CanRequestWelcomeBonusResponse_Verdict {
    /**
     * @generated from protobuf enum value: NOT_CONNECTED = 0;
     */
    NOT_CONNECTED = 0,
    /**
     * @generated from protobuf enum value: ALREADY_CLAIMED = 1;
     */
    ALREADY_CLAIMED = 1,
    /**
     * @generated from protobuf enum value: CAN_CLAIM = 99;
     */
    CAN_CLAIM = 99
}
/**
 * @generated from protobuf message one.plan3t.core.model.RequestOpenBankingWelcomeBonusRequest
 */
export interface RequestOpenBankingWelcomeBonusRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.RequestOpenBankingWelcomeBonusResponse
 */
export interface RequestOpenBankingWelcomeBonusResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint amount = 1;
     */
    amount?: Plan3TPoint;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetPartnerCashbackRateRequest
 */
export interface GetPartnerCashbackRateRequest {
    /**
     * @generated from protobuf field: repeated string partner_ids = 1;
     */
    partnerIds: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetPartnerCashbackRateResponse
 */
export interface GetPartnerCashbackRateResponse {
    /**
     * @generated from protobuf field: map<string, one.plan3t.core.model.GetPartnerCashbackRateResponse.Rate> items = 1;
     */
    items: {
        [key: string]: GetPartnerCashbackRateResponse_Rate;
    };
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetPartnerCashbackRateResponse.Rate
 */
export interface GetPartnerCashbackRateResponse_Rate {
    /**
     * @generated from protobuf field: int32 rate_unscaled = 1;
     */
    rateUnscaled: number;
    /**
     * @generated from protobuf field: int32 rate_scale = 2;
     */
    rateScale: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.DonationTarget
 */
export interface DonationTarget {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp starts_at = 2;
     */
    startsAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp ends_at = 3;
     */
    endsAt?: Timestamp;
    /**
     * @generated from protobuf field: int32 max_claims = 4;
     */
    maxClaims: number;
    /**
     * @generated from protobuf field: google.protobuf.Duration max_claims_cooldown = 5;
     */
    maxClaimsCooldown?: Duration;
    /**
     * @generated from protobuf field: string title_short = 6;
     */
    titleShort: string;
    /**
     * @generated from protobuf field: string title = 7;
     */
    title: string;
    /**
     * @generated from protobuf field: string description = 8;
     */
    description: string;
    /**
     * @generated from protobuf field: string extra_benefit_explanation = 9;
     */
    extraBenefitExplanation: string;
    /**
     * @generated from protobuf field: string unit = 10;
     */
    unit: string;
    /**
     * @generated from protobuf field: string unit_icon = 11;
     */
    unitIcon: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint unit_price = 12;
     */
    unitPrice?: Plan3TPoint;
    /**
     * @generated from protobuf field: string buy_slider_template_string = 13;
     */
    buySliderTemplateString: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource image = 14;
     */
    image?: ImageResource;
}
/**
 * @generated from protobuf message one.plan3t.core.model.DonationGoal
 */
export interface DonationGoal {
    /**
     * @generated from protobuf field: string target_id = 1;
     */
    targetId: string;
    /**
     * @generated from protobuf field: int32 amount = 2;
     */
    amount: number;
    /**
     * @generated from protobuf field: int32 progress = 3;
     */
    progress: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp until = 4;
     */
    until?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.DonationReceipt
 */
export interface DonationReceipt {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string donation_target_id = 2;
     */
    donationTargetId: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint points_value = 3;
     */
    pointsValue?: Plan3TPoint;
    /**
     * @generated from protobuf field: int32 amount = 4;
     */
    amount: number;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 5;
     */
    createdAt?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetDonationTargetsRequest
 */
export interface GetDonationTargetsRequest {
    /**
     * @generated from protobuf field: repeated string ids = 1;
     */
    ids: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetDonationTargetsResponse
 */
export interface GetDonationTargetsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.DonationTarget items = 1;
     */
    items: DonationTarget[];
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.DonationGoal goals = 2;
     */
    goals: DonationGoal[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetDonationGoalsRequest
 */
export interface GetDonationGoalsRequest {
    /**
     * @generated from protobuf field: repeated string ids = 1;
     */
    ids: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetDonationGoalsResponse
 */
export interface GetDonationGoalsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.DonationGoal items = 1;
     */
    items: DonationGoal[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.DonateToTargetRequest
 */
export interface DonateToTargetRequest {
    /**
     * @generated from protobuf field: string transaction_id = 1;
     */
    transactionId: string;
    /**
     * @generated from protobuf field: string target_id = 2;
     */
    targetId: string;
    /**
     * @generated from protobuf field: int32 amount = 3;
     */
    amount: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.DonateToTargetResponse
 */
export interface DonateToTargetResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.DonationReceipt receipt = 1;
     */
    receipt?: DonationReceipt;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetDonationReceiptsRequest
 */
export interface GetDonationReceiptsRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetDonationReceiptsResponse
 */
export interface GetDonationReceiptsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.DonationReceipt receipts = 1;
     */
    receipts: DonationReceipt[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.AppDisplayKPI
 */
export interface AppDisplayKPI {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string icon = 2;
     */
    icon: string;
    /**
     * @generated from protobuf field: double amount = 3;
     */
    amount: number;
    /**
     * @generated from protobuf field: string unit = 4;
     */
    unit: string;
    /**
     * @generated from protobuf field: string title = 5;
     */
    title: string;
    /**
     * @generated from protobuf field: string title_short = 6;
     */
    titleShort: string;
    /**
     * @generated from protobuf field: string deep_link = 7;
     */
    deepLink: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource image = 8;
     */
    image?: ImageResource;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetAppDisplayKPIsRequest
 */
export interface GetAppDisplayKPIsRequest {
    /**
     * @deprecated
     * @generated from protobuf field: string tag = 1 [deprecated = true];
     */
    tag: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Date reference = 2;
     */
    reference?: Date;
    /**
     * @generated from protobuf field: repeated string tags = 3;
     */
    tags: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetAppDisplayKPIsResponse
 */
export interface GetAppDisplayKPIsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.AppDisplayKPI items = 1;
     */
    items: AppDisplayKPI[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.AssessmentScore
 */
export interface AssessmentScore {
    /**
     * @generated from protobuf field: string label = 1;
     */
    label: string;
    /**
     * @generated from protobuf field: int32 value_unscaled = 2;
     */
    valueUnscaled: number;
    /**
     * @generated from protobuf field: int32 value_scale = 3;
     */
    valueScale: number;
    /**
     * @generated from protobuf field: bool applicable = 4;
     */
    applicable: boolean;
}
/**
 * @generated from protobuf message one.plan3t.core.model.Assessment
 */
export interface Assessment {
    /**
     * @generated from protobuf field: string partner_id = 1;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string scoring_word = 2;
     */
    scoringWord: string;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.AssessmentScore scores = 3;
     */
    scores: AssessmentScore[];
    /**
     * @generated from protobuf field: string scoring_word_label = 4;
     */
    scoringWordLabel: string;
    /**
     * @generated from protobuf field: int32 score = 5;
     */
    score: number;
    /**
     * @generated from protobuf field: int32 score_max = 6;
     */
    scoreMax: number;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Assessment.MoreInfo more_info = 7;
     */
    moreInfo?: Assessment_MoreInfo;
}
/**
 * @generated from protobuf message one.plan3t.core.model.Assessment.MoreInfo
 */
export interface Assessment_MoreInfo {
    /**
     * @generated from protobuf field: string label = 1;
     */
    label: string;
    /**
     * @generated from protobuf field: string link = 2;
     */
    link: string;
    /**
     * @generated from protobuf field: string link_label = 3;
     */
    linkLabel: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetAssessmentsRequest
 */
export interface GetAssessmentsRequest {
    /**
     * @generated from protobuf field: repeated string partner_ids = 1;
     */
    partnerIds: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetAssessmentsResponse
 */
export interface GetAssessmentsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.Assessment assessments = 1;
     */
    assessments: Assessment[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.RewardCode
 */
export interface RewardCode {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string code = 2;
     */
    code: string;
    /**
     * @generated from protobuf field: string partner_id = 4;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string reward_id = 5;
     */
    rewardId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp valid_until = 6;
     */
    validUntil?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp acquired_at = 9;
     */
    acquiredAt?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRewardCodesRequest
 */
export interface GetRewardCodesRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRewardCodesResponse
 */
export interface GetRewardCodesResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.RewardCode codes = 1;
     */
    codes: RewardCode[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetOpenBankingCapacityInformationRequest
 */
export interface GetOpenBankingCapacityInformationRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetOpenBankingCapacityInformationResponse
 */
export interface GetOpenBankingCapacityInformationResponse {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
    /**
     * @generated from protobuf field: bool can_connect = 2;
     */
    canConnect: boolean;
    /**
     * @generated from protobuf field: string no_capacity_reason = 3;
     */
    noCapacityReason: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.StoreConsentGrantRequest
 */
export interface StoreConsentGrantRequest {
    /**
     * @generated from protobuf field: string consent_legal_text_id = 1;
     */
    consentLegalTextId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp granted_at = 2;
     */
    grantedAt?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.StoreConsentGrantResponse
 */
export interface StoreConsentGrantResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.RevokeConsentGrantRequest
 */
export interface RevokeConsentGrantRequest {
    /**
     * @generated from protobuf field: string consent_legal_text_id = 1;
     */
    consentLegalTextId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp revoked_at = 2;
     */
    revokedAt?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.RevokeConsentGrantResponse
 */
export interface RevokeConsentGrantResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetAvailableImpactReportsRequest
 */
export interface GetAvailableImpactReportsRequest {
    /**
     * @generated from protobuf field: int32 version = 1;
     */
    version: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetAvailableImpactReportsResponse
 */
export interface GetAvailableImpactReportsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.Date dates = 1;
     */
    dates: Date[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRelativityFactRequest
 */
export interface GetRelativityFactRequest {
    /**
     * @generated from protobuf field: one.plan3t.core.model.CoreIndustry core_industry = 1;
     */
    coreIndustry: CoreIndustry;
    /**
     * @generated from protobuf field: one.plan3t.core.model.GetRelativityFactRequest.Variant variant = 2;
     */
    variant: GetRelativityFactRequest_Variant;
    /**
     * @generated from protobuf field: int32 amount_kg_co2 = 3;
     */
    amountKgCo2: number;
}
/**
 * @generated from protobuf enum one.plan3t.core.model.GetRelativityFactRequest.Variant
 */
export enum GetRelativityFactRequest_Variant {
    /**
     * @generated from protobuf enum value: VARIANT_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: VARIANT_POSITIVE = 1;
     */
    POSITIVE = 1,
    /**
     * @generated from protobuf enum value: VARIANT_NEGATIVE = 2;
     */
    NEGATIVE = 2
}
/**
 * @generated from protobuf message one.plan3t.core.model.RelativityFact
 */
export interface RelativityFact {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * @generated from protobuf field: string subtitle = 3;
     */
    subtitle: string;
    /**
     * @generated from protobuf oneof: logo
     */
    logo: {
        oneofKind: "iconName";
        /**
         * @generated from protobuf field: string icon_name = 4;
         */
        iconName: string;
    } | {
        oneofKind: "emoji";
        /**
         * @generated from protobuf field: string emoji = 5;
         */
        emoji: string;
    } | {
        oneofKind: undefined;
    };
    /**
     * @generated from protobuf field: repeated string sources = 6;
     */
    sources: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRelativityFactResponse
 */
export interface GetRelativityFactResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.RelativityFact fact = 1;
     */
    fact?: RelativityFact;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetPrimaryClickoutRequest
 */
export interface GetPrimaryClickoutRequest {
    /**
     * @generated from protobuf field: string partner_id = 1;
     */
    partnerId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetPrimaryClickoutResponse
 */
export interface GetPrimaryClickoutResponse {
    /**
     * @generated from protobuf field: string url = 1;
     */
    url: string;
    /**
     * @generated from protobuf field: string label = 2;
     */
    label: string;
    /**
     * @generated from protobuf field: string icon_name = 3;
     */
    iconName: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTransactionCo2EModifiersRequest
 */
export interface GetTransactionCo2EModifiersRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetTransactionCo2EModifiersResponse
 */
export interface GetTransactionCo2EModifiersResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.TransactionCo2EModifier co2e_modifiers = 1 [json_name = "co2eModifiers"];
     */
    co2EModifiers: TransactionCo2EModifier[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.AddLikeRequest
 */
export interface AddLikeRequest {
    /**
     * @generated from protobuf field: string likeable_type = 1;
     */
    likeableType: string;
    /**
     * @generated from protobuf field: string likeable_id = 2;
     */
    likeableId: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.LikeRating rating = 3;
     */
    rating: LikeRating;
}
/**
 * @generated from protobuf message one.plan3t.core.model.AddLikeResponse
 */
export interface AddLikeResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetLikeRequest
 */
export interface GetLikeRequest {
    /**
     * @generated from protobuf field: string likeable_type = 1;
     */
    likeableType: string;
    /**
     * @generated from protobuf field: string likeable_id = 2;
     */
    likeableId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetLikeResponse
 */
export interface GetLikeResponse {
    /**
     * @generated from protobuf field: one.plan3t.core.model.LikeRating rating = 1;
     */
    rating: LikeRating;
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRecommendedTipsForTransactionsRequest
 */
export interface GetRecommendedTipsForTransactionsRequest {
    /**
     * @generated from protobuf field: repeated string transaction_ids = 1;
     */
    transactionIds: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.GetRecommendedTipsForTransactionsResponse
 */
export interface GetRecommendedTipsForTransactionsResponse {
    /**
     * @generated from protobuf field: repeated string tip_ids = 1;
     */
    tipIds: string[];
}
/**
 * @generated from protobuf enum one.plan3t.core.model.ReferralSpecialBonusRequestState
 */
export enum ReferralSpecialBonusRequestState {
    /**
     * @generated from protobuf enum value: REFERRAL_SPECIAL_BONUS_REQUEST_STATE_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: REFERRAL_SPECIAL_BONUS_REQUEST_STATE_REQUESTED = 1;
     */
    REQUESTED = 1,
    /**
     * @generated from protobuf enum value: REFERRAL_SPECIAL_BONUS_REQUEST_STATE_FULFILLED = 2;
     */
    FULFILLED = 2
}
/**
 * @generated from protobuf enum one.plan3t.core.model.OpenBankingCredentialStatus
 */
export enum OpenBankingCredentialStatus {
    /**
     * @generated from protobuf enum value: OPENBANKING_CREDENTIAL_STATUS_UNKNOWN = 0;
     */
    OPENBANKING_CREDENTIAL_STATUS_UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: OPENBANKING_CREDENTIAL_STATUS_FUNCTIONING = 1;
     */
    OPENBANKING_CREDENTIAL_STATUS_FUNCTIONING = 1,
    /**
     * @generated from protobuf enum value: OPENBANKING_CREDENTIAL_STATUS_ERROR = 2;
     */
    OPENBANKING_CREDENTIAL_STATUS_ERROR = 2,
    /**
     * @generated from protobuf enum value: OPENBANKING_CREDENTIAL_STATUS_EXPIRED = 3;
     */
    OPENBANKING_CREDENTIAL_STATUS_EXPIRED = 3,
    /**
     * @generated from protobuf enum value: OPENBANKING_CREDENTIAL_STATUS_DISABLED = 4;
     */
    OPENBANKING_CREDENTIAL_STATUS_DISABLED = 4
}
/**
 * @generated from protobuf enum one.plan3t.core.model.OpenBankingWaitlistSignupOrigin
 */
export enum OpenBankingWaitlistSignupOrigin {
    /**
     * @generated from protobuf enum value: OPENBANKING_WAITLIST_SIGNUP_ORIGIN_UNKNOWN = 0;
     */
    OPENBANKING_WAITLIST_SIGNUP_ORIGIN_UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: OPENBANKING_WAITLIST_SIGNUP_ORIGIN_CASHBACK = 1;
     */
    OPENBANKING_WAITLIST_SIGNUP_ORIGIN_CASHBACK = 1,
    /**
     * @generated from protobuf enum value: OPENBANKING_WAITLIST_SIGNUP_ORIGIN_COMPENSATE = 2;
     */
    OPENBANKING_WAITLIST_SIGNUP_ORIGIN_COMPENSATE = 2,
    /**
     * @generated from protobuf enum value: OPENBANKING_WAITLIST_SIGNUP_ORIGIN_TRACKING = 3;
     */
    OPENBANKING_WAITLIST_SIGNUP_ORIGIN_TRACKING = 3
}
/**
 * @generated from protobuf enum one.plan3t.core.model.OpenBankingWaitlistUserStatus
 */
export enum OpenBankingWaitlistUserStatus {
    /**
     * @generated from protobuf enum value: OPENBANKING_WAITLIST_USER_STATUS_UNKNOWN = 0;
     */
    OPENBANKING_WAITLIST_USER_STATUS_UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: OPENBANKING_WAITLIST_UNREGISTERED = 1;
     */
    OPENBANKING_WAITLIST_UNREGISTERED = 1,
    /**
     * @generated from protobuf enum value: OPENBANKING_WAITLIST_SIGNED_UP = 2;
     */
    OPENBANKING_WAITLIST_SIGNED_UP = 2,
    /**
     * @generated from protobuf enum value: OPENBANKING_WAITLIST_EMAIL_CONFIRMED = 3;
     */
    OPENBANKING_WAITLIST_EMAIL_CONFIRMED = 3,
    /**
     * @generated from protobuf enum value: OPENBANKING_WAITLIST_ACCEPTED = 4;
     */
    OPENBANKING_WAITLIST_ACCEPTED = 4
}
/**
 * @generated from protobuf enum one.plan3t.core.model.UserAccountType
 */
export enum UserAccountType {
    /**
     * @generated from protobuf enum value: USER_ACCOUNT_TYPE_B2C = 0;
     */
    B2C = 0,
    /**
     * @generated from protobuf enum value: USER_ACCOUNT_TYPE_B2B = 1;
     */
    B2B = 1,
    /**
     * @generated from protobuf enum value: USER_ACCOUNT_TYPE_BACKOFFICE = 2;
     */
    BACKOFFICE = 2
}
/**
 * @generated from protobuf enum one.plan3t.core.model.LikeRating
 */
export enum LikeRating {
    /**
     * @generated from protobuf enum value: LIKE_RATING_NONE = 0;
     */
    NONE = 0,
    /**
     * @generated from protobuf enum value: LIKE_RATING_LIKE = 1;
     */
    LIKE = 1,
    /**
     * @generated from protobuf enum value: LIKE_RATING_DISLIKE = 2;
     */
    DISLIKE = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class PostProfilingResultRequest$Type extends MessageType<PostProfilingResultRequest> {
    constructor() {
        super("one.plan3t.core.model.PostProfilingResultRequest", [
            { no: 1, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 2, name: "jsonBlob", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "transactionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PostProfilingResultRequest>): PostProfilingResultRequest {
        const message = { jsonBlob: "", transactionId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostProfilingResultRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostProfilingResultRequest): PostProfilingResultRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp created_at */ 1:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* string jsonBlob */ 2:
                    message.jsonBlob = reader.string();
                    break;
                case /* string transactionId */ 3:
                    message.transactionId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostProfilingResultRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp created_at = 1; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string jsonBlob = 2; */
        if (message.jsonBlob !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.jsonBlob);
        /* string transactionId = 3; */
        if (message.transactionId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.transactionId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostProfilingResultRequest
 */
export const PostProfilingResultRequest = new PostProfilingResultRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostProfilingResultResponse$Type extends MessageType<PostProfilingResultResponse> {
    constructor() {
        super("one.plan3t.core.model.PostProfilingResultResponse", [
            { no: 1, name: "earned", kind: "message", T: () => Plan3TPoint }
        ]);
    }
    create(value?: PartialMessage<PostProfilingResultResponse>): PostProfilingResultResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostProfilingResultResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostProfilingResultResponse): PostProfilingResultResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.Plan3TPoint earned */ 1:
                    message.earned = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.earned);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostProfilingResultResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.Plan3TPoint earned = 1; */
        if (message.earned)
            Plan3TPoint.internalBinaryWrite(message.earned, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostProfilingResultResponse
 */
export const PostProfilingResultResponse = new PostProfilingResultResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProfilingResultRequest$Type extends MessageType<GetProfilingResultRequest> {
    constructor() {
        super("one.plan3t.core.model.GetProfilingResultRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetProfilingResultRequest>): GetProfilingResultRequest {
        const message = { userId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProfilingResultRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProfilingResultRequest): GetProfilingResultRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_id */ 1:
                    message.userId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProfilingResultRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user_id = 1; */
        if (message.userId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetProfilingResultRequest
 */
export const GetProfilingResultRequest = new GetProfilingResultRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProfilingResultResponse$Type extends MessageType<GetProfilingResultResponse> {
    constructor() {
        super("one.plan3t.core.model.GetProfilingResultResponse", [
            { no: 1, name: "jsonBlob", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetProfilingResultResponse>): GetProfilingResultResponse {
        const message = { jsonBlob: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProfilingResultResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProfilingResultResponse): GetProfilingResultResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string jsonBlob */ 1:
                    message.jsonBlob = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProfilingResultResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string jsonBlob = 1; */
        if (message.jsonBlob !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.jsonBlob);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetProfilingResultResponse
 */
export const GetProfilingResultResponse = new GetProfilingResultResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBalanceRequest$Type extends MessageType<GetBalanceRequest> {
    constructor() {
        super("one.plan3t.core.model.GetBalanceRequest", []);
    }
    create(value?: PartialMessage<GetBalanceRequest>): GetBalanceRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBalanceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBalanceRequest): GetBalanceRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetBalanceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetBalanceRequest
 */
export const GetBalanceRequest = new GetBalanceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBalanceResponse$Type extends MessageType<GetBalanceResponse> {
    constructor() {
        super("one.plan3t.core.model.GetBalanceResponse", [
            { no: 1, name: "balance", kind: "message", T: () => Balance }
        ]);
    }
    create(value?: PartialMessage<GetBalanceResponse>): GetBalanceResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBalanceResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBalanceResponse): GetBalanceResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.Balance balance */ 1:
                    message.balance = Balance.internalBinaryRead(reader, reader.uint32(), options, message.balance);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBalanceResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.Balance balance = 1; */
        if (message.balance)
            Balance.internalBinaryWrite(message.balance, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetBalanceResponse
 */
export const GetBalanceResponse = new GetBalanceResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UserBalanceChangeEntry$Type extends MessageType<UserBalanceChangeEntry> {
    constructor() {
        super("one.plan3t.core.model.UserBalanceChangeEntry", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "amount", kind: "message", T: () => Plan3TPoint },
            { no: 11, name: "rewardId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "compensateProjectId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 13, name: "challengeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "profiling", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 15, name: "referralBonus", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 16, name: "voucher", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 17, name: "lotteryDrawingItemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 18, name: "tipId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "compensateWheelSpinId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "partnerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "open_banking_welcome_bonus", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 22, name: "donationReceiptId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UserBalanceChangeEntry>): UserBalanceChangeEntry {
        const message = { id: "", rewardId: "", compensateProjectId: "", challengeId: "", profiling: false, referralBonus: false, voucher: false, lotteryDrawingItemId: "", tipId: "", compensateWheelSpinId: "", partnerId: "", openBankingWelcomeBonus: false, donationReceiptId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UserBalanceChangeEntry>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UserBalanceChangeEntry): UserBalanceChangeEntry {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* one.plan3t.core.model.Plan3TPoint amount */ 3:
                    message.amount = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                case /* string rewardId */ 11:
                    message.rewardId = reader.string();
                    break;
                case /* string compensateProjectId */ 12:
                    message.compensateProjectId = reader.string();
                    break;
                case /* string challengeId */ 13:
                    message.challengeId = reader.string();
                    break;
                case /* bool profiling */ 14:
                    message.profiling = reader.bool();
                    break;
                case /* bool referralBonus */ 15:
                    message.referralBonus = reader.bool();
                    break;
                case /* bool voucher */ 16:
                    message.voucher = reader.bool();
                    break;
                case /* string lotteryDrawingItemId */ 17:
                    message.lotteryDrawingItemId = reader.string();
                    break;
                case /* string tipId */ 18:
                    message.tipId = reader.string();
                    break;
                case /* string compensateWheelSpinId */ 19:
                    message.compensateWheelSpinId = reader.string();
                    break;
                case /* string partnerId */ 20:
                    message.partnerId = reader.string();
                    break;
                case /* bool open_banking_welcome_bonus */ 21:
                    message.openBankingWelcomeBonus = reader.bool();
                    break;
                case /* string donationReceiptId */ 22:
                    message.donationReceiptId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UserBalanceChangeEntry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.Plan3TPoint amount = 3; */
        if (message.amount)
            Plan3TPoint.internalBinaryWrite(message.amount, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string rewardId = 11; */
        if (message.rewardId !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.rewardId);
        /* string compensateProjectId = 12; */
        if (message.compensateProjectId !== "")
            writer.tag(12, WireType.LengthDelimited).string(message.compensateProjectId);
        /* string challengeId = 13; */
        if (message.challengeId !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.challengeId);
        /* bool profiling = 14; */
        if (message.profiling !== false)
            writer.tag(14, WireType.Varint).bool(message.profiling);
        /* bool referralBonus = 15; */
        if (message.referralBonus !== false)
            writer.tag(15, WireType.Varint).bool(message.referralBonus);
        /* bool voucher = 16; */
        if (message.voucher !== false)
            writer.tag(16, WireType.Varint).bool(message.voucher);
        /* string lotteryDrawingItemId = 17; */
        if (message.lotteryDrawingItemId !== "")
            writer.tag(17, WireType.LengthDelimited).string(message.lotteryDrawingItemId);
        /* string tipId = 18; */
        if (message.tipId !== "")
            writer.tag(18, WireType.LengthDelimited).string(message.tipId);
        /* string compensateWheelSpinId = 19; */
        if (message.compensateWheelSpinId !== "")
            writer.tag(19, WireType.LengthDelimited).string(message.compensateWheelSpinId);
        /* string partnerId = 20; */
        if (message.partnerId !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.partnerId);
        /* bool open_banking_welcome_bonus = 21; */
        if (message.openBankingWelcomeBonus !== false)
            writer.tag(21, WireType.Varint).bool(message.openBankingWelcomeBonus);
        /* string donationReceiptId = 22; */
        if (message.donationReceiptId !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.donationReceiptId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.UserBalanceChangeEntry
 */
export const UserBalanceChangeEntry = new UserBalanceChangeEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBalanceChangesRequest$Type extends MessageType<GetBalanceChangesRequest> {
    constructor() {
        super("one.plan3t.core.model.GetBalanceChangesRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "before", kind: "message", T: () => Timestamp },
            { no: 3, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetBalanceChangesRequest>): GetBalanceChangesRequest {
        const message = { userId: "", limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBalanceChangesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBalanceChangesRequest): GetBalanceChangesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_id */ 1:
                    message.userId = reader.string();
                    break;
                case /* google.protobuf.Timestamp before */ 2:
                    message.before = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.before);
                    break;
                case /* int32 limit */ 3:
                    message.limit = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBalanceChangesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user_id = 1; */
        if (message.userId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userId);
        /* google.protobuf.Timestamp before = 2; */
        if (message.before)
            Timestamp.internalBinaryWrite(message.before, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int32 limit = 3; */
        if (message.limit !== 0)
            writer.tag(3, WireType.Varint).int32(message.limit);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetBalanceChangesRequest
 */
export const GetBalanceChangesRequest = new GetBalanceChangesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBalanceChangesResponse$Type extends MessageType<GetBalanceChangesResponse> {
    constructor() {
        super("one.plan3t.core.model.GetBalanceChangesResponse", [
            { no: 1, name: "entries", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => UserBalanceChangeEntry }
        ]);
    }
    create(value?: PartialMessage<GetBalanceChangesResponse>): GetBalanceChangesResponse {
        const message = { entries: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBalanceChangesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBalanceChangesResponse): GetBalanceChangesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.UserBalanceChangeEntry entries */ 1:
                    message.entries.push(UserBalanceChangeEntry.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBalanceChangesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.UserBalanceChangeEntry entries = 1; */
        for (let i = 0; i < message.entries.length; i++)
            UserBalanceChangeEntry.internalBinaryWrite(message.entries[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetBalanceChangesResponse
 */
export const GetBalanceChangesResponse = new GetBalanceChangesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBalanceChangesSummaryRequest$Type extends MessageType<GetBalanceChangesSummaryRequest> {
    constructor() {
        super("one.plan3t.core.model.GetBalanceChangesSummaryRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "start_date", kind: "message", T: () => Date },
            { no: 3, name: "end_date", kind: "message", T: () => Date }
        ]);
    }
    create(value?: PartialMessage<GetBalanceChangesSummaryRequest>): GetBalanceChangesSummaryRequest {
        const message = { userId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBalanceChangesSummaryRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBalanceChangesSummaryRequest): GetBalanceChangesSummaryRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_id */ 1:
                    message.userId = reader.string();
                    break;
                case /* one.plan3t.core.model.Date start_date */ 2:
                    message.startDate = Date.internalBinaryRead(reader, reader.uint32(), options, message.startDate);
                    break;
                case /* one.plan3t.core.model.Date end_date */ 3:
                    message.endDate = Date.internalBinaryRead(reader, reader.uint32(), options, message.endDate);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBalanceChangesSummaryRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user_id = 1; */
        if (message.userId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userId);
        /* one.plan3t.core.model.Date start_date = 2; */
        if (message.startDate)
            Date.internalBinaryWrite(message.startDate, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.Date end_date = 3; */
        if (message.endDate)
            Date.internalBinaryWrite(message.endDate, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetBalanceChangesSummaryRequest
 */
export const GetBalanceChangesSummaryRequest = new GetBalanceChangesSummaryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetBalanceChangesSummaryResponse$Type extends MessageType<GetBalanceChangesSummaryResponse> {
    constructor() {
        super("one.plan3t.core.model.GetBalanceChangesSummaryResponse", [
            { no: 1, name: "num_transactions", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "earned", kind: "message", T: () => Plan3TPoint },
            { no: 3, name: "spent", kind: "message", T: () => Plan3TPoint }
        ]);
    }
    create(value?: PartialMessage<GetBalanceChangesSummaryResponse>): GetBalanceChangesSummaryResponse {
        const message = { numTransactions: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetBalanceChangesSummaryResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetBalanceChangesSummaryResponse): GetBalanceChangesSummaryResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 num_transactions */ 1:
                    message.numTransactions = reader.int32();
                    break;
                case /* one.plan3t.core.model.Plan3TPoint earned */ 2:
                    message.earned = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.earned);
                    break;
                case /* one.plan3t.core.model.Plan3TPoint spent */ 3:
                    message.spent = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.spent);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetBalanceChangesSummaryResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 num_transactions = 1; */
        if (message.numTransactions !== 0)
            writer.tag(1, WireType.Varint).int32(message.numTransactions);
        /* one.plan3t.core.model.Plan3TPoint earned = 2; */
        if (message.earned)
            Plan3TPoint.internalBinaryWrite(message.earned, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.Plan3TPoint spent = 3; */
        if (message.spent)
            Plan3TPoint.internalBinaryWrite(message.spent, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetBalanceChangesSummaryResponse
 */
export const GetBalanceChangesSummaryResponse = new GetBalanceChangesSummaryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostBuyRewardRequest$Type extends MessageType<PostBuyRewardRequest> {
    constructor() {
        super("one.plan3t.core.model.PostBuyRewardRequest", [
            { no: 1, name: "transactionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "partnerId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "userId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "lastEventId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "points", kind: "message", T: () => Plan3TPoint }
        ]);
    }
    create(value?: PartialMessage<PostBuyRewardRequest>): PostBuyRewardRequest {
        const message = { transactionId: "", partnerId: "", userId: "", lastEventId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostBuyRewardRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostBuyRewardRequest): PostBuyRewardRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string transactionId */ 1:
                    message.transactionId = reader.string();
                    break;
                case /* string partnerId */ 2:
                    message.partnerId = reader.string();
                    break;
                case /* string userId */ 3:
                    message.userId = reader.string();
                    break;
                case /* string lastEventId */ 4:
                    message.lastEventId = reader.string();
                    break;
                case /* one.plan3t.core.model.Plan3TPoint points */ 5:
                    message.points = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.points);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostBuyRewardRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string transactionId = 1; */
        if (message.transactionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.transactionId);
        /* string partnerId = 2; */
        if (message.partnerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerId);
        /* string userId = 3; */
        if (message.userId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.userId);
        /* string lastEventId = 4; */
        if (message.lastEventId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.lastEventId);
        /* one.plan3t.core.model.Plan3TPoint points = 5; */
        if (message.points)
            Plan3TPoint.internalBinaryWrite(message.points, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostBuyRewardRequest
 */
export const PostBuyRewardRequest = new PostBuyRewardRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostBuyRewardResponse$Type extends MessageType<PostBuyRewardResponse> {
    constructor() {
        super("one.plan3t.core.model.PostBuyRewardResponse", []);
    }
    create(value?: PartialMessage<PostBuyRewardResponse>): PostBuyRewardResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostBuyRewardResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostBuyRewardResponse): PostBuyRewardResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: PostBuyRewardResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostBuyRewardResponse
 */
export const PostBuyRewardResponse = new PostBuyRewardResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostStartPurchaseCompensationRequest$Type extends MessageType<PostStartPurchaseCompensationRequest> {
    constructor() {
        super("one.plan3t.core.model.PostStartPurchaseCompensationRequest", [
            { no: 1, name: "checkoutId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "projectId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "amountInKg", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "targetDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PostStartPurchaseCompensationRequest>): PostStartPurchaseCompensationRequest {
        const message = { checkoutId: "", projectId: "", amountInKg: 0, targetDate: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostStartPurchaseCompensationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostStartPurchaseCompensationRequest): PostStartPurchaseCompensationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string checkoutId */ 1:
                    message.checkoutId = reader.string();
                    break;
                case /* string projectId */ 2:
                    message.projectId = reader.string();
                    break;
                case /* int32 amountInKg */ 3:
                    message.amountInKg = reader.int32();
                    break;
                case /* string targetDate */ 4:
                    message.targetDate = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostStartPurchaseCompensationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string checkoutId = 1; */
        if (message.checkoutId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.checkoutId);
        /* string projectId = 2; */
        if (message.projectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.projectId);
        /* int32 amountInKg = 3; */
        if (message.amountInKg !== 0)
            writer.tag(3, WireType.Varint).int32(message.amountInKg);
        /* string targetDate = 4; */
        if (message.targetDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.targetDate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostStartPurchaseCompensationRequest
 */
export const PostStartPurchaseCompensationRequest = new PostStartPurchaseCompensationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostStartPurchaseCompensationResponse$Type extends MessageType<PostStartPurchaseCompensationResponse> {
    constructor() {
        super("one.plan3t.core.model.PostStartPurchaseCompensationResponse", [
            { no: 1, name: "stripeClientSecret", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "currency", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "total", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "targetDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PostStartPurchaseCompensationResponse>): PostStartPurchaseCompensationResponse {
        const message = { stripeClientSecret: "", currency: "", total: 0, targetDate: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostStartPurchaseCompensationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostStartPurchaseCompensationResponse): PostStartPurchaseCompensationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string stripeClientSecret */ 1:
                    message.stripeClientSecret = reader.string();
                    break;
                case /* string currency */ 2:
                    message.currency = reader.string();
                    break;
                case /* int32 total */ 3:
                    message.total = reader.int32();
                    break;
                case /* string targetDate */ 4:
                    message.targetDate = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostStartPurchaseCompensationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string stripeClientSecret = 1; */
        if (message.stripeClientSecret !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.stripeClientSecret);
        /* string currency = 2; */
        if (message.currency !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.currency);
        /* int32 total = 3; */
        if (message.total !== 0)
            writer.tag(3, WireType.Varint).int32(message.total);
        /* string targetDate = 4; */
        if (message.targetDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.targetDate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostStartPurchaseCompensationResponse
 */
export const PostStartPurchaseCompensationResponse = new PostStartPurchaseCompensationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostCompletePurchaseCompensationRequest$Type extends MessageType<PostCompletePurchaseCompensationRequest> {
    constructor() {
        super("one.plan3t.core.model.PostCompletePurchaseCompensationRequest", [
            { no: 1, name: "checkoutId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PostCompletePurchaseCompensationRequest>): PostCompletePurchaseCompensationRequest {
        const message = { checkoutId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostCompletePurchaseCompensationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostCompletePurchaseCompensationRequest): PostCompletePurchaseCompensationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string checkoutId */ 1:
                    message.checkoutId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostCompletePurchaseCompensationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string checkoutId = 1; */
        if (message.checkoutId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.checkoutId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostCompletePurchaseCompensationRequest
 */
export const PostCompletePurchaseCompensationRequest = new PostCompletePurchaseCompensationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostCompletePurchaseCompensationResponse$Type extends MessageType<PostCompletePurchaseCompensationResponse> {
    constructor() {
        super("one.plan3t.core.model.PostCompletePurchaseCompensationResponse", [
            { no: 1, name: "earned", kind: "message", T: () => Plan3TPoint }
        ]);
    }
    create(value?: PartialMessage<PostCompletePurchaseCompensationResponse>): PostCompletePurchaseCompensationResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostCompletePurchaseCompensationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostCompletePurchaseCompensationResponse): PostCompletePurchaseCompensationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.Plan3TPoint earned */ 1:
                    message.earned = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.earned);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostCompletePurchaseCompensationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.Plan3TPoint earned = 1; */
        if (message.earned)
            Plan3TPoint.internalBinaryWrite(message.earned, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostCompletePurchaseCompensationResponse
 */
export const PostCompletePurchaseCompensationResponse = new PostCompletePurchaseCompensationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCompensateProjectsRequest$Type extends MessageType<GetCompensateProjectsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetCompensateProjectsRequest", [
            { no: 1, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "after", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCompensateProjectsRequest>): GetCompensateProjectsRequest {
        const message = { limit: 0, after: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCompensateProjectsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCompensateProjectsRequest): GetCompensateProjectsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 limit */ 1:
                    message.limit = reader.int32();
                    break;
                case /* string after */ 2:
                    message.after = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCompensateProjectsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 limit = 1; */
        if (message.limit !== 0)
            writer.tag(1, WireType.Varint).int32(message.limit);
        /* string after = 2; */
        if (message.after !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.after);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetCompensateProjectsRequest
 */
export const GetCompensateProjectsRequest = new GetCompensateProjectsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCompensateProjectsResponse$Type extends MessageType<GetCompensateProjectsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetCompensateProjectsResponse", [
            { no: 1, name: "projects", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CompensateProject },
            { no: 2, name: "featured_order", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCompensateProjectsResponse>): GetCompensateProjectsResponse {
        const message = { projects: [], featuredOrder: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCompensateProjectsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCompensateProjectsResponse): GetCompensateProjectsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.CompensateProject projects */ 1:
                    message.projects.push(CompensateProject.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string featured_order */ 2:
                    message.featuredOrder.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCompensateProjectsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.CompensateProject projects = 1; */
        for (let i = 0; i < message.projects.length; i++)
            CompensateProject.internalBinaryWrite(message.projects[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated string featured_order = 2; */
        for (let i = 0; i < message.featuredOrder.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.featuredOrder[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetCompensateProjectsResponse
 */
export const GetCompensateProjectsResponse = new GetCompensateProjectsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompensatePurchase$Type extends MessageType<CompensatePurchase> {
    constructor() {
        super("one.plan3t.core.model.CompensatePurchase", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "userId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "projectId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "amountInKg", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "completed", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 6, name: "points", kind: "message", T: () => Plan3TPoint },
            { no: 7, name: "createdAt", kind: "message", T: () => Timestamp },
            { no: 8, name: "modifiedAt", kind: "message", T: () => Timestamp },
            { no: 9, name: "targetDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CompensatePurchase>): CompensatePurchase {
        const message = { id: "", userId: "", projectId: "", amountInKg: 0, completed: false, targetDate: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompensatePurchase>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompensatePurchase): CompensatePurchase {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string userId */ 2:
                    message.userId = reader.string();
                    break;
                case /* string projectId */ 3:
                    message.projectId = reader.string();
                    break;
                case /* int32 amountInKg */ 4:
                    message.amountInKg = reader.int32();
                    break;
                case /* bool completed */ 5:
                    message.completed = reader.bool();
                    break;
                case /* one.plan3t.core.model.Plan3TPoint points */ 6:
                    message.points = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.points);
                    break;
                case /* google.protobuf.Timestamp createdAt */ 7:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp modifiedAt */ 8:
                    message.modifiedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.modifiedAt);
                    break;
                case /* string targetDate */ 9:
                    message.targetDate = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompensatePurchase, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string userId = 2; */
        if (message.userId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        /* string projectId = 3; */
        if (message.projectId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.projectId);
        /* int32 amountInKg = 4; */
        if (message.amountInKg !== 0)
            writer.tag(4, WireType.Varint).int32(message.amountInKg);
        /* bool completed = 5; */
        if (message.completed !== false)
            writer.tag(5, WireType.Varint).bool(message.completed);
        /* one.plan3t.core.model.Plan3TPoint points = 6; */
        if (message.points)
            Plan3TPoint.internalBinaryWrite(message.points, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp createdAt = 7; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp modifiedAt = 8; */
        if (message.modifiedAt)
            Timestamp.internalBinaryWrite(message.modifiedAt, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* string targetDate = 9; */
        if (message.targetDate !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.targetDate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CompensatePurchase
 */
export const CompensatePurchase = new CompensatePurchase$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCompensatePurchasesRequest$Type extends MessageType<GetCompensatePurchasesRequest> {
    constructor() {
        super("one.plan3t.core.model.GetCompensatePurchasesRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCompensatePurchasesRequest>): GetCompensatePurchasesRequest {
        const message = { userId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCompensatePurchasesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCompensatePurchasesRequest): GetCompensatePurchasesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_id */ 1:
                    message.userId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCompensatePurchasesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user_id = 1; */
        if (message.userId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetCompensatePurchasesRequest
 */
export const GetCompensatePurchasesRequest = new GetCompensatePurchasesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCompensatePurchasesResponse$Type extends MessageType<GetCompensatePurchasesResponse> {
    constructor() {
        super("one.plan3t.core.model.GetCompensatePurchasesResponse", [
            { no: 1, name: "purchases", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CompensatePurchase }
        ]);
    }
    create(value?: PartialMessage<GetCompensatePurchasesResponse>): GetCompensatePurchasesResponse {
        const message = { purchases: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCompensatePurchasesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCompensatePurchasesResponse): GetCompensatePurchasesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.CompensatePurchase purchases */ 1:
                    message.purchases.push(CompensatePurchase.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCompensatePurchasesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.CompensatePurchase purchases = 1; */
        for (let i = 0; i < message.purchases.length; i++)
            CompensatePurchase.internalBinaryWrite(message.purchases[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetCompensatePurchasesResponse
 */
export const GetCompensatePurchasesResponse = new GetCompensatePurchasesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoteConfigValue$Type extends MessageType<RemoteConfigValue> {
    constructor() {
        super("one.plan3t.core.model.RemoteConfigValue", [
            { no: 1, name: "_mask", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 10, name: "boolVal", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 11, name: "stringVal", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "bytesVal", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 13, name: "doubleVal", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 14, name: "int32Val", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 15, name: "int64Val", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<RemoteConfigValue>): RemoteConfigValue {
        const message = { Mask: 0, boolVal: false, stringVal: "", bytesVal: new Uint8Array(0), doubleVal: 0, int32Val: 0, int64Val: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RemoteConfigValue>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoteConfigValue): RemoteConfigValue {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 _mask */ 1:
                    message.Mask = reader.int32();
                    break;
                case /* bool boolVal */ 10:
                    message.boolVal = reader.bool();
                    break;
                case /* string stringVal */ 11:
                    message.stringVal = reader.string();
                    break;
                case /* bytes bytesVal */ 12:
                    message.bytesVal = reader.bytes();
                    break;
                case /* double doubleVal */ 13:
                    message.doubleVal = reader.double();
                    break;
                case /* int32 int32Val */ 14:
                    message.int32Val = reader.int32();
                    break;
                case /* int64 int64Val */ 15:
                    message.int64Val = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoteConfigValue, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 _mask = 1; */
        if (message.Mask !== 0)
            writer.tag(1, WireType.Varint).int32(message.Mask);
        /* bool boolVal = 10; */
        if (message.boolVal !== false)
            writer.tag(10, WireType.Varint).bool(message.boolVal);
        /* string stringVal = 11; */
        if (message.stringVal !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.stringVal);
        /* bytes bytesVal = 12; */
        if (message.bytesVal.length)
            writer.tag(12, WireType.LengthDelimited).bytes(message.bytesVal);
        /* double doubleVal = 13; */
        if (message.doubleVal !== 0)
            writer.tag(13, WireType.Bit64).double(message.doubleVal);
        /* int32 int32Val = 14; */
        if (message.int32Val !== 0)
            writer.tag(14, WireType.Varint).int32(message.int32Val);
        /* int64 int64Val = 15; */
        if (message.int64Val !== 0)
            writer.tag(15, WireType.Varint).int64(message.int64Val);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.RemoteConfigValue
 */
export const RemoteConfigValue = new RemoteConfigValue$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRemoteConfigRequest$Type extends MessageType<GetRemoteConfigRequest> {
    constructor() {
        super("one.plan3t.core.model.GetRemoteConfigRequest", []);
    }
    create(value?: PartialMessage<GetRemoteConfigRequest>): GetRemoteConfigRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRemoteConfigRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRemoteConfigRequest): GetRemoteConfigRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetRemoteConfigRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRemoteConfigRequest
 */
export const GetRemoteConfigRequest = new GetRemoteConfigRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRemoteConfigResponse$Type extends MessageType<GetRemoteConfigResponse> {
    constructor() {
        super("one.plan3t.core.model.GetRemoteConfigResponse", [
            { no: 1, name: "remoteConfig", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => RemoteConfigValue } }
        ]);
    }
    create(value?: PartialMessage<GetRemoteConfigResponse>): GetRemoteConfigResponse {
        const message = { remoteConfig: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRemoteConfigResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRemoteConfigResponse): GetRemoteConfigResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, one.plan3t.core.model.RemoteConfigValue> remoteConfig */ 1:
                    this.binaryReadMap1(message.remoteConfig, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: GetRemoteConfigResponse["remoteConfig"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetRemoteConfigResponse["remoteConfig"] | undefined, val: GetRemoteConfigResponse["remoteConfig"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = RemoteConfigValue.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.model.GetRemoteConfigResponse.remoteConfig");
            }
        }
        map[key ?? ""] = val ?? RemoteConfigValue.create();
    }
    internalBinaryWrite(message: GetRemoteConfigResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, one.plan3t.core.model.RemoteConfigValue> remoteConfig = 1; */
        for (let k of Object.keys(message.remoteConfig)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            RemoteConfigValue.internalBinaryWrite(message.remoteConfig[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRemoteConfigResponse
 */
export const GetRemoteConfigResponse = new GetRemoteConfigResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostRegisterNotificationTokenRequest$Type extends MessageType<PostRegisterNotificationTokenRequest> {
    constructor() {
        super("one.plan3t.core.model.PostRegisterNotificationTokenRequest", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "userId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PostRegisterNotificationTokenRequest>): PostRegisterNotificationTokenRequest {
        const message = { token: "", userId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostRegisterNotificationTokenRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostRegisterNotificationTokenRequest): PostRegisterNotificationTokenRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                case /* string userId */ 2:
                    message.userId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostRegisterNotificationTokenRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        /* string userId = 2; */
        if (message.userId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostRegisterNotificationTokenRequest
 */
export const PostRegisterNotificationTokenRequest = new PostRegisterNotificationTokenRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostRegisterNotificationTokenResponse$Type extends MessageType<PostRegisterNotificationTokenResponse> {
    constructor() {
        super("one.plan3t.core.model.PostRegisterNotificationTokenResponse", []);
    }
    create(value?: PartialMessage<PostRegisterNotificationTokenResponse>): PostRegisterNotificationTokenResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostRegisterNotificationTokenResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostRegisterNotificationTokenResponse): PostRegisterNotificationTokenResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: PostRegisterNotificationTokenResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostRegisterNotificationTokenResponse
 */
export const PostRegisterNotificationTokenResponse = new PostRegisterNotificationTokenResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostStartChallengeRequest$Type extends MessageType<PostStartChallengeRequest> {
    constructor() {
        super("one.plan3t.core.model.PostStartChallengeRequest", [
            { no: 1, name: "started_at", kind: "message", T: () => Timestamp },
            { no: 2, name: "challengeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "goal", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "created_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<PostStartChallengeRequest>): PostStartChallengeRequest {
        const message = { challengeId: "", goal: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostStartChallengeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostStartChallengeRequest): PostStartChallengeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp started_at */ 1:
                    message.startedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.startedAt);
                    break;
                case /* string challengeId */ 2:
                    message.challengeId = reader.string();
                    break;
                case /* int32 goal */ 3:
                    message.goal = reader.int32();
                    break;
                case /* google.protobuf.Timestamp created_at */ 4:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostStartChallengeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp started_at = 1; */
        if (message.startedAt)
            Timestamp.internalBinaryWrite(message.startedAt, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string challengeId = 2; */
        if (message.challengeId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.challengeId);
        /* int32 goal = 3; */
        if (message.goal !== 0)
            writer.tag(3, WireType.Varint).int32(message.goal);
        /* google.protobuf.Timestamp created_at = 4; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostStartChallengeRequest
 */
export const PostStartChallengeRequest = new PostStartChallengeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostStartChallengeResponse$Type extends MessageType<PostStartChallengeResponse> {
    constructor() {
        super("one.plan3t.core.model.PostStartChallengeResponse", []);
    }
    create(value?: PartialMessage<PostStartChallengeResponse>): PostStartChallengeResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostStartChallengeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostStartChallengeResponse): PostStartChallengeResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: PostStartChallengeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostStartChallengeResponse
 */
export const PostStartChallengeResponse = new PostStartChallengeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartedChallenge$Type extends MessageType<StartedChallenge> {
    constructor() {
        super("one.plan3t.core.model.StartedChallenge", [
            { no: 1, name: "challengeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "started_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "goal", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "created_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<StartedChallenge>): StartedChallenge {
        const message = { challengeId: "", goal: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StartedChallenge>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StartedChallenge): StartedChallenge {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string challengeId */ 1:
                    message.challengeId = reader.string();
                    break;
                case /* google.protobuf.Timestamp started_at */ 2:
                    message.startedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.startedAt);
                    break;
                case /* int32 goal */ 3:
                    message.goal = reader.int32();
                    break;
                case /* google.protobuf.Timestamp created_at */ 4:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StartedChallenge, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string challengeId = 1; */
        if (message.challengeId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.challengeId);
        /* google.protobuf.Timestamp started_at = 2; */
        if (message.startedAt)
            Timestamp.internalBinaryWrite(message.startedAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* int32 goal = 3; */
        if (message.goal !== 0)
            writer.tag(3, WireType.Varint).int32(message.goal);
        /* google.protobuf.Timestamp created_at = 4; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.StartedChallenge
 */
export const StartedChallenge = new StartedChallenge$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetStartedChallengesResponse$Type extends MessageType<GetStartedChallengesResponse> {
    constructor() {
        super("one.plan3t.core.model.GetStartedChallengesResponse", [
            { no: 1, name: "startedChallenges", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => StartedChallenge }
        ]);
    }
    create(value?: PartialMessage<GetStartedChallengesResponse>): GetStartedChallengesResponse {
        const message = { startedChallenges: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetStartedChallengesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetStartedChallengesResponse): GetStartedChallengesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.StartedChallenge startedChallenges */ 1:
                    message.startedChallenges.push(StartedChallenge.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetStartedChallengesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.StartedChallenge startedChallenges = 1; */
        for (let i = 0; i < message.startedChallenges.length; i++)
            StartedChallenge.internalBinaryWrite(message.startedChallenges[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetStartedChallengesResponse
 */
export const GetStartedChallengesResponse = new GetStartedChallengesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostTrackChallengeRequest$Type extends MessageType<PostTrackChallengeRequest> {
    constructor() {
        super("one.plan3t.core.model.PostTrackChallengeRequest", [
            { no: 1, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "transactionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "timestamp", kind: "message", T: () => Timestamp },
            { no: 4, name: "targetDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "goal", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<PostTrackChallengeRequest>): PostTrackChallengeRequest {
        const message = { success: false, transactionId: "", targetDate: "", goal: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostTrackChallengeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostTrackChallengeRequest): PostTrackChallengeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool success */ 1:
                    message.success = reader.bool();
                    break;
                case /* string transactionId */ 2:
                    message.transactionId = reader.string();
                    break;
                case /* google.protobuf.Timestamp timestamp */ 3:
                    message.timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timestamp);
                    break;
                case /* string targetDate */ 4:
                    message.targetDate = reader.string();
                    break;
                case /* int32 goal */ 5:
                    message.goal = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostTrackChallengeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool success = 1; */
        if (message.success !== false)
            writer.tag(1, WireType.Varint).bool(message.success);
        /* string transactionId = 2; */
        if (message.transactionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.transactionId);
        /* google.protobuf.Timestamp timestamp = 3; */
        if (message.timestamp)
            Timestamp.internalBinaryWrite(message.timestamp, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string targetDate = 4; */
        if (message.targetDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.targetDate);
        /* int32 goal = 5; */
        if (message.goal !== 0)
            writer.tag(5, WireType.Varint).int32(message.goal);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostTrackChallengeRequest
 */
export const PostTrackChallengeRequest = new PostTrackChallengeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostTrackChallengeResponse$Type extends MessageType<PostTrackChallengeResponse> {
    constructor() {
        super("one.plan3t.core.model.PostTrackChallengeResponse", []);
    }
    create(value?: PartialMessage<PostTrackChallengeResponse>): PostTrackChallengeResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostTrackChallengeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostTrackChallengeResponse): PostTrackChallengeResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: PostTrackChallengeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostTrackChallengeResponse
 */
export const PostTrackChallengeResponse = new PostTrackChallengeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class HabitTrackingState$Type extends MessageType<HabitTrackingState> {
    constructor() {
        super("one.plan3t.core.model.HabitTrackingState", [
            { no: 1, name: "challengeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "transactionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "targetDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "goal", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<HabitTrackingState>): HabitTrackingState {
        const message = { challengeId: "", success: false, transactionId: "", targetDate: "", goal: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<HabitTrackingState>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: HabitTrackingState): HabitTrackingState {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string challengeId */ 1:
                    message.challengeId = reader.string();
                    break;
                case /* bool success */ 2:
                    message.success = reader.bool();
                    break;
                case /* string transactionId */ 3:
                    message.transactionId = reader.string();
                    break;
                case /* string targetDate */ 4:
                    message.targetDate = reader.string();
                    break;
                case /* int32 goal */ 5:
                    message.goal = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: HabitTrackingState, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string challengeId = 1; */
        if (message.challengeId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.challengeId);
        /* bool success = 2; */
        if (message.success !== false)
            writer.tag(2, WireType.Varint).bool(message.success);
        /* string transactionId = 3; */
        if (message.transactionId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.transactionId);
        /* string targetDate = 4; */
        if (message.targetDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.targetDate);
        /* int32 goal = 5; */
        if (message.goal !== 0)
            writer.tag(5, WireType.Varint).int32(message.goal);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.HabitTrackingState
 */
export const HabitTrackingState = new HabitTrackingState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetHabitTrackingStatesResponse$Type extends MessageType<GetHabitTrackingStatesResponse> {
    constructor() {
        super("one.plan3t.core.model.GetHabitTrackingStatesResponse", [
            { no: 1, name: "habitStates", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => HabitTrackingState }
        ]);
    }
    create(value?: PartialMessage<GetHabitTrackingStatesResponse>): GetHabitTrackingStatesResponse {
        const message = { habitStates: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetHabitTrackingStatesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetHabitTrackingStatesResponse): GetHabitTrackingStatesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.HabitTrackingState habitStates */ 1:
                    message.habitStates.push(HabitTrackingState.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetHabitTrackingStatesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.HabitTrackingState habitStates = 1; */
        for (let i = 0; i < message.habitStates.length; i++)
            HabitTrackingState.internalBinaryWrite(message.habitStates[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetHabitTrackingStatesResponse
 */
export const GetHabitTrackingStatesResponse = new GetHabitTrackingStatesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BonusPayout$Type extends MessageType<BonusPayout> {
    constructor() {
        super("one.plan3t.core.model.BonusPayout", [
            { no: 1, name: "challengeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "amount", kind: "message", T: () => Plan3TPoint },
            { no: 3, name: "streakAmount", kind: "message", T: () => Plan3TPoint },
            { no: 4, name: "targetDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BonusPayout>): BonusPayout {
        const message = { challengeId: "", targetDate: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BonusPayout>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BonusPayout): BonusPayout {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string challengeId */ 1:
                    message.challengeId = reader.string();
                    break;
                case /* one.plan3t.core.model.Plan3TPoint amount */ 2:
                    message.amount = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                case /* one.plan3t.core.model.Plan3TPoint streakAmount */ 3:
                    message.streakAmount = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.streakAmount);
                    break;
                case /* string targetDate */ 4:
                    message.targetDate = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BonusPayout, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string challengeId = 1; */
        if (message.challengeId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.challengeId);
        /* one.plan3t.core.model.Plan3TPoint amount = 2; */
        if (message.amount)
            Plan3TPoint.internalBinaryWrite(message.amount, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.Plan3TPoint streakAmount = 3; */
        if (message.streakAmount)
            Plan3TPoint.internalBinaryWrite(message.streakAmount, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string targetDate = 4; */
        if (message.targetDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.targetDate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.BonusPayout
 */
export const BonusPayout = new BonusPayout$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LockedChallenge$Type extends MessageType<LockedChallenge> {
    constructor() {
        super("one.plan3t.core.model.LockedChallenge", [
            { no: 1, name: "challengeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "targetDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<LockedChallenge>): LockedChallenge {
        const message = { challengeId: "", targetDate: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LockedChallenge>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LockedChallenge): LockedChallenge {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string challengeId */ 1:
                    message.challengeId = reader.string();
                    break;
                case /* string targetDate */ 2:
                    message.targetDate = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LockedChallenge, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string challengeId = 1; */
        if (message.challengeId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.challengeId);
        /* string targetDate = 2; */
        if (message.targetDate !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.targetDate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.LockedChallenge
 */
export const LockedChallenge = new LockedChallenge$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ChallengeBonusRequest$Type extends MessageType<ChallengeBonusRequest> {
    constructor() {
        super("one.plan3t.core.model.ChallengeBonusRequest", [
            { no: 1, name: "challengeId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "transactionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "targetDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ChallengeBonusRequest>): ChallengeBonusRequest {
        const message = { challengeId: "", transactionId: "", targetDate: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ChallengeBonusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ChallengeBonusRequest): ChallengeBonusRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string challengeId */ 1:
                    message.challengeId = reader.string();
                    break;
                case /* string transactionId */ 2:
                    message.transactionId = reader.string();
                    break;
                case /* string targetDate */ 3:
                    message.targetDate = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ChallengeBonusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string challengeId = 1; */
        if (message.challengeId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.challengeId);
        /* string transactionId = 2; */
        if (message.transactionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.transactionId);
        /* string targetDate = 3; */
        if (message.targetDate !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.targetDate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ChallengeBonusRequest
 */
export const ChallengeBonusRequest = new ChallengeBonusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostClaimChallengeBonusRequest$Type extends MessageType<PostClaimChallengeBonusRequest> {
    constructor() {
        super("one.plan3t.core.model.PostClaimChallengeBonusRequest", [
            { no: 1, name: "requests", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ChallengeBonusRequest }
        ]);
    }
    create(value?: PartialMessage<PostClaimChallengeBonusRequest>): PostClaimChallengeBonusRequest {
        const message = { requests: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostClaimChallengeBonusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostClaimChallengeBonusRequest): PostClaimChallengeBonusRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.ChallengeBonusRequest requests */ 1:
                    message.requests.push(ChallengeBonusRequest.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostClaimChallengeBonusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.ChallengeBonusRequest requests = 1; */
        for (let i = 0; i < message.requests.length; i++)
            ChallengeBonusRequest.internalBinaryWrite(message.requests[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostClaimChallengeBonusRequest
 */
export const PostClaimChallengeBonusRequest = new PostClaimChallengeBonusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostClaimChallengeBonusResponse$Type extends MessageType<PostClaimChallengeBonusResponse> {
    constructor() {
        super("one.plan3t.core.model.PostClaimChallengeBonusResponse", [
            { no: 1, name: "bonuses", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => BonusPayout },
            { no: 2, name: "lockedChallenges", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LockedChallenge }
        ]);
    }
    create(value?: PartialMessage<PostClaimChallengeBonusResponse>): PostClaimChallengeBonusResponse {
        const message = { bonuses: [], lockedChallenges: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostClaimChallengeBonusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostClaimChallengeBonusResponse): PostClaimChallengeBonusResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.BonusPayout bonuses */ 1:
                    message.bonuses.push(BonusPayout.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated one.plan3t.core.model.LockedChallenge lockedChallenges */ 2:
                    message.lockedChallenges.push(LockedChallenge.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostClaimChallengeBonusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.BonusPayout bonuses = 1; */
        for (let i = 0; i < message.bonuses.length; i++)
            BonusPayout.internalBinaryWrite(message.bonuses[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated one.plan3t.core.model.LockedChallenge lockedChallenges = 2; */
        for (let i = 0; i < message.lockedChallenges.length; i++)
            LockedChallenge.internalBinaryWrite(message.lockedChallenges[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostClaimChallengeBonusResponse
 */
export const PostClaimChallengeBonusResponse = new PostClaimChallengeBonusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationChannelStatus$Type extends MessageType<NotificationChannelStatus> {
    constructor() {
        super("one.plan3t.core.model.NotificationChannelStatus", [
            { no: 1, name: "channelId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "syncedAt", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<NotificationChannelStatus>): NotificationChannelStatus {
        const message = { channelId: "", enabled: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NotificationChannelStatus>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NotificationChannelStatus): NotificationChannelStatus {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string channelId */ 1:
                    message.channelId = reader.string();
                    break;
                case /* bool enabled */ 2:
                    message.enabled = reader.bool();
                    break;
                case /* google.protobuf.Timestamp syncedAt */ 3:
                    message.syncedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.syncedAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NotificationChannelStatus, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string channelId = 1; */
        if (message.channelId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.channelId);
        /* bool enabled = 2; */
        if (message.enabled !== false)
            writer.tag(2, WireType.Varint).bool(message.enabled);
        /* google.protobuf.Timestamp syncedAt = 3; */
        if (message.syncedAt)
            Timestamp.internalBinaryWrite(message.syncedAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.NotificationChannelStatus
 */
export const NotificationChannelStatus = new NotificationChannelStatus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostWriteNotificationChannelStatusRequest$Type extends MessageType<PostWriteNotificationChannelStatusRequest> {
    constructor() {
        super("one.plan3t.core.model.PostWriteNotificationChannelStatusRequest", [
            { no: 1, name: "status", kind: "message", T: () => NotificationChannelStatus }
        ]);
    }
    create(value?: PartialMessage<PostWriteNotificationChannelStatusRequest>): PostWriteNotificationChannelStatusRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostWriteNotificationChannelStatusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostWriteNotificationChannelStatusRequest): PostWriteNotificationChannelStatusRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.NotificationChannelStatus status */ 1:
                    message.status = NotificationChannelStatus.internalBinaryRead(reader, reader.uint32(), options, message.status);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PostWriteNotificationChannelStatusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.NotificationChannelStatus status = 1; */
        if (message.status)
            NotificationChannelStatus.internalBinaryWrite(message.status, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostWriteNotificationChannelStatusRequest
 */
export const PostWriteNotificationChannelStatusRequest = new PostWriteNotificationChannelStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PostWriteNotificationChannelStatusResponse$Type extends MessageType<PostWriteNotificationChannelStatusResponse> {
    constructor() {
        super("one.plan3t.core.model.PostWriteNotificationChannelStatusResponse", []);
    }
    create(value?: PartialMessage<PostWriteNotificationChannelStatusResponse>): PostWriteNotificationChannelStatusResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PostWriteNotificationChannelStatusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PostWriteNotificationChannelStatusResponse): PostWriteNotificationChannelStatusResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: PostWriteNotificationChannelStatusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PostWriteNotificationChannelStatusResponse
 */
export const PostWriteNotificationChannelStatusResponse = new PostWriteNotificationChannelStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNotificationChannelStatusRequest$Type extends MessageType<GetNotificationChannelStatusRequest> {
    constructor() {
        super("one.plan3t.core.model.GetNotificationChannelStatusRequest", []);
    }
    create(value?: PartialMessage<GetNotificationChannelStatusRequest>): GetNotificationChannelStatusRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNotificationChannelStatusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNotificationChannelStatusRequest): GetNotificationChannelStatusRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetNotificationChannelStatusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetNotificationChannelStatusRequest
 */
export const GetNotificationChannelStatusRequest = new GetNotificationChannelStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNotificationChannelStatusResponse$Type extends MessageType<GetNotificationChannelStatusResponse> {
    constructor() {
        super("one.plan3t.core.model.GetNotificationChannelStatusResponse", [
            { no: 1, name: "status", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NotificationChannelStatus }
        ]);
    }
    create(value?: PartialMessage<GetNotificationChannelStatusResponse>): GetNotificationChannelStatusResponse {
        const message = { status: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNotificationChannelStatusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNotificationChannelStatusResponse): GetNotificationChannelStatusResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.NotificationChannelStatus status */ 1:
                    message.status.push(NotificationChannelStatus.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNotificationChannelStatusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.NotificationChannelStatus status = 1; */
        for (let i = 0; i < message.status.length; i++)
            NotificationChannelStatus.internalBinaryWrite(message.status[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetNotificationChannelStatusResponse
 */
export const GetNotificationChannelStatusResponse = new GetNotificationChannelStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChallengeStartedCountResponse$Type extends MessageType<GetChallengeStartedCountResponse> {
    constructor() {
        super("one.plan3t.core.model.GetChallengeStartedCountResponse", [
            { no: 1, name: "counts", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 5 /*ScalarType.INT32*/ } }
        ]);
    }
    create(value?: PartialMessage<GetChallengeStartedCountResponse>): GetChallengeStartedCountResponse {
        const message = { counts: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetChallengeStartedCountResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetChallengeStartedCountResponse): GetChallengeStartedCountResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, int32> counts */ 1:
                    this.binaryReadMap1(message.counts, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: GetChallengeStartedCountResponse["counts"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetChallengeStartedCountResponse["counts"] | undefined, val: GetChallengeStartedCountResponse["counts"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.model.GetChallengeStartedCountResponse.counts");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message: GetChallengeStartedCountResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, int32> counts = 1; */
        for (let k of Object.keys(message.counts))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).int32(message.counts[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetChallengeStartedCountResponse
 */
export const GetChallengeStartedCountResponse = new GetChallengeStartedCountResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NotificationMessage$Type extends MessageType<NotificationMessage> {
    constructor() {
        super("one.plan3t.core.model.NotificationMessage", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "channelId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "body", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "category", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "action", kind: "message", T: () => NotificationAction },
            { no: 8, name: "payload", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 9, name: "timestamp", kind: "message", T: () => Timestamp },
            { no: 10, name: "seenAt", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<NotificationMessage>): NotificationMessage {
        const message = { id: "", channelId: "", title: "", body: "", category: "", payload: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<NotificationMessage>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NotificationMessage): NotificationMessage {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string channelId */ 3:
                    message.channelId = reader.string();
                    break;
                case /* string title */ 4:
                    message.title = reader.string();
                    break;
                case /* string body */ 5:
                    message.body = reader.string();
                    break;
                case /* string category */ 6:
                    message.category = reader.string();
                    break;
                case /* one.plan3t.core.model.NotificationAction action */ 7:
                    message.action = NotificationAction.internalBinaryRead(reader, reader.uint32(), options, message.action);
                    break;
                case /* map<string, string> payload */ 8:
                    this.binaryReadMap8(message.payload, reader, options);
                    break;
                case /* google.protobuf.Timestamp timestamp */ 9:
                    message.timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timestamp);
                    break;
                case /* google.protobuf.Timestamp seenAt */ 10:
                    message.seenAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.seenAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap8(map: NotificationMessage["payload"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof NotificationMessage["payload"] | undefined, val: NotificationMessage["payload"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.model.NotificationMessage.payload");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: NotificationMessage, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string channelId = 3; */
        if (message.channelId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.channelId);
        /* string title = 4; */
        if (message.title !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.title);
        /* string body = 5; */
        if (message.body !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.body);
        /* string category = 6; */
        if (message.category !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.category);
        /* one.plan3t.core.model.NotificationAction action = 7; */
        if (message.action)
            NotificationAction.internalBinaryWrite(message.action, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* map<string, string> payload = 8; */
        for (let k of Object.keys(message.payload))
            writer.tag(8, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.payload[k]).join();
        /* google.protobuf.Timestamp timestamp = 9; */
        if (message.timestamp)
            Timestamp.internalBinaryWrite(message.timestamp, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp seenAt = 10; */
        if (message.seenAt)
            Timestamp.internalBinaryWrite(message.seenAt, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.NotificationMessage
 */
export const NotificationMessage = new NotificationMessage$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNotificationsRequest$Type extends MessageType<GetNotificationsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetNotificationsRequest", [
            { no: 1, name: "userId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "limit", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "after", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<GetNotificationsRequest>): GetNotificationsRequest {
        const message = { userId: "", limit: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNotificationsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNotificationsRequest): GetNotificationsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string userId */ 1:
                    message.userId = reader.string();
                    break;
                case /* int32 limit */ 2:
                    message.limit = reader.int32();
                    break;
                case /* google.protobuf.Timestamp after */ 3:
                    message.after = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.after);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNotificationsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string userId = 1; */
        if (message.userId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userId);
        /* int32 limit = 2; */
        if (message.limit !== 0)
            writer.tag(2, WireType.Varint).int32(message.limit);
        /* google.protobuf.Timestamp after = 3; */
        if (message.after)
            Timestamp.internalBinaryWrite(message.after, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetNotificationsRequest
 */
export const GetNotificationsRequest = new GetNotificationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNotificationsResponse$Type extends MessageType<GetNotificationsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetNotificationsResponse", [
            { no: 1, name: "notifications", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NotificationMessage }
        ]);
    }
    create(value?: PartialMessage<GetNotificationsResponse>): GetNotificationsResponse {
        const message = { notifications: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetNotificationsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNotificationsResponse): GetNotificationsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.NotificationMessage notifications */ 1:
                    message.notifications.push(NotificationMessage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNotificationsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.NotificationMessage notifications = 1; */
        for (let i = 0; i < message.notifications.length; i++)
            NotificationMessage.internalBinaryWrite(message.notifications[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetNotificationsResponse
 */
export const GetNotificationsResponse = new GetNotificationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddNotificationsRequest$Type extends MessageType<AddNotificationsRequest> {
    constructor() {
        super("one.plan3t.core.model.AddNotificationsRequest", [
            { no: 1, name: "messages", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NotificationMessage }
        ]);
    }
    create(value?: PartialMessage<AddNotificationsRequest>): AddNotificationsRequest {
        const message = { messages: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddNotificationsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddNotificationsRequest): AddNotificationsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.NotificationMessage messages */ 1:
                    message.messages.push(NotificationMessage.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddNotificationsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.NotificationMessage messages = 1; */
        for (let i = 0; i < message.messages.length; i++)
            NotificationMessage.internalBinaryWrite(message.messages[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.AddNotificationsRequest
 */
export const AddNotificationsRequest = new AddNotificationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddNotificationsResponse$Type extends MessageType<AddNotificationsResponse> {
    constructor() {
        super("one.plan3t.core.model.AddNotificationsResponse", []);
    }
    create(value?: PartialMessage<AddNotificationsResponse>): AddNotificationsResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddNotificationsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddNotificationsResponse): AddNotificationsResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AddNotificationsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.AddNotificationsResponse
 */
export const AddNotificationsResponse = new AddNotificationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetIndividualReferralCodeRequest$Type extends MessageType<GetIndividualReferralCodeRequest> {
    constructor() {
        super("one.plan3t.core.model.GetIndividualReferralCodeRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetIndividualReferralCodeRequest>): GetIndividualReferralCodeRequest {
        const message = { userId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetIndividualReferralCodeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetIndividualReferralCodeRequest): GetIndividualReferralCodeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_id */ 1:
                    message.userId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetIndividualReferralCodeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user_id = 1; */
        if (message.userId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetIndividualReferralCodeRequest
 */
export const GetIndividualReferralCodeRequest = new GetIndividualReferralCodeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetIndividualReferralCodeResponse$Type extends MessageType<GetIndividualReferralCodeResponse> {
    constructor() {
        super("one.plan3t.core.model.GetIndividualReferralCodeResponse", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetIndividualReferralCodeResponse>): GetIndividualReferralCodeResponse {
        const message = { code: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetIndividualReferralCodeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetIndividualReferralCodeResponse): GetIndividualReferralCodeResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetIndividualReferralCodeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetIndividualReferralCodeResponse
 */
export const GetIndividualReferralCodeResponse = new GetIndividualReferralCodeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterReferralCodeUsageRequest$Type extends MessageType<RegisterReferralCodeUsageRequest> {
    constructor() {
        super("one.plan3t.core.model.RegisterReferralCodeUsageRequest", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RegisterReferralCodeUsageRequest>): RegisterReferralCodeUsageRequest {
        const message = { code: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RegisterReferralCodeUsageRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterReferralCodeUsageRequest): RegisterReferralCodeUsageRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RegisterReferralCodeUsageRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.RegisterReferralCodeUsageRequest
 */
export const RegisterReferralCodeUsageRequest = new RegisterReferralCodeUsageRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RegisterReferralCodeUsageResponse$Type extends MessageType<RegisterReferralCodeUsageResponse> {
    constructor() {
        super("one.plan3t.core.model.RegisterReferralCodeUsageResponse", []);
    }
    create(value?: PartialMessage<RegisterReferralCodeUsageResponse>): RegisterReferralCodeUsageResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RegisterReferralCodeUsageResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RegisterReferralCodeUsageResponse): RegisterReferralCodeUsageResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RegisterReferralCodeUsageResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.RegisterReferralCodeUsageResponse
 */
export const RegisterReferralCodeUsageResponse = new RegisterReferralCodeUsageResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClaimReferralBonusRequest$Type extends MessageType<ClaimReferralBonusRequest> {
    constructor() {
        super("one.plan3t.core.model.ClaimReferralBonusRequest", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ClaimReferralBonusRequest>): ClaimReferralBonusRequest {
        const message = { code: "", deviceId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ClaimReferralBonusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClaimReferralBonusRequest): ClaimReferralBonusRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                case /* string device_id */ 2:
                    message.deviceId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ClaimReferralBonusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        /* string device_id = 2; */
        if (message.deviceId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.deviceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ClaimReferralBonusRequest
 */
export const ClaimReferralBonusRequest = new ClaimReferralBonusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClaimReferralBonusResponse$Type extends MessageType<ClaimReferralBonusResponse> {
    constructor() {
        super("one.plan3t.core.model.ClaimReferralBonusResponse", [
            { no: 1, name: "amount", kind: "message", T: () => Plan3TPoint }
        ]);
    }
    create(value?: PartialMessage<ClaimReferralBonusResponse>): ClaimReferralBonusResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ClaimReferralBonusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClaimReferralBonusResponse): ClaimReferralBonusResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.Plan3TPoint amount */ 1:
                    message.amount = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ClaimReferralBonusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.Plan3TPoint amount = 1; */
        if (message.amount)
            Plan3TPoint.internalBinaryWrite(message.amount, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ClaimReferralBonusResponse
 */
export const ClaimReferralBonusResponse = new ClaimReferralBonusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckReferralCodeRequest$Type extends MessageType<CheckReferralCodeRequest> {
    constructor() {
        super("one.plan3t.core.model.CheckReferralCodeRequest", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CheckReferralCodeRequest>): CheckReferralCodeRequest {
        const message = { code: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CheckReferralCodeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckReferralCodeRequest): CheckReferralCodeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckReferralCodeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CheckReferralCodeRequest
 */
export const CheckReferralCodeRequest = new CheckReferralCodeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CheckReferralCodeResponse$Type extends MessageType<CheckReferralCodeResponse> {
    constructor() {
        super("one.plan3t.core.model.CheckReferralCodeResponse", [
            { no: 1, name: "valid", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CheckReferralCodeResponse>): CheckReferralCodeResponse {
        const message = { valid: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CheckReferralCodeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CheckReferralCodeResponse): CheckReferralCodeResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool valid */ 1:
                    message.valid = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CheckReferralCodeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool valid = 1; */
        if (message.valid !== false)
            writer.tag(1, WireType.Varint).bool(message.valid);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CheckReferralCodeResponse
 */
export const CheckReferralCodeResponse = new CheckReferralCodeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetIndividualReferralReportRequest$Type extends MessageType<GetIndividualReferralReportRequest> {
    constructor() {
        super("one.plan3t.core.model.GetIndividualReferralReportRequest", []);
    }
    create(value?: PartialMessage<GetIndividualReferralReportRequest>): GetIndividualReferralReportRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetIndividualReferralReportRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetIndividualReferralReportRequest): GetIndividualReferralReportRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetIndividualReferralReportRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetIndividualReferralReportRequest
 */
export const GetIndividualReferralReportRequest = new GetIndividualReferralReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReferralSpecialBonus$Type extends MessageType<ReferralSpecialBonus> {
    constructor() {
        super("one.plan3t.core.model.ReferralSpecialBonus", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "requiredReferrals", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "image", kind: "message", T: () => ImageResource },
            { no: 5, name: "labelLong", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "icon_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReferralSpecialBonus>): ReferralSpecialBonus {
        const message = { id: "", requiredReferrals: 0, label: "", labelLong: "", description: "", iconName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReferralSpecialBonus>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReferralSpecialBonus): ReferralSpecialBonus {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* int32 requiredReferrals */ 2:
                    message.requiredReferrals = reader.int32();
                    break;
                case /* string label */ 3:
                    message.label = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource image */ 4:
                    message.image = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.image);
                    break;
                case /* string labelLong */ 5:
                    message.labelLong = reader.string();
                    break;
                case /* string description */ 6:
                    message.description = reader.string();
                    break;
                case /* string icon_name */ 7:
                    message.iconName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReferralSpecialBonus, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* int32 requiredReferrals = 2; */
        if (message.requiredReferrals !== 0)
            writer.tag(2, WireType.Varint).int32(message.requiredReferrals);
        /* string label = 3; */
        if (message.label !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.label);
        /* one.plan3t.core.model.ImageResource image = 4; */
        if (message.image)
            ImageResource.internalBinaryWrite(message.image, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string labelLong = 5; */
        if (message.labelLong !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.labelLong);
        /* string description = 6; */
        if (message.description !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.description);
        /* string icon_name = 7; */
        if (message.iconName !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.iconName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ReferralSpecialBonus
 */
export const ReferralSpecialBonus = new ReferralSpecialBonus$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReferralBonusTier$Type extends MessageType<ReferralBonusTier> {
    constructor() {
        super("one.plan3t.core.model.ReferralBonusTier", [
            { no: 1, name: "required_referrals", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "amount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "icon_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReferralBonusTier>): ReferralBonusTier {
        const message = { requiredReferrals: 0, name: "", amount: 0, iconName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReferralBonusTier>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReferralBonusTier): ReferralBonusTier {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 required_referrals */ 1:
                    message.requiredReferrals = reader.int32();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* int32 amount */ 3:
                    message.amount = reader.int32();
                    break;
                case /* string icon_name */ 4:
                    message.iconName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReferralBonusTier, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 required_referrals = 1; */
        if (message.requiredReferrals !== 0)
            writer.tag(1, WireType.Varint).int32(message.requiredReferrals);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* int32 amount = 3; */
        if (message.amount !== 0)
            writer.tag(3, WireType.Varint).int32(message.amount);
        /* string icon_name = 4; */
        if (message.iconName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.iconName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ReferralBonusTier
 */
export const ReferralBonusTier = new ReferralBonusTier$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetIndividualReferralReportResponse$Type extends MessageType<GetIndividualReferralReportResponse> {
    constructor() {
        super("one.plan3t.core.model.GetIndividualReferralReportResponse", [
            { no: 1, name: "numReferred", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "specialBonuses", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ReferralSpecialBonus },
            { no: 3, name: "specialBonusRequestState", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "enum", T: () => ["one.plan3t.core.model.ReferralSpecialBonusRequestState", ReferralSpecialBonusRequestState, "REFERRAL_SPECIAL_BONUS_REQUEST_STATE_"] } },
            { no: 4, name: "bonusTiers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ReferralBonusTier },
            { no: 5, name: "shareText", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetIndividualReferralReportResponse>): GetIndividualReferralReportResponse {
        const message = { numReferred: 0, specialBonuses: [], specialBonusRequestState: {}, bonusTiers: [], shareText: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetIndividualReferralReportResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetIndividualReferralReportResponse): GetIndividualReferralReportResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 numReferred */ 1:
                    message.numReferred = reader.int32();
                    break;
                case /* repeated one.plan3t.core.model.ReferralSpecialBonus specialBonuses */ 2:
                    message.specialBonuses.push(ReferralSpecialBonus.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* map<string, one.plan3t.core.model.ReferralSpecialBonusRequestState> specialBonusRequestState */ 3:
                    this.binaryReadMap3(message.specialBonusRequestState, reader, options);
                    break;
                case /* repeated one.plan3t.core.model.ReferralBonusTier bonusTiers */ 4:
                    message.bonusTiers.push(ReferralBonusTier.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string shareText */ 5:
                    message.shareText = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: GetIndividualReferralReportResponse["specialBonusRequestState"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetIndividualReferralReportResponse["specialBonusRequestState"] | undefined, val: GetIndividualReferralReportResponse["specialBonusRequestState"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.int32();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.model.GetIndividualReferralReportResponse.specialBonusRequestState");
            }
        }
        map[key ?? ""] = val ?? 0;
    }
    internalBinaryWrite(message: GetIndividualReferralReportResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 numReferred = 1; */
        if (message.numReferred !== 0)
            writer.tag(1, WireType.Varint).int32(message.numReferred);
        /* repeated one.plan3t.core.model.ReferralSpecialBonus specialBonuses = 2; */
        for (let i = 0; i < message.specialBonuses.length; i++)
            ReferralSpecialBonus.internalBinaryWrite(message.specialBonuses[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* map<string, one.plan3t.core.model.ReferralSpecialBonusRequestState> specialBonusRequestState = 3; */
        for (let k of Object.keys(message.specialBonusRequestState))
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.Varint).int32(message.specialBonusRequestState[k]).join();
        /* repeated one.plan3t.core.model.ReferralBonusTier bonusTiers = 4; */
        for (let i = 0; i < message.bonusTiers.length; i++)
            ReferralBonusTier.internalBinaryWrite(message.bonusTiers[i], writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string shareText = 5; */
        if (message.shareText !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.shareText);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetIndividualReferralReportResponse
 */
export const GetIndividualReferralReportResponse = new GetIndividualReferralReportResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClaimSpecialReferralBonusRequest$Type extends MessageType<ClaimSpecialReferralBonusRequest> {
    constructor() {
        super("one.plan3t.core.model.ClaimSpecialReferralBonusRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "deviceId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "specialBonusId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ClaimSpecialReferralBonusRequest>): ClaimSpecialReferralBonusRequest {
        const message = { id: "", deviceId: "", specialBonusId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ClaimSpecialReferralBonusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClaimSpecialReferralBonusRequest): ClaimSpecialReferralBonusRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string deviceId */ 2:
                    message.deviceId = reader.string();
                    break;
                case /* string specialBonusId */ 3:
                    message.specialBonusId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ClaimSpecialReferralBonusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string deviceId = 2; */
        if (message.deviceId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.deviceId);
        /* string specialBonusId = 3; */
        if (message.specialBonusId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.specialBonusId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ClaimSpecialReferralBonusRequest
 */
export const ClaimSpecialReferralBonusRequest = new ClaimSpecialReferralBonusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClaimSpecialReferralBonusResponse$Type extends MessageType<ClaimSpecialReferralBonusResponse> {
    constructor() {
        super("one.plan3t.core.model.ClaimSpecialReferralBonusResponse", []);
    }
    create(value?: PartialMessage<ClaimSpecialReferralBonusResponse>): ClaimSpecialReferralBonusResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ClaimSpecialReferralBonusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClaimSpecialReferralBonusResponse): ClaimSpecialReferralBonusResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ClaimSpecialReferralBonusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ClaimSpecialReferralBonusResponse
 */
export const ClaimSpecialReferralBonusResponse = new ClaimSpecialReferralBonusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RedeemVoucherRequest$Type extends MessageType<RedeemVoucherRequest> {
    constructor() {
        super("one.plan3t.core.model.RedeemVoucherRequest", [
            { no: 1, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RedeemVoucherRequest>): RedeemVoucherRequest {
        const message = { code: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RedeemVoucherRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RedeemVoucherRequest): RedeemVoucherRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string code */ 1:
                    message.code = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RedeemVoucherRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string code = 1; */
        if (message.code !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.code);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.RedeemVoucherRequest
 */
export const RedeemVoucherRequest = new RedeemVoucherRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RedeemVoucherResponse$Type extends MessageType<RedeemVoucherResponse> {
    constructor() {
        super("one.plan3t.core.model.RedeemVoucherResponse", [
            { no: 1, name: "amount", kind: "message", T: () => Plan3TPoint }
        ]);
    }
    create(value?: PartialMessage<RedeemVoucherResponse>): RedeemVoucherResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RedeemVoucherResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RedeemVoucherResponse): RedeemVoucherResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.Plan3TPoint amount */ 1:
                    message.amount = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RedeemVoucherResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.Plan3TPoint amount = 1; */
        if (message.amount)
            Plan3TPoint.internalBinaryWrite(message.amount, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.RedeemVoucherResponse
 */
export const RedeemVoucherResponse = new RedeemVoucherResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BuyLotteryTicketRequest$Type extends MessageType<BuyLotteryTicketRequest> {
    constructor() {
        super("one.plan3t.core.model.BuyLotteryTicketRequest", [
            { no: 1, name: "transactionId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "priceDrawingId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "lastEventId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "points", kind: "message", T: () => Plan3TPoint }
        ]);
    }
    create(value?: PartialMessage<BuyLotteryTicketRequest>): BuyLotteryTicketRequest {
        const message = { transactionId: "", priceDrawingId: "", lastEventId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BuyLotteryTicketRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BuyLotteryTicketRequest): BuyLotteryTicketRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string transactionId */ 1:
                    message.transactionId = reader.string();
                    break;
                case /* string priceDrawingId */ 2:
                    message.priceDrawingId = reader.string();
                    break;
                case /* string lastEventId */ 3:
                    message.lastEventId = reader.string();
                    break;
                case /* one.plan3t.core.model.Plan3TPoint points */ 4:
                    message.points = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.points);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BuyLotteryTicketRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string transactionId = 1; */
        if (message.transactionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.transactionId);
        /* string priceDrawingId = 2; */
        if (message.priceDrawingId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.priceDrawingId);
        /* string lastEventId = 3; */
        if (message.lastEventId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.lastEventId);
        /* one.plan3t.core.model.Plan3TPoint points = 4; */
        if (message.points)
            Plan3TPoint.internalBinaryWrite(message.points, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.BuyLotteryTicketRequest
 */
export const BuyLotteryTicketRequest = new BuyLotteryTicketRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BuyLotteryTicketResponse$Type extends MessageType<BuyLotteryTicketResponse> {
    constructor() {
        super("one.plan3t.core.model.BuyLotteryTicketResponse", []);
    }
    create(value?: PartialMessage<BuyLotteryTicketResponse>): BuyLotteryTicketResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BuyLotteryTicketResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BuyLotteryTicketResponse): BuyLotteryTicketResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: BuyLotteryTicketResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.BuyLotteryTicketResponse
 */
export const BuyLotteryTicketResponse = new BuyLotteryTicketResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLotteryTicketsRequest$Type extends MessageType<GetLotteryTicketsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetLotteryTicketsRequest", [
            { no: 1, name: "drawingItemId", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetLotteryTicketsRequest>): GetLotteryTicketsRequest {
        const message = { drawingItemId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetLotteryTicketsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLotteryTicketsRequest): GetLotteryTicketsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string drawingItemId */ 1:
                    message.drawingItemId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLotteryTicketsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string drawingItemId = 1; */
        if (message.drawingItemId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.drawingItemId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetLotteryTicketsRequest
 */
export const GetLotteryTicketsRequest = new GetLotteryTicketsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLotteryTicketsResponse$Type extends MessageType<GetLotteryTicketsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetLotteryTicketsResponse", [
            { no: 1, name: "tickets", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => LotteryTicket }
        ]);
    }
    create(value?: PartialMessage<GetLotteryTicketsResponse>): GetLotteryTicketsResponse {
        const message = { tickets: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetLotteryTicketsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLotteryTicketsResponse): GetLotteryTicketsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.LotteryTicket tickets */ 1:
                    message.tickets.push(LotteryTicket.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLotteryTicketsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.LotteryTicket tickets = 1; */
        for (let i = 0; i < message.tickets.length; i++)
            LotteryTicket.internalBinaryWrite(message.tickets[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetLotteryTicketsResponse
 */
export const GetLotteryTicketsResponse = new GetLotteryTicketsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ItemCategory$Type extends MessageType<ItemCategory> {
    constructor() {
        super("one.plan3t.core.model.ItemCategory", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "coreIndustry", kind: "enum", T: () => ["one.plan3t.core.model.CoreIndustry", CoreIndustry] },
            { no: 3, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "icon_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "color", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "item_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "numAboveTheFold", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ItemCategory>): ItemCategory {
        const message = { id: "", coreIndustry: 0, title: "", iconName: "", color: 0, active: false, itemIds: [], numAboveTheFold: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ItemCategory>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ItemCategory): ItemCategory {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* one.plan3t.core.model.CoreIndustry coreIndustry */ 2:
                    message.coreIndustry = reader.int32();
                    break;
                case /* string title */ 3:
                    message.title = reader.string();
                    break;
                case /* string icon_name */ 4:
                    message.iconName = reader.string();
                    break;
                case /* int64 color */ 5:
                    message.color = reader.int64().toNumber();
                    break;
                case /* bool active */ 6:
                    message.active = reader.bool();
                    break;
                case /* repeated string item_ids */ 7:
                    message.itemIds.push(reader.string());
                    break;
                case /* int32 numAboveTheFold */ 8:
                    message.numAboveTheFold = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ItemCategory, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* one.plan3t.core.model.CoreIndustry coreIndustry = 2; */
        if (message.coreIndustry !== 0)
            writer.tag(2, WireType.Varint).int32(message.coreIndustry);
        /* string title = 3; */
        if (message.title !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.title);
        /* string icon_name = 4; */
        if (message.iconName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.iconName);
        /* int64 color = 5; */
        if (message.color !== 0)
            writer.tag(5, WireType.Varint).int64(message.color);
        /* bool active = 6; */
        if (message.active !== false)
            writer.tag(6, WireType.Varint).bool(message.active);
        /* repeated string item_ids = 7; */
        for (let i = 0; i < message.itemIds.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.itemIds[i]);
        /* int32 numAboveTheFold = 8; */
        if (message.numAboveTheFold !== 0)
            writer.tag(8, WireType.Varint).int32(message.numAboveTheFold);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ItemCategory
 */
export const ItemCategory = new ItemCategory$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRewardsCategoriesRequest$Type extends MessageType<GetRewardsCategoriesRequest> {
    constructor() {
        super("one.plan3t.core.model.GetRewardsCategoriesRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "billing_type", kind: "enum", T: () => ["one.plan3t.core.model.RewardBillingType", RewardBillingType, "REWARD_BILLING_TYPE_"] },
            { no: 4, name: "ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetRewardsCategoriesRequest>): GetRewardsCategoriesRequest {
        const message = { pageSize: 0, pageToken: "", billingType: 0, ids: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRewardsCategoriesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRewardsCategoriesRequest): GetRewardsCategoriesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                case /* one.plan3t.core.model.RewardBillingType billing_type */ 3:
                    message.billingType = reader.int32();
                    break;
                case /* repeated string ids */ 4:
                    message.ids.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRewardsCategoriesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        /* one.plan3t.core.model.RewardBillingType billing_type = 3; */
        if (message.billingType !== 0)
            writer.tag(3, WireType.Varint).int32(message.billingType);
        /* repeated string ids = 4; */
        for (let i = 0; i < message.ids.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.ids[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRewardsCategoriesRequest
 */
export const GetRewardsCategoriesRequest = new GetRewardsCategoriesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRewardsCategoriesResponse$Type extends MessageType<GetRewardsCategoriesResponse> {
    constructor() {
        super("one.plan3t.core.model.GetRewardsCategoriesResponse", [
            { no: 1, name: "categories", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemCategory },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetRewardsCategoriesResponse>): GetRewardsCategoriesResponse {
        const message = { categories: [], nextPageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRewardsCategoriesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRewardsCategoriesResponse): GetRewardsCategoriesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.ItemCategory categories */ 1:
                    message.categories.push(ItemCategory.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRewardsCategoriesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.ItemCategory categories = 1; */
        for (let i = 0; i < message.categories.length; i++)
            ItemCategory.internalBinaryWrite(message.categories[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRewardsCategoriesResponse
 */
export const GetRewardsCategoriesResponse = new GetRewardsCategoriesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPartnerCategoriesRequest$Type extends MessageType<GetPartnerCategoriesRequest> {
    constructor() {
        super("one.plan3t.core.model.GetPartnerCategoriesRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPartnerCategoriesRequest>): GetPartnerCategoriesRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPartnerCategoriesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPartnerCategoriesRequest): GetPartnerCategoriesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPartnerCategoriesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetPartnerCategoriesRequest
 */
export const GetPartnerCategoriesRequest = new GetPartnerCategoriesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPartnerCategoriesResponse$Type extends MessageType<GetPartnerCategoriesResponse> {
    constructor() {
        super("one.plan3t.core.model.GetPartnerCategoriesResponse", [
            { no: 1, name: "categories", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemCategory },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPartnerCategoriesResponse>): GetPartnerCategoriesResponse {
        const message = { categories: [], nextPageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPartnerCategoriesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPartnerCategoriesResponse): GetPartnerCategoriesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.ItemCategory categories */ 1:
                    message.categories.push(ItemCategory.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPartnerCategoriesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.ItemCategory categories = 1; */
        for (let i = 0; i < message.categories.length; i++)
            ItemCategory.internalBinaryWrite(message.categories[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetPartnerCategoriesResponse
 */
export const GetPartnerCategoriesResponse = new GetPartnerCategoriesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipsCategoriesRequest$Type extends MessageType<GetTipsCategoriesRequest> {
    constructor() {
        super("one.plan3t.core.model.GetTipsCategoriesRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTipsCategoriesRequest>): GetTipsCategoriesRequest {
        const message = { pageSize: 0, pageToken: "", ids: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipsCategoriesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipsCategoriesRequest): GetTipsCategoriesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                case /* repeated string ids */ 3:
                    message.ids.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipsCategoriesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        /* repeated string ids = 3; */
        for (let i = 0; i < message.ids.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.ids[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipsCategoriesRequest
 */
export const GetTipsCategoriesRequest = new GetTipsCategoriesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipsCategoriesResponse$Type extends MessageType<GetTipsCategoriesResponse> {
    constructor() {
        super("one.plan3t.core.model.GetTipsCategoriesResponse", [
            { no: 1, name: "categories", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemCategory },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTipsCategoriesResponse>): GetTipsCategoriesResponse {
        const message = { categories: [], nextPageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipsCategoriesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipsCategoriesResponse): GetTipsCategoriesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.ItemCategory categories */ 1:
                    message.categories.push(ItemCategory.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipsCategoriesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.ItemCategory categories = 1; */
        for (let i = 0; i < message.categories.length; i++)
            ItemCategory.internalBinaryWrite(message.categories[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipsCategoriesResponse
 */
export const GetTipsCategoriesResponse = new GetTipsCategoriesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipPreviewsRequest$Type extends MessageType<GetTipPreviewsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetTipPreviewsRequest", [
            { no: 1, name: "ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTipPreviewsRequest>): GetTipPreviewsRequest {
        const message = { ids: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipPreviewsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipPreviewsRequest): GetTipPreviewsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string ids */ 1:
                    message.ids.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipPreviewsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string ids = 1; */
        for (let i = 0; i < message.ids.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.ids[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipPreviewsRequest
 */
export const GetTipPreviewsRequest = new GetTipPreviewsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipPreviewsResponse$Type extends MessageType<GetTipPreviewsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetTipPreviewsResponse", [
            { no: 1, name: "tips", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TipPreview }
        ]);
    }
    create(value?: PartialMessage<GetTipPreviewsResponse>): GetTipPreviewsResponse {
        const message = { tips: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipPreviewsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipPreviewsResponse): GetTipPreviewsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.TipPreview tips */ 1:
                    message.tips.push(TipPreview.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipPreviewsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.TipPreview tips = 1; */
        for (let i = 0; i < message.tips.length; i++)
            TipPreview.internalBinaryWrite(message.tips[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipPreviewsResponse
 */
export const GetTipPreviewsResponse = new GetTipPreviewsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipSlidesRequest$Type extends MessageType<GetTipSlidesRequest> {
    constructor() {
        super("one.plan3t.core.model.GetTipSlidesRequest", [
            { no: 1, name: "tip_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTipSlidesRequest>): GetTipSlidesRequest {
        const message = { tipId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipSlidesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipSlidesRequest): GetTipSlidesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tip_id */ 1:
                    message.tipId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipSlidesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tip_id = 1; */
        if (message.tipId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tipId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipSlidesRequest
 */
export const GetTipSlidesRequest = new GetTipSlidesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipSlidesResponse$Type extends MessageType<GetTipSlidesResponse> {
    constructor() {
        super("one.plan3t.core.model.GetTipSlidesResponse", [
            { no: 1, name: "slides", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TipSlide }
        ]);
    }
    create(value?: PartialMessage<GetTipSlidesResponse>): GetTipSlidesResponse {
        const message = { slides: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipSlidesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipSlidesResponse): GetTipSlidesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.TipSlide slides */ 1:
                    message.slides.push(TipSlide.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipSlidesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.TipSlide slides = 1; */
        for (let i = 0; i < message.slides.length; i++)
            TipSlide.internalBinaryWrite(message.slides[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipSlidesResponse
 */
export const GetTipSlidesResponse = new GetTipSlidesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipQuizRequest$Type extends MessageType<GetTipQuizRequest> {
    constructor() {
        super("one.plan3t.core.model.GetTipQuizRequest", [
            { no: 1, name: "tip_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTipQuizRequest>): GetTipQuizRequest {
        const message = { tipId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipQuizRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipQuizRequest): GetTipQuizRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tip_id */ 1:
                    message.tipId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipQuizRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tip_id = 1; */
        if (message.tipId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tipId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipQuizRequest
 */
export const GetTipQuizRequest = new GetTipQuizRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipQuizResponse$Type extends MessageType<GetTipQuizResponse> {
    constructor() {
        super("one.plan3t.core.model.GetTipQuizResponse", [
            { no: 1, name: "quiz", kind: "message", T: () => TipQuiz }
        ]);
    }
    create(value?: PartialMessage<GetTipQuizResponse>): GetTipQuizResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipQuizResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipQuizResponse): GetTipQuizResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.TipQuiz quiz */ 1:
                    message.quiz = TipQuiz.internalBinaryRead(reader, reader.uint32(), options, message.quiz);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipQuizResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.TipQuiz quiz = 1; */
        if (message.quiz)
            TipQuiz.internalBinaryWrite(message.quiz, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipQuizResponse
 */
export const GetTipQuizResponse = new GetTipQuizResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClaimTipQuizBonusRequest$Type extends MessageType<ClaimTipQuizBonusRequest> {
    constructor() {
        super("one.plan3t.core.model.ClaimTipQuizBonusRequest", [
            { no: 1, name: "tip_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ClaimTipQuizBonusRequest>): ClaimTipQuizBonusRequest {
        const message = { tipId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ClaimTipQuizBonusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClaimTipQuizBonusRequest): ClaimTipQuizBonusRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tip_id */ 1:
                    message.tipId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ClaimTipQuizBonusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tip_id = 1; */
        if (message.tipId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tipId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ClaimTipQuizBonusRequest
 */
export const ClaimTipQuizBonusRequest = new ClaimTipQuizBonusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClaimTipQuizBonusResponse$Type extends MessageType<ClaimTipQuizBonusResponse> {
    constructor() {
        super("one.plan3t.core.model.ClaimTipQuizBonusResponse", [
            { no: 1, name: "award", kind: "message", T: () => Plan3TPoint }
        ]);
    }
    create(value?: PartialMessage<ClaimTipQuizBonusResponse>): ClaimTipQuizBonusResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ClaimTipQuizBonusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClaimTipQuizBonusResponse): ClaimTipQuizBonusResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.Plan3TPoint award */ 1:
                    message.award = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.award);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ClaimTipQuizBonusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.Plan3TPoint award = 1; */
        if (message.award)
            Plan3TPoint.internalBinaryWrite(message.award, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ClaimTipQuizBonusResponse
 */
export const ClaimTipQuizBonusResponse = new ClaimTipQuizBonusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipQuizCompletionsRequest$Type extends MessageType<GetTipQuizCompletionsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetTipQuizCompletionsRequest", [
            { no: 1, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTipQuizCompletionsRequest>): GetTipQuizCompletionsRequest {
        const message = { userId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipQuizCompletionsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipQuizCompletionsRequest): GetTipQuizCompletionsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_id */ 1:
                    message.userId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipQuizCompletionsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user_id = 1; */
        if (message.userId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipQuizCompletionsRequest
 */
export const GetTipQuizCompletionsRequest = new GetTipQuizCompletionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipQuizCompletionsResponse$Type extends MessageType<GetTipQuizCompletionsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetTipQuizCompletionsResponse", [
            { no: 1, name: "tip_completed_at", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Timestamp } }
        ]);
    }
    create(value?: PartialMessage<GetTipQuizCompletionsResponse>): GetTipQuizCompletionsResponse {
        const message = { tipCompletedAt: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipQuizCompletionsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipQuizCompletionsResponse): GetTipQuizCompletionsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, google.protobuf.Timestamp> tip_completed_at */ 1:
                    this.binaryReadMap1(message.tipCompletedAt, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: GetTipQuizCompletionsResponse["tipCompletedAt"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetTipQuizCompletionsResponse["tipCompletedAt"] | undefined, val: GetTipQuizCompletionsResponse["tipCompletedAt"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Timestamp.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.model.GetTipQuizCompletionsResponse.tip_completed_at");
            }
        }
        map[key ?? ""] = val ?? Timestamp.create();
    }
    internalBinaryWrite(message: GetTipQuizCompletionsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, google.protobuf.Timestamp> tip_completed_at = 1; */
        for (let k of Object.keys(message.tipCompletedAt)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Timestamp.internalBinaryWrite(message.tipCompletedAt[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipQuizCompletionsResponse
 */
export const GetTipQuizCompletionsResponse = new GetTipQuizCompletionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRecommendedTipsRequest$Type extends MessageType<GetRecommendedTipsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetRecommendedTipsRequest", [
            { no: 1, name: "potentials", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["one.plan3t.core.model.CO2SavingPotential", CO2SavingPotential] }
        ]);
    }
    create(value?: PartialMessage<GetRecommendedTipsRequest>): GetRecommendedTipsRequest {
        const message = { potentials: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRecommendedTipsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRecommendedTipsRequest): GetRecommendedTipsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.CO2SavingPotential potentials */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.potentials.push(reader.int32());
                    else
                        message.potentials.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRecommendedTipsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.CO2SavingPotential potentials = 1; */
        if (message.potentials.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.potentials.length; i++)
                writer.int32(message.potentials[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRecommendedTipsRequest
 */
export const GetRecommendedTipsRequest = new GetRecommendedTipsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRecommendedTipsResponse$Type extends MessageType<GetRecommendedTipsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetRecommendedTipsResponse", [
            { no: 1, name: "tips", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TipPreview }
        ]);
    }
    create(value?: PartialMessage<GetRecommendedTipsResponse>): GetRecommendedTipsResponse {
        const message = { tips: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRecommendedTipsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRecommendedTipsResponse): GetRecommendedTipsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.TipPreview tips */ 1:
                    message.tips.push(TipPreview.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRecommendedTipsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.TipPreview tips = 1; */
        for (let i = 0; i < message.tips.length; i++)
            TipPreview.internalBinaryWrite(message.tips[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRecommendedTipsResponse
 */
export const GetRecommendedTipsResponse = new GetRecommendedTipsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipAuthorsRequest$Type extends MessageType<GetTipAuthorsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetTipAuthorsRequest", [
            { no: 1, name: "ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTipAuthorsRequest>): GetTipAuthorsRequest {
        const message = { ids: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipAuthorsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipAuthorsRequest): GetTipAuthorsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string ids */ 1:
                    message.ids.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipAuthorsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string ids = 1; */
        for (let i = 0; i < message.ids.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.ids[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipAuthorsRequest
 */
export const GetTipAuthorsRequest = new GetTipAuthorsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTipAuthorsResponse$Type extends MessageType<GetTipAuthorsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetTipAuthorsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TipAuthor }
        ]);
    }
    create(value?: PartialMessage<GetTipAuthorsResponse>): GetTipAuthorsResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTipAuthorsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTipAuthorsResponse): GetTipAuthorsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.TipAuthor items */ 1:
                    message.items.push(TipAuthor.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTipAuthorsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.TipAuthor items = 1; */
        for (let i = 0; i < message.items.length; i++)
            TipAuthor.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTipAuthorsResponse
 */
export const GetTipAuthorsResponse = new GetTipAuthorsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitOpenBankingConnectionRequest$Type extends MessageType<InitOpenBankingConnectionRequest> {
    constructor() {
        super("one.plan3t.core.model.InitOpenBankingConnectionRequest", [
            { no: 1, name: "market", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "locale", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "device_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "aspsp_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<InitOpenBankingConnectionRequest>): InitOpenBankingConnectionRequest {
        const message = { market: "", locale: "", displayName: "", token: "", deviceId: "", aspspId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InitOpenBankingConnectionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitOpenBankingConnectionRequest): InitOpenBankingConnectionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string market */ 1:
                    message.market = reader.string();
                    break;
                case /* string locale */ 2:
                    message.locale = reader.string();
                    break;
                case /* string display_name */ 4:
                    message.displayName = reader.string();
                    break;
                case /* string token */ 5:
                    message.token = reader.string();
                    break;
                case /* string device_id */ 6:
                    message.deviceId = reader.string();
                    break;
                case /* string aspsp_id */ 7:
                    message.aspspId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitOpenBankingConnectionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string market = 1; */
        if (message.market !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.market);
        /* string locale = 2; */
        if (message.locale !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.locale);
        /* string display_name = 4; */
        if (message.displayName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.displayName);
        /* string token = 5; */
        if (message.token !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.token);
        /* string device_id = 6; */
        if (message.deviceId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.deviceId);
        /* string aspsp_id = 7; */
        if (message.aspspId !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.aspspId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.InitOpenBankingConnectionRequest
 */
export const InitOpenBankingConnectionRequest = new InitOpenBankingConnectionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitOpenBankingConnectionResponse$Type extends MessageType<InitOpenBankingConnectionResponse> {
    constructor() {
        super("one.plan3t.core.model.InitOpenBankingConnectionResponse", [
            { no: 1, name: "user_authorization_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "tink_client_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "link_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<InitOpenBankingConnectionResponse>): InitOpenBankingConnectionResponse {
        const message = { userAuthorizationCode: "", state: "", tinkClientId: "", linkUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InitOpenBankingConnectionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitOpenBankingConnectionResponse): InitOpenBankingConnectionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_authorization_code */ 1:
                    message.userAuthorizationCode = reader.string();
                    break;
                case /* string state */ 2:
                    message.state = reader.string();
                    break;
                case /* string tink_client_id */ 3:
                    message.tinkClientId = reader.string();
                    break;
                case /* string link_url */ 4:
                    message.linkUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitOpenBankingConnectionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user_authorization_code = 1; */
        if (message.userAuthorizationCode !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userAuthorizationCode);
        /* string state = 2; */
        if (message.state !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.state);
        /* string tink_client_id = 3; */
        if (message.tinkClientId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.tinkClientId);
        /* string link_url = 4; */
        if (message.linkUrl !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.linkUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.InitOpenBankingConnectionResponse
 */
export const InitOpenBankingConnectionResponse = new InitOpenBankingConnectionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OpenBankingASPSP$Type extends MessageType<OpenBankingASPSP> {
    constructor() {
        super("one.plan3t.core.model.OpenBankingASPSP", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "logo", kind: "message", T: () => ImageResource },
            { no: 4, name: "group_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "sort_index", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<OpenBankingASPSP>): OpenBankingASPSP {
        const message = { id: "", name: "", groupId: "", sortIndex: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OpenBankingASPSP>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OpenBankingASPSP): OpenBankingASPSP {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource logo */ 3:
                    message.logo = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.logo);
                    break;
                case /* string group_id */ 4:
                    message.groupId = reader.string();
                    break;
                case /* int32 sort_index */ 5:
                    message.sortIndex = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OpenBankingASPSP, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* one.plan3t.core.model.ImageResource logo = 3; */
        if (message.logo)
            ImageResource.internalBinaryWrite(message.logo, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string group_id = 4; */
        if (message.groupId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.groupId);
        /* int32 sort_index = 5; */
        if (message.sortIndex !== 0)
            writer.tag(5, WireType.Varint).int32(message.sortIndex);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.OpenBankingASPSP
 */
export const OpenBankingASPSP = new OpenBankingASPSP$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OpenBankingASPSPGroup$Type extends MessageType<OpenBankingASPSPGroup> {
    constructor() {
        super("one.plan3t.core.model.OpenBankingASPSPGroup", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "logo", kind: "message", T: () => ImageResource },
            { no: 5, name: "sort_index", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<OpenBankingASPSPGroup>): OpenBankingASPSPGroup {
        const message = { id: "", name: "", sortIndex: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OpenBankingASPSPGroup>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OpenBankingASPSPGroup): OpenBankingASPSPGroup {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource logo */ 4:
                    message.logo = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.logo);
                    break;
                case /* int32 sort_index */ 5:
                    message.sortIndex = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OpenBankingASPSPGroup, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* one.plan3t.core.model.ImageResource logo = 4; */
        if (message.logo)
            ImageResource.internalBinaryWrite(message.logo, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* int32 sort_index = 5; */
        if (message.sortIndex !== 0)
            writer.tag(5, WireType.Varint).int32(message.sortIndex);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.OpenBankingASPSPGroup
 */
export const OpenBankingASPSPGroup = new OpenBankingASPSPGroup$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOpenBankingASPSPsRequest$Type extends MessageType<GetOpenBankingASPSPsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetOpenBankingASPSPsRequest", [
            { no: 1, name: "market", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetOpenBankingASPSPsRequest>): GetOpenBankingASPSPsRequest {
        const message = { market: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOpenBankingASPSPsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOpenBankingASPSPsRequest): GetOpenBankingASPSPsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string market */ 1:
                    message.market = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOpenBankingASPSPsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string market = 1; */
        if (message.market !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.market);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetOpenBankingASPSPsRequest
 */
export const GetOpenBankingASPSPsRequest = new GetOpenBankingASPSPsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOpenBankingASPSPsResponse$Type extends MessageType<GetOpenBankingASPSPsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetOpenBankingASPSPsResponse", [
            { no: 1, name: "aspsps", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OpenBankingASPSP },
            { no: 2, name: "groups", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OpenBankingASPSPGroup }
        ]);
    }
    create(value?: PartialMessage<GetOpenBankingASPSPsResponse>): GetOpenBankingASPSPsResponse {
        const message = { aspsps: [], groups: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOpenBankingASPSPsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOpenBankingASPSPsResponse): GetOpenBankingASPSPsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.OpenBankingASPSP aspsps */ 1:
                    message.aspsps.push(OpenBankingASPSP.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated one.plan3t.core.model.OpenBankingASPSPGroup groups */ 2:
                    message.groups.push(OpenBankingASPSPGroup.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOpenBankingASPSPsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.OpenBankingASPSP aspsps = 1; */
        for (let i = 0; i < message.aspsps.length; i++)
            OpenBankingASPSP.internalBinaryWrite(message.aspsps[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated one.plan3t.core.model.OpenBankingASPSPGroup groups = 2; */
        for (let i = 0; i < message.groups.length; i++)
            OpenBankingASPSPGroup.internalBinaryWrite(message.groups[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetOpenBankingASPSPsResponse
 */
export const GetOpenBankingASPSPsResponse = new GetOpenBankingASPSPsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompleteInitOpenBankingConnectionRequest$Type extends MessageType<CompleteInitOpenBankingConnectionRequest> {
    constructor() {
        super("one.plan3t.core.model.CompleteInitOpenBankingConnectionRequest", [
            { no: 1, name: "credentials_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "state", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CompleteInitOpenBankingConnectionRequest>): CompleteInitOpenBankingConnectionRequest {
        const message = { credentialsId: "", state: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompleteInitOpenBankingConnectionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompleteInitOpenBankingConnectionRequest): CompleteInitOpenBankingConnectionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string credentials_id */ 1:
                    message.credentialsId = reader.string();
                    break;
                case /* string state */ 2:
                    message.state = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompleteInitOpenBankingConnectionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string credentials_id = 1; */
        if (message.credentialsId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.credentialsId);
        /* string state = 2; */
        if (message.state !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.state);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CompleteInitOpenBankingConnectionRequest
 */
export const CompleteInitOpenBankingConnectionRequest = new CompleteInitOpenBankingConnectionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompleteInitOpenBankingConnectionResponse$Type extends MessageType<CompleteInitOpenBankingConnectionResponse> {
    constructor() {
        super("one.plan3t.core.model.CompleteInitOpenBankingConnectionResponse", [
            { no: 1, name: "fetch_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CompleteInitOpenBankingConnectionResponse>): CompleteInitOpenBankingConnectionResponse {
        const message = { fetchId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompleteInitOpenBankingConnectionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompleteInitOpenBankingConnectionResponse): CompleteInitOpenBankingConnectionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string fetch_id */ 1:
                    message.fetchId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompleteInitOpenBankingConnectionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string fetch_id = 1; */
        if (message.fetchId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.fetchId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CompleteInitOpenBankingConnectionResponse
 */
export const CompleteInitOpenBankingConnectionResponse = new CompleteInitOpenBankingConnectionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefreshOpenBankingConnectionRequest$Type extends MessageType<RefreshOpenBankingConnectionRequest> {
    constructor() {
        super("one.plan3t.core.model.RefreshOpenBankingConnectionRequest", [
            { no: 1, name: "credentials_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RefreshOpenBankingConnectionRequest>): RefreshOpenBankingConnectionRequest {
        const message = { credentialsId: "", displayName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RefreshOpenBankingConnectionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RefreshOpenBankingConnectionRequest): RefreshOpenBankingConnectionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string credentials_id */ 1:
                    message.credentialsId = reader.string();
                    break;
                case /* string display_name */ 2:
                    message.displayName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RefreshOpenBankingConnectionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string credentials_id = 1; */
        if (message.credentialsId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.credentialsId);
        /* string display_name = 2; */
        if (message.displayName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.displayName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.RefreshOpenBankingConnectionRequest
 */
export const RefreshOpenBankingConnectionRequest = new RefreshOpenBankingConnectionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RefreshOpenBankingConnectionResponse$Type extends MessageType<RefreshOpenBankingConnectionResponse> {
    constructor() {
        super("one.plan3t.core.model.RefreshOpenBankingConnectionResponse", [
            { no: 1, name: "user_authorization_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "credentials_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "state", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "tink_client_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "link_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RefreshOpenBankingConnectionResponse>): RefreshOpenBankingConnectionResponse {
        const message = { userAuthorizationCode: "", credentialsId: "", state: "", tinkClientId: "", linkUrl: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RefreshOpenBankingConnectionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RefreshOpenBankingConnectionResponse): RefreshOpenBankingConnectionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string user_authorization_code */ 1:
                    message.userAuthorizationCode = reader.string();
                    break;
                case /* string credentials_id */ 2:
                    message.credentialsId = reader.string();
                    break;
                case /* string state */ 3:
                    message.state = reader.string();
                    break;
                case /* string tink_client_id */ 4:
                    message.tinkClientId = reader.string();
                    break;
                case /* string link_url */ 5:
                    message.linkUrl = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RefreshOpenBankingConnectionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string user_authorization_code = 1; */
        if (message.userAuthorizationCode !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.userAuthorizationCode);
        /* string credentials_id = 2; */
        if (message.credentialsId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.credentialsId);
        /* string state = 3; */
        if (message.state !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.state);
        /* string tink_client_id = 4; */
        if (message.tinkClientId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.tinkClientId);
        /* string link_url = 5; */
        if (message.linkUrl !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.linkUrl);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.RefreshOpenBankingConnectionResponse
 */
export const RefreshOpenBankingConnectionResponse = new RefreshOpenBankingConnectionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompleteRefreshOpenBankingConnectionRequest$Type extends MessageType<CompleteRefreshOpenBankingConnectionRequest> {
    constructor() {
        super("one.plan3t.core.model.CompleteRefreshOpenBankingConnectionRequest", [
            { no: 1, name: "credentials_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "state", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CompleteRefreshOpenBankingConnectionRequest>): CompleteRefreshOpenBankingConnectionRequest {
        const message = { credentialsId: "", state: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompleteRefreshOpenBankingConnectionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompleteRefreshOpenBankingConnectionRequest): CompleteRefreshOpenBankingConnectionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string credentials_id */ 1:
                    message.credentialsId = reader.string();
                    break;
                case /* string state */ 2:
                    message.state = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompleteRefreshOpenBankingConnectionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string credentials_id = 1; */
        if (message.credentialsId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.credentialsId);
        /* string state = 2; */
        if (message.state !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.state);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CompleteRefreshOpenBankingConnectionRequest
 */
export const CompleteRefreshOpenBankingConnectionRequest = new CompleteRefreshOpenBankingConnectionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompleteRefreshOpenBankingConnectionResponse$Type extends MessageType<CompleteRefreshOpenBankingConnectionResponse> {
    constructor() {
        super("one.plan3t.core.model.CompleteRefreshOpenBankingConnectionResponse", [
            { no: 1, name: "fetch_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CompleteRefreshOpenBankingConnectionResponse>): CompleteRefreshOpenBankingConnectionResponse {
        const message = { fetchId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompleteRefreshOpenBankingConnectionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompleteRefreshOpenBankingConnectionResponse): CompleteRefreshOpenBankingConnectionResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string fetch_id */ 1:
                    message.fetchId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompleteRefreshOpenBankingConnectionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string fetch_id = 1; */
        if (message.fetchId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.fetchId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CompleteRefreshOpenBankingConnectionResponse
 */
export const CompleteRefreshOpenBankingConnectionResponse = new CompleteRefreshOpenBankingConnectionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteOpenBankingConnectionRequest$Type extends MessageType<DeleteOpenBankingConnectionRequest> {
    constructor() {
        super("one.plan3t.core.model.DeleteOpenBankingConnectionRequest", [
            { no: 2, name: "credentials_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteOpenBankingConnectionRequest>): DeleteOpenBankingConnectionRequest {
        const message = { credentialsId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteOpenBankingConnectionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteOpenBankingConnectionRequest): DeleteOpenBankingConnectionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string credentials_id */ 2:
                    message.credentialsId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteOpenBankingConnectionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string credentials_id = 2; */
        if (message.credentialsId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.credentialsId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.DeleteOpenBankingConnectionRequest
 */
export const DeleteOpenBankingConnectionRequest = new DeleteOpenBankingConnectionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteOpenBankingConnectionResponse$Type extends MessageType<DeleteOpenBankingConnectionResponse> {
    constructor() {
        super("one.plan3t.core.model.DeleteOpenBankingConnectionResponse", []);
    }
    create(value?: PartialMessage<DeleteOpenBankingConnectionResponse>): DeleteOpenBankingConnectionResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteOpenBankingConnectionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteOpenBankingConnectionResponse): DeleteOpenBankingConnectionResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteOpenBankingConnectionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.DeleteOpenBankingConnectionResponse
 */
export const DeleteOpenBankingConnectionResponse = new DeleteOpenBankingConnectionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOpenBankingUserStatusResponse$Type extends MessageType<GetOpenBankingUserStatusResponse> {
    constructor() {
        super("one.plan3t.core.model.GetOpenBankingUserStatusResponse", [
            { no: 1, name: "connections", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OpenBankingConnection }
        ]);
    }
    create(value?: PartialMessage<GetOpenBankingUserStatusResponse>): GetOpenBankingUserStatusResponse {
        const message = { connections: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOpenBankingUserStatusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOpenBankingUserStatusResponse): GetOpenBankingUserStatusResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.OpenBankingConnection connections */ 1:
                    message.connections.push(OpenBankingConnection.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOpenBankingUserStatusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.OpenBankingConnection connections = 1; */
        for (let i = 0; i < message.connections.length; i++)
            OpenBankingConnection.internalBinaryWrite(message.connections[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetOpenBankingUserStatusResponse
 */
export const GetOpenBankingUserStatusResponse = new GetOpenBankingUserStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OpenBankingConnection$Type extends MessageType<OpenBankingConnection> {
    constructor() {
        super("one.plan3t.core.model.OpenBankingConnection", [
            { no: 1, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "accounts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OpenBankingAccount },
            { no: 3, name: "connected_since", kind: "message", T: () => Timestamp },
            { no: 4, name: "valid_until", kind: "message", T: () => Timestamp },
            { no: 5, name: "credentials_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "status", kind: "enum", T: () => ["one.plan3t.core.model.OpenBankingCredentialStatus", OpenBankingCredentialStatus] },
            { no: 7, name: "statusPayload", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "statusUpdated", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<OpenBankingConnection>): OpenBankingConnection {
        const message = { displayName: "", accounts: [], credentialsId: "", status: 0, statusPayload: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OpenBankingConnection>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OpenBankingConnection): OpenBankingConnection {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string display_name */ 1:
                    message.displayName = reader.string();
                    break;
                case /* repeated one.plan3t.core.model.OpenBankingAccount accounts */ 2:
                    message.accounts.push(OpenBankingAccount.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp connected_since */ 3:
                    message.connectedSince = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.connectedSince);
                    break;
                case /* google.protobuf.Timestamp valid_until */ 4:
                    message.validUntil = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.validUntil);
                    break;
                case /* string credentials_id */ 5:
                    message.credentialsId = reader.string();
                    break;
                case /* one.plan3t.core.model.OpenBankingCredentialStatus status */ 6:
                    message.status = reader.int32();
                    break;
                case /* string statusPayload */ 7:
                    message.statusPayload = reader.string();
                    break;
                case /* google.protobuf.Timestamp statusUpdated */ 8:
                    message.statusUpdated = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.statusUpdated);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OpenBankingConnection, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string display_name = 1; */
        if (message.displayName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.displayName);
        /* repeated one.plan3t.core.model.OpenBankingAccount accounts = 2; */
        for (let i = 0; i < message.accounts.length; i++)
            OpenBankingAccount.internalBinaryWrite(message.accounts[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp connected_since = 3; */
        if (message.connectedSince)
            Timestamp.internalBinaryWrite(message.connectedSince, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp valid_until = 4; */
        if (message.validUntil)
            Timestamp.internalBinaryWrite(message.validUntil, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* string credentials_id = 5; */
        if (message.credentialsId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.credentialsId);
        /* one.plan3t.core.model.OpenBankingCredentialStatus status = 6; */
        if (message.status !== 0)
            writer.tag(6, WireType.Varint).int32(message.status);
        /* string statusPayload = 7; */
        if (message.statusPayload !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.statusPayload);
        /* google.protobuf.Timestamp statusUpdated = 8; */
        if (message.statusUpdated)
            Timestamp.internalBinaryWrite(message.statusUpdated, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.OpenBankingConnection
 */
export const OpenBankingConnection = new OpenBankingConnection$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OpenBankingAccount$Type extends MessageType<OpenBankingAccount> {
    constructor() {
        super("one.plan3t.core.model.OpenBankingAccount", [
            { no: 1, name: "display_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "account_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OpenBankingAccount>): OpenBankingAccount {
        const message = { displayName: "", accountNumber: "", id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OpenBankingAccount>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OpenBankingAccount): OpenBankingAccount {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string display_name */ 1:
                    message.displayName = reader.string();
                    break;
                case /* string account_number */ 2:
                    message.accountNumber = reader.string();
                    break;
                case /* string id */ 3:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OpenBankingAccount, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string display_name = 1; */
        if (message.displayName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.displayName);
        /* string account_number = 2; */
        if (message.accountNumber !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.accountNumber);
        /* string id = 3; */
        if (message.id !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.OpenBankingAccount
 */
export const OpenBankingAccount = new OpenBankingAccount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OpenBankingWaitlistSignupRequest$Type extends MessageType<OpenBankingWaitlistSignupRequest> {
    constructor() {
        super("one.plan3t.core.model.OpenBankingWaitlistSignupRequest", [
            { no: 1, name: "email", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "origin", kind: "enum", T: () => ["one.plan3t.core.model.OpenBankingWaitlistSignupOrigin", OpenBankingWaitlistSignupOrigin] }
        ]);
    }
    create(value?: PartialMessage<OpenBankingWaitlistSignupRequest>): OpenBankingWaitlistSignupRequest {
        const message = { email: "", origin: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OpenBankingWaitlistSignupRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OpenBankingWaitlistSignupRequest): OpenBankingWaitlistSignupRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string email */ 1:
                    message.email = reader.string();
                    break;
                case /* one.plan3t.core.model.OpenBankingWaitlistSignupOrigin origin */ 2:
                    message.origin = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OpenBankingWaitlistSignupRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string email = 1; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* one.plan3t.core.model.OpenBankingWaitlistSignupOrigin origin = 2; */
        if (message.origin !== 0)
            writer.tag(2, WireType.Varint).int32(message.origin);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.OpenBankingWaitlistSignupRequest
 */
export const OpenBankingWaitlistSignupRequest = new OpenBankingWaitlistSignupRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OpenBankingWaitlistSignupResponse$Type extends MessageType<OpenBankingWaitlistSignupResponse> {
    constructor() {
        super("one.plan3t.core.model.OpenBankingWaitlistSignupResponse", []);
    }
    create(value?: PartialMessage<OpenBankingWaitlistSignupResponse>): OpenBankingWaitlistSignupResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OpenBankingWaitlistSignupResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OpenBankingWaitlistSignupResponse): OpenBankingWaitlistSignupResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: OpenBankingWaitlistSignupResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.OpenBankingWaitlistSignupResponse
 */
export const OpenBankingWaitlistSignupResponse = new OpenBankingWaitlistSignupResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OpenBankingWaitlistConfirmRequest$Type extends MessageType<OpenBankingWaitlistConfirmRequest> {
    constructor() {
        super("one.plan3t.core.model.OpenBankingWaitlistConfirmRequest", [
            { no: 1, name: "confirmation_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OpenBankingWaitlistConfirmRequest>): OpenBankingWaitlistConfirmRequest {
        const message = { confirmationCode: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OpenBankingWaitlistConfirmRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OpenBankingWaitlistConfirmRequest): OpenBankingWaitlistConfirmRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string confirmation_code */ 1:
                    message.confirmationCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OpenBankingWaitlistConfirmRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string confirmation_code = 1; */
        if (message.confirmationCode !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.confirmationCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.OpenBankingWaitlistConfirmRequest
 */
export const OpenBankingWaitlistConfirmRequest = new OpenBankingWaitlistConfirmRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OpenBankingWaitlistConfirmResponse$Type extends MessageType<OpenBankingWaitlistConfirmResponse> {
    constructor() {
        super("one.plan3t.core.model.OpenBankingWaitlistConfirmResponse", []);
    }
    create(value?: PartialMessage<OpenBankingWaitlistConfirmResponse>): OpenBankingWaitlistConfirmResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OpenBankingWaitlistConfirmResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OpenBankingWaitlistConfirmResponse): OpenBankingWaitlistConfirmResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: OpenBankingWaitlistConfirmResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.OpenBankingWaitlistConfirmResponse
 */
export const OpenBankingWaitlistConfirmResponse = new OpenBankingWaitlistConfirmResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChallengesCategoriesRequest$Type extends MessageType<GetChallengesCategoriesRequest> {
    constructor() {
        super("one.plan3t.core.model.GetChallengesCategoriesRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetChallengesCategoriesRequest>): GetChallengesCategoriesRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetChallengesCategoriesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetChallengesCategoriesRequest): GetChallengesCategoriesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetChallengesCategoriesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetChallengesCategoriesRequest
 */
export const GetChallengesCategoriesRequest = new GetChallengesCategoriesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetChallengesCategoriesResponse$Type extends MessageType<GetChallengesCategoriesResponse> {
    constructor() {
        super("one.plan3t.core.model.GetChallengesCategoriesResponse", [
            { no: 1, name: "categories", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ItemCategory },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetChallengesCategoriesResponse>): GetChallengesCategoriesResponse {
        const message = { categories: [], nextPageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetChallengesCategoriesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetChallengesCategoriesResponse): GetChallengesCategoriesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.ItemCategory categories */ 1:
                    message.categories.push(ItemCategory.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetChallengesCategoriesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.ItemCategory categories = 1; */
        for (let i = 0; i < message.categories.length; i++)
            ItemCategory.internalBinaryWrite(message.categories[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetChallengesCategoriesResponse
 */
export const GetChallengesCategoriesResponse = new GetChallengesCategoriesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRecommendedChallengesRequest$Type extends MessageType<GetRecommendedChallengesRequest> {
    constructor() {
        super("one.plan3t.core.model.GetRecommendedChallengesRequest", [
            { no: 1, name: "potentials", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["one.plan3t.core.model.CO2SavingPotential", CO2SavingPotential] }
        ]);
    }
    create(value?: PartialMessage<GetRecommendedChallengesRequest>): GetRecommendedChallengesRequest {
        const message = { potentials: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRecommendedChallengesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRecommendedChallengesRequest): GetRecommendedChallengesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.CO2SavingPotential potentials */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.potentials.push(reader.int32());
                    else
                        message.potentials.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRecommendedChallengesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.CO2SavingPotential potentials = 1; */
        if (message.potentials.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.potentials.length; i++)
                writer.int32(message.potentials[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRecommendedChallengesRequest
 */
export const GetRecommendedChallengesRequest = new GetRecommendedChallengesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRecommendedChallengesResponse$Type extends MessageType<GetRecommendedChallengesResponse> {
    constructor() {
        super("one.plan3t.core.model.GetRecommendedChallengesResponse", [
            { no: 1, name: "challenges", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ChallengePreview }
        ]);
    }
    create(value?: PartialMessage<GetRecommendedChallengesResponse>): GetRecommendedChallengesResponse {
        const message = { challenges: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRecommendedChallengesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRecommendedChallengesResponse): GetRecommendedChallengesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.ChallengePreview challenges */ 1:
                    message.challenges.push(ChallengePreview.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRecommendedChallengesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.ChallengePreview challenges = 1; */
        for (let i = 0; i < message.challenges.length; i++)
            ChallengePreview.internalBinaryWrite(message.challenges[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRecommendedChallengesResponse
 */
export const GetRecommendedChallengesResponse = new GetRecommendedChallengesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOpenBankingWaitlistUserStatusResponse$Type extends MessageType<GetOpenBankingWaitlistUserStatusResponse> {
    constructor() {
        super("one.plan3t.core.model.GetOpenBankingWaitlistUserStatusResponse", [
            { no: 1, name: "status", kind: "enum", T: () => ["one.plan3t.core.model.OpenBankingWaitlistUserStatus", OpenBankingWaitlistUserStatus] }
        ]);
    }
    create(value?: PartialMessage<GetOpenBankingWaitlistUserStatusResponse>): GetOpenBankingWaitlistUserStatusResponse {
        const message = { status: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOpenBankingWaitlistUserStatusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOpenBankingWaitlistUserStatusResponse): GetOpenBankingWaitlistUserStatusResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.OpenBankingWaitlistUserStatus status */ 1:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOpenBankingWaitlistUserStatusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.OpenBankingWaitlistUserStatus status = 1; */
        if (message.status !== 0)
            writer.tag(1, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetOpenBankingWaitlistUserStatusResponse
 */
export const GetOpenBankingWaitlistUserStatusResponse = new GetOpenBankingWaitlistUserStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFeedRequest$Type extends MessageType<GetFeedRequest> {
    constructor() {
        super("one.plan3t.core.model.GetFeedRequest", [
            { no: 1, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetFeedRequest>): GetFeedRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetFeedRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFeedRequest): GetFeedRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 page_size */ 1:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 2:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFeedRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 page_size = 1; */
        if (message.pageSize !== 0)
            writer.tag(1, WireType.Varint).int32(message.pageSize);
        /* string page_token = 2; */
        if (message.pageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetFeedRequest
 */
export const GetFeedRequest = new GetFeedRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFeedResponse$Type extends MessageType<GetFeedResponse> {
    constructor() {
        super("one.plan3t.core.model.GetFeedResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FeedItem },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetFeedResponse>): GetFeedResponse {
        const message = { items: [], nextPageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetFeedResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFeedResponse): GetFeedResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.FeedItem items */ 1:
                    message.items.push(FeedItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFeedResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.FeedItem items = 1; */
        for (let i = 0; i < message.items.length; i++)
            FeedItem.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetFeedResponse
 */
export const GetFeedResponse = new GetFeedResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompensateWheel$Type extends MessageType<CompensateWheel> {
    constructor() {
        super("one.plan3t.core.model.CompensateWheel", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "starts_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "ends_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "targetDate", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "tagLine", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "image", kind: "message", T: () => ImageResource },
            { no: 8, name: "wheel_background_image", kind: "message", T: () => ImageResource },
            { no: 9, name: "wheel_title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "price_to_spin", kind: "message", T: () => Plan3TPoint },
            { no: 11, name: "outcomes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CompensateWheelOutcome }
        ]);
    }
    create(value?: PartialMessage<CompensateWheel>): CompensateWheel {
        const message = { id: "", targetDate: "", title: "", tagLine: "", wheelTitle: "", outcomes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompensateWheel>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompensateWheel): CompensateWheel {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* google.protobuf.Timestamp starts_at */ 2:
                    message.startsAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.startsAt);
                    break;
                case /* google.protobuf.Timestamp ends_at */ 3:
                    message.endsAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.endsAt);
                    break;
                case /* string targetDate */ 4:
                    message.targetDate = reader.string();
                    break;
                case /* string title */ 5:
                    message.title = reader.string();
                    break;
                case /* string tagLine */ 6:
                    message.tagLine = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource image */ 7:
                    message.image = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.image);
                    break;
                case /* one.plan3t.core.model.ImageResource wheel_background_image */ 8:
                    message.wheelBackgroundImage = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.wheelBackgroundImage);
                    break;
                case /* string wheel_title */ 9:
                    message.wheelTitle = reader.string();
                    break;
                case /* one.plan3t.core.model.Plan3TPoint price_to_spin */ 10:
                    message.priceToSpin = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.priceToSpin);
                    break;
                case /* repeated one.plan3t.core.model.CompensateWheelOutcome outcomes */ 11:
                    message.outcomes.push(CompensateWheelOutcome.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompensateWheel, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* google.protobuf.Timestamp starts_at = 2; */
        if (message.startsAt)
            Timestamp.internalBinaryWrite(message.startsAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp ends_at = 3; */
        if (message.endsAt)
            Timestamp.internalBinaryWrite(message.endsAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string targetDate = 4; */
        if (message.targetDate !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.targetDate);
        /* string title = 5; */
        if (message.title !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.title);
        /* string tagLine = 6; */
        if (message.tagLine !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.tagLine);
        /* one.plan3t.core.model.ImageResource image = 7; */
        if (message.image)
            ImageResource.internalBinaryWrite(message.image, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.ImageResource wheel_background_image = 8; */
        if (message.wheelBackgroundImage)
            ImageResource.internalBinaryWrite(message.wheelBackgroundImage, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* string wheel_title = 9; */
        if (message.wheelTitle !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.wheelTitle);
        /* one.plan3t.core.model.Plan3TPoint price_to_spin = 10; */
        if (message.priceToSpin)
            Plan3TPoint.internalBinaryWrite(message.priceToSpin, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* repeated one.plan3t.core.model.CompensateWheelOutcome outcomes = 11; */
        for (let i = 0; i < message.outcomes.length; i++)
            CompensateWheelOutcome.internalBinaryWrite(message.outcomes[i], writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CompensateWheel
 */
export const CompensateWheel = new CompensateWheel$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompensateWheelOutcome$Type extends MessageType<CompensateWheelOutcome> {
    constructor() {
        super("one.plan3t.core.model.CompensateWheelOutcome", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "chance", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "amountInKg", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "emoji", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "human_readable_equivalent", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CompensateWheelOutcome>): CompensateWheelOutcome {
        const message = { id: "", projectId: "", chance: 0, amountInKg: 0, emoji: "", humanReadableEquivalent: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompensateWheelOutcome>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompensateWheelOutcome): CompensateWheelOutcome {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string project_id */ 2:
                    message.projectId = reader.string();
                    break;
                case /* double chance */ 3:
                    message.chance = reader.double();
                    break;
                case /* int32 amountInKg */ 4:
                    message.amountInKg = reader.int32();
                    break;
                case /* string emoji */ 5:
                    message.emoji = reader.string();
                    break;
                case /* string human_readable_equivalent */ 6:
                    message.humanReadableEquivalent = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompensateWheelOutcome, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string project_id = 2; */
        if (message.projectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.projectId);
        /* double chance = 3; */
        if (message.chance !== 0)
            writer.tag(3, WireType.Bit64).double(message.chance);
        /* int32 amountInKg = 4; */
        if (message.amountInKg !== 0)
            writer.tag(4, WireType.Varint).int32(message.amountInKg);
        /* string emoji = 5; */
        if (message.emoji !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.emoji);
        /* string human_readable_equivalent = 6; */
        if (message.humanReadableEquivalent !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.humanReadableEquivalent);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CompensateWheelOutcome
 */
export const CompensateWheelOutcome = new CompensateWheelOutcome$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCompensateWheelsRequest$Type extends MessageType<GetCompensateWheelsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetCompensateWheelsRequest", [
            { no: 1, name: "ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetCompensateWheelsRequest>): GetCompensateWheelsRequest {
        const message = { ids: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCompensateWheelsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCompensateWheelsRequest): GetCompensateWheelsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string ids */ 1:
                    message.ids.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCompensateWheelsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string ids = 1; */
        for (let i = 0; i < message.ids.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.ids[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetCompensateWheelsRequest
 */
export const GetCompensateWheelsRequest = new GetCompensateWheelsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCompensateWheelsResponse$Type extends MessageType<GetCompensateWheelsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetCompensateWheelsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CompensateWheel }
        ]);
    }
    create(value?: PartialMessage<GetCompensateWheelsResponse>): GetCompensateWheelsResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCompensateWheelsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCompensateWheelsResponse): GetCompensateWheelsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.CompensateWheel items */ 1:
                    message.items.push(CompensateWheel.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCompensateWheelsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.CompensateWheel items = 1; */
        for (let i = 0; i < message.items.length; i++)
            CompensateWheel.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetCompensateWheelsResponse
 */
export const GetCompensateWheelsResponse = new GetCompensateWheelsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BuyCompensateWheelSpinRequest$Type extends MessageType<BuyCompensateWheelSpinRequest> {
    constructor() {
        super("one.plan3t.core.model.BuyCompensateWheelSpinRequest", [
            { no: 1, name: "spin_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "wheel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "lastEventId", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "points", kind: "message", T: () => Plan3TPoint }
        ]);
    }
    create(value?: PartialMessage<BuyCompensateWheelSpinRequest>): BuyCompensateWheelSpinRequest {
        const message = { spinId: "", wheelId: "", lastEventId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BuyCompensateWheelSpinRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BuyCompensateWheelSpinRequest): BuyCompensateWheelSpinRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string spin_id */ 1:
                    message.spinId = reader.string();
                    break;
                case /* string wheel_id */ 2:
                    message.wheelId = reader.string();
                    break;
                case /* string lastEventId */ 3:
                    message.lastEventId = reader.string();
                    break;
                case /* one.plan3t.core.model.Plan3TPoint points */ 4:
                    message.points = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.points);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BuyCompensateWheelSpinRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string spin_id = 1; */
        if (message.spinId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.spinId);
        /* string wheel_id = 2; */
        if (message.wheelId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.wheelId);
        /* string lastEventId = 3; */
        if (message.lastEventId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.lastEventId);
        /* one.plan3t.core.model.Plan3TPoint points = 4; */
        if (message.points)
            Plan3TPoint.internalBinaryWrite(message.points, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.BuyCompensateWheelSpinRequest
 */
export const BuyCompensateWheelSpinRequest = new BuyCompensateWheelSpinRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BuyCompensateWheelSpinResponse$Type extends MessageType<BuyCompensateWheelSpinResponse> {
    constructor() {
        super("one.plan3t.core.model.BuyCompensateWheelSpinResponse", [
            { no: 1, name: "result", kind: "message", T: () => CompensateWheelSpin }
        ]);
    }
    create(value?: PartialMessage<BuyCompensateWheelSpinResponse>): BuyCompensateWheelSpinResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BuyCompensateWheelSpinResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BuyCompensateWheelSpinResponse): BuyCompensateWheelSpinResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.CompensateWheelSpin result */ 1:
                    message.result = CompensateWheelSpin.internalBinaryRead(reader, reader.uint32(), options, message.result);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BuyCompensateWheelSpinResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.CompensateWheelSpin result = 1; */
        if (message.result)
            CompensateWheelSpin.internalBinaryWrite(message.result, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.BuyCompensateWheelSpinResponse
 */
export const BuyCompensateWheelSpinResponse = new BuyCompensateWheelSpinResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompensateWheelSpin$Type extends MessageType<CompensateWheelSpin> {
    constructor() {
        super("one.plan3t.core.model.CompensateWheelSpin", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "points", kind: "message", T: () => Plan3TPoint },
            { no: 3, name: "compensate_wheel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "compensate_wheel_outcome_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "target_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "created_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<CompensateWheelSpin>): CompensateWheelSpin {
        const message = { id: "", compensateWheelId: "", compensateWheelOutcomeId: "", targetDate: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompensateWheelSpin>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompensateWheelSpin): CompensateWheelSpin {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* one.plan3t.core.model.Plan3TPoint points */ 2:
                    message.points = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.points);
                    break;
                case /* string compensate_wheel_id */ 3:
                    message.compensateWheelId = reader.string();
                    break;
                case /* string compensate_wheel_outcome_id */ 4:
                    message.compensateWheelOutcomeId = reader.string();
                    break;
                case /* string target_date */ 5:
                    message.targetDate = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 6:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompensateWheelSpin, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* one.plan3t.core.model.Plan3TPoint points = 2; */
        if (message.points)
            Plan3TPoint.internalBinaryWrite(message.points, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string compensate_wheel_id = 3; */
        if (message.compensateWheelId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.compensateWheelId);
        /* string compensate_wheel_outcome_id = 4; */
        if (message.compensateWheelOutcomeId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.compensateWheelOutcomeId);
        /* string target_date = 5; */
        if (message.targetDate !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.targetDate);
        /* google.protobuf.Timestamp created_at = 6; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CompensateWheelSpin
 */
export const CompensateWheelSpin = new CompensateWheelSpin$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCompensateWheelSpinsRequest$Type extends MessageType<GetCompensateWheelSpinsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetCompensateWheelSpinsRequest", []);
    }
    create(value?: PartialMessage<GetCompensateWheelSpinsRequest>): GetCompensateWheelSpinsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCompensateWheelSpinsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCompensateWheelSpinsRequest): GetCompensateWheelSpinsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetCompensateWheelSpinsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetCompensateWheelSpinsRequest
 */
export const GetCompensateWheelSpinsRequest = new GetCompensateWheelSpinsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCompensateWheelSpinsResponse$Type extends MessageType<GetCompensateWheelSpinsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetCompensateWheelSpinsResponse", [
            { no: 1, name: "spins", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => CompensateWheelSpin }
        ]);
    }
    create(value?: PartialMessage<GetCompensateWheelSpinsResponse>): GetCompensateWheelSpinsResponse {
        const message = { spins: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCompensateWheelSpinsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCompensateWheelSpinsResponse): GetCompensateWheelSpinsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.CompensateWheelSpin spins */ 1:
                    message.spins.push(CompensateWheelSpin.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCompensateWheelSpinsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.CompensateWheelSpin spins = 1; */
        for (let i = 0; i < message.spins.length; i++)
            CompensateWheelSpin.internalBinaryWrite(message.spins[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetCompensateWheelSpinsResponse
 */
export const GetCompensateWheelSpinsResponse = new GetCompensateWheelSpinsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteAccountRequest$Type extends MessageType<DeleteAccountRequest> {
    constructor() {
        super("one.plan3t.core.model.DeleteAccountRequest", [
            { no: 1, name: "type", kind: "enum", T: () => ["one.plan3t.core.model.UserAccountType", UserAccountType, "USER_ACCOUNT_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<DeleteAccountRequest>): DeleteAccountRequest {
        const message = { type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteAccountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteAccountRequest): DeleteAccountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.UserAccountType type */ 1:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteAccountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.UserAccountType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.DeleteAccountRequest
 */
export const DeleteAccountRequest = new DeleteAccountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteAccountResponse$Type extends MessageType<DeleteAccountResponse> {
    constructor() {
        super("one.plan3t.core.model.DeleteAccountResponse", []);
    }
    create(value?: PartialMessage<DeleteAccountResponse>): DeleteAccountResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteAccountResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteAccountResponse): DeleteAccountResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteAccountResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.DeleteAccountResponse
 */
export const DeleteAccountResponse = new DeleteAccountResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReportMissingCashbackRequest$Type extends MessageType<ReportMissingCashbackRequest> {
    constructor() {
        super("one.plan3t.core.model.ReportMissingCashbackRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "purchase_date", kind: "message", T: () => Timestamp },
            { no: 3, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "amount_unscaled", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "amount_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<ReportMissingCashbackRequest>): ReportMissingCashbackRequest {
        const message = { id: "", partnerId: "", accountId: "", amountUnscaled: 0, amountScale: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReportMissingCashbackRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReportMissingCashbackRequest): ReportMissingCashbackRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* google.protobuf.Timestamp purchase_date */ 2:
                    message.purchaseDate = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.purchaseDate);
                    break;
                case /* string partner_id */ 3:
                    message.partnerId = reader.string();
                    break;
                case /* string account_id */ 4:
                    message.accountId = reader.string();
                    break;
                case /* int32 amount_unscaled */ 5:
                    message.amountUnscaled = reader.int32();
                    break;
                case /* int32 amount_scale */ 6:
                    message.amountScale = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReportMissingCashbackRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* google.protobuf.Timestamp purchase_date = 2; */
        if (message.purchaseDate)
            Timestamp.internalBinaryWrite(message.purchaseDate, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string partner_id = 3; */
        if (message.partnerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.partnerId);
        /* string account_id = 4; */
        if (message.accountId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.accountId);
        /* int32 amount_unscaled = 5; */
        if (message.amountUnscaled !== 0)
            writer.tag(5, WireType.Varint).int32(message.amountUnscaled);
        /* int32 amount_scale = 6; */
        if (message.amountScale !== 0)
            writer.tag(6, WireType.Varint).int32(message.amountScale);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ReportMissingCashbackRequest
 */
export const ReportMissingCashbackRequest = new ReportMissingCashbackRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReportMissingCashbackResponse$Type extends MessageType<ReportMissingCashbackResponse> {
    constructor() {
        super("one.plan3t.core.model.ReportMissingCashbackResponse", []);
    }
    create(value?: PartialMessage<ReportMissingCashbackResponse>): ReportMissingCashbackResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReportMissingCashbackResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReportMissingCashbackResponse): ReportMissingCashbackResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ReportMissingCashbackResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ReportMissingCashbackResponse
 */
export const ReportMissingCashbackResponse = new ReportMissingCashbackResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OpenBankingTransaction$Type extends MessageType<OpenBankingTransaction> {
    constructor() {
        super("one.plan3t.core.model.OpenBankingTransaction", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "transaction_amount_unscaled", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "transaction_amount_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "transaction_description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "transaction_date", kind: "message", T: () => Timestamp },
            { no: 10, name: "category", kind: "message", T: () => OpenBankingTransaction_CategoryClassification },
            { no: 11, name: "cashback", kind: "message", T: () => OpenBankingTransaction_CashbackClassification },
            { no: 12, name: "co2e", kind: "message", jsonName: "co2e", T: () => OpenBankingTransaction_Co2EClassification },
            { no: 13, name: "partner", kind: "message", T: () => OpenBankingTransaction_PartnerClassification },
            { no: 14, name: "modifier_choices", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Any } }
        ]);
    }
    create(value?: PartialMessage<OpenBankingTransaction>): OpenBankingTransaction {
        const message = { id: "", userId: "", accountId: "", transactionAmountUnscaled: 0, transactionAmountScale: 0, transactionDescription: "", modifierChoices: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OpenBankingTransaction>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OpenBankingTransaction): OpenBankingTransaction {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string user_id */ 2:
                    message.userId = reader.string();
                    break;
                case /* string account_id */ 3:
                    message.accountId = reader.string();
                    break;
                case /* int32 transaction_amount_unscaled */ 4:
                    message.transactionAmountUnscaled = reader.int32();
                    break;
                case /* int32 transaction_amount_scale */ 5:
                    message.transactionAmountScale = reader.int32();
                    break;
                case /* string transaction_description */ 6:
                    message.transactionDescription = reader.string();
                    break;
                case /* google.protobuf.Timestamp transaction_date */ 7:
                    message.transactionDate = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.transactionDate);
                    break;
                case /* one.plan3t.core.model.OpenBankingTransaction.CategoryClassification category */ 10:
                    message.category = OpenBankingTransaction_CategoryClassification.internalBinaryRead(reader, reader.uint32(), options, message.category);
                    break;
                case /* one.plan3t.core.model.OpenBankingTransaction.CashbackClassification cashback */ 11:
                    message.cashback = OpenBankingTransaction_CashbackClassification.internalBinaryRead(reader, reader.uint32(), options, message.cashback);
                    break;
                case /* one.plan3t.core.model.OpenBankingTransaction.Co2EClassification co2e = 12 [json_name = "co2e"];*/ 12:
                    message.co2E = OpenBankingTransaction_Co2EClassification.internalBinaryRead(reader, reader.uint32(), options, message.co2E);
                    break;
                case /* one.plan3t.core.model.OpenBankingTransaction.PartnerClassification partner */ 13:
                    message.partner = OpenBankingTransaction_PartnerClassification.internalBinaryRead(reader, reader.uint32(), options, message.partner);
                    break;
                case /* map<string, google.protobuf.Any> modifier_choices */ 14:
                    this.binaryReadMap14(message.modifierChoices, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap14(map: OpenBankingTransaction["modifierChoices"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof OpenBankingTransaction["modifierChoices"] | undefined, val: OpenBankingTransaction["modifierChoices"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Any.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.model.OpenBankingTransaction.modifier_choices");
            }
        }
        map[key ?? ""] = val ?? Any.create();
    }
    internalBinaryWrite(message: OpenBankingTransaction, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string user_id = 2; */
        if (message.userId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        /* string account_id = 3; */
        if (message.accountId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.accountId);
        /* int32 transaction_amount_unscaled = 4; */
        if (message.transactionAmountUnscaled !== 0)
            writer.tag(4, WireType.Varint).int32(message.transactionAmountUnscaled);
        /* int32 transaction_amount_scale = 5; */
        if (message.transactionAmountScale !== 0)
            writer.tag(5, WireType.Varint).int32(message.transactionAmountScale);
        /* string transaction_description = 6; */
        if (message.transactionDescription !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.transactionDescription);
        /* google.protobuf.Timestamp transaction_date = 7; */
        if (message.transactionDate)
            Timestamp.internalBinaryWrite(message.transactionDate, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.OpenBankingTransaction.CategoryClassification category = 10; */
        if (message.category)
            OpenBankingTransaction_CategoryClassification.internalBinaryWrite(message.category, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.OpenBankingTransaction.CashbackClassification cashback = 11; */
        if (message.cashback)
            OpenBankingTransaction_CashbackClassification.internalBinaryWrite(message.cashback, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.OpenBankingTransaction.Co2EClassification co2e = 12 [json_name = "co2e"]; */
        if (message.co2E)
            OpenBankingTransaction_Co2EClassification.internalBinaryWrite(message.co2E, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.OpenBankingTransaction.PartnerClassification partner = 13; */
        if (message.partner)
            OpenBankingTransaction_PartnerClassification.internalBinaryWrite(message.partner, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* map<string, google.protobuf.Any> modifier_choices = 14; */
        for (let k of Object.keys(message.modifierChoices)) {
            writer.tag(14, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Any.internalBinaryWrite(message.modifierChoices[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.OpenBankingTransaction
 */
export const OpenBankingTransaction = new OpenBankingTransaction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OpenBankingTransaction_CategoryClassification$Type extends MessageType<OpenBankingTransaction_CategoryClassification> {
    constructor() {
        super("one.plan3t.core.model.OpenBankingTransaction.CategoryClassification", [
            { no: 1, name: "state", kind: "enum", T: () => ["one.plan3t.core.model.OpenBankingTransaction.ClassificationState", OpenBankingTransaction_ClassificationState] },
            { no: 2, name: "category_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OpenBankingTransaction_CategoryClassification>): OpenBankingTransaction_CategoryClassification {
        const message = { state: 0, categoryId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OpenBankingTransaction_CategoryClassification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OpenBankingTransaction_CategoryClassification): OpenBankingTransaction_CategoryClassification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.OpenBankingTransaction.ClassificationState state */ 1:
                    message.state = reader.int32();
                    break;
                case /* string category_id */ 2:
                    message.categoryId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OpenBankingTransaction_CategoryClassification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.OpenBankingTransaction.ClassificationState state = 1; */
        if (message.state !== 0)
            writer.tag(1, WireType.Varint).int32(message.state);
        /* string category_id = 2; */
        if (message.categoryId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.categoryId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.OpenBankingTransaction.CategoryClassification
 */
export const OpenBankingTransaction_CategoryClassification = new OpenBankingTransaction_CategoryClassification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OpenBankingTransaction_CashbackClassification$Type extends MessageType<OpenBankingTransaction_CashbackClassification> {
    constructor() {
        super("one.plan3t.core.model.OpenBankingTransaction.CashbackClassification", [
            { no: 1, name: "state", kind: "enum", T: () => ["one.plan3t.core.model.OpenBankingTransaction.ClassificationState", OpenBankingTransaction_ClassificationState] },
            { no: 2, name: "amount", kind: "message", T: () => Plan3TPoint }
        ]);
    }
    create(value?: PartialMessage<OpenBankingTransaction_CashbackClassification>): OpenBankingTransaction_CashbackClassification {
        const message = { state: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OpenBankingTransaction_CashbackClassification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OpenBankingTransaction_CashbackClassification): OpenBankingTransaction_CashbackClassification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.OpenBankingTransaction.ClassificationState state */ 1:
                    message.state = reader.int32();
                    break;
                case /* one.plan3t.core.model.Plan3TPoint amount */ 2:
                    message.amount = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OpenBankingTransaction_CashbackClassification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.OpenBankingTransaction.ClassificationState state = 1; */
        if (message.state !== 0)
            writer.tag(1, WireType.Varint).int32(message.state);
        /* one.plan3t.core.model.Plan3TPoint amount = 2; */
        if (message.amount)
            Plan3TPoint.internalBinaryWrite(message.amount, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.OpenBankingTransaction.CashbackClassification
 */
export const OpenBankingTransaction_CashbackClassification = new OpenBankingTransaction_CashbackClassification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OpenBankingTransaction_Co2EClassification$Type extends MessageType<OpenBankingTransaction_Co2EClassification> {
    constructor() {
        super("one.plan3t.core.model.OpenBankingTransaction.Co2EClassification", [
            { no: 1, name: "state", kind: "enum", T: () => ["one.plan3t.core.model.OpenBankingTransaction.ClassificationState", OpenBankingTransaction_ClassificationState] },
            { no: 2, name: "amount_unscaled", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "amount_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<OpenBankingTransaction_Co2EClassification>): OpenBankingTransaction_Co2EClassification {
        const message = { state: 0, amountUnscaled: 0, amountScale: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OpenBankingTransaction_Co2EClassification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OpenBankingTransaction_Co2EClassification): OpenBankingTransaction_Co2EClassification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.OpenBankingTransaction.ClassificationState state */ 1:
                    message.state = reader.int32();
                    break;
                case /* int32 amount_unscaled */ 2:
                    message.amountUnscaled = reader.int32();
                    break;
                case /* int32 amount_scale */ 3:
                    message.amountScale = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OpenBankingTransaction_Co2EClassification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.OpenBankingTransaction.ClassificationState state = 1; */
        if (message.state !== 0)
            writer.tag(1, WireType.Varint).int32(message.state);
        /* int32 amount_unscaled = 2; */
        if (message.amountUnscaled !== 0)
            writer.tag(2, WireType.Varint).int32(message.amountUnscaled);
        /* int32 amount_scale = 3; */
        if (message.amountScale !== 0)
            writer.tag(3, WireType.Varint).int32(message.amountScale);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.OpenBankingTransaction.Co2EClassification
 */
export const OpenBankingTransaction_Co2EClassification = new OpenBankingTransaction_Co2EClassification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OpenBankingTransaction_PartnerClassification$Type extends MessageType<OpenBankingTransaction_PartnerClassification> {
    constructor() {
        super("one.plan3t.core.model.OpenBankingTransaction.PartnerClassification", [
            { no: 1, name: "state", kind: "enum", T: () => ["one.plan3t.core.model.OpenBankingTransaction.ClassificationState", OpenBankingTransaction_ClassificationState] },
            { no: 2, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OpenBankingTransaction_PartnerClassification>): OpenBankingTransaction_PartnerClassification {
        const message = { state: 0, partnerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OpenBankingTransaction_PartnerClassification>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OpenBankingTransaction_PartnerClassification): OpenBankingTransaction_PartnerClassification {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.OpenBankingTransaction.ClassificationState state */ 1:
                    message.state = reader.int32();
                    break;
                case /* string partner_id */ 2:
                    message.partnerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OpenBankingTransaction_PartnerClassification, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.OpenBankingTransaction.ClassificationState state = 1; */
        if (message.state !== 0)
            writer.tag(1, WireType.Varint).int32(message.state);
        /* string partner_id = 2; */
        if (message.partnerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.partnerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.OpenBankingTransaction.PartnerClassification
 */
export const OpenBankingTransaction_PartnerClassification = new OpenBankingTransaction_PartnerClassification$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOpenBankingTransactionsRequest$Type extends MessageType<GetOpenBankingTransactionsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetOpenBankingTransactionsRequest", [
            { no: 1, name: "date", kind: "message", oneof: "filter", T: () => Date },
            { no: 2, name: "ids", kind: "message", oneof: "filter", T: () => GetOpenBankingTransactionsRequest_IdsWrapper }
        ]);
    }
    create(value?: PartialMessage<GetOpenBankingTransactionsRequest>): GetOpenBankingTransactionsRequest {
        const message = { filter: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOpenBankingTransactionsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOpenBankingTransactionsRequest): GetOpenBankingTransactionsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.Date date */ 1:
                    message.filter = {
                        oneofKind: "date",
                        date: Date.internalBinaryRead(reader, reader.uint32(), options, (message.filter as any).date)
                    };
                    break;
                case /* one.plan3t.core.model.GetOpenBankingTransactionsRequest.IdsWrapper ids */ 2:
                    message.filter = {
                        oneofKind: "ids",
                        ids: GetOpenBankingTransactionsRequest_IdsWrapper.internalBinaryRead(reader, reader.uint32(), options, (message.filter as any).ids)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOpenBankingTransactionsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.Date date = 1; */
        if (message.filter.oneofKind === "date")
            Date.internalBinaryWrite(message.filter.date, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.GetOpenBankingTransactionsRequest.IdsWrapper ids = 2; */
        if (message.filter.oneofKind === "ids")
            GetOpenBankingTransactionsRequest_IdsWrapper.internalBinaryWrite(message.filter.ids, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetOpenBankingTransactionsRequest
 */
export const GetOpenBankingTransactionsRequest = new GetOpenBankingTransactionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOpenBankingTransactionsRequest_IdsWrapper$Type extends MessageType<GetOpenBankingTransactionsRequest_IdsWrapper> {
    constructor() {
        super("one.plan3t.core.model.GetOpenBankingTransactionsRequest.IdsWrapper", [
            { no: 1, name: "ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetOpenBankingTransactionsRequest_IdsWrapper>): GetOpenBankingTransactionsRequest_IdsWrapper {
        const message = { ids: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOpenBankingTransactionsRequest_IdsWrapper>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOpenBankingTransactionsRequest_IdsWrapper): GetOpenBankingTransactionsRequest_IdsWrapper {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string ids */ 1:
                    message.ids.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOpenBankingTransactionsRequest_IdsWrapper, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string ids = 1; */
        for (let i = 0; i < message.ids.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.ids[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetOpenBankingTransactionsRequest.IdsWrapper
 */
export const GetOpenBankingTransactionsRequest_IdsWrapper = new GetOpenBankingTransactionsRequest_IdsWrapper$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOpenBankingTransactionsResponse$Type extends MessageType<GetOpenBankingTransactionsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetOpenBankingTransactionsResponse", [
            { no: 1, name: "transactions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OpenBankingTransaction }
        ]);
    }
    create(value?: PartialMessage<GetOpenBankingTransactionsResponse>): GetOpenBankingTransactionsResponse {
        const message = { transactions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOpenBankingTransactionsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOpenBankingTransactionsResponse): GetOpenBankingTransactionsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.OpenBankingTransaction transactions */ 1:
                    message.transactions.push(OpenBankingTransaction.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOpenBankingTransactionsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.OpenBankingTransaction transactions = 1; */
        for (let i = 0; i < message.transactions.length; i++)
            OpenBankingTransaction.internalBinaryWrite(message.transactions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetOpenBankingTransactionsResponse
 */
export const GetOpenBankingTransactionsResponse = new GetOpenBankingTransactionsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOpenBankingInfoRequest$Type extends MessageType<GetOpenBankingInfoRequest> {
    constructor() {
        super("one.plan3t.core.model.GetOpenBankingInfoRequest", []);
    }
    create(value?: PartialMessage<GetOpenBankingInfoRequest>): GetOpenBankingInfoRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOpenBankingInfoRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOpenBankingInfoRequest): GetOpenBankingInfoRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetOpenBankingInfoRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetOpenBankingInfoRequest
 */
export const GetOpenBankingInfoRequest = new GetOpenBankingInfoRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOpenBankingInfoResponse$Type extends MessageType<GetOpenBankingInfoResponse> {
    constructor() {
        super("one.plan3t.core.model.GetOpenBankingInfoResponse", [
            { no: 1, name: "earliest_date", kind: "message", T: () => Date }
        ]);
    }
    create(value?: PartialMessage<GetOpenBankingInfoResponse>): GetOpenBankingInfoResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOpenBankingInfoResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOpenBankingInfoResponse): GetOpenBankingInfoResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.Date earliest_date */ 1:
                    message.earliestDate = Date.internalBinaryRead(reader, reader.uint32(), options, message.earliestDate);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOpenBankingInfoResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.Date earliest_date = 1; */
        if (message.earliestDate)
            Date.internalBinaryWrite(message.earliestDate, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetOpenBankingInfoResponse
 */
export const GetOpenBankingInfoResponse = new GetOpenBankingInfoResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OpenBankingCategory$Type extends MessageType<OpenBankingCategory> {
    constructor() {
        super("one.plan3t.core.model.OpenBankingCategory", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "icon_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "color", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<OpenBankingCategory>): OpenBankingCategory {
        const message = { id: "", path: [], label: "", iconName: "", color: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OpenBankingCategory>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OpenBankingCategory): OpenBankingCategory {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* repeated string path */ 2:
                    message.path.push(reader.string());
                    break;
                case /* string label */ 3:
                    message.label = reader.string();
                    break;
                case /* string icon_name */ 4:
                    message.iconName = reader.string();
                    break;
                case /* int64 color */ 5:
                    message.color = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OpenBankingCategory, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* repeated string path = 2; */
        for (let i = 0; i < message.path.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.path[i]);
        /* string label = 3; */
        if (message.label !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.label);
        /* string icon_name = 4; */
        if (message.iconName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.iconName);
        /* int64 color = 5; */
        if (message.color !== 0)
            writer.tag(5, WireType.Varint).int64(message.color);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.OpenBankingCategory
 */
export const OpenBankingCategory = new OpenBankingCategory$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOpenBankingCategoriesRequest$Type extends MessageType<GetOpenBankingCategoriesRequest> {
    constructor() {
        super("one.plan3t.core.model.GetOpenBankingCategoriesRequest", [
            { no: 1, name: "ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetOpenBankingCategoriesRequest>): GetOpenBankingCategoriesRequest {
        const message = { ids: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOpenBankingCategoriesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOpenBankingCategoriesRequest): GetOpenBankingCategoriesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string ids */ 1:
                    message.ids.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOpenBankingCategoriesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string ids = 1; */
        for (let i = 0; i < message.ids.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.ids[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetOpenBankingCategoriesRequest
 */
export const GetOpenBankingCategoriesRequest = new GetOpenBankingCategoriesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOpenBankingCategoriesResponse$Type extends MessageType<GetOpenBankingCategoriesResponse> {
    constructor() {
        super("one.plan3t.core.model.GetOpenBankingCategoriesResponse", [
            { no: 1, name: "categories", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OpenBankingCategory }
        ]);
    }
    create(value?: PartialMessage<GetOpenBankingCategoriesResponse>): GetOpenBankingCategoriesResponse {
        const message = { categories: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOpenBankingCategoriesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOpenBankingCategoriesResponse): GetOpenBankingCategoriesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.OpenBankingCategory categories */ 1:
                    message.categories.push(OpenBankingCategory.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOpenBankingCategoriesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.OpenBankingCategory categories = 1; */
        for (let i = 0; i < message.categories.length; i++)
            OpenBankingCategory.internalBinaryWrite(message.categories[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetOpenBankingCategoriesResponse
 */
export const GetOpenBankingCategoriesResponse = new GetOpenBankingCategoriesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReclassifyOpenBankingTransactionRequest$Type extends MessageType<ReclassifyOpenBankingTransactionRequest> {
    constructor() {
        super("one.plan3t.core.model.ReclassifyOpenBankingTransactionRequest", [
            { no: 1, name: "request_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "transaction_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "current_category_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "new_category_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "modifier_choices", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Any } }
        ]);
    }
    create(value?: PartialMessage<ReclassifyOpenBankingTransactionRequest>): ReclassifyOpenBankingTransactionRequest {
        const message = { requestId: "", transactionId: "", currentCategoryId: "", newCategoryId: "", modifierChoices: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReclassifyOpenBankingTransactionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReclassifyOpenBankingTransactionRequest): ReclassifyOpenBankingTransactionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string request_id */ 1:
                    message.requestId = reader.string();
                    break;
                case /* string transaction_id */ 2:
                    message.transactionId = reader.string();
                    break;
                case /* string current_category_id */ 3:
                    message.currentCategoryId = reader.string();
                    break;
                case /* string new_category_id */ 4:
                    message.newCategoryId = reader.string();
                    break;
                case /* map<string, google.protobuf.Any> modifier_choices */ 5:
                    this.binaryReadMap5(message.modifierChoices, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap5(map: ReclassifyOpenBankingTransactionRequest["modifierChoices"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof ReclassifyOpenBankingTransactionRequest["modifierChoices"] | undefined, val: ReclassifyOpenBankingTransactionRequest["modifierChoices"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Any.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.model.ReclassifyOpenBankingTransactionRequest.modifier_choices");
            }
        }
        map[key ?? ""] = val ?? Any.create();
    }
    internalBinaryWrite(message: ReclassifyOpenBankingTransactionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string request_id = 1; */
        if (message.requestId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.requestId);
        /* string transaction_id = 2; */
        if (message.transactionId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.transactionId);
        /* string current_category_id = 3; */
        if (message.currentCategoryId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.currentCategoryId);
        /* string new_category_id = 4; */
        if (message.newCategoryId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.newCategoryId);
        /* map<string, google.protobuf.Any> modifier_choices = 5; */
        for (let k of Object.keys(message.modifierChoices)) {
            writer.tag(5, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Any.internalBinaryWrite(message.modifierChoices[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ReclassifyOpenBankingTransactionRequest
 */
export const ReclassifyOpenBankingTransactionRequest = new ReclassifyOpenBankingTransactionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReclassifyOpenBankingTransactionResponse$Type extends MessageType<ReclassifyOpenBankingTransactionResponse> {
    constructor() {
        super("one.plan3t.core.model.ReclassifyOpenBankingTransactionResponse", []);
    }
    create(value?: PartialMessage<ReclassifyOpenBankingTransactionResponse>): ReclassifyOpenBankingTransactionResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReclassifyOpenBankingTransactionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReclassifyOpenBankingTransactionResponse): ReclassifyOpenBankingTransactionResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ReclassifyOpenBankingTransactionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ReclassifyOpenBankingTransactionResponse
 */
export const ReclassifyOpenBankingTransactionResponse = new ReclassifyOpenBankingTransactionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOpenBankingFetchStatusRequest$Type extends MessageType<GetOpenBankingFetchStatusRequest> {
    constructor() {
        super("one.plan3t.core.model.GetOpenBankingFetchStatusRequest", [
            { no: 1, name: "fetch_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetOpenBankingFetchStatusRequest>): GetOpenBankingFetchStatusRequest {
        const message = { fetchId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOpenBankingFetchStatusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOpenBankingFetchStatusRequest): GetOpenBankingFetchStatusRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string fetch_id */ 1:
                    message.fetchId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOpenBankingFetchStatusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string fetch_id = 1; */
        if (message.fetchId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.fetchId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetOpenBankingFetchStatusRequest
 */
export const GetOpenBankingFetchStatusRequest = new GetOpenBankingFetchStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOpenBankingFetchStatusResponse$Type extends MessageType<GetOpenBankingFetchStatusResponse> {
    constructor() {
        super("one.plan3t.core.model.GetOpenBankingFetchStatusResponse", [
            { no: 1, name: "state", kind: "enum", T: () => ["one.plan3t.core.model.GetOpenBankingFetchStatusResponse.State", GetOpenBankingFetchStatusResponse_State] },
            { no: 2, name: "num_transactions_fetched", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "num_transactions_processed", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetOpenBankingFetchStatusResponse>): GetOpenBankingFetchStatusResponse {
        const message = { state: 0, numTransactionsFetched: 0, numTransactionsProcessed: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOpenBankingFetchStatusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOpenBankingFetchStatusResponse): GetOpenBankingFetchStatusResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.GetOpenBankingFetchStatusResponse.State state */ 1:
                    message.state = reader.int32();
                    break;
                case /* int32 num_transactions_fetched */ 2:
                    message.numTransactionsFetched = reader.int32();
                    break;
                case /* int32 num_transactions_processed */ 3:
                    message.numTransactionsProcessed = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOpenBankingFetchStatusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.GetOpenBankingFetchStatusResponse.State state = 1; */
        if (message.state !== 0)
            writer.tag(1, WireType.Varint).int32(message.state);
        /* int32 num_transactions_fetched = 2; */
        if (message.numTransactionsFetched !== 0)
            writer.tag(2, WireType.Varint).int32(message.numTransactionsFetched);
        /* int32 num_transactions_processed = 3; */
        if (message.numTransactionsProcessed !== 0)
            writer.tag(3, WireType.Varint).int32(message.numTransactionsProcessed);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetOpenBankingFetchStatusResponse
 */
export const GetOpenBankingFetchStatusResponse = new GetOpenBankingFetchStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CanRequestWelcomeBonusRequest$Type extends MessageType<CanRequestWelcomeBonusRequest> {
    constructor() {
        super("one.plan3t.core.model.CanRequestWelcomeBonusRequest", []);
    }
    create(value?: PartialMessage<CanRequestWelcomeBonusRequest>): CanRequestWelcomeBonusRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CanRequestWelcomeBonusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CanRequestWelcomeBonusRequest): CanRequestWelcomeBonusRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CanRequestWelcomeBonusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CanRequestWelcomeBonusRequest
 */
export const CanRequestWelcomeBonusRequest = new CanRequestWelcomeBonusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CanRequestWelcomeBonusResponse$Type extends MessageType<CanRequestWelcomeBonusResponse> {
    constructor() {
        super("one.plan3t.core.model.CanRequestWelcomeBonusResponse", [
            { no: 1, name: "verdict", kind: "enum", T: () => ["one.plan3t.core.model.CanRequestWelcomeBonusResponse.Verdict", CanRequestWelcomeBonusResponse_Verdict] }
        ]);
    }
    create(value?: PartialMessage<CanRequestWelcomeBonusResponse>): CanRequestWelcomeBonusResponse {
        const message = { verdict: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CanRequestWelcomeBonusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CanRequestWelcomeBonusResponse): CanRequestWelcomeBonusResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.CanRequestWelcomeBonusResponse.Verdict verdict */ 1:
                    message.verdict = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CanRequestWelcomeBonusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.CanRequestWelcomeBonusResponse.Verdict verdict = 1; */
        if (message.verdict !== 0)
            writer.tag(1, WireType.Varint).int32(message.verdict);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CanRequestWelcomeBonusResponse
 */
export const CanRequestWelcomeBonusResponse = new CanRequestWelcomeBonusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequestOpenBankingWelcomeBonusRequest$Type extends MessageType<RequestOpenBankingWelcomeBonusRequest> {
    constructor() {
        super("one.plan3t.core.model.RequestOpenBankingWelcomeBonusRequest", []);
    }
    create(value?: PartialMessage<RequestOpenBankingWelcomeBonusRequest>): RequestOpenBankingWelcomeBonusRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RequestOpenBankingWelcomeBonusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RequestOpenBankingWelcomeBonusRequest): RequestOpenBankingWelcomeBonusRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RequestOpenBankingWelcomeBonusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.RequestOpenBankingWelcomeBonusRequest
 */
export const RequestOpenBankingWelcomeBonusRequest = new RequestOpenBankingWelcomeBonusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequestOpenBankingWelcomeBonusResponse$Type extends MessageType<RequestOpenBankingWelcomeBonusResponse> {
    constructor() {
        super("one.plan3t.core.model.RequestOpenBankingWelcomeBonusResponse", [
            { no: 1, name: "amount", kind: "message", T: () => Plan3TPoint }
        ]);
    }
    create(value?: PartialMessage<RequestOpenBankingWelcomeBonusResponse>): RequestOpenBankingWelcomeBonusResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RequestOpenBankingWelcomeBonusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RequestOpenBankingWelcomeBonusResponse): RequestOpenBankingWelcomeBonusResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.Plan3TPoint amount */ 1:
                    message.amount = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RequestOpenBankingWelcomeBonusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.Plan3TPoint amount = 1; */
        if (message.amount)
            Plan3TPoint.internalBinaryWrite(message.amount, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.RequestOpenBankingWelcomeBonusResponse
 */
export const RequestOpenBankingWelcomeBonusResponse = new RequestOpenBankingWelcomeBonusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPartnerCashbackRateRequest$Type extends MessageType<GetPartnerCashbackRateRequest> {
    constructor() {
        super("one.plan3t.core.model.GetPartnerCashbackRateRequest", [
            { no: 1, name: "partner_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPartnerCashbackRateRequest>): GetPartnerCashbackRateRequest {
        const message = { partnerIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPartnerCashbackRateRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPartnerCashbackRateRequest): GetPartnerCashbackRateRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string partner_ids */ 1:
                    message.partnerIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPartnerCashbackRateRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string partner_ids = 1; */
        for (let i = 0; i < message.partnerIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.partnerIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetPartnerCashbackRateRequest
 */
export const GetPartnerCashbackRateRequest = new GetPartnerCashbackRateRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPartnerCashbackRateResponse$Type extends MessageType<GetPartnerCashbackRateResponse> {
    constructor() {
        super("one.plan3t.core.model.GetPartnerCashbackRateResponse", [
            { no: 1, name: "items", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => GetPartnerCashbackRateResponse_Rate } }
        ]);
    }
    create(value?: PartialMessage<GetPartnerCashbackRateResponse>): GetPartnerCashbackRateResponse {
        const message = { items: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPartnerCashbackRateResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPartnerCashbackRateResponse): GetPartnerCashbackRateResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, one.plan3t.core.model.GetPartnerCashbackRateResponse.Rate> items */ 1:
                    this.binaryReadMap1(message.items, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: GetPartnerCashbackRateResponse["items"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetPartnerCashbackRateResponse["items"] | undefined, val: GetPartnerCashbackRateResponse["items"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = GetPartnerCashbackRateResponse_Rate.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.model.GetPartnerCashbackRateResponse.items");
            }
        }
        map[key ?? ""] = val ?? GetPartnerCashbackRateResponse_Rate.create();
    }
    internalBinaryWrite(message: GetPartnerCashbackRateResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, one.plan3t.core.model.GetPartnerCashbackRateResponse.Rate> items = 1; */
        for (let k of Object.keys(message.items)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            GetPartnerCashbackRateResponse_Rate.internalBinaryWrite(message.items[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetPartnerCashbackRateResponse
 */
export const GetPartnerCashbackRateResponse = new GetPartnerCashbackRateResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPartnerCashbackRateResponse_Rate$Type extends MessageType<GetPartnerCashbackRateResponse_Rate> {
    constructor() {
        super("one.plan3t.core.model.GetPartnerCashbackRateResponse.Rate", [
            { no: 1, name: "rate_unscaled", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "rate_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetPartnerCashbackRateResponse_Rate>): GetPartnerCashbackRateResponse_Rate {
        const message = { rateUnscaled: 0, rateScale: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPartnerCashbackRateResponse_Rate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPartnerCashbackRateResponse_Rate): GetPartnerCashbackRateResponse_Rate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 rate_unscaled */ 1:
                    message.rateUnscaled = reader.int32();
                    break;
                case /* int32 rate_scale */ 2:
                    message.rateScale = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPartnerCashbackRateResponse_Rate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 rate_unscaled = 1; */
        if (message.rateUnscaled !== 0)
            writer.tag(1, WireType.Varint).int32(message.rateUnscaled);
        /* int32 rate_scale = 2; */
        if (message.rateScale !== 0)
            writer.tag(2, WireType.Varint).int32(message.rateScale);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetPartnerCashbackRateResponse.Rate
 */
export const GetPartnerCashbackRateResponse_Rate = new GetPartnerCashbackRateResponse_Rate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DonationTarget$Type extends MessageType<DonationTarget> {
    constructor() {
        super("one.plan3t.core.model.DonationTarget", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "starts_at", kind: "message", T: () => Timestamp },
            { no: 3, name: "ends_at", kind: "message", T: () => Timestamp },
            { no: 4, name: "max_claims", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "max_claims_cooldown", kind: "message", T: () => Duration },
            { no: 6, name: "title_short", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "extra_benefit_explanation", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 10, name: "unit", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 11, name: "unit_icon", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "unit_price", kind: "message", T: () => Plan3TPoint },
            { no: 13, name: "buy_slider_template_string", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 14, name: "image", kind: "message", T: () => ImageResource }
        ]);
    }
    create(value?: PartialMessage<DonationTarget>): DonationTarget {
        const message = { id: "", maxClaims: 0, titleShort: "", title: "", description: "", extraBenefitExplanation: "", unit: "", unitIcon: "", buySliderTemplateString: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DonationTarget>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DonationTarget): DonationTarget {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* google.protobuf.Timestamp starts_at */ 2:
                    message.startsAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.startsAt);
                    break;
                case /* google.protobuf.Timestamp ends_at */ 3:
                    message.endsAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.endsAt);
                    break;
                case /* int32 max_claims */ 4:
                    message.maxClaims = reader.int32();
                    break;
                case /* google.protobuf.Duration max_claims_cooldown */ 5:
                    message.maxClaimsCooldown = Duration.internalBinaryRead(reader, reader.uint32(), options, message.maxClaimsCooldown);
                    break;
                case /* string title_short */ 6:
                    message.titleShort = reader.string();
                    break;
                case /* string title */ 7:
                    message.title = reader.string();
                    break;
                case /* string description */ 8:
                    message.description = reader.string();
                    break;
                case /* string extra_benefit_explanation */ 9:
                    message.extraBenefitExplanation = reader.string();
                    break;
                case /* string unit */ 10:
                    message.unit = reader.string();
                    break;
                case /* string unit_icon */ 11:
                    message.unitIcon = reader.string();
                    break;
                case /* one.plan3t.core.model.Plan3TPoint unit_price */ 12:
                    message.unitPrice = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.unitPrice);
                    break;
                case /* string buy_slider_template_string */ 13:
                    message.buySliderTemplateString = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource image */ 14:
                    message.image = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.image);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DonationTarget, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* google.protobuf.Timestamp starts_at = 2; */
        if (message.startsAt)
            Timestamp.internalBinaryWrite(message.startsAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp ends_at = 3; */
        if (message.endsAt)
            Timestamp.internalBinaryWrite(message.endsAt, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int32 max_claims = 4; */
        if (message.maxClaims !== 0)
            writer.tag(4, WireType.Varint).int32(message.maxClaims);
        /* google.protobuf.Duration max_claims_cooldown = 5; */
        if (message.maxClaimsCooldown)
            Duration.internalBinaryWrite(message.maxClaimsCooldown, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string title_short = 6; */
        if (message.titleShort !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.titleShort);
        /* string title = 7; */
        if (message.title !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.title);
        /* string description = 8; */
        if (message.description !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.description);
        /* string extra_benefit_explanation = 9; */
        if (message.extraBenefitExplanation !== "")
            writer.tag(9, WireType.LengthDelimited).string(message.extraBenefitExplanation);
        /* string unit = 10; */
        if (message.unit !== "")
            writer.tag(10, WireType.LengthDelimited).string(message.unit);
        /* string unit_icon = 11; */
        if (message.unitIcon !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.unitIcon);
        /* one.plan3t.core.model.Plan3TPoint unit_price = 12; */
        if (message.unitPrice)
            Plan3TPoint.internalBinaryWrite(message.unitPrice, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* string buy_slider_template_string = 13; */
        if (message.buySliderTemplateString !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.buySliderTemplateString);
        /* one.plan3t.core.model.ImageResource image = 14; */
        if (message.image)
            ImageResource.internalBinaryWrite(message.image, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.DonationTarget
 */
export const DonationTarget = new DonationTarget$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DonationGoal$Type extends MessageType<DonationGoal> {
    constructor() {
        super("one.plan3t.core.model.DonationGoal", [
            { no: 1, name: "target_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "amount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "progress", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "until", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<DonationGoal>): DonationGoal {
        const message = { targetId: "", amount: 0, progress: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DonationGoal>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DonationGoal): DonationGoal {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string target_id */ 1:
                    message.targetId = reader.string();
                    break;
                case /* int32 amount */ 2:
                    message.amount = reader.int32();
                    break;
                case /* int32 progress */ 3:
                    message.progress = reader.int32();
                    break;
                case /* google.protobuf.Timestamp until */ 4:
                    message.until = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.until);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DonationGoal, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string target_id = 1; */
        if (message.targetId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.targetId);
        /* int32 amount = 2; */
        if (message.amount !== 0)
            writer.tag(2, WireType.Varint).int32(message.amount);
        /* int32 progress = 3; */
        if (message.progress !== 0)
            writer.tag(3, WireType.Varint).int32(message.progress);
        /* google.protobuf.Timestamp until = 4; */
        if (message.until)
            Timestamp.internalBinaryWrite(message.until, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.DonationGoal
 */
export const DonationGoal = new DonationGoal$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DonationReceipt$Type extends MessageType<DonationReceipt> {
    constructor() {
        super("one.plan3t.core.model.DonationReceipt", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "donation_target_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "points_value", kind: "message", T: () => Plan3TPoint },
            { no: 4, name: "amount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "created_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<DonationReceipt>): DonationReceipt {
        const message = { id: "", donationTargetId: "", amount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DonationReceipt>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DonationReceipt): DonationReceipt {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string donation_target_id */ 2:
                    message.donationTargetId = reader.string();
                    break;
                case /* one.plan3t.core.model.Plan3TPoint points_value */ 3:
                    message.pointsValue = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.pointsValue);
                    break;
                case /* int32 amount */ 4:
                    message.amount = reader.int32();
                    break;
                case /* google.protobuf.Timestamp created_at */ 5:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DonationReceipt, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string donation_target_id = 2; */
        if (message.donationTargetId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.donationTargetId);
        /* one.plan3t.core.model.Plan3TPoint points_value = 3; */
        if (message.pointsValue)
            Plan3TPoint.internalBinaryWrite(message.pointsValue, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int32 amount = 4; */
        if (message.amount !== 0)
            writer.tag(4, WireType.Varint).int32(message.amount);
        /* google.protobuf.Timestamp created_at = 5; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.DonationReceipt
 */
export const DonationReceipt = new DonationReceipt$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDonationTargetsRequest$Type extends MessageType<GetDonationTargetsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetDonationTargetsRequest", [
            { no: 1, name: "ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetDonationTargetsRequest>): GetDonationTargetsRequest {
        const message = { ids: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetDonationTargetsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDonationTargetsRequest): GetDonationTargetsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string ids */ 1:
                    message.ids.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDonationTargetsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string ids = 1; */
        for (let i = 0; i < message.ids.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.ids[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetDonationTargetsRequest
 */
export const GetDonationTargetsRequest = new GetDonationTargetsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDonationTargetsResponse$Type extends MessageType<GetDonationTargetsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetDonationTargetsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DonationTarget },
            { no: 2, name: "goals", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DonationGoal }
        ]);
    }
    create(value?: PartialMessage<GetDonationTargetsResponse>): GetDonationTargetsResponse {
        const message = { items: [], goals: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetDonationTargetsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDonationTargetsResponse): GetDonationTargetsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.DonationTarget items */ 1:
                    message.items.push(DonationTarget.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated one.plan3t.core.model.DonationGoal goals */ 2:
                    message.goals.push(DonationGoal.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDonationTargetsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.DonationTarget items = 1; */
        for (let i = 0; i < message.items.length; i++)
            DonationTarget.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated one.plan3t.core.model.DonationGoal goals = 2; */
        for (let i = 0; i < message.goals.length; i++)
            DonationGoal.internalBinaryWrite(message.goals[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetDonationTargetsResponse
 */
export const GetDonationTargetsResponse = new GetDonationTargetsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDonationGoalsRequest$Type extends MessageType<GetDonationGoalsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetDonationGoalsRequest", [
            { no: 1, name: "ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetDonationGoalsRequest>): GetDonationGoalsRequest {
        const message = { ids: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetDonationGoalsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDonationGoalsRequest): GetDonationGoalsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string ids */ 1:
                    message.ids.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDonationGoalsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string ids = 1; */
        for (let i = 0; i < message.ids.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.ids[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetDonationGoalsRequest
 */
export const GetDonationGoalsRequest = new GetDonationGoalsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDonationGoalsResponse$Type extends MessageType<GetDonationGoalsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetDonationGoalsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DonationGoal }
        ]);
    }
    create(value?: PartialMessage<GetDonationGoalsResponse>): GetDonationGoalsResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetDonationGoalsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDonationGoalsResponse): GetDonationGoalsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.DonationGoal items */ 1:
                    message.items.push(DonationGoal.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDonationGoalsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.DonationGoal items = 1; */
        for (let i = 0; i < message.items.length; i++)
            DonationGoal.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetDonationGoalsResponse
 */
export const GetDonationGoalsResponse = new GetDonationGoalsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DonateToTargetRequest$Type extends MessageType<DonateToTargetRequest> {
    constructor() {
        super("one.plan3t.core.model.DonateToTargetRequest", [
            { no: 1, name: "transaction_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "target_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "amount", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<DonateToTargetRequest>): DonateToTargetRequest {
        const message = { transactionId: "", targetId: "", amount: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DonateToTargetRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DonateToTargetRequest): DonateToTargetRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string transaction_id */ 1:
                    message.transactionId = reader.string();
                    break;
                case /* string target_id */ 2:
                    message.targetId = reader.string();
                    break;
                case /* int32 amount */ 3:
                    message.amount = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DonateToTargetRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string transaction_id = 1; */
        if (message.transactionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.transactionId);
        /* string target_id = 2; */
        if (message.targetId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.targetId);
        /* int32 amount = 3; */
        if (message.amount !== 0)
            writer.tag(3, WireType.Varint).int32(message.amount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.DonateToTargetRequest
 */
export const DonateToTargetRequest = new DonateToTargetRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DonateToTargetResponse$Type extends MessageType<DonateToTargetResponse> {
    constructor() {
        super("one.plan3t.core.model.DonateToTargetResponse", [
            { no: 1, name: "receipt", kind: "message", T: () => DonationReceipt }
        ]);
    }
    create(value?: PartialMessage<DonateToTargetResponse>): DonateToTargetResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DonateToTargetResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DonateToTargetResponse): DonateToTargetResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.DonationReceipt receipt */ 1:
                    message.receipt = DonationReceipt.internalBinaryRead(reader, reader.uint32(), options, message.receipt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DonateToTargetResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.DonationReceipt receipt = 1; */
        if (message.receipt)
            DonationReceipt.internalBinaryWrite(message.receipt, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.DonateToTargetResponse
 */
export const DonateToTargetResponse = new DonateToTargetResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDonationReceiptsRequest$Type extends MessageType<GetDonationReceiptsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetDonationReceiptsRequest", []);
    }
    create(value?: PartialMessage<GetDonationReceiptsRequest>): GetDonationReceiptsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetDonationReceiptsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDonationReceiptsRequest): GetDonationReceiptsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetDonationReceiptsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetDonationReceiptsRequest
 */
export const GetDonationReceiptsRequest = new GetDonationReceiptsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDonationReceiptsResponse$Type extends MessageType<GetDonationReceiptsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetDonationReceiptsResponse", [
            { no: 1, name: "receipts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DonationReceipt }
        ]);
    }
    create(value?: PartialMessage<GetDonationReceiptsResponse>): GetDonationReceiptsResponse {
        const message = { receipts: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetDonationReceiptsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDonationReceiptsResponse): GetDonationReceiptsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.DonationReceipt receipts */ 1:
                    message.receipts.push(DonationReceipt.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDonationReceiptsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.DonationReceipt receipts = 1; */
        for (let i = 0; i < message.receipts.length; i++)
            DonationReceipt.internalBinaryWrite(message.receipts[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetDonationReceiptsResponse
 */
export const GetDonationReceiptsResponse = new GetDonationReceiptsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AppDisplayKPI$Type extends MessageType<AppDisplayKPI> {
    constructor() {
        super("one.plan3t.core.model.AppDisplayKPI", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "icon", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "amount", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 4, name: "unit", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "title_short", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "deep_link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "image", kind: "message", T: () => ImageResource }
        ]);
    }
    create(value?: PartialMessage<AppDisplayKPI>): AppDisplayKPI {
        const message = { id: "", icon: "", amount: 0, unit: "", title: "", titleShort: "", deepLink: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AppDisplayKPI>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AppDisplayKPI): AppDisplayKPI {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string icon */ 2:
                    message.icon = reader.string();
                    break;
                case /* double amount */ 3:
                    message.amount = reader.double();
                    break;
                case /* string unit */ 4:
                    message.unit = reader.string();
                    break;
                case /* string title */ 5:
                    message.title = reader.string();
                    break;
                case /* string title_short */ 6:
                    message.titleShort = reader.string();
                    break;
                case /* string deep_link */ 7:
                    message.deepLink = reader.string();
                    break;
                case /* one.plan3t.core.model.ImageResource image */ 8:
                    message.image = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.image);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AppDisplayKPI, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string icon = 2; */
        if (message.icon !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.icon);
        /* double amount = 3; */
        if (message.amount !== 0)
            writer.tag(3, WireType.Bit64).double(message.amount);
        /* string unit = 4; */
        if (message.unit !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.unit);
        /* string title = 5; */
        if (message.title !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.title);
        /* string title_short = 6; */
        if (message.titleShort !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.titleShort);
        /* string deep_link = 7; */
        if (message.deepLink !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.deepLink);
        /* one.plan3t.core.model.ImageResource image = 8; */
        if (message.image)
            ImageResource.internalBinaryWrite(message.image, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.AppDisplayKPI
 */
export const AppDisplayKPI = new AppDisplayKPI$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAppDisplayKPIsRequest$Type extends MessageType<GetAppDisplayKPIsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetAppDisplayKPIsRequest", [
            { no: 1, name: "tag", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "reference", kind: "message", T: () => Date },
            { no: 3, name: "tags", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetAppDisplayKPIsRequest>): GetAppDisplayKPIsRequest {
        const message = { tag: "", tags: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAppDisplayKPIsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAppDisplayKPIsRequest): GetAppDisplayKPIsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string tag = 1 [deprecated = true];*/ 1:
                    message.tag = reader.string();
                    break;
                case /* one.plan3t.core.model.Date reference */ 2:
                    message.reference = Date.internalBinaryRead(reader, reader.uint32(), options, message.reference);
                    break;
                case /* repeated string tags */ 3:
                    message.tags.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAppDisplayKPIsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string tag = 1 [deprecated = true]; */
        if (message.tag !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.tag);
        /* one.plan3t.core.model.Date reference = 2; */
        if (message.reference)
            Date.internalBinaryWrite(message.reference, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated string tags = 3; */
        for (let i = 0; i < message.tags.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.tags[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetAppDisplayKPIsRequest
 */
export const GetAppDisplayKPIsRequest = new GetAppDisplayKPIsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAppDisplayKPIsResponse$Type extends MessageType<GetAppDisplayKPIsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetAppDisplayKPIsResponse", [
            { no: 1, name: "items", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AppDisplayKPI }
        ]);
    }
    create(value?: PartialMessage<GetAppDisplayKPIsResponse>): GetAppDisplayKPIsResponse {
        const message = { items: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAppDisplayKPIsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAppDisplayKPIsResponse): GetAppDisplayKPIsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.AppDisplayKPI items */ 1:
                    message.items.push(AppDisplayKPI.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAppDisplayKPIsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.AppDisplayKPI items = 1; */
        for (let i = 0; i < message.items.length; i++)
            AppDisplayKPI.internalBinaryWrite(message.items[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetAppDisplayKPIsResponse
 */
export const GetAppDisplayKPIsResponse = new GetAppDisplayKPIsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AssessmentScore$Type extends MessageType<AssessmentScore> {
    constructor() {
        super("one.plan3t.core.model.AssessmentScore", [
            { no: 1, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value_unscaled", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "value_scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "applicable", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<AssessmentScore>): AssessmentScore {
        const message = { label: "", valueUnscaled: 0, valueScale: 0, applicable: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AssessmentScore>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AssessmentScore): AssessmentScore {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string label */ 1:
                    message.label = reader.string();
                    break;
                case /* int32 value_unscaled */ 2:
                    message.valueUnscaled = reader.int32();
                    break;
                case /* int32 value_scale */ 3:
                    message.valueScale = reader.int32();
                    break;
                case /* bool applicable */ 4:
                    message.applicable = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AssessmentScore, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string label = 1; */
        if (message.label !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.label);
        /* int32 value_unscaled = 2; */
        if (message.valueUnscaled !== 0)
            writer.tag(2, WireType.Varint).int32(message.valueUnscaled);
        /* int32 value_scale = 3; */
        if (message.valueScale !== 0)
            writer.tag(3, WireType.Varint).int32(message.valueScale);
        /* bool applicable = 4; */
        if (message.applicable !== false)
            writer.tag(4, WireType.Varint).bool(message.applicable);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.AssessmentScore
 */
export const AssessmentScore = new AssessmentScore$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Assessment$Type extends MessageType<Assessment> {
    constructor() {
        super("one.plan3t.core.model.Assessment", [
            { no: 1, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "scoring_word", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "scores", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AssessmentScore },
            { no: 4, name: "scoring_word_label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "score", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "score_max", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 7, name: "more_info", kind: "message", T: () => Assessment_MoreInfo }
        ]);
    }
    create(value?: PartialMessage<Assessment>): Assessment {
        const message = { partnerId: "", scoringWord: "", scores: [], scoringWordLabel: "", score: 0, scoreMax: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Assessment>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Assessment): Assessment {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string partner_id */ 1:
                    message.partnerId = reader.string();
                    break;
                case /* string scoring_word */ 2:
                    message.scoringWord = reader.string();
                    break;
                case /* repeated one.plan3t.core.model.AssessmentScore scores */ 3:
                    message.scores.push(AssessmentScore.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string scoring_word_label */ 4:
                    message.scoringWordLabel = reader.string();
                    break;
                case /* int32 score */ 5:
                    message.score = reader.int32();
                    break;
                case /* int32 score_max */ 6:
                    message.scoreMax = reader.int32();
                    break;
                case /* one.plan3t.core.model.Assessment.MoreInfo more_info */ 7:
                    message.moreInfo = Assessment_MoreInfo.internalBinaryRead(reader, reader.uint32(), options, message.moreInfo);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Assessment, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string partner_id = 1; */
        if (message.partnerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.partnerId);
        /* string scoring_word = 2; */
        if (message.scoringWord !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.scoringWord);
        /* repeated one.plan3t.core.model.AssessmentScore scores = 3; */
        for (let i = 0; i < message.scores.length; i++)
            AssessmentScore.internalBinaryWrite(message.scores[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string scoring_word_label = 4; */
        if (message.scoringWordLabel !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.scoringWordLabel);
        /* int32 score = 5; */
        if (message.score !== 0)
            writer.tag(5, WireType.Varint).int32(message.score);
        /* int32 score_max = 6; */
        if (message.scoreMax !== 0)
            writer.tag(6, WireType.Varint).int32(message.scoreMax);
        /* one.plan3t.core.model.Assessment.MoreInfo more_info = 7; */
        if (message.moreInfo)
            Assessment_MoreInfo.internalBinaryWrite(message.moreInfo, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.Assessment
 */
export const Assessment = new Assessment$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Assessment_MoreInfo$Type extends MessageType<Assessment_MoreInfo> {
    constructor() {
        super("one.plan3t.core.model.Assessment.MoreInfo", [
            { no: 1, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "link", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "link_label", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Assessment_MoreInfo>): Assessment_MoreInfo {
        const message = { label: "", link: "", linkLabel: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Assessment_MoreInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Assessment_MoreInfo): Assessment_MoreInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string label */ 1:
                    message.label = reader.string();
                    break;
                case /* string link */ 2:
                    message.link = reader.string();
                    break;
                case /* string link_label */ 3:
                    message.linkLabel = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Assessment_MoreInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string label = 1; */
        if (message.label !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.label);
        /* string link = 2; */
        if (message.link !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.link);
        /* string link_label = 3; */
        if (message.linkLabel !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.linkLabel);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.Assessment.MoreInfo
 */
export const Assessment_MoreInfo = new Assessment_MoreInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentsRequest$Type extends MessageType<GetAssessmentsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetAssessmentsRequest", [
            { no: 1, name: "partner_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetAssessmentsRequest>): GetAssessmentsRequest {
        const message = { partnerIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAssessmentsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAssessmentsRequest): GetAssessmentsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string partner_ids */ 1:
                    message.partnerIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAssessmentsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string partner_ids = 1; */
        for (let i = 0; i < message.partnerIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.partnerIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetAssessmentsRequest
 */
export const GetAssessmentsRequest = new GetAssessmentsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAssessmentsResponse$Type extends MessageType<GetAssessmentsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetAssessmentsResponse", [
            { no: 1, name: "assessments", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Assessment }
        ]);
    }
    create(value?: PartialMessage<GetAssessmentsResponse>): GetAssessmentsResponse {
        const message = { assessments: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAssessmentsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAssessmentsResponse): GetAssessmentsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.Assessment assessments */ 1:
                    message.assessments.push(Assessment.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAssessmentsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.Assessment assessments = 1; */
        for (let i = 0; i < message.assessments.length; i++)
            Assessment.internalBinaryWrite(message.assessments[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetAssessmentsResponse
 */
export const GetAssessmentsResponse = new GetAssessmentsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RewardCode$Type extends MessageType<RewardCode> {
    constructor() {
        super("one.plan3t.core.model.RewardCode", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "code", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "reward_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "valid_until", kind: "message", T: () => Timestamp },
            { no: 9, name: "acquired_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<RewardCode>): RewardCode {
        const message = { id: "", code: "", partnerId: "", rewardId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RewardCode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RewardCode): RewardCode {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string code */ 2:
                    message.code = reader.string();
                    break;
                case /* string partner_id */ 4:
                    message.partnerId = reader.string();
                    break;
                case /* string reward_id */ 5:
                    message.rewardId = reader.string();
                    break;
                case /* google.protobuf.Timestamp valid_until */ 6:
                    message.validUntil = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.validUntil);
                    break;
                case /* google.protobuf.Timestamp acquired_at */ 9:
                    message.acquiredAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.acquiredAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RewardCode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string code = 2; */
        if (message.code !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.code);
        /* string partner_id = 4; */
        if (message.partnerId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.partnerId);
        /* string reward_id = 5; */
        if (message.rewardId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.rewardId);
        /* google.protobuf.Timestamp valid_until = 6; */
        if (message.validUntil)
            Timestamp.internalBinaryWrite(message.validUntil, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp acquired_at = 9; */
        if (message.acquiredAt)
            Timestamp.internalBinaryWrite(message.acquiredAt, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.RewardCode
 */
export const RewardCode = new RewardCode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRewardCodesRequest$Type extends MessageType<GetRewardCodesRequest> {
    constructor() {
        super("one.plan3t.core.model.GetRewardCodesRequest", []);
    }
    create(value?: PartialMessage<GetRewardCodesRequest>): GetRewardCodesRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRewardCodesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRewardCodesRequest): GetRewardCodesRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetRewardCodesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRewardCodesRequest
 */
export const GetRewardCodesRequest = new GetRewardCodesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRewardCodesResponse$Type extends MessageType<GetRewardCodesResponse> {
    constructor() {
        super("one.plan3t.core.model.GetRewardCodesResponse", [
            { no: 1, name: "codes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RewardCode }
        ]);
    }
    create(value?: PartialMessage<GetRewardCodesResponse>): GetRewardCodesResponse {
        const message = { codes: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRewardCodesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRewardCodesResponse): GetRewardCodesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.RewardCode codes */ 1:
                    message.codes.push(RewardCode.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRewardCodesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.RewardCode codes = 1; */
        for (let i = 0; i < message.codes.length; i++)
            RewardCode.internalBinaryWrite(message.codes[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRewardCodesResponse
 */
export const GetRewardCodesResponse = new GetRewardCodesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOpenBankingCapacityInformationRequest$Type extends MessageType<GetOpenBankingCapacityInformationRequest> {
    constructor() {
        super("one.plan3t.core.model.GetOpenBankingCapacityInformationRequest", []);
    }
    create(value?: PartialMessage<GetOpenBankingCapacityInformationRequest>): GetOpenBankingCapacityInformationRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOpenBankingCapacityInformationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOpenBankingCapacityInformationRequest): GetOpenBankingCapacityInformationRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetOpenBankingCapacityInformationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetOpenBankingCapacityInformationRequest
 */
export const GetOpenBankingCapacityInformationRequest = new GetOpenBankingCapacityInformationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetOpenBankingCapacityInformationResponse$Type extends MessageType<GetOpenBankingCapacityInformationResponse> {
    constructor() {
        super("one.plan3t.core.model.GetOpenBankingCapacityInformationResponse", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "can_connect", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "no_capacity_reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetOpenBankingCapacityInformationResponse>): GetOpenBankingCapacityInformationResponse {
        const message = { token: "", canConnect: false, noCapacityReason: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetOpenBankingCapacityInformationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetOpenBankingCapacityInformationResponse): GetOpenBankingCapacityInformationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                case /* bool can_connect */ 2:
                    message.canConnect = reader.bool();
                    break;
                case /* string no_capacity_reason */ 3:
                    message.noCapacityReason = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetOpenBankingCapacityInformationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        /* bool can_connect = 2; */
        if (message.canConnect !== false)
            writer.tag(2, WireType.Varint).bool(message.canConnect);
        /* string no_capacity_reason = 3; */
        if (message.noCapacityReason !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.noCapacityReason);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetOpenBankingCapacityInformationResponse
 */
export const GetOpenBankingCapacityInformationResponse = new GetOpenBankingCapacityInformationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StoreConsentGrantRequest$Type extends MessageType<StoreConsentGrantRequest> {
    constructor() {
        super("one.plan3t.core.model.StoreConsentGrantRequest", [
            { no: 1, name: "consent_legal_text_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "granted_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<StoreConsentGrantRequest>): StoreConsentGrantRequest {
        const message = { consentLegalTextId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StoreConsentGrantRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StoreConsentGrantRequest): StoreConsentGrantRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string consent_legal_text_id */ 1:
                    message.consentLegalTextId = reader.string();
                    break;
                case /* google.protobuf.Timestamp granted_at */ 2:
                    message.grantedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.grantedAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StoreConsentGrantRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string consent_legal_text_id = 1; */
        if (message.consentLegalTextId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.consentLegalTextId);
        /* google.protobuf.Timestamp granted_at = 2; */
        if (message.grantedAt)
            Timestamp.internalBinaryWrite(message.grantedAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.StoreConsentGrantRequest
 */
export const StoreConsentGrantRequest = new StoreConsentGrantRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StoreConsentGrantResponse$Type extends MessageType<StoreConsentGrantResponse> {
    constructor() {
        super("one.plan3t.core.model.StoreConsentGrantResponse", []);
    }
    create(value?: PartialMessage<StoreConsentGrantResponse>): StoreConsentGrantResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StoreConsentGrantResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StoreConsentGrantResponse): StoreConsentGrantResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: StoreConsentGrantResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.StoreConsentGrantResponse
 */
export const StoreConsentGrantResponse = new StoreConsentGrantResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RevokeConsentGrantRequest$Type extends MessageType<RevokeConsentGrantRequest> {
    constructor() {
        super("one.plan3t.core.model.RevokeConsentGrantRequest", [
            { no: 1, name: "consent_legal_text_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "revoked_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<RevokeConsentGrantRequest>): RevokeConsentGrantRequest {
        const message = { consentLegalTextId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RevokeConsentGrantRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RevokeConsentGrantRequest): RevokeConsentGrantRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string consent_legal_text_id */ 1:
                    message.consentLegalTextId = reader.string();
                    break;
                case /* google.protobuf.Timestamp revoked_at */ 2:
                    message.revokedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.revokedAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RevokeConsentGrantRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string consent_legal_text_id = 1; */
        if (message.consentLegalTextId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.consentLegalTextId);
        /* google.protobuf.Timestamp revoked_at = 2; */
        if (message.revokedAt)
            Timestamp.internalBinaryWrite(message.revokedAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.RevokeConsentGrantRequest
 */
export const RevokeConsentGrantRequest = new RevokeConsentGrantRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RevokeConsentGrantResponse$Type extends MessageType<RevokeConsentGrantResponse> {
    constructor() {
        super("one.plan3t.core.model.RevokeConsentGrantResponse", []);
    }
    create(value?: PartialMessage<RevokeConsentGrantResponse>): RevokeConsentGrantResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RevokeConsentGrantResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RevokeConsentGrantResponse): RevokeConsentGrantResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RevokeConsentGrantResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.RevokeConsentGrantResponse
 */
export const RevokeConsentGrantResponse = new RevokeConsentGrantResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAvailableImpactReportsRequest$Type extends MessageType<GetAvailableImpactReportsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetAvailableImpactReportsRequest", [
            { no: 1, name: "version", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetAvailableImpactReportsRequest>): GetAvailableImpactReportsRequest {
        const message = { version: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAvailableImpactReportsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAvailableImpactReportsRequest): GetAvailableImpactReportsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 version */ 1:
                    message.version = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAvailableImpactReportsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 version = 1; */
        if (message.version !== 0)
            writer.tag(1, WireType.Varint).int32(message.version);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetAvailableImpactReportsRequest
 */
export const GetAvailableImpactReportsRequest = new GetAvailableImpactReportsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAvailableImpactReportsResponse$Type extends MessageType<GetAvailableImpactReportsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetAvailableImpactReportsResponse", [
            { no: 1, name: "dates", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Date }
        ]);
    }
    create(value?: PartialMessage<GetAvailableImpactReportsResponse>): GetAvailableImpactReportsResponse {
        const message = { dates: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAvailableImpactReportsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAvailableImpactReportsResponse): GetAvailableImpactReportsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.Date dates */ 1:
                    message.dates.push(Date.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAvailableImpactReportsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.Date dates = 1; */
        for (let i = 0; i < message.dates.length; i++)
            Date.internalBinaryWrite(message.dates[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetAvailableImpactReportsResponse
 */
export const GetAvailableImpactReportsResponse = new GetAvailableImpactReportsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRelativityFactRequest$Type extends MessageType<GetRelativityFactRequest> {
    constructor() {
        super("one.plan3t.core.model.GetRelativityFactRequest", [
            { no: 1, name: "core_industry", kind: "enum", T: () => ["one.plan3t.core.model.CoreIndustry", CoreIndustry] },
            { no: 2, name: "variant", kind: "enum", T: () => ["one.plan3t.core.model.GetRelativityFactRequest.Variant", GetRelativityFactRequest_Variant, "VARIANT_"] },
            { no: 3, name: "amount_kg_co2", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetRelativityFactRequest>): GetRelativityFactRequest {
        const message = { coreIndustry: 0, variant: 0, amountKgCo2: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRelativityFactRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRelativityFactRequest): GetRelativityFactRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.CoreIndustry core_industry */ 1:
                    message.coreIndustry = reader.int32();
                    break;
                case /* one.plan3t.core.model.GetRelativityFactRequest.Variant variant */ 2:
                    message.variant = reader.int32();
                    break;
                case /* int32 amount_kg_co2 */ 3:
                    message.amountKgCo2 = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRelativityFactRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.CoreIndustry core_industry = 1; */
        if (message.coreIndustry !== 0)
            writer.tag(1, WireType.Varint).int32(message.coreIndustry);
        /* one.plan3t.core.model.GetRelativityFactRequest.Variant variant = 2; */
        if (message.variant !== 0)
            writer.tag(2, WireType.Varint).int32(message.variant);
        /* int32 amount_kg_co2 = 3; */
        if (message.amountKgCo2 !== 0)
            writer.tag(3, WireType.Varint).int32(message.amountKgCo2);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRelativityFactRequest
 */
export const GetRelativityFactRequest = new GetRelativityFactRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RelativityFact$Type extends MessageType<RelativityFact> {
    constructor() {
        super("one.plan3t.core.model.RelativityFact", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "subtitle", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "icon_name", kind: "scalar", oneof: "logo", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "emoji", kind: "scalar", oneof: "logo", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "sources", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RelativityFact>): RelativityFact {
        const message = { id: "", title: "", subtitle: "", logo: { oneofKind: undefined }, sources: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RelativityFact>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RelativityFact): RelativityFact {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string subtitle */ 3:
                    message.subtitle = reader.string();
                    break;
                case /* string icon_name */ 4:
                    message.logo = {
                        oneofKind: "iconName",
                        iconName: reader.string()
                    };
                    break;
                case /* string emoji */ 5:
                    message.logo = {
                        oneofKind: "emoji",
                        emoji: reader.string()
                    };
                    break;
                case /* repeated string sources */ 6:
                    message.sources.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RelativityFact, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string subtitle = 3; */
        if (message.subtitle !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.subtitle);
        /* string icon_name = 4; */
        if (message.logo.oneofKind === "iconName")
            writer.tag(4, WireType.LengthDelimited).string(message.logo.iconName);
        /* string emoji = 5; */
        if (message.logo.oneofKind === "emoji")
            writer.tag(5, WireType.LengthDelimited).string(message.logo.emoji);
        /* repeated string sources = 6; */
        for (let i = 0; i < message.sources.length; i++)
            writer.tag(6, WireType.LengthDelimited).string(message.sources[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.RelativityFact
 */
export const RelativityFact = new RelativityFact$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRelativityFactResponse$Type extends MessageType<GetRelativityFactResponse> {
    constructor() {
        super("one.plan3t.core.model.GetRelativityFactResponse", [
            { no: 1, name: "fact", kind: "message", T: () => RelativityFact }
        ]);
    }
    create(value?: PartialMessage<GetRelativityFactResponse>): GetRelativityFactResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRelativityFactResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRelativityFactResponse): GetRelativityFactResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.RelativityFact fact */ 1:
                    message.fact = RelativityFact.internalBinaryRead(reader, reader.uint32(), options, message.fact);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRelativityFactResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.RelativityFact fact = 1; */
        if (message.fact)
            RelativityFact.internalBinaryWrite(message.fact, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRelativityFactResponse
 */
export const GetRelativityFactResponse = new GetRelativityFactResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPrimaryClickoutRequest$Type extends MessageType<GetPrimaryClickoutRequest> {
    constructor() {
        super("one.plan3t.core.model.GetPrimaryClickoutRequest", [
            { no: 1, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPrimaryClickoutRequest>): GetPrimaryClickoutRequest {
        const message = { partnerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPrimaryClickoutRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPrimaryClickoutRequest): GetPrimaryClickoutRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string partner_id */ 1:
                    message.partnerId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPrimaryClickoutRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string partner_id = 1; */
        if (message.partnerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.partnerId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetPrimaryClickoutRequest
 */
export const GetPrimaryClickoutRequest = new GetPrimaryClickoutRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPrimaryClickoutResponse$Type extends MessageType<GetPrimaryClickoutResponse> {
    constructor() {
        super("one.plan3t.core.model.GetPrimaryClickoutResponse", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "icon_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetPrimaryClickoutResponse>): GetPrimaryClickoutResponse {
        const message = { url: "", label: "", iconName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPrimaryClickoutResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPrimaryClickoutResponse): GetPrimaryClickoutResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                case /* string label */ 2:
                    message.label = reader.string();
                    break;
                case /* string icon_name */ 3:
                    message.iconName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetPrimaryClickoutResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        /* string label = 2; */
        if (message.label !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.label);
        /* string icon_name = 3; */
        if (message.iconName !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.iconName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetPrimaryClickoutResponse
 */
export const GetPrimaryClickoutResponse = new GetPrimaryClickoutResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTransactionCo2EModifiersRequest$Type extends MessageType<GetTransactionCo2EModifiersRequest> {
    constructor() {
        super("one.plan3t.core.model.GetTransactionCo2EModifiersRequest", []);
    }
    create(value?: PartialMessage<GetTransactionCo2EModifiersRequest>): GetTransactionCo2EModifiersRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTransactionCo2EModifiersRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTransactionCo2EModifiersRequest): GetTransactionCo2EModifiersRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetTransactionCo2EModifiersRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTransactionCo2EModifiersRequest
 */
export const GetTransactionCo2EModifiersRequest = new GetTransactionCo2EModifiersRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTransactionCo2EModifiersResponse$Type extends MessageType<GetTransactionCo2EModifiersResponse> {
    constructor() {
        super("one.plan3t.core.model.GetTransactionCo2EModifiersResponse", [
            { no: 1, name: "co2e_modifiers", kind: "message", jsonName: "co2eModifiers", repeat: 1 /*RepeatType.PACKED*/, T: () => TransactionCo2EModifier }
        ]);
    }
    create(value?: PartialMessage<GetTransactionCo2EModifiersResponse>): GetTransactionCo2EModifiersResponse {
        const message = { co2EModifiers: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTransactionCo2EModifiersResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTransactionCo2EModifiersResponse): GetTransactionCo2EModifiersResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.TransactionCo2EModifier co2e_modifiers = 1 [json_name = "co2eModifiers"];*/ 1:
                    message.co2EModifiers.push(TransactionCo2EModifier.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTransactionCo2EModifiersResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.TransactionCo2EModifier co2e_modifiers = 1 [json_name = "co2eModifiers"]; */
        for (let i = 0; i < message.co2EModifiers.length; i++)
            TransactionCo2EModifier.internalBinaryWrite(message.co2EModifiers[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetTransactionCo2EModifiersResponse
 */
export const GetTransactionCo2EModifiersResponse = new GetTransactionCo2EModifiersResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddLikeRequest$Type extends MessageType<AddLikeRequest> {
    constructor() {
        super("one.plan3t.core.model.AddLikeRequest", [
            { no: 1, name: "likeable_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "likeable_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "rating", kind: "enum", T: () => ["one.plan3t.core.model.LikeRating", LikeRating, "LIKE_RATING_"] }
        ]);
    }
    create(value?: PartialMessage<AddLikeRequest>): AddLikeRequest {
        const message = { likeableType: "", likeableId: "", rating: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddLikeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddLikeRequest): AddLikeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string likeable_type */ 1:
                    message.likeableType = reader.string();
                    break;
                case /* string likeable_id */ 2:
                    message.likeableId = reader.string();
                    break;
                case /* one.plan3t.core.model.LikeRating rating */ 3:
                    message.rating = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddLikeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string likeable_type = 1; */
        if (message.likeableType !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.likeableType);
        /* string likeable_id = 2; */
        if (message.likeableId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.likeableId);
        /* one.plan3t.core.model.LikeRating rating = 3; */
        if (message.rating !== 0)
            writer.tag(3, WireType.Varint).int32(message.rating);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.AddLikeRequest
 */
export const AddLikeRequest = new AddLikeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddLikeResponse$Type extends MessageType<AddLikeResponse> {
    constructor() {
        super("one.plan3t.core.model.AddLikeResponse", []);
    }
    create(value?: PartialMessage<AddLikeResponse>): AddLikeResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddLikeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddLikeResponse): AddLikeResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AddLikeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.AddLikeResponse
 */
export const AddLikeResponse = new AddLikeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLikeRequest$Type extends MessageType<GetLikeRequest> {
    constructor() {
        super("one.plan3t.core.model.GetLikeRequest", [
            { no: 1, name: "likeable_type", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "likeable_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetLikeRequest>): GetLikeRequest {
        const message = { likeableType: "", likeableId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetLikeRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLikeRequest): GetLikeRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string likeable_type */ 1:
                    message.likeableType = reader.string();
                    break;
                case /* string likeable_id */ 2:
                    message.likeableId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLikeRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string likeable_type = 1; */
        if (message.likeableType !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.likeableType);
        /* string likeable_id = 2; */
        if (message.likeableId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.likeableId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetLikeRequest
 */
export const GetLikeRequest = new GetLikeRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetLikeResponse$Type extends MessageType<GetLikeResponse> {
    constructor() {
        super("one.plan3t.core.model.GetLikeResponse", [
            { no: 1, name: "rating", kind: "enum", T: () => ["one.plan3t.core.model.LikeRating", LikeRating, "LIKE_RATING_"] }
        ]);
    }
    create(value?: PartialMessage<GetLikeResponse>): GetLikeResponse {
        const message = { rating: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetLikeResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetLikeResponse): GetLikeResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.LikeRating rating */ 1:
                    message.rating = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetLikeResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.LikeRating rating = 1; */
        if (message.rating !== 0)
            writer.tag(1, WireType.Varint).int32(message.rating);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetLikeResponse
 */
export const GetLikeResponse = new GetLikeResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRecommendedTipsForTransactionsRequest$Type extends MessageType<GetRecommendedTipsForTransactionsRequest> {
    constructor() {
        super("one.plan3t.core.model.GetRecommendedTipsForTransactionsRequest", [
            { no: 1, name: "transaction_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetRecommendedTipsForTransactionsRequest>): GetRecommendedTipsForTransactionsRequest {
        const message = { transactionIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRecommendedTipsForTransactionsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRecommendedTipsForTransactionsRequest): GetRecommendedTipsForTransactionsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string transaction_ids */ 1:
                    message.transactionIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRecommendedTipsForTransactionsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string transaction_ids = 1; */
        for (let i = 0; i < message.transactionIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.transactionIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRecommendedTipsForTransactionsRequest
 */
export const GetRecommendedTipsForTransactionsRequest = new GetRecommendedTipsForTransactionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetRecommendedTipsForTransactionsResponse$Type extends MessageType<GetRecommendedTipsForTransactionsResponse> {
    constructor() {
        super("one.plan3t.core.model.GetRecommendedTipsForTransactionsResponse", [
            { no: 1, name: "tip_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetRecommendedTipsForTransactionsResponse>): GetRecommendedTipsForTransactionsResponse {
        const message = { tipIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetRecommendedTipsForTransactionsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetRecommendedTipsForTransactionsResponse): GetRecommendedTipsForTransactionsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string tip_ids */ 1:
                    message.tipIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetRecommendedTipsForTransactionsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string tip_ids = 1; */
        for (let i = 0; i < message.tipIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.tipIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.GetRecommendedTipsForTransactionsResponse
 */
export const GetRecommendedTipsForTransactionsResponse = new GetRecommendedTipsForTransactionsResponse$Type();
