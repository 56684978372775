import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import BillingPeriodsOverview from './BillingPeriodsOverview';
import LoadingSpinner from '../common/LoadingSpinner';
import BillingPeriodOverview from './BillingPeriodOverview';
import PartnerBillingSheet from './PartnerBillingSheet';

const BillingModule: React.FunctionComponent = () => {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <Switch>
                <Route path="/billing" exact>
                    <BillingPeriodsOverview />
                </Route>
                <Route
                    path="/billing/:periodCode"
                    exact
                    render={({ match }) => (
                        <BillingPeriodOverview
                            periodCode={match.params['periodCode']}
                        />
                    )}
                />
                <Route
                    path="/billing/:periodCode/:partnerId"
                    exact
                    render={({ match }) => (
                        <PartnerBillingSheet
                            periodCode={match.params['periodCode']}
                            partnerId={match.params['partnerId']}
                        />
                    )}
                />
            </Switch>
        </Suspense>
    );
};

export default BillingModule;
