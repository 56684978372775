import { CrudSlice } from './store';
import { useAppDispatch, useAppSelector } from '../redux/react';
import React, { ReactNode, useCallback } from 'react';
import Scrollable from '../common/Scrollable';
import { Table, TableFooter, TableHeader, TableRow } from '../common/Table';
import Pagination from '../common/Pagination';
import { Button } from '../common/Button';

export const ListPagination: React.FunctionComponent<{
    module: CrudSlice<any>;
}> = ({ module }) => {
    const getCurrentPageNumber = useAppSelector(
        module.selectors.getCurrentPageNumber,
    );
    const pageSize = useAppSelector(module.selectors.getPageSize);
    const hasMore = useAppSelector(module.selectors.hasMore);

    const dispatch = useAppDispatch();

    const navigateToPreviousPage = () =>
        dispatch(module.actions.navigateToPreviousPage());
    const navigateToNextPage = () =>
        dispatch(module.actions.navigateToNextPage());

    return (
        <Pagination
            currentPage={getCurrentPageNumber}
            perPage={pageSize}
            hasNext={hasMore}
            hasPrev={getCurrentPageNumber > 0}
            onPrev={navigateToPreviousPage}
            onNext={navigateToNextPage}
        />
    );
};

type Props<T> = {
    module: CrudSlice<T>;
    listActions?: { label: ReactNode; to: string }[];
    tableHeaders: React.ReactNode[];
    children: (item: T) => ReactNode[];
};

export default function ItemList<T>(props: Props<T>) {
    const dispatch = useAppDispatch();

    const hasMore = useAppSelector(props.module.selectors.hasMore);
    const items = useAppSelector(props.module.selectors.getCurrentPage);
    const error = useAppSelector(props.module.selectors.getError);

    const { fetchList } = props.module.actions;
    const fetchPage = useCallback(() => {
        dispatch(fetchList());
    }, [dispatch, fetchList]);

    const isEmpty = items.length === 0;
    React.useEffect(() => {
        if (hasMore && isEmpty) {
            fetchPage();
        }
    }, [hasMore, fetchPage, isEmpty]);

    return (
        <Scrollable>
            <div className="flex mb-4">
                <h1 className="font-bold text-3xl capitalize">
                    {props.module._args.name}
                </h1>
                <div className="flex-grow flex flex-row items-center justify-end space-x-2">
                    {(props.listActions || []).map((a, i) => (
                        <div key={i}>
                            <Button secondary to={a.to} label={a.label} />
                        </div>
                    ))}
                    <div>
                        <Button
                            to={`${props.module._args.localClientBasePath}/create`}
                            primary
                            label="Create"
                        />
                    </div>
                </div>
            </div>
            <Table
                header={<TableHeader labels={props.tableHeaders} />}
                footer={
                    <TableFooter>
                        <ListPagination module={props.module} />
                    </TableFooter>
                }
            >
                {items.map((item) => (
                    <TableRow
                        key={props.module._args.idProvider(item)}
                        contents={props.children(item)}
                    />
                ))}
            </Table>
            {error && (
                <div className="bg-red-100 rounded-md border border-red-300 text-red-900 px-4 py-2 mt-8">
                    Error loading: {error.message}
                </div>
            )}
        </Scrollable>
    );
}
