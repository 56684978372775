import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import LoadingSpinner from '../common/LoadingSpinner';
import { Form, Formik } from 'formik';
import { ManuallyApplyCashbackRequest } from '../api/model/backoffice';
import { Button } from '../common/Button';
import FormPartnerAssociationField from '../common/FormPartnerAssociationField';
import { FormInlineDocumentField, FormTextField } from '../common/FormFields';
import axios from 'axios';
import { useAppDispatch } from '../redux/react';
import { snackbarActions } from '../snackbar/store';

export default function CashbackApplier() {
    const history = useHistory();

    const [working, setWorking] = useState(false);
    const dispatch = useAppDispatch();

    const [defaultEmpty] = useState(ManuallyApplyCashbackRequest.create());

    const onSubmit = async (values: typeof defaultEmpty) => {
        setWorking(true);

        try {
            await axios.post(
                '/Cashback.ManuallyApplyCashback',
                ManuallyApplyCashbackRequest.toJson(values),
            );
        } catch (e) {
            dispatch(
                snackbarActions.sendMessage({
                    type: 'warning',
                    title: 'Error',
                    subtitle: `${e}`,
                }),
            );
        } finally {
            setWorking(false);
        }
    };

    return (
        <div className="relative">
            {working && (
                <div className="absolute inset-0 bg-black opacity-25 pt-4 flex justify-center">
                    <LoadingSpinner className="h-12 w-12 text-white" />
                </div>
            )}
            <Formik
                onSubmit={onSubmit}
                initialValues={defaultEmpty}
                validate={(v) => {
                    if (!v.partnerId) {
                        return { partnerId: 'required' };
                    }
                    if (!v.transactionId) {
                        return { transactionId: 'required' };
                    }
                    if (v.document && v.document.length > 0) {
                        if (!v.documentIdentifier) {
                            return {
                                documentIdentifier:
                                    'required if a document is provided ',
                            };
                        }
                    }
                }}
            >
                <Form className="container m-0 px-4 pt-8 space-y-2 max-w-lg">
                    <div className="flex items-center space-x-2">
                        <div>
                            <Button
                                secondary
                                label={
                                    <svg
                                        className="w-4 h-4"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                    </svg>
                                }
                                onClick={(e) => {
                                    e.preventDefault();
                                    history.goBack();
                                }}
                            />
                        </div>
                        <h1 className="font-bold text-3xl">Apply Cashback</h1>
                        <div className="flex-shrink">
                            <Button type="submit" label="DO IT" primary />
                        </div>
                    </div>
                    <div className="flex flex-col space-y-4 px-4 pt-8">
                        <FormPartnerAssociationField
                            single
                            name="partnerId"
                            label="Partner"
                        />
                        <FormTextField
                            name="transactionId"
                            label="Transaction ID"
                        />
                        <FormInlineDocumentField
                            name="document"
                            label="Document"
                            mimeTypeField="mimeType"
                        />
                        <FormTextField
                            name="documentIdentifier"
                            label="Document Identifier"
                        />
                    </div>
                </Form>
            </Formik>
        </div>
    );
}
