import { FunctionComponent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { notificationActions, notificationSelectors } from './store';
import { Transition } from '@headlessui/react';
import { Button } from '../common/Button';
import LoadingSpinner from '../common/LoadingSpinner';

const NotificationBroadcastMessageStatsDialog: FunctionComponent = () => {
    const id = useAppSelector(notificationSelectors.getViewStatsID);
    const stats = useAppSelector((s) =>
        notificationSelectors.getStatsFor(s, { id: id ?? '' }),
    );
    const visible = id !== undefined;

    const dispatch = useAppDispatch();

    const [hasFetched, setHasFetched] = useState(false);

    useEffect(() => {
        setHasFetched(false);
    }, [id]);

    useEffect(() => {
        if (!hasFetched && visible) {
            setHasFetched(true);
            dispatch(notificationActions.fetchStats());
        }
    }, [dispatch, visible, hasFetched, setHasFetched]);

    const onClose = () => {
        dispatch(notificationActions.exitViewStatsMode());
    };

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto pointer-events-none">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition
                    show={visible}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-gray-500 opacity-75" />
                </Transition>
                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <Transition
                    show={visible}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full pointer-events-auto"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6">
                        <div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left space-y-8">
                            <h3
                                className="text-lg leading-6 font-medium text-gray-900 flex items-center space-x-2"
                                id="modal-headline"
                            >
                                <span className="text-gray-700">Stats</span>
                                {stats === undefined && <LoadingSpinner />}
                            </h3>

                            <ul className="flex flex-col space-y-2">
                                <li>Sent to: {stats?.numSent ?? 0}</li>
                                <li>Opened by: {stats?.numOpened ?? 0}</li>
                            </ul>

                            <Button secondary label="Close" onClick={onClose} />
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
};

export default NotificationBroadcastMessageStatsDialog;
