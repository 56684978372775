import { TipPreviewDraft } from '../api/model/tip';
import { createCrudSlice, CrudSlice } from '../crud-module/store';
import { AppState } from '../redux/AppStore';
import {
    GetTipDraftResponse,
    GetTipDraftsResponse,
    SaveTipDraftRequest,
} from '../api/model/backoffice';
import { ulid } from 'ulid';
import { CoreIndustry } from '../api/model/core';
import { Timestamp } from '../api/google/protobuf/timestamp';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { snackbarActions } from '../snackbar/store';

export const tipModule: CrudSlice<TipPreviewDraft> =
    createCrudSlice<TipPreviewDraft>({
        name: 'tip',
        baseUrl: '/tip',
        localClientBasePath: '/tip',
        stateProvider: (s) => (s as AppState).tip,
        listResponseParser: (data) => {
            const res = GetTipDraftsResponse.fromJson(data);
            return {
                items: res.tips,
                nextPageToken: res.nextPageToken,
            };
        },
        singleResponseParser: (data) => ({
            item: GetTipDraftResponse.fromJson(data).draft,
        }),
        writeArgumentTransformer: (draft) =>
            SaveTipDraftRequest.toJson({ draft }),
        idProvider: ({ id }) => id,
        prepareEmpty: () => ({
            id: ulid(),
            title: '',
            tagline: '',
            image: undefined,
            coreIndustry: CoreIndustry.UnknownIndustry,
            category: '',
            award: { amount: 0 },
            partnerId: '',
            associatedPartnerIds: [],
            recommendedPotentials: [],
            authorId: '',
            createdAt: Timestamp.now(),
            createdBy: '',
            modifiedAt: Timestamp.now(),
            modifiedBy: '',
            publishedAt: undefined,
            publishedBy: '',
        }),
    });

export const tipReducer = tipModule.reducer;
export const tipActions = tipModule.actions;
export const tipSelectors = tipModule.selectors;

export const copyTipDeepLink = createAsyncThunk(
    'tip/copyDeepLink',
    async (args: { tipId: string }, { dispatch }) => {
        const deepLink = `/tipViewer?tip=${args.tipId}`;
        await navigator.clipboard.writeText(deepLink);

        dispatch(
            snackbarActions.sendMessage({
                title: 'DeepLink copied to clipboard',
                subtitle: deepLink,
            }),
        );
    },
);
