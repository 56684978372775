import { Button, Label, MessageBox, Page } from '@ui5/webcomponents-react';
import { TextField } from '../common/TextField';
import React, { useState } from 'react';
import axios from 'axios';
import {
    GeneratePasswordResetLinkRequest,
    GeneratePasswordResetLinkRequest_Scope,
    GeneratePasswordResetLinkResponse,
} from '../api/model/backoffice';

type RequestState =
    | { tag: 'pending' }
    | { tag: 'inflight' }
    | { tag: 'error'; error: any }
    | { tag: 'success'; url: string };

export default function GeneratePasswordResetLink() {
    const [email, setEmail] = useState('');

    const [requestState, setRequestState] = useState<RequestState>({
        tag: 'pending',
    });

    const onGenerate = async () => {
        setRequestState({ tag: 'inflight' });
        try {
            const res = await axios.post(
                '/fuser/resetPassword',
                GeneratePasswordResetLinkRequest.toJson({
                    email,
                    scope: GeneratePasswordResetLinkRequest_Scope.B2C,
                }),
            );
            const { url } = GeneratePasswordResetLinkResponse.fromJson(
                res.data,
            );
            setRequestState({ tag: 'success', url });
        } catch (e) {
            setRequestState({ tag: 'error', error: e });
        } finally {
            setRequestState((old) =>
                old.tag === 'inflight'
                    ? {
                          tag: 'error',
                          error: new Error('Unknown error'),
                      }
                    : old,
            );
        }
    };

    return (
        <>
            <Page title="Generate Password Reset Link">
                <div className="container px-4 pt-8 flex flex-col space-y-2">
                    <TextField
                        label="E-Mail"
                        value={email}
                        onChange={setEmail}
                        disabled={requestState.tag !== 'pending'}
                    />
                    <Button
                        disabled={requestState.tag !== 'pending'}
                        onClick={onGenerate}
                    >
                        Reset
                    </Button>
                </div>
            </Page>
            <MessageBox
                open={
                    requestState.tag === 'success' ||
                    requestState.tag === 'error'
                }
                titleText={requestState.tag}
                onClose={() => setRequestState({ tag: 'pending' })}
            >
                {requestState.tag === 'success' && (
                    <Label>{requestState.url}</Label>
                )}
                {requestState.tag === 'error' && (
                    <Label>{requestState.error.toString()}</Label>
                )}
            </MessageBox>
        </>
    );
}
