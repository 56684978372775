import Scrollable from '../common/Scrollable';
import { usePartnerProfile } from './store';
import { Link } from 'react-router-dom';
import { IconBack } from '../icons';
import { useRecoilValue } from 'recoil';
import { currentRateQuery } from '../cashback/store';
import { addValues, formatPercentage } from '../cashback/CashbackRateModule';

export default function PartnerProfileViewer({ id }: { id: string }) {
    const profile = usePartnerProfile(id);

    if (!profile) {
        return <span>Not Found</span>;
    }

    return (
        <Scrollable>
            <div className="pb-4">
                <Link
                    to={`/partner`}
                    className="inline-flex items-center space-x-2 px-4 py-2 -mx-4 hover:bg-gray-200 rounded"
                >
                    <IconBack className="h-4 w-4" />
                </Link>
            </div>
            <div className="flex flex-col space-y-2 max-w-2xl">
                <img
                    src={profile.coverImage?.url}
                    alt="cover"
                    className="h-32 object-cover"
                />
                <div className="flex flex-row space-x-2 items-center">
                    <img
                        src={profile.logo?.url}
                        alt="logo"
                        className="w-8 h-8 rounded-full overflow-hidden object-contain border border-gray-100"
                    />
                    <div className="flex flex-col">
                        <h1 className="font-bold">{profile.name}</h1>
                        <span className="text-gray-500 text-xs">
                            {profile.tagLine}
                        </span>
                    </div>
                </div>
                <p className="text-sm text-gray-800">{profile.description}</p>
                <div className="flex flex-row space-x-4">
                    {profile.moodImages.map((i, idx) => (
                        <img
                            key={i.id}
                            src={i.url}
                            alt="shared content"
                            className="w-20 h-32 object-cover rounded overflow-hidden"
                        />
                    ))}
                </div>
                <div>
                    <ul>
                        {profile.socialLinksRich.map((l) => (
                            <li key={l.url}>
                                <a
                                    href={l.url}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    {l.label || l.url} ({l.kind})
                                </a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <div className="flex py-4">
                <CurrentCashbackRateLabel partnerID={profile.id} />
            </div>
        </Scrollable>
    );
}

function CurrentCashbackRateLabel(props: { partnerID: string }) {
    const rate = useRecoilValue(currentRateQuery(props.partnerID));
    if (!rate) {
        return (
            <div className="flex items-center text-red-500">NO CASHBACK</div>
        );
    }
    return (
        <div className="flex items-center justify-center py-2 px-4 text-green-500 bg-green-100 rounded md:py-3">
            <span className="font-bold">
                {formatPercentage(
                    addValues(
                        rate.rate,
                        rate.rateScale,
                        rate.sponsoredRate,
                        rate.sponsoredRateScale,
                    ),
                )}{' '}
                Cashback
            </span>
        </div>
    );
}
