// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "model/transaction_co2e_modifier.proto" (package "one.plan3t.core.model", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message one.plan3t.core.model.TransactionCo2EModifierSelectData
 */
export interface TransactionCo2EModifierSelectData {
    /**
     * @generated from protobuf field: string value = 1;
     */
    value: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.TransactionCo2EModifierSelectConfig
 */
export interface TransactionCo2EModifierSelectConfig {
    /**
     * @generated from protobuf field: string label = 1;
     */
    label: string;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.TransactionCo2EModifierSelectConfig.Option options = 2;
     */
    options: TransactionCo2EModifierSelectConfig_Option[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.TransactionCo2EModifierSelectConfig.Option
 */
export interface TransactionCo2EModifierSelectConfig_Option {
    /**
     * @generated from protobuf field: string value = 1;
     */
    value: string;
    /**
     * @generated from protobuf field: string label = 2;
     */
    label: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.TransactionCo2EModifier
 */
export interface TransactionCo2EModifier {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string category_id = 2;
     */
    categoryId: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.TransactionCo2EModifierType type = 3;
     */
    type: TransactionCo2EModifierType;
    /**
     * @generated from protobuf oneof: config
     */
    config: {
        oneofKind: "selectConfig";
        /**
         * @generated from protobuf field: one.plan3t.core.model.TransactionCo2EModifierSelectConfig select_config = 4;
         */
        selectConfig: TransactionCo2EModifierSelectConfig;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf enum one.plan3t.core.model.TransactionCo2EModifierType
 */
export enum TransactionCo2EModifierType {
    /**
     * @generated from protobuf enum value: TRANSACTION_CO2_MODIFIER_TYPE_UNKNOWN = 0;
     */
    TRANSACTION_CO2_MODIFIER_TYPE_UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: TRANSACTION_CO2_MODIFIER_TYPE_SELECT = 1;
     */
    TRANSACTION_CO2_MODIFIER_TYPE_SELECT = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class TransactionCo2EModifierSelectData$Type extends MessageType<TransactionCo2EModifierSelectData> {
    constructor() {
        super("one.plan3t.core.model.TransactionCo2EModifierSelectData", [
            { no: 1, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TransactionCo2EModifierSelectData>): TransactionCo2EModifierSelectData {
        const message = { value: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TransactionCo2EModifierSelectData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TransactionCo2EModifierSelectData): TransactionCo2EModifierSelectData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string value */ 1:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TransactionCo2EModifierSelectData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string value = 1; */
        if (message.value !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.TransactionCo2EModifierSelectData
 */
export const TransactionCo2EModifierSelectData = new TransactionCo2EModifierSelectData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionCo2EModifierSelectConfig$Type extends MessageType<TransactionCo2EModifierSelectConfig> {
    constructor() {
        super("one.plan3t.core.model.TransactionCo2EModifierSelectConfig", [
            { no: 1, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "options", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => TransactionCo2EModifierSelectConfig_Option }
        ]);
    }
    create(value?: PartialMessage<TransactionCo2EModifierSelectConfig>): TransactionCo2EModifierSelectConfig {
        const message = { label: "", options: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TransactionCo2EModifierSelectConfig>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TransactionCo2EModifierSelectConfig): TransactionCo2EModifierSelectConfig {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string label */ 1:
                    message.label = reader.string();
                    break;
                case /* repeated one.plan3t.core.model.TransactionCo2EModifierSelectConfig.Option options */ 2:
                    message.options.push(TransactionCo2EModifierSelectConfig_Option.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TransactionCo2EModifierSelectConfig, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string label = 1; */
        if (message.label !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.label);
        /* repeated one.plan3t.core.model.TransactionCo2EModifierSelectConfig.Option options = 2; */
        for (let i = 0; i < message.options.length; i++)
            TransactionCo2EModifierSelectConfig_Option.internalBinaryWrite(message.options[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.TransactionCo2EModifierSelectConfig
 */
export const TransactionCo2EModifierSelectConfig = new TransactionCo2EModifierSelectConfig$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionCo2EModifierSelectConfig_Option$Type extends MessageType<TransactionCo2EModifierSelectConfig_Option> {
    constructor() {
        super("one.plan3t.core.model.TransactionCo2EModifierSelectConfig.Option", [
            { no: 1, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TransactionCo2EModifierSelectConfig_Option>): TransactionCo2EModifierSelectConfig_Option {
        const message = { value: "", label: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TransactionCo2EModifierSelectConfig_Option>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TransactionCo2EModifierSelectConfig_Option): TransactionCo2EModifierSelectConfig_Option {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string value */ 1:
                    message.value = reader.string();
                    break;
                case /* string label */ 2:
                    message.label = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TransactionCo2EModifierSelectConfig_Option, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string value = 1; */
        if (message.value !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.value);
        /* string label = 2; */
        if (message.label !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.label);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.TransactionCo2EModifierSelectConfig.Option
 */
export const TransactionCo2EModifierSelectConfig_Option = new TransactionCo2EModifierSelectConfig_Option$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionCo2EModifier$Type extends MessageType<TransactionCo2EModifier> {
    constructor() {
        super("one.plan3t.core.model.TransactionCo2EModifier", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "category_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "enum", T: () => ["one.plan3t.core.model.TransactionCo2EModifierType", TransactionCo2EModifierType] },
            { no: 4, name: "select_config", kind: "message", oneof: "config", T: () => TransactionCo2EModifierSelectConfig }
        ]);
    }
    create(value?: PartialMessage<TransactionCo2EModifier>): TransactionCo2EModifier {
        const message = { id: "", categoryId: "", type: 0, config: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TransactionCo2EModifier>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TransactionCo2EModifier): TransactionCo2EModifier {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string category_id */ 2:
                    message.categoryId = reader.string();
                    break;
                case /* one.plan3t.core.model.TransactionCo2EModifierType type */ 3:
                    message.type = reader.int32();
                    break;
                case /* one.plan3t.core.model.TransactionCo2EModifierSelectConfig select_config */ 4:
                    message.config = {
                        oneofKind: "selectConfig",
                        selectConfig: TransactionCo2EModifierSelectConfig.internalBinaryRead(reader, reader.uint32(), options, (message.config as any).selectConfig)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TransactionCo2EModifier, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string category_id = 2; */
        if (message.categoryId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.categoryId);
        /* one.plan3t.core.model.TransactionCo2EModifierType type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        /* one.plan3t.core.model.TransactionCo2EModifierSelectConfig select_config = 4; */
        if (message.config.oneofKind === "selectConfig")
            TransactionCo2EModifierSelectConfig.internalBinaryWrite(message.config.selectConfig, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.TransactionCo2EModifier
 */
export const TransactionCo2EModifier = new TransactionCo2EModifier$Type();
