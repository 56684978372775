import {
    Bar,
    Button,
    ButtonDesign,
    Dialog,
    Loader,
} from '@ui5/webcomponents-react';
import { useState } from 'react';
import * as Billing from '../api/billing/rpc/billing';
import { PostingType, useAddPostings } from './store';
import { ulid } from 'ulid';
import { Form, Formik, useFormikContext } from 'formik';
import {
    FormSelectField,
    FormTextField,
    FormUnscaledValueField,
} from '../common/FormFields';

type Props = {
    open: boolean;
    onClose: () => void;

    period: number;
    partnerId: string;
    documentId: string;

    baseMetadata: Record<string, string>;
};

export default function CreatePostingDialog(props: Props) {
    const [initialValues] =
        useState<Billing.AddPostingsRequest_PostingCreationData>({
            type: '',
            amountScale: 0,
            amountUnscaled: 0,
            currency: 'EUR',
            documentId: props.documentId,
            idempotencyKey: ulid(),
            metadata: {
                ...props.baseMetadata,
            },
        });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const { addPostings } = useAddPostings(props.period, props.partnerId);

    const onSubmit = async (values: typeof initialValues) => {
        setIsSubmitting(true);
        try {
            await addPostings([values]);
            props.onClose();
        } finally {
            setIsSubmitting(false);
        }
    };

    const validate = (values: typeof initialValues) => {
        const errors: Record<string, string> = {};

        if (!values.type) {
            errors.type = 'Type is required';
        }
        if (!values.amountUnscaled) {
            errors.amountUnscaled = 'Amount cannot be zero';
        } else if (values.amountUnscaled > 0) {
            errors.amountUnscaled = 'Should be negative';
        }

        return errors;
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
        >
            <Dialog
                open={props.open}
                onAfterClose={props.onClose}
                headerText="Buchen"
                footer={<ActionBar onClose={props.onClose} />}
            >
                <Form className="px-4 pt-8 space-y-2">
                    <FormSelectField
                        label="Type"
                        name="type"
                        options={[
                            { label: 'Auswählen', value: '' },
                            {
                                label: 'Affiliate',
                                value: PostingType.RewardAffiliate,
                            },
                        ]}
                    />
                    <FormUnscaledValueField name="amount" label="Betrag" />
                    <FormTextField label="Währung" name="currency" disabled />
                </Form>
                {isSubmitting && <Loader />}
            </Dialog>
        </Formik>
    );
}

function ActionBar(props: { slot?: string; onClose: () => void }) {
    const { submitForm } = useFormikContext();
    return (
        <Bar
            slot={props.slot}
            endContent={
                <>
                    <Button design={ButtonDesign.Positive} onClick={submitForm}>
                        Verbuchen
                    </Button>
                    <Button
                        design={ButtonDesign.Negative}
                        onClick={props.onClose}
                    >
                        Abbrechen
                    </Button>
                </>
            }
        />
    );
}
