import {
    Button,
    ButtonDesign,
    DynamicPage,
    DynamicPageHeader,
    DynamicPageTitle,
    FlexBox,
    FlexBoxDirection,
    Label,
    ObjectStatus,
    Table,
    TableCell,
    TableColumn,
    TableRow,
    Title,
} from '@ui5/webcomponents-react';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { auditorLastRunQuery, auditorRecentRunsQuery } from './store';
import { auditStatusToValueState } from './AuditorModule';
import { Timestamp } from '../api/google/protobuf/timestamp';

export default function AuditRunResultListPage() {
    const { name } = useParams<{ name: string }>();

    const history = useHistory();

    const lastRun = useRecoilValue(auditorLastRunQuery(name));
    const rrs = useRecoilValue(auditorRecentRunsQuery(name));
    return (
        <DynamicPage
            headerTitle={
                <DynamicPageTitle
                    header={<Title>Audit</Title>}
                    navigationActions={
                        <>
                            <Button
                                icon="decline"
                                design={ButtonDesign.Transparent}
                                onClick={() => {
                                    history.push('/audit');
                                }}
                            />
                        </>
                    }
                />
            }
            headerContent={
                <DynamicPageHeader>
                    <FlexBox direction={FlexBoxDirection.Column}>
                        <Label>{lastRun.auditName}</Label>
                        <ObjectStatus
                            state={auditStatusToValueState(lastRun.status)}
                            showDefaultIcon
                        />
                    </FlexBox>
                </DynamicPageHeader>
            }
        >
            <Table
                columns={
                    <>
                        <TableColumn>
                            <Label>ID</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Status</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Remarks</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Started At</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Finished At</Label>
                        </TableColumn>
                    </>
                }
            >
                {rrs.map((rr) => (
                    <TableRow key={rr.id}>
                        <TableCell>
                            <Label>{rr.id}</Label>
                        </TableCell>
                        <TableCell>
                            <Label>
                                <ObjectStatus
                                    state={auditStatusToValueState(rr.status)}
                                    showDefaultIcon
                                />
                            </Label>
                        </TableCell>
                        <TableCell>
                            <Label>{rr.remarks}</Label>
                        </TableCell>
                        <TableCell>
                            <Label>
                                {Timestamp.toDate(rr.startedAt!).toISOString()}
                            </Label>
                        </TableCell>
                        <TableCell>
                            <Label>
                                {Timestamp.toDate(rr.finishedAt!).toISOString()}
                            </Label>
                        </TableCell>
                    </TableRow>
                ))}
            </Table>
        </DynamicPage>
    );
}
