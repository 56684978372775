import { useHistory, useParams } from 'react-router-dom';
import {
    Bar,
    Label,
    Page,
    Button,
    Table,
    TableColumn,
    TableRow,
    TableCell,
    Icon,
} from '@ui5/webcomponents-react';
import { useRecoilValue } from 'recoil';
import { currentRateIDQuery, rateScheduleQuery } from './store';
import React from 'react';
import { addValues, formatPercentage } from './CashbackRateModule';
import { Timestamp } from '../api/google/protobuf/timestamp';
import PartnerName from '../partner/PartnerName';

export default function CashbackRateSchedule() {
    const { partnerId } = useParams<{ partnerId: string }>();

    const history = useHistory();

    const schedule = useRecoilValue(rateScheduleQuery(partnerId!));
    const currentRateID = useRecoilValue(currentRateIDQuery(partnerId));

    return (
        <Page
            header={
                <Bar
                    startContent={
                        <Button
                            onClick={() => history.push('/cashback/rates')}
                            icon="home"
                        />
                    }
                    endContent={
                        <Button
                            onClick={() =>
                                history.push(
                                    '/cashback/rates/create?partnerId=' +
                                        partnerId,
                                )
                            }
                            icon="add"
                        />
                    }
                >
                    <Label>
                        Cashback Rate Schedule for{' '}
                        <PartnerName id={partnerId} />
                    </Label>
                </Bar>
            }
        >
            <Table
                columns={
                    <>
                        <TableColumn>
                            <Label>ID</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>State</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Rate</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Sponsored Rate</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Total Rate</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Fee</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Valid From</Label>
                        </TableColumn>
                    </>
                }
            >
                {schedule.map((cr) => (
                    <TableRow key={cr.id} selected={cr.id === currentRateID}>
                        <TableCell>
                            <Label>{cr.id}</Label>
                        </TableCell>
                        <TableCell>
                            <Icon
                                name={(() => {
                                    if (cr.id === currentRateID) {
                                        return 'present';
                                    }
                                    if (
                                        Timestamp.toDate(cr.validFrom!) <
                                        new Date()
                                    ) {
                                        return 'locked';
                                    }
                                    return 'pending';
                                })()}
                            />
                        </TableCell>
                        <TableCell>
                            <Label>
                                {formatPercentage({
                                    valueUnscaled: cr.rate,
                                    valueScale: cr.rateScale,
                                })}
                            </Label>
                        </TableCell>
                        <TableCell>
                            <Label>
                                {formatPercentage({
                                    valueUnscaled: cr.sponsoredRate,
                                    valueScale: cr.sponsoredRateScale,
                                })}
                            </Label>
                        </TableCell>
                        <TableCell>
                            <Label>
                                {formatPercentage(
                                    addValues(
                                        cr.rate,
                                        cr.rateScale,
                                        cr.sponsoredRate,
                                        cr.sponsoredRateScale,
                                    ),
                                )}
                            </Label>
                        </TableCell>
                        <TableCell>
                            <Label>
                                {formatPercentage({
                                    valueUnscaled: cr.fee,
                                    valueScale: cr.feeScale,
                                })}
                            </Label>
                        </TableCell>
                        <TableCell>
                            <Label>
                                {Timestamp.toDate(
                                    cr.validFrom!,
                                ).toLocaleString()}
                            </Label>
                        </TableCell>
                    </TableRow>
                ))}
            </Table>
        </Page>
    );
}
