import { useRef, ReactNode } from 'react';
import cx from 'classnames';
import { Transition } from './Transition';
import { useHtmlId } from '../hooks';
import { IconClose } from '../icons';

type Props = {
    visible: boolean;
    children: ReactNode | ((visible: boolean) => ReactNode);
    headline: ReactNode;
    onRequestClose?: () => void;
};

function Modal({ visible, headline, children, onRequestClose }: Props) {
    const id = useHtmlId();

    const backdropRef = useRef<HTMLDivElement>(null);
    const modalRef = useRef<HTMLDivElement>(null);

    // @ts-ignore
    const body = typeof children === 'function' ? children(visible) : children;

    return (
        <div
            className={cx(
                'fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center z-10',
                {
                    'pointer-events-none': !visible,
                    'pointer-events-auto': visible,
                },
            )}
        >
            <Transition
                nodeRef={backdropRef}
                show={visible}
                appear
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div
                    ref={backdropRef}
                    className="fixed inset-0 transition-opacity"
                    onClick={onRequestClose}
                >
                    <div className="absolute inset-0 bg-gray-500 opacity-75" />
                </div>
            </Transition>

            <Transition
                nodeRef={modalRef}
                show={visible}
                appear
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
                <div
                    ref={modalRef}
                    className="bg-white rounded-xl overflow-hidden shadow-xl transform transition-all sm:max-w-xl sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby={id}
                >
                    {!!onRequestClose && (
                        <div className="absolute top-0 right-0 px-4 pt-4">
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    onRequestClose();
                                }}
                                className="p-2 rounded hover:bg-gray-50"
                            >
                                <IconClose className="h-6 w-6 text-gray-800" />
                            </button>
                        </div>
                    )}
                    <div className="bg-white px-4 pb-4">
                        <div className="py-6 flex items-center">
                            <h3
                                className="text-lg leading-6 font-bold text-primary"
                                id={id}
                            >
                                {headline}
                            </h3>
                        </div>
                        {body}
                    </div>
                </div>
            </Transition>
        </div>
    );
}

export default Modal;
