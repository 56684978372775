// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "ledger/rpc/ledger.proto" (package "one.plan3t.core.ledger.rpc", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Date } from "../../model/common";
/**
 * @generated from protobuf message one.plan3t.core.ledger.rpc.Transaction
 */
export interface Transaction {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string ledger_id = 2;
     */
    ledgerId: string;
    /**
     * @generated from protobuf field: string description = 3;
     */
    description: string;
    /**
     * @generated from protobuf field: map<string, string> metadata = 4;
     */
    metadata: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: one.plan3t.core.model.Date effective_date = 5;
     */
    effectiveDate?: Date;
    /**
     * @generated from protobuf field: one.plan3t.core.ledger.rpc.TransactionState state = 6;
     */
    state: TransactionState;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.ledger.rpc.Entry entries = 7;
     */
    entries: Entry[];
}
/**
 * @generated from protobuf message one.plan3t.core.ledger.rpc.Entry
 */
export interface Entry {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: int64 debit = 2;
     */
    debit: number;
    /**
     * @generated from protobuf field: int64 credit = 3;
     */
    credit: number;
    /**
     * @generated from protobuf field: string account_id = 4;
     */
    accountId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.ledger.rpc.CreateTransactionRequest
 */
export interface CreateTransactionRequest {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string ledger_id = 2;
     */
    ledgerId: string;
    /**
     * @generated from protobuf field: map<string, string> metadata = 3;
     */
    metadata: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: string description = 4;
     */
    description: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Date effective_date = 5;
     */
    effectiveDate?: Date;
    /**
     * @generated from protobuf field: one.plan3t.core.ledger.rpc.TransactionState state = 6;
     */
    state: TransactionState;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.ledger.rpc.Entry entries = 7;
     */
    entries: Entry[];
}
/**
 * @generated from protobuf message one.plan3t.core.ledger.rpc.CreateTransactionResponse
 */
export interface CreateTransactionResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.ledger.rpc.Account
 */
export interface Account {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string ledger_id = 2;
     */
    ledgerId: string;
    /**
     * @generated from protobuf field: uint32 number = 3;
     */
    number: number;
    /**
     * @generated from protobuf field: string name = 4;
     */
    name: string;
    /**
     * @generated from protobuf field: string description = 5;
     */
    description: string;
    /**
     * @generated from protobuf field: one.plan3t.core.ledger.rpc.DebitCreditMarker normal_balance = 6;
     */
    normalBalance: DebitCreditMarker;
    /**
     * @generated from protobuf field: map<string, string> metadata = 7;
     */
    metadata: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: string currency = 8;
     */
    currency: string;
    /**
     * @generated from protobuf field: uint32 currency_exponent = 9;
     */
    currencyExponent: number;
}
/**
 * @generated from protobuf message one.plan3t.core.ledger.rpc.GetTrialBalanceRequest
 */
export interface GetTrialBalanceRequest {
    /**
     * @generated from protobuf field: string ledger_id = 1;
     */
    ledgerId: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Date as_of_date = 2;
     */
    asOfDate?: Date;
}
/**
 * @generated from protobuf message one.plan3t.core.ledger.rpc.GetTrialBalanceResponse
 */
export interface GetTrialBalanceResponse {
    /**
     * maps account_id -> balances
     *
     * @generated from protobuf field: map<string, one.plan3t.core.ledger.rpc.GetTrialBalanceResponse.Balances> balances = 1;
     */
    balances: {
        [key: string]: GetTrialBalanceResponse_Balances;
    };
    /**
     * @generated from protobuf field: repeated one.plan3t.core.ledger.rpc.Account accounts = 2;
     */
    accounts: Account[];
}
/**
 * @generated from protobuf message one.plan3t.core.ledger.rpc.GetTrialBalanceResponse.Balances
 */
export interface GetTrialBalanceResponse_Balances {
    /**
     * pending is the sum of all pending and posted entries on the ledger account.
     *
     * @generated from protobuf field: one.plan3t.core.ledger.rpc.GetTrialBalanceResponse.Balance pending = 1;
     */
    pending?: GetTrialBalanceResponse_Balance;
    /**
     * posted is the sum of all posted entries on the ledger account.
     *
     * @generated from protobuf field: one.plan3t.core.ledger.rpc.GetTrialBalanceResponse.Balance posted = 2;
     */
    posted?: GetTrialBalanceResponse_Balance;
}
/**
 * @generated from protobuf message one.plan3t.core.ledger.rpc.GetTrialBalanceResponse.Balance
 */
export interface GetTrialBalanceResponse_Balance {
    /**
     * @generated from protobuf field: int64 debit = 2;
     */
    debit: number;
    /**
     * @generated from protobuf field: int64 credit = 3;
     */
    credit: number;
    /**
     * @generated from protobuf field: int64 amount = 4;
     */
    amount: number;
    /**
     * @generated from protobuf field: string currency = 5;
     */
    currency: string;
    /**
     * @generated from protobuf field: uint32 currency_exponent = 6;
     */
    currencyExponent: number;
}
/**
 * @generated from protobuf message one.plan3t.core.ledger.rpc.GetTransactionsRequest
 */
export interface GetTransactionsRequest {
    /**
     * @generated from protobuf field: one.plan3t.core.ledger.rpc.GetTransactionsRequest.Filter filter = 1;
     */
    filter?: GetTransactionsRequest_Filter;
    /**
     * @generated from protobuf field: int32 page_size = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: string page_token = 3;
     */
    pageToken: string;
}
/**
 * @generated from protobuf message one.plan3t.core.ledger.rpc.GetTransactionsRequest.Filter
 */
export interface GetTransactionsRequest_Filter {
    /**
     * @generated from protobuf field: string ledger_id = 1;
     */
    ledgerId: string;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.ledger.rpc.TransactionState states = 2;
     */
    states: TransactionState[];
    /**
     * @generated from protobuf field: optional one.plan3t.core.model.Date before = 3;
     */
    before?: Date;
    /**
     * @generated from protobuf field: optional one.plan3t.core.model.Date after = 4;
     */
    after?: Date;
}
/**
 * @generated from protobuf message one.plan3t.core.ledger.rpc.GetTransactionsResponse
 */
export interface GetTransactionsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.ledger.rpc.Transaction transactions = 1;
     */
    transactions: Transaction[];
    /**
     * @generated from protobuf field: string next_page_token = 2;
     */
    nextPageToken: string;
}
/**
 * @generated from protobuf enum one.plan3t.core.ledger.rpc.DebitCreditMarker
 */
export enum DebitCreditMarker {
    /**
     * @generated from protobuf enum value: DEBIT_CREDIT_MARKER_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: DEBIT_CREDIT_MARKER_DEBIT = 1;
     */
    DEBIT = 1,
    /**
     * @generated from protobuf enum value: DEBIT_CREDIT_MARKER_CREDIT = 2;
     */
    CREDIT = 2
}
/**
 * @generated from protobuf enum one.plan3t.core.ledger.rpc.TransactionState
 */
export enum TransactionState {
    /**
     * @generated from protobuf enum value: TRANSACTION_STATE_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * @generated from protobuf enum value: TRANSACTION_STATE_PENDING = 1;
     */
    PENDING = 1,
    /**
     * @generated from protobuf enum value: TRANSACTION_STATE_POSTED = 2;
     */
    POSTED = 2,
    /**
     * @generated from protobuf enum value: TRANSACTION_STATE_ARCHIVED = 3;
     */
    ARCHIVED = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class Transaction$Type extends MessageType<Transaction> {
    constructor() {
        super("one.plan3t.core.ledger.rpc.Transaction", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "ledger_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "metadata", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 5, name: "effective_date", kind: "message", T: () => Date },
            { no: 6, name: "state", kind: "enum", T: () => ["one.plan3t.core.ledger.rpc.TransactionState", TransactionState, "TRANSACTION_STATE_"] },
            { no: 7, name: "entries", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Entry }
        ]);
    }
    create(value?: PartialMessage<Transaction>): Transaction {
        const message = { id: "", ledgerId: "", description: "", metadata: {}, state: 0, entries: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Transaction>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Transaction): Transaction {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string ledger_id */ 2:
                    message.ledgerId = reader.string();
                    break;
                case /* string description */ 3:
                    message.description = reader.string();
                    break;
                case /* map<string, string> metadata */ 4:
                    this.binaryReadMap4(message.metadata, reader, options);
                    break;
                case /* one.plan3t.core.model.Date effective_date */ 5:
                    message.effectiveDate = Date.internalBinaryRead(reader, reader.uint32(), options, message.effectiveDate);
                    break;
                case /* one.plan3t.core.ledger.rpc.TransactionState state */ 6:
                    message.state = reader.int32();
                    break;
                case /* repeated one.plan3t.core.ledger.rpc.Entry entries */ 7:
                    message.entries.push(Entry.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap4(map: Transaction["metadata"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Transaction["metadata"] | undefined, val: Transaction["metadata"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.ledger.rpc.Transaction.metadata");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: Transaction, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string ledger_id = 2; */
        if (message.ledgerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.ledgerId);
        /* string description = 3; */
        if (message.description !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.description);
        /* map<string, string> metadata = 4; */
        for (let k of Object.keys(message.metadata))
            writer.tag(4, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.metadata[k]).join();
        /* one.plan3t.core.model.Date effective_date = 5; */
        if (message.effectiveDate)
            Date.internalBinaryWrite(message.effectiveDate, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.ledger.rpc.TransactionState state = 6; */
        if (message.state !== 0)
            writer.tag(6, WireType.Varint).int32(message.state);
        /* repeated one.plan3t.core.ledger.rpc.Entry entries = 7; */
        for (let i = 0; i < message.entries.length; i++)
            Entry.internalBinaryWrite(message.entries[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.ledger.rpc.Transaction
 */
export const Transaction = new Transaction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Entry$Type extends MessageType<Entry> {
    constructor() {
        super("one.plan3t.core.ledger.rpc.Entry", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "debit", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "credit", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Entry>): Entry {
        const message = { id: "", debit: 0, credit: 0, accountId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Entry>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Entry): Entry {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* int64 debit */ 2:
                    message.debit = reader.int64().toNumber();
                    break;
                case /* int64 credit */ 3:
                    message.credit = reader.int64().toNumber();
                    break;
                case /* string account_id */ 4:
                    message.accountId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Entry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* int64 debit = 2; */
        if (message.debit !== 0)
            writer.tag(2, WireType.Varint).int64(message.debit);
        /* int64 credit = 3; */
        if (message.credit !== 0)
            writer.tag(3, WireType.Varint).int64(message.credit);
        /* string account_id = 4; */
        if (message.accountId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.accountId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.ledger.rpc.Entry
 */
export const Entry = new Entry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateTransactionRequest$Type extends MessageType<CreateTransactionRequest> {
    constructor() {
        super("one.plan3t.core.ledger.rpc.CreateTransactionRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "ledger_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "metadata", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "effective_date", kind: "message", T: () => Date },
            { no: 6, name: "state", kind: "enum", T: () => ["one.plan3t.core.ledger.rpc.TransactionState", TransactionState, "TRANSACTION_STATE_"] },
            { no: 7, name: "entries", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Entry }
        ]);
    }
    create(value?: PartialMessage<CreateTransactionRequest>): CreateTransactionRequest {
        const message = { id: "", ledgerId: "", metadata: {}, description: "", state: 0, entries: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateTransactionRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateTransactionRequest): CreateTransactionRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string ledger_id */ 2:
                    message.ledgerId = reader.string();
                    break;
                case /* map<string, string> metadata */ 3:
                    this.binaryReadMap3(message.metadata, reader, options);
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* one.plan3t.core.model.Date effective_date */ 5:
                    message.effectiveDate = Date.internalBinaryRead(reader, reader.uint32(), options, message.effectiveDate);
                    break;
                case /* one.plan3t.core.ledger.rpc.TransactionState state */ 6:
                    message.state = reader.int32();
                    break;
                case /* repeated one.plan3t.core.ledger.rpc.Entry entries */ 7:
                    message.entries.push(Entry.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: CreateTransactionRequest["metadata"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CreateTransactionRequest["metadata"] | undefined, val: CreateTransactionRequest["metadata"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.ledger.rpc.CreateTransactionRequest.metadata");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: CreateTransactionRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string ledger_id = 2; */
        if (message.ledgerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.ledgerId);
        /* map<string, string> metadata = 3; */
        for (let k of Object.keys(message.metadata))
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.metadata[k]).join();
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* one.plan3t.core.model.Date effective_date = 5; */
        if (message.effectiveDate)
            Date.internalBinaryWrite(message.effectiveDate, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.ledger.rpc.TransactionState state = 6; */
        if (message.state !== 0)
            writer.tag(6, WireType.Varint).int32(message.state);
        /* repeated one.plan3t.core.ledger.rpc.Entry entries = 7; */
        for (let i = 0; i < message.entries.length; i++)
            Entry.internalBinaryWrite(message.entries[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.ledger.rpc.CreateTransactionRequest
 */
export const CreateTransactionRequest = new CreateTransactionRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateTransactionResponse$Type extends MessageType<CreateTransactionResponse> {
    constructor() {
        super("one.plan3t.core.ledger.rpc.CreateTransactionResponse", []);
    }
    create(value?: PartialMessage<CreateTransactionResponse>): CreateTransactionResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateTransactionResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateTransactionResponse): CreateTransactionResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CreateTransactionResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.ledger.rpc.CreateTransactionResponse
 */
export const CreateTransactionResponse = new CreateTransactionResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Account$Type extends MessageType<Account> {
    constructor() {
        super("one.plan3t.core.ledger.rpc.Account", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "ledger_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "number", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "normal_balance", kind: "enum", T: () => ["one.plan3t.core.ledger.rpc.DebitCreditMarker", DebitCreditMarker, "DEBIT_CREDIT_MARKER_"] },
            { no: 7, name: "metadata", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 8, name: "currency", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "currency_exponent", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<Account>): Account {
        const message = { id: "", ledgerId: "", number: 0, name: "", description: "", normalBalance: 0, metadata: {}, currency: "", currencyExponent: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Account>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Account): Account {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string ledger_id */ 2:
                    message.ledgerId = reader.string();
                    break;
                case /* uint32 number */ 3:
                    message.number = reader.uint32();
                    break;
                case /* string name */ 4:
                    message.name = reader.string();
                    break;
                case /* string description */ 5:
                    message.description = reader.string();
                    break;
                case /* one.plan3t.core.ledger.rpc.DebitCreditMarker normal_balance */ 6:
                    message.normalBalance = reader.int32();
                    break;
                case /* map<string, string> metadata */ 7:
                    this.binaryReadMap7(message.metadata, reader, options);
                    break;
                case /* string currency */ 8:
                    message.currency = reader.string();
                    break;
                case /* uint32 currency_exponent */ 9:
                    message.currencyExponent = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap7(map: Account["metadata"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof Account["metadata"] | undefined, val: Account["metadata"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.ledger.rpc.Account.metadata");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: Account, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string ledger_id = 2; */
        if (message.ledgerId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.ledgerId);
        /* uint32 number = 3; */
        if (message.number !== 0)
            writer.tag(3, WireType.Varint).uint32(message.number);
        /* string name = 4; */
        if (message.name !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.name);
        /* string description = 5; */
        if (message.description !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.description);
        /* one.plan3t.core.ledger.rpc.DebitCreditMarker normal_balance = 6; */
        if (message.normalBalance !== 0)
            writer.tag(6, WireType.Varint).int32(message.normalBalance);
        /* map<string, string> metadata = 7; */
        for (let k of Object.keys(message.metadata))
            writer.tag(7, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.metadata[k]).join();
        /* string currency = 8; */
        if (message.currency !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.currency);
        /* uint32 currency_exponent = 9; */
        if (message.currencyExponent !== 0)
            writer.tag(9, WireType.Varint).uint32(message.currencyExponent);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.ledger.rpc.Account
 */
export const Account = new Account$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTrialBalanceRequest$Type extends MessageType<GetTrialBalanceRequest> {
    constructor() {
        super("one.plan3t.core.ledger.rpc.GetTrialBalanceRequest", [
            { no: 1, name: "ledger_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "as_of_date", kind: "message", T: () => Date }
        ]);
    }
    create(value?: PartialMessage<GetTrialBalanceRequest>): GetTrialBalanceRequest {
        const message = { ledgerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTrialBalanceRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTrialBalanceRequest): GetTrialBalanceRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ledger_id */ 1:
                    message.ledgerId = reader.string();
                    break;
                case /* one.plan3t.core.model.Date as_of_date */ 2:
                    message.asOfDate = Date.internalBinaryRead(reader, reader.uint32(), options, message.asOfDate);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTrialBalanceRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ledger_id = 1; */
        if (message.ledgerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ledgerId);
        /* one.plan3t.core.model.Date as_of_date = 2; */
        if (message.asOfDate)
            Date.internalBinaryWrite(message.asOfDate, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.ledger.rpc.GetTrialBalanceRequest
 */
export const GetTrialBalanceRequest = new GetTrialBalanceRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTrialBalanceResponse$Type extends MessageType<GetTrialBalanceResponse> {
    constructor() {
        super("one.plan3t.core.ledger.rpc.GetTrialBalanceResponse", [
            { no: 1, name: "balances", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => GetTrialBalanceResponse_Balances } },
            { no: 2, name: "accounts", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Account }
        ]);
    }
    create(value?: PartialMessage<GetTrialBalanceResponse>): GetTrialBalanceResponse {
        const message = { balances: {}, accounts: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTrialBalanceResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTrialBalanceResponse): GetTrialBalanceResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, one.plan3t.core.ledger.rpc.GetTrialBalanceResponse.Balances> balances */ 1:
                    this.binaryReadMap1(message.balances, reader, options);
                    break;
                case /* repeated one.plan3t.core.ledger.rpc.Account accounts */ 2:
                    message.accounts.push(Account.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: GetTrialBalanceResponse["balances"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetTrialBalanceResponse["balances"] | undefined, val: GetTrialBalanceResponse["balances"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = GetTrialBalanceResponse_Balances.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.ledger.rpc.GetTrialBalanceResponse.balances");
            }
        }
        map[key ?? ""] = val ?? GetTrialBalanceResponse_Balances.create();
    }
    internalBinaryWrite(message: GetTrialBalanceResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, one.plan3t.core.ledger.rpc.GetTrialBalanceResponse.Balances> balances = 1; */
        for (let k of Object.keys(message.balances)) {
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            GetTrialBalanceResponse_Balances.internalBinaryWrite(message.balances[k], writer, options);
            writer.join().join();
        }
        /* repeated one.plan3t.core.ledger.rpc.Account accounts = 2; */
        for (let i = 0; i < message.accounts.length; i++)
            Account.internalBinaryWrite(message.accounts[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.ledger.rpc.GetTrialBalanceResponse
 */
export const GetTrialBalanceResponse = new GetTrialBalanceResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTrialBalanceResponse_Balances$Type extends MessageType<GetTrialBalanceResponse_Balances> {
    constructor() {
        super("one.plan3t.core.ledger.rpc.GetTrialBalanceResponse.Balances", [
            { no: 1, name: "pending", kind: "message", T: () => GetTrialBalanceResponse_Balance },
            { no: 2, name: "posted", kind: "message", T: () => GetTrialBalanceResponse_Balance }
        ]);
    }
    create(value?: PartialMessage<GetTrialBalanceResponse_Balances>): GetTrialBalanceResponse_Balances {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTrialBalanceResponse_Balances>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTrialBalanceResponse_Balances): GetTrialBalanceResponse_Balances {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.ledger.rpc.GetTrialBalanceResponse.Balance pending */ 1:
                    message.pending = GetTrialBalanceResponse_Balance.internalBinaryRead(reader, reader.uint32(), options, message.pending);
                    break;
                case /* one.plan3t.core.ledger.rpc.GetTrialBalanceResponse.Balance posted */ 2:
                    message.posted = GetTrialBalanceResponse_Balance.internalBinaryRead(reader, reader.uint32(), options, message.posted);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTrialBalanceResponse_Balances, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.ledger.rpc.GetTrialBalanceResponse.Balance pending = 1; */
        if (message.pending)
            GetTrialBalanceResponse_Balance.internalBinaryWrite(message.pending, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.ledger.rpc.GetTrialBalanceResponse.Balance posted = 2; */
        if (message.posted)
            GetTrialBalanceResponse_Balance.internalBinaryWrite(message.posted, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.ledger.rpc.GetTrialBalanceResponse.Balances
 */
export const GetTrialBalanceResponse_Balances = new GetTrialBalanceResponse_Balances$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTrialBalanceResponse_Balance$Type extends MessageType<GetTrialBalanceResponse_Balance> {
    constructor() {
        super("one.plan3t.core.ledger.rpc.GetTrialBalanceResponse.Balance", [
            { no: 2, name: "debit", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "credit", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "amount", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "currency", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "currency_exponent", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetTrialBalanceResponse_Balance>): GetTrialBalanceResponse_Balance {
        const message = { debit: 0, credit: 0, amount: 0, currency: "", currencyExponent: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTrialBalanceResponse_Balance>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTrialBalanceResponse_Balance): GetTrialBalanceResponse_Balance {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 debit */ 2:
                    message.debit = reader.int64().toNumber();
                    break;
                case /* int64 credit */ 3:
                    message.credit = reader.int64().toNumber();
                    break;
                case /* int64 amount */ 4:
                    message.amount = reader.int64().toNumber();
                    break;
                case /* string currency */ 5:
                    message.currency = reader.string();
                    break;
                case /* uint32 currency_exponent */ 6:
                    message.currencyExponent = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTrialBalanceResponse_Balance, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 debit = 2; */
        if (message.debit !== 0)
            writer.tag(2, WireType.Varint).int64(message.debit);
        /* int64 credit = 3; */
        if (message.credit !== 0)
            writer.tag(3, WireType.Varint).int64(message.credit);
        /* int64 amount = 4; */
        if (message.amount !== 0)
            writer.tag(4, WireType.Varint).int64(message.amount);
        /* string currency = 5; */
        if (message.currency !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.currency);
        /* uint32 currency_exponent = 6; */
        if (message.currencyExponent !== 0)
            writer.tag(6, WireType.Varint).uint32(message.currencyExponent);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.ledger.rpc.GetTrialBalanceResponse.Balance
 */
export const GetTrialBalanceResponse_Balance = new GetTrialBalanceResponse_Balance$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTransactionsRequest$Type extends MessageType<GetTransactionsRequest> {
    constructor() {
        super("one.plan3t.core.ledger.rpc.GetTransactionsRequest", [
            { no: 1, name: "filter", kind: "message", T: () => GetTransactionsRequest_Filter },
            { no: 2, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTransactionsRequest>): GetTransactionsRequest {
        const message = { pageSize: 0, pageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTransactionsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTransactionsRequest): GetTransactionsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.ledger.rpc.GetTransactionsRequest.Filter filter */ 1:
                    message.filter = GetTransactionsRequest_Filter.internalBinaryRead(reader, reader.uint32(), options, message.filter);
                    break;
                case /* int32 page_size */ 2:
                    message.pageSize = reader.int32();
                    break;
                case /* string page_token */ 3:
                    message.pageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTransactionsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.ledger.rpc.GetTransactionsRequest.Filter filter = 1; */
        if (message.filter)
            GetTransactionsRequest_Filter.internalBinaryWrite(message.filter, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int32 page_size = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int32(message.pageSize);
        /* string page_token = 3; */
        if (message.pageToken !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.pageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.ledger.rpc.GetTransactionsRequest
 */
export const GetTransactionsRequest = new GetTransactionsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTransactionsRequest_Filter$Type extends MessageType<GetTransactionsRequest_Filter> {
    constructor() {
        super("one.plan3t.core.ledger.rpc.GetTransactionsRequest.Filter", [
            { no: 1, name: "ledger_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "states", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["one.plan3t.core.ledger.rpc.TransactionState", TransactionState, "TRANSACTION_STATE_"] },
            { no: 3, name: "before", kind: "message", T: () => Date },
            { no: 4, name: "after", kind: "message", T: () => Date }
        ]);
    }
    create(value?: PartialMessage<GetTransactionsRequest_Filter>): GetTransactionsRequest_Filter {
        const message = { ledgerId: "", states: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTransactionsRequest_Filter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTransactionsRequest_Filter): GetTransactionsRequest_Filter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string ledger_id */ 1:
                    message.ledgerId = reader.string();
                    break;
                case /* repeated one.plan3t.core.ledger.rpc.TransactionState states */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.states.push(reader.int32());
                    else
                        message.states.push(reader.int32());
                    break;
                case /* optional one.plan3t.core.model.Date before */ 3:
                    message.before = Date.internalBinaryRead(reader, reader.uint32(), options, message.before);
                    break;
                case /* optional one.plan3t.core.model.Date after */ 4:
                    message.after = Date.internalBinaryRead(reader, reader.uint32(), options, message.after);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTransactionsRequest_Filter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string ledger_id = 1; */
        if (message.ledgerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.ledgerId);
        /* repeated one.plan3t.core.ledger.rpc.TransactionState states = 2; */
        if (message.states.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.states.length; i++)
                writer.int32(message.states[i]);
            writer.join();
        }
        /* optional one.plan3t.core.model.Date before = 3; */
        if (message.before)
            Date.internalBinaryWrite(message.before, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* optional one.plan3t.core.model.Date after = 4; */
        if (message.after)
            Date.internalBinaryWrite(message.after, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.ledger.rpc.GetTransactionsRequest.Filter
 */
export const GetTransactionsRequest_Filter = new GetTransactionsRequest_Filter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetTransactionsResponse$Type extends MessageType<GetTransactionsResponse> {
    constructor() {
        super("one.plan3t.core.ledger.rpc.GetTransactionsResponse", [
            { no: 1, name: "transactions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Transaction },
            { no: 2, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetTransactionsResponse>): GetTransactionsResponse {
        const message = { transactions: [], nextPageToken: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetTransactionsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetTransactionsResponse): GetTransactionsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.ledger.rpc.Transaction transactions */ 1:
                    message.transactions.push(Transaction.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* string next_page_token */ 2:
                    message.nextPageToken = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetTransactionsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.ledger.rpc.Transaction transactions = 1; */
        for (let i = 0; i < message.transactions.length; i++)
            Transaction.internalBinaryWrite(message.transactions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string next_page_token = 2; */
        if (message.nextPageToken !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.nextPageToken);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.ledger.rpc.GetTransactionsResponse
 */
export const GetTransactionsResponse = new GetTransactionsResponse$Type();
/**
 * @generated ServiceType for protobuf service one.plan3t.core.ledger.rpc.Ledger
 */
export const Ledger = new ServiceType("one.plan3t.core.ledger.rpc.Ledger", [
    { name: "CreateTransaction", options: {}, I: CreateTransactionRequest, O: CreateTransactionResponse },
    { name: "GetTrialBalance", options: {}, I: GetTrialBalanceRequest, O: GetTrialBalanceResponse },
    { name: "GetTransactions", options: {}, I: GetTransactionsRequest, O: GetTransactionsResponse }
]);
