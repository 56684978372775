import { connect, ConnectedProps } from 'react-redux';
import { TextTableCell } from '../common/Table';
import { lotteryDrawingActions, lotteryModule } from './store';
import { LotteryPriceDrawingItem } from '../api/model/core';
import { Button } from '../common/Button';
import LotteryDrawingDialog from './LotteryDrawingDialog';
import LotteryDrawingWinnerDialog from './LotteryDrawingWinnerDialog';
import NumberOfTicketsButton from './NumberOfTicketsButton';
import { Timestamp } from '../api/google/protobuf/timestamp';
import ItemList from '../crud-module/ItemList';
import { FunctionComponent } from 'react';

const connector = connect(undefined, {
    enterDrawingMode: lotteryDrawingActions.enterDrawingMode,
    enterViewWinnersMode: lotteryDrawingActions.enterViewWinnersMode,
    copyDeepLink: lotteryDrawingActions.copyDeepLink,
});

type Props = ConnectedProps<typeof connector>;

export const LotteryListInner: FunctionComponent<Props> = (props) => {
    return (
        <>
            <LotteryDrawingDialog />
            <LotteryDrawingWinnerDialog />
            <ItemList
                module={lotteryModule}
                tableHeaders={['Title', 'Status', 'Actions']}
            >
                {(item) => [
                    <TextTableCell
                        primary={
                            item.title.length > 50
                                ? item.title.substring(0, 47) + '...'
                                : item.title
                        }
                        secondary={`${Timestamp.toDate(
                            item.startsAt!,
                        ).toDateString()} - ${Timestamp.toDate(
                            item.endsAt!,
                        ).toDateString()}`}
                    />,
                    <TextTableCell primary={<StatusChip item={item} />} />,
                    <div className="inline-flex">
                        {Timestamp.toDate(item.endsAt!) < new Date() &&
                            item.winnerTicketIds.length === 0 && (
                                <Button
                                    primary
                                    label="Select Winners"
                                    onClick={() =>
                                        props.enterDrawingMode(item.id)
                                    }
                                />
                            )}
                        {item.winnerTicketIds.length > 0 && (
                            <Button
                                secondary
                                label="Show Winners"
                                onClick={() =>
                                    props.enterViewWinnersMode(item.id)
                                }
                            />
                        )}
                        <NumberOfTicketsButton id={item.id} />
                        <Button
                            label="Edit"
                            secondary
                            to={`/lottery/${item.id}/edit`}
                        />
                        <Button
                            label="Copy DeepLink"
                            secondary
                            onClick={() => props.copyDeepLink({ id: item.id })}
                        />
                    </div>,
                ]}
            </ItemList>
        </>
    );
};

export default connector(LotteryListInner);

const StatusChip: React.FunctionComponent<{
    item: LotteryPriceDrawingItem;
}> = ({ item }) => {
    const now = new Date();
    if (Timestamp.toDate(item.startsAt!) > now) {
        return (
            <span className="text-sm flex items-center space-x-2 -ml-4">
                <span className="w-2 h-2 bg-yellow-500 rounded-full" />
                <span>Starts soon</span>
            </span>
        );
    } else if (Timestamp.toDate(item.endsAt!) > now) {
        return (
            <span className="text-sm flex items-center space-x-2 -ml-4">
                <span className="w-2 h-2 bg-green-500 rounded-full" />
                <span>Active</span>
            </span>
        );
    } else if (item.winnerTicketIds.length === 0) {
        return (
            <span className="text-sm flex items-center space-x-2 -ml-4">
                <span className="w-2 h-2 bg-blue-500 rounded-full" />
                <span>Waiting for drawing</span>
            </span>
        );
    } else {
        return (
            <span className="text-sm flex items-center space-x-2 -ml-4">
                <span className="w-2 h-2 bg-gray-500 rounded-full" />
                <span>{item.winnerTicketIds.length} winner drawn</span>
            </span>
        );
    }
};
