import { FunctionComponent, useEffect } from 'react';
import {
    AssessmentAuthoring,
    AssessmentScoreAuthoring,
} from '../api/model/backoffice';
import { ProgressBar } from '../common/ProgressBar';
import PartnerName from '../partner/PartnerName';
import { partnerActions } from '../partner/store';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { assessmentsActions } from './store';

export const AssessmentList: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(assessmentsActions.getAssessments());
        dispatch(partnerActions.fetchPartners());
    }, [dispatch]);

    const isLoading = useAppSelector(
        (state) => state.assessments.isLoading || state.partner.isFetching,
    );
    const err = useAppSelector(
        (state) => state.assessments.error || state.partner.fetchError,
    );

    const assessments = useAppSelector(
        (state) => state.assessments.assessments,
    );

    if (err) {
        return (
            <div className="bg-red-100 rounded-md border border-red-300 text-red-900 px-4 py-2 mt-8">
                Error loading: {err.message}
            </div>
        );
    }

    if (isLoading || !assessments) {
        return <>Loading ...</>;
    }

    if (assessments.length === 0) {
        return <>No Data</>;
    }

    return (
        <div className="flex flex-wrap -mt-8 -ml-8">
            {assessments.map((assessment) => (
                <div
                    key={assessment.partnerId}
                    className="flex flex-col mt-8 ml-8 w-84 space-y-2"
                >
                    <div className="text-md font-medium text-semantic-primary">
                        <PartnerName id={assessment.partnerId} />
                    </div>
                    <AssessmentCard assessment={assessment} />
                    {assessment.editLink && (
                        <a
                            className="text-xs underline"
                            href={assessment.editLink}
                        >
                            Edit Link
                        </a>
                    )}
                </div>
            ))}
        </div>
    );
};
interface AssessmentCardProps {
    assessment: AssessmentAuthoring;
}

const AssessmentCard = (props: AssessmentCardProps) => {
    const { assessment } = props;
    return (
        <div className="p-6 w-84 mx-auto bg-semantic-background rounded-lg shadow-lg">
            <div className="flex flex-col space-y-6 w-full divide-y divide-gray-200">
                <div className="flex flex-col items-center space-y-1">
                    <h1 className="text-2xl font-bold text-semantic-primary">
                        <span>{assessment.score}</span>
                        <span className="text-gray-500 text-lg">
                            /{assessment.scoreMax}
                        </span>
                    </h1>
                    <p className="text-xs text-gray-500 font-bold">
                        PLAN3T SCORE
                    </p>
                </div>
                <div className="flex flex-col pt-6 space-y-4">
                    {assessment.scores
                        .filter((score) => score.applicable)
                        .map((score) => (
                            <Score key={score.label} score={score} />
                        ))}
                </div>
            </div>
        </div>
    );
};

interface ScoreProps {
    score: AssessmentScoreAuthoring;
}

const Score = (props: ScoreProps) => {
    const { score } = props;
    const scorePercent =
        (score.valueUnscaled / Math.pow(10, score.valueScale)) * 100;

    return (
        <div className="flex flex-row w-full justify-between items-center">
            <h6 className="flex-shrink-0 text-xs w-1/2 text-gray-500 font-bold">
                {score.label.toUpperCase()}
            </h6>
            <div className="w-1/2">
                <ProgressBar percent={scorePercent} />
            </div>
        </div>
    );
};
