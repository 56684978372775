import React from 'react';
import { Switch, Route } from 'react-router-dom';
import CreateBroadcastMessageForm from './CreateBroadcastMessageForm';
import NotificationList from './NotificationList';

const NotificationModule: React.FunctionComponent = () => {
    return (
        <Switch>
            <Route path="/notification" exact>
                <NotificationList />
            </Route>
            <Route path="/notification/broadcast/create" exact>
                <CreateBroadcastMessageForm id={undefined} />
            </Route>
            <Route
                path="/notification/broadcast/:id/edit"
                exact
                render={({ match }) => (
                    <CreateBroadcastMessageForm id={match.params['id']} />
                )}
            />
        </Switch>
    );
};

export default NotificationModule;
