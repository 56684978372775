import {
    AcceptOpenBankingWaitlistEntryRequest,
    AcceptOpenBankingWaitlistEntryResponse,
    GetOpenBankingWaitlistEntriesResponse,
    OpenBankingWaitlistEntry,
} from '../api/model/backoffice';
import { createCrudSlice, CrudSlice } from '../crud-module/store';
import { AppState } from '../redux/AppStore';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const openBankingWaitlistModule: CrudSlice<OpenBankingWaitlistEntry> =
    createCrudSlice<OpenBankingWaitlistEntry>({
        name: 'openbankingWaitlist',
        baseUrl: '/openbanking/waitlist',
        localClientBasePath: '/openbanking/waitlist',
        stateProvider: (s) => (s as AppState).openBankingWaitlist,
        listResponseParser: (data) => {
            const res = GetOpenBankingWaitlistEntriesResponse.fromJson(data);
            return {
                items: res.items,
                nextPageToken: res.nextPageToken,
            };
        },
        singleResponseParser: (data) => {
            throw Error('not implemented');
        },
        writeArgumentTransformer: (item) => {
            throw Error('not implemented');
        },
        idProvider: ({ userId }) => userId,
        prepareEmpty: () => {
            throw Error('not implemented');
        },
    });

const acceptEntry = createAsyncThunk(
    'openbankingWaitlist/accept',
    async (arg: { userId: string }, { getState }) => {
        const req: AcceptOpenBankingWaitlistEntryRequest = {
            userId: arg.userId,
        };
        const raw = await axios.post<Object>(
            `/openbanking/waitlist/accept`,
            req,
        );
        return AcceptOpenBankingWaitlistEntryResponse.fromJson(raw.data as any);
    },
);

export const openBankingWaitlistReducer = openBankingWaitlistModule.reducer;
export const openBankingWaitlistActions = {
    ...openBankingWaitlistModule.actions,
    acceptEntry,
};
export const openBankingWaitlistSelectors = openBankingWaitlistModule.selectors;
