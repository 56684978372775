// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "model/openbanking.proto" (package "one.plan3t.core.model", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../google/protobuf/timestamp";
/**
 * @generated from protobuf message one.plan3t.core.model.Plan3tUserTransaction
 */
export interface Plan3tUserTransaction {
    /**
     * Unique identifier
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The id identifying the user.
     *
     * @generated from protobuf field: string user_id = 2;
     */
    userId: string;
    /**
     * The id identifying the api call.
     *
     * @generated from protobuf field: string fetch_id = 3;
     */
    fetchId: string;
    /**
     * The timestamp when we fetched the transaction.
     *
     * @generated from protobuf field: google.protobuf.Timestamp fetched_at = 4;
     */
    fetchedAt?: Timestamp;
    /**
     * Provider from where the transaction originates.
     *
     * @generated from protobuf field: one.plan3t.core.model.ProviderId provider_id = 5;
     */
    providerId: ProviderId;
    /**
     * The id identifying the user account.
     *
     * @generated from protobuf field: string account_id = 6;
     */
    accountId: string;
    /**
     * The type of account used for this transaction.
     *
     * @generated from protobuf field: one.plan3t.core.model.AccountType account_type = 7;
     */
    accountType: AccountType;
    /**
     * The raw transaction response from the provider.
     *
     * @generated from protobuf field: bytes raw_response = 8;
     */
    rawResponse: Uint8Array;
    /**
     * Further details of the transaction.
     *
     * @generated from protobuf field: one.plan3t.core.model.TransactionDetails transaction = 9;
     */
    transaction?: TransactionDetails;
}
/**
 * @generated from protobuf message one.plan3t.core.model.TransactionDetails
 */
export interface TransactionDetails {
    /**
     * The amount of the transaction.
     *
     * @generated from protobuf field: one.plan3t.core.model.Amount amount = 1;
     */
    amount?: Amount;
    /**
     * The date the transaction was executed.
     *
     * @generated from protobuf field: google.protobuf.Timestamp date = 2;
     */
    date?: Timestamp;
    /**
     * The counterpart of the transaction (debtor or creditor)
     *
     * @generated from protobuf field: one.plan3t.core.model.Counterpart counterpart = 3;
     */
    counterpart?: Counterpart;
    /**
     * The description that was received from the provider.
     *
     * @generated from protobuf field: string description = 4;
     */
    description: string;
    /**
     * The state of the transaction.
     *
     * @generated from protobuf field: one.plan3t.core.model.TransactionState transaction_state = 5;
     */
    transactionState: TransactionState;
    /**
     * The type of the transaction.
     *
     * @generated from protobuf field: one.plan3t.core.model.TransactionType transaction_type = 6;
     */
    transactionType: TransactionType;
}
/**
 * @generated from protobuf message one.plan3t.core.model.Amount
 */
export interface Amount {
    /**
     * Comma separated value (can be positive or negative)
     *
     * @generated from protobuf field: double scaledValue = 1;
     */
    scaledValue: number;
    /**
     * Value without decimal separator (can be positive or negative)
     *
     * @generated from protobuf field: sint32 unscaledValue = 2;
     */
    unscaledValue: number;
    /**
     * The scale of the amount (where to put the decimal separator)
     *
     * @generated from protobuf field: int32 scale = 3;
     */
    scale: number;
    /**
     * ISO4217
     *
     * @generated from protobuf field: string currency_code = 4;
     */
    currencyCode: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.Counterpart
 */
export interface Counterpart {
    /**
     * The name of the counterpart
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * The identification number of the counterpart
     *
     * @generated from protobuf field: string identification_number = 2;
     */
    identificationNumber: string;
    /**
     * The type of the identification number.
     *
     * @generated from protobuf field: one.plan3t.core.model.IdentificationNumberType identification_number_type = 3;
     */
    identificationNumberType: IdentificationNumberType;
}
/**
 * @generated from protobuf message one.plan3t.core.model.PartnerRules
 */
export interface PartnerRules {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.PartnerRule rules = 1;
     */
    rules: PartnerRule[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.PartnerRule
 */
export interface PartnerRule {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp valid_from = 1;
     */
    validFrom?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp valid_to = 2;
     */
    validTo?: Timestamp;
    /**
     * @generated from protobuf field: string partner_id = 3;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string rule_id = 4;
     */
    ruleId: string;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.PartnerCondition conditions = 5;
     */
    conditions: PartnerCondition[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.PartnerCondition
 */
export interface PartnerCondition {
    /**
     * @generated from protobuf field: string field = 1;
     */
    field: string;
    /**
     * @generated from protobuf field: string regex = 2;
     */
    regex: string;
}
/**
 * @generated from protobuf enum one.plan3t.core.model.ProviderId
 */
export enum ProviderId {
    /**
     * @generated from protobuf enum value: UNKNOWN_PROVIDER = 0;
     */
    UNKNOWN_PROVIDER = 0,
    /**
     * @generated from protobuf enum value: TINK = 1;
     */
    TINK = 1
}
/**
 * @generated from protobuf enum one.plan3t.core.model.TransactionType
 */
export enum TransactionType {
    /**
     * @generated from protobuf enum value: UNKNOWN_TRANSACTION_TYPE = 0;
     */
    UNKNOWN_TRANSACTION_TYPE = 0,
    /**
     * @generated from protobuf enum value: DEFAULT = 1;
     */
    DEFAULT = 1,
    /**
     * Rebalance credit card
     *
     * @generated from protobuf enum value: CREDIT = 2;
     */
    CREDIT = 2,
    /**
     * Moving money between own accounts
     *
     * @generated from protobuf enum value: TRANSFER = 3;
     */
    TRANSFER = 3,
    /**
     * @generated from protobuf enum value: PAYMENT = 4;
     */
    PAYMENT = 4,
    /**
     * @generated from protobuf enum value: WITHDRAWAL = 5;
     */
    WITHDRAWAL = 5
}
/**
 * @generated from protobuf enum one.plan3t.core.model.AccountType
 */
export enum AccountType {
    /**
     * @generated from protobuf enum value: UNKNOWN_ACCOUNT_TYPE = 0;
     */
    UNKNOWN_ACCOUNT_TYPE = 0,
    /**
     * @generated from protobuf enum value: CHECKING = 1;
     */
    CHECKING = 1,
    /**
     * @generated from protobuf enum value: CREDIT_CARD = 2;
     */
    CREDIT_CARD = 2,
    /**
     * @generated from protobuf enum value: SAVINGS = 3;
     */
    SAVINGS = 3
}
/**
 * @generated from protobuf enum one.plan3t.core.model.TransactionState
 */
export enum TransactionState {
    /**
     * @generated from protobuf enum value: UNKNOWN_TRANSACTION_STATE = 0;
     */
    UNKNOWN_TRANSACTION_STATE = 0,
    /**
     * Indicates if this transaction has been settled or is still pending.
     *
     * @generated from protobuf enum value: PENDING = 1;
     */
    PENDING = 1,
    /**
     * Indicates if this is an upcoming transaction not booked yet.
     *
     * @generated from protobuf enum value: UPCOMING = 2;
     */
    UPCOMING = 2,
    /**
     * Indicates if this transaction is fully booked.
     *
     * @generated from protobuf enum value: BOOKED = 3;
     */
    BOOKED = 3
}
/**
 * @generated from protobuf enum one.plan3t.core.model.IdentificationNumberType
 */
export enum IdentificationNumberType {
    /**
     * @generated from protobuf enum value: UNKNOWN_IDENTIFICATION_NUMBER_TYPE = 0;
     */
    UNKNOWN_IDENTIFICATION_NUMBER_TYPE = 0,
    /**
     * @generated from protobuf enum value: IBAN = 1;
     */
    IBAN = 1,
    /**
     * @generated from protobuf enum value: BBAN = 2;
     */
    BBAN = 2,
    /**
     * @generated from protobuf enum value: PAN = 3;
     */
    PAN = 3,
    /**
     * @generated from protobuf enum value: MASKED_PAN = 4;
     */
    MASKED_PAN = 4,
    /**
     * @generated from protobuf enum value: MSISDN = 5;
     */
    MSISDN = 5
}
// @generated message type with reflection information, may provide speed optimized methods
class Plan3tUserTransaction$Type extends MessageType<Plan3tUserTransaction> {
    constructor() {
        super("one.plan3t.core.model.Plan3tUserTransaction", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "fetch_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "fetched_at", kind: "message", T: () => Timestamp },
            { no: 5, name: "provider_id", kind: "enum", T: () => ["one.plan3t.core.model.ProviderId", ProviderId] },
            { no: 6, name: "account_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "account_type", kind: "enum", T: () => ["one.plan3t.core.model.AccountType", AccountType] },
            { no: 8, name: "raw_response", kind: "scalar", T: 12 /*ScalarType.BYTES*/ },
            { no: 9, name: "transaction", kind: "message", T: () => TransactionDetails }
        ]);
    }
    create(value?: PartialMessage<Plan3tUserTransaction>): Plan3tUserTransaction {
        const message = { id: "", userId: "", fetchId: "", providerId: 0, accountId: "", accountType: 0, rawResponse: new Uint8Array(0) };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Plan3tUserTransaction>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Plan3tUserTransaction): Plan3tUserTransaction {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string user_id */ 2:
                    message.userId = reader.string();
                    break;
                case /* string fetch_id */ 3:
                    message.fetchId = reader.string();
                    break;
                case /* google.protobuf.Timestamp fetched_at */ 4:
                    message.fetchedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.fetchedAt);
                    break;
                case /* one.plan3t.core.model.ProviderId provider_id */ 5:
                    message.providerId = reader.int32();
                    break;
                case /* string account_id */ 6:
                    message.accountId = reader.string();
                    break;
                case /* one.plan3t.core.model.AccountType account_type */ 7:
                    message.accountType = reader.int32();
                    break;
                case /* bytes raw_response */ 8:
                    message.rawResponse = reader.bytes();
                    break;
                case /* one.plan3t.core.model.TransactionDetails transaction */ 9:
                    message.transaction = TransactionDetails.internalBinaryRead(reader, reader.uint32(), options, message.transaction);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Plan3tUserTransaction, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string user_id = 2; */
        if (message.userId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        /* string fetch_id = 3; */
        if (message.fetchId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.fetchId);
        /* google.protobuf.Timestamp fetched_at = 4; */
        if (message.fetchedAt)
            Timestamp.internalBinaryWrite(message.fetchedAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.ProviderId provider_id = 5; */
        if (message.providerId !== 0)
            writer.tag(5, WireType.Varint).int32(message.providerId);
        /* string account_id = 6; */
        if (message.accountId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.accountId);
        /* one.plan3t.core.model.AccountType account_type = 7; */
        if (message.accountType !== 0)
            writer.tag(7, WireType.Varint).int32(message.accountType);
        /* bytes raw_response = 8; */
        if (message.rawResponse.length)
            writer.tag(8, WireType.LengthDelimited).bytes(message.rawResponse);
        /* one.plan3t.core.model.TransactionDetails transaction = 9; */
        if (message.transaction)
            TransactionDetails.internalBinaryWrite(message.transaction, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.Plan3tUserTransaction
 */
export const Plan3tUserTransaction = new Plan3tUserTransaction$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TransactionDetails$Type extends MessageType<TransactionDetails> {
    constructor() {
        super("one.plan3t.core.model.TransactionDetails", [
            { no: 1, name: "amount", kind: "message", T: () => Amount },
            { no: 2, name: "date", kind: "message", T: () => Timestamp },
            { no: 3, name: "counterpart", kind: "message", T: () => Counterpart },
            { no: 4, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "transaction_state", kind: "enum", T: () => ["one.plan3t.core.model.TransactionState", TransactionState] },
            { no: 6, name: "transaction_type", kind: "enum", T: () => ["one.plan3t.core.model.TransactionType", TransactionType] }
        ]);
    }
    create(value?: PartialMessage<TransactionDetails>): TransactionDetails {
        const message = { description: "", transactionState: 0, transactionType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<TransactionDetails>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TransactionDetails): TransactionDetails {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.Amount amount */ 1:
                    message.amount = Amount.internalBinaryRead(reader, reader.uint32(), options, message.amount);
                    break;
                case /* google.protobuf.Timestamp date */ 2:
                    message.date = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.date);
                    break;
                case /* one.plan3t.core.model.Counterpart counterpart */ 3:
                    message.counterpart = Counterpart.internalBinaryRead(reader, reader.uint32(), options, message.counterpart);
                    break;
                case /* string description */ 4:
                    message.description = reader.string();
                    break;
                case /* one.plan3t.core.model.TransactionState transaction_state */ 5:
                    message.transactionState = reader.int32();
                    break;
                case /* one.plan3t.core.model.TransactionType transaction_type */ 6:
                    message.transactionType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TransactionDetails, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.Amount amount = 1; */
        if (message.amount)
            Amount.internalBinaryWrite(message.amount, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp date = 2; */
        if (message.date)
            Timestamp.internalBinaryWrite(message.date, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.Counterpart counterpart = 3; */
        if (message.counterpart)
            Counterpart.internalBinaryWrite(message.counterpart, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string description = 4; */
        if (message.description !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.description);
        /* one.plan3t.core.model.TransactionState transaction_state = 5; */
        if (message.transactionState !== 0)
            writer.tag(5, WireType.Varint).int32(message.transactionState);
        /* one.plan3t.core.model.TransactionType transaction_type = 6; */
        if (message.transactionType !== 0)
            writer.tag(6, WireType.Varint).int32(message.transactionType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.TransactionDetails
 */
export const TransactionDetails = new TransactionDetails$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Amount$Type extends MessageType<Amount> {
    constructor() {
        super("one.plan3t.core.model.Amount", [
            { no: 1, name: "scaledValue", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ },
            { no: 2, name: "unscaledValue", kind: "scalar", T: 17 /*ScalarType.SINT32*/ },
            { no: 3, name: "scale", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "currency_code", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Amount>): Amount {
        const message = { scaledValue: 0, unscaledValue: 0, scale: 0, currencyCode: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Amount>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Amount): Amount {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* double scaledValue */ 1:
                    message.scaledValue = reader.double();
                    break;
                case /* sint32 unscaledValue */ 2:
                    message.unscaledValue = reader.sint32();
                    break;
                case /* int32 scale */ 3:
                    message.scale = reader.int32();
                    break;
                case /* string currency_code */ 4:
                    message.currencyCode = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Amount, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* double scaledValue = 1; */
        if (message.scaledValue !== 0)
            writer.tag(1, WireType.Bit64).double(message.scaledValue);
        /* sint32 unscaledValue = 2; */
        if (message.unscaledValue !== 0)
            writer.tag(2, WireType.Varint).sint32(message.unscaledValue);
        /* int32 scale = 3; */
        if (message.scale !== 0)
            writer.tag(3, WireType.Varint).int32(message.scale);
        /* string currency_code = 4; */
        if (message.currencyCode !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.currencyCode);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.Amount
 */
export const Amount = new Amount$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Counterpart$Type extends MessageType<Counterpart> {
    constructor() {
        super("one.plan3t.core.model.Counterpart", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "identification_number", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "identification_number_type", kind: "enum", T: () => ["one.plan3t.core.model.IdentificationNumberType", IdentificationNumberType] }
        ]);
    }
    create(value?: PartialMessage<Counterpart>): Counterpart {
        const message = { name: "", identificationNumber: "", identificationNumberType: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Counterpart>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Counterpart): Counterpart {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string identification_number */ 2:
                    message.identificationNumber = reader.string();
                    break;
                case /* one.plan3t.core.model.IdentificationNumberType identification_number_type */ 3:
                    message.identificationNumberType = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Counterpart, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string identification_number = 2; */
        if (message.identificationNumber !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.identificationNumber);
        /* one.plan3t.core.model.IdentificationNumberType identification_number_type = 3; */
        if (message.identificationNumberType !== 0)
            writer.tag(3, WireType.Varint).int32(message.identificationNumberType);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.Counterpart
 */
export const Counterpart = new Counterpart$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PartnerRules$Type extends MessageType<PartnerRules> {
    constructor() {
        super("one.plan3t.core.model.PartnerRules", [
            { no: 1, name: "rules", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PartnerRule }
        ]);
    }
    create(value?: PartialMessage<PartnerRules>): PartnerRules {
        const message = { rules: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PartnerRules>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PartnerRules): PartnerRules {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.PartnerRule rules */ 1:
                    message.rules.push(PartnerRule.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PartnerRules, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.PartnerRule rules = 1; */
        for (let i = 0; i < message.rules.length; i++)
            PartnerRule.internalBinaryWrite(message.rules[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PartnerRules
 */
export const PartnerRules = new PartnerRules$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PartnerRule$Type extends MessageType<PartnerRule> {
    constructor() {
        super("one.plan3t.core.model.PartnerRule", [
            { no: 1, name: "valid_from", kind: "message", T: () => Timestamp },
            { no: 2, name: "valid_to", kind: "message", T: () => Timestamp },
            { no: 3, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "rule_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "conditions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PartnerCondition }
        ]);
    }
    create(value?: PartialMessage<PartnerRule>): PartnerRule {
        const message = { partnerId: "", ruleId: "", conditions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PartnerRule>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PartnerRule): PartnerRule {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp valid_from */ 1:
                    message.validFrom = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.validFrom);
                    break;
                case /* google.protobuf.Timestamp valid_to */ 2:
                    message.validTo = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.validTo);
                    break;
                case /* string partner_id */ 3:
                    message.partnerId = reader.string();
                    break;
                case /* string rule_id */ 4:
                    message.ruleId = reader.string();
                    break;
                case /* repeated one.plan3t.core.model.PartnerCondition conditions */ 5:
                    message.conditions.push(PartnerCondition.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PartnerRule, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp valid_from = 1; */
        if (message.validFrom)
            Timestamp.internalBinaryWrite(message.validFrom, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp valid_to = 2; */
        if (message.validTo)
            Timestamp.internalBinaryWrite(message.validTo, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string partner_id = 3; */
        if (message.partnerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.partnerId);
        /* string rule_id = 4; */
        if (message.ruleId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.ruleId);
        /* repeated one.plan3t.core.model.PartnerCondition conditions = 5; */
        for (let i = 0; i < message.conditions.length; i++)
            PartnerCondition.internalBinaryWrite(message.conditions[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PartnerRule
 */
export const PartnerRule = new PartnerRule$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PartnerCondition$Type extends MessageType<PartnerCondition> {
    constructor() {
        super("one.plan3t.core.model.PartnerCondition", [
            { no: 1, name: "field", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "regex", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PartnerCondition>): PartnerCondition {
        const message = { field: "", regex: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PartnerCondition>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PartnerCondition): PartnerCondition {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string field */ 1:
                    message.field = reader.string();
                    break;
                case /* string regex */ 2:
                    message.regex = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PartnerCondition, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string field = 1; */
        if (message.field !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.field);
        /* string regex = 2; */
        if (message.regex !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.regex);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.PartnerCondition
 */
export const PartnerCondition = new PartnerCondition$Type();
