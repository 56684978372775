import {
    ContentFeedItemStyle,
    FeedItem,
    FeedItemType,
} from '../api/model/feed';
import {
    CreateFeedItemRequest,
    GetFeedItemsResponse,
} from '../api/model/backoffice';
import { createCrudSlice, CrudSlice } from '../crud-module/store';
import { AppState } from '../redux/AppStore';
import { ulid } from 'ulid';

export const feedModule: CrudSlice<FeedItem> = createCrudSlice<FeedItem>({
    name: 'feed',
    baseUrl: '/feed',
    localClientBasePath: '/feed',
    stateProvider: (s) => (s as AppState).feed,
    listResponseParser: (data) => {
        const res = GetFeedItemsResponse.fromJson(data);
        return {
            items: res.items,
            nextPageToken: res.nextPageToken,
        };
    },
    singleResponseParser: (data) => {
        throw Error('not implemented');
    },
    writeArgumentTransformer: (item) => {
        return CreateFeedItemRequest.toJson({ item });
    },
    idProvider: ({ id }) => id,
    prepareEmpty: () => ({
        id: ulid(),
        type: FeedItemType.CONTENT,
        timestamp: undefined,
        expiry: undefined,
        data: {
            oneofKind: 'contentFeedItemData',
            contentFeedItemData: {
                route: '',
                title: '',
                subtitle: '',
                image: undefined,
                style: ContentFeedItemStyle.UNKNOWN,
                partnerId: '',
            },
        },
    }),
});

export const feedReducer = feedModule.reducer;
export const feedActions = feedModule.actions;
export const feedSelectors = feedModule.selectors;
