import React, { SyntheticEvent, useCallback, useState } from 'react';
import { Button } from '../common/Button';
import { useAppDispatch } from '../redux/react';
import { partnerActions } from './store';
import { useHistory } from 'react-router-dom';
import { snackbarActions } from '../snackbar/store';
import { ulid } from 'ulid';
import cx from 'classnames';

const CreatePartnerForm: React.FunctionComponent = () => {
    const [id] = useState(ulid());
    const [legalName, setLegalName] = useState('');
    const [brandName, setBrandName] = useState('');
    const [email, setEmail] = useState('');
    const [noManager, setNoManager] = useState(false);
    const [affiliateOnly, setAffiliateOnly] = useState(false);

    const history = useHistory();

    const dispatch = useAppDispatch();
    const onSubmit = useCallback(
        async (e: SyntheticEvent) => {
            e.preventDefault();
            const res = await dispatch(
                partnerActions.createPartnerBase({
                    id,
                    legalName,
                    brandName,
                    email: noManager || affiliateOnly ? '' : email,
                    affiliateOnly,
                }),
            );
            if (res.meta.requestStatus === 'fulfilled') {
                history.goBack();
            } else if (res.meta.requestStatus === 'rejected') {
                dispatch(
                    snackbarActions.sendMessage({
                        type: 'warning',
                        title: 'Error',
                        subtitle:
                            'error' in res ? res.error.message : undefined,
                    }),
                );
            }
        },
        [
            dispatch,
            legalName,
            brandName,
            email,
            noManager,
            affiliateOnly,
            history,
            id,
        ],
    );

    return (
        <form
            className="container m-0 px-4 pt-8 space-y-2 max-w-lg"
            onSubmit={onSubmit}
        >
            <div className="flex items-center space-x-2">
                <div>
                    <Button
                        secondary
                        label={
                            <svg
                                className="w-4 h-4"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                            </svg>
                        }
                        to="/partner"
                    />
                </div>
                <h1 className="font-bold text-3xl">Create Partner</h1>
            </div>
            <label className="block">
                <span className="text-gray-700">Legal Name</span>
                <input
                    type="text"
                    required
                    className="form-input mt-1 block w-full"
                    value={legalName}
                    onChange={(e) => setLegalName(e.target.value)}
                    placeholder="XYZ GmbH"
                />
            </label>
            <label className="block">
                <span className="text-gray-700">Brand Name</span>
                <input
                    type="text"
                    required
                    className="form-input mt-1 block w-full"
                    value={brandName}
                    onChange={(e) => setBrandName(e.target.value)}
                    placeholder="XYZ"
                />
            </label>
            {!noManager && !affiliateOnly && (
                <label className="block">
                    <span className="text-gray-700">Manager E-Mail</span>
                    <input
                        type="text"
                        required
                        className="form-input mt-1 block w-full"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="marketing@xzy.de"
                    />
                </label>
            )}
            <label className="flex space-x-2">
                <input
                    type="checkbox"
                    className="form-checkbox mt-1 block"
                    checked={affiliateOnly}
                    onChange={(e) => setAffiliateOnly(e.target.checked)}
                />
                <span className="text-gray-700">
                    External Affiliate Partner
                </span>
            </label>
            <div
                className={cx('flex flex-col', {
                    hidden: affiliateOnly,
                })}
            >
                <label className="flex space-x-2">
                    <input
                        type="checkbox"
                        className="form-checkbox mt-1 block"
                        checked={noManager}
                        onChange={(e) => setNoManager(e.target.checked)}
                        disabled={affiliateOnly}
                    />
                    <span className="text-gray-700">
                        Do not create a manager
                    </span>
                </label>
                <span className="text-gray-500">
                    This is useful if you want to start setting up the profile
                    first, using the "Impersonate" functionality
                </span>
                <span className="text-gray-500">
                    The creation of manager accounts in the form of
                    xxx+partner@plan3t.one is no longer the recommend way.
                </span>
            </div>
            <Button label="Create" primary />
        </form>
    );
};

export default CreatePartnerForm;
