import Pica from 'pica';
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect, ConnectedProps } from 'react-redux';
import { ImageResource, UploadedFile } from '../api/model/core';
import LoadingSpinner from '../common/LoadingSpinner';
import { useHtmlId } from '../hooks';
import { filesActions } from './store';
import Thumbnails from './Thumbnails';

const actions = {
    uploadImages: filesActions.uploadImages,
};

const connector = connect(null, actions);

type Props = ConnectedProps<typeof connector> & {
    disabled?: boolean;
    label: string;
    message: string;
    file?: ImageResource | undefined;
    files?: ImageResource[];
    onSingleChanged?: (f: ImageResource | undefined) => void;
    onMultipleChanged?: (f: ImageResource[]) => void;
    thumbnailCircleCrop?: boolean;
};

const FileUpload: React.FunctionComponent<Props> = (props) => {
    const {
        disabled,
        label,
        message,
        file,
        files,
        onMultipleChanged,
        onSingleChanged,
        uploadImages,
        thumbnailCircleCrop,
    } = props;
    const id = useHtmlId();
    const currentFiles = useMemo(
        () => (file ? [file] : files || []),
        [file, files],
    );

    const [isLoading, setIsLoading] = useState(false);

    const onDrop = useCallback(
        async (acceptedFiles: File[]) => {
            if (onSingleChanged && acceptedFiles.length > 1) {
                alert('Only one image is allowed.');
                return;
            }
            setIsLoading(true);
            try {
                const resizedImages: File[] = [];

                const opt = { maxWidth: 1400, quality: 0.6 };
                for (let f of acceptedFiles) {
                    resizedImages.push(await resizeImage(f, opt));
                }

                const uploads = await uploadImages({ files: resizedImages });
                const result = uploads.payload.map(
                    (file: { upload: UploadedFile }) => {
                        return {
                            id: file.upload.id,
                            url: file.upload.path,
                        };
                    },
                );
                if (onSingleChanged) {
                    onSingleChanged(result[0]);
                } else if (onMultipleChanged) {
                    onMultipleChanged([...currentFiles, ...result]);
                }
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        },
        [uploadImages, onSingleChanged, onMultipleChanged, currentFiles],
    );
    const { getRootProps, getInputProps } = useDropzone({
        disabled: disabled,
        accept: 'image/*',
        onDrop,
    });

    const onRemove = useCallback(
        (fileToRemove) => {
            if (onSingleChanged) {
                onSingleChanged(undefined);
            } else if (onMultipleChanged) {
                onMultipleChanged(
                    currentFiles.filter(
                        (tmp: ImageResource) => tmp.id !== fileToRemove.id,
                    ),
                );
            }
        },
        [currentFiles, onMultipleChanged, onSingleChanged],
    );

    return (
        <section>
            <label
                htmlFor={id}
                className="block text-sm leading-5 font-medium text-gray-700"
            >
                {label}
            </label>
            <div
                {...getRootProps()}
                className="mt-1 border-dashed border-2 border-gray-400 cursor-pointer bg-white w-100 h-32 rounded flex justify-center items-center hover:bg-gray-100 focus:border-green-400 focus:outline-none"
            >
                <input
                    className="focus:border-green-400"
                    id={id}
                    {...getInputProps()}
                />
                <p className="text-center">{message}</p>
            </div>
            {isLoading ? (
                <LoadingSpinner />
            ) : (
                <Thumbnails
                    images={currentFiles}
                    allowRemove={!onSingleChanged}
                    onRemove={onRemove}
                    circleCrop={!!thumbnailCircleCrop}
                />
            )}
        </section>
    );
};

export default connector(FileUpload);

type ResizeImageOptions = {
    maxWidth: number;
    quality: number;
};

function resizeImage(file: File, options: ResizeImageOptions): Promise<File> {
    return new Promise((resolve, reject) => {
        const p = new Pica();
        const img = new Image();

        img.onload = () => {
            // this is getting destroyed by the garbage collector, so no need to manually delete it or something.
            const c = document.createElement('canvas');
            const maxWidth = options.maxWidth;

            c.width = img.width;
            c.height = img.height;

            if (c.width > maxWidth) {
                c.height = c.height * (maxWidth / c.width);
                c.width = maxWidth;
            }

            p.resize(img, c, { alpha: file.type.includes('png') })
                .then((result) => p.toBlob(result, file.type, options.quality))
                .then((blob) => {
                    return new File([blob], file.name, {
                        type: file.type,
                        lastModified: file.lastModified,
                    });
                })
                .then(resolve)
                .catch(reject);
        };
        img.src = URL.createObjectURL(file);
    });
}
