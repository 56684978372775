import { GetUserAccountResponse, UserAccount } from '../api/model/core';
import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AppState } from '../redux/AppStore';

const fetchUserAccount = createAsyncThunk(
    'account/fetch',
    async (ids: string[]) => {
        const res = await axios.get<Object>(`/fuser/b2c?ids=${ids.join(',')}`);
        return GetUserAccountResponse.fromJson(res.data as any).accounts;
    },
);

type AccountState = {
    accounts: Record<
        string,
        {
            account: UserAccount;
            isLoading: boolean;
            error?: string;
        }
    >;
};

const initialState: AccountState = {
    accounts: {},
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchUserAccount.fulfilled, (state, action) => {
            const firebaseUser = action.payload;
            (firebaseUser || []).forEach((u) => {
                state.accounts[u.uid] = {
                    account: u,
                    isLoading: false,
                };
            });
        });
    },
});

export const userActions = {
    ...userSlice.actions,
    fetchUserAccount,
};

export const userReducer = userSlice.reducer;

const s = (state: AppState): AccountState => state.user;
export const userSelectors = {
    getById: (
        state: AppState,
        { id }: { id: string },
    ): UserAccount | undefined => s(state).accounts[id]?.account,
} as const;
