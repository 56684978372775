import React, { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { authActions, authSelectors } from './store';
import { Redirect } from 'react-router-dom';
import { Button } from '../common/Button';
import logo from '../images/logo.png';

const Login: React.FunctionComponent = () => {
    const isSignedIn = useAppSelector(authSelectors.isSignedIn);

    const dispatch = useAppDispatch();

    const startLogin = useCallback(() => {
        dispatch(authActions.login());
    }, [dispatch]);

    if (isSignedIn) {
        return <Redirect to="/" />;
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-md w-full space-y-2">
                <h2 className="text-center text-3xl leading-9 text-gray-900 flex flex-col items-center">
                    <img src={logo} alt="PLAN3T logo" className="h-8" />
                    <span className="text-primary uppercase tracking-wider">
                        backoffice
                    </span>
                </h2>
                <Button
                    primary
                    label="Sign in with @plan3t.one"
                    onClick={startLogin}
                />
            </div>
        </div>
    );
};

export default Login;
