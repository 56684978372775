import axios from 'axios';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppState } from '../redux/AppStore';
import {
    CreateNotificationBroadcastMessageRequest,
    GetNotificationBroadcastMessagesResponse,
    NotificationBroadcastMessage,
    NotificationMessageBroadcastState,
    SendNotificationBroadcastMessageRequest,
    GetNotificationBroadcastStatsResponse,
} from '../api/model/backoffice';

const writeNotificationBroadcastMessage = createAsyncThunk(
    'notification/broadcast/write',
    async (message: NotificationBroadcastMessage) => {
        const req: CreateNotificationBroadcastMessageRequest = {
            message,
        };

        await axios.post<Object>('/notification/broadcast', req);

        return message;
    },
);

const sendNotification = createAsyncThunk(
    'notification/broadcast/send',
    async (id: string, { dispatch }) => {
        const req: SendNotificationBroadcastMessageRequest = {
            id,
        };

        await axios.post<Object>('/notification/broadcast/-/send', req);

        dispatch(fetchRecentBroadcasts());

        return id;
    },
);

const fetchRecentBroadcasts = createAsyncThunk(
    'notification/broadcast/fetch',
    async () => {
        const raw = await axios.get<Object>(`/notification/broadcast`);
        const res = GetNotificationBroadcastMessagesResponse.fromJson(
            raw.data as any,
        );
        return res.messages;
    },
);

const fetchStats = createAsyncThunk(
    'notification/broadcast/stats/fetch',
    async (_: void, { getState }) => {
        const id = (getState() as AppState).notification
            .viewStatsForBroadcastMessageId;
        if (!id) {
            throw new Error('No broadcast message selected');
        }

        const raw = await axios.get<Object>(
            `/notification/broadcast/${id}/stats`,
        );
        return {
            id,
            stats: GetNotificationBroadcastStatsResponse.fromJson(
                raw.data as any,
            ),
        };
    },
);

type State = {
    broadcastMessageIds: string[];
    broadcastMessageById: Record<string, NotificationBroadcastMessage>;
    isFetching: boolean;
    fetchError: any;
    isSending: Record<string, boolean>;

    viewStatsForBroadcastMessageId: string | undefined;
    stats: Record<string, GetNotificationBroadcastStatsResponse>;
};

const initialState: State = {
    broadcastMessageIds: [],
    broadcastMessageById: {},
    isFetching: false,
    fetchError: undefined,
    isSending: {},

    viewStatsForBroadcastMessageId: undefined,
    stats: {},
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        enterViewStatsMode(state, action: PayloadAction<string>) {
            state.viewStatsForBroadcastMessageId = action.payload;
        },
        exitViewStatsMode(state) {
            state.viewStatsForBroadcastMessageId = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchRecentBroadcasts.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(fetchRecentBroadcasts.fulfilled, (state, action) => {
            state.isFetching = false;

            const asSet = new Set(state.broadcastMessageIds);

            action.payload.forEach((bm) => {
                asSet.add(bm.id);
                state.broadcastMessageById[bm.id] = bm;
            });
            state.broadcastMessageIds = Array.from(asSet).sort();
        });
        builder.addCase(fetchRecentBroadcasts.rejected, (state, action) => {
            state.isFetching = false;
            state.fetchError = action.error;
        });
        builder.addCase(
            writeNotificationBroadcastMessage.fulfilled,
            (state, action) => {
                // perform optimistic update
                state.broadcastMessageById[action.payload.id] = action.payload;
                if (!state.broadcastMessageIds.includes(action.payload.id)) {
                    state.broadcastMessageIds.push(action.payload.id);
                    state.broadcastMessageIds.sort();
                }
            },
        );
        builder.addCase(sendNotification.pending, (state, action) => {
            state.isSending[action.meta.arg] = true;
        });
        builder.addCase(sendNotification.fulfilled, (state, action) => {
            state.isSending[action.meta.arg] = false;
        });
        builder.addCase(sendNotification.rejected, (state, action) => {
            state.isSending[action.meta.arg] = false;
        });
        builder.addCase(fetchStats.fulfilled, (state, action) => {
            state.stats[action.payload.id] = action.payload.stats;
        });
    },
});

export const notificationReducer = notificationSlice.reducer;
export const notificationActions = {
    ...notificationSlice.actions,
    fetchRecentBroadcasts,
    writeNotificationBroadcastMessage,
    sendNotification,
    fetchStats,
};

export const notificationSelectors = {
    isFetching: (state: AppState) => state.notification.isFetching,
    getNotificationBroadcast: (state: AppState, { id }: { id: string }) =>
        state.notification.broadcastMessageById[id],
    getRecentBroadcastMessages: (state: AppState) => {
        const all = Object.values(state.notification.broadcastMessageById);

        all.sort((a, b) => b.id.localeCompare(a.id));
        return all;
    },
    canBeSent: (state: AppState, { id }: { id: string }) =>
        state.notification.broadcastMessageById[id]?.state ===
        NotificationMessageBroadcastState.DRAFT,
    isSending: (state: AppState, { id }: { id: string }) =>
        state.notification.isSending[id] ?? false,
    getViewStatsID: (state: AppState) =>
        state.notification.viewStatsForBroadcastMessageId,
    getStatsFor: (state: AppState, { id }: { id: string }) =>
        state.notification.stats[id],
} as const;
