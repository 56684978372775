import { accountQuery, trialBalanceQuery } from './store';
import { useRecoilValue } from 'recoil';
import {
    Label,
    Table,
    TableCell,
    TableColumn,
    TableRow,
} from '@ui5/webcomponents-react';
import React from 'react';
import { useFormatMoney } from '../common/formatting';
import * as Ledger from '../api/ledger/rpc/ledger';

export default function LedgerTrialBalance() {
    const trialBalance = useRecoilValue(trialBalanceQuery);

    const sorted = Object.entries(trialBalance.balances).sort((a, b) => {
        const numA =
            trialBalance.accounts.find((acc) => acc.id === a[0])?.number ?? -1;
        const numB =
            trialBalance.accounts.find((acc) => acc.id === b[0])?.number ?? -1;
        return numA - numB;
    });

    return (
        <Table
            columns={
                <>
                    <TableColumn>
                        <Label>#</Label>
                    </TableColumn>
                    <TableColumn>
                        <Label>Name</Label>
                    </TableColumn>
                    <TableColumn>
                        <Label>Debit</Label>
                    </TableColumn>
                    <TableColumn>
                        <Label>Credit</Label>
                    </TableColumn>
                    <TableColumn>
                        <Label>Amount</Label>
                    </TableColumn>
                    <TableColumn>
                        <Label>Debit (incl. pending)</Label>
                    </TableColumn>
                    <TableColumn>
                        <Label>Credit (incl. pending)</Label>
                    </TableColumn>
                    <TableColumn>
                        <Label>Amount (incl. pending)</Label>
                    </TableColumn>
                </>
            }
        >
            {sorted.map(([accountID, balance]) => (
                <AccountBalanceTableRow
                    key={accountID}
                    accountID={accountID}
                    balance={balance}
                />
            ))}
        </Table>
    );
}

function AccountBalanceTableRow(props: {
    slot?: string;
    accountID: string;
    balance: Ledger.GetTrialBalanceResponse_Balances;
}) {
    const account = useRecoilValue(accountQuery(props.accountID));
    const fmtAmountBase = useFormatMoney();
    const fmt = (amount: number) =>
        fmtAmountBase({
            currency: account?.currency ?? 'XXX',
            amountUnscaled: amount,
            amountScale: account?.currencyExponent ?? 2,
        });
    if (!account) {
        return (
            <TableRow slot={props.slot}>
                <TableCell>Invalid Account</TableCell>
            </TableRow>
        );
    }

    return (
        <TableRow slot={props.slot}>
            <TableCell>
                <Label>{account.number}</Label>
            </TableCell>
            <TableCell>
                <Label>{account.name}</Label>
            </TableCell>
            <TableCell>
                <Label>{fmt(props.balance.posted?.debit ?? 0)}</Label>
            </TableCell>
            <TableCell>
                <Label>{fmt(props.balance.posted?.credit ?? 0)}</Label>
            </TableCell>
            <TableCell>
                <Label>{fmt(props.balance.posted?.amount ?? 0)}</Label>
            </TableCell>
            <TableCell>
                <Label>{fmt(props.balance.pending?.debit ?? 0)}</Label>
            </TableCell>
            <TableCell>
                <Label>{fmt(props.balance.pending?.credit ?? 0)}</Label>
            </TableCell>
            <TableCell>
                <Label>{fmt(props.balance.pending?.amount ?? 0)}</Label>
            </TableCell>
        </TableRow>
    );
}
