import { Button } from '../common/Button';
import React, { Suspense, useState } from 'react';
import { Table, TableHeader, TableRow, TextTableCell } from '../common/Table';
import { useRecoilCallback, useRecoilValue } from 'recoil';
import PartnerName from '../partner/PartnerName';
import LoadingSpinner from '../common/LoadingSpinner';
import { Timestamp } from '../api/google/protobuf/timestamp';
import Scrollable from '../common/Scrollable';
import { Route, Switch, useHistory } from 'react-router-dom';
import { currentRatesQuery, rateScheduleQuery } from './store';
import { Form, Formik } from 'formik';
import { SetCashbackRateRequest } from '../api/model/backoffice';
import { ulid } from 'ulid';
import { FormNumberField, FormTimestampField } from '../common/FormFields';
import FormPartnerAssociationField from '../common/FormPartnerAssociationField';
import axios from 'axios';
import CashbackRateSchedule from './CashbackRateSchedule';

export default function CashbackRateModule() {
    return (
        <Switch>
            <Route path="/cashback/rates" exact>
                <Scrollable className="flex flex-col">
                    <div className="flex mb-4">
                        <h1 className="font-bold text-3xl capitalize">
                            Cashback Rates
                        </h1>
                        <div className="flex-grow flex flex-row items-center justify-end space-x-2">
                            <div>
                                <Button
                                    to={`/cashback/rates/create`}
                                    primary
                                    label="Create"
                                />
                            </div>
                        </div>
                    </div>
                    <Table
                        header={
                            <TableHeader
                                labels={['Partner', 'Rate', 'Since', 'Actions']}
                            />
                        }
                    >
                        <Suspense
                            fallback={
                                <tr>
                                    <td className="col-span-3 inline-flex justify-center p-4">
                                        <LoadingSpinner />
                                    </td>
                                </tr>
                            }
                        >
                            <TableBody />
                        </Suspense>
                    </Table>
                </Scrollable>
            </Route>
            <Route path="/cashback/rates/:partnerId/schedule">
                <CashbackRateSchedule />
            </Route>
            <Route path="/cashback/rates/create">
                <CreateRateForm />
            </Route>
        </Switch>
    );
}

function TableBody() {
    const rates = useRecoilValue(currentRatesQuery);
    return (
        <>
            {rates.map((r) => (
                <TableRow
                    key={r.id}
                    contents={[
                        <TextTableCell
                            primary={<PartnerName id={r.partnerId} />}
                        />,
                        <TextTableCell
                            primary={formatPercentage(
                                addValues(
                                    r.rate,
                                    r.rateScale,
                                    r.sponsoredRate,
                                    r.sponsoredRateScale,
                                ),
                            )}
                            secondary={
                                <span>
                                    <span title="PAID">
                                        {formatPercentage({
                                            valueUnscaled: r.rate,
                                            valueScale: r.rateScale,
                                        })}
                                    </span>
                                    <span
                                        className="text-gray-400"
                                        title="SPONSORED"
                                    >
                                        &nbsp;+&nbsp;
                                        {formatPercentage({
                                            valueUnscaled: r.sponsoredRate,
                                            valueScale: r.sponsoredRateScale,
                                        })}
                                    </span>
                                </span>
                            }
                        />,
                        <TextTableCell
                            primary={Timestamp.toDate(
                                r.validFrom!,
                            ).toLocaleString()}
                        />,
                        <div className="inline-flex">
                            <Button
                                label="View Schedule"
                                secondary
                                to={`/cashback/rates/${r.partnerId}/schedule`}
                            />
                        </div>,
                    ]}
                />
            ))}
        </>
    );
}

function CreateRateForm() {
    const history = useHistory();
    const [defaultEmpty] = useState({
        id: ulid(),
        partnerId:
            new URLSearchParams(window.location.search).get('partnerId') || '',
        rate: 0,
        sponsored: 0,
        fee: 0,
        validFrom: Timestamp.now(),
    });

    const [working, setWorking] = useState(false);

    const onSubmit = useRecoilCallback(
        ({ refresh }) =>
            async (values: typeof defaultEmpty) => {
                setWorking(true);
                let rate = values.rate * 10;
                let rateScale = 3;
                if (rate % 10 === 0) {
                    rate /= 10;
                    rateScale--;
                }

                let sponsoredRate = values.sponsored * 10;
                let sponsoredRateScale = 3;
                if (sponsoredRate % 10 === 0) {
                    sponsoredRate /= 10;
                    sponsoredRateScale--;
                }

                let fee = values.fee * 10;
                let feeScale = 3;
                if (fee % 10 === 0) {
                    fee /= 10;
                    feeScale--;
                }

                if (Timestamp.toDate(values.validFrom) < new Date()) {
                    values.validFrom = Timestamp.now();
                }

                try {
                    const req = SetCashbackRateRequest.create({
                        id: values.id,
                        partnerId: values.partnerId,
                        rate,
                        rateScale,
                        fee,
                        feeScale,
                        validFrom: values.validFrom,
                        sponsoredRate,
                        sponsoredRateScale,
                    });

                    await axios.post(
                        '/cashback/rate',
                        SetCashbackRateRequest.toJson(req),
                    );
                    refresh(currentRatesQuery);
                    refresh(rateScheduleQuery(values.partnerId));
                    window.history.back();
                } finally {
                    setWorking(false);
                }
            },
        [setWorking],
    );

    return (
        <div className="relative">
            {working && (
                <div className="absolute inset-0 bg-black opacity-25 pt-4 flex justify-center">
                    <LoadingSpinner className="h-12 w-12 text-white" />
                </div>
            )}
            <Formik
                onSubmit={onSubmit}
                initialValues={defaultEmpty}
                validate={(v) => {
                    if (!v.partnerId) {
                        return { partnerId: 'required' };
                    }
                    if (v.rate > 100) {
                        return { rate: 'really?' };
                    }

                    if (
                        Math.round(v.rate * 10) / 10 !==
                        Math.round(v.rate * 100) / 100
                    ) {
                        return { rate: 'too many decimal places' };
                    }
                }}
            >
                <Form className="container m-0 px-4 pt-8 space-y-2 max-w-lg">
                    <div className="flex items-center space-x-2">
                        <div>
                            <Button
                                secondary
                                label={
                                    <svg
                                        className="w-4 h-4"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                                    </svg>
                                }
                                onClick={(e) => {
                                    e.preventDefault();
                                    history.goBack();
                                }}
                            />
                        </div>
                        <h1 className="font-bold text-3xl">
                            Set Cashback Rate
                        </h1>
                        <div className="flex-shrink">
                            <Button type="submit" label="Save" primary />
                        </div>
                    </div>
                    <div className="flex flex-col space-y-4 px-4 pt-8">
                        <FormPartnerAssociationField
                            single
                            name="partnerId"
                            label="Partner"
                        />
                        <FormNumberField
                            name="rate"
                            label="Rate %"
                            step="0.1"
                            min={0}
                        />
                        <FormNumberField
                            name="sponsored"
                            label="Sponsored %"
                            step="0.1"
                            min={0}
                        />
                        <FormNumberField
                            name="fee"
                            label="Fee %"
                            step="0.1"
                            min={0}
                        />
                        <FormTimestampField
                            name="validFrom"
                            label="Valid From"
                        />
                    </div>
                </Form>
            </Formik>
        </div>
    );
}

export function addValues(
    a: number,
    aScale: number,
    b: number,
    bScale: number,
): { valueUnscaled: number; valueScale: number } {
    if (a === 0 && b === 0) {
        return { valueUnscaled: 0, valueScale: 0 };
    }
    while (aScale < bScale) {
        a *= 10;
        aScale++;
    }
    while (bScale < aScale) {
        b *= 10;
        bScale++;
    }
    return {
        valueUnscaled: a + b,
        valueScale: bScale,
    };
}

export function formatPercentage(arg: {
    valueUnscaled: number;
    valueScale: number;
}): string {
    return (
        (arg.valueUnscaled / Math.pow(10, arg.valueScale - 2)).toFixed(1) + '%'
    );
}
