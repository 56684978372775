import Scrollable from './common/Scrollable';
import cx from 'classnames';

export default function Overview() {
    return (
        <Scrollable>
            <h1 className="font-bold text-xl">BACKOFFICE</h1>
            <div className="mt-4">External Links</div>
            <div className="flex flex-row flex-wrap py-4 -m-2">
                <Card
                    className="m-2"
                    title="Collaboration"
                    links={[
                        {
                            label: 'Drive',
                            url: 'https://drive.google.com/drive/folders/0AEejzXO4LPFmUk9PVA',
                        },
                        { label: 'Slack', url: 'https://plan3t.slack.com' },
                        { label: 'Asana', url: 'https://app.asana.com' },
                        { label: 'Linear', url: 'https://app.linear.com' },
                        {
                            label: 'Pipedrive',
                            url: 'https://plan3tgmbh.pipedrive.com',
                        },
                    ]}
                />
                <Card
                    className="m-2"
                    title="Content"
                    links={[
                        { label: 'Sanity', url: 'https://sanity.plan3t.one' },
                    ]}
                />
                <Card
                    className="m-2"
                    title="App Operation"
                    links={[
                        {
                            label: 'KPI Dashboard',
                            url: 'https://datastudio.google.com/reporting/ff7996ad-7206-4c70-a76d-0fdf8fb59c71/',
                        },
                        {
                            label: 'AppStore Reviews',
                            url: 'https://appstoreconnect.apple.com/apps/1525284698/appstore/activity/ios/ratingsResponses',
                        },
                        {
                            label: 'PlayStore Reviews',
                            url: 'https://play.google.com/console/u/0/developers/7326524077206544443/app/4973778634882445188/user-feedback/reviews',
                        },
                        {
                            label: 'sentry',
                            url: 'https://sentry.io/organizations/plan3t/issues/?project=5504268',
                        },
                        {
                            label: 'Cloud Logging',
                            url: 'https://console.cloud.google.com/logs/query;query=resource.type%20%3D%20%22cloud_run_revision%22%0Aresource.labels.service_name%20%3D%20%22core%22%0Aresource.labels.location%20%3D%20%22europe-west4%22%0A%20severity%3E%3DDEFAULT?project=plan3t',
                        },
                    ]}
                />
                <Card
                    className="m-2"
                    title="Security"
                    links={[
                        { label: 'Vault', url: 'https://vault.plan3t.one' },
                        {
                            label: 'GSuite Admin',
                            url: 'https://admin.google.com/',
                        },
                    ]}
                />
                <Card
                    className="m-2"
                    title="Finance"
                    links={[
                        { label: 'DATEV', url: 'https://duo.datev.de/' },
                        {
                            label: 'stripe',
                            url: 'https://dashboard.stripe.com/',
                        },
                    ]}
                />
            </div>
        </Scrollable>
    );
}

function Card(props: {
    title: string;
    links: Array<{ label: string; url: string }>;
    className?: string;
}) {
    return (
        <div
            className={cx(
                'border border-gray-200 rounded p-4 flex flex-col space-y-2 w-48',
                props.className,
            )}
        >
            <h2 className="font-bold">{props.title}</h2>
            <hr />
            <ul>
                {props.links.map((l) => (
                    <li key={l.url}>
                        <a
                            href={l.url}
                            target="_blank"
                            rel="noreferrer"
                            className="text-semantic-primary hover:underline"
                        >
                            {l.label}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}
