import axios from 'axios';
import {
    ActionCreatorWithoutPayload,
    AsyncThunk,
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';
import { Slice } from '@reduxjs/toolkit/src/createSlice';
import { snackbarActions } from '../snackbar/store';

type FetchState = { isFetching: boolean; error: unknown };

type CrudModuleState<T> = {
    ids: string[];
    byId: Record<string, T>;

    fetchState: FetchState;

    pagination: {
        pageSize: number;
        currentPage: number;
        loadedPages: number;
        hasMore: boolean;
        nextPageToken: string;
    };
};

export function getInitialState<T>(): CrudModuleState<T> {
    return {
        ids: [],
        byId: {},
        fetchState: { isFetching: false, error: undefined },

        pagination: {
            pageSize: 25,
            currentPage: 0,
            hasMore: true,
            loadedPages: 0,
            nextPageToken: '',
        },
    };
}

type FetchPageResponse<T> = {
    items: T[];
    nextPageToken: string;
};

type FetchSingleResponse<T> = {
    item: T | undefined;
};

type Args<T> = {
    name: string;
    baseUrl: string;
    localClientBasePath: string;
    stateProvider: (s: unknown) => CrudModuleState<T>;

    listResponseParser: (data: any) => FetchPageResponse<T>;
    singleResponseParser: (data: any) => FetchSingleResponse<T>;
    writeArgumentTransformer: (item: T) => any;

    idProvider: (item: T) => string;

    prepareEmpty: () => T;

    listUrlConstructor?: (s: CrudModuleState<T>) => string;
};

export type CrudSlice<T> = {
    reducer: Slice<CrudModuleState<T>>['reducer'];
    actions: {
        navigateToNextPage: ActionCreatorWithoutPayload;
        navigateToPreviousPage: ActionCreatorWithoutPayload;
        fetchList: AsyncThunk<FetchPageResponse<T>, void, {}>;
        fetchSingleItem: AsyncThunk<FetchSingleResponse<T>, string, {}>;
        writeItem: AsyncThunk<T, T, {}>;
        deleteItem: AsyncThunk<void, T, {}>;
    };
    selectors: {
        isFetching: (state: unknown) => boolean;
        getCurrentPageNumber: (state: unknown) => number;
        getCurrentPage: (state: unknown) => T[];
        getPageSize: (state: unknown) => number;
        hasMore: (state: unknown) => boolean;
        getError: (state: unknown) => any;

        getItem: (state: unknown, { id }: { id: string }) => T;
    };
    _args: Args<T>;
};

export function createCrudSlice<T>(args: Args<T>): CrudSlice<T> {
    const fetchPage = async (
        s: CrudModuleState<T>,
    ): Promise<FetchPageResponse<T>> => {
        const url = !!args.listUrlConstructor
            ? args.listUrlConstructor(s)
            : `${args.baseUrl}?page_size=${s.pagination.pageSize}&page_token=${s.pagination.nextPageToken}`;
        const raw = await axios.get<Object>(url);
        return args.listResponseParser(raw.data as any);
    };

    const fetchList = createAsyncThunk(
        `${args.name}/fetch`,
        async (_: void, { getState }) => {
            const s = args.stateProvider(getState());
            if (!s.pagination.hasMore) {
                return {
                    items: [],
                    nextPageToken: '--invalid',
                };
            }
            return fetchPage(s);
        },
    );

    const fetchSingleItem = createAsyncThunk(
        `${args.name}/fetch-single`,
        async (id: string): Promise<FetchSingleResponse<T>> => {
            const raw = await axios.get<Object>(`${args.baseUrl}/${id}`);
            const res = args.singleResponseParser(raw.data as any);
            if (!res.item) {
                return Promise.reject('not found');
            }
            return res;
        },
    );

    const writeItem = createAsyncThunk(
        `${args.name}/write`,
        async (item: T, { dispatch }) => {
            try {
                const req = args.writeArgumentTransformer(item);
                await axios.post<any>(`${args.baseUrl}`, req);
                return item;
            } catch (e) {
                dispatch(
                    snackbarActions.sendMessage({
                        title: 'Saving failed',
                        type: 'warning',
                    }),
                );
                throw e;
            }
        },
    );

    const deleteItem = createAsyncThunk(
        `${args.name}/delete`,
        async (item: T, { dispatch }) => {
            try {
                const id = args.idProvider(item);
                await axios.delete(`${args.baseUrl}/${id}`);
            } catch (e) {
                dispatch(
                    snackbarActions.sendMessage({
                        title: 'Deleting failed',
                        type: 'warning',
                    }),
                );
                throw e;
            }
        },
    );

    const slice = createSlice({
        name: args.name,
        initialState: getInitialState<T>(),
        reducers: {
            navigateToNextPage(state) {
                state.pagination.currentPage++;
            },
            navigateToPreviousPage(state) {
                if (state.pagination.currentPage === 0) {
                    return;
                }
                state.pagination.currentPage--;
            },
        },
        extraReducers: (builder) => {
            builder.addCase(fetchList.pending, (state) => {
                state.fetchState.isFetching = true;
            });
            builder.addCase(fetchList.fulfilled, (state, action) => {
                state.fetchState.isFetching = false;
                state.fetchState.error = undefined;

                const asSet = new Set(state.ids);

                action.payload.items.forEach((item) => {
                    const id = args.idProvider(item);
                    asSet.add(id);
                    // @ts-ignore
                    state.byId[id] = item;
                });
                state.ids = Array.from(asSet);

                state.pagination.hasMore =
                    action.payload.items.length === state.pagination.pageSize;
                state.pagination.loadedPages = Math.ceil(
                    state.ids.length / state.pagination.pageSize,
                );
                state.pagination.nextPageToken = action.payload.nextPageToken;
            });
            builder.addCase(fetchList.rejected, (state, action) => {
                state.fetchState.isFetching = false;
                state.fetchState.error = action.error;
            });

            builder.addCase(fetchSingleItem.pending, (state, action) => {
                state.fetchState.isFetching = true;
            });
            builder.addCase(fetchSingleItem.rejected, (state, action) => {
                state.fetchState.isFetching = false;
                state.fetchState.error = action.error;
            });
            builder.addCase(fetchSingleItem.fulfilled, (state, action) => {
                state.fetchState.isFetching = false;

                const id = args.idProvider(action.payload.item!);
                // @ts-ignore
                state.byId[id] = action.payload.item;
                if (!state.ids.includes(id)) {
                    state.ids.push(id);
                    state.ids.sort();
                }
            });

            builder.addCase(writeItem.fulfilled, (state, action) => {
                const id = args.idProvider(action.payload);
                // perform optimistic update
                // @ts-ignore
                state.byId[id] = action.payload;
                if (!state.ids.includes(id)) {
                    state.ids.unshift(id);
                }
            });
            builder.addCase(deleteItem.fulfilled, (state, action) => {
                const id = args.idProvider(action.meta.arg);
                delete state.byId[id];
                state.ids = state.ids.filter((i) => i !== id);
            });
        },
    });

    return {
        ...slice,
        actions: {
            ...slice.actions,
            fetchList,
            fetchSingleItem,
            writeItem,
            deleteItem,
        },
        selectors: {
            isFetching: (state: unknown) =>
                args.stateProvider(state).fetchState.isFetching,
            getCurrentPageNumber: (state: unknown) =>
                args.stateProvider(state).pagination.currentPage,
            getCurrentPage: (state: unknown): T[] => {
                const { pagination, ids, byId } = args.stateProvider(state);

                const start = pagination.currentPage * pagination.pageSize;
                const end = start + pagination.pageSize;

                const idsForPage = ids.slice(start, end);

                return idsForPage.map((id) => byId[id]);
            },
            getPageSize: (state: unknown) =>
                args.stateProvider(state).pagination.pageSize,
            hasMore: (state: unknown): boolean => {
                const { pagination, fetchState } = args.stateProvider(state);
                return (
                    fetchState.error === undefined &&
                    (pagination.hasMore ||
                        pagination.currentPage + 1 < pagination.loadedPages)
                );
            },
            getError: (state: unknown): unknown => {
                const { fetchState } = args.stateProvider(state);
                return fetchState.error;
            },

            getItem: (state: unknown, { id }: { id: string }) =>
                args.stateProvider(state).byId[id],
        },
        _args: args,
    };
}
