import { useCallback, useRef } from 'react';
import * as React from 'react';
import { SnackbarMessage } from './types';
import { IconSuccess, IconWarning } from '../icons';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { snackbarActions, snackbarSelectors } from './store';
import { useTimeout } from '../hooks';
import { Transition } from '../common/Transition';

const SnackbarManager: React.FunctionComponent = () => {
    const snackbarItems = useAppSelector(snackbarSelectors.getMessages);

    return (
        <div className="z-50 fixed inset-0 flex flex-col items-end justify-start px-4 py-4 sm:p-6 pointer-events-none space-y-4">
            {snackbarItems.map((item) => (
                <SnackbarEntry
                    key={item.uuid}
                    msg={item}
                    visible={item.visible}
                />
            ))}
        </div>
    );
};
export default SnackbarManager;

const SnackbarEntry: React.FunctionComponent<{
    msg: SnackbarMessage;
    visible: boolean;
}> = ({ msg, visible }) => {
    const ref = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch();

    const { uuid } = msg;
    const pop = useCallback(() => {
        visible && dispatch(snackbarActions.dismissMessage(uuid));
    }, [dispatch, uuid, visible]);

    const clear = useCallback(() => {
        !visible && dispatch(snackbarActions.clearMessage(uuid));
    }, [dispatch, uuid, visible]);

    useTimeout(pop, msg.duration);
    useTimeout(clear, msg.duration + 1000);

    return (
        <Transition
            nodeRef={ref}
            appear
            show={visible}
            enter="transition-default transform"
            enterFrom="translate-x-4 opacity-0"
            enterTo="translate-x-0 opacity-100"
            leave="transition-default"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div
                ref={ref}
                className="max-w-sm w-full bg-white shadow-lg rounded-lg flex flex-row items-center pointer-events-auto p-4 space-x-2"
            >
                {msg.type === 'success' ? (
                    <IconSuccess className="w-6 h-6 text-green-500" />
                ) : (
                    <IconWarning className="w-6 h-6 text-red-500" />
                )}
                <div className="space-y-1 flex flex-col">
                    <span className="text-sm leading-5 font-medium text-gray-900">
                        {msg.title}
                    </span>
                    {msg.subtitle && (
                        <span className="text-sm leading-5 text-gray-500">
                            {msg.subtitle}
                        </span>
                    )}
                </div>
            </div>
        </Transition>
    );
};
