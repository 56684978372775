import { useAppDispatch, useAppSelector } from '../redux/react';
import ItemCategoryEditor from '../common/ItemCategoryEditor';
import { FunctionComponent, useCallback, useEffect } from 'react';
import {
    GetTipDraftsResponse,
    GetTipsCategoryAuthoringItemsResponse,
    ItemCategoryAuthoring,
    WriteTipsCategoryAuthoringRequest,
} from '../api/model/backoffice';
import axios from 'axios';
import { tipActions, tipSelectors } from './store';

const TipCategoriesEditor: FunctionComponent = () => {
    // preload the tips
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(tipActions.fetchList());
    }, [dispatch]);

    const fetchCategories = useCallback(async (): Promise<
        ItemCategoryAuthoring[]
    > => {
        const raw = await axios.get<Object>(`/tipCategory`);
        const res = GetTipsCategoryAuthoringItemsResponse.fromJson(
            raw.data as any,
        );
        return res.categories;
    }, []);

    const fetchRewardIds = useCallback(async () => {
        const raw = await axios.get<Object>(`/tip?page_size=250`);
        const res = GetTipDraftsResponse.fromJson(raw.data as any);
        return res.tips.map((t) => t.id);
    }, []);

    const write = useCallback(async (item: ItemCategoryAuthoring) => {
        const req = WriteTipsCategoryAuthoringRequest.toJson({ item }) as any;
        req.item.color = item.color.toString();
        await axios.post<Object>(`/tipCategory`, req);
    }, []);

    return (
        <ItemCategoryEditor
            title="Tip Categories"
            deepLinkBuilder={(id) => `/tipCategory?itemCategory=${id}`}
            fetchCategories={fetchCategories}
            fetchAllItemIds={fetchRewardIds}
            write={write}
            renderItem={(itemId, index, categoryId) => (
                <TipCard
                    key={itemId}
                    id={itemId}
                    index={index}
                    categoryId={categoryId}
                />
            )}
        />
    );
};

export default TipCategoriesEditor;

const TipCard: FunctionComponent<{
    id: string;
    index: number;
    categoryId: string;
}> = ({ id, index, categoryId }) => {
    const item = useAppSelector((state) => tipSelectors.getItem(state, { id }));

    const dispatch = useAppDispatch();
    const fetch = useCallback(
        () => dispatch(tipActions.fetchSingleItem(id)),
        [dispatch, id],
    );

    if (!item) {
        fetch();
        return <span>Loading...</span>;
    }

    return (
        <div className="bg-white rounded border-gray-100 border-2 space-y-2 w-48 h-56 hover:shadow-lg overflow-hidden">
            {!!item.image && (
                <img
                    src={item.image?.url}
                    alt="cover"
                    className="h-32 w-full object-cover rounded-t"
                />
            )}
            <div className="px-2 pb-4">
                <span className="text-lg font-bold text-gray-700">
                    {item?.title}
                </span>
            </div>
        </div>
    );
};
