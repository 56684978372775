import {
    atom,
    atomFamily,
    selector,
    selectorFamily,
    useRecoilCallback,
    useRecoilState,
    useRecoilValue,
} from 'recoil';
import axios, { AxiosResponse } from 'axios';
import * as Billing from '../api/billing/rpc/billing';
import * as Common from '../api/model/common';
import { PartnerBase } from '../api/model/core';
import { Timestamp } from '../api/google/protobuf/timestamp';
import { useCallback, useState } from 'react';
import {
    makeRecoilValueShallowRefreshable,
    useShallowRecoilRefresher,
} from '../common/recoil';
import { useAppDispatch } from '../redux/react';
import { snackbarActions } from '../snackbar/store';
import { partnerBaseListQuery } from '../partner/store';

export const plan3tBillingPartner: PartnerBase = {
    id: '::plan3t',
    legalName: 'Plan3t GmbH',
    brandName: 'PLAN3T',
    pipedriveId: '',
    datevId: '',
    affiliateOnly: false,
    createdBy: 'system',
    createdAt: Timestamp.fromDate(new Date('2020-05-20')),
    modifiedBy: 'system',
    modifiedAt: Timestamp.fromDate(new Date('2020-05-20')),
};

export const PostingType = {
    Cashback: 'C',
    CashbackFee: 'CF',
    RewardPaid: 'R',
    RewardPaidFee: 'RF',
    RewardAffiliate: 'AF',
};

export function postingTypeIndex(pt: string): number {
    return Object.values(PostingType).indexOf(pt);
}

export const PeriodStateCode = {
    Initial: 'I',
    HalfOpen: 'H',
    Open: 'O',
    Review: 'R',
    Closed: 'C',
};

const aggregationRulesQuery = selector({
    key: 'billing/aggregationRulesQuery',
    get: async () => {
        const res = await axios.post(
            '/one.plan3t.core.billing.rpc.Billing/GetAggregationRules',
            {},
        );
        return Billing.GetAggregationRulesResponse.fromJson(res.data)
            .byPostingType;
    },
});

export const periodsQuery = selector<Array<Billing.BillingPeriod>>({
    key: 'BillingPeriods',
    get: async () => {
        const res = await axios.post(
            '/one.plan3t.core.billing.rpc.Billing/GetBillingPeriods',
            {},
        );
        const sorted = [
            ...Billing.GetBillingPeriodsResponse.fromJson(res.data).periods,
        ];

        const toDT = (d: Common.Date | undefined): Date => {
            if (!d) {
                return new Date(0);
            }
            return new Date(d.year, d.month - 1, d.day);
        };
        sorted.sort(
            (a, b) => toDT(a.endsAt).getTime() - toDT(b.endsAt).getTime(),
        );
        return sorted;
    },
});

export const periodAndPartnerAtom = atom<[number, string] | null>({
    key: 'billing/periodAndPartnerAtom',
    default: null,
});

export const periodForCode = selectorFamily({
    key: 'BillingPeriodForCodeFamily',
    get:
        (periodCode: string) =>
        ({ get }) => {
            const all = get(periodsQuery);
            return all.find(({ code }) => code === periodCode)!;
        },
});

export const periodStateForCodeAndPartner = selectorFamily({
    key: 'BillingPeriodStateForCodeAndPartner',
    get:
        ([periodCode, partnerId]: [string, string]) =>
        async ({ get }): Promise<string> => {
            makeRecoilValueShallowRefreshable({
                tag: `periodStateForCodeAndPartner${periodCode}-${partnerId}`,
                get,
            });

            const period = get(periodForCode(periodCode));

            const req = Billing.GetBillingPeriodStateRequest.toJson({
                period: period.id,
                partnerId,
            });

            const res = await axios.post(
                '/one.plan3t.core.billing.rpc.Billing/GetBillingPeriodState',
                req,
            );

            return Billing.GetBillingPeriodStateResponse.fromJson(res.data)
                .stateCode;
        },
});

export const partnerBaseWithPeriodStateQuery = selectorFamily({
    key: 'billing/partnerBaseWithPeriodStateQuery',
    get:
        (periodCode: string) =>
        ({ get }) => {
            const partners = [
                plan3tBillingPartner,
                ...get(partnerBaseListQuery),
            ];

            return partners.map((partner) => ({
                partner,
                state: get(
                    periodStateForCodeAndPartner([periodCode, partner.id]),
                ),
            }));
        },
});

export const postingsQuery = selector<Array<Billing.Posting>>({
    key: 'PostingsFamily',
    get: async ({ get }) => {
        const v = get(periodAndPartnerAtom);
        if (!v) {
            return [];
        }
        const [period, partnerId] = v;
        const req = Billing.GetPostingsRequest.toJson({
            period,
            partnerId,
        });

        makeRecoilValueShallowRefreshable({
            tag: `PostingsFamily${period}-${partnerId}`,
            get,
        });
        const res = await axios.post(
            '/one.plan3t.core.billing.rpc.Billing/GetPostings',
            req,
        );

        const items = [...Billing.GetPostingsResponse.fromJson(res.data).items];
        items.sort((a, b) => a.id - b.id);
        return items;
    },
});

export const periodTotalsQuery = selectorFamily({
    key: 'periodTotalsQuery',
    get:
        (periodCode: string) =>
        async ({ get }) => {
            const period = get(periodForCode(periodCode));

            const req = Billing.GetPeriodTotalsRequest.toJson({
                period: period.id,
            });

            const res = await axios.post(
                '/one.plan3t.core.billing.rpc.Billing/GetPeriodTotals',
                req,
            );

            return Billing.GetPeriodTotalsResponse.fromJson(res.data);
        },
});

type PostingGroup = {
    type: string;
    amountUnscaled: number;
    amountScale: number;
    currency: string;
    numPostings: number;
};

export const postingGroupsQuery = selector<Array<PostingGroup>>({
    key: 'postingGroupsQuery',
    get: async ({ get }) => {
        const postings = get(postingsQuery);
        if (postings.length === 0) {
            return [];
        }
        let currency: string | null = null;
        const grouped: Record<string, Array<Billing.Posting>> = {};
        for (const p of postings) {
            if (currency === null) {
                currency = p.currency;
            }
            if (currency !== p.currency) {
                throw new Error('Mixed currency billing not supported yet.');
            }
            if (!grouped[p.type]) {
                grouped[p.type] = [];
            }
            grouped[p.type].push(p);
        }
        if (currency === null) {
            throw new Error('No valid currency found.');
        }

        const rules = get(aggregationRulesQuery);
        const groups: Array<PostingGroup> = [];

        for (const [typ, pstings] of Object.entries(grouped)) {
            const rule = rules[typ];
            if (!rule) {
                throw new Error(`No aggregation rule for '${typ}' found.`);
            }

            groups.push({
                type: typ,
                numPostings: pstings.length,
                currency,
                ...sum(rule, pstings),
            });
        }

        return groups;
    },
});

export const documentsQuery = selector<Array<Billing.Document>>({
    key: 'documentsQuery',
    get: async ({ get }) => {
        const v = get(periodAndPartnerAtom);
        if (!v) {
            return [];
        }
        const [period, partnerId] = v;
        const req = Billing.GetDocumentsRequest.toJson({
            period,
            partnerId,
        });

        const res = await axios.post(
            '/one.plan3t.core.billing.rpc.Billing/GetDocuments',
            req,
        );

        const items = [
            ...Billing.GetDocumentsResponse.fromJson(res.data).documents,
        ];
        items.sort((a, b) => a.id.localeCompare(b.id));
        return items;
    },
});

export const resubmissionDocumentsQuery = selectorFamily<
    Array<Billing.Document>,
    string
>({
    key: 'resubmissionDocumentsQuery',
    get:
        (periodCode: string) =>
        async ({ get }) => {
            const period = get(periodForCode(periodCode));

            const req = Billing.GetResubmissionsRequest.toJson({
                period: period.id,
            });

            const res = await axios.post(
                '/one.plan3t.core.billing.rpc.Billing/GetResubmissions',
                req,
            );

            return Billing.GetResubmissionsResponse.fromJson(res.data)
                .documents;
        },
});

export const singleDocumentQuery = selectorFamily<Billing.Document, string>({
    key: 'singleDocumentQuery',
    get:
        (documentId) =>
        ({ get }) => {
            const all = get(documentsQuery);

            const found = all.find((v) => v.id === documentId);

            if (!found) {
                throw new Error(`could not find document '${documentId}'`);
            }

            return found;
        },
});

export const invoicesQuery = selectorFamily<Array<Billing.Invoice>, string>({
    key: 'invoicesQuery',
    get:
        (periodCode: string) =>
        async ({ get }) => {
            const period = get(periodForCode(periodCode));

            const req = Billing.GetInvoicesRequest.toJson({
                period: period.id,
            });

            makeRecoilValueShallowRefreshable({
                tag: `invoicesQuery-${periodCode}`,
                get,
            });
            const res = await axios.post(
                '/one.plan3t.core.billing.rpc.Billing/GetInvoices',
                req,
            );
            return Billing.GetInvoicesResponse.fromJson(res.data).invoices;
        },
});

export const invoiceForPeriodAndPartnerQuery = selectorFamily({
    key: 'invoiceForCurrentBillingSheetQuery',
    get:
        ([periodCode, partnerID]: [string, string]) =>
        ({ get }) => {
            return get(invoicesQuery(periodCode)).find(
                (invoice) => invoice.partnerId === partnerID,
            );
        },
});

export const numberOfPostingsQuery = selectorFamily<number, string>({
    key: 'numberOfPostingsQuery',
    get:
        (docID) =>
        ({ get }) => {
            const postings = get(postingsQuery);

            return postings.filter((p) => p.documentId === docID).length;
        },
});

const revertPostingQuery = selectorFamily({
    key: 'revertPostingQuery',
    get:
        (postingID: number) =>
        ({ get }) => {
            const postings = get(postingsQuery);
            return postings.find(
                (p) =>
                    p.metadata['type'] === 'revert' &&
                    parseInt(p.metadata['posting_id'], 10) === postingID,
            );
        },
});

export const isPostingRevertedQuery = selectorFamily({
    key: 'isPostingRevertedQuery',
    get:
        (postingID: number) =>
        ({ get }) =>
            !!get(revertPostingQuery(postingID)),
});

export const postingRevertReasonQuery = selectorFamily({
    key: 'postingRevertReasonQuery',
    get:
        (postingID: number) =>
        ({ get }) =>
            get(revertPostingQuery(postingID))?.metadata['reason'],
});

export const totalForPeriodQuery = selector<PostingGroup>({
    key: 'totalForPeriodQuery',
    get: ({ get }) => {
        const groups = get(postingGroupsQuery);

        const rules = get(aggregationRulesQuery);

        const totalRule = rules[''];
        if (!totalRule) {
            throw new Error('No rule found for total aggregation');
        }

        if (groups.length === 0) {
            return {
                type: '',
                currency: 'EUR',
                numPostings: 0,
                amountScale: totalRule.precision,
                amountUnscaled: 0,
            };
        }

        return {
            type: '',
            currency: groups[0].currency,
            numPostings: groups
                .map((g) => g.numPostings)
                .reduce((a, b) => a + b),
            ...sum(rules[''], groups),
        };
    },
});

const periodStateCodePendingChangeAtom = atomFamily({
    key: 'periodStateCodePendingChangeAtom',
    default: periodStateForCodeAndPartner,
});

export const useChangePeriodStateCode = (
    periodCode: string,
    partnerId: string,
) => {
    const period = useRecoilValue(periodForCode(periodCode));
    const currentState = useRecoilValue(
        periodStateForCodeAndPartner([periodCode, partnerId]),
    );
    const refreshCurrentState = useShallowRecoilRefresher(
        `periodStateForCodeAndPartner${periodCode}-${partnerId}`,
    );
    const [pendingChange, setPendingChange] = useRecoilState(
        periodStateCodePendingChangeAtom([periodCode, partnerId]),
    );
    const fn = useCallback(
        async (stateCode: string) => {
            try {
                setPendingChange(stateCode);
                const req = Billing.SetBillingPeriodStateRequest.toJson({
                    period: period.id,
                    partnerId,
                    stateCode,
                });
                await axios.post(
                    '/one.plan3t.core.billing.rpc.Billing/SetBillingPeriodState',
                    req,
                );
                refreshCurrentState();
            } catch (e) {
                setPendingChange(currentState);
                throw e;
            }
        },
        [
            period,
            partnerId,
            setPendingChange,
            currentState,
            refreshCurrentState,
        ],
    );

    const prompt = useCallback(async () => {
        const res = window.prompt('Change state to:');

        if (!res) {
            return;
        }

        if (!['H', 'O', 'R', 'C'].includes(res)) {
            window.alert('Invalid state code');
            return;
        }

        await fn(res);
    }, [fn]);

    return {
        state: pendingChange,
        isChanging: pendingChange !== currentState,
        change: fn,
        prompt,
    };
};

export const useAddPostings = (period: number, partnerId: string) => {
    const refreshCurrentState = useShallowRecoilRefresher(
        `PostingsFamily${period}-${partnerId}`,
    );
    const fn = useCallback(
        async (
            values: Array<Billing.AddPostingsRequest_PostingCreationData>,
        ) => {
            await axios.post(
                '/one.plan3t.core.billing.rpc.Billing/AddPostings',
                Billing.AddPostingsRequest.toJson({
                    period: period,
                    partnerId: partnerId,
                    postings: values,
                }),
            );
            refreshCurrentState();
        },
        [period, partnerId, refreshCurrentState],
    );

    return {
        addPostings: fn,
    };
};

function sum(
    rule: Billing.AggregationRule,
    as: Array<{ amountUnscaled: number; amountScale: number }>,
): { amountUnscaled: number; amountScale: number } {
    function round(a: number): number {
        const s = a > 0 ? 1 : -1;
        a = Math.abs(a);
        switch (rule.roundingType) {
            case 0:
                return s * Math.round(a);
            case 1:
                return s * Math.floor(a);
            case 2:
                return s * Math.ceil(a);
            default:
                throw new Error(
                    `Unrecognized rounding type '${rule.roundingType}'.`,
                );
        }
    }

    const s = { amountUnscaled: 0, amountScale: rule.precision };

    for (let { amountUnscaled, amountScale } of as) {
        const d = rule.precision - amountScale;
        amountUnscaled *= Math.pow(10, d);

        if (rule.roundFirst) {
            amountUnscaled = round(amountUnscaled);
        }
        s.amountUnscaled += amountUnscaled;
    }

    if (!rule.roundFirst) {
        s.amountUnscaled = round(s.amountUnscaled);
    }

    return s;
}

export function useCreateInvoiceFn({
    partnerId,
    periodCode,
}: {
    periodCode: string;
    partnerId: string;
}) {
    const dispatch = useAppDispatch();
    const refresh = useShallowRecoilRefresher('invoicesQuery-' + periodCode);
    const [working, setWorking] = useState(false);

    const createInvoice = useRecoilCallback(
        ({ snapshot }) =>
            async () => {
                setWorking(true);
                try {
                    const period = await snapshot.getPromise(
                        periodForCode(periodCode),
                    );

                    await axios.post(
                        '/one.plan3t.core.billing.rpc.Billing/CreateInvoice',
                        Billing.CreateInvoiceRequest.toJson({
                            period: period.id,
                            partnerId,
                        }),
                    );
                    refresh();
                } catch (e: any) {
                    if (
                        e.isAxiosError &&
                        e.response.status >= 400 &&
                        e.response.status < 500
                    ) {
                        const response = e.response as AxiosResponse;
                        dispatch(
                            snackbarActions.sendMessage({
                                type: 'warning',
                                title: 'Failed to create invoice',
                                subtitle: response.data.msg,
                            }),
                        );
                    } else {
                        dispatch(
                            snackbarActions.sendMessage({
                                type: 'warning',
                                title: 'Failed to create invoice',
                                subtitle: e.toString(),
                            }),
                        );
                    }
                } finally {
                    setWorking(false);
                }
            },
        [dispatch, partnerId, periodCode, setWorking, refresh],
    );

    return {
        working,
        createInvoice: working ? undefined : createInvoice,
    } as const;
}
