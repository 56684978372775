// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "model/richtext.proto" (package "one.plan3t.core.model", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { StringValue } from "../google/protobuf/wrappers";
import { DoubleValue } from "../google/protobuf/wrappers";
import { Any } from "../google/protobuf/any";
/**
 * @generated from protobuf message one.plan3t.core.model.RTText
 */
export interface RTText {
    /**
     * @generated from protobuf field: string text = 1;
     */
    text: string;
    /**
     * @generated from protobuf field: bool bold = 2;
     */
    bold: boolean;
    /**
     * @generated from protobuf field: bool italic = 3;
     */
    italic: boolean;
    /**
     * @generated from protobuf field: int64 color = 4;
     */
    color: number;
}
/**
 * @generated from protobuf message one.plan3t.core.model.RTElement
 */
export interface RTElement {
    /**
     * @generated from protobuf field: one.plan3t.core.model.RTElementType type = 1;
     */
    type: RTElementType;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.RTNode children = 2;
     */
    children: RTNode[];
    /**
     * @generated from protobuf field: map<string, google.protobuf.Any> attributes = 3;
     */
    attributes: {
        [key: string]: Any;
    };
}
/**
 * @generated from protobuf message one.plan3t.core.model.RTNode
 */
export interface RTNode {
    /**
     * @generated from protobuf oneof: value
     */
    value: {
        oneofKind: "text";
        /**
         * @generated from protobuf field: one.plan3t.core.model.RTText text = 1;
         */
        text: RTText;
    } | {
        oneofKind: "element";
        /**
         * @generated from protobuf field: one.plan3t.core.model.RTElement element = 2;
         */
        element: RTElement;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message one.plan3t.core.model.Plan3tRichText
 */
export interface Plan3tRichText {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.RTNode children = 1;
     */
    children: RTNode[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.____IncludeStuff
 */
export interface ____IncludeStuff {
    /**
     * we include stuff which is used as part of the Any wrappers
     *
     * @generated from protobuf field: google.protobuf.DoubleValue double = 1;
     */
    double?: DoubleValue;
    /**
     * @generated from protobuf field: google.protobuf.StringValue string = 2;
     */
    string?: StringValue;
}
/**
 * @generated from protobuf enum one.plan3t.core.model.RTElementType
 */
export enum RTElementType {
    /**
     * @generated from protobuf enum value: unknown = 0;
     */
    unknown = 0,
    /**
     * @generated from protobuf enum value: column = 1;
     */
    column = 1,
    /**
     * @generated from protobuf enum value: image = 2;
     */
    image = 2,
    /**
     * @generated from protobuf enum value: paragraph = 3;
     */
    paragraph = 3,
    /**
     * @generated from protobuf enum value: list = 4;
     */
    list = 4,
    /**
     * @generated from protobuf enum value: partner_ref = 5;
     */
    partner_ref = 5
}
// @generated message type with reflection information, may provide speed optimized methods
class RTText$Type extends MessageType<RTText> {
    constructor() {
        super("one.plan3t.core.model.RTText", [
            { no: 1, name: "text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "bold", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "italic", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "color", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<RTText>): RTText {
        const message = { text: "", bold: false, italic: false, color: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RTText>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RTText): RTText {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string text */ 1:
                    message.text = reader.string();
                    break;
                case /* bool bold */ 2:
                    message.bold = reader.bool();
                    break;
                case /* bool italic */ 3:
                    message.italic = reader.bool();
                    break;
                case /* int64 color */ 4:
                    message.color = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RTText, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string text = 1; */
        if (message.text !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.text);
        /* bool bold = 2; */
        if (message.bold !== false)
            writer.tag(2, WireType.Varint).bool(message.bold);
        /* bool italic = 3; */
        if (message.italic !== false)
            writer.tag(3, WireType.Varint).bool(message.italic);
        /* int64 color = 4; */
        if (message.color !== 0)
            writer.tag(4, WireType.Varint).int64(message.color);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.RTText
 */
export const RTText = new RTText$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RTElement$Type extends MessageType<RTElement> {
    constructor() {
        super("one.plan3t.core.model.RTElement", [
            { no: 1, name: "type", kind: "enum", T: () => ["one.plan3t.core.model.RTElementType", RTElementType] },
            { no: 2, name: "children", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RTNode },
            { no: 3, name: "attributes", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "message", T: () => Any } }
        ]);
    }
    create(value?: PartialMessage<RTElement>): RTElement {
        const message = { type: 0, children: [], attributes: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RTElement>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RTElement): RTElement {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.RTElementType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* repeated one.plan3t.core.model.RTNode children */ 2:
                    message.children.push(RTNode.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* map<string, google.protobuf.Any> attributes */ 3:
                    this.binaryReadMap3(message.attributes, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap3(map: RTElement["attributes"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof RTElement["attributes"] | undefined, val: RTElement["attributes"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = Any.internalBinaryRead(reader, reader.uint32(), options);
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.model.RTElement.attributes");
            }
        }
        map[key ?? ""] = val ?? Any.create();
    }
    internalBinaryWrite(message: RTElement, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.RTElementType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* repeated one.plan3t.core.model.RTNode children = 2; */
        for (let i = 0; i < message.children.length; i++)
            RTNode.internalBinaryWrite(message.children[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* map<string, google.protobuf.Any> attributes = 3; */
        for (let k of Object.keys(message.attributes)) {
            writer.tag(3, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k);
            writer.tag(2, WireType.LengthDelimited).fork();
            Any.internalBinaryWrite(message.attributes[k], writer, options);
            writer.join().join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.RTElement
 */
export const RTElement = new RTElement$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RTNode$Type extends MessageType<RTNode> {
    constructor() {
        super("one.plan3t.core.model.RTNode", [
            { no: 1, name: "text", kind: "message", oneof: "value", T: () => RTText },
            { no: 2, name: "element", kind: "message", oneof: "value", T: () => RTElement }
        ]);
    }
    create(value?: PartialMessage<RTNode>): RTNode {
        const message = { value: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RTNode>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RTNode): RTNode {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.RTText text */ 1:
                    message.value = {
                        oneofKind: "text",
                        text: RTText.internalBinaryRead(reader, reader.uint32(), options, (message.value as any).text)
                    };
                    break;
                case /* one.plan3t.core.model.RTElement element */ 2:
                    message.value = {
                        oneofKind: "element",
                        element: RTElement.internalBinaryRead(reader, reader.uint32(), options, (message.value as any).element)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RTNode, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.RTText text = 1; */
        if (message.value.oneofKind === "text")
            RTText.internalBinaryWrite(message.value.text, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.RTElement element = 2; */
        if (message.value.oneofKind === "element")
            RTElement.internalBinaryWrite(message.value.element, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.RTNode
 */
export const RTNode = new RTNode$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Plan3tRichText$Type extends MessageType<Plan3tRichText> {
    constructor() {
        super("one.plan3t.core.model.Plan3tRichText", [
            { no: 1, name: "children", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RTNode }
        ]);
    }
    create(value?: PartialMessage<Plan3tRichText>): Plan3tRichText {
        const message = { children: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Plan3tRichText>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Plan3tRichText): Plan3tRichText {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.RTNode children */ 1:
                    message.children.push(RTNode.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Plan3tRichText, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.RTNode children = 1; */
        for (let i = 0; i < message.children.length; i++)
            RTNode.internalBinaryWrite(message.children[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.Plan3tRichText
 */
export const Plan3tRichText = new Plan3tRichText$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ____IncludeStuff$Type extends MessageType<____IncludeStuff> {
    constructor() {
        super("one.plan3t.core.model.____IncludeStuff", [
            { no: 1, name: "double", kind: "message", T: () => DoubleValue },
            { no: 2, name: "string", kind: "message", T: () => StringValue }
        ]);
    }
    create(value?: PartialMessage<____IncludeStuff>): ____IncludeStuff {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<____IncludeStuff>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ____IncludeStuff): ____IncludeStuff {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.DoubleValue double */ 1:
                    message.double = DoubleValue.internalBinaryRead(reader, reader.uint32(), options, message.double);
                    break;
                case /* google.protobuf.StringValue string */ 2:
                    message.string = StringValue.internalBinaryRead(reader, reader.uint32(), options, message.string);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ____IncludeStuff, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.DoubleValue double = 1; */
        if (message.double)
            DoubleValue.internalBinaryWrite(message.double, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.StringValue string = 2; */
        if (message.string)
            StringValue.internalBinaryWrite(message.string, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.____IncludeStuff
 */
export const ____IncludeStuff = new ____IncludeStuff$Type();
