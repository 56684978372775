import cx from 'classnames';
import { DateTime } from 'luxon';
import { useField } from 'formik';
import React, {
    forwardRef,
    FunctionComponent,
    ReactNode,
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import {
    CO2SavingPotential,
    CoreIndustry,
    ImageResource,
} from '../api/model/core';
import { Timestamp } from '../api/google/protobuf/timestamp';
import FileUpload from '../files/FileUpload';
import { CrudSlice } from '../crud-module/store';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { ListPagination } from '../crud-module/ItemList';
import { IconCalendar, IconClose } from '../icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './react-datepicker-patches.css';
import Select from 'react-select';

type Props = {
    name: string;
    label: string;
    disabled?: boolean;
    fieldNamePrefix?: string;
};

export const FormTextField: FunctionComponent<
    Props & { placeholder?: string }
> = ({ name, label, disabled, placeholder, fieldNamePrefix }) => {
    const [field, meta] = useField(
        fieldNamePrefix ? `${fieldNamePrefix}.${name}` : name,
    );
    return (
        <div>
            <label className="block">
                <span className="text-gray-700">{label}</span>
                <input
                    {...field}
                    className="form-input mt-1 block w-full"
                    type="text"
                    disabled={!!disabled}
                    placeholder={placeholder}
                    onChange={(e) => {
                        field.onChange(e);
                    }}
                />
            </label>
            {meta.touched && meta.error ? (
                <p className="font-medium text-sm text-red-400 leading-5 py-1">
                    {meta.error}
                </p>
            ) : (
                <div className="py-1 leading-5">&nbsp;</div>
            )}
        </div>
    );
};

export const FormInternalImageField: FunctionComponent<Props> = ({
    name,
    fieldNamePrefix,
    label,
    disabled,
}) => {
    const [url] = useField<string>(
        `${fieldNamePrefix ? `${fieldNamePrefix}.${name}` : name}.url`,
    );

    const [, meta, h] = useField<ImageResource | undefined>(name);

    return (
        <div>
            <FileUpload
                label="Image"
                message="Drop a single image here"
                file={
                    !!url
                        ? ImageResource.create({
                              url: url.value,
                              id: 'internal::' + url,
                          })
                        : undefined
                }
                onSingleChanged={(ir) => h.setValue(ir)}
            />
            {meta.touched && meta.error ? (
                <p className="font-medium text-sm text-red-400 leading-5 py-1">
                    {meta.error}
                </p>
            ) : (
                <div className="py-1 leading-5">&nbsp;</div>
            )}
        </div>
    );
};

export const FormTextAreaField: FunctionComponent<Props> = ({
    name,
    fieldNamePrefix,
    label,
    disabled,
}) => {
    const [field, meta] = useField(
        fieldNamePrefix ? `${fieldNamePrefix}.${name}` : name,
    );
    return (
        <div>
            <label className="block">
                <span className="text-gray-700">{label}</span>
                <textarea
                    className="form-textarea mt-1 block w-full"
                    rows={10}
                    disabled={!!disabled}
                    {...field}
                />
            </label>
            {meta.touched && meta.error ? (
                <p className="font-medium text-sm text-red-400 leading-5 py-1">
                    {meta.error}
                </p>
            ) : (
                <div className="py-1 leading-5">&nbsp;</div>
            )}
        </div>
    );
};

export const FormSelectField: FunctionComponent<
    Props & { options: { label: string; value: string | number }[] }
> = ({ name, fieldNamePrefix, label, disabled, options }) => {
    const [field, meta, helpers] = useField(
        fieldNamePrefix ? `${fieldNamePrefix}.${name}` : name,
    );
    return (
        <div>
            <label className="block">
                <span className="text-gray-700">{label}</span>
                <select
                    className="form-select mt-1 block w-full shadow-sm disabled:opacity-50"
                    disabled={!!disabled}
                    {...field}
                    onChange={(e) => {
                        if (typeof field.value === 'number') {
                            const v = parseInt(e.target.value, 10);
                            helpers.setValue(v);
                        } else {
                            field.onChange(e);
                        }
                    }}
                >
                    {options.map((option) => (
                        <option
                            key={option.value.toString()}
                            value={option.value}
                        >
                            {option.label}
                        </option>
                    ))}
                </select>
            </label>
            {meta.touched && meta.error ? (
                <p className="font-medium text-sm text-red-400 leading-5 py-1">
                    {meta.error}
                </p>
            ) : (
                <div className="py-1 leading-5">&nbsp;</div>
            )}
        </div>
    );
};

export const FormMultiSelectField: FunctionComponent<
    Props & { options: { label: string; value: string | number }[] }
> = ({ name, fieldNamePrefix, label, disabled, options }) => {
    const [field, meta, helpers] = useField(
        fieldNamePrefix ? `${fieldNamePrefix}.${name}` : name,
    );

    const v = field.value;
    const value = useMemo(() => {
        return options
            .filter((opt) => v.includes(opt.value))
            .sort((a, b) => v.indexOf(a.value) - v.indexOf(b.value));
    }, [options, v]);

    return (
        <div>
            <label className="block">
                <span className="text-gray-700">{label}</span>
                <Select
                    isMulti
                    name={name}
                    onChange={(v) =>
                        helpers.setValue(
                            v.map(({ value }) => value).filter((v) => !!v),
                        )
                    }
                    value={value}
                    options={options}
                    isDisabled={disabled}
                    className="basic-multi-select"
                    classNamePrefix="select"
                />
            </label>
            {meta.touched && meta.error ? (
                <p className="font-medium text-sm text-red-400 leading-5 py-1">
                    {meta.error}
                </p>
            ) : (
                <div className="py-1 leading-5">&nbsp;</div>
            )}
        </div>
    );
};

export const FormNumberField: FunctionComponent<
    Props & { min?: number; step?: number | string }
> = ({ name, label, disabled, min, step }) => {
    const [field, meta] = useField(name);
    return (
        <div>
            <label className="block">
                <span className="text-gray-700">{label}</span>
                <input
                    className="form-input mt-1 block w-full"
                    type="number"
                    min={min}
                    step={step}
                    disabled={!!disabled}
                    {...field}
                />
            </label>
            {meta.touched && meta.error ? (
                <p className="font-medium text-sm text-red-400 leading-5 py-1">
                    {meta.error}
                </p>
            ) : (
                <div className="py-1 leading-5">&nbsp;</div>
            )}
        </div>
    );
};

export const FormPublishedAtField: FunctionComponent<Props> = ({
    name,
    label,
    disabled,
}) => {
    const [field, meta, helpers] = useField(name);

    disabled = disabled && !!meta.initialValue;

    if (disabled) {
        return (
            <div>
                {!!field.value && (
                    <span className="text-gray-700">
                        Published at {Timestamp.toDate(field.value).toString()}
                    </span>
                )}
                {!field.value && (
                    <span className="text-gray-700">Not published</span>
                )}
            </div>
        );
    }

    return (
        <div>
            <label
                className={cx('block', {
                    'opacity-50 cursor-not-allowed': disabled,
                })}
            >
                <span className="text-gray-700">{label}</span>
                <input
                    name={name}
                    className="form-checkbox mt-1 block"
                    type="checkbox"
                    checked={!!field.value}
                    disabled={disabled}
                    onChange={(e) =>
                        helpers.setValue(
                            e.target.checked ? Timestamp.now() : undefined,
                        )
                    }
                />
            </label>
            {meta.touched && meta.error ? (
                <p className="font-medium text-sm text-red-400 leading-5 py-1">
                    {meta.error}
                </p>
            ) : (
                <div className="py-1 leading-5">&nbsp;</div>
            )}
        </div>
    );
};

export const FormCheckboxField: FunctionComponent<Props> = ({
    name,
    label,
    disabled,
}) => {
    const [field, meta, helpers] = useField(name);
    return (
        <div>
            <label className="block">
                <span className="text-gray-700">{label}</span>
                <input
                    name={name}
                    className="form-checkbox mt-1 block"
                    type="checkbox"
                    checked={!!field.value}
                    disabled={!!disabled}
                    onChange={(e) => helpers.setValue(e.target.checked)}
                />
            </label>
            {meta.touched && meta.error ? (
                <p className="font-medium text-sm text-red-400 leading-5 py-1">
                    {meta.error}
                </p>
            ) : (
                <div className="py-1 leading-5">&nbsp;</div>
            )}
        </div>
    );
};

export const FormCoreIndustryField: FunctionComponent<Props> = ({
    name,
    label,
}) => {
    return (
        <FormSelectField
            name={name}
            label={label}
            options={[
                {
                    label: 'Unknown',
                    value: CoreIndustry.UnknownIndustry,
                },
                {
                    label: 'Energy',
                    value: CoreIndustry.Housing,
                },
                {
                    label: 'Mobility',
                    value: CoreIndustry.Mobility,
                },
                {
                    label: 'Nutrition',
                    value: CoreIndustry.Nutrition,
                },
                {
                    label: 'Consumption',
                    value: CoreIndustry.Consumption,
                },
            ]}
        />
    );
};

export const FormSingleImageField: FunctionComponent<
    Props & { message: string }
> = ({ name, label, message, disabled, fieldNamePrefix }) => {
    const [field, , helpers] = useField(
        fieldNamePrefix ? `${fieldNamePrefix}.${name}` : name,
    );
    return (
        <FileUpload
            label={label}
            message={message}
            file={field.value}
            onSingleChanged={(img) => {
                helpers.setValue(img);
            }}
            disabled={!!disabled}
        />
    );
};

export const FormMultiImageField: FunctionComponent<
    Props & { message: string }
> = ({ name, label, message, disabled }) => {
    const [field, , helpers] = useField(name);
    return (
        <FileUpload
            label={label}
            message={message}
            file={field.value}
            onMultipleChanged={(imgs) => {
                helpers.setValue(imgs);
            }}
            disabled={!!disabled}
        />
    );
};

export function FormItemAssociationField<T>(
    props: Props & { module: CrudSlice<T>; nameGetter: (i: T) => ReactNode },
) {
    const dispatch = useAppDispatch();
    const items = useAppSelector(props.module.selectors.getCurrentPage);
    const hasMore = useAppSelector(props.module.selectors.hasMore);
    const shouldFetch = hasMore && items.length === 0;

    useEffect(() => {
        if (shouldFetch) {
            dispatch(props.module.actions.fetchList());
        }
    }, [dispatch, shouldFetch, props.module.actions]);

    const [field, , helper] = useField(props.name);

    const selected = field.value as string[];

    const handleChange = (id: string, checked: boolean) => {
        if (checked) {
            helper.setValue([...selected, id]);
        } else {
            helper.setValue(selected.filter((pid) => pid !== id));
        }
    };

    return (
        <div className="flex flex-col">
            <span className="text-gray-700">{props.label}</span>

            <ul className="flex flex-col">
                {items.map((item) => (
                    <li key={props.module._args.idProvider(item)}>
                        <label>
                            <input
                                type="checkbox"
                                className="form-checkbox mr-1"
                                checked={selected.includes(
                                    props.module._args.idProvider(item),
                                )}
                                onChange={(e) =>
                                    handleChange(
                                        props.module._args.idProvider(item),
                                        e.target.checked,
                                    )
                                }
                                onBlur={field.onBlur}
                                name={props.name}
                            />
                            <span>{props.nameGetter(item)}</span>
                        </label>
                    </li>
                ))}
            </ul>
            <ListPagination module={props.module} />
        </div>
    );
}

export const FormTimestampField: FunctionComponent<
    Props & { placeholder?: string; clearable?: boolean }
> = ({ name, label, disabled, placeholder, clearable }) => {
    const [field, meta, helper] = useField(name);

    return (
        <div>
            <label className="block">
                <span className="text-gray-700">{label}</span>
                <div className="flex flex-row space-x-2 items-stretch">
                    <input
                        {...field}
                        type="datetime-local"
                        className="form-input mt-1 block w-full"
                        disabled={!!disabled}
                        placeholder={placeholder}
                        value={
                            !field.value
                                ? ''
                                : DateTime.fromJSDate(
                                      Timestamp.toDate(field.value),
                                  ).toFormat("yyyy-MM-dd'T'HH:mm")
                        }
                        onChange={(e) => {
                            let value = e.target.value.replace(
                                /^000/,
                                new Date()
                                    .getFullYear()
                                    .toString(10)
                                    .substr(0, 3),
                            );
                            const date = new Date(value);
                            if (isNaN(date.getTime())) {
                                return;
                            }
                            helper.setValue(Timestamp.fromDate(date));
                        }}
                    />
                    <DateTimePickerFallback
                        value={
                            field.value
                                ? Timestamp.toDate(field.value)
                                : undefined
                        }
                        onChange={(d) => helper.setValue(Timestamp.fromDate(d))}
                    />
                    {clearable && (
                        <button
                            className="block rounded border border-gray-200 hover:border-gray-400 px-4 mt-1"
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                helper.setValue(undefined);
                            }}
                        >
                            <IconClose className="w-6 h-6 text-gray-500" />
                        </button>
                    )}
                </div>
            </label>
            {meta.touched && meta.error ? (
                <p className="font-medium text-sm text-red-400 leading-5 py-1">
                    {meta.error}
                </p>
            ) : (
                <div className="py-1 leading-5">&nbsp;</div>
            )}
        </div>
    );
};

function DateTimePickerFallback(props: {
    value: Date | undefined;
    onChange: (v: Date) => void;
}) {
    const Trigger = forwardRef<HTMLButtonElement>(
        ({ value, onClick }: any, ref) => (
            <button
                className="block rounded border border-gray-200 hover:border-gray-400 px-4 h-full"
                type="button"
                onClick={(e) => {
                    e.preventDefault();
                    onClick();
                }}
                ref={ref}
            >
                <IconCalendar className="w-6 h-6 text-gray-500" />
            </button>
        ),
    );
    return (
        <div className="flex flex-row items-stretch">
            <DatePicker
                showTimeSelect
                selected={props.value ?? null}
                onChange={props.onChange}
                customInput={<Trigger />}
                wrapperClassName="contents mt-1"
            />
        </div>
    );
}

export const FormCO2SavingPotentialField: FunctionComponent<Props> = ({
    name,
    label,
}) => {
    const allCO2SavingPotentials = Object.keys(CO2SavingPotential)
        .filter((v) => !isNaN(Number(v)))
        .map((key) => CO2SavingPotential[Number(key)])
        .filter(
            (v) =>
                v !==
                CO2SavingPotential[
                    CO2SavingPotential.CO2SavingPotentialUnknown
                ],
        );

    const options = allCO2SavingPotentials.map((k) => ({
        label: k.replace(/^CO2SavingPotential/, ''),
        value: CO2SavingPotential[k as keyof typeof CO2SavingPotential],
    }));

    return <FormMultiSelectField name={name} label={label} options={options} />;
};

export const FormInlineDocumentField: FunctionComponent<
    Props & { mimeTypeField: string }
> = ({ name, mimeTypeField, label, disabled }) => {
    const [, meta, helper] = useField(name);
    const [, , mtHelper] = useField(mimeTypeField);

    const onDrop = useCallback(
        async (acceptedFiles: FileList | null) => {
            if (!acceptedFiles) {
                return;
            }
            if (acceptedFiles.length !== 1) {
                return;
            }
            const file = acceptedFiles[0];

            const res = await new Promise<Uint8Array>((resolve, reject) => {
                const reader = new FileReader();
                reader.addEventListener('loadend', (e) => {
                    const result = e.target?.result;
                    if (result instanceof ArrayBuffer) {
                        resolve(new Uint8Array(result));
                    } else {
                        reject(
                            `unexpected read result, expected ArrayBuffer, got ${typeof result}: '${result}'`,
                        );
                    }
                });
                reader.addEventListener('error', reject);

                reader.readAsArrayBuffer(file);
            });

            console.log(file);

            helper.setValue(res);
            mtHelper.setValue(file.type);
        },
        [helper, mtHelper],
    );

    return (
        <div>
            <label className="flex flex-col">
                <span className="text-gray-700">{label}</span>
                <input type="file" onChange={(e) => onDrop(e.target.files)} />
            </label>
            {meta.touched && meta.error ? (
                <p className="font-medium text-sm text-red-400 leading-5 py-1">
                    {meta.error}
                </p>
            ) : (
                <div className="py-1 leading-5">&nbsp;</div>
            )}
        </div>
    );
};

export const FormUnscaledValueField: FunctionComponent<Props> = ({
    name,
    label,
    disabled,
}) => {
    const [unF, unM] = useField(`${name}Unscaled`);
    const [sF, sM] = useField(`${name}Scale`);

    const value =
        ((unF.value ?? 0) as number) / Math.pow(10, (sF.value ?? 0) as number);

    return (
        <div>
            <label className="block">
                <span className="text-gray-700">{label}</span>
                <div className="flex flex-row items-center space-x-2">
                    <input
                        className="form-input mt-1 block w-full"
                        type="number"
                        disabled={!!disabled}
                        {...unF}
                    />
                    <input
                        className="form-input mt-1 block w-full"
                        type="number"
                        disabled={!!disabled}
                        {...sF}
                    />
                    <span className="flex-shrink-0 text-sm">= {value}</span>
                </div>
            </label>
            {(unM.touched && unM.error) || (sM.touched && sM.error) ? (
                <p className="font-medium text-sm text-red-400 leading-5 py-1">
                    {unM?.error ?? sM.error}
                </p>
            ) : (
                <div className="py-1 leading-5">&nbsp;</div>
            )}
        </div>
    );
};
