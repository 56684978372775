import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LotteryList from './LotteryList';
import LotteryPriceDrawingItemForm from './LotteryPriceDrawingItemForm';

const LotteryModule: React.FunctionComponent = () => {
    return (
        <Switch>
            <Route path="/lottery" exact>
                <LotteryList />
            </Route>
            <Route path="/lottery/create" exact>
                <LotteryPriceDrawingItemForm id={undefined} />
            </Route>
            <Route
                path="/lottery/:id/edit"
                exact
                render={({ match }) => (
                    <LotteryPriceDrawingItemForm id={match.params['id']} />
                )}
            />
        </Switch>
    );
};

export default LotteryModule;
