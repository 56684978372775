import Scrollable from '../common/Scrollable';
import { useReward } from './store';
import { Link } from 'react-router-dom';
import { IconBack } from '../icons';
import { usePartnerProfile } from '../partner/store';
import { Timestamp } from '../api/google/protobuf/timestamp';

export default function RewardViewer({
    id,
    partnerId,
}: {
    id: string;
    partnerId: string;
}) {
    const reward = useReward(id);
    const profile = usePartnerProfile(partnerId);

    if (!reward || !profile) {
        return <span>Not Found</span>;
    }

    return (
        <Scrollable>
            <div className="pb-4">
                <Link
                    to={`/reward`}
                    className="inline-flex items-center space-x-2 px-4 py-2 -mx-4 hover:bg-gray-200 rounded"
                >
                    <IconBack className="h-4 w-4" />
                </Link>
            </div>
            <div className="flex flex-col space-y-2 max-w-2xl">
                <a href={reward.images[0].url}>
                    <img
                        src={reward.images[0].url}
                        alt="cover"
                        className="h-32 object-cover"
                    />
                </a>
                <div className="flex flex-row space-x-2 items-center">
                    <img
                        src={profile.logo?.url}
                        alt="logo"
                        className="w-8 h-8 rounded-full overflow-hidden object-contain border border-gray-100"
                    />
                    <div className="flex flex-col">
                        <h1 className="font-bold">{profile.name}</h1>
                        <span className="text-gray-500 text-xs">
                            {profile.tagLine}
                        </span>
                    </div>
                </div>
                <h1 className="font-bold">{reward.title}</h1>
                <p className="text-sm text-gray-800">{reward.description}</p>
                <p>
                    Valid until:{' '}
                    {Timestamp.toDate(reward.validUntil!).toDateString()}
                </p>
                <hr />
                <p className="text-sm text-gray-800">{reward.terms}</p>
                <hr />
                <a href={reward.directLink}>{reward.directLink}</a>
            </div>
        </Scrollable>
    );
}
