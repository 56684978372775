import { atom, selector, useRecoilCallback } from 'recoil';

import * as Report from '../api/report/rpc/report';
import axios from 'axios';

export const availableReportsQuery = selector({
    key: 'report/availableReportsQuery',
    get: async () => {
        const res = await axios.post(
            '/one.plan3t.core.report.rpc.Report/GetAvailableReports',
            {},
        );
        return Report.GetAvailableReportsResponse.fromJson(res.data)
            .reportDefinitions;
    },
});

export const activeReportAtom = atom<Report.ReportDefinition | undefined>({
    key: 'report/activeReportAtom',
    default: undefined,
});

export const activeReportResultQuery = selector({
    key: 'report/activeReportResultQuery',
    get: async ({ get }) => {
        const active = get(activeReportAtom);
        if (!active) {
            return Report.GetReportResponse.create();
        }
        const res = await axios.post(
            '/one.plan3t.core.report.rpc.Report/GetReport',
            Report.GetReportRequest.toJson({
                definition: get(activeReportAtom),
            }),
        );
        return Report.GetReportResponse.fromJson(res.data);
    },
});

export function useDownloadAsXLSXFunction() {
    return useRecoilCallback(
        ({ snapshot }) =>
            async () => {
                const active = await snapshot.getPromise(activeReportAtom);
                if (!active) {
                    throw new Error('no report is active');
                }
                const data = await snapshot.getPromise(activeReportResultQuery);
                const XLSX = await import('xlsx');
                const wb = XLSX.utils.book_new();
                if (!wb.Props) wb.Props = {};
                wb.Props.Title = `PLAN3T Backoffice Report Export '${active.id}'`;
                wb.Props.Company = 'Plan3t GmbH';
                wb.Props.CreatedDate = new Date();
                const worksheet = XLSX.utils.aoa_to_sheet([
                    data.header,
                    ...data.rows.map((r) => r.fields),
                ]);
                XLSX.utils.book_append_sheet(wb, worksheet, active.id);

                const metaSheet = XLSX.utils.aoa_to_sheet([
                    ['ID', active.id],
                    [],
                    ...active.arguments.map((arg) => [arg.name, arg.value]),
                    [],
                    [],
                    ['Exported at', new Date().toISOString()],
                ]);
                XLSX.utils.book_append_sheet(
                    wb,
                    metaSheet,
                    'Export Information',
                );

                const prefix = new Date().toISOString().slice(0, 10);
                XLSX.writeFileXLSX(wb, `${prefix}-${active.id}.xlsx`);
            },
        [],
    );
}
