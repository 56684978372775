import * as React from 'react';
import { AppState } from '../redux/AppStore';
import { rewardActions, rewardSelectors } from './store';
import { connect, ConnectedProps } from 'react-redux';
import {
    Table,
    TableFooter,
    TableHeader,
    TableRow,
    TextTableCell,
} from '../common/Table';
import Pagination from '../common/Pagination';
import { Button } from '../common/Button';
import Scrollable from '../common/Scrollable';
import SuspendRewardButton from './SuspendRewardButton';
import { Timestamp } from '../api/google/protobuf/timestamp';
import { RewardBillingType } from '../api/model/core';
import PartnerName from '../partner/PartnerName';

const mapStateToProps = (state: AppState) => ({
    rewards: rewardSelectors.getCurrentPage(state),
    hasMore: rewardSelectors.hasMore(state),
    currentPage: rewardSelectors.getCurrentPageNumber(state),
    pageSize: rewardSelectors.getPageSize(state),
});

const connector = connect(mapStateToProps, {
    navigateToNextPage: rewardActions.navigateToNextPage,
    navigateToPreviousPage: rewardActions.navigateToPreviousPage,
    fetchRewards: rewardActions.fetchRewards,
    copyDeepLink: rewardActions.copyDeepLink,
});

type Props = ConnectedProps<typeof connector>;

export const RewardListInner: React.FunctionComponent<Props> = (props) => {
    const { hasMore, fetchRewards, rewards } = props;
    React.useEffect(() => {
        if (hasMore) {
            fetchRewards();
        }
    }, [hasMore, fetchRewards]);

    return (
        <Scrollable>
            <div className="flex flex-row items-center space-x-2 mb-4">
                <h1 className="font-bold text-3xl">Rewards</h1>
                <div>
                    <Button
                        primary
                        label="Edit Categories (Discounts)"
                        to={'/reward/categories/REWARD_BILLING_TYPE_DISCOUNT'}
                        className="my-6"
                    />
                </div>
                <div>
                    <Button
                        primary
                        label="Edit Categories (Paids)"
                        to={'/reward/categories/REWARD_BILLING_TYPE_PAID'}
                        className="my-6"
                    />
                </div>
            </div>
            <Table
                header={
                    <TableHeader
                        labels={['Name', 'Partner', 'Status', 'Actions']}
                    />
                }
                footer={
                    <TableFooter>
                        <Pagination
                            currentPage={props.currentPage}
                            perPage={props.pageSize}
                            hasNext={props.hasMore}
                            hasPrev={props.currentPage > 0}
                            onPrev={props.navigateToPreviousPage}
                            onNext={props.navigateToNextPage}
                        />
                    </TableFooter>
                }
            >
                {rewards.map((reward) => (
                    <TableRow
                        key={reward.rewardId}
                        contents={[
                            <TextTableCell
                                primary={truncate(reward.title, 50)}
                                secondary={`${
                                    reward.price?.amount ?? 'XX'
                                } Planet Points`}
                            />,
                            <TextTableCell
                                primary={<PartnerName id={reward.partnerId} />}
                            />,
                            <TextTableCell
                                primary={
                                    Timestamp.toDate(reward.validUntil!) <
                                    new Date() ? (
                                        <span className="text-semantic-error font-bold tracking-wider">
                                            EXPIRED
                                        </span>
                                    ) : (
                                        'Active'
                                    )
                                }
                                secondary={`Valid Until: ${Timestamp.toDate(
                                    reward.validUntil!,
                                )
                                    .toLocaleString()
                                    .substr(0, 10)}`}
                            />,
                            <div className="inline-flex -ml-4">
                                <Button
                                    label="Copy DeepLink"
                                    secondary
                                    onClick={() => props.copyDeepLink(reward)}
                                />
                                <Button
                                    label="Adjust Price"
                                    secondary
                                    to={`/reward/${reward.partnerId}/${reward.rewardId}/price`}
                                />
                                <Button
                                    label="Manage Codes"
                                    secondary
                                    to={
                                        `/reward/${reward.partnerId}/${reward.rewardId}/codes` +
                                        (reward.billingType ===
                                        RewardBillingType.PAID
                                            ? 'V2'
                                            : '')
                                    }
                                />
                                <SuspendRewardButton
                                    rewardId={reward.rewardId}
                                    partnerId={reward.partnerId}
                                />
                                <Button
                                    label="Preview"
                                    secondary
                                    to={`/reward/${reward.partnerId}/${reward.rewardId}/preview`}
                                />
                            </div>,
                        ]}
                    />
                ))}
            </Table>
        </Scrollable>
    );
};

export default connector(RewardListInner);

function truncate(str: string, n: number) {
    return str.length > n ? str.substr(0, n - 1) + '...' : str;
}
