import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    GetReviewRequestsResponse,
    ReviewRequest,
} from '../api/model/backoffice';
import { AppState } from '../redux/AppStore';

const fetchPage = async (): Promise<ReviewRequest[]> => {
    const raw = await axios.get<Object>(`/review`);
    const res = GetReviewRequestsResponse.fromJson(raw.data as any);
    return res.reviewRequests;
};

const fetchReviewRequests = createAsyncThunk(
    'review/fetch/all',
    async (_: void, { getState }) => {
        return fetchPage();
    },
);

type State = {
    payloadIds: string[];
    reviewRequestByPayloadId: Record<string, ReviewRequest>;
    isFetching: boolean;
    fetchError: any;
};

const initialState: State = {
    payloadIds: [],
    reviewRequestByPayloadId: {},
    isFetching: false,
    fetchError: undefined,
};

const reviewSlice = createSlice({
    name: 'review',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchReviewRequests.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(fetchReviewRequests.fulfilled, (state, action) => {
            state.isFetching = false;

            const asSet = new Set(state.payloadIds);

            action.payload.forEach((request) => {
                asSet.add(request.payloadId);
                state.reviewRequestByPayloadId[request.payloadId] = request;
            });
            state.payloadIds = Array.from(asSet).sort();
        });
        builder.addCase(fetchReviewRequests.rejected, (state, action) => {
            state.isFetching = false;
            state.fetchError = action.error;
        });
    },
});

export const reviewReducer = reviewSlice.reducer;
export const reviewActions = {
    ...reviewSlice.actions,
    fetchReviewRequests,
};

export const reviewSelectors = {
    getAllPending: (state: AppState): Array<ReviewRequest> =>
        Object.values(state.review.reviewRequestByPayloadId),
} as const;
