import cx from 'classnames';
import React, { ButtonHTMLAttributes } from 'react';
import { Link, LinkProps } from 'react-router-dom';

type CommonProps = {
    label: React.ReactNode;
} & (
    | { primary: true; secondary?: false }
    | { primary?: false; secondary: true }
);

type ButtonElProps = ButtonHTMLAttributes<HTMLButtonElement>;
type LinkElProps = LinkProps;

type ButtonProps =
    | (CommonProps & ButtonElProps)
    | (CommonProps & LinkElProps & { to: string; disabled: boolean });

export const Button: React.FunctionComponent<ButtonProps> = ({
    label,
    primary,
    secondary,
    className,
    ...props
}) => {
    const buttonClass = cx(
        'relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md focus:outline-none focus:border-green-700 focus:shadow-outline-green transition-default',
        className,
        {
            'text-semantic-onPrimary bg-semantic-primary hover:bg-opacity-75 active:bg-opacity-75':
                primary,
            'text-semantic-secondary hover:bg-green-200 hover:text-green-800':
                secondary,
            'opacity-75 cursor-not-allowed':
                'disabled' in props && props.disabled,
        },
    );

    if ('to' in props) {
        return (
            <Link className={buttonClass} {...props}>
                {label}
            </Link>
        );
    }

    return (
        <button className={buttonClass} {...props}>
            {label}
        </button>
    );
};
