// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "auditor/rpc/auditor.proto" (package "one.plan3t.core.auditor.rpc", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../google/protobuf/timestamp";
/**
 * @generated from protobuf message one.plan3t.core.auditor.rpc.RunResult
 */
export interface RunResult {
    /**
     * @generated from protobuf field: int64 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string audit_name = 2;
     */
    auditName: string;
    /**
     * @generated from protobuf field: one.plan3t.core.auditor.rpc.AuditStatus status = 3;
     */
    status: AuditStatus;
    /**
     * @generated from protobuf field: string remarks = 4;
     */
    remarks: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp started_at = 5;
     */
    startedAt?: Timestamp;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp finished_at = 6;
     */
    finishedAt?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.auditor.rpc.RunChecksRequest
 */
export interface RunChecksRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.auditor.rpc.RunChecksResponse
 */
export interface RunChecksResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.auditor.rpc.GetResultOverviewRequest
 */
export interface GetResultOverviewRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.auditor.rpc.GetResultOverviewResponse
 */
export interface GetResultOverviewResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.auditor.rpc.RunResult recent_run_results = 1;
     */
    recentRunResults: RunResult[];
}
/**
 * @generated from protobuf enum one.plan3t.core.auditor.rpc.AuditStatus
 */
export enum AuditStatus {
    /**
     * @generated from protobuf enum value: AUDIT_STATUS_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: AUDIT_STATUS_FAILED = 100;
     */
    FAILED = 100,
    /**
     * @generated from protobuf enum value: AUDIT_STATUS_PASSED = 200;
     */
    PASSED = 200
}
// @generated message type with reflection information, may provide speed optimized methods
class RunResult$Type extends MessageType<RunResult> {
    constructor() {
        super("one.plan3t.core.auditor.rpc.RunResult", [
            { no: 1, name: "id", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "audit_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "status", kind: "enum", T: () => ["one.plan3t.core.auditor.rpc.AuditStatus", AuditStatus, "AUDIT_STATUS_"] },
            { no: 4, name: "remarks", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "started_at", kind: "message", T: () => Timestamp },
            { no: 6, name: "finished_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<RunResult>): RunResult {
        const message = { id: 0, auditName: "", status: 0, remarks: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RunResult>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RunResult): RunResult {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 id */ 1:
                    message.id = reader.int64().toNumber();
                    break;
                case /* string audit_name */ 2:
                    message.auditName = reader.string();
                    break;
                case /* one.plan3t.core.auditor.rpc.AuditStatus status */ 3:
                    message.status = reader.int32();
                    break;
                case /* string remarks */ 4:
                    message.remarks = reader.string();
                    break;
                case /* google.protobuf.Timestamp started_at */ 5:
                    message.startedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.startedAt);
                    break;
                case /* google.protobuf.Timestamp finished_at */ 6:
                    message.finishedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.finishedAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RunResult, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int64(message.id);
        /* string audit_name = 2; */
        if (message.auditName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.auditName);
        /* one.plan3t.core.auditor.rpc.AuditStatus status = 3; */
        if (message.status !== 0)
            writer.tag(3, WireType.Varint).int32(message.status);
        /* string remarks = 4; */
        if (message.remarks !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.remarks);
        /* google.protobuf.Timestamp started_at = 5; */
        if (message.startedAt)
            Timestamp.internalBinaryWrite(message.startedAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp finished_at = 6; */
        if (message.finishedAt)
            Timestamp.internalBinaryWrite(message.finishedAt, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.auditor.rpc.RunResult
 */
export const RunResult = new RunResult$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RunChecksRequest$Type extends MessageType<RunChecksRequest> {
    constructor() {
        super("one.plan3t.core.auditor.rpc.RunChecksRequest", []);
    }
    create(value?: PartialMessage<RunChecksRequest>): RunChecksRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RunChecksRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RunChecksRequest): RunChecksRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RunChecksRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.auditor.rpc.RunChecksRequest
 */
export const RunChecksRequest = new RunChecksRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RunChecksResponse$Type extends MessageType<RunChecksResponse> {
    constructor() {
        super("one.plan3t.core.auditor.rpc.RunChecksResponse", []);
    }
    create(value?: PartialMessage<RunChecksResponse>): RunChecksResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RunChecksResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RunChecksResponse): RunChecksResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RunChecksResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.auditor.rpc.RunChecksResponse
 */
export const RunChecksResponse = new RunChecksResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetResultOverviewRequest$Type extends MessageType<GetResultOverviewRequest> {
    constructor() {
        super("one.plan3t.core.auditor.rpc.GetResultOverviewRequest", []);
    }
    create(value?: PartialMessage<GetResultOverviewRequest>): GetResultOverviewRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetResultOverviewRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetResultOverviewRequest): GetResultOverviewRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetResultOverviewRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.auditor.rpc.GetResultOverviewRequest
 */
export const GetResultOverviewRequest = new GetResultOverviewRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetResultOverviewResponse$Type extends MessageType<GetResultOverviewResponse> {
    constructor() {
        super("one.plan3t.core.auditor.rpc.GetResultOverviewResponse", [
            { no: 1, name: "recent_run_results", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => RunResult }
        ]);
    }
    create(value?: PartialMessage<GetResultOverviewResponse>): GetResultOverviewResponse {
        const message = { recentRunResults: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetResultOverviewResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetResultOverviewResponse): GetResultOverviewResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.auditor.rpc.RunResult recent_run_results */ 1:
                    message.recentRunResults.push(RunResult.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetResultOverviewResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.auditor.rpc.RunResult recent_run_results = 1; */
        for (let i = 0; i < message.recentRunResults.length; i++)
            RunResult.internalBinaryWrite(message.recentRunResults[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.auditor.rpc.GetResultOverviewResponse
 */
export const GetResultOverviewResponse = new GetResultOverviewResponse$Type();
/**
 * @generated ServiceType for protobuf service one.plan3t.core.auditor.rpc.Auditor
 */
export const Auditor = new ServiceType("one.plan3t.core.auditor.rpc.Auditor", [
    { name: "RunChecks", options: {}, I: RunChecksRequest, O: RunChecksResponse },
    { name: "GetResultOverview", options: {}, I: GetResultOverviewRequest, O: GetResultOverviewResponse }
]);
