import { Editor, Transforms, Text, Node, Element, Path } from 'slate';
import { SlateElement } from './layouts';

export type Plan3tEditor = {
    isFormatActive: (format: 'bold' | 'italic') => boolean;
    toggleFormat: (format: 'bold' | 'italic') => void;
    isColorActive: (color: number) => boolean;
    toggleColor: (color: number) => void;
    isBlockActive: (type: SlateElement['type']) => boolean;
    toggleList: (marker: 'disc' | 'decimal' | 'check') => void;
    insertPartnerRef: (id: string) => void;
};

const isSlateElement = (value: Node): value is SlateElement => {
    return 'type' in value;
};

export const withPlan3tEditing = (editor: Editor) => {
    const { isVoid, insertBreak, deleteBackward } = editor;
    editor.isVoid = (element: Element) => {
        if (element.type === 'partner_ref') {
            return true;
        }
        return isVoid(element);
    };

    editor.insertSoftBreak = () => {
        editor.insertText('\n');
    };
    editor.insertBreak = () => {
        const parentPath = Path.parent(editor.selection!.focus.path);
        const parentNode = Node.get(editor, parentPath);

        if (isSlateElement(parentNode) && editor.isVoid(parentNode)) {
            const nextPath = Path.next(parentPath);
            Transforms.insertNodes(
                editor,
                {
                    type: 'paragraph',
                    children: [
                        { text: '', color: 0, bold: false, italic: false },
                    ],
                },
                {
                    at: nextPath,
                    select: true,
                },
            );
        } else {
            insertBreak();
        }
    };

    editor.deleteBackward = (...args) => {
        const parentPath = Path.parent(editor.selection!.focus.path);
        const parentNode = Node.get(editor, parentPath);

        if (isSlateElement(parentNode) && editor.isVoid(parentNode)) {
            Transforms.removeNodes(editor, { at: parentPath });
        } else {
            deleteBackward(...args);
        }
    };

    editor.isFormatActive = (format: 'bold' | 'italic') => {
        const match = Editor.nodes(editor, {
            match: (n) => Text.isText(n) && n[format] === true,
            universal: true,
        }).next().value;

        return !!match;
    };
    editor.toggleFormat = (format: 'bold' | 'italic') => {
        const isActive = editor.isFormatActive(format);
        Transforms.setNodes(
            editor,
            { [format]: !isActive },
            { match: Text.isText, split: true },
        );
    };
    editor.isColorActive = (color: number) => {
        const match = Editor.nodes(editor, {
            match: (n) => Text.isText(n) && n.color === color,
            universal: true,
        }).next().value;

        return !!match;
    };
    editor.toggleColor = (color: number) => {
        const isActive = editor.isColorActive(color);
        Transforms.setNodes(
            editor,
            { color: isActive ? 0 : color },
            { match: Text.isText, split: true },
        );
    };
    editor.isBlockActive = (type) => {
        const match = Editor.nodes(editor, {
            match: (n) =>
                !Editor.isEditor(n) && isSlateElement(n) && n.type === type,
        }).next().value;
        return !!match;
    };
    editor.toggleList = (marker) => {
        const isInList = editor.isBlockActive('list');
        Transforms.unwrapNodes(editor, {
            match: (n) =>
                !Editor.isEditor(n) && isSlateElement(n) && n.type === 'list',
            split: true,
        });
        Transforms.setNodes(editor, {
            type: 'paragraph',
        });
        if (!isInList) {
            Transforms.wrapNodes(editor, {
                type: 'list',
                marker,
                children: [],
            });
        }
    };

    editor.insertPartnerRef = (id: string) => {
        if (!id) {
            return;
        }

        const isEmptyText = !!Editor.nodes(editor, {
            match: (n) => Text.isText(n) && n.text.trim().length === 0,
            universal: true,
        }).next().value;

        const node: Node = {
            type: 'partner_ref',
            id,
            children: [
                {
                    text: '',
                    color: 0,
                    bold: false,
                    italic: false,
                },
            ],
        };
        if (isEmptyText) {
            Transforms.wrapNodes(editor, node);
        } else {
            Transforms.insertNodes(editor, node);
        }
    };

    return editor;
};
