import { createCrudSlice, CrudSlice } from '../crud-module/store';
import {
    ChallengePreview,
    ChallengeType,
    CoreIndustry,
    GetChallengeResponse,
    GetChallengesResponse,
} from '../api/model/core';
import { AppState } from '../redux/AppStore';
import { PostChallengePreviewRequest } from '../api/model/backoffice';
import { ulid } from 'ulid';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { snackbarActions } from '../snackbar/store';

export const challengeModule: CrudSlice<ChallengePreview> =
    createCrudSlice<ChallengePreview>({
        name: 'challenge',
        baseUrl: '/challenge',
        localClientBasePath: '/challenge',
        stateProvider: (s) => (s as AppState).challenge,
        listResponseParser: (data) => {
            const res = GetChallengesResponse.fromJson(data);
            return {
                items: res.challenges,
                nextPageToken: '',
            };
        },
        singleResponseParser: (data) => ({
            item: GetChallengeResponse.fromJson(data).challenge,
        }),
        writeArgumentTransformer: (challenge) =>
            PostChallengePreviewRequest.toJson({ challenge }),
        idProvider: ({ id }) => id,
        prepareEmpty: () => ({
            id: ulid(),
            name: '',
            coreIndustry: CoreIndustry.UnknownIndustry,
            type: ChallengeType.ChallengeTypeUnknown,
            category: '',
            description: '',
            iconName: '',
            keyIdeas: ['', '', ''],
            image: undefined,
            tagLine: '',
            award: { amount: 0 },
            partnerIds: [],
            createdAt: undefined,
            createdBy: '',
            modifiedAt: undefined,
            modifiedBy: '',
            publishedAt: undefined,
            publishedBy: '',
            estimatedSavings: undefined,
            recommendedPotentials: [],
            requiredPotentials: [],
            forbiddenPotentials: [],
            active: false,
            blogReferenceIds: [],
            tipIds: [],
        }),
    });

export const challengeReducer = challengeModule.reducer;
export const challengeActions = challengeModule.actions;
export const challengeSelectors = challengeModule.selectors;

export const copyChallengeDeepLink = createAsyncThunk(
    'challenge/copyDeepLink',
    async (args: { id: string }, { dispatch }) => {
        const deepLink = `/challenge?challenge=${args.id}`;
        await navigator.clipboard.writeText(deepLink);

        dispatch(
            snackbarActions.sendMessage({
                title: 'DeepLink copied to clipboard',
                subtitle: deepLink,
            }),
        );
    },
);
