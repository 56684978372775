import { Button } from '../common/Button';
import { Transition } from '@headlessui/react';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { assessmentFetchingActions } from './store';
import React from 'react';

export default function AssessmentFetchingButton() {
    const dispatch = useAppDispatch();

    const isFetching = useAppSelector(
        (state) => state.assessmentFetching.isFetching,
    );
    const fetchErr = useAppSelector(
        (state) => state.assessmentFetching.fetchError,
    );

    const onTriggerFetch = (overwrite: boolean) =>
        dispatch(
            assessmentFetchingActions.fetchAssessmentsFromSheet(overwrite),
        );

    return (
        <>
            <ResultDialog />
            <div className="flex flex-col space-y-2">
                <Button
                    primary
                    label={isFetching ? 'Fetching...' : 'Fetch'}
                    onClick={
                        isFetching ? undefined : () => onTriggerFetch(false)
                    }
                />
                <Button
                    primary
                    label={isFetching ? 'Fetching...' : 'Fetch and overwrite'}
                    onClick={
                        isFetching ? undefined : () => onTriggerFetch(true)
                    }
                />
            </div>
            {fetchErr && (
                <div className="bg-red-100 rounded-md border border-red-300 text-red-900 px-4 py-2 mt-8">
                    Error fetching: {fetchErr.message}
                </div>
            )}
        </>
    );
}

function ResultDialog() {
    const dispatch = useAppDispatch();
    const res = useAppSelector((state) => state.assessmentFetching.res);

    const visible = res !== undefined;

    const onClose = () => dispatch(assessmentFetchingActions.dismissResult());

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto pointer-events-none">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition
                    show={visible}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-gray-500 opacity-75" />
                </Transition>
                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <Transition
                    show={visible}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full pointer-events-auto"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6">
                        <div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left space-y-8">
                            <h3
                                className="text-lg leading-6 font-medium text-gray-900"
                                id="modal-headline"
                            >
                                <span className="text-gray-700">
                                    Fetch Result
                                </span>
                            </h3>
                            {res && (
                                <div className="flex flex-col space-y-2 h-128 overflow-y-auto">
                                    <span>
                                        Number of rows processed:{' '}
                                        {res.numFetched}
                                    </span>
                                    <span>
                                        New assessments stored: {res.numWritten}
                                    </span>
                                    {res.partnersWithoutIds.length > 0 && (
                                        <>
                                            <span>
                                                Partner without IDs in Sheet:
                                            </span>
                                            <ul>
                                                {res.partnersWithoutIds.map(
                                                    (p) => (
                                                        <li key={p}>{p}</li>
                                                    ),
                                                )}
                                            </ul>
                                        </>
                                    )}
                                </div>
                            )}

                            <Button secondary label="Close" onClick={onClose} />
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
}
