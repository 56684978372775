import { FunctionComponent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { lotteryDrawingActions, lotteryDrawingSelectors } from './store';
import { Transition } from '@headlessui/react';
import { Button } from '../common/Button';
import LoadingSpinner from '../common/LoadingSpinner';
import cx from 'classnames';
import LotteryUserAccountDisplay from './LotteryUserAccountDisplay';

const LotteryDrawingDialog: FunctionComponent = () => {
    const visible = useAppSelector(lotteryDrawingSelectors.isInDrawingMode);
    const item = useAppSelector(
        lotteryDrawingSelectors.getPriceDrawingInDrawingMode,
    );
    const drawnTickets = useAppSelector(
        lotteryDrawingSelectors.getDrawnTickets,
    );
    const isDrawing = useAppSelector(lotteryDrawingSelectors.isDrawingTickets);
    const excludedTicketIds = useAppSelector(
        lotteryDrawingSelectors.getExcludedTicketIds,
    );

    const dispatch = useAppDispatch();

    const [numTickets, setNumTickets] = useState(1);

    const onDraw = () => {
        dispatch(lotteryDrawingActions.drawRandomTickets({ numTickets }));
    };

    const excludeTicket = (id: string) => {
        dispatch(lotteryDrawingActions.excludeTicket(id));
    };

    const onClose = () => {
        dispatch(lotteryDrawingActions.exitDrawingMode());
    };

    const onSelectWinners = () => {
        dispatch(lotteryDrawingActions.setWinnerTickets());
    };

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto pointer-events-none">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition
                    show={visible}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-gray-500 opacity-75" />
                </Transition>
                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <Transition
                    show={visible}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full pointer-events-auto"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6">
                        <div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left space-y-8">
                            <h3
                                className="text-lg leading-6 font-medium text-gray-900"
                                id="modal-headline"
                            >
                                <span className="text-gray-700">
                                    Price Drawing
                                </span>
                                <br />
                                {item?.title ?? ''}
                            </h3>

                            <div className="flex space-x-2">
                                <span>Number of Tickets to draw:</span>
                                <div className="flex space-x-2 rounded bg-gray-100 px-2">
                                    <button
                                        onClick={() => {
                                            if (numTickets <= 1) {
                                                setNumTickets(1);
                                            } else {
                                                setNumTickets(numTickets - 1);
                                            }
                                        }}
                                    >
                                        -
                                    </button>
                                    <span>{numTickets}</span>
                                    <button
                                        onClick={() => {
                                            setNumTickets(numTickets + 1);
                                        }}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>

                            {isDrawing && <LoadingSpinner />}
                            <div className="flex flex-col space-y-2 overflow-y-auto">
                                {drawnTickets.map((ticket) => (
                                    <div
                                        key={ticket.id}
                                        className={cx(
                                            'flex flex-row justify-between items-center bg-gray-50 px-2 py-4',
                                            {
                                                'opacity-50': new Set(
                                                    excludedTicketIds,
                                                ).has(ticket.id),
                                            },
                                        )}
                                    >
                                        <div
                                            key={ticket.id}
                                            className="flex flex-col space-y-2"
                                        >
                                            <span className="font-mono">
                                                {ticket.id}
                                            </span>
                                            <LotteryUserAccountDisplay
                                                uid={ticket.ownerId}
                                            />
                                        </div>
                                        {!new Set(excludedTicketIds).has(
                                            ticket.id,
                                        ) && (
                                            <Button
                                                label="Exclude"
                                                secondary
                                                onClick={() =>
                                                    excludeTicket(ticket.id)
                                                }
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>

                            {drawnTickets.length > 0 && (
                                <Button
                                    primary
                                    label="Select as Winners"
                                    onClick={onSelectWinners}
                                />
                            )}

                            {!isDrawing && (
                                <Button
                                    primary
                                    label="Draw Tickets"
                                    onClick={onDraw}
                                />
                            )}

                            <Button secondary label="Close" onClick={onClose} />
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
};

export default LotteryDrawingDialog;
