import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Button } from '../common/Button';
import { TextTableCell } from '../common/Table';
import ItemList from '../crud-module/ItemList';
import { copyTipDeepLink, tipModule } from './store';
import TipCategoriesEditor from './TipCategoryEditor';
import TipPreviewForm from './TipPreviewForm';
import { TipQuizEditor } from './TipQuizEditor';
import TipSlideEditor from './TipSlideEditor';
import { useAppDispatch } from '../redux/react';

const TipModule: FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const copyDeepLink = (tipId: string) =>
        dispatch(copyTipDeepLink({ tipId }));

    return (
        <Switch>
            <Route path="/tip" exact>
                <ItemList
                    module={tipModule}
                    listActions={[
                        { label: 'Edit Categories', to: '/tip/categories' },
                    ]}
                    tableHeaders={['Name', 'Award', 'Actions']}
                >
                    {(item) => [
                        <TextTableCell
                            primary={item.title}
                            secondary={item.tagline}
                        />,
                        <TextTableCell primary={item.award?.amount ?? 0} />,
                        <div className="inline-flex">
                            <Button
                                label="Edit"
                                secondary
                                to={`/tip/${item.id}/edit`}
                            />
                            <Button
                                label="Slide Editor"
                                secondary
                                to={`/tip/${item.id}/slides`}
                            />
                            <Button
                                label="Quiz Master"
                                secondary
                                to={`/tip/${item.id}/quiz`}
                            />
                            <Button
                                label="Copy DeepLink"
                                secondary
                                onClick={() => copyDeepLink(item.id)}
                            />
                        </div>,
                    ]}
                </ItemList>
            </Route>
            <Route path="/tip/create" exact>
                <TipPreviewForm id={undefined} />
            </Route>
            <Route path="/tip/categories" exact>
                <TipCategoriesEditor />
            </Route>
            <Route
                path="/tip/:id/edit"
                exact
                render={({ match }) => (
                    <TipPreviewForm id={match.params['id']} />
                )}
            />
            <Route
                path="/tip/:id/slides"
                exact
                render={({ match }) => (
                    <TipSlideEditor tipID={match.params['id']} />
                )}
            />

            <Route
                path="/tip/:id/quiz"
                exact
                render={({ match }) => (
                    <TipQuizEditor tipID={match.params['id']} />
                )}
            />
        </Switch>
    );
};

export default TipModule;
