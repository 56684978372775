import { useRecoilValue } from 'recoil';
import { periodsQuery } from './store';
import React from 'react';
import * as common from '../api/model/common';
import {
    Breadcrumbs,
    BreadcrumbsItem,
    Button,
    DynamicPage,
    DynamicPageTitle,
    FlexBox,
    Label,
    TableCell,
    TableColumn,
    TableRow,
    Title,
} from '@ui5/webcomponents-react';
import { Table } from '@ui5/webcomponents-react';
import { useHistory } from 'react-router-dom';

export default function BillingPeriodsOverview() {
    const periods = useRecoilValue(periodsQuery);
    const history = useHistory();
    return (
        <DynamicPage
            headerTitle={
                <DynamicPageTitle
                    breadcrumbs={
                        <Breadcrumbs>
                            <BreadcrumbsItem>Billing</BreadcrumbsItem>
                        </Breadcrumbs>
                    }
                    header={<Title>Billing</Title>}
                />
            }
        >
            <Table
                columns={
                    <>
                        <TableColumn>
                            <Label>Period</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Zeitraum</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Aktionen</Label>
                        </TableColumn>
                    </>
                }
            >
                {periods.map((p) => (
                    <TableRow key={p.code}>
                        <TableCell>
                            <Label>{p.code}</Label>
                        </TableCell>
                        <TableCell>
                            {formatDate(p.startsAt!)} - {formatDate(p.endsAt!)}
                        </TableCell>
                        <TableCell>
                            <FlexBox>
                                <Button
                                    icon="arrow-right"
                                    onClick={() =>
                                        history.push(`/billing/${p.code}`)
                                    }
                                />
                            </FlexBox>
                        </TableCell>
                    </TableRow>
                ))}
            </Table>
        </DynamicPage>
    );
}

export function formatDate(d: common.Date) {
    if (d.year > 0 && d.month === 0 && d.day === 0) {
        return `${d.year}`;
    }
    if (d.year > 0 && d.month > 0 && d.day === 0) {
        return `${d.year}-${d.month}`;
    }
    return `${d.year}-${d.month > 9 ? d.month : '0' + d.month}-${
        d.day > 9 ? d.day : '0' + d.day
    }`;
}
