// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "report/rpc/report.proto" (package "one.plan3t.core.report.rpc", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message one.plan3t.core.report.rpc.ReportDefinition
 */
export interface ReportDefinition {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: repeated one.plan3t.core.report.rpc.ReportDefinition.Argument arguments = 2;
     */
    arguments: ReportDefinition_Argument[];
}
/**
 * @generated from protobuf message one.plan3t.core.report.rpc.ReportDefinition.Argument
 */
export interface ReportDefinition_Argument {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string;
}
/**
 * @generated from protobuf message one.plan3t.core.report.rpc.GetAvailableReportsRequest
 */
export interface GetAvailableReportsRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.report.rpc.GetAvailableReportsResponse
 */
export interface GetAvailableReportsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.report.rpc.ReportDefinition report_definitions = 1;
     */
    reportDefinitions: ReportDefinition[];
}
/**
 * @generated from protobuf message one.plan3t.core.report.rpc.GetReportRequest
 */
export interface GetReportRequest {
    /**
     * @generated from protobuf field: one.plan3t.core.report.rpc.ReportDefinition definition = 1;
     */
    definition?: ReportDefinition;
}
/**
 * @generated from protobuf message one.plan3t.core.report.rpc.GetReportResponse
 */
export interface GetReportResponse {
    /**
     * @generated from protobuf field: repeated string header = 1;
     */
    header: string[];
    /**
     * @generated from protobuf field: repeated one.plan3t.core.report.rpc.GetReportResponse.Row rows = 2;
     */
    rows: GetReportResponse_Row[];
}
/**
 * @generated from protobuf message one.plan3t.core.report.rpc.GetReportResponse.Row
 */
export interface GetReportResponse_Row {
    /**
     * @generated from protobuf field: repeated string fields = 1;
     */
    fields: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.report.rpc.SendRewardsExpiringSlackReminderRequest
 */
export interface SendRewardsExpiringSlackReminderRequest {
    /**
     * @generated from protobuf field: string channel_id = 1;
     */
    channelId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.report.rpc.SendRewardsExpiringSlackReminderResponse
 */
export interface SendRewardsExpiringSlackReminderResponse {
}
// @generated message type with reflection information, may provide speed optimized methods
class ReportDefinition$Type extends MessageType<ReportDefinition> {
    constructor() {
        super("one.plan3t.core.report.rpc.ReportDefinition", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "arguments", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ReportDefinition_Argument }
        ]);
    }
    create(value?: PartialMessage<ReportDefinition>): ReportDefinition {
        const message = { id: "", arguments: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReportDefinition>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReportDefinition): ReportDefinition {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* repeated one.plan3t.core.report.rpc.ReportDefinition.Argument arguments */ 2:
                    message.arguments.push(ReportDefinition_Argument.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReportDefinition, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* repeated one.plan3t.core.report.rpc.ReportDefinition.Argument arguments = 2; */
        for (let i = 0; i < message.arguments.length; i++)
            ReportDefinition_Argument.internalBinaryWrite(message.arguments[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.report.rpc.ReportDefinition
 */
export const ReportDefinition = new ReportDefinition$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReportDefinition_Argument$Type extends MessageType<ReportDefinition_Argument> {
    constructor() {
        super("one.plan3t.core.report.rpc.ReportDefinition.Argument", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReportDefinition_Argument>): ReportDefinition_Argument {
        const message = { name: "", value: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReportDefinition_Argument>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReportDefinition_Argument): ReportDefinition_Argument {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReportDefinition_Argument, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.report.rpc.ReportDefinition.Argument
 */
export const ReportDefinition_Argument = new ReportDefinition_Argument$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAvailableReportsRequest$Type extends MessageType<GetAvailableReportsRequest> {
    constructor() {
        super("one.plan3t.core.report.rpc.GetAvailableReportsRequest", []);
    }
    create(value?: PartialMessage<GetAvailableReportsRequest>): GetAvailableReportsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAvailableReportsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAvailableReportsRequest): GetAvailableReportsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetAvailableReportsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.report.rpc.GetAvailableReportsRequest
 */
export const GetAvailableReportsRequest = new GetAvailableReportsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAvailableReportsResponse$Type extends MessageType<GetAvailableReportsResponse> {
    constructor() {
        super("one.plan3t.core.report.rpc.GetAvailableReportsResponse", [
            { no: 1, name: "report_definitions", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ReportDefinition }
        ]);
    }
    create(value?: PartialMessage<GetAvailableReportsResponse>): GetAvailableReportsResponse {
        const message = { reportDefinitions: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAvailableReportsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAvailableReportsResponse): GetAvailableReportsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.report.rpc.ReportDefinition report_definitions */ 1:
                    message.reportDefinitions.push(ReportDefinition.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAvailableReportsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.report.rpc.ReportDefinition report_definitions = 1; */
        for (let i = 0; i < message.reportDefinitions.length; i++)
            ReportDefinition.internalBinaryWrite(message.reportDefinitions[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.report.rpc.GetAvailableReportsResponse
 */
export const GetAvailableReportsResponse = new GetAvailableReportsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetReportRequest$Type extends MessageType<GetReportRequest> {
    constructor() {
        super("one.plan3t.core.report.rpc.GetReportRequest", [
            { no: 1, name: "definition", kind: "message", T: () => ReportDefinition }
        ]);
    }
    create(value?: PartialMessage<GetReportRequest>): GetReportRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetReportRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetReportRequest): GetReportRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.report.rpc.ReportDefinition definition */ 1:
                    message.definition = ReportDefinition.internalBinaryRead(reader, reader.uint32(), options, message.definition);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetReportRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.report.rpc.ReportDefinition definition = 1; */
        if (message.definition)
            ReportDefinition.internalBinaryWrite(message.definition, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.report.rpc.GetReportRequest
 */
export const GetReportRequest = new GetReportRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetReportResponse$Type extends MessageType<GetReportResponse> {
    constructor() {
        super("one.plan3t.core.report.rpc.GetReportResponse", [
            { no: 1, name: "header", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "rows", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GetReportResponse_Row }
        ]);
    }
    create(value?: PartialMessage<GetReportResponse>): GetReportResponse {
        const message = { header: [], rows: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetReportResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetReportResponse): GetReportResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string header */ 1:
                    message.header.push(reader.string());
                    break;
                case /* repeated one.plan3t.core.report.rpc.GetReportResponse.Row rows */ 2:
                    message.rows.push(GetReportResponse_Row.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetReportResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string header = 1; */
        for (let i = 0; i < message.header.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.header[i]);
        /* repeated one.plan3t.core.report.rpc.GetReportResponse.Row rows = 2; */
        for (let i = 0; i < message.rows.length; i++)
            GetReportResponse_Row.internalBinaryWrite(message.rows[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.report.rpc.GetReportResponse
 */
export const GetReportResponse = new GetReportResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetReportResponse_Row$Type extends MessageType<GetReportResponse_Row> {
    constructor() {
        super("one.plan3t.core.report.rpc.GetReportResponse.Row", [
            { no: 1, name: "fields", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetReportResponse_Row>): GetReportResponse_Row {
        const message = { fields: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetReportResponse_Row>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetReportResponse_Row): GetReportResponse_Row {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string fields */ 1:
                    message.fields.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetReportResponse_Row, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string fields = 1; */
        for (let i = 0; i < message.fields.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.fields[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.report.rpc.GetReportResponse.Row
 */
export const GetReportResponse_Row = new GetReportResponse_Row$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendRewardsExpiringSlackReminderRequest$Type extends MessageType<SendRewardsExpiringSlackReminderRequest> {
    constructor() {
        super("one.plan3t.core.report.rpc.SendRewardsExpiringSlackReminderRequest", [
            { no: 1, name: "channel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SendRewardsExpiringSlackReminderRequest>): SendRewardsExpiringSlackReminderRequest {
        const message = { channelId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SendRewardsExpiringSlackReminderRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendRewardsExpiringSlackReminderRequest): SendRewardsExpiringSlackReminderRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string channel_id */ 1:
                    message.channelId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendRewardsExpiringSlackReminderRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string channel_id = 1; */
        if (message.channelId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.channelId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.report.rpc.SendRewardsExpiringSlackReminderRequest
 */
export const SendRewardsExpiringSlackReminderRequest = new SendRewardsExpiringSlackReminderRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendRewardsExpiringSlackReminderResponse$Type extends MessageType<SendRewardsExpiringSlackReminderResponse> {
    constructor() {
        super("one.plan3t.core.report.rpc.SendRewardsExpiringSlackReminderResponse", []);
    }
    create(value?: PartialMessage<SendRewardsExpiringSlackReminderResponse>): SendRewardsExpiringSlackReminderResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SendRewardsExpiringSlackReminderResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendRewardsExpiringSlackReminderResponse): SendRewardsExpiringSlackReminderResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: SendRewardsExpiringSlackReminderResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.report.rpc.SendRewardsExpiringSlackReminderResponse
 */
export const SendRewardsExpiringSlackReminderResponse = new SendRewardsExpiringSlackReminderResponse$Type();
/**
 * @generated ServiceType for protobuf service one.plan3t.core.report.rpc.Report
 */
export const Report = new ServiceType("one.plan3t.core.report.rpc.Report", [
    { name: "GetAvailableReports", options: {}, I: GetAvailableReportsRequest, O: GetAvailableReportsResponse },
    { name: "GetReport", options: {}, I: GetReportRequest, O: GetReportResponse },
    { name: "SendRewardsExpiringSlackReminder", options: {}, I: SendRewardsExpiringSlackReminderRequest, O: SendRewardsExpiringSlackReminderResponse }
]);
