import axios from 'axios';
import React, { FunctionComponent, useMemo, useState } from 'react';
import { Button } from '../common/Button';
import { Form, Formik } from 'formik';
import { FormSelectField } from '../common/FormFields';

// Sun Nov 01 2020
const FIRST_PAYMENT = new Date(2020, 10, 1);

const generateTimestamps = () => {
    const toStartOfMonth = (d: Date) =>
        new Date(d.getFullYear(), d.getMonth(), 1);

    const firstDay = toStartOfMonth(FIRST_PAYMENT);

    const v = (d: Date) => {
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }
        return `${year}-${month}-${day}`;
    };
    const l = (d: Date) =>
        `${d.getFullYear()} ${d.toLocaleString('default', { month: 'long' })}`;

    const limit = toStartOfMonth(new Date());
    let next = firstDay;

    let i = 0;
    const startOfMonths = [];
    while (next < limit && i < 1000) {
        i++;
        startOfMonths.push(next);
        next = new Date(next);
        next.setMonth(next.getMonth() + 1);
    }

    return startOfMonths
        .map((d) => ({
            value: v(d),
            label: l(d),
        }))
        .reverse();
};

const DATEVExport: FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState(false);
    const options = useMemo(() => generateTimestamps(), []);
    const onSubmit = async (v: { start: string }) => {
        setIsLoading(true);
        try {
            const res = await axios.get(
                '/payment/export/datev?start=' + v.start,
            );

            const element = document.createElement('a');
            element.setAttribute(
                'href',
                'data:text/csv;charset=utf-8,' + encodeURIComponent(res.data),
            );
            element.setAttribute(
                'download',
                `datev-export-${v.start.substr(0, 7)}.csv`,
            );
            element.style.display = 'none';
            document.body.appendChild(element);

            try {
                element.click();
            } finally {
                document.body.removeChild(element);
            }
        } finally {
            setIsLoading(false);
        }
    };

    console.log(options);

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{
                start: options[0].value,
            }}
            enableReinitialize
        >
            <Form className="container py-6">
                <h1 className="font-medium text-lg">DATEV Export</h1>
                <div className="flex flex-row items-center max-w-lg space-x-4">
                    <div className="flex">
                        <FormSelectField
                            name="start"
                            label="Select Month"
                            options={options}
                        />
                    </div>
                    <div className="flex justify-start">
                        <Button
                            primary
                            label={isLoading ? 'Working...' : 'Generate Export'}
                            type="submit"
                            disabled={isLoading}
                        />
                    </div>
                </div>
            </Form>
        </Formik>
    );
};

export default DATEVExport;
