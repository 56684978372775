import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Button } from '../common/Button';
import { TextTableCell } from '../common/Table';
import ItemList from '../crud-module/ItemList';
import { cashbackTicketModule } from './store';
import { Timestamp } from '../api/google/protobuf/timestamp';
import PartnerName from '../partner/PartnerName';
import CashbackTicketInspector from './CashbackTicketInspector';
import { MissingCashbackState } from '../api/model/backoffice';
import CashbackApplier from './CashbackApplier';
import { ObjectStatus, ValueState } from '@ui5/webcomponents-react';
import CashbackExternal from './CashbackExternal';

const CashbackTicketModule: FunctionComponent = () => {
    return (
        <Switch>
            <Route path="/cashback/ticket" exact>
                <ItemList
                    module={cashbackTicketModule}
                    tableHeaders={['Purchase', 'Timestamp', 'Actions']}
                >
                    {(item) => {
                        return [
                            <TextTableCell
                                primary={
                                    <>
                                        <PartnerName id={item.partnerId} />
                                        &nbsp;
                                        <span className="text-gray-600">
                                            (
                                            {(
                                                item.amountUnscaled /
                                                Math.pow(10, item.amountScale)
                                            ).toFixed(2)}
                                            )
                                        </span>
                                    </>
                                }
                                secondary={<StateDisplay state={item.state} />}
                            />,
                            <TextTableCell
                                primary={Timestamp.toDate(
                                    item.purchaseDate!,
                                ).toDateString()}
                                secondary={`Reported on ${Timestamp.toDate(
                                    item.createdAt!,
                                ).toDateString()}`}
                            />,
                            <div className="inline-flex">
                                <Button
                                    label="Inspect"
                                    secondary
                                    to={`/cashback/ticket/${item.id}/inspect`}
                                />
                            </div>,
                        ];
                    }}
                </ItemList>
            </Route>
            <Route
                path="/cashback/ticket/:id/inspect"
                exact
                render={({ match }) => (
                    <CashbackTicketInspector ticketID={match.params['id']} />
                )}
            />
            <Route path="/cashback/ticket/apply" exact>
                <CashbackApplier />
            </Route>
            <Route path="/cashback/ticket/external" exact>
                <CashbackExternal />
            </Route>
        </Switch>
    );
};

export default CashbackTicketModule;

function StateDisplay(props: { state: MissingCashbackState }) {
    switch (props.state) {
        case MissingCashbackState.MISSING_CASHBACK_TICKET_STATE_OPEN:
            return (
                <ObjectStatus showDefaultIcon state={ValueState.Warning}>
                    Open
                </ObjectStatus>
            );
        case MissingCashbackState.MISSING_CASHBACK_TICKET_STATE_ON_HOLD:
            return (
                <ObjectStatus showDefaultIcon state={ValueState.Warning}>
                    OnHold
                </ObjectStatus>
            );
        case MissingCashbackState.MISSING_CASHBACK_TICKET_STATE_SOLVED:
            return (
                <ObjectStatus showDefaultIcon state={ValueState.Success}>
                    Solved
                </ObjectStatus>
            );
        case MissingCashbackState.MISSING_CASHBACK_TICKET_STATE_REJECTED:
            return (
                <ObjectStatus showDefaultIcon state={ValueState.Success}>
                    Rejected
                </ObjectStatus>
            );
        default:
            return (
                <ObjectStatus showDefaultIcon state={ValueState.Error}>
                    Unknown State
                </ObjectStatus>
            );
    }
}
