import React from 'react';
import { Switch, Route } from 'react-router-dom';
import ChallengeList from './ChallengeList';
import ChallengeForm from './ChallengeForm';
import ChallengeCategoriesEditor from './ChallengeCategoryEditor';

const ChallengeModule: React.FunctionComponent = () => {
    return (
        <Switch>
            <Route path="/challenge" exact>
                <ChallengeList />
            </Route>
            <Route path="/challenge/create" exact>
                <ChallengeForm id={undefined} />
            </Route>
            <Route path="/challenge/categories" exact>
                <ChallengeCategoriesEditor />
            </Route>
            <Route
                path="/challenge/:id/edit"
                exact
                render={({ match }) => (
                    <ChallengeForm id={match.params['id']} />
                )}
            />
        </Switch>
    );
};

export default ChallengeModule;
