import LoadingSpinner from '../common/LoadingSpinner';
import { Route, Switch, useHistory } from 'react-router-dom';
import React, { Suspense } from 'react';
import {
    Button,
    DynamicPage,
    DynamicPageTitle,
    Title,
} from '@ui5/webcomponents-react';
import LedgerTrialBalance from './LedgerTrialBalance';
import LedgerTransactionList from './LedgerTransactionList';
import { usePerformSplitBalanceCalculations } from '../accounting/SplitBalanceQueue';

export default function LedgerModule() {
    const { working, perform } = usePerformSplitBalanceCalculations();
    const history = useHistory();
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <Switch>
                <Route path="/ledger" exact>
                    <DynamicPage
                        headerTitle={
                            <DynamicPageTitle
                                header={<Title>Ledger</Title>}
                                actions={
                                    <>
                                        <Button onClick={perform}>
                                            {working ? (
                                                <LoadingSpinner />
                                            ) : (
                                                <>Process Split Balance Queue</>
                                            )}
                                        </Button>
                                    </>
                                }
                                navigationActions={
                                    <>
                                        <Button
                                            icon="compare"
                                            onClick={() =>
                                                history.push(
                                                    '/accounting/split-balance-queue',
                                                )
                                            }
                                        />
                                    </>
                                }
                            />
                        }
                    >
                        <LedgerTrialBalance />
                        <LedgerTransactionList />
                    </DynamicPage>
                </Route>
            </Switch>
        </Suspense>
    );
}
