// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "model/feed.proto" (package "one.plan3t.core.model", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Date } from "./common";
import { Plan3TPoint } from "./core";
import { ImageResource } from "./core";
import { Timestamp } from "../google/protobuf/timestamp";
/**
 * @generated from protobuf message one.plan3t.core.model.FeedItem
 */
export interface FeedItem {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: optional string user_id = 2;
     */
    userId?: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.FeedItemType type = 3;
     */
    type: FeedItemType;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timestamp = 4;
     */
    timestamp?: Timestamp;
    /**
     * @generated from protobuf field: optional google.protobuf.Timestamp expiry = 5;
     */
    expiry?: Timestamp;
    /**
     * @generated from protobuf oneof: data
     */
    data: {
        oneofKind: "contentFeedItemData";
        /**
         * @generated from protobuf field: one.plan3t.core.model.ContentFeedItemData content_feed_item_data = 6;
         */
        contentFeedItemData: ContentFeedItemData;
    } | {
        oneofKind: "todoFeedItemData";
        /**
         * @generated from protobuf field: one.plan3t.core.model.ToDoFeedItemData todo_feed_item_data = 7;
         */
        todoFeedItemData: ToDoFeedItemData;
    } | {
        oneofKind: "compensationFeedItemData";
        /**
         * @generated from protobuf field: one.plan3t.core.model.CompensationFeedItemData compensation_feed_item_data = 8;
         */
        compensationFeedItemData: CompensationFeedItemData;
    } | {
        oneofKind: "startedChallengesFeedItemData";
        /**
         * @generated from protobuf field: one.plan3t.core.model.StartedChallengesFeedItemData started_challenges_feed_item_data = 9;
         */
        startedChallengesFeedItemData: StartedChallengesFeedItemData;
    } | {
        oneofKind: "claimedChallengesFeedItemData";
        /**
         * @generated from protobuf field: one.plan3t.core.model.ClaimedChallengesFeedItemData claimed_challenges_feed_item_data = 10;
         */
        claimedChallengesFeedItemData: ClaimedChallengesFeedItemData;
    } | {
        oneofKind: "onboardingFeedItemData";
        /**
         * @generated from protobuf field: one.plan3t.core.model.OnboardingFeedItemData onboarding_feed_item_data = 11;
         */
        onboardingFeedItemData: OnboardingFeedItemData;
    } | {
        oneofKind: "compensateWheelSpinFeedItemData";
        /**
         * @generated from protobuf field: one.plan3t.core.model.CompensateWheelSpinFeedItemData compensate_wheel_spin_feed_item_data = 12;
         */
        compensateWheelSpinFeedItemData: CompensateWheelSpinFeedItemData;
    } | {
        oneofKind: "lotteryDrawingFeedItemData";
        /**
         * @generated from protobuf field: one.plan3t.core.model.LotteryDrawingFeedItemData lottery_drawing_feed_item_data = 13;
         */
        lotteryDrawingFeedItemData: LotteryDrawingFeedItemData;
    } | {
        oneofKind: "cashbackFeedItemData";
        /**
         * @generated from protobuf field: one.plan3t.core.model.CashbackFeedItemData cashback_feed_item_data = 14;
         */
        cashbackFeedItemData: CashbackFeedItemData;
    } | {
        oneofKind: "impactReportFeedItemData";
        /**
         * @generated from protobuf field: one.plan3t.core.model.ImpactReportFeedItemData impact_report_feed_item_data = 15;
         */
        impactReportFeedItemData: ImpactReportFeedItemData;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message one.plan3t.core.model.ContentFeedItemData
 */
export interface ContentFeedItemData {
    /**
     * @generated from protobuf field: one.plan3t.core.model.ImageResource image = 1;
     */
    image?: ImageResource;
    /**
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * @generated from protobuf field: string subtitle = 3;
     */
    subtitle: string;
    /**
     * @generated from protobuf field: string route = 4;
     */
    route: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ContentFeedItemStyle style = 5;
     */
    style: ContentFeedItemStyle;
    /**
     * @generated from protobuf field: string partner_id = 6;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.ContentFeedItemData.Indicator indicator = 7;
     */
    indicator?: ContentFeedItemData_Indicator;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ContentFeedItemData.Indicator
 */
export interface ContentFeedItemData_Indicator {
    /**
     * @generated from protobuf field: string icon_name = 1;
     */
    iconName: string;
    /**
     * @generated from protobuf field: string value = 2;
     */
    value: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ToDoFeedItemData
 */
export interface ToDoFeedItemData {
    /**
     * @generated from protobuf field: string title = 1;
     */
    title: string;
    /**
     * @generated from protobuf field: string route = 2;
     */
    route: string;
    /**
     * @generated from protobuf field: string icon = 3;
     */
    icon: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.CompensationFeedItemData
 */
export interface CompensationFeedItemData {
    /**
     * @generated from protobuf field: string project_id = 1;
     */
    projectId: string;
    /**
     * @generated from protobuf field: string purchase_id = 2;
     */
    purchaseId: string;
    /**
     * @generated from protobuf field: int32 amount_in_kg = 3;
     */
    amountInKg: number;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint points = 4;
     */
    points?: Plan3TPoint;
}
/**
 * @generated from protobuf message one.plan3t.core.model.OnboardingFeedItemData
 */
export interface OnboardingFeedItemData {
    /**
     * @generated from protobuf field: one.plan3t.core.model.OnboardingFeedItemType type = 1;
     */
    type: OnboardingFeedItemType;
    /**
     * @generated from protobuf field: string route = 2;
     */
    route: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.StartedChallengesFeedItemData
 */
export interface StartedChallengesFeedItemData {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp target_week = 1;
     */
    targetWeek?: Timestamp;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ClaimedChallengesFeedItemData
 */
export interface ClaimedChallengesFeedItemData {
    /**
     * @generated from protobuf field: google.protobuf.Timestamp target_week = 1;
     */
    targetWeek?: Timestamp;
    /**
     * @generated from protobuf field: repeated string challenge_ids = 2;
     */
    challengeIds: string[];
}
/**
 * @generated from protobuf message one.plan3t.core.model.CompensateWheelSpinFeedItemData
 */
export interface CompensateWheelSpinFeedItemData {
    /**
     * @generated from protobuf field: string compensate_wheel_spin_id = 1;
     */
    compensateWheelSpinId: string;
    /**
     * @generated from protobuf field: string project_id = 2;
     */
    projectId: string;
    /**
     * @generated from protobuf field: int32 amount_in_kg = 3;
     */
    amountInKg: number;
    /**
     * @generated from protobuf field: string compensate_wheel_id = 4;
     */
    compensateWheelId: string;
    /**
     * @generated from protobuf field: string compensate_wheel_outcome_id = 5;
     */
    compensateWheelOutcomeId: string;
    /**
     * @generated from protobuf field: string target_date = 6;
     */
    targetDate: string;
}
/**
 * @generated from protobuf message one.plan3t.core.model.LotteryDrawingFeedItemData
 */
export interface LotteryDrawingFeedItemData {
    /**
     * @generated from protobuf field: string lottery_price_drawing_id = 1;
     */
    lotteryPriceDrawingId: string;
    /**
     * @generated from protobuf field: bool success = 2;
     */
    success: boolean;
}
/**
 * @generated from protobuf message one.plan3t.core.model.CashbackFeedItemData
 */
export interface CashbackFeedItemData {
    /**
     * @generated from protobuf field: string transaction_id = 1;
     */
    transactionId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp transaction_timestamp = 2;
     */
    transactionTimestamp?: Timestamp;
    /**
     * @generated from protobuf field: string partner_id = 3;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: one.plan3t.core.model.Plan3TPoint points = 4;
     */
    points?: Plan3TPoint;
}
/**
 * @generated from protobuf message one.plan3t.core.model.ImpactReportFeedItemData
 */
export interface ImpactReportFeedItemData {
    /**
     * @generated from protobuf field: one.plan3t.core.model.Date date = 1;
     */
    date?: Date;
}
/**
 * @generated from protobuf enum one.plan3t.core.model.ContentFeedItemStyle
 */
export enum ContentFeedItemStyle {
    /**
     * @generated from protobuf enum value: CONTENT_FEED_ITEM_STYLE_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: CONTENT_FEED_ITEM_STYLE_SMALL = 1;
     */
    SMALL = 1,
    /**
     * @generated from protobuf enum value: CONTENT_FEED_ITEM_STYLE_LARGE = 2;
     */
    LARGE = 2
}
/**
 * @generated from protobuf enum one.plan3t.core.model.OnboardingFeedItemType
 */
export enum OnboardingFeedItemType {
    /**
     * @generated from protobuf enum value: ONBOARDING_FEED_ITEM_TYPE_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: ONBOARDING_FEED_ITEM_TYPE_EXPLANATION = 1;
     */
    EXPLANATION = 1,
    /**
     * @generated from protobuf enum value: ONBOARDING_FEED_ITEM_TYPE_ARROW_TODO = 2;
     */
    ARROW_TODO = 2,
    /**
     * @generated from protobuf enum value: ONBOARDING_FEED_ITEM_TYPE_TODO_PROFILING = 3;
     */
    TODO_PROFILING = 3,
    /**
     * @generated from protobuf enum value: ONBOARDING_FEED_ITEM_TYPE_TODO_ACCOUNT = 4;
     */
    TODO_ACCOUNT = 4,
    /**
     * @generated from protobuf enum value: ONBOARDING_FEED_ITEM_TYPE_ARROW_NEWS = 5;
     */
    ARROW_NEWS = 5
}
/**
 * @generated from protobuf enum one.plan3t.core.model.FeedItemType
 */
export enum FeedItemType {
    /**
     * @generated from protobuf enum value: FEED_ITEM_TYPE_UNKNOWN = 0;
     */
    UNKNOWN = 0,
    /**
     * @generated from protobuf enum value: FEED_ITEM_TYPE_CONTENT = 1;
     */
    CONTENT = 1,
    /**
     * @generated from protobuf enum value: FEED_ITEM_TYPE_TODO = 2;
     */
    TODO = 2,
    /**
     * @generated from protobuf enum value: FEED_ITEM_TYPE_COMPENSATION = 3;
     */
    COMPENSATION = 3,
    /**
     * @generated from protobuf enum value: FEED_ITEM_TYPE_STARTED_CHALLENGES = 4;
     */
    STARTED_CHALLENGES = 4,
    /**
     * @generated from protobuf enum value: FEED_ITEM_TYPE_CHALLENGE_CLAIMED = 5;
     */
    CHALLENGE_CLAIMED = 5,
    /**
     * @generated from protobuf enum value: FEED_ITEM_TYPE_ONBOARDING = 6;
     */
    ONBOARDING = 6,
    /**
     * @generated from protobuf enum value: FEED_ITEM_TYPE_COMPENSATE_WHEEL_SPIN = 7;
     */
    COMPENSATE_WHEEL_SPIN = 7,
    /**
     * @generated from protobuf enum value: FEED_ITEM_TYPE_LOTTERY_DRAWING = 8;
     */
    LOTTERY_DRAWING = 8,
    /**
     * @generated from protobuf enum value: FEED_ITEM_TYPE_CASHBACK = 9;
     */
    CASHBACK = 9,
    /**
     * @generated from protobuf enum value: FEED_ITEM_TYPE_IMPACT_REPORT = 10;
     */
    IMPACT_REPORT = 10
}
// @generated message type with reflection information, may provide speed optimized methods
class FeedItem$Type extends MessageType<FeedItem> {
    constructor() {
        super("one.plan3t.core.model.FeedItem", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "user_id", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "enum", T: () => ["one.plan3t.core.model.FeedItemType", FeedItemType, "FEED_ITEM_TYPE_"] },
            { no: 4, name: "timestamp", kind: "message", T: () => Timestamp },
            { no: 5, name: "expiry", kind: "message", T: () => Timestamp },
            { no: 6, name: "content_feed_item_data", kind: "message", oneof: "data", T: () => ContentFeedItemData },
            { no: 7, name: "todo_feed_item_data", kind: "message", oneof: "data", T: () => ToDoFeedItemData },
            { no: 8, name: "compensation_feed_item_data", kind: "message", oneof: "data", T: () => CompensationFeedItemData },
            { no: 9, name: "started_challenges_feed_item_data", kind: "message", oneof: "data", T: () => StartedChallengesFeedItemData },
            { no: 10, name: "claimed_challenges_feed_item_data", kind: "message", oneof: "data", T: () => ClaimedChallengesFeedItemData },
            { no: 11, name: "onboarding_feed_item_data", kind: "message", oneof: "data", T: () => OnboardingFeedItemData },
            { no: 12, name: "compensate_wheel_spin_feed_item_data", kind: "message", oneof: "data", T: () => CompensateWheelSpinFeedItemData },
            { no: 13, name: "lottery_drawing_feed_item_data", kind: "message", oneof: "data", T: () => LotteryDrawingFeedItemData },
            { no: 14, name: "cashback_feed_item_data", kind: "message", oneof: "data", T: () => CashbackFeedItemData },
            { no: 15, name: "impact_report_feed_item_data", kind: "message", oneof: "data", T: () => ImpactReportFeedItemData }
        ]);
    }
    create(value?: PartialMessage<FeedItem>): FeedItem {
        const message = { id: "", type: 0, data: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<FeedItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FeedItem): FeedItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* optional string user_id */ 2:
                    message.userId = reader.string();
                    break;
                case /* one.plan3t.core.model.FeedItemType type */ 3:
                    message.type = reader.int32();
                    break;
                case /* google.protobuf.Timestamp timestamp */ 4:
                    message.timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timestamp);
                    break;
                case /* optional google.protobuf.Timestamp expiry */ 5:
                    message.expiry = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.expiry);
                    break;
                case /* one.plan3t.core.model.ContentFeedItemData content_feed_item_data */ 6:
                    message.data = {
                        oneofKind: "contentFeedItemData",
                        contentFeedItemData: ContentFeedItemData.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).contentFeedItemData)
                    };
                    break;
                case /* one.plan3t.core.model.ToDoFeedItemData todo_feed_item_data */ 7:
                    message.data = {
                        oneofKind: "todoFeedItemData",
                        todoFeedItemData: ToDoFeedItemData.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).todoFeedItemData)
                    };
                    break;
                case /* one.plan3t.core.model.CompensationFeedItemData compensation_feed_item_data */ 8:
                    message.data = {
                        oneofKind: "compensationFeedItemData",
                        compensationFeedItemData: CompensationFeedItemData.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).compensationFeedItemData)
                    };
                    break;
                case /* one.plan3t.core.model.StartedChallengesFeedItemData started_challenges_feed_item_data */ 9:
                    message.data = {
                        oneofKind: "startedChallengesFeedItemData",
                        startedChallengesFeedItemData: StartedChallengesFeedItemData.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).startedChallengesFeedItemData)
                    };
                    break;
                case /* one.plan3t.core.model.ClaimedChallengesFeedItemData claimed_challenges_feed_item_data */ 10:
                    message.data = {
                        oneofKind: "claimedChallengesFeedItemData",
                        claimedChallengesFeedItemData: ClaimedChallengesFeedItemData.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).claimedChallengesFeedItemData)
                    };
                    break;
                case /* one.plan3t.core.model.OnboardingFeedItemData onboarding_feed_item_data */ 11:
                    message.data = {
                        oneofKind: "onboardingFeedItemData",
                        onboardingFeedItemData: OnboardingFeedItemData.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).onboardingFeedItemData)
                    };
                    break;
                case /* one.plan3t.core.model.CompensateWheelSpinFeedItemData compensate_wheel_spin_feed_item_data */ 12:
                    message.data = {
                        oneofKind: "compensateWheelSpinFeedItemData",
                        compensateWheelSpinFeedItemData: CompensateWheelSpinFeedItemData.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).compensateWheelSpinFeedItemData)
                    };
                    break;
                case /* one.plan3t.core.model.LotteryDrawingFeedItemData lottery_drawing_feed_item_data */ 13:
                    message.data = {
                        oneofKind: "lotteryDrawingFeedItemData",
                        lotteryDrawingFeedItemData: LotteryDrawingFeedItemData.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).lotteryDrawingFeedItemData)
                    };
                    break;
                case /* one.plan3t.core.model.CashbackFeedItemData cashback_feed_item_data */ 14:
                    message.data = {
                        oneofKind: "cashbackFeedItemData",
                        cashbackFeedItemData: CashbackFeedItemData.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).cashbackFeedItemData)
                    };
                    break;
                case /* one.plan3t.core.model.ImpactReportFeedItemData impact_report_feed_item_data */ 15:
                    message.data = {
                        oneofKind: "impactReportFeedItemData",
                        impactReportFeedItemData: ImpactReportFeedItemData.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).impactReportFeedItemData)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FeedItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* optional string user_id = 2; */
        if (message.userId !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.userId);
        /* one.plan3t.core.model.FeedItemType type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        /* google.protobuf.Timestamp timestamp = 4; */
        if (message.timestamp)
            Timestamp.internalBinaryWrite(message.timestamp, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* optional google.protobuf.Timestamp expiry = 5; */
        if (message.expiry)
            Timestamp.internalBinaryWrite(message.expiry, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.ContentFeedItemData content_feed_item_data = 6; */
        if (message.data.oneofKind === "contentFeedItemData")
            ContentFeedItemData.internalBinaryWrite(message.data.contentFeedItemData, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.ToDoFeedItemData todo_feed_item_data = 7; */
        if (message.data.oneofKind === "todoFeedItemData")
            ToDoFeedItemData.internalBinaryWrite(message.data.todoFeedItemData, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.CompensationFeedItemData compensation_feed_item_data = 8; */
        if (message.data.oneofKind === "compensationFeedItemData")
            CompensationFeedItemData.internalBinaryWrite(message.data.compensationFeedItemData, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.StartedChallengesFeedItemData started_challenges_feed_item_data = 9; */
        if (message.data.oneofKind === "startedChallengesFeedItemData")
            StartedChallengesFeedItemData.internalBinaryWrite(message.data.startedChallengesFeedItemData, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.ClaimedChallengesFeedItemData claimed_challenges_feed_item_data = 10; */
        if (message.data.oneofKind === "claimedChallengesFeedItemData")
            ClaimedChallengesFeedItemData.internalBinaryWrite(message.data.claimedChallengesFeedItemData, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.OnboardingFeedItemData onboarding_feed_item_data = 11; */
        if (message.data.oneofKind === "onboardingFeedItemData")
            OnboardingFeedItemData.internalBinaryWrite(message.data.onboardingFeedItemData, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.CompensateWheelSpinFeedItemData compensate_wheel_spin_feed_item_data = 12; */
        if (message.data.oneofKind === "compensateWheelSpinFeedItemData")
            CompensateWheelSpinFeedItemData.internalBinaryWrite(message.data.compensateWheelSpinFeedItemData, writer.tag(12, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.LotteryDrawingFeedItemData lottery_drawing_feed_item_data = 13; */
        if (message.data.oneofKind === "lotteryDrawingFeedItemData")
            LotteryDrawingFeedItemData.internalBinaryWrite(message.data.lotteryDrawingFeedItemData, writer.tag(13, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.CashbackFeedItemData cashback_feed_item_data = 14; */
        if (message.data.oneofKind === "cashbackFeedItemData")
            CashbackFeedItemData.internalBinaryWrite(message.data.cashbackFeedItemData, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        /* one.plan3t.core.model.ImpactReportFeedItemData impact_report_feed_item_data = 15; */
        if (message.data.oneofKind === "impactReportFeedItemData")
            ImpactReportFeedItemData.internalBinaryWrite(message.data.impactReportFeedItemData, writer.tag(15, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.FeedItem
 */
export const FeedItem = new FeedItem$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContentFeedItemData$Type extends MessageType<ContentFeedItemData> {
    constructor() {
        super("one.plan3t.core.model.ContentFeedItemData", [
            { no: 1, name: "image", kind: "message", T: () => ImageResource },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "subtitle", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "route", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "style", kind: "enum", T: () => ["one.plan3t.core.model.ContentFeedItemStyle", ContentFeedItemStyle, "CONTENT_FEED_ITEM_STYLE_"] },
            { no: 6, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "indicator", kind: "message", T: () => ContentFeedItemData_Indicator }
        ]);
    }
    create(value?: PartialMessage<ContentFeedItemData>): ContentFeedItemData {
        const message = { title: "", subtitle: "", route: "", style: 0, partnerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContentFeedItemData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContentFeedItemData): ContentFeedItemData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.ImageResource image */ 1:
                    message.image = ImageResource.internalBinaryRead(reader, reader.uint32(), options, message.image);
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string subtitle */ 3:
                    message.subtitle = reader.string();
                    break;
                case /* string route */ 4:
                    message.route = reader.string();
                    break;
                case /* one.plan3t.core.model.ContentFeedItemStyle style */ 5:
                    message.style = reader.int32();
                    break;
                case /* string partner_id */ 6:
                    message.partnerId = reader.string();
                    break;
                case /* one.plan3t.core.model.ContentFeedItemData.Indicator indicator */ 7:
                    message.indicator = ContentFeedItemData_Indicator.internalBinaryRead(reader, reader.uint32(), options, message.indicator);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContentFeedItemData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.ImageResource image = 1; */
        if (message.image)
            ImageResource.internalBinaryWrite(message.image, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string subtitle = 3; */
        if (message.subtitle !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.subtitle);
        /* string route = 4; */
        if (message.route !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.route);
        /* one.plan3t.core.model.ContentFeedItemStyle style = 5; */
        if (message.style !== 0)
            writer.tag(5, WireType.Varint).int32(message.style);
        /* string partner_id = 6; */
        if (message.partnerId !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.partnerId);
        /* one.plan3t.core.model.ContentFeedItemData.Indicator indicator = 7; */
        if (message.indicator)
            ContentFeedItemData_Indicator.internalBinaryWrite(message.indicator, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ContentFeedItemData
 */
export const ContentFeedItemData = new ContentFeedItemData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ContentFeedItemData_Indicator$Type extends MessageType<ContentFeedItemData_Indicator> {
    constructor() {
        super("one.plan3t.core.model.ContentFeedItemData.Indicator", [
            { no: 1, name: "icon_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "value", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ContentFeedItemData_Indicator>): ContentFeedItemData_Indicator {
        const message = { iconName: "", value: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ContentFeedItemData_Indicator>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ContentFeedItemData_Indicator): ContentFeedItemData_Indicator {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string icon_name */ 1:
                    message.iconName = reader.string();
                    break;
                case /* string value */ 2:
                    message.value = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ContentFeedItemData_Indicator, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string icon_name = 1; */
        if (message.iconName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.iconName);
        /* string value = 2; */
        if (message.value !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.value);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ContentFeedItemData.Indicator
 */
export const ContentFeedItemData_Indicator = new ContentFeedItemData_Indicator$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ToDoFeedItemData$Type extends MessageType<ToDoFeedItemData> {
    constructor() {
        super("one.plan3t.core.model.ToDoFeedItemData", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "route", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "icon", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ToDoFeedItemData>): ToDoFeedItemData {
        const message = { title: "", route: "", icon: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ToDoFeedItemData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ToDoFeedItemData): ToDoFeedItemData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* string route */ 2:
                    message.route = reader.string();
                    break;
                case /* string icon */ 3:
                    message.icon = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ToDoFeedItemData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* string route = 2; */
        if (message.route !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.route);
        /* string icon = 3; */
        if (message.icon !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.icon);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ToDoFeedItemData
 */
export const ToDoFeedItemData = new ToDoFeedItemData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompensationFeedItemData$Type extends MessageType<CompensationFeedItemData> {
    constructor() {
        super("one.plan3t.core.model.CompensationFeedItemData", [
            { no: 1, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "purchase_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "amount_in_kg", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "points", kind: "message", T: () => Plan3TPoint }
        ]);
    }
    create(value?: PartialMessage<CompensationFeedItemData>): CompensationFeedItemData {
        const message = { projectId: "", purchaseId: "", amountInKg: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompensationFeedItemData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompensationFeedItemData): CompensationFeedItemData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project_id */ 1:
                    message.projectId = reader.string();
                    break;
                case /* string purchase_id */ 2:
                    message.purchaseId = reader.string();
                    break;
                case /* int32 amount_in_kg */ 3:
                    message.amountInKg = reader.int32();
                    break;
                case /* one.plan3t.core.model.Plan3TPoint points */ 4:
                    message.points = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.points);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompensationFeedItemData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project_id = 1; */
        if (message.projectId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.projectId);
        /* string purchase_id = 2; */
        if (message.purchaseId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.purchaseId);
        /* int32 amount_in_kg = 3; */
        if (message.amountInKg !== 0)
            writer.tag(3, WireType.Varint).int32(message.amountInKg);
        /* one.plan3t.core.model.Plan3TPoint points = 4; */
        if (message.points)
            Plan3TPoint.internalBinaryWrite(message.points, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CompensationFeedItemData
 */
export const CompensationFeedItemData = new CompensationFeedItemData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OnboardingFeedItemData$Type extends MessageType<OnboardingFeedItemData> {
    constructor() {
        super("one.plan3t.core.model.OnboardingFeedItemData", [
            { no: 1, name: "type", kind: "enum", T: () => ["one.plan3t.core.model.OnboardingFeedItemType", OnboardingFeedItemType, "ONBOARDING_FEED_ITEM_TYPE_"] },
            { no: 2, name: "route", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<OnboardingFeedItemData>): OnboardingFeedItemData {
        const message = { type: 0, route: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OnboardingFeedItemData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OnboardingFeedItemData): OnboardingFeedItemData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.OnboardingFeedItemType type */ 1:
                    message.type = reader.int32();
                    break;
                case /* string route */ 2:
                    message.route = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OnboardingFeedItemData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.OnboardingFeedItemType type = 1; */
        if (message.type !== 0)
            writer.tag(1, WireType.Varint).int32(message.type);
        /* string route = 2; */
        if (message.route !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.route);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.OnboardingFeedItemData
 */
export const OnboardingFeedItemData = new OnboardingFeedItemData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class StartedChallengesFeedItemData$Type extends MessageType<StartedChallengesFeedItemData> {
    constructor() {
        super("one.plan3t.core.model.StartedChallengesFeedItemData", [
            { no: 1, name: "target_week", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<StartedChallengesFeedItemData>): StartedChallengesFeedItemData {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<StartedChallengesFeedItemData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: StartedChallengesFeedItemData): StartedChallengesFeedItemData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp target_week */ 1:
                    message.targetWeek = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.targetWeek);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: StartedChallengesFeedItemData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp target_week = 1; */
        if (message.targetWeek)
            Timestamp.internalBinaryWrite(message.targetWeek, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.StartedChallengesFeedItemData
 */
export const StartedChallengesFeedItemData = new StartedChallengesFeedItemData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ClaimedChallengesFeedItemData$Type extends MessageType<ClaimedChallengesFeedItemData> {
    constructor() {
        super("one.plan3t.core.model.ClaimedChallengesFeedItemData", [
            { no: 1, name: "target_week", kind: "message", T: () => Timestamp },
            { no: 2, name: "challenge_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ClaimedChallengesFeedItemData>): ClaimedChallengesFeedItemData {
        const message = { challengeIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ClaimedChallengesFeedItemData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ClaimedChallengesFeedItemData): ClaimedChallengesFeedItemData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Timestamp target_week */ 1:
                    message.targetWeek = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.targetWeek);
                    break;
                case /* repeated string challenge_ids */ 2:
                    message.challengeIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ClaimedChallengesFeedItemData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Timestamp target_week = 1; */
        if (message.targetWeek)
            Timestamp.internalBinaryWrite(message.targetWeek, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated string challenge_ids = 2; */
        for (let i = 0; i < message.challengeIds.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.challengeIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ClaimedChallengesFeedItemData
 */
export const ClaimedChallengesFeedItemData = new ClaimedChallengesFeedItemData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CompensateWheelSpinFeedItemData$Type extends MessageType<CompensateWheelSpinFeedItemData> {
    constructor() {
        super("one.plan3t.core.model.CompensateWheelSpinFeedItemData", [
            { no: 1, name: "compensate_wheel_spin_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "amount_in_kg", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "compensate_wheel_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "compensate_wheel_outcome_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "target_date", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CompensateWheelSpinFeedItemData>): CompensateWheelSpinFeedItemData {
        const message = { compensateWheelSpinId: "", projectId: "", amountInKg: 0, compensateWheelId: "", compensateWheelOutcomeId: "", targetDate: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CompensateWheelSpinFeedItemData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CompensateWheelSpinFeedItemData): CompensateWheelSpinFeedItemData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string compensate_wheel_spin_id */ 1:
                    message.compensateWheelSpinId = reader.string();
                    break;
                case /* string project_id */ 2:
                    message.projectId = reader.string();
                    break;
                case /* int32 amount_in_kg */ 3:
                    message.amountInKg = reader.int32();
                    break;
                case /* string compensate_wheel_id */ 4:
                    message.compensateWheelId = reader.string();
                    break;
                case /* string compensate_wheel_outcome_id */ 5:
                    message.compensateWheelOutcomeId = reader.string();
                    break;
                case /* string target_date */ 6:
                    message.targetDate = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CompensateWheelSpinFeedItemData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string compensate_wheel_spin_id = 1; */
        if (message.compensateWheelSpinId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.compensateWheelSpinId);
        /* string project_id = 2; */
        if (message.projectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.projectId);
        /* int32 amount_in_kg = 3; */
        if (message.amountInKg !== 0)
            writer.tag(3, WireType.Varint).int32(message.amountInKg);
        /* string compensate_wheel_id = 4; */
        if (message.compensateWheelId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.compensateWheelId);
        /* string compensate_wheel_outcome_id = 5; */
        if (message.compensateWheelOutcomeId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.compensateWheelOutcomeId);
        /* string target_date = 6; */
        if (message.targetDate !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.targetDate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CompensateWheelSpinFeedItemData
 */
export const CompensateWheelSpinFeedItemData = new CompensateWheelSpinFeedItemData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class LotteryDrawingFeedItemData$Type extends MessageType<LotteryDrawingFeedItemData> {
    constructor() {
        super("one.plan3t.core.model.LotteryDrawingFeedItemData", [
            { no: 1, name: "lottery_price_drawing_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "success", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<LotteryDrawingFeedItemData>): LotteryDrawingFeedItemData {
        const message = { lotteryPriceDrawingId: "", success: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<LotteryDrawingFeedItemData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: LotteryDrawingFeedItemData): LotteryDrawingFeedItemData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string lottery_price_drawing_id */ 1:
                    message.lotteryPriceDrawingId = reader.string();
                    break;
                case /* bool success */ 2:
                    message.success = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: LotteryDrawingFeedItemData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string lottery_price_drawing_id = 1; */
        if (message.lotteryPriceDrawingId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.lotteryPriceDrawingId);
        /* bool success = 2; */
        if (message.success !== false)
            writer.tag(2, WireType.Varint).bool(message.success);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.LotteryDrawingFeedItemData
 */
export const LotteryDrawingFeedItemData = new LotteryDrawingFeedItemData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CashbackFeedItemData$Type extends MessageType<CashbackFeedItemData> {
    constructor() {
        super("one.plan3t.core.model.CashbackFeedItemData", [
            { no: 1, name: "transaction_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "transaction_timestamp", kind: "message", T: () => Timestamp },
            { no: 3, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "points", kind: "message", T: () => Plan3TPoint }
        ]);
    }
    create(value?: PartialMessage<CashbackFeedItemData>): CashbackFeedItemData {
        const message = { transactionId: "", partnerId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CashbackFeedItemData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CashbackFeedItemData): CashbackFeedItemData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string transaction_id */ 1:
                    message.transactionId = reader.string();
                    break;
                case /* google.protobuf.Timestamp transaction_timestamp */ 2:
                    message.transactionTimestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.transactionTimestamp);
                    break;
                case /* string partner_id */ 3:
                    message.partnerId = reader.string();
                    break;
                case /* one.plan3t.core.model.Plan3TPoint points */ 4:
                    message.points = Plan3TPoint.internalBinaryRead(reader, reader.uint32(), options, message.points);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CashbackFeedItemData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string transaction_id = 1; */
        if (message.transactionId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.transactionId);
        /* google.protobuf.Timestamp transaction_timestamp = 2; */
        if (message.transactionTimestamp)
            Timestamp.internalBinaryWrite(message.transactionTimestamp, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* string partner_id = 3; */
        if (message.partnerId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.partnerId);
        /* one.plan3t.core.model.Plan3TPoint points = 4; */
        if (message.points)
            Plan3TPoint.internalBinaryWrite(message.points, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.CashbackFeedItemData
 */
export const CashbackFeedItemData = new CashbackFeedItemData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ImpactReportFeedItemData$Type extends MessageType<ImpactReportFeedItemData> {
    constructor() {
        super("one.plan3t.core.model.ImpactReportFeedItemData", [
            { no: 1, name: "date", kind: "message", T: () => Date }
        ]);
    }
    create(value?: PartialMessage<ImpactReportFeedItemData>): ImpactReportFeedItemData {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ImpactReportFeedItemData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ImpactReportFeedItemData): ImpactReportFeedItemData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.Date date */ 1:
                    message.date = Date.internalBinaryRead(reader, reader.uint32(), options, message.date);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ImpactReportFeedItemData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.Date date = 1; */
        if (message.date)
            Date.internalBinaryWrite(message.date, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.model.ImpactReportFeedItemData
 */
export const ImpactReportFeedItemData = new ImpactReportFeedItemData$Type();
