import { rewardActions } from './store';
import { connect, ConnectedProps } from 'react-redux';
import { Button } from '../common/Button';
import { FunctionComponent, useState } from 'react';
import { Timestamp } from '../api/google/protobuf/timestamp';

type OwnProps = {
    partnerId: string;
    rewardId: string;
};

const connector = connect(null, {
    setValidUntil: rewardActions.setValidUntil,
});

type Props = OwnProps & ConnectedProps<typeof connector>;

const SuspendRewardButton: FunctionComponent<Props> = (props) => {
    const [working, setWorking] = useState(false);
    const onClick = () => {
        setWorking(true);
        try {
            // eslint-disable-next-line no-restricted-globals
            if (!confirm('Really? This cannot be undone!')) {
                return;
            }
            props.setValidUntil({
                partnerId: props.partnerId,
                rewardId: props.rewardId,
                validUntil: Timestamp.now(),
            });
        } finally {
            setWorking(false);
        }
    };

    return (
        <Button
            label={working ? '...' : 'Suspend from App'}
            secondary
            onClick={working ? undefined : onClick}
        />
    );
};

export default connector(SuspendRewardButton);
