import React, { SVGProps, useCallback, useMemo } from 'react';
import cx from 'classnames';
import { useLocation, matchPath, Link } from 'react-router-dom';
import { useAppDispatch } from './redux/react';
import { authActions } from './auth/store';
import logo from './images/logo.png';
import { deploymentEnvironment } from './env';
import {
    IconBilling,
    IconCash,
    IconClipboardList,
    IconCollection,
    IconCreditCard,
    IconCube,
    IconDocumentSearch,
    IconLightBulb,
    IconPartnerProfile,
    IconReports,
    IconRewards,
    IconShare,
    IconShieldCheck,
    IconSpeakerphone,
    IconTableCells,
    IconTicket,
} from './icons';

const Sidebar: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const onLogout = useCallback(() => {
        dispatch(authActions.logout());
    }, [dispatch]);

    const links = [
        {
            label: 'Feed',
            to: '/feed',
            icon: IconCollection,
        },
        {
            label: 'Notifications',
            to: '/notification',
            icon: IconSpeakerphone,
        },
        {
            label: 'Partner',
            to: '/partner',
            icon: IconPartnerProfile,
        },
        {
            label: 'Reward',
            to: '/reward',
            icon: IconRewards,
        },
        {
            label: 'Review',
            to: '/review',
            icon: IconShieldCheck,
        },
        {
            label: 'Assessment',
            to: '/assessment',
            icon: IconClipboardList,
        },
        {
            label: 'Tip',
            to: '/tip',
            icon: IconLightBulb,
        },
        {
            label: 'Lottery',
            to: '/lottery',
            icon: IconTicket,
        },
        {
            label: 'Voucher',
            to: '/voucher',
            icon: IconTicket,
        },
        {
            label: 'Referral',
            to: '/referral/customCode',
            icon: IconShare,
        },
        {
            label: 'Analytics',
            to: '/analytics',
            icon: IconReports,
        },
        {
            label: 'Payments',
            to: '/payment',
            icon: IconCreditCard,
        },
        {
            label: 'Billing',
            to: '/billing',
            icon: IconBilling,
        },
        {
            label: 'Cashback Rate',
            to: '/cashback/rates',
            icon: IconCash,
        },
        {
            label: 'Cashback Log',
            to: '/cashback/log',
            icon: IconCash,
        },
        {
            label: 'CashbackTickets',
            to: '/cashback/ticket',
            icon: IconDocumentSearch,
        },
        {
            label: 'SmartContract',
            to: '/accounting',
            icon: IconCube,
        },
        {
            label: 'Audit',
            to: '/audit',
            icon: IconShieldCheck,
        },
        {
            label: 'Ledger',
            to: '/ledger',
            icon: IconTableCells,
        },
    ];

    return (
        <aside className="text-gray-700 w-48 flex-shrink-0 flex flex-col items-center py-8 border-r border-gray-200 relative">
            {deploymentEnvironment !== 'production' && (
                <div className="absolute top-0 inset-x-0 p-1 flex justify-center">
                    <span className="uppercase text-xs px-2 py-1 bg-yellow-100 text-yellow-900">
                        {deploymentEnvironment}
                    </span>
                </div>
            )}
            <img src={logo} alt="PLAN3T logo" className="h-4 mb-2" />

            <ul className="mt-4 space-y-2 w-full h-full px-4 overflow-y-auto">
                {links.map((link) => (
                    <li key={link.to}>
                        <SidebarLink {...link} />
                    </li>
                ))}
                <li>
                    <button
                        className="px-3 py-2 flex items-center justify-between text-sm font-medium rounded-md hover:bg-gray-200 transition-default"
                        onClick={onLogout}
                    >
                        Logout
                    </button>
                </li>
            </ul>
        </aside>
    );
};

type LinkProps = {
    label: string;
    to: string;
    icon: React.FunctionComponent<SVGProps<SVGElement>>;
};
export const SidebarLink: React.FunctionComponent<LinkProps> = ({
    label,
    to,
    icon: Icon,
}) => {
    const { pathname: path } = useLocation();

    const isActive = useMemo(() => {
        return matchPath(path, to);
    }, [path, to]);

    const ariaAttributes = isActive
        ? ({
              'aria-current': 'page',
          } as const)
        : {};

    return (
        <Link
            to={to}
            className={cx(
                'px-3 py-2 flex items-center justify-between text-sm font-medium rounded-md hover:bg-gray-200 transition-default',
                {
                    'bg-gray-200': isActive,
                },
            )}
            {...ariaAttributes}
        >
            <span className="inline-flex space-x-2 items-center">
                <Icon
                    className={cx('w-5 h-5', {
                        'text-green-500': isActive,
                        'text-gray-500': !isActive,
                    })}
                />
                <span
                    className={cx({
                        'text-green-500': isActive,
                        'text-gray-700 font-light': !isActive,
                    })}
                >
                    {label}
                </span>
            </span>
        </Link>
    );
};

export default Sidebar;
