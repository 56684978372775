export interface TextFieldProps {
    readonly className?: string;
    readonly placeholder?: string;
    readonly value: string;
    readonly onChange: (value: string) => void;
    readonly disabled?: boolean;
    readonly label?: string;
    readonly error?: string;
}

export const TextField = (props: TextFieldProps) => {
    return (
        <div className={props.className}>
            <label className="block">
                {props.label && (
                    <span className="text-gray-700">{props.label}</span>
                )}
                <input
                    className="form-input mt-1 block w-full"
                    type="text"
                    disabled={!!props.disabled}
                    placeholder={props.placeholder}
                    value={props.value}
                    onChange={(e) => {
                        props.onChange(e.target.value);
                    }}
                />
            </label>
            {props.error ? (
                <p className="font-medium text-sm text-red-400 leading-5 py-1">
                    {props.error}
                </p>
            ) : (
                <div className="py-1 leading-5">&nbsp;</div>
            )}
        </div>
    );
};
