import Modal from '../common/Modal';
import { SyntheticEvent, useState } from 'react';
import { PartnerBase } from '../api/model/core';
import { TextField } from '../common/TextField';
import { partnerActions, partnerSelectors } from './store';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { Button } from '../common/Button';

type editFields = keyof Pick<
    PartnerBase,
    'datevId' | 'pipedriveId' | 'brandName' | 'legalName'
>;

export default function EditPartnerFieldDialog(props: {
    partnerId: string | undefined;
    requestClose: () => void;
}) {
    const [field, setField] = useState<editFields | undefined>(undefined);
    return (
        <Modal
            visible={!!props.partnerId}
            onRequestClose={props.requestClose}
            headline="Edit Partner"
        >
            <select
                className="form-select"
                onChange={(e) =>
                    setField(
                        e.target.value.trim().length === 0
                            ? undefined
                            : (e.target.value as any as editFields),
                    )
                }
            >
                <option value="">Select a field</option>
                <option value="pipedriveId">Pipedrive ID</option>
                <option value="datevId">DATEV ID</option>
            </select>

            {field && field.length > 0 && props.partnerId && (
                <FieldEditor
                    key={field}
                    partnerId={props.partnerId}
                    field={field}
                    onDone={props.requestClose}
                />
            )}
        </Modal>
    );
}

function FieldEditor(props: {
    partnerId: string;
    field: editFields;
    onDone: () => void;
}) {
    const dispatch = useAppDispatch();
    const p = useAppSelector((s) => partnerSelectors.getPartnerById(s, props));

    const [value, setValue] = useState(p![props.field]);

    const onSave = async (e: SyntheticEvent) => {
        e.preventDefault();

        await dispatch(
            partnerActions.updatePartnerBase({
                id: props.partnerId,
                [props.field]: value,
            }),
        );
        props.onDone();
    };

    return (
        <form className="flex flex-col space-y-2" onSubmit={onSave}>
            <TextField
                placeholder={p![props.field]}
                value={value ?? ''}
                onChange={setValue}
            />
            <Button label="Save" primary onClick={onSave} />
        </form>
    );
}
