import { combineReducers } from '@reduxjs/toolkit';
import { analyticsReducer } from '../analytics/store';
import { authReducer } from '../auth/store';
import { challengeReducer } from '../challenge/store';
import { feedReducer } from '../feed/store';
import { lotteryDrawingReducer, lotteryReducer } from '../lottery/store';
import { notificationReducer } from '../notification/store';
import { partnerReducer } from '../partner/store';
import { reviewReducer } from '../review/store';
import { rewardReducer } from '../reward/store';
import { snackbarReducer } from '../snackbar/store';
import {
    tipQuizEditorReducer,
    tipSlideEditorReducer,
} from '../tip/editorStore';
import { tipReducer } from '../tip/store';
import { userReducer } from '../user/store';
import { voucherReducer } from '../voucher/store';
import {
    cashbackInspectorReducer,
    cashbackLogReducer,
    cashbackTicketReducer,
} from '../cashback/store';
import { referralCustomCodeReducer } from '../referral/store';
import { openBankingWaitlistReducer } from '../openbankingwaitlist/store';
import {
    assessmentFetchingReducer,
    assessmentsReducer,
} from '../assessment/store';

export const rootReducer = combineReducers({
    assessments: assessmentsReducer,
    assessmentFetching: assessmentFetchingReducer,
    auth: authReducer,
    analytics: analyticsReducer,
    cashbackInspector: cashbackInspectorReducer,
    cashbackLog: cashbackLogReducer,
    cashbackTicket: cashbackTicketReducer,
    challenge: challengeReducer,
    feed: feedReducer,
    lottery: lotteryReducer,
    lotteryDrawing: lotteryDrawingReducer,
    notification: notificationReducer,
    openBankingWaitlist: openBankingWaitlistReducer,
    partner: partnerReducer,
    referralCustomCode: referralCustomCodeReducer,
    review: reviewReducer,
    reward: rewardReducer,
    snackbar: snackbarReducer,
    tip: tipReducer,
    tipSlideEditor: tipSlideEditorReducer,
    tipQuizEditor: tipQuizEditorReducer,
    user: userReducer,
    voucher: voucherReducer,
});
