import {
    AnalyticalTable,
    Bar,
    Button,
    FlexBox,
    FlexBoxDirection,
    Label,
    Page,
    TableScaleWidthMode,
} from '@ui5/webcomponents-react';
import { Suspense, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    activeReportAtom,
    activeReportResultQuery,
    availableReportsQuery,
    useDownloadAsXLSXFunction,
} from './store';
import LoadingSpinner from '../common/LoadingSpinner';
import * as Report from '../api/report/rpc/report';
import { Route, Switch } from 'react-router-dom';

export default function ReportModule() {
    const [active, setActive] = useRecoilState(activeReportAtom);
    return (
        <Switch>
            <Route path="/report">
                <Page
                    header={
                        <Bar
                            startContent={
                                <Button
                                    onClick={() => setActive(undefined)}
                                    icon="home"
                                />
                            }
                            endContent={active && <DownloadButton />}
                        >
                            <Label>Internal Report {active?.id}</Label>
                        </Bar>
                    }
                >
                    <FlexBox direction={FlexBoxDirection.Column}>
                        <Suspense fallback={<LoadingSpinner />}>
                            {active ? <ReportDisplay /> : <ReportSelector />}
                        </Suspense>
                    </FlexBox>
                </Page>
            </Route>
        </Switch>
    );
}

function ReportSelector() {
    const available = useRecoilValue(availableReportsQuery);

    const [selected, setSelected] = useState<
        Report.ReportDefinition | undefined
    >();

    const [args, setArguments] = useState<Record<string, string>>({});

    const [, setActive] = useRecoilState(activeReportAtom);

    const onExecute = () => {
        setActive({
            id: selected!.id,
            arguments: Object.entries(args).map(([k, v]) => ({
                name: k,
                value: v,
            })),
        });
    };

    return (
        <div className="flex flex-col space-y-2 max-w-xl py-4">
            <select
                className="form-select"
                value={selected?.id ?? ''}
                onChange={(e) => {
                    setSelected(
                        available.find((re) => re.id === e.target.value),
                    );
                    setArguments({});
                }}
            >
                {!selected && <option value="">Select a report</option>}
                {available.map((r) => (
                    <option value={r.id}>{r.id}</option>
                ))}
            </select>
            <div className="flex flex-row space-x-2">
                {selected &&
                    selected.arguments.map((arg) => (
                        <label key={arg.name} className="inline-flex flex-col">
                            <Label>{arg.name}</Label>
                            <input
                                className="form-input"
                                value={args[arg.name] ?? ''}
                                onChange={(e) =>
                                    setArguments((a) => ({
                                        ...a,
                                        [arg.name]: e.target.value,
                                    }))
                                }
                            />
                        </label>
                    ))}
            </div>
            {selected && (
                <div className="flex flex-row">
                    <Button onClick={onExecute}>Execute</Button>
                </div>
            )}
        </div>
    );
}

function ReportDisplay() {
    const result = useRecoilValue(activeReportResultQuery);

    return (
        <AnalyticalTable
            columns={result.header.map((label, i) => ({
                Header: label,
                accessor: (row: Report.GetReportResponse_Row) => {
                    if ('emptyRow' in row) {
                        return '';
                    }
                    return row.fields[i];
                },
            }))}
            minRows={1}
            data={result.rows}
            scaleWidthMode={TableScaleWidthMode.Grow}
        />
    );
}

function DownloadButton(props: { slot?: string }) {
    const download = useDownloadAsXLSXFunction();

    const [working, setWorking] = useState(false);

    const onClick = async () => {
        setWorking(true);
        try {
            await download();
        } catch (e) {
            console.error(e);
        } finally {
            setWorking(false);
        }
    };

    return (
        <Button
            slot={props.slot}
            icon={working ? 'lateness' : 'excel-attachment'}
            onClick={working ? undefined : onClick}
        />
    );
}
