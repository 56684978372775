import { initializeApp } from 'firebase/app';
import { deploymentEnvironment } from '../env';
import { getAuth } from 'firebase/auth';

const firebaseConfigs = {
    production: {
        apiKey: 'AIzaSyA-UHmllzOzD28rZ5edhS3WtI-ORB6cxeE',
        authDomain: 'plan3t-backoffice.firebaseapp.com',
        projectId: 'plan3t-backoffice',
        storageBucket: 'plan3t-backoffice.appspot.com',
        messagingSenderId: '572044026674',
        appId: '1:572044026674:web:37f427039115676a8dad2b',
    },
    staging: {
        apiKey: 'AIzaSyBKqGfgXHD87T0vlx3hhanFmQTFe_fg1Ug',
        authDomain: 'plan3t-staging.firebaseapp.com',
        projectId: 'plan3t-staging',
        storageBucket: 'plan3t-staging.appspot.com',
        messagingSenderId: '213063106542',
        appId: '1:213063106542:web:7564257480f165cf7e98ff',
    },
    development: {
        apiKey: 'AIzaSyDxRO9NquEteLXz8XEt4SJqASuVOiYAaqY',
        authDomain: 'plan3t-dev.firebaseapp.com',
        projectId: 'plan3t-dev',
        storageBucket: 'plan3t-dev.appspot.com',
        messagingSenderId: '469113112437',
        appId: '1:469113112437:web:63dc6f1805245a0c33753c',
    },
} as const;

const firebaseConfig = firebaseConfigs[deploymentEnvironment];

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
