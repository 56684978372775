import React from 'react';
import { useParams } from 'react-router-dom';

const ReviewView: React.FunctionComponent = () => {
    const { type, id, magiclinkid } = useParams<any>();

    return <h1>{JSON.stringify({ id, type, magiclinkid })}</h1>;
};

export default ReviewView;
