import { LotteryTicket, Plan3TPoint } from '../api/model/core';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import {
    DrawRandomLotteryTicketsRequest,
    DrawRandomLotteryTicketsResponse,
    GetLotteryTicketResponse,
    GetNumberOfLotteryTicketsResponse,
    SetLotteryWinnerTicketsRequest,
    LotteryPriceDrawingAuthoringItem,
    GetLotteryPriceDrawingAuthoringItemsResponse,
    WriteLotteryPriceDrawingAuthoringItemRequest,
    GetAvailableLotteryProjectsResponse,
} from '../api/model/backoffice';
import { AppState } from '../redux/AppStore';
import { snackbarActions } from '../snackbar/store';
import { createCrudSlice, CrudSlice } from '../crud-module/store';
import { ulid } from 'ulid';
import { selector } from 'recoil';

export const lotteryModule: CrudSlice<LotteryPriceDrawingAuthoringItem> =
    createCrudSlice<LotteryPriceDrawingAuthoringItem>({
        name: 'lottery',
        baseUrl: '/lottery',
        localClientBasePath: '/lottery',
        stateProvider: (s) => (s as AppState).lottery,
        listResponseParser: (data) => {
            const res =
                GetLotteryPriceDrawingAuthoringItemsResponse.fromJson(data);
            return {
                items: res.items,
                nextPageToken: res.nextPageToken,
            };
        },
        singleResponseParser: (data) => {
            throw Error('not implemented');
        },
        writeArgumentTransformer: (item: LotteryPriceDrawingAuthoringItem) => {
            return WriteLotteryPriceDrawingAuthoringItemRequest.toJson({
                item,
            });
        },
        idProvider: ({ id }) => id,
        prepareEmpty: () => ({
            id: ulid(),
            startsAt: undefined,
            endsAt: undefined,
            title: '',
            description: '',
            image: undefined,
            ticketPrice: Plan3TPoint.create({ amount: 15 }),
            partnerId: '',
            winnerTicketIds: [],
            extraBenefitExplanation: '',
            cooperationProjectName: '',
            cooperationProjectUrl: '',
            cooperationProjectTagLine: '',
            cooperationProjectLogo: undefined,
            terms: '',
            notificationBody: '',
            notificationTitle: '',
            associatedPartnerIds: [],
            compensateProjectId: '',
            donationProjectContribution: 0,
            donationProjectSku: '',
            createdAt: undefined,
            createdBy: '',
            modifiedAt: undefined,
            modifiedBy: '',
        }),
    });

export const lotteryReducer = lotteryModule.reducer;
export const lotteryActions = lotteryModule.actions;
export const lotterySelectors = lotteryModule.selectors;

type State = {
    viewWinnerTicketsForItemId: string | undefined;

    drawingForItemId: string | undefined;

    isDrawingTickets: boolean;
    drawingTicketsError: any;

    drawnTickets: LotteryTicket[];
    excludedTicketIds: string[];

    isSettingWinner: boolean;

    ticketsById: Record<string, LotteryTicket>;

    fetchingNumberOfTicketsFor: Record<string, boolean>;
    numberOfTicketsById: Record<string, number>;
};

const initialState: State = {
    viewWinnerTicketsForItemId: undefined,

    drawingForItemId: undefined,

    isDrawingTickets: false,
    drawingTicketsError: undefined,

    drawnTickets: [],
    excludedTicketIds: [],

    isSettingWinner: false,

    ticketsById: {},

    fetchingNumberOfTicketsFor: {},
    numberOfTicketsById: {},
};

const fetchTicket = createAsyncThunk(
    'lottery/ticket/fetch',
    async (arg: { id: string }) => {
        const raw = await axios.get<Object>(`/lottery/-/ticket/${arg.id}`);
        return GetLotteryTicketResponse.fromJson(raw.data as any).ticket;
    },
);

const drawRandomTickets = createAsyncThunk(
    'lottery/drawTickets',
    async (arg: { numTickets: number }, { getState }) => {
        const req: DrawRandomLotteryTicketsRequest = {
            priceDrawingId: (getState() as AppState).lotteryDrawing
                .drawingForItemId!,
            numTickets: arg.numTickets,
            excludedTicketIds: (getState() as AppState).lotteryDrawing
                .excludedTicketIds,
        };
        const raw = await axios.post<Object>(
            `/lottery/${req.priceDrawingId}/drawTickets`,
            req,
        );
        return DrawRandomLotteryTicketsResponse.fromJson(raw.data as any);
    },
);

const setWinnerTickets = createAsyncThunk(
    'lottery/setWinnerTickets',
    async (arg: void, { getState, dispatch }) => {
        const priceDrawingId = (getState() as AppState).lotteryDrawing
            .drawingForItemId!;
        const ticketIds = (
            getState() as AppState
        ).lotteryDrawing.drawnTickets.map((t) => t.id);
        const req: SetLotteryWinnerTicketsRequest = {
            priceDrawingId,
            ticketIds,
        };
        await axios.post<Object>(
            `/lottery/${priceDrawingId}/setWinnerTickets`,
            req,
        );

        dispatch(lotteryModule.actions.fetchList());
    },
);

const fetchNumberOfTickets = createAsyncThunk(
    'lottery/numberOfTickets/fetch',
    async (arg: { id: string }) => {
        const raw = await axios.get<Object>(`/lottery/${arg.id}/numTickets`);
        return GetNumberOfLotteryTicketsResponse.fromJson(raw.data as any)
            .numTickets;
    },
);

export const copyDeepLink = createAsyncThunk(
    'lottery/copyDeepLink',
    async (args: { id: string }, { dispatch }) => {
        const deepLink = `/lottery?lottery_item=${args.id}`;
        await navigator.clipboard.writeText(deepLink);

        dispatch(
            snackbarActions.sendMessage({
                title: 'DeepLink copied to clipboard',
                subtitle: deepLink,
            }),
        );
    },
);

const lotteryDrawingSlice = createSlice({
    name: 'lotteryDrawing',
    initialState,
    reducers: {
        enterDrawingMode(state, action: PayloadAction<string>) {
            state.drawingForItemId = action.payload;
            state.drawnTickets = [];
            state.excludedTicketIds = [];
        },
        exitDrawingMode(state) {
            state.drawingForItemId = undefined;
            state.drawnTickets = [];
            state.excludedTicketIds = [];
        },
        enterViewWinnersMode(state, action: PayloadAction<string>) {
            state.viewWinnerTicketsForItemId = action.payload;
        },
        hideWinners(state) {
            state.viewWinnerTicketsForItemId = undefined;
        },
        excludeTicket(state, action: PayloadAction<string>) {
            state.excludedTicketIds = Array.from(
                new Set([action.payload, ...state.excludedTicketIds]),
            );
        },
    },
    extraReducers: (builder) => {
        builder.addCase(drawRandomTickets.pending, (state) => {
            state.isDrawingTickets = true;
            state.drawingTicketsError = undefined;
        });
        builder.addCase(drawRandomTickets.rejected, (state, action) => {
            state.isDrawingTickets = false;
            state.drawingTicketsError = action.error;
        });

        builder.addCase(drawRandomTickets.fulfilled, (state, action) => {
            state.isDrawingTickets = false;
            state.drawnTickets = action.payload.tickets;
        });

        builder.addCase(setWinnerTickets.pending, (state) => {
            state.isSettingWinner = true;
        });
        builder.addCase(setWinnerTickets.rejected, (state) => {
            state.isSettingWinner = false;
        });
        builder.addCase(setWinnerTickets.fulfilled, (state) => {
            state.drawingForItemId = undefined;
            state.drawnTickets = [];
            state.excludedTicketIds = [];
        });

        builder.addCase(fetchTicket.fulfilled, (state, action) => {
            if (!!action.payload) {
                state.ticketsById[action.payload.id] = action.payload;
            }
        });

        builder.addCase(fetchNumberOfTickets.pending, (state, action) => {
            state.fetchingNumberOfTicketsFor[action.meta.arg.id] = true;
        });

        builder.addCase(fetchNumberOfTickets.fulfilled, (state, action) => {
            state.fetchingNumberOfTicketsFor[action.meta.arg.id] = false;
            if (!!action.payload) {
                state.numberOfTicketsById[action.meta.arg.id] = action.payload;
            }
        });
    },
});

export const lotteryDrawingReducer = lotteryDrawingSlice.reducer;
export const lotteryDrawingActions = {
    ...lotteryDrawingSlice.actions,
    drawRandomTickets,
    setWinnerTickets,
    fetchTicket,
    fetchNumberOfTickets,
    copyDeepLink,
};
export const lotteryDrawingSelectors = {
    isInDrawingMode: (state: AppState) =>
        state.lotteryDrawing.drawingForItemId !== undefined,
    isInViewWinnersMode: (state: AppState) =>
        state.lotteryDrawing.viewWinnerTicketsForItemId !== undefined,
    getPriceDrawingInDrawingMode: (state: AppState) =>
        lotteryDrawingSelectors.isInDrawingMode(state)
            ? lotterySelectors.getItem(state, {
                  id: state.lotteryDrawing.drawingForItemId!,
              })
            : undefined,
    getPriceDrawingInViewingMode: (state: AppState) =>
        lotteryDrawingSelectors.isInViewWinnersMode(state)
            ? lotterySelectors.getItem(state, {
                  id: state.lotteryDrawing.viewWinnerTicketsForItemId!,
              })
            : undefined,
    getDrawnTickets: (state: AppState) => state.lotteryDrawing.drawnTickets,
    isDrawingTickets: (state: AppState) =>
        state.lotteryDrawing.isDrawingTickets,
    getExcludedTicketIds: (state: AppState) =>
        state.lotteryDrawing.excludedTicketIds,
    getTicket: (state: AppState, { id }: { id: string }) =>
        state.lotteryDrawing.ticketsById[id],
    isFetchingNumberOfTickets: (state: AppState, { id }: { id: string }) =>
        state.lotteryDrawing.fetchingNumberOfTicketsFor[id] || false,
    getNumberOfTickets: (state: AppState, { id }: { id: string }) =>
        state.lotteryDrawing.numberOfTicketsById[id],
};

export const availableLotteryProjectsQuery = selector({
    key: 'lottery/availableLotteryProjectsQuery',
    get: async () => {
        const res = await axios.get('/lottery/projects');
        return GetAvailableLotteryProjectsResponse.fromJson(res.data).projects;
    },
});
