import { useCallback, useEffect, useRef } from 'react';

type KeyboardShortcutDefinition =
    | string
    | { sequence: Array<KeyboardShortcutDefinition> };

export default function useKeyboardShortcut(
    shortcuts: Array<{
        shortcut: KeyboardShortcutDefinition;
        callback: () => void;
    }>,
) {
    const state = useRef<Array<string>>([]);

    const clearer = useRef<NodeJS.Timeout>();

    const onKeyDown = useCallback(
        (e: KeyboardEvent) => {
            if (clearer.current !== undefined) {
                clearTimeout(clearer.current);
            }
            clearer.current = setTimeout(() => {
                state.current = [];
            }, 1000);
            state.current.push(e.key);
            state.current = state.current.slice(-3);
            for (let { shortcut, callback } of shortcuts) {
                if (matches(state.current, shortcut)) {
                    callback();
                    return;
                }
            }
        },
        [state, shortcuts],
    );

    useEffect(() => {
        window.addEventListener('keydown', onKeyDown);

        return () => window.removeEventListener('keydown', onKeyDown);
    }, [onKeyDown]);

    return null;
}

function matches(
    state: Array<string>,
    shortcut: KeyboardShortcutDefinition,
): boolean {
    const last = state.length === 0 ? '' : state[state.length - 1];
    if (
        typeof shortcut === 'string' &&
        shortcut.includes(' ') &&
        shortcut.length > 2
    ) {
        shortcut = { sequence: shortcut.split(' ') };
    }
    if (typeof shortcut === 'string' && last === shortcut) {
        return true;
    }
    if (typeof shortcut === 'object' && 'sequence' in shortcut) {
        if (shortcut.sequence.length === 0) {
            return true;
        }
        if (last === shortcut.sequence[shortcut.sequence.length - 1]) {
            return matches(state.slice(0, -1), {
                sequence: shortcut.sequence.slice(0, -1),
            });
        }
    }
    return false;
}
