import { feedModule } from './store';
import { FormTimestampField } from '../common/FormFields';
import { ContentFeedItemDataField } from './ContentFieldItemDataField';
import ItemForm from '../crud-module/ItemForm';
import { useField } from 'formik';
import { ContentFeedItemData, ContentFeedItemStyle } from '../api/model/feed';
import cx from 'classnames';
import * as Yup from 'yup';
import { Timestamp } from '../api/google/protobuf/timestamp';

export default function FeedItemForm(props: { id: string | undefined }) {
    const validationSchema = Yup.object().shape({
        timestamp: Yup.object()
            .test(
                'reasonable timestamp',
                'Timestamp has to be at least in the current year.',
                (val) => {
                    if (props.id !== undefined) {
                        // we do not validate a min date for existing feed items
                        return true;
                    }
                    if (!val) {
                        return true;
                    }
                    const d = Timestamp.toDate(val as any);
                    const minDate = new Date(new Date().getFullYear(), 0, 1);
                    return d > minDate;
                },
            )
            .required(),
        data: Yup.object().shape({
            contentFeedItemData: Yup.object().shape({
                image: Yup.object()
                    .shape({
                        id: Yup.string().required(),
                        url: Yup.string().required(),
                    })
                    .required(),
                title: Yup.string()
                    .min(2, 'Too Short')
                    .max(100, 'Too Long!')
                    .required('Required'),
                subtitle: Yup.string()
                    .min(2, 'Too Short')
                    .max(100, 'Too Long!')
                    .required('Required'),
                route: Yup.string()
                    .min(2, 'Too Short')
                    .max(100, 'Too Long!')
                    .required('Required'),
            }),
        }),
    });

    return (
        <ItemForm
            module={feedModule}
            id={props.id}
            validationSchema={validationSchema}
        >
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4">
                <div className="w-full">
                    <ContentFeedItemDataField />
                    <FormTimestampField name="timestamp" label="Show from" />
                    <FormTimestampField
                        name="expiry"
                        label="Expires at (optional)"
                        clearable
                    />
                </div>
                <div className="md:w-128 bg-semantic-background p-2">
                    <span className="uppercase text-xs font-bold tracking-wide text-gray-500">
                        Rough Preview
                    </span>

                    <FeedItemPreview />
                </div>
            </div>
        </ItemForm>
    );
}

function FeedItemPreview() {
    const [{ value }] = useField('data');

    const data: ContentFeedItemData = value.contentFeedItemData;

    return (
        <div
            className={cx(
                'rounded-xl border-2 border-gray-100 bg-semantic-surface p-4 flex',
                {
                    'flex-row':
                        data.style === ContentFeedItemStyle.UNKNOWN ||
                        data.style === ContentFeedItemStyle.SMALL,
                    'flex-col': data.style === ContentFeedItemStyle.LARGE,
                },
            )}
        >
            <div
                className={cx('rounded overflow-hidden flex-shrink-0', {
                    'w-16 h-16 mr-4':
                        data.style === ContentFeedItemStyle.UNKNOWN ||
                        data.style === ContentFeedItemStyle.SMALL,
                    'w-full h-32 mb-4':
                        data.style === ContentFeedItemStyle.LARGE,
                })}
            >
                {data.image && (
                    <img
                        src={data.image.url}
                        alt="preview"
                        className="h-full w-full object-cover"
                    />
                )}
            </div>

            <div className="flex flex-col justify-center">
                <div className="text-gray-500 font-bold text-xs">
                    {data.subtitle}
                </div>
                <div className="text-semantic-onSurface font-bold text-sm">
                    {data.title}
                </div>
            </div>
        </div>
    );
}
