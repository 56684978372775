import { useAppSelector } from '../redux/react';
import { partnerSelectors, usePartnerProfile } from './store';

type Props = {
    id: string;
    forceShowLegalName?: boolean;
};

export default function PartnerName({ id, forceShowLegalName }: Props) {
    const partner = usePartnerProfile(id);

    const partnerBase = useAppSelector((s) =>
        partnerSelectors.getPartnerById(s, { partnerId: id }),
    );
    return (
        <>
            {partner?.name ||
                partnerBase?.brandName ||
                partnerBase?.legalName ||
                id}
            {forceShowLegalName && <> ({partnerBase?.legalName})</>}
        </>
    );
}
