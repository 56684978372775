import { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import DATEVExport from './DATEVExport';

const PaymentModule: FunctionComponent = () => {
    return (
        <Switch>
            <Route path="/payment" exact>
                <div>
                    <DATEVExport />
                </div>
            </Route>
        </Switch>
    );
};

export default PaymentModule;
