interface ProgressBarProps {
    /** Must be a number between (including) 0 and 100. */
    percent: number;
}

export const ProgressBar = (props: ProgressBarProps) => {
    return (
        <div className="bg-gray-200 h-2 rounded-md">
            <div
                className="bg-green-300 h-2 rounded-md"
                style={{
                    width: `${props.percent.toFixed()}%`,
                }}
            ></div>
        </div>
    );
};
