import ItemCategoryEditor from '../common/ItemCategoryEditor';
import { FunctionComponent, useCallback } from 'react';
import {
    GetPartnerCategoryAuthoringItemsResponse,
    ItemCategoryAuthoring,
    WritePartnerCategoryAuthoringRequest,
} from '../api/model/backoffice';
import axios from 'axios';
import { GetPartnerProfilesResponse } from '../api/model/core';
import { usePartnerProfile } from './store';

const PartnerCategoriesEditor: FunctionComponent = () => {
    const fetchCategories = useCallback(async (): Promise<
        ItemCategoryAuthoring[]
    > => {
        const raw = await axios.get<Object>(`/partnerCategory`);
        const res = GetPartnerCategoryAuthoringItemsResponse.fromJson(
            raw.data as any,
        );
        return res.categories;
    }, []);

    const fetchPartnerIds = useCallback(async () => {
        const raw = await axios.get<Object>(`/partner_profile`);
        const res = GetPartnerProfilesResponse.fromJson(raw.data as any);
        return res.partner.map((r) => r.id);
    }, []);

    const write = useCallback(async (item: ItemCategoryAuthoring) => {
        await axios.post<Object>(
            `/partnerCategory`,
            WritePartnerCategoryAuthoringRequest.toJson({
                item,
            }),
        );
    }, []);

    return (
        <ItemCategoryEditor
            title="Partner Categories"
            fetchCategories={fetchCategories}
            fetchAllItemIds={fetchPartnerIds}
            write={write}
            renderItem={(itemId, index, categoryId) => (
                <PartnerCard
                    key={itemId}
                    id={itemId}
                    index={index}
                    categoryId={categoryId}
                />
            )}
        />
    );
};

export default PartnerCategoriesEditor;

const PartnerCard: FunctionComponent<{
    id: string;
    index: number;
    categoryId: string;
}> = ({ id, index, categoryId }) => {
    const partner = usePartnerProfile(id);

    if (!partner) {
        return <span>Loading...</span>;
    }

    return (
        <div className="bg-white rounded border-gray-100 border-2 space-y-2 w-48 h-56 hover:shadow-lg overflow-hidden">
            <img
                src={partner.logo?.url}
                alt="logo"
                className="h-8 w-8 object-contain rounded-full overflow-hidden border border-gray-300"
            />
            <div className="px-2 pb-4">
                <span className="text-lg font-bold text-gray-700">
                    {partner.name}
                </span>
            </div>
        </div>
    );
};
