import { Editor } from 'slate';
import { useRef } from 'react';
import { hexToInt64 } from '../common/proto_conv';

export default function SlateEditorToolbar(props: { editor: Editor }) {
    const colorRef = useRef<HTMLInputElement>(null);

    return (
        <div>
            <div className="flex space-x-2">
                <button
                    className="rounded w-6 h-6 bg-gray-200 hover:bg-gray-100 font-serif font-bold"
                    onClick={() => props.editor.toggleFormat('bold')}
                >
                    B
                </button>
                <button
                    className="rounded w-6 h-6 bg-gray-200 hover:bg-gray-100 font-serif italic"
                    onClick={() => props.editor.toggleFormat('italic')}
                >
                    I
                </button>
                <div className="rounded w-12 h-6 bg-gray-200 font-serif">
                    <button
                        className="w-6 text-red-600 hover:bg-gray-100 rounded"
                        onClick={() => {
                            function onChange() {
                                props.editor.toggleColor(
                                    colorRef?.current?.value
                                        ? hexToInt64(colorRef?.current?.value)
                                        : 0,
                                );
                                colorRef.current?.removeEventListener(
                                    'change',
                                    onChange,
                                );
                            }
                            colorRef.current?.addEventListener(
                                'change',
                                onChange,
                            );
                            colorRef.current?.click();
                        }}
                    >
                        A
                    </button>
                    <button
                        className="w-6 hover:bg-gray-100 rounded"
                        onClick={() => {
                            props.editor.toggleColor(0);
                        }}
                    >
                        A
                    </button>
                </div>
                <div className="rounded w-18 h-6 bg-gray-200 font-serif">
                    <button
                        className="rounded w-6 h-6 hover:bg-gray-100"
                        onClick={() => props.editor.toggleList('decimal')}
                    >
                        1.
                    </button>
                    <button
                        className="rounded w-6 h-6 hover:bg-gray-100"
                        onClick={() => props.editor.toggleList('disc')}
                    >
                        •
                    </button>
                    <button
                        className="rounded w-6 h-6 hover:bg-gray-100"
                        onClick={() => props.editor.toggleList('check')}
                    >
                        ✓
                    </button>
                </div>
                {/*<button*/}
                {/*    className="rounded w-6 h-6 bg-gray-200 hover:bg-gray-100 font-serif font-bold"*/}
                {/*    onClick={() => {*/}
                {/*        const id = prompt('Partner ID');*/}
                {/*        if (!id) {*/}
                {/*            return;*/}
                {/*        }*/}
                {/*        props.editor.insertPartnerRef(id);*/}
                {/*    }}*/}
                {/*>*/}
                {/*    P*/}
                {/*</button>*/}
            </div>
            <input
                ref={colorRef}
                type="color"
                className="opacity-0 h-0 w-0"
                aria-hidden
            />
        </div>
    );
}
