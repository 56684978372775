import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { authActions, authSelectors } from './store';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';

const AuthProvider: React.FunctionComponent = ({ children }) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            dispatch(authActions.loadUser(user));
        });
    });

    const isInitialized = useAppSelector(authSelectors.isInitialized);

    if (!isInitialized) {
        return <span>.</span>;
    }

    return <>{children}</>;
};
export default AuthProvider;
