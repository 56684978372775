import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { TextTableCell } from '../common/Table';
import ItemList from '../crud-module/ItemList';
import { cashbackLogModule } from './store';
import { Timestamp } from '../api/google/protobuf/timestamp';
import PartnerName from '../partner/PartnerName';

const CashbackLogModule: FunctionComponent = () => {
    return (
        <Switch>
            <Route path="/cashback/log" exact>
                <ItemList
                    module={cashbackLogModule}
                    tableHeaders={[
                        'Partner',
                        'Timestamp',
                        'Amount',
                        'Cashback',
                        'Fee',
                        'Planet Coins',
                    ]}
                >
                    {(item) => {
                        return [
                            <TextTableCell
                                primary={<PartnerName id={item.partnerId} />}
                            />,
                            <TextTableCell
                                primary={Timestamp.toDate(
                                    item.transactionDate!,
                                ).toDateString()}
                            />,
                            <TextTableCell
                                primary={
                                    (
                                        item.transactionAmountUnscaled /
                                        Math.pow(
                                            10,
                                            item.transactionAmountScale,
                                        )
                                    ).toFixed(2) + ' €'
                                }
                            />,
                            <TextTableCell
                                primary={
                                    (
                                        item.issuedCashbackUnscaled /
                                        Math.pow(10, item.issuedCashbackScale)
                                    ).toFixed(2) + ' €'
                                }
                            />,
                            <TextTableCell
                                primary={
                                    (
                                        item.feeUnscaled /
                                        Math.pow(10, item.feeScale)
                                    ).toFixed(2) + ' €'
                                }
                            />,
                            <TextTableCell
                                primary={item.points?.amount ?? 0}
                            />,
                        ];
                    }}
                </ItemList>
            </Route>
        </Switch>
    );
};

export default CashbackLogModule;
