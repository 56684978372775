import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import Scrollable from '../common/Scrollable';
import AssessmentFetchingButton from './AssessmentFetchingButton';
import { AssessmentList } from './AssessmentList';

const AssessmentModule: FunctionComponent = () => {
    return (
        <Switch>
            <Route path="/assessment" exact>
                <Scrollable>
                    <div className="flex mb-4">
                        <h1 className="font-bold text-3xl capitalize">
                            Assessment
                        </h1>
                        <div className="flex-grow flex flex-row items-center justify-end space-x-2">
                            <div className="flex flex-col">
                                <AssessmentFetchingButton />
                            </div>
                        </div>
                    </div>
                    <AssessmentList />
                </Scrollable>
            </Route>
        </Switch>
    );
};

export default AssessmentModule;
