import React, { useCallback } from 'react';
import { Button } from '../common/Button';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { lotteryDrawingActions, lotteryDrawingSelectors } from './store';
import LoadingSpinner from '../common/LoadingSpinner';

const NumberOfTicketsButton: React.FunctionComponent<{ id: string }> = (
    props,
) => {
    const numberOfTickets = useAppSelector((state) =>
        lotteryDrawingSelectors.getNumberOfTickets(state, props),
    );
    const isFetchingNumberOfTickets = useAppSelector((state) =>
        lotteryDrawingSelectors.isFetchingNumberOfTickets(state, props),
    );
    const dispatch = useAppDispatch();

    const id = props.id;
    const fetch = useCallback(() => {
        dispatch(lotteryDrawingActions.fetchNumberOfTickets({ id }));
    }, [dispatch, id]);

    if (isFetchingNumberOfTickets) {
        return <LoadingSpinner />;
    }

    if (numberOfTickets !== undefined) {
        return <span>{numberOfTickets}</span>;
    }

    return <Button secondary label="Get # Tickets" onClick={() => fetch()} />;
};

export default NumberOfTicketsButton;
