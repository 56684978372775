import { useAppDispatch, useAppSelector } from '../redux/react';
import ItemCategoryEditor from '../common/ItemCategoryEditor';
import { FunctionComponent, useCallback, useEffect } from 'react';
import {
    GetChallengesCategoryAuthoringItemsResponse,
    ItemCategoryAuthoring,
    WriteChallengesCategoryAuthoringRequest,
} from '../api/model/backoffice';
import axios from 'axios';
import { challengeActions, challengeSelectors } from './store';
import { GetChallengesResponse } from '../api/model/core';
import cx from 'classnames';

const ChallengeCategoriesEditor: FunctionComponent = () => {
    // preload the tips
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(challengeActions.fetchList());
    }, [dispatch]);

    const fetchCategories = useCallback(async (): Promise<
        ItemCategoryAuthoring[]
    > => {
        const raw = await axios.get<Object>(`/challengeCategory`);
        const res = GetChallengesCategoryAuthoringItemsResponse.fromJson(
            raw.data as any,
        );
        return res.categories;
    }, []);

    const fetchChallengeIds = useCallback(async () => {
        const raw = await axios.get<Object>(`/challenge?limit=500`);
        const res = GetChallengesResponse.fromJson(raw.data as any);
        return res.challenges.map((t) => t.id);
    }, []);

    const write = useCallback(async (item: ItemCategoryAuthoring) => {
        const req = WriteChallengesCategoryAuthoringRequest.toJson({
            item,
        }) as any;
        req.item.color = item.color.toString();
        await axios.post<Object>(`/challengeCategory`, req);
    }, []);

    return (
        <ItemCategoryEditor
            title="Challenge Categories"
            fetchCategories={fetchCategories}
            fetchAllItemIds={fetchChallengeIds}
            write={write}
            renderItem={(itemId, index, categoryId) => (
                <ChallengeCard
                    key={itemId}
                    id={itemId}
                    index={index}
                    categoryId={categoryId}
                />
            )}
        />
    );
};

export default ChallengeCategoriesEditor;

const ChallengeCard: FunctionComponent<{
    id: string;
    index: number;
    categoryId: string;
}> = ({ id, index, categoryId }) => {
    const item = useAppSelector((state) =>
        challengeSelectors.getItem(state, { id }),
    );

    const dispatch = useAppDispatch();
    const fetch = useCallback(
        () => dispatch(challengeActions.fetchSingleItem(id)),
        [dispatch, id],
    );

    if (!item) {
        fetch();
        return <span>Loading...</span>;
    }

    return (
        <div
            className={cx(
                'bg-white rounded border-gray-100 border-2 space-y-2 w-48 h-56 hover:shadow-lg overflow-hidden',
                {
                    'opacity-25':
                        !item.active || item.publishedAt === undefined,
                },
            )}
        >
            {!!item.image && (
                <img
                    src={item.image?.url}
                    alt="cover"
                    className="h-32 w-full object-cover rounded-t"
                />
            )}
            <div className="px-2 pb-4">
                <span className="text-lg font-bold text-gray-700">
                    {item?.name}
                </span>
            </div>
        </div>
    );
};
