interface CheckboxProps {
    readonly label: string;
    readonly checked?: boolean;
    readonly disabled?: boolean;
    readonly onChange?: (value: boolean) => void;
}

export const Checkbox = (props: CheckboxProps) => {
    return (
        <label className="block">
            <span className="text-gray-700">{props.label}</span>
            <input
                className="form-checkbox mt-1 block"
                type="checkbox"
                checked={props.checked}
                disabled={!!props.disabled}
                onChange={(e) => props.onChange?.(e.target.checked)}
            />
        </label>
    );
};

export const RadioButton = (props: CheckboxProps) => {
    return (
        <label className="block">
            <span className="text-gray-700">{props.label}</span>
            <input
                className="form-radio mt-1 block"
                type="radio"
                checked={props.checked}
                disabled={!!props.disabled}
                onChange={(e) => props.onChange?.(e.target.checked)}
            />
        </label>
    );
};
