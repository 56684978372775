import React, { FunctionComponent } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Button } from '../common/Button';
import { TextTableCell } from '../common/Table';
import ItemList from '../crud-module/ItemList';
import { feedModule } from './store';
import FeedItemForm from './FeedItemForm';
import { Timestamp } from '../api/google/protobuf/timestamp';
import { FeedItem } from '../api/model/feed';
import ItemDeletionButton from '../crud-module/ItemDeletionButton';

const FeedModule: FunctionComponent = () => {
    return (
        <Switch>
            <Route path="/feed" exact>
                <ItemList
                    module={feedModule}
                    tableHeaders={['Name', 'Timestamp', 'Actions']}
                >
                    {(item) => {
                        if (item.data.oneofKind !== 'contentFeedItemData') {
                            return [<></>];
                        }
                        return [
                            <TextTableCell
                                primary={item.data.contentFeedItemData.title}
                                secondary={
                                    item.data.contentFeedItemData.subtitle
                                }
                            />,
                            <TimestampCell item={item} />,
                            <div className="inline-flex">
                                <Button
                                    label="Edit"
                                    secondary
                                    to={`/feed/${item.id}/edit`}
                                />
                                <ItemDeletionButton
                                    module={feedModule}
                                    item={item}
                                />
                            </div>,
                        ];
                    }}
                </ItemList>
            </Route>
            <Route path="/feed/create" exact>
                <FeedItemForm id={undefined} />
            </Route>
            <Route
                path="/feed/:id/edit"
                exact
                render={({ match }) => <FeedItemForm id={match.params['id']} />}
            />
        </Switch>
    );
};

export default FeedModule;

function TimestampCell(props: { item: FeedItem }) {
    const ts = Timestamp.toDate(props.item.timestamp!);
    const expiry = props.item.expiry && Timestamp.toDate(props.item.expiry);

    let secondary = (
        <span className="text-sm flex items-center space-x-2 -ml-4">
            <span className="w-2 h-2 bg-green-500 rounded-full" />
            <span>Live</span>
        </span>
    );

    if (ts.valueOf() > Date.now()) {
        secondary = (
            <span className="text-sm flex items-center space-x-2 -ml-4">
                <span className="w-2 h-2 bg-yellow-500 rounded-full" />
                <span>Scheduled</span>
            </span>
        );
    }

    if (expiry && expiry.valueOf() < Date.now()) {
        secondary = (
            <span className="text-sm flex items-center space-x-2 -ml-4">
                <span className="w-2 h-2 bg-blue-500 rounded-full" />
                <span>Expired</span>
            </span>
        );
    } else if (expiry) {
        secondary = (
            <span className="text-sm flex items-center space-x-2 -ml-4">
                <span className="w-2 h-2 bg-green-500 rounded-full" />
                <span>Expires: {expiry.toDateString()}</span>
            </span>
        );
    }

    return <TextTableCell primary={ts.toDateString()} secondary={secondary} />;
}
