// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "accounting/rpc/accounting.proto" (package "one.plan3t.core.accounting.rpc", syntax proto3)
// tslint:disable
import { GetBalanceChangesSummaryResponse } from "../../model/b2c";
import { GetBalanceChangesSummaryRequest } from "../../model/b2c";
import { GetAccountingTransfersResponse } from "../../model/internal";
import { GetAccountingTransfersRequest } from "../../model/internal";
import { SendCashbackNotificationsResponse } from "../../model/internal";
import { SendCashbackNotificationsRequest } from "../../model/internal";
import { ApplyAccountingCommandManualResponse } from "../../model/backoffice";
import { ApplyAccountingCommandManualRequest } from "../../model/backoffice";
import { ApplyAccountingCommandResponse } from "../../model/internal";
import { ApplyAccountingCommandRequest } from "../../model/internal";
import { GetBalanceChangesResponse } from "../../model/b2c";
import { GetBalanceChangesRequest } from "../../model/b2c";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { AccountingCommand } from "../../model/internal";
/**
 * @generated from protobuf message one.plan3t.core.accounting.rpc.PerformSplitBalanceCalculationsRequest
 */
export interface PerformSplitBalanceCalculationsRequest {
    /**
     * @generated from protobuf field: uint32 max_transactions = 1;
     */
    maxTransactions: number;
}
/**
 * @generated from protobuf message one.plan3t.core.accounting.rpc.PerformSplitBalanceCalculationsResponse
 */
export interface PerformSplitBalanceCalculationsResponse {
}
/**
 * @generated from protobuf message one.plan3t.core.accounting.rpc.GetMissingSBCCsRequest
 */
export interface GetMissingSBCCsRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.accounting.rpc.GetMissingSBCCsResponse
 */
export interface GetMissingSBCCsResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.model.AccountingCommand commands = 1;
     */
    commands: AccountingCommand[];
    /**
     * @generated from protobuf field: int64 blocked_queue_size = 2;
     */
    blockedQueueSize: number;
}
/**
 * @generated from protobuf message one.plan3t.core.accounting.rpc.GetMissingSBCCsResponse.SBCC
 */
export interface GetMissingSBCCsResponse_SBCC {
    /**
     * @generated from protobuf field: one.plan3t.core.model.AccountingCommand command = 1;
     */
    command?: AccountingCommand;
    /**
     * @generated from protobuf field: bool mint_non_virtual = 2;
     */
    mintNonVirtual: boolean;
    /**
     * @generated from protobuf field: bool locked = 3;
     */
    locked: boolean;
}
/**
 * @generated from protobuf message one.plan3t.core.accounting.rpc.CreateSBCCRequest
 */
export interface CreateSBCCRequest {
    /**
     * @generated from protobuf field: string command_id = 1;
     */
    commandId: string;
    /**
     * @generated from protobuf field: bool mint_non_virtual = 2;
     */
    mintNonVirtual: boolean;
    /**
     * @generated from protobuf field: bool locked = 3;
     */
    locked: boolean;
}
/**
 * @generated from protobuf message one.plan3t.core.accounting.rpc.CreateSBCCResponse
 */
export interface CreateSBCCResponse {
}
// @generated message type with reflection information, may provide speed optimized methods
class PerformSplitBalanceCalculationsRequest$Type extends MessageType<PerformSplitBalanceCalculationsRequest> {
    constructor() {
        super("one.plan3t.core.accounting.rpc.PerformSplitBalanceCalculationsRequest", [
            { no: 1, name: "max_transactions", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<PerformSplitBalanceCalculationsRequest>): PerformSplitBalanceCalculationsRequest {
        const message = { maxTransactions: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PerformSplitBalanceCalculationsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PerformSplitBalanceCalculationsRequest): PerformSplitBalanceCalculationsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 max_transactions */ 1:
                    message.maxTransactions = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PerformSplitBalanceCalculationsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 max_transactions = 1; */
        if (message.maxTransactions !== 0)
            writer.tag(1, WireType.Varint).uint32(message.maxTransactions);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.accounting.rpc.PerformSplitBalanceCalculationsRequest
 */
export const PerformSplitBalanceCalculationsRequest = new PerformSplitBalanceCalculationsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PerformSplitBalanceCalculationsResponse$Type extends MessageType<PerformSplitBalanceCalculationsResponse> {
    constructor() {
        super("one.plan3t.core.accounting.rpc.PerformSplitBalanceCalculationsResponse", []);
    }
    create(value?: PartialMessage<PerformSplitBalanceCalculationsResponse>): PerformSplitBalanceCalculationsResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PerformSplitBalanceCalculationsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PerformSplitBalanceCalculationsResponse): PerformSplitBalanceCalculationsResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: PerformSplitBalanceCalculationsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.accounting.rpc.PerformSplitBalanceCalculationsResponse
 */
export const PerformSplitBalanceCalculationsResponse = new PerformSplitBalanceCalculationsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMissingSBCCsRequest$Type extends MessageType<GetMissingSBCCsRequest> {
    constructor() {
        super("one.plan3t.core.accounting.rpc.GetMissingSBCCsRequest", []);
    }
    create(value?: PartialMessage<GetMissingSBCCsRequest>): GetMissingSBCCsRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetMissingSBCCsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMissingSBCCsRequest): GetMissingSBCCsRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetMissingSBCCsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.accounting.rpc.GetMissingSBCCsRequest
 */
export const GetMissingSBCCsRequest = new GetMissingSBCCsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMissingSBCCsResponse$Type extends MessageType<GetMissingSBCCsResponse> {
    constructor() {
        super("one.plan3t.core.accounting.rpc.GetMissingSBCCsResponse", [
            { no: 1, name: "commands", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => AccountingCommand },
            { no: 2, name: "blocked_queue_size", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetMissingSBCCsResponse>): GetMissingSBCCsResponse {
        const message = { commands: [], blockedQueueSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetMissingSBCCsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMissingSBCCsResponse): GetMissingSBCCsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.model.AccountingCommand commands */ 1:
                    message.commands.push(AccountingCommand.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 blocked_queue_size */ 2:
                    message.blockedQueueSize = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMissingSBCCsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.model.AccountingCommand commands = 1; */
        for (let i = 0; i < message.commands.length; i++)
            AccountingCommand.internalBinaryWrite(message.commands[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 blocked_queue_size = 2; */
        if (message.blockedQueueSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.blockedQueueSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.accounting.rpc.GetMissingSBCCsResponse
 */
export const GetMissingSBCCsResponse = new GetMissingSBCCsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetMissingSBCCsResponse_SBCC$Type extends MessageType<GetMissingSBCCsResponse_SBCC> {
    constructor() {
        super("one.plan3t.core.accounting.rpc.GetMissingSBCCsResponse.SBCC", [
            { no: 1, name: "command", kind: "message", T: () => AccountingCommand },
            { no: 2, name: "mint_non_virtual", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "locked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<GetMissingSBCCsResponse_SBCC>): GetMissingSBCCsResponse_SBCC {
        const message = { mintNonVirtual: false, locked: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetMissingSBCCsResponse_SBCC>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetMissingSBCCsResponse_SBCC): GetMissingSBCCsResponse_SBCC {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* one.plan3t.core.model.AccountingCommand command */ 1:
                    message.command = AccountingCommand.internalBinaryRead(reader, reader.uint32(), options, message.command);
                    break;
                case /* bool mint_non_virtual */ 2:
                    message.mintNonVirtual = reader.bool();
                    break;
                case /* bool locked */ 3:
                    message.locked = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetMissingSBCCsResponse_SBCC, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* one.plan3t.core.model.AccountingCommand command = 1; */
        if (message.command)
            AccountingCommand.internalBinaryWrite(message.command, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bool mint_non_virtual = 2; */
        if (message.mintNonVirtual !== false)
            writer.tag(2, WireType.Varint).bool(message.mintNonVirtual);
        /* bool locked = 3; */
        if (message.locked !== false)
            writer.tag(3, WireType.Varint).bool(message.locked);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.accounting.rpc.GetMissingSBCCsResponse.SBCC
 */
export const GetMissingSBCCsResponse_SBCC = new GetMissingSBCCsResponse_SBCC$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSBCCRequest$Type extends MessageType<CreateSBCCRequest> {
    constructor() {
        super("one.plan3t.core.accounting.rpc.CreateSBCCRequest", [
            { no: 1, name: "command_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "mint_non_virtual", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "locked", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<CreateSBCCRequest>): CreateSBCCRequest {
        const message = { commandId: "", mintNonVirtual: false, locked: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateSBCCRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateSBCCRequest): CreateSBCCRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string command_id */ 1:
                    message.commandId = reader.string();
                    break;
                case /* bool mint_non_virtual */ 2:
                    message.mintNonVirtual = reader.bool();
                    break;
                case /* bool locked */ 3:
                    message.locked = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateSBCCRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string command_id = 1; */
        if (message.commandId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.commandId);
        /* bool mint_non_virtual = 2; */
        if (message.mintNonVirtual !== false)
            writer.tag(2, WireType.Varint).bool(message.mintNonVirtual);
        /* bool locked = 3; */
        if (message.locked !== false)
            writer.tag(3, WireType.Varint).bool(message.locked);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.accounting.rpc.CreateSBCCRequest
 */
export const CreateSBCCRequest = new CreateSBCCRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateSBCCResponse$Type extends MessageType<CreateSBCCResponse> {
    constructor() {
        super("one.plan3t.core.accounting.rpc.CreateSBCCResponse", []);
    }
    create(value?: PartialMessage<CreateSBCCResponse>): CreateSBCCResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<CreateSBCCResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateSBCCResponse): CreateSBCCResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: CreateSBCCResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.accounting.rpc.CreateSBCCResponse
 */
export const CreateSBCCResponse = new CreateSBCCResponse$Type();
/**
 * @generated ServiceType for protobuf service one.plan3t.core.accounting.rpc.Accounting
 */
export const Accounting = new ServiceType("one.plan3t.core.accounting.rpc.Accounting", [
    { name: "GetTransactionHistory", options: {}, I: GetBalanceChangesRequest, O: GetBalanceChangesResponse },
    { name: "ApplyCommand", options: {}, I: ApplyAccountingCommandRequest, O: ApplyAccountingCommandResponse },
    { name: "ApplyCommandManual", options: {}, I: ApplyAccountingCommandManualRequest, O: ApplyAccountingCommandManualResponse },
    { name: "SendCashbackNotifications", options: {}, I: SendCashbackNotificationsRequest, O: SendCashbackNotificationsResponse },
    { name: "GetTransfersRequest", options: {}, I: GetAccountingTransfersRequest, O: GetAccountingTransfersResponse },
    { name: "GetBalanceChangeSummary", options: {}, I: GetBalanceChangesSummaryRequest, O: GetBalanceChangesSummaryResponse },
    { name: "PerformSplitBalanceCalculations", options: {}, I: PerformSplitBalanceCalculationsRequest, O: PerformSplitBalanceCalculationsResponse },
    { name: "GetMissingSBCCs", options: {}, I: GetMissingSBCCsRequest, O: GetMissingSBCCsResponse },
    { name: "CreateSBCC", options: {}, I: CreateSBCCRequest, O: CreateSBCCResponse }
]);
