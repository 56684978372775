import { FunctionComponent, SyntheticEvent } from 'react';
import { Form, Formik, useField } from 'formik';
import { CreateVoucherRequest } from '../api/model/backoffice';
import { Button } from '../common/Button';
import { FormSelectField, FormTextField } from '../common/FormFields';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { voucherActions, voucherSelectors } from './store';
import CreatedPreviewDialog from './CreatedPreviewDialog';

const CreateVoucherForm: FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const error = useAppSelector(voucherSelectors.getWriteError);
    const draft = useAppSelector(voucherSelectors.getDraft);
    const isWriting = useAppSelector(voucherSelectors.isWriting);

    const onSubmit = (req: CreateVoucherRequest) => {
        dispatch(voucherActions.createVoucher(req));
    };

    const validate = (voucher: CreateVoucherRequest) => {
        const errors: any = {};
        if (voucher.value < 1 || voucher.value > 10000) {
            errors.value = 'Must be between 1 and 10000 (inclusive)';
        }
        if (voucher.code.length === 0) {
            errors.code = 'Please specify or generate a code';
        } else if (voucher.code.length !== 19) {
            errors.code = 'Code must be in the form of XXXX-XXXX-XXXX-XXXX';
        } else if (voucher.code.toUpperCase() !== voucher.code) {
            errors.code = 'Code can only contain numbers and uppercase letters';
        } else if (
            !/^[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}-[A-Z0-9]{4}$/.test(
                voucher.code,
            )
        ) {
            errors.code = 'Code must be in the form of XXXX-XXXX-XXXX-XXXX';
        }
        if (voucher.comment.trim().length === 0) {
            errors.comment =
                'A comment describing the intended use is required';
        }
        return errors;
    };

    return (
        <>
            <CreatedPreviewDialog />
            <Formik
                onSubmit={onSubmit}
                initialValues={draft}
                validate={validate}
                enableReinitialize
            >
                <Form className="px-4 pt-8 space-y-2">
                    <div className="flex items-center space-x-2">
                        <h1 className="sm:font-bold lg:text-3xl">
                            New Voucher
                        </h1>
                        <div className="flex-shrink">
                            <Button
                                type="submit"
                                label={isWriting ? '...' : 'Create'}
                                primary
                                disabled={isWriting}
                            />
                        </div>
                    </div>
                    {!!error && (
                        <div className="px-2 py-4 bg-red-100 text-red-400 rounded-md">
                            {error}
                        </div>
                    )}
                    <div className="space-y-2 max-w-lg">
                        <div className="flex items-center space-x-4">
                            <div className="flex-grow">
                                <FormTextField
                                    name="code"
                                    label="Code"
                                    placeholder="XXXX-XXXX-XXXX-XXXX"
                                />
                            </div>
                            <GenerateCodeButton />
                        </div>
                        <FormTextField
                            name="comment"
                            label="Purpose/Intended use"
                        />
                        <FormSelectField
                            name="value"
                            label="Value"
                            options={[
                                10, 50, 100, 200, 300, 500, 1000, 2000, 3000,
                                4000, 5000, 10000,
                            ].map((value) => ({
                                label: value.toString(10),
                                value,
                            }))}
                        />
                    </div>
                </Form>
            </Formik>
        </>
    );
};

export default CreateVoucherForm;

const GenerateCodeButton: FunctionComponent = () => {
    const [, , h] = useField('code');

    const onClick = (e: SyntheticEvent) => {
        e.preventDefault();

        // no ambiguous characters, charset taken from https://ux.stackexchange.com/a/21078
        const pool = 'ABCDEFGHJKLMNPQRTWXY34689';
        function generatePart() {
            let r = '';
            for (let i = 0; i < 4; i++) {
                r += pool.charAt(Math.floor(Math.random() * pool.length));
            }
            return r;
        }
        h.setValue(
            `${generatePart()}-${generatePart()}-${generatePart()}-${generatePart()}`,
        );
    };

    return (
        <div>
            <Button secondary label="Generate" onClick={onClick} />
        </div>
    );
};
