import {
    Plan3tRichText,
    RTElement,
    RTElementType,
    RTNode,
    RTText,
} from '../api/model/richtext';
import { Any } from '../api/google/protobuf/any';
import { DoubleValue, StringValue } from '../api/google/protobuf/wrappers';
import { ImageResource } from '../api/model/core';

export type SlateElement =
    | { type: 'paragraph'; children: SlateText[] }
    | { type: 'partner_ref'; id: string; children: SlateText[] }
    | {
          type: 'list';
          marker: 'disc' | 'decimal' | 'check';
          children: SlateElement[];
      };
export type SlateText = {
    text: string;
    bold: boolean;
    italic: boolean;
    color: number;
};

export type StandardLayoutType = 'one';

export type StandardLayoutOne = {
    _type: 'one';
    image: ImageResource;
    headline: string;
    paragraphs: Array<SlateElement>;
};

export type StandardLayout = StandardLayoutOne;

export const emptyStandardLayoutOne: StandardLayoutOne = {
    _type: 'one',
    image: { id: 'internal::', url: '' },
    headline: 'Headline1',
    paragraphs: [
        {
            type: 'paragraph',
            children: [
                { text: 'Text A', bold: false, italic: false, color: 0 },
            ],
        },
        {
            type: 'paragraph',
            children: [
                { text: 'Text A', bold: false, italic: false, color: 0 },
            ],
        },
        {
            type: 'list',
            marker: 'disc',
            children: [
                {
                    type: 'paragraph',
                    children: [
                        {
                            text: 'Text A',
                            bold: false,
                            italic: false,
                            color: 0,
                        },
                    ],
                },
            ],
        },
    ],
};

function slateElementTypeToProto(t: SlateElement['type']): RTElementType {
    switch (t) {
        case 'paragraph':
            return RTElementType.paragraph;
        case 'list':
            return RTElementType.list;
        case 'partner_ref':
            return RTElementType.partner_ref;
    }
}

function attributesToProto(element: SlateElement): RTElement['attributes'] {
    switch (element.type) {
        case 'paragraph':
            return {
                style: Any.pack(
                    StringValue.create({
                        value: 'body1',
                    }),
                    StringValue,
                ),
            };
        case 'list':
            return {
                marker: Any.pack(
                    StringValue.create({
                        value: element.marker,
                    }),
                    StringValue,
                ),
                style: Any.pack(
                    StringValue.create({
                        value: 'body1',
                    }),
                    StringValue,
                ),
            };
        case 'partner_ref':
            return {
                id: Any.pack(
                    StringValue.create({
                        value: element.id,
                    }),
                    StringValue,
                ),
            };
    }
}

function slateNodeToProto(node: SlateElement | SlateText): RTNode {
    if ('type' in node) {
        return RTNode.create({
            value: {
                oneofKind: 'element',
                element: {
                    type: slateElementTypeToProto(node.type),
                    children: node.children.map(slateNodeToProto),
                    attributes: attributesToProto(node),
                },
            },
        });
    }
    return RTNode.create({
        value: {
            oneofKind: 'text',
            text: node,
        },
    });
}

function protoNodeToSlate(node: RTNode): SlateElement | SlateText {
    if (node.value.oneofKind === 'text') {
        return node.value.text;
    }
    if (node.value.oneofKind === 'element') {
        switch (node.value.element.type) {
            case RTElementType.paragraph:
                return {
                    type: 'paragraph',
                    children: node.value.element.children.map(
                        protoNodeToSlate,
                    ) as SlateText[],
                };
            case RTElementType.list:
                return {
                    type: 'list',
                    marker: Any.unpack(
                        node.value.element.attributes['marker'],
                        StringValue,
                    ).value as any,
                    children: node.value.element.children.map(
                        protoNodeToSlate,
                    ) as SlateElement[],
                };
            case RTElementType.partner_ref:
                return {
                    type: 'partner_ref',
                    id: Any.unpack(
                        node.value.element.attributes['id'],
                        StringValue,
                    ).value,
                    children: node.value.element.children.map(
                        protoNodeToSlate,
                    ) as SlateText[],
                };
            default:
                throw new Error('cannot convert other element types yet.');
        }
    }
    throw new Error('unsupported node value kind: ' + node.value.oneofKind);
}

export function standardLayoutToProtoRichText(
    s: StandardLayout,
): Plan3tRichText {
    if (s._type === 'one') {
        return {
            children: [
                RTNode.create({
                    value: {
                        oneofKind: 'element',
                        element: RTElement.create({
                            type: RTElementType.column,
                            attributes: {
                                gap: Any.pack(
                                    DoubleValue.create({ value: 32 }),
                                    DoubleValue,
                                ),
                            },
                            children: [
                                RTNode.create({
                                    value: {
                                        oneofKind: 'element',
                                        element: RTElement.create({
                                            type: RTElementType.image,
                                            attributes: {
                                                id: Any.pack(
                                                    StringValue.create({
                                                        value: s.image.id,
                                                    }),
                                                    StringValue,
                                                ),
                                                url: Any.pack(
                                                    StringValue.create({
                                                        value: s.image.url,
                                                    }),
                                                    StringValue,
                                                ),
                                            },
                                            children: [],
                                        }),
                                    },
                                }),
                                RTNode.create({
                                    value: {
                                        oneofKind: 'element',
                                        element: RTElement.create({
                                            type: RTElementType.column,
                                            attributes: {
                                                gap: Any.pack(
                                                    DoubleValue.create({
                                                        value: 16,
                                                    }),
                                                    DoubleValue,
                                                ),
                                            },
                                            children: [
                                                RTNode.create({
                                                    value: {
                                                        oneofKind: 'element',
                                                        element:
                                                            RTElement.create({
                                                                type: RTElementType.paragraph,
                                                                attributes: {
                                                                    style: Any.pack(
                                                                        StringValue.create(
                                                                            {
                                                                                value: 'headline4',
                                                                            },
                                                                        ),
                                                                        StringValue,
                                                                    ),
                                                                },
                                                                children: [
                                                                    RTNode.create(
                                                                        {
                                                                            value: {
                                                                                oneofKind:
                                                                                    'text',
                                                                                text: RTText.create(
                                                                                    {
                                                                                        text: s.headline,
                                                                                    },
                                                                                ),
                                                                            },
                                                                        },
                                                                    ),
                                                                ],
                                                            }),
                                                    },
                                                }),
                                                ...s.paragraphs.map(
                                                    slateNodeToProto,
                                                ),
                                            ],
                                        }),
                                    },
                                }),
                            ],
                        }),
                    },
                }),
            ],
        };
    }
    return { children: [] };
}

export function richTextToStandardLayout(
    s: Plan3tRichText | undefined,
): StandardLayout | undefined {
    return richTextToStandardLayoutOne(s);
}

function richTextToStandardLayoutOne(
    s: Plan3tRichText | undefined,
): StandardLayoutOne | undefined {
    if (s === undefined) {
        return {
            _type: 'one',
            image: {
                id: '',
                url: '',
            },
            headline: '',
            paragraphs: [],
        };
    }

    if (s.children[0]?.value?.oneofKind !== 'element') {
        return undefined;
    }
    if (
        s.children[0]?.value?.element?.children[1]?.value.oneofKind !==
        'element'
    ) {
        return undefined;
    }

    if (
        s.children[0]?.value?.element?.children[0].value?.oneofKind !==
        'element'
    ) {
        return undefined;
    }

    const imageElement =
        s.children[0]?.value?.element?.children[0]?.value?.element;

    if (imageElement.type !== RTElementType.image) {
        return undefined;
    }

    const textColumn = s.children[0].value.element.children[1].value.element;

    if (textColumn.children[0].value.oneofKind !== 'element') {
        return undefined;
    }
    const headlineParagraph = textColumn.children[0].value.element;

    if (headlineParagraph.children[0].value.oneofKind !== 'text') {
        return undefined;
    }

    return {
        _type: 'one',
        image: {
            id: Any.unpack(imageElement.attributes['id'], StringValue).value,
            url: Any.unpack(imageElement.attributes['url'], StringValue).value,
        },
        headline: headlineParagraph.children[0].value.text.text,
        paragraphs: textColumn.children
            .slice(1)
            .map(protoNodeToSlate) as SlateElement[],
    };
}
