import axios from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
    PostCreatePartnerBaseRequest,
    GetPartnersResponse,
    PostCreatePartnerBaseResponse,
    GetImpersonationTokenRequest,
    GetImpersonationTokenResponse,
    UpdatePartnerBaseRequest,
    UpdatePartnerBaseResponse,
} from '../api/model/backoffice';
import {
    GetPartnerProfilesResponse,
    PartnerBase,
    PartnerProfile,
} from '../api/model/core';
import { AppState } from '../redux/AppStore';
import { getPartnerManagerUrl } from '../api';
import { snackbarActions } from '../snackbar/store';
import { Timestamp } from '../api/google/protobuf/timestamp';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { useEffect, useMemo } from 'react';
import { selector } from 'recoil';

const PAGE_SIZE = 250;

type saveProfileDraftArgs = {
    id: string;
    legalName: string;
    brandName: string;
    email: string;
    affiliateOnly: boolean;
};

const createPartnerBase = createAsyncThunk(
    'partner/write/submit',
    async (payload: saveProfileDraftArgs) => {
        const req: PostCreatePartnerBaseRequest = {
            id: payload.id,
            legalName: payload.legalName,
            brandName: payload.brandName,
            managerEmail: payload.email,
            pipedriveId: '',
            datevId: '',
            affiliateOnly: payload.affiliateOnly,
        };

        const raw = await axios.post<PostCreatePartnerBaseResponse>(
            '/partner',
            req,
        );

        const res = PostCreatePartnerBaseResponse.fromJson(raw.data as any);

        await navigator.clipboard.writeText(res.loginLink);

        return {
            id: payload.id,
            legalName: payload.legalName,
            brandName: payload.brandName,
            loginLink: res.loginLink,
            pipedriveId: req.pipedriveId,
            datevId: req.datevId,
            affiliateOnly: req.affiliateOnly,
        };
    },
);

const fetchPage = async (
    page_size: number,
    page_token: string,
): Promise<GetPartnersResponse> => {
    const raw = await axios.get<Object>(
        `/partner?page_size=${page_size}&page_token=${page_token}`,
    );
    return GetPartnersResponse.fromJson(raw.data as any);
};

const fetchPartners = createAsyncThunk(
    'partner/fetch',
    async (_: void, { getState }) => {
        const s = (getState() as AppState).partner;
        if (!s.hasMore) {
            return GetPartnersResponse.create({
                partners: [],
                nextPageToken: '--invalid',
            });
        }
        return fetchPage(PAGE_SIZE, s.nextPageToken);
    },
);

const fetchPartnerProfiles = createAsyncThunk(
    'partner_profiles/fetch',
    async (_: void, { getState }) => {
        const raw = await axios.get<Object>(`/partner_profile`);
        return GetPartnerProfilesResponse.fromJson(raw.data as any);
    },
);

const impersonatePartner = createAsyncThunk(
    'partner/impersonate',
    async (args: { partnerId: string }) => {
        const req: GetImpersonationTokenRequest = {
            partnerId: args.partnerId,
        };

        const raw = await axios.post('/partner/impersonate', req);

        const res = GetImpersonationTokenResponse.fromJson(raw.data as any);

        window.open(
            getPartnerManagerUrl() +
                '?__plan3t_impersonate_token=' +
                encodeURIComponent(res.token),
            '_blank',
        );
    },
);

const copyDeepLink = createAsyncThunk(
    'partner/copyDeepLink',
    async (args: { partnerId: string }, { dispatch }) => {
        const deepLink = `/partnerProfile?partner=${args.partnerId}`;
        await navigator.clipboard.writeText(deepLink);

        dispatch(
            snackbarActions.sendMessage({
                title: 'DeepLink copied to clipboard',
                subtitle: deepLink,
            }),
        );
    },
);

const updatePartnerBase = createAsyncThunk(
    'partner/update',
    async (
        args: Partial<UpdatePartnerBaseRequest> &
            Pick<UpdatePartnerBaseRequest, 'id'>,
    ) => {
        const raw = await axios.patch('/partner', args);

        return UpdatePartnerBaseResponse.fromJson(raw.data);
    },
);

type State = {
    partnerIds: string[];
    partnersById: Record<string, PartnerBase>;
    profilesById: Record<string, PartnerProfile>;
    isFetching: boolean;
    isFetchingProfiles: boolean;
    fetchError: any;
    currentPage: number;
    loadedPages: number;
    hasMore: boolean;
    nextPageToken: string;

    impersonationState: Record<
        string,
        undefined | 'pending' | 'success' | 'failed'
    >;
};

const initialState: State = {
    partnerIds: [],
    partnersById: {},
    profilesById: {},
    isFetching: false,
    isFetchingProfiles: false,
    fetchError: undefined,
    currentPage: 0,
    loadedPages: 0,
    hasMore: true,
    nextPageToken: '',

    impersonationState: {},
};

const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        navigateToNextPage(state) {
            state.currentPage++;
        },
        navigateToPreviousPage(state) {
            if (state.currentPage === 0) {
                return;
            }
            state.currentPage--;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchPartners.pending, (state) => {
            state.isFetching = true;
        });
        builder.addCase(fetchPartners.fulfilled, (state, action) => {
            state.isFetching = false;

            const asSet = new Set(state.partnerIds);

            action.payload.partners.forEach((partner) => {
                asSet.add(partner.id);
                state.partnersById[partner.id] = partner;
            });
            state.partnerIds = Array.from(asSet);

            state.hasMore = action.payload.partners.length === PAGE_SIZE;
            state.loadedPages = Math.ceil(state.partnerIds.length / PAGE_SIZE);
            state.nextPageToken = action.payload.nextPageToken;
        });
        builder.addCase(fetchPartners.rejected, (state, action) => {
            state.isFetching = false;
            state.fetchError = action.error;
        });
        builder.addCase(createPartnerBase.fulfilled, (state, action) => {
            // perform optimistic update
            state.partnersById[action.payload.id] = {
                ...action.payload,
                createdAt: Timestamp.now(),
                createdBy: 'you',
                modifiedAt: Timestamp.now(),
                modifiedBy: 'you',
            };
            state.partnerIds.unshift(action.payload.id);
        });

        builder.addCase(impersonatePartner.pending, (state, action) => {
            state.impersonationState[action.meta.arg.partnerId] = 'pending';
        });
        builder.addCase(impersonatePartner.fulfilled, (state, action) => {
            state.impersonationState[action.meta.arg.partnerId] = 'success';
        });
        builder.addCase(impersonatePartner.rejected, (state, action) => {
            state.impersonationState[action.meta.arg.partnerId] = 'failed';
        });

        builder.addCase(fetchPartnerProfiles.pending, (state) => {
            state.isFetchingProfiles = true;
        });
        builder.addCase(fetchPartnerProfiles.rejected, (state) => {
            state.isFetchingProfiles = false;
        });
        builder.addCase(fetchPartnerProfiles.fulfilled, (state, action) => {
            state.isFetchingProfiles = false;
            action.payload.partner.forEach((partner) => {
                state.profilesById[partner.id] = partner;
            });
        });
        builder.addCase(updatePartnerBase.fulfilled, (state, action) => {
            state.partnersById[action.meta.arg.id] = {
                ...state.partnersById[action.meta.arg.id],
                ...action.meta.arg,
            };
        });
    },
});

export const partnerReducer = partnerSlice.reducer;
export const partnerActions = {
    ...partnerSlice.actions,
    createPartnerBase,
    fetchPartners,
    fetchPartnerProfiles,
    impersonatePartner,
    copyDeepLink,
    updatePartnerBase,
};

export const partnerSelectors = {
    getCurrentPageNumber: (state: AppState) => state.partner.currentPage,
    getCurrentPage: (state: AppState): PartnerBase[] => {
        const { currentPage, partnerIds, partnersById } = state.partner;

        const start = currentPage * PAGE_SIZE;
        const end = start + PAGE_SIZE;

        const idsForPage = partnerIds.slice(start, end);

        return idsForPage.map((id) => partnersById[id]);
    },
    getPageSize: (state: AppState) => PAGE_SIZE,
    hasMore: (state: AppState): boolean =>
        state.partner.fetchError === undefined &&
        (state.partner.hasMore ||
            state.partner.currentPage + 1 < state.partner.loadedPages),
    impersonationState: (state: AppState, props: { partnerId: string }) =>
        state.partner.impersonationState[props.partnerId],
    getPartnerById: (
        state: AppState,
        props: { partnerId: string },
    ): PartnerBase | undefined => state.partner.partnersById[props.partnerId],
} as const;

export const partnerBaseListQuery = selector({
    key: 'partner/partnerBaseListQuery',
    get: async () => {
        const raw = await axios.get<Object>(
            `/partner?page_size=${PAGE_SIZE}&page_token=`,
        );
        return GetPartnersResponse.fromJson(raw.data as any).partners;
    },
});

export const partnerProfileListQuery = selector({
    key: 'partner/partnerProfileListQuery',
    get: async () => {
        const raw = await axios.get<Object>(`/partner_profile`);
        return GetPartnerProfilesResponse.fromJson(raw.data as any).partner;
    },
});

export function usePartnerBaseList() {
    const dispatch = useAppDispatch();
    const partners = useAppSelector((state) =>
        partnerSelectors.getCurrentPage(state),
    );
    const hasLoaded = partners.length > 0;

    useEffect(() => {
        if (!hasLoaded) {
            dispatch(partnerActions.fetchPartners());
        }
    }, [dispatch, hasLoaded]);

    return partners;
}

export function usePartnerProfileList(): Array<PartnerProfile> {
    usePartnerBaseList(); // trigger load of partner base items
    const dispatch = useAppDispatch();
    const partners = useAppSelector((state) =>
        Object.values(state.partner.profilesById),
    );
    const isFetching = useAppSelector(
        (state) => state.partner.isFetchingProfiles,
    );
    const hasLoaded = partners.length > 0;

    useEffect(() => {
        if (!hasLoaded && !isFetching) {
            dispatch(partnerActions.fetchPartnerProfiles());
        }
    }, [dispatch, hasLoaded, isFetching]);

    return partners;
}

export function usePartnerProfile(id: string): PartnerProfile | undefined {
    const profiles = usePartnerProfileList();
    return useMemo(() => profiles.find((p) => p.id === id), [id, profiles]);
}
