import * as React from 'react';
import { Button } from '../common/Button';
import axios from 'axios';
import { SyntheticEvent } from 'react';
import Scrollable from '../common/Scrollable';

const SendPartnerManagerInvite: React.FunctionComponent = () => {
    const [email, setEmail] = React.useState('');

    const onSubmit = React.useCallback(
        async (e: SyntheticEvent) => {
            e.preventDefault();

            try {
                const data = new FormData();
                data.append('email', email);

                await axios.post(`/debug/sendInvite`, data);
                alert('Accepted');
                setEmail('');
            } catch (e) {
                alert('Failed');
            }
        },
        [email, setEmail],
    );

    return (
        <Scrollable>
            <form
                className="container max-w-xl flex flex-col space-y-6 py-6"
                onSubmit={onSubmit}
            >
                <div className="flex mb-4">
                    <h1 className="font-medium text-3xl">Send Test E-Mail</h1>
                </div>
                <label>
                    <span className="mb-2">To:</span>
                    <input
                        type="text"
                        className="form-input w-full"
                        value={email}
                        required
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="bob@example.com"
                    />
                </label>
                <Button label="Send" primary onClick={onSubmit} />
            </form>
        </Scrollable>
    );
};

export default SendPartnerManagerInvite;
