import * as React from 'react';
import { AppState } from '../redux/AppStore';
import { connect, ConnectedProps } from 'react-redux';
import { reviewActions, reviewSelectors } from './store';
import { useState } from 'react';
import Scrollable from '../common/Scrollable';
import { Table, TableHeader, TableRow, TextTableCell } from '../common/Table';
import { Button } from '../common/Button';
import { tmpGetReviewLink } from '../api';

const mapStateToProps = (state: AppState) => ({
    reviewRequests: reviewSelectors.getAllPending(state),
});

const connector = connect(mapStateToProps, {
    fetchReviewRequests: reviewActions.fetchReviewRequests,
});

type Props = ConnectedProps<typeof connector>;

const PendingReviews: React.FunctionComponent<Props> = (props) => {
    const { reviewRequests, fetchReviewRequests } = props;

    const [hasFetched, setHasFetched] = useState(false);

    React.useEffect(() => {
        if (!hasFetched) {
            fetchReviewRequests();
            setHasFetched(true);
        }
    }, [fetchReviewRequests, hasFetched]);

    return (
        <Scrollable>
            <div className="flex mb-4">
                <h1 className="font-bold text-3xl">Review</h1>
            </div>
            {reviewRequests.length === 0 && (
                <div className="relative rounded-lg overflow-hidden h-64 mb-6">
                    <img
                        src="https://source.unsplash.com/1600x900/?relax,nature"
                        alt="relax"
                        className="absolute inset-0 object-cover object-center"
                    />
                    <div className="absolute inset-0 bg-semantic-primary bg-opacity-50" />
                    <span className="absolute inset-0 flex items-center justify-center">
                        <span className="text-2xl text-white">
                            No pending reviews!
                        </span>
                    </span>
                </div>
            )}
            <Table header={<TableHeader labels={['ID', 'Type', 'Action']} />}>
                {reviewRequests.map((reviewRequests) => (
                    <TableRow
                        key={reviewRequests.payloadId}
                        contents={[
                            <TextTableCell
                                primary={reviewRequests.payloadId}
                            />,
                            <TextTableCell
                                primary={reviewRequests.payloadType}
                            />,
                            <Button
                                primary
                                label="Open"
                                onClick={() =>
                                    window.open(
                                        tmpGetReviewLink(
                                            reviewRequests.payloadId,
                                            reviewRequests.payloadType,
                                            reviewRequests.magicLinkId,
                                        ),
                                        '_blank',
                                    )
                                }
                            />,
                        ]}
                    />
                ))}
            </Table>
        </Scrollable>
    );
};

export default connector(PendingReviews);
