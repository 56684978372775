import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

import './tailwind.generated.css';
import '@fontsource/rubik/300.css';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import '@fontsource/rubik/700.css';

import App from './App';
import AppStore from './redux/AppStore';
import * as api from './api';
import AuthProvider from './auth/AuthProvider';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from '@ui5/webcomponents-react';
// @ts-ignore
import { setTheme } from '@ui5/webcomponents-base/dist/config/Theme';
import '@ui5/webcomponents/dist/Assets';
import '@ui5/webcomponents-icons/dist/AllIcons.js';

// @ts-ignore
setTheme('sap_horizon');

api.init();

ReactDOM.render(
    <StrictMode>
        <ThemeProvider>
            <AppStore>
                <RecoilRoot>
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </RecoilRoot>
            </AppStore>
        </ThemeProvider>
    </StrictMode>,
    document.getElementById('root'),
);
