import { ContentFeedItemStyle } from '../api/model/feed';
import React, { FunctionComponent } from 'react';
import {
    FormSelectField,
    FormSingleImageField,
    FormTextField,
} from '../common/FormFields';
import FormPartnerAssociationField from '../common/FormPartnerAssociationField';

export const ContentFeedItemDataField: FunctionComponent = () => {
    const name = 'data.contentFeedItemData';
    return (
        <div>
            <FormTextField name="title" label="Title" fieldNamePrefix={name} />
            <FormTextField
                name="subtitle"
                label="Subtitle"
                fieldNamePrefix={name}
            />
            <FormSingleImageField
                name="image"
                label="Image"
                message="A preview image which will be displayed before a user start viewings a tip"
                fieldNamePrefix={name}
            />
            <FormTextField name="route" label="Route" fieldNamePrefix={name} />
            <FormPartnerAssociationField
                name="partnerId"
                label="Partner (for impression tracking, should be 'None' for non partner content)"
                single
                fieldNamePrefix={name}
            />
            <FormSelectField
                name="style"
                label="Style"
                fieldNamePrefix={name}
                options={[
                    { label: 'Default', value: ContentFeedItemStyle.UNKNOWN },
                    { label: 'small', value: ContentFeedItemStyle.SMALL },
                    { label: 'large', value: ContentFeedItemStyle.LARGE },
                ]}
            />
        </div>
    );
};
