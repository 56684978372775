import { useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { accountQuery, transactionListQuery } from './store';
import * as Ledger from '../api/ledger/rpc/ledger';
import {
    AnalyticalTable,
    Button,
    FlexBox,
    Label,
    ValueState,
} from '@ui5/webcomponents-react';
import { useMemo } from 'react';
import { useStartExpanded } from './useStartExpanded';
import { useFormatMoney } from '../common/formatting';

export default function LedgerTransactionList() {
    const txs = useRecoilValueLoadable(transactionListQuery);

    const data: Array<{ entry: Ledger.Entry; tx: Ledger.Transaction }> =
        useMemo(
            () =>
                txs
                    .map(({ transactions }) =>
                        transactions.flatMap((tx) =>
                            tx.entries.map((entry) => ({ entry, tx })),
                        ),
                    )
                    .valueMaybe() || [],
            [txs],
        );

    return (
        <AnalyticalTable
            loading={txs.state === 'loading'}
            // groupBy={['tx.id']}
            sortable={false}
            filterable={false}
            highlightField={(r) =>
                (r.tx as Ledger.Transaction).state ===
                Ledger.TransactionState.POSTED
                    ? ValueState.Success
                    : ValueState.Warning
            }
            withRowHighlight
            groupable={false}
            groupBy={['tx.id']}
            header="Ledger Entries"
            tableHooks={[useStartExpanded]}
            columns={[
                {
                    Header: 'ID',
                    accessor: 'tx.id',
                },
                {
                    Header: 'Account',
                    accessor: 'entry.accountId',
                    Cell: ({ cell }) => {
                        return <AccountNameDisplay accountID={cell.value} />;
                    },
                },
                {
                    Header: 'Date',
                    accessor: 'tx.effectiveDate',
                    Cell: ({ cell }) => {
                        if (!cell.value) {
                            return null;
                        }
                        const ts = new Date(
                            cell.value.year,
                            cell.value.month - 1,
                            cell.value.day,
                        );
                        return <Label>{ts.toISOString().slice(0, 10)}</Label>;
                    },
                },
                {
                    id: 'debit',
                    Header: 'Debit',
                    accessor: 'entry',
                    Cell: ({ cell }) =>
                        cell.value && (
                            <MoneyDisplay entry={cell.value} kind="debit" />
                        ),
                },
                {
                    id: 'credit',
                    Header: 'Credit',
                    accessor: 'entry',
                    Cell: ({ cell }) =>
                        cell.value && (
                            <MoneyDisplay entry={cell.value} kind="credit" />
                        ),
                },
                {
                    id: 'actions',
                    Header: 'Actions',
                    accessor: '.',
                    disableFilters: true,
                    disableGroupBy: true,
                    disableResizing: true,
                    disableSortBy: true,
                    Cell: ({ row, webComponentsReactProperties }) => {
                        if (!row.isGrouped) {
                            return null;
                        }
                        // disable buttons if overlay is active to prevent focus
                        const isOverlay =
                            webComponentsReactProperties.showOverlay;
                        const canBeModified =
                            row &&
                            row.tx &&
                            (row.tx as Ledger.Transaction).state ===
                                Ledger.TransactionState.PENDING;

                        if (canBeModified) {
                            return null;
                        }
                        return (
                            <FlexBox>
                                <Button
                                    icon="complete"
                                    disabled={isOverlay}
                                    onClick={() => alert('Not implemented')}
                                />
                            </FlexBox>
                        );
                    },
                },
            ]}
            data={data}
        />
    );
}

function AccountNameDisplay(props: { slot?: string; accountID: string }) {
    const account = useRecoilValue(accountQuery(props.accountID));

    return (
        <Label slot={props.slot}>
            {account?.number} {account?.name ?? props.accountID}
        </Label>
    );
}

function MoneyDisplay(props: {
    slot?: string;
    entry: Ledger.Entry;
    kind: 'debit' | 'credit';
}) {
    const fmt = useFormatMoney();
    const acc = useRecoilValue(accountQuery(props.entry.accountId));

    if (!acc) {
        return <>--</>;
    }

    if (!props.entry[props.kind]) {
        return null;
    }

    return (
        <Label slot={props.slot}>
            {fmt({
                amountUnscaled: props.entry[props.kind],
                amountScale: acc.currencyExponent,
                currency: acc.currency,
            })}
        </Label>
    );
}
