import * as React from 'react';
import { AppState } from '../redux/AppStore';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { rewardActions, rewardSelectors } from './store';
import { SyntheticEvent, useEffect, useState } from 'react';
import Scrollable from '../common/Scrollable';
import { Button } from '../common/Button';
import { ulid } from 'ulid';

const mapStateToProps = (
    state: AppState,
    props: RouteComponentProps<{ id: string; partnerId: string }>,
) => ({
    reward: rewardSelectors.getReward(state, { id: props.match.params.id }),
});

const connector = connect(mapStateToProps, {
    adjustRewardPrice: rewardActions.adjustRewardPrice,
    fetchSingleReward: rewardActions.fetchSingleReward,
});

type Props = ConnectedProps<typeof connector> &
    RouteComponentProps<{ id: string; partnerId: string }>;

const AdjustRewardPriceInner: React.FunctionComponent<Props> = (props) => {
    const { reward, adjustRewardPrice, fetchSingleReward } = props;
    const rewardId = props.match.params.id;
    const partnerId = props.match.params.partnerId;

    const price = reward?.price;

    const [points, setPoints] = useState<number>(price?.amount ?? 0);

    useEffect(() => {
        if (!!price) {
            setPoints(price.amount);
        }
    }, [price, setPoints]);

    const [hasFetchedReward, setHasFetchedReward] = useState(false);
    useEffect(() => {
        if (hasFetchedReward || !!reward) {
            return;
        }
        fetchSingleReward({ rewardId, partnerId });
        setHasFetchedReward(true);
    }, [
        reward,
        fetchSingleReward,
        hasFetchedReward,
        setHasFetchedReward,
        rewardId,
        partnerId,
    ]);

    const transactionId: string = React.useMemo(() => {
        return ulid();
    }, []);

    const onSubmit = React.useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault();

            // eslint-disable-next-line no-restricted-globals
            const ok = confirm('Really change price?');
            if (!ok) {
                return;
            }

            adjustRewardPrice({
                partnerId,
                rewardId,
                points,
                transactionId,
            });
            window.history.back();
        },
        [points, partnerId, rewardId, transactionId, adjustRewardPrice],
    );

    return (
        <Scrollable>
            <h1 className="text-xl font-bold">
                {reward?.title || 'Loading...'}
            </h1>
            Current Price:{' '}
            <span className="font-bold">{reward?.price?.amount ?? 0}</span>
            &nbsp;Planet Coins
            <form onSubmit={onSubmit} className="py-6 flex flex-row space-x-4">
                <input
                    type="number"
                    className="form-input"
                    value={points}
                    onChange={(e) => setPoints(parseInt(e.target.value, 10))}
                />
                <div>
                    <Button primary label="Change price" onClick={onSubmit} />
                </div>
            </form>
        </Scrollable>
    );
};

export default withRouter(connector(AdjustRewardPriceInner));
