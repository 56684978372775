import React from 'react';
import {
    configureStore,
    getDefaultMiddleware,
    isPlain,
    createSerializableStateInvariantMiddleware,
} from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { createLogger } from 'redux-logger';
import { rootReducer } from './reducer';

export type AppState = ReturnType<typeof rootReducer>;

const logger = createLogger({
    collapsed: true,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: getDefaultMiddleware<AppState, { serializableCheck: boolean }>({
        serializableCheck: false,
    })
        .concat(logger)
        .concat(
            createSerializableStateInvariantMiddleware({
                isSerializable: (v) =>
                    isPlain(v) || v instanceof Date || v instanceof Uint8Array,
            }),
        ),
});

export type AppDispatch = typeof store.dispatch;

const AppStore: React.FunctionComponent = ({ children }) => (
    <Provider store={store}>{children}</Provider>
);

export default AppStore;

// @ts-ignore
if (process.env.NODE_ENV !== 'production' && module.hot) {
    // @ts-ignore
    module.hot.accept('./reducer', () => {
        store.replaceReducer(rootReducer);
    });
}
