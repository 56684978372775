import * as React from 'react';
import { AppState } from '../redux/AppStore';
import { connect, ConnectedProps } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { rewardActions, rewardSelectors } from './store';
import { SyntheticEvent, useEffect, useState } from 'react';
import Scrollable from '../common/Scrollable';
import { Button } from '../common/Button';
import { ulid } from 'ulid';

const mapStateToProps = (
    state: AppState,
    props: RouteComponentProps<{ id: string; partnerId: string }>,
) => ({
    reward: rewardSelectors.getReward(state, { id: props.match.params.id }),
    codes: rewardSelectors.codesForReward(state, {
        id: props.match.params.id,
    }),
});

const connector = connect(mapStateToProps, {
    fetchRewardBalance: rewardActions.fetchRewardBalance,
    deleteRewardCodes: rewardActions.deleteRewardCodes,
    fetchSingleReward: rewardActions.fetchSingleReward,
});

type Props = ConnectedProps<typeof connector> &
    RouteComponentProps<{ id: string; partnerId: string }>;

const ManageRewardCodesInner: React.FunctionComponent<Props> = (props) => {
    const { codes, fetchRewardBalance, deleteRewardCodes } = props;
    const rewardId = props.match.params.id;
    const partnerId = props.match.params.partnerId;
    const [selectedCodes, setSelectedCodes] = React.useState<
        Record<string, boolean>
    >({});

    const { fetchSingleReward, reward } = props;
    const [hasFetchedReward, setHasFetchedReward] = useState(false);
    useEffect(() => {
        if (hasFetchedReward || !!reward) {
            return;
        }
        fetchSingleReward({ rewardId, partnerId });
        setHasFetchedReward(true);
    }, [
        reward,
        fetchSingleReward,
        hasFetchedReward,
        setHasFetchedReward,
        rewardId,
        partnerId,
    ]);

    const transactionId: string = React.useMemo(() => {
        return ulid();
    }, []);

    useEffect(() => {
        fetchRewardBalance({ rewardId, partnerId });
    }, [fetchRewardBalance, rewardId, partnerId]);

    const onSelectAll = () => {
        setSelectedCodes(Object.fromEntries(codes.map((c) => [c, true])));
    };

    const onDeselectAll = () => {
        setSelectedCodes(Object.fromEntries(codes.map((c) => [c, false])));
    };

    const onSubmit = React.useCallback(
        (e: SyntheticEvent) => {
            e.preventDefault();

            const codesToDelete: string[] = Object.entries(selectedCodes)
                .filter(([code, selected]) => selected)
                .map(([code]) => code);

            // eslint-disable-next-line no-restricted-globals
            const ok = confirm(
                `Really delete ${codesToDelete.length}? There is undo!`,
            );

            if (!ok) {
                return;
            }

            deleteRewardCodes({
                partnerId,
                codes: codesToDelete,
                rewardId,
                transactionId,
            });
            window.history.back();
        },
        [deleteRewardCodes, selectedCodes, partnerId, rewardId, transactionId],
    );

    if (codes.length === 0) {
        return (
            <Scrollable>
                <h1 className="text-xl font-bold">
                    {reward?.title || 'Loading...'}
                </h1>
                <span className="font-bold">{codes.length}</span>&nbsp;Codes
                left
            </Scrollable>
        );
    }

    return (
        <Scrollable>
            <h1 className="text-xl font-bold">
                {reward?.title || 'Loading...'}
            </h1>
            <span className="font-bold">{codes.length}</span>&nbsp;Codes left
            <form onSubmit={onSubmit}>
                <Button
                    primary
                    label="Revoke"
                    onClick={onSubmit}
                    className="my-6"
                />
                <div className="flex space-x-2">
                    <button
                        className="border px-2 py-1"
                        onClick={(e) => {
                            e.preventDefault();
                            onSelectAll();
                        }}
                    >
                        Select All
                    </button>
                    <button
                        className="border px-2 py-1"
                        onClick={(e) => {
                            e.preventDefault();
                            onDeselectAll();
                        }}
                    >
                        Deselect All
                    </button>
                </div>
                <ul>
                    {codes.map((code) => (
                        <li key={code} className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                className="form-checkbox w-4 h-4"
                                checked={selectedCodes[code] ?? false}
                                onChange={(e) => {
                                    const checked = e.target.checked;
                                    setSelectedCodes({
                                        ...selectedCodes,
                                        [code]: checked,
                                    });
                                }}
                            />
                            <span>{code}</span>
                        </li>
                    ))}
                </ul>
            </form>
        </Scrollable>
    );
};

export default withRouter(connector(ManageRewardCodesInner));
