import {
    EvaluateCashbackRuleConditionRequest,
    EvaluateCashbackRuleConditionResponse,
    GetCurrentCashbackRatesResponse,
    GetMissingCashbackTicketsResponse,
    GetTransactionsForMissingCashbackTicketResponse,
    MissingCashbackTicket,
    GetCashbackRateScheduleResponse,
    CashbackRate,
} from '../api/model/backoffice';
import { createCrudSlice, CrudSlice } from '../crud-module/store';
import { AppState } from '../redux/AppStore';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TransactionDetails } from '../api/model/openbanking';
import axios from 'axios';
import { Timestamp } from '../api/google/protobuf/timestamp';
import { CashbackLogEntry, GetCashbackLogResponse } from '../api/model/core';
import { selector, selectorFamily } from 'recoil';

export const cashbackLogModule: CrudSlice<CashbackLogEntry> =
    createCrudSlice<CashbackLogEntry>({
        name: 'cashbackLog',
        baseUrl: '/cashback/log',
        localClientBasePath: '/cashback/log',
        stateProvider: (s) => (s as AppState).cashbackLog,
        listResponseParser: (data) => {
            const res = GetCashbackLogResponse.fromJson(data);
            return {
                items: res.items,
                nextPageToken: res.nextPageToken,
            };
        },
        singleResponseParser: (data) => {
            throw Error('not implemented');
        },
        writeArgumentTransformer: (item) => {
            throw Error('not implemented');
        },
        idProvider: ({ transactionId }) => transactionId,
        prepareEmpty: () => {
            throw Error('not implemented');
        },
    });

export const cashbackLogReducer = cashbackLogModule.reducer;
export const cashbackLogActions = cashbackLogModule.actions;
export const cashbackLogSelectors = cashbackLogModule.selectors;

export const cashbackTicketModule: CrudSlice<MissingCashbackTicket> =
    createCrudSlice<MissingCashbackTicket>({
        name: 'cashback',
        baseUrl: '/cashback/ticket',
        localClientBasePath: '/cashback/ticket',
        stateProvider: (s) => (s as AppState).cashbackTicket,
        listResponseParser: (data) => {
            const res = GetMissingCashbackTicketsResponse.fromJson(data);
            return {
                items: res.items,
                nextPageToken: res.nextPageToken,
            };
        },
        singleResponseParser: (data) => {
            throw Error('not implemented');
        },
        writeArgumentTransformer: (item) => {
            throw Error('not implemented');
        },
        idProvider: ({ id }) => id,
        prepareEmpty: () => {
            throw Error('not implemented');
        },
    });

export const cashbackTicketReducer = cashbackTicketModule.reducer;
export const cashbackTicketActions = cashbackTicketModule.actions;
export const cashbackTicketSelectors = cashbackTicketModule.selectors;

const fetchTransactions = createAsyncThunk(
    'cashback/transactions/fetch',
    async (args: {
        id: string;
    }): Promise<GetTransactionsForMissingCashbackTicketResponse> => {
        const res = await axios.get<Object>(
            `/cashback/ticket/${args.id}/transactions`,
        );

        return GetTransactionsForMissingCashbackTicketResponse.fromJson(
            res.data as any,
        );
    },
);

const evaluateTestRegex = createAsyncThunk(
    'cashback/evaluate_rule',
    async (
        arg: void,
        { getState },
    ): Promise<EvaluateCashbackRuleConditionResponse> => {
        const regex = (getState() as AppState).cashbackInspector.testRegex;
        const req = EvaluateCashbackRuleConditionRequest.toJson({
            condition: {
                id: '--testing',
                field: 'transaction.description',
                regex,
                comment: '',
            },
            from: Timestamp.fromDate(
                new Date(new Date().setDate(new Date().getDate() - 29)),
            ),
            to: Timestamp.now(),
        });
        const res = await axios.post<Object>(
            `/Cashback.EvaluateCashbackRuleCondition`,
            req,
        );
        return EvaluateCashbackRuleConditionResponse.fromJson(res.data as any);
    },
);

type InspectorState = {
    id: string | undefined;
    transactions: Array<TransactionDetails>;
    transactionsForTicketID: string | undefined;
    isLoading: boolean;
    loadingFailed: boolean;
    testRegex: string;
    evaluationState:
        | { tag: 'initial' }
        | { tag: 'pending' }
        | {
              tag: 'fulfilled';
              evaluationResult: EvaluateCashbackRuleConditionResponse;
          }
        | { tag: 'rejected'; error: any };
};
const initialInspectorState: InspectorState = {
    id: undefined,
    transactions: [],
    transactionsForTicketID: undefined,
    isLoading: false,
    loadingFailed: false,
    testRegex: '',
    evaluationState: { tag: 'initial' },
};

const inspectorSlice = createSlice({
    name: 'cashback/inspector',
    initialState: initialInspectorState,
    reducers: {
        setTestRegex: (state, action: PayloadAction<string>) => {
            state.testRegex = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchTransactions.pending, (state, action) => {
            state.isLoading = true;
            state.loadingFailed = false;
            if (state.transactionsForTicketID !== action.meta.arg.id) {
                state.transactions = [];
            }
        });
        builder.addCase(fetchTransactions.rejected, (state) => {
            state.isLoading = false;
            state.loadingFailed = true;
        });
        builder.addCase(fetchTransactions.fulfilled, (state, action) => {
            state.isLoading = false;
            state.loadingFailed = false;
            state.transactions = action.payload.transactions;
        });
        builder.addCase(evaluateTestRegex.pending, (state) => {
            state.evaluationState = { tag: 'pending' };
        });
        builder.addCase(evaluateTestRegex.rejected, (state, action) => {
            state.evaluationState = { tag: 'rejected', error: action.error };
        });
        builder.addCase(evaluateTestRegex.fulfilled, (state, action) => {
            state.evaluationState = {
                tag: 'fulfilled',
                evaluationResult: action.payload,
            };
        });
    },
});

export const cashbackInspectorReducer = inspectorSlice.reducer;
export const cashbackInspectorActions = {
    ...inspectorSlice.actions,
    fetchTransactions,
    evaluateTestRegex,
};
export const cashbackInspectorSelectors = {
    isLoading: (state: AppState) => state.cashbackInspector.isLoading,
    loadingFailed: (state: AppState) => state.cashbackInspector.loadingFailed,
    transactions: (state: AppState) => state.cashbackInspector.transactions,
    getTestRegex: (state: AppState) => state.cashbackInspector.testRegex,
    getEvaluationState: (state: AppState) =>
        state.cashbackInspector.evaluationState,
};

export const currentRatesQuery = selector({
    key: 'cashback/rates/currentRatesQuery',
    get: async () => {
        const res = await axios.get(`/cashback/rates`);
        const items = GetCurrentCashbackRatesResponse.fromJson(res.data).items;

        items.sort((a, b) => b.partnerId.localeCompare(a.partnerId));

        return items;
    },
});

export const rateScheduleQuery = selectorFamily({
    key: 'cashback/rates/rateScheduleQuery',
    get: (id: string) => async (): Promise<Array<CashbackRate>> => {
        const res = await axios.get(`/cashback/rates/partnerId/${id}/schedule`);
        return GetCashbackRateScheduleResponse.fromJson(res.data).items;
    },
});

export const currentRateIDQuery = selectorFamily({
    key: 'cashback/rates/currentRateIDQuery',
    get:
        (partnerID: string) =>
        ({ get }) => {
            const all = [...get(rateScheduleQuery(partnerID))];

            if (all.length === 0) {
                return null;
            }

            all.sort(
                (a, b) =>
                    Timestamp.toDate(b.validFrom!).getTime() -
                    Timestamp.toDate(a.validFrom!).getTime(),
            );

            for (let r of all) {
                const vf = Timestamp.toDate(r.validFrom!);
                if (vf < new Date()) {
                    return r.id;
                }
            }
            return null;
        },
});

export const currentRateQuery = selectorFamily({
    key: 'cashback/rates/currentRateQuery',
    get:
        (partnerID: string) =>
        ({ get }) => {
            const id = get(currentRateIDQuery(partnerID));
            return get(rateScheduleQuery(partnerID)).find(
                (rate) => rate.id === id,
            );
        },
});
