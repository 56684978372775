import { FunctionComponent } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { voucherActions, voucherSelectors } from './store';
import { Transition } from '@headlessui/react';
import { Button } from '../common/Button';
import CopyToClipboardButton from '../common/CopyToClipboardButton';

const CreatedPreviewDialog: FunctionComponent = () => {
    const voucher = useAppSelector(voucherSelectors.getCreatedPreview);
    const dispatch = useAppDispatch();

    const onClose = () => {
        dispatch(voucherActions.clearCreatedPreview());
    };

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto pointer-events-none">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <Transition
                    show={voucher !== undefined}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                >
                    <div className="absolute inset-0 bg-gray-500 opacity-75" />
                </Transition>
                {/* This element is to trick the browser into centering the modal contents. */}
                <span
                    className="hidden sm:inline-block sm:align-middle sm:h-screen"
                    aria-hidden="true"
                >
                    &#8203;
                </span>
                <Transition
                    show={voucher !== undefined}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enterTo="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full pointer-events-auto"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6">
                        <div className="mt-3 text-center sm:mt-0 sm:mx-4 sm:text-left space-y-8">
                            <h3
                                className="text-lg leading-6 font-medium text-gray-900"
                                id="modal-headline"
                            >
                                Voucher created
                            </h3>
                            <div className="px-2 py-2 rounded-md bg-gray-200 flex justify-between items-center">
                                <span className="font-mono tracking-widest text-lg">
                                    {voucher?.code}
                                </span>
                                <CopyToClipboardButton
                                    value={voucher?.code ?? ''}
                                />
                            </div>
                            <span className="text-sm text-gray-500">
                                Make sure to copy the code now, has it will not
                                been shown again.
                            </span>
                            <Button primary label="Close" onClick={onClose} />
                        </div>
                    </div>
                </Transition>
            </div>
        </div>
    );
};

export default CreatedPreviewDialog;
