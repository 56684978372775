import { useHistory } from 'react-router-dom';
import PartnerName from '../partner/PartnerName';
import {
    atom,
    useRecoilState,
    useRecoilValue,
    useSetRecoilState,
} from 'recoil';
import {
    documentsQuery,
    invoiceForPeriodAndPartnerQuery,
    isPostingRevertedQuery,
    numberOfPostingsQuery,
    periodAndPartnerAtom,
    periodForCode,
    periodsQuery,
    PeriodStateCode,
    periodStateForCodeAndPartner,
    postingGroupsQuery,
    postingRevertReasonQuery,
    postingsQuery,
    PostingType,
    totalForPeriodQuery,
    useAddPostings,
    useChangePeriodStateCode,
    useCreateInvoiceFn,
} from './store';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { Timestamp } from '../api/google/protobuf/timestamp';
import { useFormatMoney } from '../common/formatting';
import DocumentPreviewDialog from './DocumentPreviewDialog';
import * as Billing from '../api/billing/rpc/billing';
import RewardName from '../reward/RewardName';
import {
    BillingPeriodStateChip,
    PeriodStateChangerButton,
} from './BillingPeriodOverview';
import {
    ActionSheet,
    Bar,
    Breadcrumbs,
    BreadcrumbsItem,
    BusyIndicator,
    Button,
    ButtonDesign,
    Dialog,
    DynamicPage,
    DynamicPageHeader,
    DynamicPageTitle,
    FlexBox,
    FlexBoxAlignItems,
    FlexBoxDirection,
    FlexBoxWrap,
    Input,
    Label,
    Loader,
    ObjectStatus,
    Table,
    TableCell,
    TableColumn,
    TableGrowingMode,
    TableRow,
    Title,
    ValueState,
} from '@ui5/webcomponents-react';
import CreatePostingDialog from './CreatePostingDialog';
import MarkRewardCodesAsUsedDialog from './MarkRewardCodesAsUsedDialog';
import LoadingSpinner from '../common/LoadingSpinner';
import useKeyboardShortcut from '../common/keyboard-shortcuts';
import { usePartnerBaseList } from '../partner/store';

const documentViewingState = atom<string | null>({
    key: 'billing/PartnerBillingSheet/documentViewingState',
    default: null,
});

const highlightPostingsForDocument = atom<string | null>({
    key: 'billing/PartnerBillingSheet/highlightPostingsForDocument',
    default: null,
});

type Props = {
    periodCode: string;
    partnerId: string;
};

export default function PartnerBillingSheet({ partnerId, periodCode }: Props) {
    const period = useRecoilValue(periodForCode(periodCode));

    const [, sB] = useRecoilState(periodAndPartnerAtom);

    useEffect(() => {
        sB([period.id, partnerId]);
    }, [sB, period, partnerId]);

    const postings = useRecoilValue(postingsQuery);
    const groups = useRecoilValue(postingGroupsQuery);

    const documents = useRecoilValue(documentsQuery);

    const invoice = useRecoilValue(
        invoiceForPeriodAndPartnerQuery([periodCode, partnerId]),
    );

    const total = useRecoilValue(totalForPeriodQuery);

    const [viewDocID, setViewDocID] = useRecoilState(documentViewingState);

    const history = useHistory();

    const numDocumentsWithoutPostings = useMemo(() => {
        return documents.filter(
            (doc) => !postings.some((pos) => pos.documentId === doc.id),
        ).length;
    }, [documents, postings]);

    const { prompt } = useChangePeriodStateCode(periodCode, partnerId);

    const partnerList = usePartnerBaseList();

    useKeyboardShortcut([
        {
            shortcut: 'g p',
            callback: () => {
                const idx = partnerList.findIndex((p) => p.id === partnerId);
                if (idx < 1) {
                    return;
                }
                const prev = partnerList[idx - 1].id;

                history.replace(`/billing/${periodCode}/${prev}`);
            },
        },
        {
            shortcut: 'g n',
            callback: () => {
                const idx = partnerList.findIndex((p) => p.id === partnerId);
                if (idx >= partnerList.length - 1) {
                    return;
                }
                const next = partnerList[idx + 1].id;

                history.replace(`/billing/${periodCode}/${next}`);
            },
        },
        { shortcut: 'c s', callback: prompt },
    ]);

    return (
        <>
            <DynamicPage
                headerTitle={
                    <DynamicPageTitle
                        breadcrumbs={
                            <Breadcrumbs
                                onItemClick={(e) => {
                                    e.preventDefault();
                                    const { item } = e.detail;
                                    if (
                                        'href' in item &&
                                        // @ts-ignore
                                        typeof item.href === 'string'
                                    ) {
                                        // @ts-ignore
                                        history.push(item.href);
                                    }
                                }}
                            >
                                <BreadcrumbsItem href="/billing">
                                    Billing
                                </BreadcrumbsItem>
                                <BreadcrumbsItem
                                    href={`/billing/${periodCode}`}
                                >
                                    {periodCode}
                                </BreadcrumbsItem>
                                <BreadcrumbsItem>
                                    <PartnerName id={partnerId} />
                                </BreadcrumbsItem>
                            </Breadcrumbs>
                        }
                        navigationActions={
                            <>
                                <PeriodSwitcher
                                    periodCode={periodCode}
                                    partnerId={partnerId}
                                />
                                <Button
                                    icon="decline"
                                    design={ButtonDesign.Transparent}
                                    onClick={() => {
                                        history.push('..');
                                    }}
                                />
                            </>
                        }
                        header={
                            <Title>
                                <PartnerName id={partnerId} />
                            </Title>
                        }
                        actions={
                            <>
                                <PeriodStateChangerButton
                                    periodCode={periodCode}
                                    partnerId={partnerId}
                                />
                                <CreateInvoiceButton
                                    periodCode={periodCode}
                                    partnerId={partnerId}
                                />
                            </>
                        }
                    >
                        <Suspense fallback={<BusyIndicator />}>
                            <BillingPeriodStateChip
                                periodCode={periodCode}
                                partnerId={partnerId}
                            />
                        </Suspense>
                    </DynamicPageTitle>
                }
                headerContent={
                    <DynamicPageHeader>
                        <FlexBox wrap={FlexBoxWrap.Wrap} className="space-x-4">
                            <FlexBox direction={FlexBoxDirection.Column}>
                                <Label>
                                    Partner ID: <code>{partnerId}</code>
                                </Label>
                            </FlexBox>
                            <FlexBox direction={FlexBoxDirection.Column}>
                                <Label>Dokumenten Status:</Label>
                                <ObjectStatus
                                    showDefaultIcon
                                    state={
                                        numDocumentsWithoutPostings === 0
                                            ? ValueState.Success
                                            : ValueState.Warning
                                    }
                                >
                                    {numDocumentsWithoutPostings === 0
                                        ? 'Alle bearbeitet'
                                        : `Dokumente offen: ${numDocumentsWithoutPostings}`}
                                </ObjectStatus>
                            </FlexBox>
                            <FlexBox direction={FlexBoxDirection.Column}>
                                <Label>Rechnung</Label>
                                <ObjectStatus
                                    showDefaultIcon
                                    state={
                                        invoice === undefined
                                            ? ValueState.Information
                                            : ValueState.Success
                                    }
                                >
                                    {!invoice && <>Keine Rechnung erstellt</>}
                                    {invoice && (
                                        <a
                                            href={invoice.externalLink}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Zu easybill
                                        </a>
                                    )}
                                </ObjectStatus>
                            </FlexBox>
                        </FlexBox>
                    </DynamicPageHeader>
                }
            >
                <div className="text-sm">
                    <Table
                        columns={
                            <>
                                <TableColumn className="w-8">Type</TableColumn>
                                <TableColumn className="w-8">Wert</TableColumn>
                            </>
                        }
                        growing={TableGrowingMode.None}
                    >
                        {groups.map((g) => (
                            <TableRow key={g.type}>
                                <TableCell>
                                    <Label>{g.type}</Label>
                                </TableCell>
                                <TableCell>
                                    <Label>
                                        <MoneyDisplay
                                            currency={g.currency}
                                            amountScale={g.amountScale}
                                            amountUnscaled={g.amountUnscaled}
                                        />
                                    </Label>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell>
                                <Label className="font-bold">Total</Label>
                            </TableCell>
                            <TableCell>
                                <Label className="font-bold">
                                    <MoneyDisplay
                                        currency={total.currency}
                                        amountScale={total.amountScale}
                                        amountUnscaled={total.amountUnscaled}
                                    />
                                </Label>
                            </TableCell>
                        </TableRow>
                    </Table>
                </div>
                <div className="text-sm">
                    <Table
                        columns={
                            <>
                                <TableColumn className="w-8">
                                    <Label>Belegnummer</Label>
                                </TableColumn>
                                <TableColumn className="w-8">
                                    <Label>Type</Label>
                                </TableColumn>
                                <TableColumn>
                                    <Label>Wert</Label>
                                </TableColumn>
                                <TableColumn>
                                    <Label>Datum</Label>
                                </TableColumn>
                                <TableColumn>
                                    <Label>Aktionen</Label>
                                </TableColumn>
                            </>
                        }
                        growing={TableGrowingMode.Scroll}
                    >
                        {postings
                            .filter((p) => p.metadata['type'] !== 'revert')
                            .map((posting) => (
                                <PostingTableRow
                                    key={posting.id}
                                    partnerId={partnerId}
                                    posting={posting}
                                />
                            ))}
                        {postings.length > 0 && (
                            <TableRow>
                                <TableCell />
                                <TableCell />
                                <TableCell>
                                    <Label>
                                        <MoneyDisplay
                                            currency={total.currency}
                                            amountScale={total.amountScale}
                                            amountUnscaled={
                                                total.amountUnscaled
                                            }
                                        />
                                    </Label>
                                </TableCell>
                                <TableCell />
                                <TableCell />
                            </TableRow>
                        )}
                    </Table>
                </div>
                <div className="text-sm">
                    <Table
                        columns={
                            <>
                                <TableColumn className="w-16">
                                    <Label>ID</Label>
                                </TableColumn>
                                <TableColumn className="w-8">
                                    <Label>Type</Label>
                                </TableColumn>
                                <TableColumn className="w-8">
                                    <Label># Buchungen</Label>
                                </TableColumn>
                                <TableColumn>
                                    <Label>Extrainfos</Label>
                                </TableColumn>
                                <TableColumn>
                                    <Label>Aktionen</Label>
                                </TableColumn>
                            </>
                        }
                    >
                        {documents.map((doc) => (
                            <TableRow key={doc.id}>
                                <TableCell>
                                    <Label>{doc.id}</Label>
                                </TableCell>
                                <TableCell>
                                    <Label>{doc.type}</Label>
                                </TableCell>
                                <TableCell>
                                    <NumberOfPostingsForDocument
                                        document={doc}
                                    />
                                </TableCell>
                                <TableCell>
                                    <MetadataFormatter
                                        metadata={doc.metadata}
                                    />
                                </TableCell>
                                <TableCell>
                                    <DocumentActions document={doc} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </Table>
                </div>
            </DynamicPage>
            <DocumentPreviewDialog
                id={viewDocID}
                show={!!viewDocID}
                onClose={() => setViewDocID(null)}
            />
        </>
    );
}

export function MoneyDisplay(props: {
    amountUnscaled: number;
    amountScale: number;
    currency: string;
}) {
    const fmt = useFormatMoney();

    return <>{fmt(props)}</>;
}

function PostingTableRow(props: {
    slot?: string;
    partnerId: string;
    posting: Billing.Posting;
}) {
    const { posting, partnerId } = props;
    const highlightForDocID = useRecoilValue(highlightPostingsForDocument);
    const isReverted = useRecoilValue(isPostingRevertedQuery(posting.id));
    const revertReason = useRecoilValue(postingRevertReasonQuery(posting.id));
    return (
        <TableRow
            key={posting.id}
            selected={highlightForDocID === posting.documentId}
        >
            <TableCell>
                <Label>{posting.id}</Label>
            </TableCell>
            <TableCell>
                <Label>
                    <span className={isReverted ? 'line-through' : undefined}>
                        {posting.type}
                    </span>
                </Label>
            </TableCell>
            <TableCell>
                <Label>
                    <span className={isReverted ? 'line-through' : undefined}>
                        <MoneyDisplay
                            currency={posting.currency}
                            amountScale={posting.amountScale}
                            amountUnscaled={posting.amountUnscaled}
                        />
                    </span>
                </Label>
            </TableCell>
            <TableCell>
                <Label>
                    {Timestamp.toDate(posting.createdAt!).toLocaleDateString()}
                </Label>
            </TableCell>
            <TableCell>
                {isReverted && <span>Reverted: {revertReason}</span>}
                <PostingActions partnerId={partnerId} posting={posting} />
            </TableCell>
        </TableRow>
    );
}

function PostingActions(props: {
    slot?: string;
    partnerId: string;
    posting: Billing.Posting;
}) {
    const alreadyReverted = useRecoilValue(
        isPostingRevertedQuery(props.posting.id),
    );
    const setViewDocID = useSetRecoilState(documentViewingState);
    const [revertPostingDialogOpen, setRevertPostingDialogOpen] =
        useState(false);

    const { addPostings } = useAddPostings(
        props.posting.periodId,
        props.partnerId,
    );

    const [revertReason, setRevertReason] = useState('');

    const [isSubmitting, setIsSubmitting] = useState(false);
    const onRevert = async () => {
        setIsSubmitting(true);
        try {
            await addPostings([
                {
                    type: props.posting.type,
                    amountUnscaled: -props.posting.amountUnscaled,
                    amountScale: props.posting.amountScale,
                    currency: props.posting.currency,
                    documentId: '',
                    metadata: {
                        type: 'revert',
                        reason: revertReason,
                        posting_id: props.posting.id.toString(10),
                    },
                    idempotencyKey: Buffer.from(
                        `revert-${props.posting.id}`,
                        'utf-8',
                    ).toString('base64'),
                },
            ]);
            setRevertReason('');
            setRevertPostingDialogOpen(false);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <FlexBox slot={props.slot}>
            {props.posting.metadata['type'] !== 'revert' &&
                !alreadyReverted && (
                    <Button
                        icon="reset"
                        title="Revert"
                        onClick={() => setRevertPostingDialogOpen(true)}
                    />
                )}
            {props.posting.documentId && (
                <Button
                    onClick={() => setViewDocID(props.posting.documentId)}
                    icon="document"
                />
            )}
            <Dialog
                open={revertPostingDialogOpen}
                onAfterClose={() => setRevertPostingDialogOpen(false)}
                headerText="Revert"
                footer={
                    <Bar
                        endContent={
                            <>
                                <Button
                                    design={ButtonDesign.Positive}
                                    onClick={onRevert}
                                >
                                    Revert
                                </Button>
                                <Button
                                    design={ButtonDesign.Negative}
                                    onClick={() =>
                                        setRevertPostingDialogOpen(false)
                                    }
                                >
                                    Cancel
                                </Button>
                            </>
                        }
                    />
                }
            >
                <FlexBox
                    direction={FlexBoxDirection.Column}
                    alignItems={FlexBoxAlignItems.Start}
                >
                    <Label required showColon>
                        Reason
                    </Label>
                    <Input
                        value={revertReason}
                        onChange={(e) => setRevertReason(e.target.value ?? '')}
                        className="border border-gray-400"
                    />
                </FlexBox>
                {isSubmitting && <Loader />}
            </Dialog>
        </FlexBox>
    );
}

function DocumentActions(props: { slot?: string; document: Billing.Document }) {
    const [, setViewDocID] = useRecoilState(documentViewingState);
    const [hpdf, setHPFD] = useRecoilState(highlightPostingsForDocument);
    const [createPostingDialogOpen, setCreatePostingDialogOpen] =
        useState(false);

    const [
        markRewardCodesAsUsedDialogOpen,
        setMarkRewardCodesAsUsedDialogOpen,
    ] = useState(false);
    return (
        <FlexBox slot={props.slot}>
            <Button
                onClick={() => setViewDocID(props.document.id)}
                icon="document"
            />
            <Button
                onClick={() =>
                    setHPFD(
                        hpdf === props.document.id ? null : props.document.id,
                    )
                }
                icon={
                    hpdf === props.document.id ? 'inspect-down' : 'detail-view'
                }
            />
            <Button
                icon="create-form"
                onClick={() => setCreatePostingDialogOpen(true)}
            />
            {props.document.type === PostingType.RewardPaid &&
                props.document.metadata['reward_id'] && (
                    <Button
                        icon="commission-check"
                        onClick={() => setMarkRewardCodesAsUsedDialogOpen(true)}
                    />
                )}
            <CreatePostingDialog
                open={createPostingDialogOpen}
                partnerId={props.document.partnerId}
                documentId={props.document.id}
                period={props.document.period}
                onClose={() => setCreatePostingDialogOpen(false)}
                baseMetadata={{
                    ...(props.document.metadata['reward_id']
                        ? { reward_id: props.document.metadata['reward_id'] }
                        : {}),
                }}
            />
            <MarkRewardCodesAsUsedDialog
                open={markRewardCodesAsUsedDialogOpen}
                partnerId={props.document.partnerId}
                documentId={props.document.id}
                rewardId={props.document.metadata['reward_id'] ?? ''}
                period={props.document.period}
                onClose={() => setMarkRewardCodesAsUsedDialogOpen(false)}
            />
        </FlexBox>
    );
}

function NumberOfPostingsForDocument(props: { document: Billing.Document }) {
    const num = useRecoilValue(numberOfPostingsQuery(props.document.id));
    return <>{num}</>;
}

function MetadataFormatter(props: { metadata: Record<string, string> }) {
    if (Object.keys(props.metadata).length === 0) {
        return <>-</>;
    }

    if (props.metadata['reward_id']) {
        return (
            <>
                Reward: <RewardName id={props.metadata['reward_id']} />
            </>
        );
    }

    return <>Unknown metadata</>;
}

function PeriodSwitcher(props: {
    periodCode: string;
    partnerId: string;
    slot?: string;
}) {
    const [open, setOpen] = useState(false);

    const periods = useRecoilValue(periodsQuery);

    const id = `PeriodSwitcher-${props.partnerId}`;

    const history = useHistory();

    return (
        <>
            <Button
                id={id}
                slot={props.slot}
                icon="time-account"
                title="switch period"
                onClick={() => setOpen(true)}
            />
            <ActionSheet
                open={open}
                opener={id}
                onAfterClose={() => setOpen(false)}
            >
                {periods.map((p) => (
                    <Button
                        key={p.id}
                        onClick={() =>
                            history.push(
                                `/billing/${p.code}/${props.partnerId}`,
                            )
                        }
                        disabled={p.code === props.periodCode}
                    >
                        {p.code}
                    </Button>
                ))}
            </ActionSheet>
        </>
    );
}

function CreateInvoiceButton(props: {
    periodCode: string;
    partnerId: string;
    slot?: string;
}) {
    const invoice = useRecoilValue(
        invoiceForPeriodAndPartnerQuery([props.periodCode, props.partnerId]),
    );
    const state = useRecoilValue(
        periodStateForCodeAndPartner([props.periodCode, props.partnerId]),
    );
    const { working, createInvoice } = useCreateInvoiceFn(props);

    if (state !== PeriodStateCode.Closed || invoice !== undefined) {
        return null;
    }

    return (
        <Button slot={props.slot} onClick={createInvoice}>
            {!working ? <>Transport zu easybill</> : <LoadingSpinner />}
        </Button>
    );
}
