import { useEffect } from 'react';

export const useStartExpanded = (hooks: any): void => {
    hooks.useInstance.push(useInstance);
};
useStartExpanded.pluginName = 'useStartExpanded';

const useInstance = (instance: any): void => {
    const { toggleAllRowsExpanded, isAllRowsExpanded } = instance;

    const hasData = instance.data.length > 0;

    useEffect(() => {
        if (hasData || !isAllRowsExpanded) toggleAllRowsExpanded(true);
    }, [hasData, isAllRowsExpanded, toggleAllRowsExpanded]);
};
