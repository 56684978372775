import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { Form, Formik, useField, useFormikContext } from 'formik';
import { ApplyExternalCashbackRequest } from '../api/model/backoffice';
import FormPartnerAssociationField from '../common/FormPartnerAssociationField';
import {
    FormInlineDocumentField,
    FormTextField,
    FormTimestampField,
    FormUnscaledValueField,
} from '../common/FormFields';
import axios from 'axios';
import { useAppDispatch } from '../redux/react';
import { snackbarActions } from '../snackbar/store';
import {
    Bar,
    Button,
    ButtonDesign,
    Label,
    Loader,
    Page,
} from '@ui5/webcomponents-react';
import { Timestamp } from '../api/google/protobuf/timestamp';
import { ulid } from 'ulid';

export default function CashbackExternal() {
    const history = useHistory();

    const [working, setWorking] = useState(false);
    const dispatch = useAppDispatch();

    const [defaultEmpty] = useState(ApplyExternalCashbackRequest.create());

    const onSubmit = async (values: typeof defaultEmpty) => {
        setWorking(true);

        try {
            await axios.post(
                '/Cashback.ApplyExternalCashback',
                ApplyExternalCashbackRequest.toJson(values),
            );
        } catch (e) {
            dispatch(
                snackbarActions.sendMessage({
                    type: 'warning',
                    title: 'Error',
                    subtitle: `${e}`,
                }),
            );
        } finally {
            setWorking(false);
        }
    };

    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={defaultEmpty}
            validate={(v) => {
                if (!v.id) {
                    return { id: 'required' };
                }
                if (!v.partnerId) {
                    return { partnerId: 'required' };
                }
                if (!v.userId) {
                    return { userId: 'required' };
                }
                if (!v.orderAmountUnscaled) {
                    return { orderAmountUnscaled: 'required' };
                }
                if (!v.orderDate) {
                    return { orderDate: 'required' };
                }
                if (!v.documentIdentifier) {
                    return { documentIdentifier: 'required' };
                }
            }}
        >
            <Page
                header={
                    <Bar
                        startContent={
                            <Button
                                onClick={() => history.push('/cashback/ticket')}
                                icon="home"
                            />
                        }
                    >
                        <Label>External Cashback</Label>
                    </Bar>
                }
                footer={<FooterBar />}
            >
                {working && <Loader />}
                <Form className="container m-0 px-4 pt-8 space-y-2 max-w-lg">
                    <div className="flex flex-col space-y-4 px-4 pt-8">
                        <FormTextField name="id" label="ID" />
                        <GenerateIDFieldButton />
                        <FormPartnerAssociationField
                            single
                            name="partnerId"
                            label="Partner"
                        />
                        <FormTextField name="userId" label="User ID" />
                        <FormUnscaledValueField
                            name="orderAmount"
                            label="Order Amount"
                        />
                        <FormTimestampField
                            name="orderDate"
                            label="Order Date"
                        />
                        <FormInlineDocumentField
                            name="document"
                            label="Document"
                            mimeTypeField="mimeType"
                        />
                        <FormTextField
                            name="documentIdentifier"
                            label="Document Identifier"
                        />
                    </div>
                </Form>
            </Page>
        </Formik>
    );
}

function FooterBar(props: { slot?: string }) {
    const { submitForm, resetForm } = useFormikContext();
    return (
        <Bar
            slot={props.slot}
            endContent={
                <>
                    <Button
                        design={ButtonDesign.Positive}
                        onClick={() => submitForm()}
                    >
                        Apply
                    </Button>
                    <Button onClick={() => resetForm()}>Reset</Button>
                </>
            }
        />
    );
}

function GenerateIDFieldButton() {
    const [d] = useField('orderDate');
    const [, , idHelper] = useField('id');

    const generate = () => {
        if (!d.value) {
            return;
        }
        const ds = Timestamp.toDate(d.value);
        const id = `${ds
            .toISOString()
            .replace('.000Z', 'Z')}_EXTERNAL_${ulid()}`;
        idHelper.setValue(id);
    };

    return <Button onClick={generate}>Generate ID</Button>;
}
