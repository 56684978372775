import ItemForm from '../crud-module/ItemForm';
import { availableLotteryProjectsQuery, lotteryModule } from './store';
import {
    FormNumberField,
    FormSingleImageField,
    FormTextAreaField,
    FormTextField,
    FormTimestampField,
} from '../common/FormFields';
import { Suspense } from 'react';
import FormPartnerAssociationField from '../common/FormPartnerAssociationField';
import { useField } from 'formik';
import * as Yup from 'yup';
import { Timestamp } from '../api/google/protobuf/timestamp';
import { useRecoilValue } from 'recoil';
import LoadingSpinner from '../common/LoadingSpinner';

export default function LotteryPriceDrawingItemForm(props: {
    id: string | undefined;
}) {
    const validationSchema = Yup.object().shape({
        startsAt: Yup.object().required(),
        endsAt: Yup.object()
            .test(function (value) {
                if (this.parent.startsAt === undefined) {
                    return true;
                }
                const start = Timestamp.toDate(this.parent.startsAt);
                const end = Timestamp.toDate(value as any);
                return end > start;
            })
            .required(),
        title: Yup.string()
            .min(2, 'Too Short')
            .max(200, 'Too Long!')
            .required('Required'),
        description: Yup.string().min(2, 'Too Short').required('Required'),
        terms: Yup.string().min(2, 'Too Short').required('Required'),
        notificationTitle: Yup.string()
            .min(2, 'Too Short')
            .max(100, 'Too Long!')
            .required('Required'),
        notificationBody: Yup.string()
            .min(2, 'Too Short')
            .max(100, 'Too Long!')
            .required('Required'),
        ticketPrice: Yup.object()
            .shape({
                amount: Yup.number().min(0, 'Positive').required('Required'),
            })
            .required(),
        associatedPartnerIds: Yup.array().test({
            name: 'primary partner check',
            message: 'Sponsor cannot be an associated partner as well',
            test: function (value) {
                return (
                    value === undefined ||
                    value.length === 0 ||
                    !value.includes(this.parent.partnerId)
                );
            },
        }),
        image: Yup.object()
            .shape({
                url: Yup.string().required(),
            })
            .required(),
    });

    return (
        <ItemForm
            module={lotteryModule}
            id={props.id}
            validationSchema={validationSchema}
        >
            <FormTextField name="title" label="Title" />
            <FormTextAreaField name="description" label="Description" />
            <FormSingleImageField
                name="image"
                label="Image"
                message="Image for the card and header in the detail view"
            />
            <FormTimestampField name="startsAt" label="Starts at" />
            <FormTimestampField name="endsAt" label="Ends at" />
            <FormNumberField name="ticketPrice.amount" label="Price" min={0} />
            <FormPartnerAssociationField
                single
                name="partnerId"
                label="Sponsor"
            />
            <FormPartnerAssociationField
                name="associatedPartnerIds"
                label="Supporting Partners"
            />
            <Suspense fallback={<LoadingSpinner />}>
                <FormLotteryProjectForm />
            </Suspense>
            <FormTextAreaField name="terms" label="Terms & Conditions" />
            <FormTextField
                name="notificationTitle"
                label="Notification Title"
            />
            <FormTextField name="notificationBody" label="Notification Body" />
        </ItemForm>
    );
}

function FormLotteryProjectForm() {
    const options = useRecoilValue(availableLotteryProjectsQuery);

    const [field, , nameHelper] = useField('cooperationProjectName');
    const [, , urlHelper] = useField('cooperationProjectUrl');
    const [, , taglineHelper] = useField('cooperationProjectTagLine');
    const [, , logoHelper] = useField('cooperationProjectLogo');
    const [f2, , benefitHelper] = useField('extraBenefitExplanation');
    const [, , compensateProjectIDHelper] = useField('compensateProjectId');
    const [, , skuHelper] = useField('donationProjectSku');
    const [, , contributionHelper] = useField('donationProjectContribution');

    const value = options.findIndex(
        (o) =>
            o.cooperationProjectName === field.value &&
            o.extraBenefitExplanation === f2.value,
    );

    const onChange = (idx: number) => {
        if (idx < 0) {
            return;
        }

        const v = options[idx];

        nameHelper.setValue(v.cooperationProjectName);
        urlHelper.setValue(v.cooperationProjectUrl);
        taglineHelper.setValue(v.cooperationProjectTagLine);
        logoHelper.setValue(v.cooperationProjectLogo);
        benefitHelper.setValue(v.extraBenefitExplanation);
        compensateProjectIDHelper.setValue(v.compensateProjectId);
        skuHelper.setValue(v.donationProjectSku);
        contributionHelper.setValue(v.donationProjectContribution);
    };

    if (value < 0 && field.value) {
        return (
            <div className="flex flex-col">
                <span className="text-gray-700">Project</span>
                <span className="mt-1">
                    Legacy Project: {field.value} (non-changeable)
                </span>
            </div>
        );
    }

    return (
        <div className="flex flex-col">
            <span className="text-gray-700">Project</span>
            <select
                className="form-select mt-1"
                value={value}
                onChange={(e) => onChange(parseInt(e.target.value, 10))}
            >
                {value < 0 && <option value="-1">None</option>}
                {options.map((o, i) => (
                    <option value={i} key={i}>
                        {o.cooperationProjectName} ({o.extraBenefitExplanation})
                    </option>
                ))}
            </select>
        </div>
    );
}
