import React from 'react';
import cx from 'classnames';

type Props = {
    currentPage: number;
    perPage: number;
    hasNext: boolean;
    hasPrev: boolean;
    onNext: () => void;
    onPrev: () => void;
};

const Pagination: React.FunctionComponent<Props> = (props) => {
    const start = props.perPage * props.currentPage;
    const end = start + props.perPage;

    return (
        <div className="flex items-center justify-between">
            <div className="flex-1 flex justify-between sm:hidden space-x-3">
                {[
                    {
                        label: 'Previous',
                        action: props.onPrev,
                        visible: props.hasPrev,
                    },
                    {
                        label: 'Next',
                        action: props.onNext,
                        visible: props.hasNext,
                    },
                ].map(
                    ({ label, action, visible }) =>
                        visible && (
                            <button
                                type="button"
                                key={label}
                                onClick={() => action()}
                                className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition-default"
                            >
                                {label}
                            </button>
                        ),
                )}
            </div>
            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                    <p className="text-sm leading-5 text-gray-700">
                        Showing &nbsp;
                        <span className="font-medium">{start + 1}</span>
                        &nbsp; to &nbsp;
                        <span className="font-medium">{end}</span>
                    </p>
                </div>
                <div>
                    <nav className="relative z-0 inline-flex shadow-sm">
                        <button
                            type="button"
                            onClick={
                                props.hasPrev ? () => props.onPrev() : undefined
                            }
                            disabled={!props.hasPrev}
                            className={cx(
                                'relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-800 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition-default',
                                {
                                    'opacity-25': !props.hasPrev,
                                },
                            )}
                            aria-label="Previous"
                        >
                            <svg
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                />
                            </svg>
                        </button>
                        <button
                            type="button"
                            onClick={
                                props.hasNext ? () => props.onNext() : undefined
                            }
                            className={cx(
                                '-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-800 hover:text-gray-500 focus:z-10 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-100 active:text-gray-500 transition-default',
                                {
                                    'opacity-25': !props.hasNext,
                                },
                            )}
                            aria-label="Next"
                        >
                            <svg
                                className="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                            >
                                <svg
                                    className="h-5 w-5"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </svg>
                        </button>
                    </nav>
                </div>
            </div>
        </div>
    );
};

export default Pagination;
