import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import AppShell from './AppShell';
import IndexPlaceholder from './IndexPlaceholder';
import { useAppSelector } from './redux/react';
import { authSelectors } from './auth/store';
import Login from './auth/Login';
import Dashboard from './Dashboard';
import SnackbarManager from './snackbar/SnackbarManager';

const App: React.FunctionComponent = () => {
    const isSignedIn = useAppSelector(authSelectors.isSignedIn);
    return (
        <AppShell>
            <SnackbarManager />
            <Router>
                <Switch>
                    <Route path="/login">
                        <Login />
                    </Route>
                    <Route path="/" exact={!isSignedIn}>
                        {isSignedIn ? <Dashboard /> : <IndexPlaceholder />}
                    </Route>
                    <Route path="*">
                        <Redirect to="/" />
                    </Route>
                </Switch>
            </Router>
        </AppShell>
    );
};

export default App;
