import { FunctionComponent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { notificationActions, notificationSelectors } from './store';
import { Button } from '../common/Button';
import { snackbarActions } from '../snackbar/store';

type Props = {
    id: string;
};

const SendNotificationButton: FunctionComponent<Props> = ({ id }) => {
    const dispatch = useAppDispatch();

    const canBeSent = useAppSelector((state) =>
        notificationSelectors.canBeSent(state, { id }),
    );
    const isSending = useAppSelector((state) =>
        notificationSelectors.isSending(state, { id }),
    );

    const [hasConfirmed, setHasConfirmed] = useState(false);
    const [hasSent, setHasSent] = useState(false);

    const send = async () => {
        if (hasSent) {
            return;
        }
        if (!hasConfirmed) {
            setHasConfirmed(true);
            return;
        }

        // eslint-disable-next-line no-restricted-globals
        const secondConfirmation = confirm(
            'Are you sure? That cannot be undone',
        );
        if (!secondConfirmation) {
            setHasConfirmed(false);
            return;
        }

        const res = await dispatch(notificationActions.sendNotification(id));
        setHasSent(res.meta.requestStatus === 'fulfilled');
        setHasConfirmed(false);

        if (res.meta.requestStatus === 'rejected') {
            dispatch(
                snackbarActions.sendMessage({
                    title: 'Error sending notification',
                    subtitle: 'error' in res ? res.error.message : undefined,
                    type: 'warning',
                }),
            );
        }
    };

    if (!canBeSent) {
        return null;
    }

    return (
        <Button
            label={
                isSending
                    ? 'Sending...'
                    : hasSent
                    ? 'Sent!'
                    : hasConfirmed
                    ? 'Confirm'
                    : 'Send'
            }
            secondary
            onClick={send}
        />
    );
};

export default SendNotificationButton;
