import { createCrudSlice, CrudSlice } from '../crud-module/store';
import {
    CreateCustomReferralCodeRequest,
    GetCustomReferralCodesResponse,
    GetReferralCodeStatsResponse,
} from '../api/model/backoffice';
import { AppState } from '../redux/AppStore';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { snackbarActions } from '../snackbar/store';
import { selectorFamily } from 'recoil';
import axios from 'axios';

export const referralCustomCodeModule: CrudSlice<{ code: string }> =
    createCrudSlice<{ code: string }>({
        name: 'referralCustomCode',
        baseUrl: '/referral/customCode',
        localClientBasePath: '/referral/customCode',
        stateProvider: (s) => (s as AppState).referralCustomCode,
        listResponseParser: (data) => {
            const res = GetCustomReferralCodesResponse.fromJson(data);
            return {
                items: res.items.map((code) => ({ code })),
                nextPageToken: res.nextPageToken,
            };
        },
        singleResponseParser: (data) => {
            throw Error('not implemented');
        },
        writeArgumentTransformer: (i: { code: string }) => {
            return CreateCustomReferralCodeRequest.toJson({
                code: i.code,
            });
        },
        idProvider: (item) => item.code,
        prepareEmpty: () => ({ code: '' }),
    });

export const copyInviteLink = createAsyncThunk(
    'referralCustomCode/copyInviteLink',
    async (args: { code: string }, { dispatch }) => {
        const link = `https://plan3t.one/i/${args.code}`;
        await navigator.clipboard.writeText(link);

        dispatch(
            snackbarActions.sendMessage({
                title: 'Invite link copied to clipboard',
                subtitle: link,
            }),
        );
    },
);

export const referralCustomCodeReducer = referralCustomCodeModule.reducer;
export const referralCustomCodeActions = {
    ...referralCustomCodeModule.actions,
    copyInviteLink,
};
export const referralCustomCodeSelectors = referralCustomCodeModule.selectors;

export const codeStatsQuery = selectorFamily({
    key: 'referral/codeStatsQuery',
    get: (code: string) => async () => {
        const res = await axios.get(`/referral/stats?code=${code}`);
        return GetReferralCodeStatsResponse.fromJson(res.data);
    },
});
