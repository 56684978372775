import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import RewardList from './RewardList';
import ManageRewardCodes from './ManageRewardCodes';
import AdjustRewardPrice from './AdjustRewardPrice';
import RewardsCategoriesEditor from './RewardsCategoriesEditor';
import ManageRewardCodesV2 from './ManageRewardCodesV2';
import RewardViewer from './RewardViewer';

const RewardModule: React.FunctionComponent = () => {
    return (
        <Switch>
            <Route path="/reward" exact>
                <RewardList />
            </Route>
            <Route path="/reward/categories/:billingType" exact>
                <RewardsCategoriesEditor />
            </Route>
            <Route path="/reward/:partnerId/:id/codes">
                <ManageRewardCodes />
            </Route>
            <Route path="/reward/:partnerId/:id/codesV2">
                <ManageRewardCodesV2 />
            </Route>
            <Route path="/reward/:partnerId/:id/price">
                <AdjustRewardPrice />
            </Route>
            <Route
                path="/reward/:partnerId/:id/preview"
                render={({ match }) => (
                    <RewardViewer
                        id={match.params['id']}
                        partnerId={match.params['partnerId']}
                    />
                )}
            />
        </Switch>
    );
};

export default RewardModule;
