import { useAppDispatch, useAppSelector } from '../redux/react';
import {
    cashbackInspectorActions,
    cashbackInspectorSelectors,
    cashbackTicketSelectors,
} from './store';
import React, { useEffect, useMemo, useState } from 'react';
import LoadingSpinner from '../common/LoadingSpinner';
import cx from 'classnames';
import { TransactionType } from '../api/model/openbanking';
import { Timestamp } from '../api/google/protobuf/timestamp';
import PartnerName from '../partner/PartnerName';
import {
    ActionSheet,
    Bar,
    Button,
    ButtonDesign,
    DynamicPage,
    DynamicPageHeader,
    DynamicPageTitle,
    FlexBox,
    FlexBoxDirection,
    FlexBoxWrap,
    Input,
    InputType,
    Label,
    Title,
    ValueState,
} from '@ui5/webcomponents-react';
import { useHistory } from 'react-router-dom';
import { userActions, userSelectors } from '../user/store';
import {
    MissingCashbackState,
    MissingCashbackTicket,
    SetCashbackTicketStateRequest,
} from '../api/model/backoffice';
import axios from 'axios';

type Props = {
    ticketID: string;
};

export default function CashbackTicketInspector(props: Props) {
    const id = props.ticketID;
    const dispatch = useAppDispatch();
    const ticket = useAppSelector((s) =>
        cashbackTicketSelectors.getItem(s, { id }),
    );

    useEffect(() => {
        dispatch(cashbackInspectorActions.fetchTransactions({ id }));
    }, [dispatch, id]);

    const transactions = useAppSelector(
        cashbackInspectorSelectors.transactions,
    );
    const isLoading = useAppSelector(cashbackInspectorSelectors.isLoading);
    const loadingFailed = useAppSelector(
        cashbackInspectorSelectors.loadingFailed,
    );

    const testRegex = useAppSelector(cashbackInspectorSelectors.getTestRegex);
    const setTestRegex = (v: string) =>
        dispatch(cashbackInspectorActions.setTestRegex(v));

    const [regexCompiled, regexValid] = useMemo(() => {
        try {
            return [new RegExp(testRegex), true];
        } catch (e) {
            return [/^$/, false];
        }
    }, [testRegex]);

    const testRegexMatches = (desc: string) => {
        return regexValid && regexCompiled.test(desc);
    };

    const evaluate = () =>
        dispatch(cashbackInspectorActions.evaluateTestRegex());

    const evaluationState = useAppSelector(
        cashbackInspectorSelectors.getEvaluationState,
    );

    const history = useHistory();

    return (
        <DynamicPage
            headerTitle={
                <DynamicPageTitle
                    navigationActions={
                        <Button
                            icon="decline"
                            design={ButtonDesign.Transparent}
                            onClick={() => {
                                history.push('..');
                            }}
                        />
                    }
                    header={<Title>Cashback Ticket</Title>}
                />
            }
            headerContent={
                <DynamicPageHeader>
                    <FlexBox wrap={FlexBoxWrap.Wrap} className="space-x-4">
                        <FlexBox direction={FlexBoxDirection.Column}>
                            <Label>
                                Ticket: #<code>{ticket.id}</code>
                            </Label>
                            <Label>
                                Partner: <PartnerName id={ticket.partnerId} />
                            </Label>
                            <Label>
                                PartnerID: <code>{ticket.partnerId}</code>
                            </Label>
                            <Label>
                                Reported on:{' '}
                                {Timestamp.toDate(
                                    ticket.createdAt!,
                                ).toDateString()}
                            </Label>
                            <Label>
                                Reported by:{' '}
                                <UserEmailDisplay uid={ticket.userId} />
                            </Label>
                            <Label>
                                UserID: <code>{ticket.userId}</code>
                            </Label>
                        </FlexBox>
                        <FlexBox direction={FlexBoxDirection.Column}>
                            <Label>
                                Purchase Date:{' '}
                                {Timestamp.toDate(
                                    ticket.purchaseDate!,
                                ).toDateString()}
                            </Label>
                            <Label>
                                Order Amount:{' '}
                                {ticket.amountUnscaled /
                                    Math.pow(10, ticket.amountScale)}
                            </Label>
                            <Label>
                                AccountID: <code>{ticket.accountId}</code>
                            </Label>
                        </FlexBox>
                    </FlexBox>
                </DynamicPageHeader>
            }
            footer={<Footer ticket={ticket} />}
        >
            {isLoading && <LoadingSpinner />}
            {loadingFailed && <h1>Loading Failed!</h1>}

            <div className="flex flex-col-reverse sm:flex-row space-y-2 sm:space-y-0 space-x-0 sm:space-x-2">
                <div className="flex-grow max-w-sm flex flex-col space-y-4">
                    {transactions.map((tx, i) => (
                        <div
                            key={i}
                            className={cx('rounded shadow px-2 py-4 max-w-sm', {
                                'bg-green-50': testRegexMatches(tx.description),
                            })}
                        >
                            <div className="flex flex-row justify-between font-bold">
                                <span className="text-gray-700">
                                    {tx.description}
                                </span>
                                <span
                                    className={cx({
                                        'text-green-500':
                                            (tx.amount?.unscaledValue ?? 0) >=
                                            0,
                                        'text-red-500':
                                            (tx.amount?.unscaledValue ?? 0) < 0,
                                    })}
                                >
                                    {tx.amount?.scaledValue ?? '??'}{' '}
                                    {tx.amount?.currencyCode ?? '??'}
                                </span>
                            </div>
                            <div className="text-gray-700">
                                <span className="text-gray-400">
                                    {Timestamp.toDate(tx.date!).toDateString()}
                                </span>
                                &nbsp;
                                <span className="text-gray-400">
                                    {TransactionType[tx.transactionType]}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="border-l w-128 px-2 py-4 flex flex-col space-y-2">
                    <Input
                        showClearIcon
                        type={InputType.Text}
                        valueState={
                            !regexValid ? ValueState.Warning : ValueState.None
                        }
                        value={testRegex}
                        onChange={(e) => setTestRegex(e.target.value ?? '')}
                    />
                    <div>
                        <Button
                            design={ButtonDesign.Emphasized}
                            onClick={evaluate}
                        >
                            Evaluate
                        </Button>
                        {evaluationState.tag !== 'initial' && (
                            <div>
                                {evaluationState.tag === 'pending' && (
                                    <LoadingSpinner />
                                )}
                                {evaluationState.tag === 'rejected' && (
                                    <code>
                                        {JSON.stringify(evaluationState.error)}
                                    </code>
                                )}
                                {evaluationState.tag === 'fulfilled' && (
                                    <>
                                        <span>
                                            {
                                                evaluationState.evaluationResult
                                                    .numMatched
                                            }{' '}
                                            /{' '}
                                            {
                                                evaluationState.evaluationResult
                                                    .numProcessed
                                            }
                                        </span>
                                        &nbsp; (
                                        <span>
                                            {(
                                                (100 *
                                                    evaluationState
                                                        .evaluationResult
                                                        .numMatched) /
                                                evaluationState.evaluationResult
                                                    .numProcessed
                                            ).toFixed(2)}
                                            %
                                        </span>
                                        )
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </DynamicPage>
    );
}

function UserEmailDisplay(props: { uid: string }) {
    const { uid } = props;
    const account = useAppSelector((state) =>
        userSelectors.getById(state, { id: uid }),
    );

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!account) {
            dispatch(userActions.fetchUserAccount([uid]));
        }
    }, [account, dispatch, uid]);

    if (!account) {
        return <>No Account Information found!</>;
    }

    return <>{account.email}</>;
}

function Footer(props: { slot?: string; ticket: MissingCashbackTicket }) {
    const [open, setOpen] = useState(false);
    const id = `CashbackTicketInspector/Footer-ChangeState-${props.ticket.id}`;

    const states = [
        MissingCashbackState.MISSING_CASHBACK_TICKET_STATE_OPEN,
        MissingCashbackState.MISSING_CASHBACK_TICKET_STATE_ON_HOLD,
        MissingCashbackState.MISSING_CASHBACK_TICKET_STATE_SOLVED,
        MissingCashbackState.MISSING_CASHBACK_TICKET_STATE_REJECTED,
    ];

    const change = async (s: MissingCashbackState) => {
        const req = SetCashbackTicketStateRequest.toJson({
            id: props.ticket.id,
            targetState: s,
        });
        await axios.post(`/cashback/ticket/${props.ticket.id}/state`, req);
    };

    return (
        <Bar
            slot={props.slot}
            endContent={
                <>
                    <Button id={id} onClick={() => setOpen(true)}>
                        Change State
                    </Button>
                    <ActionSheet
                        open={open}
                        opener={id}
                        onAfterClose={() => setOpen(false)}
                    >
                        {states.map((s) => (
                            <Button
                                key={s}
                                onClick={
                                    s !== props.ticket.state
                                        ? () => change(s)
                                        : undefined
                                }
                                disabled={s === props.ticket.state}
                            >
                                {Object.entries(MissingCashbackState)
                                    .find(([k, v]) => v === s)
                                    ?.at(0) ?? '-'}
                            </Button>
                        ))}
                    </ActionSheet>
                </>
            }
        />
    );
}
