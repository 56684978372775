import {
    Children,
    FunctionComponent,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { BaseEditor, createEditor, Descendant } from 'slate';
import {
    DefaultElement,
    Editable,
    ReactEditor,
    RenderElementProps,
    Slate,
    useFocused,
    useSelected,
    withReact,
} from 'slate-react';
import Scrollable from '../common/Scrollable';
import { useAppDispatch, useAppSelector } from '../redux/react';
import { tipSlideEditorActions, tipSlideEditorSelectors } from './editorStore';
import { TipSlideDraft } from '../api/model/tip';
import { Button } from '../common/Button';
import {
    richTextToStandardLayout,
    SlateElement,
    SlateText,
    StandardLayoutOne,
    standardLayoutToProtoRichText,
    StandardLayoutType,
} from './layouts';
import RichTextRenderer from './RichTextRenderer';
import { useHistory } from 'react-router-dom';
import DeviceFrame, {
    DeviceType,
    validDeviceTypes,
} from '../common/DeviceFrame';
import { Duration } from '../api/google/protobuf/duration';
import FileUpload from '../files/FileUpload';
import { RenderLeafProps } from 'slate-react/dist/components/editable';
import { Plan3tEditor, withPlan3tEditing } from './slate';
import { int64ToHex } from '../common/proto_conv';
import SlateEditorToolbar from './SlateEditorToolbar';
import cx from 'classnames';

type Props = {
    tipID: string;
};

const TipSlideEditor: FunctionComponent<Props> = (props) => {
    const { tipID } = props;
    const dispatch = useAppDispatch();
    const history = useHistory();
    const slides = useAppSelector(tipSlideEditorSelectors.getSlides);
    const hasUnsavedChanges = useAppSelector(
        tipSlideEditorSelectors.hasUnsavedChanges,
    );

    const [hasSlidesLoaded, setHasSlidesLoaded] = useState(false);
    useEffect(() => {
        if (!hasSlidesLoaded) {
            dispatch(tipSlideEditorActions.fetchSlides({ id: tipID }));
        }
        setHasSlidesLoaded(true);
    }, [dispatch, hasSlidesLoaded, setHasSlidesLoaded, tipID]);

    const addSlide = (type: StandardLayoutType) =>
        dispatch(tipSlideEditorActions.addSlide({ type }));
    const save = () => dispatch(tipSlideEditorActions.saveSlides());
    const reset = () => dispatch(tipSlideEditorActions.resetState());

    return (
        <Scrollable>
            <div className="flex items-center space-x-2">
                <div>
                    <Button
                        secondary
                        label={
                            <svg
                                className="w-4 h-4"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path d="M10 19l-7-7m0 0l7-7m-7 7h18" />
                            </svg>
                        }
                        onClick={(e) => {
                            e.preventDefault();
                            history.goBack();
                        }}
                    />
                </div>
                <h1 className="font-bold text-3xl">Slides</h1>
                <div className="flex-shrink">
                    <Button type="submit" label="Save" primary onClick={save} />
                </div>
                {hasUnsavedChanges && (
                    <div className="flex-shrink">
                        <Button
                            type="button"
                            label="Reset"
                            secondary
                            onClick={reset}
                        />
                    </div>
                )}
                {hasUnsavedChanges && (
                    <span className="px-2 py-1 rounded-full bg-yellow-100 text-yellow-800">
                        Unsaved changes
                    </span>
                )}
            </div>
            <div className="flex flex-col space-y-8">
                {(slides || []).map((slide) => (
                    <SingleSlideEditor key={slide.id} slide={slide} />
                ))}
                <Button
                    label="Add slide"
                    primary
                    onClick={() => addSlide('one')}
                />
            </div>
        </Scrollable>
    );
};

export default TipSlideEditor;

declare module 'slate' {
    interface CustomTypes {
        Editor: BaseEditor & ReactEditor & Plan3tEditor;
        Element: SlateElement;
        Text: SlateText;
    }
}

function SingleSlideEditor({ slide }: { slide: TipSlideDraft }) {
    const dispatch = useAppDispatch();

    const removeSlide = () => {
        if (window.confirm('Really?')) {
            dispatch(tipSlideEditorActions.removeSlide({ id: slide.id }));
        }
    };

    const moveUp = () =>
        dispatch(
            tipSlideEditorActions.reorderSlides({ id: slide.id, amount: -1 }),
        );
    const moveDown = () =>
        dispatch(
            tipSlideEditorActions.reorderSlides({ id: slide.id, amount: 1 }),
        );

    const [deviceType, setDeviceType] = useState<DeviceType>('iPhoneX');

    const simple = richTextToStandardLayout(slide.content);

    const update = (data: Partial<StandardLayoutOne>) =>
        dispatch(
            tipSlideEditorActions.updateSlide({
                id: slide.id,
                content: standardLayoutToProtoRichText({
                    ...simple!,
                    ...data,
                }),
            }),
        );

    const onChangeDuration = (seconds: number) =>
        dispatch(
            tipSlideEditorActions.updateSlide({
                id: slide.id,
                duration: Duration.create({
                    seconds,
                }),
            }),
        );

    const editorRef = useRef<BaseEditor & ReactEditor & Plan3tEditor>();
    if (!editorRef.current)
        editorRef.current = withPlan3tEditing(withReact(createEditor()));
    const editor = editorRef.current;

    const renderLeaf = useCallback((props: RenderLeafProps) => {
        return <SlateLeaf {...props} />;
    }, []);

    const renderElement = useCallback((props: RenderElementProps) => {
        switch (props.element.type) {
            case 'list':
                return <SlateList {...props} />;
            case 'partner_ref':
                return <SlatePartnerRef {...props} />;
            default:
                return <DefaultElement {...props} />;
        }
    }, []);

    return (
        <div className="flex flex-col space-y-4 px-4 py-6 border-4 border-gray-50 rounded">
            <span className="tracking wider uppercase text-gray-700 font-bold">
                Slide # {slide.position + 1}
                <button
                    onClick={removeSlide}
                    className="ml-2 px-2 py-0.5 border border-gray-500 hover:bg-gray-100 rounded uppercase"
                >
                    Remove
                </button>
                <button
                    onClick={moveUp}
                    className="ml-2 px-2 py-0.5 border border-gray-500 hover:bg-gray-100 rounded uppercase"
                >
                    Up
                </button>
                <button
                    onClick={moveDown}
                    className="ml-2 px-2 py-0.5 border border-gray-500 hover:bg-gray-100 rounded uppercase"
                >
                    Down
                </button>
            </span>
            <div className="flex flex-row space-x-8">
                {!!simple ? (
                    <form
                        onSubmit={(e) => e.preventDefault()}
                        className="flex flex-col space-y-4 w-full"
                    >
                        <label className="flex flex-col space-y-1">
                            <span className="text-sm text-gray-500">
                                Duration in seconds
                            </span>
                            <input
                                className="form-input"
                                type="number"
                                name="duration"
                                value={slide.duration?.seconds ?? 0}
                                onChange={(e) =>
                                    onChangeDuration(parseInt(e.target.value))
                                }
                                min={0}
                                max={100}
                            />
                        </label>
                        <FileUpload
                            label="Image"
                            message="Drop a single image here"
                            file={simple.image}
                            onSingleChanged={(image) => update({ image })}
                            disabled={false}
                        />
                        <label className="flex flex-col space-y-1">
                            <span className="text-sm text-gray-500">
                                Header
                            </span>
                            <input
                                className="form-input"
                                type="text"
                                name="header"
                                value={simple.headline}
                                onChange={(e) =>
                                    update({ headline: e.target.value })
                                }
                            />
                        </label>
                        <SlateEditorToolbar editor={editor} />
                        <label className="flex flex-col space-y-1 h-full">
                            <span className="text-sm text-gray-500">
                                Content
                            </span>
                            <Slate
                                editor={editor}
                                value={simple.paragraphs}
                                onChange={(v: Descendant[]) =>
                                    update({ paragraphs: v as SlateElement[] })
                                }
                            >
                                <Editable
                                    renderLeaf={renderLeaf}
                                    renderElement={renderElement}
                                    onKeyDown={(event) => {
                                        if (!event.ctrlKey && !event.metaKey) {
                                            return;
                                        }
                                        if (event.key === 'b') {
                                            event.preventDefault();
                                            editor.toggleFormat('bold');
                                        }
                                        if (event.key === 'i') {
                                            event.preventDefault();
                                            editor.toggleFormat('italic');
                                        }
                                    }}
                                />
                            </Slate>
                        </label>
                    </form>
                ) : (
                    <span>Unsupported layout</span>
                )}
                <div className="flex flex-col space-y-4">
                    <DeviceFrame device={deviceType}>
                        <div className="flex flex-col px-4 pt-8">
                            <div className="my-4 flex space-x-2">
                                <div className="w-1/4 h-1 bg-gray-200 rounded-full" />
                                <div className="w-1/4 h-1 bg-gray-200 rounded-full" />
                                <div className="w-1/4 h-1 bg-gray-200 rounded-full" />
                                <div className="w-1/4 h-1 bg-gray-200 rounded-full" />
                            </div>
                            {slide.content && (
                                <RichTextRenderer richText={slide.content} />
                            )}
                        </div>
                    </DeviceFrame>
                    <span className="text-xs text-gray-500">
                        This is just an approximation. Objects in the preview
                        might look different than in the app.
                    </span>
                    <select
                        className="form-select"
                        value={deviceType}
                        onChange={(e) =>
                            setDeviceType(e.target.value as DeviceType)
                        }
                    >
                        {validDeviceTypes.map((d) => (
                            <option key={d} value={d}>
                                {d}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </div>
    );
}

const SlateLeaf = (props: RenderLeafProps) => {
    return (
        <span
            {...props.attributes}
            style={{
                fontWeight: props.leaf.bold ? 'bold' : 'normal',
                fontStyle: props.leaf.italic ? 'italic' : 'normal',
                color:
                    props.leaf.color !== 0 ? int64ToHex(props.leaf.color) : '',
            }}
        >
            {props.children}
        </span>
    );
};

const SlateList = (props: RenderElementProps) => {
    if (props.element.type !== 'list') {
        return null;
    }
    return (
        <ul
            {...props.attributes}
            className={cx({
                'list-disc': props.element.marker === 'disc',
                'list-decimal': props.element.marker === 'decimal',
                'list-check': props.element.marker === 'check',
            })}
            style={{ paddingInlineStart: '16px' }}
        >
            {Children.map(props.children, (c) => {
                return <li>{c}</li>;
            })}
        </ul>
    );
};

const SlatePartnerRef = (props: RenderElementProps) => {
    const selected = useSelected();
    const focused = useFocused();

    if (props.element.type !== 'partner_ref') {
        return null;
    }

    return (
        <div {...props.attributes}>
            {props.children}
            <div
                className={cx('flex flex-row items-center space-x-4 py-2', {
                    'shadow-outline': focused && selected,
                })}
                contentEditable={false}
            >
                <div className="w-8 h-8 rounded-full bg-gray-200" />
                <div className="flex-1">{props.element.id}</div>
                <div>-&gt;</div>
            </div>
        </div>
    );
};
