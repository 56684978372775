import { Form, Formik, useField, useFormikContext } from 'formik';
import { SyntheticEvent, useMemo, useState } from 'react';
import { MarkRewardCodeAsUsedRequest } from '../api/model/backoffice';
import {
    Bar,
    Button,
    ButtonDesign,
    Dialog,
    Loader,
} from '@ui5/webcomponents-react';
import { FormUnscaledValueField } from '../common/FormFields';
import axios from 'axios';

type Props = {
    open: boolean;
    onClose: () => void;

    rewardId: string;
    partnerId: string;
    period: number;
    documentId: string;
};

export default function MarkRewardCodesAsUsedDialog(props: Props) {
    const initialValues = useMemo<MarkRewardCodeAsUsedRequest>(
        () => ({
            codes: [],
            rewardId: props.rewardId,
            partnerId: props.partnerId,
            billingPeriod: props.period,
            feeScale: 0,
            feeUnscaled: 0,
            documentId: props.documentId,
        }),
        [props.rewardId, props.partnerId, props.period, props.documentId],
    );

    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = async (values: MarkRewardCodeAsUsedRequest) => {
        setIsSubmitting(true);
        try {
            await axios.post(
                `/reward/${values.partnerId}/id/${values.rewardId}/markCodesAsUsed`,
                MarkRewardCodeAsUsedRequest.toJson(values),
            );
        } finally {
            setIsSubmitting(false);
        }
    };

    const validate = (values: typeof initialValues) => {
        return {};
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validate={validate}
        >
            <Dialog
                open={props.open}
                onAfterClose={props.onClose}
                headerText="Buchen"
                footer={<ActionBar onClose={props.onClose} />}
            >
                <Form className="px-4 pt-8 space-y-2">
                    <FormUnscaledValueField
                        name="fee"
                        label="Marktplatz Fee PRO Code"
                    />
                    <CodesField />
                </Form>
                {isSubmitting && <Loader />}
            </Dialog>
        </Formik>
    );
}

function ActionBar(props: { slot?: string; onClose: () => void }) {
    const { submitForm } = useFormikContext();
    return (
        <Bar
            slot={props.slot}
            endContent={
                <>
                    <Button design={ButtonDesign.Positive} onClick={submitForm}>
                        Verbuchen
                    </Button>
                    <Button
                        design={ButtonDesign.Negative}
                        onClick={props.onClose}
                    >
                        Abbrechen
                    </Button>
                </>
            }
        />
    );
}

function CodesField() {
    const [f, , x] = useField('codes');

    const codes = f.value as string[];

    const onPaste = async (e: SyntheticEvent) => {
        e.preventDefault();
        const clipboardContent = await navigator.clipboard.readText();

        const lines = clipboardContent
            .split('\n')
            .map((l) => l.split(',')[0])
            .filter((l) => l !== 'code' && l !== 'name');

        x.setValue(lines);
        x.setTouched(true);
    };

    const onClear = () => {
        x.setValue([]);
    };

    return (
        <div className="flex flex-col">
            <div className="flex flex-col h-20 overflow-y-auto">
                {codes.map((c) => (
                    <code key={c}>{c}</code>
                ))}
            </div>
            {codes.length > 0 && <Button onClick={onClear}>Clear</Button>}
            <Button onClick={onPaste}>Clipboard-Paste</Button>
        </div>
    );
}
