import * as Yup from 'yup';
import ItemForm from '../crud-module/ItemForm';
import { referralCustomCodeModule } from './store';
import { FormTextField } from '../common/FormFields';
import React from 'react';

export default function ReferralCustomCodeForm(props: {
    id: string | undefined;
}) {
    const validationSchema = Yup.object().shape({
        code: Yup.string()
            .min(5)
            .max(49)
            .matches(/^[a-zA-Z0-9]*$/, 'only letters and numbers')
            .required(),
    });

    return (
        <ItemForm
            module={referralCustomCodeModule}
            id={props.id}
            validationSchema={validationSchema}
        >
            <FormTextField name="code" label="Code" />
        </ItemForm>
    );
}
