// @generated by protobuf-ts 2.8.2 with parameter generate_dependencies,long_type_number
// @generated from protobuf file "classifier-services/categorizer/rpc/categorizer.proto" (package "one.plan3t.core.categorizer.rpc", syntax proto3)
// tslint:disable
import { StringValue } from "../../../google/protobuf/wrappers";
import { ReclassifyTransactionResponse } from "../../../model/internal";
import { ReclassifyTransactionRequest } from "../../../model/internal";
import { ProcessOpenBankingTransactionResponse } from "../../../model/internal";
import { ProcessOpenBankingTransactionRequest } from "../../../model/internal";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message one.plan3t.core.categorizer.rpc.GetCategoriesRequest
 */
export interface GetCategoriesRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.categorizer.rpc.GetCategoriesResponse
 */
export interface GetCategoriesResponse {
    /**
     * @generated from protobuf field: repeated one.plan3t.core.categorizer.rpc.GetCategoriesResponse.Category categories = 1;
     */
    categories: GetCategoriesResponse_Category[];
}
/**
 * @generated from protobuf message one.plan3t.core.categorizer.rpc.GetCategoriesResponse.Category
 */
export interface GetCategoriesResponse_Category {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: repeated string path = 2;
     */
    path: string[];
    /**
     * @generated from protobuf field: string label = 3;
     */
    label: string;
    /**
     * @generated from protobuf field: string icon_name = 4;
     */
    iconName: string;
    /**
     * @generated from protobuf field: int64 color = 5;
     */
    color: number;
}
/**
 * @generated from protobuf message one.plan3t.core.categorizer.rpc.GetPartnerMappingRequest
 */
export interface GetPartnerMappingRequest {
}
/**
 * @generated from protobuf message one.plan3t.core.categorizer.rpc.GetPartnerMappingResponse
 */
export interface GetPartnerMappingResponse {
    /**
     * @generated from protobuf field: map<string, string> partner_category_map = 1;
     */
    partnerCategoryMap: {
        [key: string]: string;
    };
}
/**
 * @generated from protobuf message one.plan3t.core.categorizer.rpc.WritePartnerMappingRequest
 */
export interface WritePartnerMappingRequest {
    /**
     * @generated from protobuf field: string partner_id = 1;
     */
    partnerId: string;
    /**
     * @generated from protobuf field: string category_id = 2;
     */
    categoryId: string;
}
/**
 * @generated from protobuf message one.plan3t.core.categorizer.rpc.WritePartnerMappingResponse
 */
export interface WritePartnerMappingResponse {
}
// @generated message type with reflection information, may provide speed optimized methods
class GetCategoriesRequest$Type extends MessageType<GetCategoriesRequest> {
    constructor() {
        super("one.plan3t.core.categorizer.rpc.GetCategoriesRequest", []);
    }
    create(value?: PartialMessage<GetCategoriesRequest>): GetCategoriesRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCategoriesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCategoriesRequest): GetCategoriesRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetCategoriesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.categorizer.rpc.GetCategoriesRequest
 */
export const GetCategoriesRequest = new GetCategoriesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCategoriesResponse$Type extends MessageType<GetCategoriesResponse> {
    constructor() {
        super("one.plan3t.core.categorizer.rpc.GetCategoriesResponse", [
            { no: 1, name: "categories", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => GetCategoriesResponse_Category }
        ]);
    }
    create(value?: PartialMessage<GetCategoriesResponse>): GetCategoriesResponse {
        const message = { categories: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCategoriesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCategoriesResponse): GetCategoriesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated one.plan3t.core.categorizer.rpc.GetCategoriesResponse.Category categories */ 1:
                    message.categories.push(GetCategoriesResponse_Category.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCategoriesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated one.plan3t.core.categorizer.rpc.GetCategoriesResponse.Category categories = 1; */
        for (let i = 0; i < message.categories.length; i++)
            GetCategoriesResponse_Category.internalBinaryWrite(message.categories[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.categorizer.rpc.GetCategoriesResponse
 */
export const GetCategoriesResponse = new GetCategoriesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetCategoriesResponse_Category$Type extends MessageType<GetCategoriesResponse_Category> {
    constructor() {
        super("one.plan3t.core.categorizer.rpc.GetCategoriesResponse.Category", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "label", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "icon_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "color", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<GetCategoriesResponse_Category>): GetCategoriesResponse_Category {
        const message = { id: "", path: [], label: "", iconName: "", color: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetCategoriesResponse_Category>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetCategoriesResponse_Category): GetCategoriesResponse_Category {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* repeated string path */ 2:
                    message.path.push(reader.string());
                    break;
                case /* string label */ 3:
                    message.label = reader.string();
                    break;
                case /* string icon_name */ 4:
                    message.iconName = reader.string();
                    break;
                case /* int64 color */ 5:
                    message.color = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetCategoriesResponse_Category, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* repeated string path = 2; */
        for (let i = 0; i < message.path.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.path[i]);
        /* string label = 3; */
        if (message.label !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.label);
        /* string icon_name = 4; */
        if (message.iconName !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.iconName);
        /* int64 color = 5; */
        if (message.color !== 0)
            writer.tag(5, WireType.Varint).int64(message.color);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.categorizer.rpc.GetCategoriesResponse.Category
 */
export const GetCategoriesResponse_Category = new GetCategoriesResponse_Category$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPartnerMappingRequest$Type extends MessageType<GetPartnerMappingRequest> {
    constructor() {
        super("one.plan3t.core.categorizer.rpc.GetPartnerMappingRequest", []);
    }
    create(value?: PartialMessage<GetPartnerMappingRequest>): GetPartnerMappingRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPartnerMappingRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPartnerMappingRequest): GetPartnerMappingRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetPartnerMappingRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.categorizer.rpc.GetPartnerMappingRequest
 */
export const GetPartnerMappingRequest = new GetPartnerMappingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetPartnerMappingResponse$Type extends MessageType<GetPartnerMappingResponse> {
    constructor() {
        super("one.plan3t.core.categorizer.rpc.GetPartnerMappingResponse", [
            { no: 1, name: "partner_category_map", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<GetPartnerMappingResponse>): GetPartnerMappingResponse {
        const message = { partnerCategoryMap: {} };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetPartnerMappingResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetPartnerMappingResponse): GetPartnerMappingResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> partner_category_map */ 1:
                    this.binaryReadMap1(message.partnerCategoryMap, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: GetPartnerMappingResponse["partnerCategoryMap"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof GetPartnerMappingResponse["partnerCategoryMap"] | undefined, val: GetPartnerMappingResponse["partnerCategoryMap"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field one.plan3t.core.categorizer.rpc.GetPartnerMappingResponse.partner_category_map");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: GetPartnerMappingResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> partner_category_map = 1; */
        for (let k of Object.keys(message.partnerCategoryMap))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.partnerCategoryMap[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.categorizer.rpc.GetPartnerMappingResponse
 */
export const GetPartnerMappingResponse = new GetPartnerMappingResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WritePartnerMappingRequest$Type extends MessageType<WritePartnerMappingRequest> {
    constructor() {
        super("one.plan3t.core.categorizer.rpc.WritePartnerMappingRequest", [
            { no: 1, name: "partner_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "category_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<WritePartnerMappingRequest>): WritePartnerMappingRequest {
        const message = { partnerId: "", categoryId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WritePartnerMappingRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WritePartnerMappingRequest): WritePartnerMappingRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string partner_id */ 1:
                    message.partnerId = reader.string();
                    break;
                case /* string category_id */ 2:
                    message.categoryId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WritePartnerMappingRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string partner_id = 1; */
        if (message.partnerId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.partnerId);
        /* string category_id = 2; */
        if (message.categoryId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.categoryId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.categorizer.rpc.WritePartnerMappingRequest
 */
export const WritePartnerMappingRequest = new WritePartnerMappingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WritePartnerMappingResponse$Type extends MessageType<WritePartnerMappingResponse> {
    constructor() {
        super("one.plan3t.core.categorizer.rpc.WritePartnerMappingResponse", []);
    }
    create(value?: PartialMessage<WritePartnerMappingResponse>): WritePartnerMappingResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<WritePartnerMappingResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WritePartnerMappingResponse): WritePartnerMappingResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: WritePartnerMappingResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message one.plan3t.core.categorizer.rpc.WritePartnerMappingResponse
 */
export const WritePartnerMappingResponse = new WritePartnerMappingResponse$Type();
/**
 * @generated ServiceType for protobuf service one.plan3t.core.categorizer.rpc.Categorizer
 */
export const Categorizer = new ServiceType("one.plan3t.core.categorizer.rpc.Categorizer", [
    { name: "ProcessTransaction", options: {}, I: ProcessOpenBankingTransactionRequest, O: ProcessOpenBankingTransactionResponse },
    { name: "ReclassifyTransaction", options: {}, I: ReclassifyTransactionRequest, O: ReclassifyTransactionResponse },
    { name: "ReadTransactionInternalCategory", options: {}, I: StringValue, O: StringValue },
    { name: "GetCategories", options: {}, I: GetCategoriesRequest, O: GetCategoriesResponse },
    { name: "GetPartnerMapping", options: {}, I: GetPartnerMappingRequest, O: GetPartnerMappingResponse },
    { name: "WritePartnerMapping", options: {}, I: WritePartnerMappingRequest, O: WritePartnerMappingResponse }
]);
