import { useCallback, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { TextTableCell } from '../common/Table';
import { Timestamp } from '../api/google/protobuf/timestamp';
import ItemList from '../crud-module/ItemList';
import { openBankingWaitlistActions, openBankingWaitlistModule } from './store';
import { Button } from '../common/Button';
import { useAppDispatch } from '../redux/react';

const OpenBankingWaitlistModule: React.FunctionComponent = () => {
    return (
        <Switch>
            <Route path="/openbanking/waitlist" exact>
                <ItemList
                    module={openBankingWaitlistModule}
                    tableHeaders={['User', 'Signed-up', 'Accepted', 'Actions']}
                >
                    {(item) => {
                        return [
                            <TextTableCell
                                primary={item.email}
                                secondary={<code>{item.userId}</code>}
                            />,
                            <TextTableCell
                                primary={Timestamp.toDate(
                                    item.createdAt!,
                                ).toDateString()}
                            />,
                            <TextTableCell
                                primary={
                                    <AcceptedStatus
                                        acceptedAt={item.acceptedAt}
                                    />
                                }
                                secondary={
                                    item.acceptedAt
                                        ? Timestamp.toDate(
                                              item.acceptedAt,
                                          ).toDateString()
                                        : ''
                                }
                            />,
                            <div className="inline-flex">
                                {!item.acceptedAt && (
                                    <AcceptButton userId={item.userId} />
                                )}
                            </div>,
                        ];
                    }}
                </ItemList>
            </Route>
        </Switch>
    );
};

export default OpenBankingWaitlistModule;

function AcceptedStatus(props: { acceptedAt: Timestamp | undefined }) {
    if (props.acceptedAt) {
        return (
            <span className="text-sm flex items-center space-x-2 -ml-4">
                <span className="w-2 h-2 bg-green-500 rounded-full" />
                <span>Accepted</span>
            </span>
        );
    }
    return (
        <span className="text-sm flex items-center space-x-2 -ml-4">
            <span className="w-2 h-2 bg-yellow-500 rounded-full" />
            <span>Pending</span>
        </span>
    );
}

function AcceptButton({ userId }: { userId: string }) {
    const dispatch = useAppDispatch();

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const acceptIntoBeta = useCallback(async () => {
        setLoading(true);
        try {
            await dispatch(openBankingWaitlistActions.acceptEntry({ userId }));
            setSuccess(true);
        } finally {
            setLoading(false);
        }
    }, [dispatch, setSuccess, userId, setLoading]);

    if (success) {
        return <Button primary disabled label="Accepted!" />;
    }

    if (loading) {
        return <Button primary disabled label="..." />;
    }

    return (
        <Button
            primary
            label="Accept into Beta"
            onClick={() => acceptIntoBeta()}
        />
    );
}
