import { Route, Switch } from 'react-router-dom';
import { TextField } from '../common/TextField';
import React, { useMemo, useState } from 'react';
import { Bar, Button, Card, Label, Page } from '@ui5/webcomponents-react';
import { useAppDispatch } from '../redux/react';
import { snackbarActions } from '../snackbar/store';

export default function DeepLinkBuilderModule() {
    const dispatch = useAppDispatch();
    const [link, setLink] = useState('');

    const [utm, setUtm] = useState({
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        utm_content: '',
        utm_term: '',
        utm_id: '',
    });

    const result = useMemo(() => {
        const url = new URL('https://plan3t.one/o/' + link.replace(/^\//, ''));

        for (let [k, v] of Object.entries(utm)) {
            if (k && v) {
                url.searchParams.append(k, v);
            }
        }

        return url.toString();
    }, [link, utm]);

    const copy = () => {
        navigator.clipboard.writeText(result);
        dispatch(
            snackbarActions.sendMessage({
                title: 'Link copied to clipboard',
                subtitle: result,
            }),
        );
    };

    return (
        <Switch>
            <Route path="/deepLinkBuilder" exact>
                <Page
                    title="Deeplink Builder"
                    header={
                        <Bar>
                            <Label>Deeplink Builder</Label>
                        </Bar>
                    }
                >
                    <div className="container px-4 pt-8 flex flex-col space-y-2">
                        <TextField
                            label="In-App Link *"
                            value={link}
                            onChange={setLink}
                        />
                        {Object.entries(utm).map(([k, v]) => (
                            <TextField
                                key={k}
                                label={k}
                                value={v}
                                onChange={(nv) =>
                                    setUtm((e) => ({ ...e, [k]: nv }))
                                }
                            />
                        ))}
                        <Card>
                            <div className="flex flex-col space-y-2">
                                <span className="p-2">{result}</span>
                                <Button onClick={copy}>
                                    Copy to clipboard
                                </Button>
                            </div>
                        </Card>
                    </div>
                </Page>
            </Route>
        </Switch>
    );
}
