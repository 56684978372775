import React from 'react';
import { challengeModule, copyChallengeDeepLink } from './store';

import { TextTableCell } from '../common/Table';
import { Button } from '../common/Button';
import { Timestamp } from '../api/google/protobuf/timestamp';
import ItemList from '../crud-module/ItemList';
import { useAppDispatch } from '../redux/react';

export default function ChallengeList() {
    const dispatch = useAppDispatch();
    const copyDeepLink = (id: string) =>
        dispatch(copyChallengeDeepLink({ id }));

    return (
        <ItemList
            module={challengeModule}
            tableHeaders={['Name', 'Status', 'Actions']}
            listActions={[
                { label: 'Edit Categories', to: '/challenge/categories' },
            ]}
        >
            {(challenge) => [
                <TextTableCell
                    primary={challenge.name}
                    secondary={challenge.category}
                />,
                <TextTableCell
                    primary={
                        <ChallengeStatusChip
                            publishedAt={
                                challenge.publishedAt &&
                                Timestamp.toDate(challenge.publishedAt)
                            }
                            active={challenge.active}
                        />
                    }
                    secondary={challenge.publishedBy}
                />,
                <div className="inline-flex">
                    <Button
                        label="Edit"
                        secondary
                        to={`/challenge/${challenge.id}/edit`}
                    />
                    <Button
                        label="Copy DeepLink"
                        secondary
                        onClick={() => copyDeepLink(challenge.id)}
                    />
                </div>,
            ]}
        </ItemList>
    );
}

const ChallengeStatusChip: React.FunctionComponent<{
    publishedAt: Date | undefined;
    active: boolean;
}> = ({ publishedAt, active }) => {
    if (!publishedAt) {
        return (
            <span className="text-sm flex items-center space-x-2 -ml-4">
                <span className="w-2 h-2 bg-yellow-500 rounded-full" />
                <span>Pending</span>
            </span>
        );
    } else if (active) {
        return (
            <span className="text-sm flex items-center space-x-2 -ml-4">
                <span className="w-2 h-2 bg-green-500 rounded-full" />
                <span>Published</span>
            </span>
        );
    } else {
        return (
            <span className="text-sm flex items-center space-x-2 -ml-4">
                <span className="w-2 h-2 bg-red-500 rounded-full" />
                <span>Inactive</span>
            </span>
        );
    }
};
