import React, { FunctionComponent, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Scrollable from '../common/Scrollable';
import cx from 'classnames';
import { TextField } from '../common/TextField';
import { ulid } from 'ulid';
import { Button } from '../common/Button';
import { Timestamp } from '../api/google/protobuf/timestamp';
import { ApplyAccountingCommandManualRequest } from '../api/model/backoffice';
import { CommandType, AccountingCommand } from '../api/model/internal';
import axios from 'axios';
import SplitBalanceQueue from './SplitBalanceQueue';

const AccountingModule: FunctionComponent = () => {
    return (
        <Switch>
            <Route path="/accounting" exact>
                <ApplyManually />
            </Route>
            <Route path="/accounting/split-balance-queue">
                <SplitBalanceQueue />
            </Route>
        </Switch>
    );
};

function ApplyManually() {
    const [commandType, setCommandType] = useState<CommandType>(
        CommandType.CommandTypeUnknown,
    );
    const [id, setID] = useState(ulid());
    const [userId, setUserId] = useState('');
    const [partnerId, setPartnerId] = useState('');
    const [payload, setPayload] = useState('{}');

    const [requestState, setRequestState] = useState<
        | undefined
        | { submitting: true }
        | { error: unknown }
        | { success: true }
    >(undefined);

    const onSubmit = async () => {
        try {
            setRequestState({ submitting: true });
            const req = ApplyAccountingCommandManualRequest.create({
                command: AccountingCommand.create({
                    id,
                    commandType,
                    userId,
                    partnerId,
                    createdAt: Timestamp.now(),
                    payload: new TextEncoder().encode(payload),
                }),
            });

            await axios.post(
                '/one.plan3t.core.accounting.rpc.Accounting/ApplyAccountingCommandManual',
                ApplyAccountingCommandManualRequest.toJson(req),
            );
            setRequestState({ success: true });
        } catch (error) {
            setRequestState({ error });
        }
    };

    const canSubmit =
        requestState === undefined &&
        commandType !== CommandType.CommandTypeUnknown &&
        id.length > 0 &&
        payload.length > 0;

    return (
        <Scrollable>
            <div className="flex mb-4">
                <h1 className="font-bold text-3xl capitalize">SmartContract</h1>
            </div>
            <div className="flex flex-wrap -mx-2 -my-1">
                {Object.keys(CommandType)
                    .filter((k) => isNaN(Number(k)))
                    .map((c) => CommandType[c as keyof typeof CommandType])
                    .slice(1)
                    .map((c) => (
                        <button
                            key={c}
                            className={cx(
                                'mx-2 my-1 border border-gray-700 text-semantic-onPrimary px-4 py-2 rounded',
                                {
                                    'bg-gray-600': commandType === c,
                                    'bg-gray-800': commandType !== c,
                                },
                            )}
                            onClick={() => setCommandType(c)}
                        >
                            {CommandType[c].slice('CommandType'.length)}
                        </button>
                    ))}
            </div>
            <div className="flex flex-col space-y-1 max-w-2xl py-4">
                <TextField label="ID" value={id} onChange={(v) => setID(v)} />
                <TextField
                    label="UserID"
                    value={userId}
                    onChange={(v) => setUserId(v)}
                />
                <TextField
                    label="PartnerID"
                    value={partnerId}
                    onChange={(v) => setPartnerId(v)}
                />
                <textarea
                    className="form-textarea mt-1 block w-full"
                    rows={10}
                    value={payload}
                    onChange={(e) => setPayload(e.target.value)}
                />
                {requestState !== undefined && (
                    <div>
                        {'success' in requestState && <span>Success!</span>}
                        <Button
                            secondary
                            label="New"
                            onClick={() => {
                                setID(ulid());
                                setRequestState(undefined);
                            }}
                        />
                    </div>
                )}
                {requestState && 'error' in requestState && (
                    <div className="bg-red-100 rounded-md border border-red-300 text-red-900 px-4 py-2 mt-8">
                        {(requestState.error as any).toString()}
                    </div>
                )}
                <Button
                    primary
                    label="Submit"
                    disabled={!canSubmit}
                    onClick={onSubmit}
                />
            </div>
        </Scrollable>
    );
}

export default AccountingModule;
