import React, { useMemo, useState } from 'react';
import { partnerActions, usePartnerBaseList, usePartnerProfile } from './store';
import { Button } from '../common/Button';
import ImpersonatePartnerButton from './ImpersonatePartnerButton';
import { PartnerBase } from '../api/model/core';
import { useAppDispatch } from '../redux/react';
import EditPartnerFieldDialog from './EditPartnerFieldDialog';
import {
    FilterBar,
    FilterGroupItem,
    Select,
    Option,
    DynamicPage,
    DynamicPageTitle,
    FlexBox,
    FlexBoxDirection,
    Label,
    Table,
    TableColumn,
    TableRow,
    Title,
    Input,
    Icon,
} from '@ui5/webcomponents-react';
import PartnerName from './PartnerName';
import { TableCell } from '@ui5/webcomponents-react/wrappers';

export default function PartnerListInner() {
    const partners = usePartnerBaseList();

    const [filterAFF, setFilterAFF] = useState<boolean | undefined>(undefined);
    const [search, setSearch] = useState<string | undefined>('');

    const visiblePartners = useMemo(
        () =>
            filterAFF === undefined && !search
                ? partners
                : partners.filter((p) => {
                      const affMatches =
                          filterAFF === undefined ||
                          p.affiliateOnly === filterAFF;
                      const searchMatches =
                          search === undefined ||
                          search.trim().length === 0 ||
                          Object.values(p)
                              .join('')
                              .toLowerCase()
                              .includes(search.toLowerCase());

                      return affMatches && searchMatches;
                  }),
        [partners, filterAFF, search],
    );

    return (
        <DynamicPage
            headerTitle={
                <DynamicPageTitle
                    actions={
                        <>
                            <Button
                                to="/partner/categories"
                                primary
                                label="Edit Categories"
                            />
                            <Button
                                to="/partner/create"
                                primary
                                label="Create new partner"
                            />
                        </>
                    }
                    header={<Title>Partner</Title>}
                />
            }
            showHideHeaderButton={false}
        >
            <FilterBar hideFilterConfiguration>
                <FilterGroupItem label="Search">
                    <Input
                        icon={<Icon name="search" className="w-full h-full" />}
                        value={search}
                        onInput={(e) => setSearch(e.target.value)}
                    />
                </FilterGroupItem>
                <FilterGroupItem label="Affiliate Partners">
                    <Select
                        onChange={(e) =>
                            setFilterAFF(
                                e.detail.selectedOption.dataset.id === 'show'
                                    ? undefined
                                    : e.detail.selectedOption.dataset.id !==
                                          'hide',
                            )
                        }
                    >
                        <Option
                            data-id="show"
                            selected={filterAFF === undefined}
                        >
                            Show
                        </Option>
                        <Option data-id="hide" selected={filterAFF === false}>
                            Hide
                        </Option>
                        <Option data-id="only" selected={filterAFF === true}>
                            Only
                        </Option>
                    </Select>
                </FilterGroupItem>
            </FilterBar>
            <Table
                columns={
                    <>
                        <TableColumn>
                            <Label>Name</Label>
                        </TableColumn>
                        <TableColumn minWidth={600}>
                            <Label>IDs</Label>
                        </TableColumn>
                        <TableColumn minWidth={600} demandPopin>
                            <Label>Actions</Label>
                        </TableColumn>
                    </>
                }
            >
                {visiblePartners.map((partner) => (
                    <PartnerRow key={partner.id} partner={partner} />
                ))}
            </Table>
        </DynamicPage>
    );
}

function PartnerRow({
    slot,
    partner,
}: {
    slot?: string;
    partner: PartnerBase;
}) {
    const profile = usePartnerProfile(partner.id);

    const dispatch = useAppDispatch();

    const copyDeepLink = () =>
        dispatch(partnerActions.copyDeepLink({ partnerId: partner.id }));

    const [editDialogVisible, setEditDialogVisible] = useState(false);

    return (
        <TableRow slot={slot}>
            <TableCell>
                <Label>
                    <PartnerName forceShowLegalName id={partner.id} />
                    {partner.affiliateOnly && (
                        <span className="mx-2 inline-flex rounded px-2 bg-orange-200 text-orange-800">
                            AFF
                        </span>
                    )}
                </Label>
            </TableCell>
            <TableCell className="text-xs font-mono">
                <FlexBox direction={FlexBoxDirection.Column}>
                    <Label>ID: '{partner.id}'</Label>
                    {partner.pipedriveId && (
                        <Label>Pipedrive: '{partner.pipedriveId}'</Label>
                    )}
                    {partner.datevId && (
                        <Label>DATEV: '{partner.datevId}'</Label>
                    )}
                </FlexBox>
            </TableCell>
            <TableCell>
                <div className="w-full flex flex-col md:flex-row justify-start items-start">
                    <div>
                        <ImpersonatePartnerButton partnerId={partner.id} />
                    </div>
                    <div>
                        <EditPartnerFieldDialog
                            partnerId={
                                editDialogVisible ? partner.id : undefined
                            }
                            requestClose={() => setEditDialogVisible(false)}
                        />
                    </div>
                    <div>
                        <Button
                            label="Attach IDs"
                            secondary
                            onClick={() => setEditDialogVisible(true)}
                        />
                    </div>
                    <div>
                        {profile && (
                            <Button
                                label="Copy DeepLink"
                                secondary
                                onClick={copyDeepLink}
                            />
                        )}
                    </div>
                    <div>
                        {profile && (
                            <Button
                                label="View Profile"
                                secondary
                                to={`/partner/${partner.id}/profile`}
                            />
                        )}
                    </div>
                </div>
            </TableCell>
        </TableRow>
    );
}
