import React, { Suspense } from 'react';
import LoadingSpinner from '../common/LoadingSpinner';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { auditorAuditNamesQuery, auditorLastRunQuery } from './store';
import {
    Button,
    DynamicPage,
    DynamicPageHeader,
    DynamicPageTitle,
    FlexBox,
    FlexBoxDirection,
    Label,
    ObjectStatus,
    TableCell,
    TableColumn,
    TableRow,
    Title,
    ValueState,
} from '@ui5/webcomponents-react';
import { Table } from '@ui5/webcomponents-react/wrappers';
import { Timestamp } from '../api/google/protobuf/timestamp';
import { AuditStatus } from '../api/auditor/rpc/auditor';
import AuditRunResultListPage from './AuditRunResultListPage';

export default function AuditorModule() {
    return (
        <Suspense fallback={<LoadingSpinner />}>
            <Switch>
                <Route path="/audit" exact>
                    <Overview />
                </Route>
                <Route path="/audit/:name">
                    <AuditRunResultListPage />
                </Route>
            </Switch>
        </Suspense>
    );
}

function Overview() {
    const auditNames = useRecoilValue(auditorAuditNamesQuery);
    return (
        <DynamicPage
            headerTitle={<DynamicPageTitle header={<Title>Audit</Title>} />}
            headerContent={
                <DynamicPageHeader>
                    <FlexBox direction={FlexBoxDirection.Column}>
                        <Label>Audit Checks</Label>
                        <ObjectStatus>{auditNames.length}</ObjectStatus>
                    </FlexBox>
                </DynamicPageHeader>
            }
        >
            <Table
                columns={
                    <>
                        <TableColumn>
                            <Label>Audit Name</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Status</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Remarks</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Last Checked At</Label>
                        </TableColumn>
                        <TableColumn>
                            <Label>Actions</Label>
                        </TableColumn>
                    </>
                }
            >
                {auditNames.map((auditName) => (
                    <AuditTableRow key={auditName} auditName={auditName} />
                ))}
            </Table>
        </DynamicPage>
    );
}

function AuditTableRow(props: { auditName: string }) {
    const rr = useRecoilValue(auditorLastRunQuery(props.auditName));
    const history = useHistory();
    return (
        <TableRow>
            <TableCell>
                <Label>{props.auditName}</Label>
            </TableCell>
            <TableCell>
                <Label>
                    <ObjectStatus
                        state={auditStatusToValueState(rr.status)}
                        showDefaultIcon
                    />
                </Label>
            </TableCell>
            <TableCell>
                <Label>{rr.remarks}</Label>
            </TableCell>
            <TableCell>
                <Label>{Timestamp.toDate(rr.finishedAt!).toISOString()}</Label>
            </TableCell>
            <TableCell>
                <FlexBox>
                    <Button
                        icon="arrow-right"
                        onClick={() => history.push(`/audit/${rr.auditName}`)}
                    />
                </FlexBox>
            </TableCell>
        </TableRow>
    );
}

export function auditStatusToValueState(status: AuditStatus): ValueState {
    switch (status) {
        case AuditStatus.UNKNOWN:
            return ValueState.Warning;
        case AuditStatus.FAILED:
            return ValueState.Error;
        case AuditStatus.PASSED:
            return ValueState.Success;
    }
}
